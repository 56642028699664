import React from 'react';
import PropTypes from 'prop-types';
import { IonModal, IonIcon } from '@ionic/react';

import './PeekingModal.scss';
import { chevronDownOutline } from 'ionicons/icons';
import PeekingAddAddressModal from './PeekingAddAddressModal';
import { connect } from 'react-redux';

const PeekingModal = ({
  showPeekingModal,
  setShowPeekingModal,
  title,
  formData,
  setFormData,
}) => {
  return (
    <IonModal
      isOpen={showPeekingModal}
      mode="ios"
      class="peekingModal"
      swipeToClose={true}
      onWillDismiss={() => setShowPeekingModal(false)}
    >
      <div className="modalContent">
        <div className="titleContainer">
          <IonIcon
            className="peekingModalChevron"
            icon={chevronDownOutline}
            onClick={() => setShowPeekingModal(false)}
          ></IonIcon>
          <h1 className="peekingModalTitle">{title}</h1>
        </div>
        <PeekingAddAddressModal
          setShowModal={setShowPeekingModal}
          setFormData={setFormData}
          formData={formData}
        />
      </div>
    </IonModal>
  );
};

PeekingModal.propTypes = {
  title: PropTypes.string.isRequired,
  showPeekingModal: PropTypes.bool.isRequired,
  setShowPeekingModal: PropTypes.func.isRequired,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};

export default connect()(PeekingModal);
