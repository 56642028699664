import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonModal,
  IonButton,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonIcon,
  IonTextarea,
} from '@ionic/react';

import './ConfirmDeliveryModal.scss';
import { connect } from 'react-redux';
import { Camera, CameraResultType } from '@capacitor/camera';
import { addCircleOutline, closeOutline } from 'ionicons/icons';
import {
  markRQAsDelivered,
  updateRooQuestWithoutCall,
} from '../../../../../../actions/rooquest/rooquest';
import { setShowEnlargeImageModal } from '../../../../../../actions/layout/navigation';
import { uploadDeliveryImages } from '../../../../../../actions/firebase/firebase-storage';
import { USE_GPS_TRACKING } from '../../../../../../App';
import { useTranslation } from 'react-i18next';
declare var global; 
const ConfirmDeliveryModal = ({
  rooquest,
  showModal,
  setShowModal,
  markRQAsDelivered,
  updateRooQuestWithoutCall,
  setShowEnlargeImageModal,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [didNotConfirmError, setDidNotConfirmError] = useState({
    error: false,
    style: null,
  });

  // Get Image
  const [deliveryImages, setDeliveryImages] = useState([]);

  const getImage = async () => {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      height: 500,
    });

    (await fetch(image.webPath)).blob().then((blobImage) => {
      setDeliveryImages([
        ...deliveryImages,
        { blobImage, webpath: image.webPath },
      ]);
    });
  };

  const enlargeImage = (e, src) => {
    if (e.target.tagName !== 'ION-ICON') {
      setShowEnlargeImageModal(true, src);
    }
  };

  const [notes, setNotes] = useState('');

  const confirmDelivery = async () => {
    if (checked) {
      const images = await uploadDeliveryImages(deliveryImages);
      const imagesString = images.toString();

      const rq = await markRQAsDelivered(rooquest.rooquest._id, notes, imagesString)

      if (rq) {
        updateRooQuestWithoutCall(rq);
      }

      setShowModal(false);

      if (USE_GPS_TRACKING) {
        console.log("global.driverInterval", global.driverInterval);
        if (global.driverInterval) {
          clearInterval(global.driverInterval);
        }
      }
      
    } else {
      setDidNotConfirmError({ error: true, style: { color: '#eb0000' } });
    }
  };

  return (
    <div className="confirmDeliveryModalDiv">
      <IonModal
        mode="ios"
        isOpen={showModal}
        class={'confirmDeliveryModal'}
        // showBackdrop={true}
        backdropDismiss={true}
        onWillDismiss={() => setShowModal(false)}
      >
        <div className="modalContent">
          <h2 className="modalTitle">{t('Confirm Delivery')}</h2>

          <div className="inputElements">
            <div className="receiptAndAmountContainer">
              <p className="imageOfReceiptMessage">
                {t('If the requester was not present during the delivery attempt')},{' '}
                <strong>
                {t('please provide an image of the RooQuest at the delivery location')}
                </strong>{' '}
                {t('for proof of delivery')}.
              </p>

              <div className="rowOfImages">
                {deliveryImages.map((image, index) => (
                  <div
                    key={`receiptImage_${index}`}
                    className="imageContainer"
                    onClick={(e) => enlargeImage(e, image.webpath)}
                  >
                    <img
                      src={image.webpath}
                      alt="receipt"
                      className="receiptImage"
                    />
                    <div
                      className="removeImage"
                      onClick={() => {
                        const newArr = [...deliveryImages];
                        newArr.splice(index, 1);
                        setDeliveryImages(newArr);
                      }}
                    >
                      <IonIcon icon={closeOutline} />
                    </div>
                  </div>
                ))}
                {deliveryImages.length < 3 && (
                  <div className="addImageButton">
                    <IonButton onClick={async () => await getImage()}>
                      <IonIcon icon={addCircleOutline} />
                    </IonButton>
                  </div>
                )}
              </div>
            </div>

            <div className="notes">
              <IonItem lines='full'>
                <IonTextarea
                  placeholder={t("Notes")}
                  rows={2}
                  value={notes}
                  onIonChange={(e) => {
                    setNotes(e.detail.value!);
                  }}
                />
              </IonItem>
            </div>

            <div className="confirmCheckbox">
              <IonItem lines='full'>
                <IonCheckbox
                  checked={checked}
                  onIonChange={(e) => {
                    setDidNotConfirmError({ error: true, style: null });
                    setChecked(e.detail.checked);
                  }}
                />
                <IonLabel style={didNotConfirmError.style}>
                  {t("I have delivered the RooQuest as per Roo's Terms of Service.")}
                </IonLabel>
              </IonItem>
            </div>
          </div>

          <div className="actionButtons">
            <div className="row">
              <IonButton
                onClick={() => setShowModal(false)}
                disabled={rooquest.loading}
              >
                {t('Close')}
              </IonButton>
              <IonButton
                onClick={() => confirmDelivery()}
                disabled={rooquest.loading}
              >
                {!rooquest.loading ? 'Confirm' : 'Confirming'}
              </IonButton>
            </div>
          </div>
        </div>
      </IonModal>
    </div>
  );
};

ConfirmDeliveryModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  rooquest: PropTypes.object.isRequired,
  markRQAsDelivered: PropTypes.func.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  rooquest: state.rooquest,
});

export default connect(mapStateToProps, {
  markRQAsDelivered,
  updateRooQuestWithoutCall,
  setShowEnlargeImageModal,
})(ConfirmDeliveryModal);
