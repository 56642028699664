import axios from 'axios';
import { backendAddress } from '../../App';

import {
  GETTING_CONVERSATIONS,
  GET_CONVERSATIONS_ERROR,
  CONVERSATIONS_LOADED,
  MESSAGES_LOADED,
  GETTING_CONVERSATION,
  CONVERSATION_LOADED,
  CLEAR_CONVERSATION,
  SEND_PRIVATE_MESSAGE,
  ADD_UNREAD_TO_CONVERSATION,
  SET_SHOW_CHAT_MODAL,
} from './chat-types';

import { updateNavbarWithUnreadMessages } from '../layout/navbar';
import { launchToast,launchToastCard } from '../layout/toast';

// Get All Chats
export const getAllConversations = (userID) => async (dispatch) => {
  try {
    dispatch({
      type: GETTING_CONVERSATIONS,
    });
    const res = await axios.get(backendAddress + '/api/chat/conversations');

    if (res.data) {
      let totalCount = 0;
      const convos = res.data;
      convos.forEach((convo) => {
        const count = countUnreadInConversation(convo, userID);
        convo.numUnreadMessages = count;
        totalCount += count;
      });

      dispatch(updateNavbarWithUnreadMessages(totalCount));

      dispatch({
        type: CONVERSATIONS_LOADED,
        payload: convos,
      });
    } else {
      dispatch({
        type: GET_CONVERSATIONS_ERROR,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CONVERSATIONS_ERROR,
    });
    return [];
  }
};

// Send Private Message
export const sendPrivateMessage = (chatID, msgObj) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify(msgObj);

    const res = await axios.put(
      backendAddress + '/api/chat/messages/' + chatID,
      body,
      config
    );

    dispatch({
      type: SEND_PRIVATE_MESSAGE,
      payload: msgObj,
    });

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

// Get Chat Information
export const getConversation = (
  rqID,
  rqTitle,
  rqImage,
  reqID,
  reqFirstName,
  delivID,
  delivFirstName
) => async (dispatch) => {
  try {
    dispatch({
      type: GETTING_CONVERSATION,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      rqID,
      rqTitle,
      rqImage,
      reqID,
      reqFirstName,
      delivID,
      delivFirstName,
    });

    const res = await axios.post(backendAddress + '/api/chat', body, config);

    dispatch({
      type: CONVERSATION_LOADED,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.message) {
      // dispatch(launchToast(err.response.data.message, 2000, 'top'));
      dispatch(launchToastCard({ 
        msg: err.response.data.message,
        type: 'error',
        duration: 8500,
     }));
    }

    dispatch({
      type: CLEAR_CONVERSATION,
    });
  }
};

// Get Chat Information by ChatID
export const getConversationByChatID = (chatID) => async (dispatch) => {
  try {
    dispatch({
      type: GETTING_CONVERSATION,
    });

    const res = await axios.get(
      backendAddress + '/api/chat/conversations/' + chatID
    );
    dispatch({
      type: CONVERSATION_LOADED,
      payload: res.data,
    });

    // Get Messages of Chat
    // console.log(res.data);/
    return res.data;
  } catch (err) {
    console.error(err);
    dispatch({
      type: CLEAR_CONVERSATION,
    });
  }
};

// Set Chat Information
export const setConversation = (convo) => async (dispatch) => {
  dispatch({
    type: CONVERSATION_LOADED,
    payload: convo,
  });
  return convo;
};

// Get Messages of a Chat
export const setChatMessages = (convo) => async (dispatch) => {
  dispatch({
    type: MESSAGES_LOADED,
    payload: convo.messages,
  });
};

export const markMessagesAsRead = (chatID) => async (dispatch) => {
  try {
    await axios.put(backendAddress + '/api/chat/messages/markAsRead/' + chatID);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const countUnreadMessages = (chat, userID) => {
  let count = 0;
  chat.conversations.forEach((convo) => {
    convo.messages.forEach((message) => {
      const messageReadBys = message.readBy.map((read) => read.userID);
      if (!messageReadBys.includes(userID)) {
        count++;
      }
    });
  });
  return count;
};

export const countUnreadInConversation = (convo, userID) => {
  let count = 0;
  convo.messages.forEach((message) => {
    const messageReadBys = message.readBy.map((read) => read.userID);
    // console.log(messageReadBys, userID);
    if (!messageReadBys.includes(userID)) {
      count++;
    }
  });
  return count;
};

export const addUnreadToConversation = (chatID, chat, amount) => (dispatch) => {
  dispatch({
    type: ADD_UNREAD_TO_CONVERSATION,
    payload: {
      chatID,
      amount,
    },
  });
  // console.log(chat.conversations);
  // chat.conversations.map((convo) =>
  //   convo._id === chatID ? (convo.numUnreadMessages += amount) : convo
  // );
  // console.log(chat.conversations);

  // chat.conversations.forEach((convo) => {
  //   if (convo._id === chatID) {
  //     convo.numUnreadMessages += amount;
  //   }
  // });
};

export const setShowChatModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_SHOW_CHAT_MODAL,
    payload: bool,
  });
};
