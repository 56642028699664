import React, { Fragment } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import './GreenHeader.scss'
import { useTranslation } from 'react-i18next';
const GreenHeader = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <IonHeader className="ion-no-border GreenHeader">
        <IonToolbar className="new-background-color">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" text="" />
          </IonButtons>
          <IonTitle>{t('Post a RooQuest')}</IonTitle>
        </IonToolbar>
      </IonHeader>
    </Fragment>
  )
}

export default GreenHeader;