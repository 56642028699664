import React from 'react';
import PropTypes from 'prop-types';

import './MessageCard.scss';
import { IonBadge } from '@ionic/react';
import { connect } from 'react-redux';
import { formatRelativeTime } from '../../../utils/formatDate';

const MessageCard = ({ onClick, conversation, auth }) => {
  let extraStyling;
  if (
    conversation &&
    conversation.numUnreadMessages &&
    conversation.numUnreadMessages > 0
  ) {
    extraStyling = {
      borderRight: '4px solid #e94040',
    };
  }

  return (
    <div className="messageCard" style={extraStyling} onClick={() => onClick()}>
      <div className="cardContent">
        <div className="topRow">
          <div className="imgTextContainerGroup">
            <img
              className="rqImg"
              src={
                conversation && conversation.rooquestImage
                  ? conversation.rooquestImage
                  : require('./NoImageAvailable.png')
              }
              alt=""
            />
            <div className="textComponent">
              <h5>
                {auth.user._id === conversation.requesterID
                  ? conversation.delivererFirstName
                  : conversation.requesterFirstName}
              </h5>

              <p>{conversation.rooquestTitle}</p>
              <p className="lastMessage">
                {conversation.messages[conversation.messages.length - 1]
                  .senderID === auth.user._id && 'You: '}
                {
                  conversation.messages[conversation.messages.length - 1]
                    .msgContent
                }
              </p>
              <p className="lastMessageTime">
                {formatRelativeTime(
                  conversation.messages[conversation.messages.length - 1].date
                )}
              </p>
            </div>
          </div>

          <div className="unreadComponent">
            {conversation.numUnreadMessages > 0 && (
              <IonBadge mode="ios">{conversation.numUnreadMessages}</IonBadge>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MessageCard.propTypes = {
  extraStyling: PropTypes.any,
  onClick: PropTypes.func,
  conversation: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(MessageCard);
