import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IonContent, IonDatetime, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonPopover, IonSpinner, IonText } from '@ionic/react';
import { format, parseISO } from 'date-fns';

import './ActivationCodeModal.scss';
import { closeOutline, createOutline } from 'ionicons/icons';
import { connect } from 'react-redux';
import { setShowPromoModal } from '../../actions/layout/navigation';
import { loadUser } from '../../actions/auth/auth';
import { launchToastCard } from '../../actions/layout/toast';
import sendRequests from '../../hooks/sendRequests';
import { useTranslation } from 'react-i18next';

const ActivationCodeModal = ({ show, setShow,
    auth,
    loadUser,
    launchToastCard }) => {
    
    const { t } = useTranslation();
    const [promoCode, setPromoCode] = useState('');

    const closeModal = () => {
      setShow(false);
    };
  
    const onSubmitPromo = async () => {
      const { doRequest } = sendRequests({
        url: '/api/promos/',
        method: 'post',
        body: {
          userId: auth.user._id,
          firstName: auth.user.firstName,
          lastName: auth.user.lastName,
          promoCode: promoCode,
          email: auth.user.email,
        },
        onSuccess: (data) => {
          const status = data.status;
          if (status === 200) {
            launchToastCard({ 
                msg: 'Code applied to account.',
                type: 'success',
                duration: 8500,
            });
            closeModal();
          } else {
            launchToastCard({ 
                msg: 'Code invalid. Please check your code and try again.',
                type: 'error',
                duration: 8500,
            });
          }
        },
      });
      await doRequest();
      await loadUser();
    };

    return (
        <IonModal
            mode="ios" 
            isOpen={show} 
            class="ActivationCodeModal"
            breakpoints={[0, 1]}
            initialBreakpoint={1}
            onIonModalDidDismiss={closeModal}        
        >
            <IonContent>
                <div className="ActivationCodeModalContent">
                    <div className="header">
                        <div className="title">
                            <p>{t("Activation or Promo Code")}</p>
                        </div>
                        <div className="closeBtn">
                            <IonIcon icon={closeOutline} onClick={closeModal} />
                        </div>
                    </div>                    
                    <div className="main">
                        <div className="description">
                            <p>{t("Please enter the promo or activation code below")}</p>
                            <input
                                value={promoCode}
                                placeholder={t("Code")}
                                onChange={(e) => setPromoCode(e.target.value!)}
                                autoFocus={false}
                            ></input>
                        </div>
                        <div className="footer">
                            <div className="acknowledgeBtn" onClick={onSubmitPromo}>
                                <p>{t("Submit Code")}</p>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </IonContent>
        </IonModal>);
};


const mapStateToProps = (state) => ({
    ShowPromoModal: state.navigation.ShowPromoModal,
    auth: state.auth,
  });

export default connect(mapStateToProps, { setShowPromoModal, loadUser, launchToastCard })(ActivationCodeModal);
