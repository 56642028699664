import React, { useEffect, useRef, useState } from 'react';
import { ReactPropTypes } from 'react';
import { useTranslation } from 'react-i18next';
const ProgressBar = ({percentage,totalItems,totalCollectedItems}) => {
    const bgcolor = '#10be5f';
  
    const fillerStyles = {
      height: '100%',
      width: `${percentage}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right' as 'right',
      transition: 'width 1s ease-in-out',
    }
    const { t } = useTranslation();
    return (
        <div className='progressBarContainer'>
          <div style={fillerStyles}>
            {/* <span style={labelStyles}>{`${percentage}%`}</span> */}
            <span className='progressBarText'>{`${totalCollectedItems}/${totalItems} ${t('Items Collected')}`}</span>
          </div>
        </div>
      );
  };
  
  export default ProgressBar;