import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GreenModal from '../../layout/Modal/GreenModal';
import FullPageModalCard from '../../layout/Modal/FullPageModalCard';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner/Spinner';
import { loadUser } from '../../../actions/auth/auth';
import './DelivererSetupModal.scss';
import { getStripeClientID } from '../../../utils/initStripe';
// import { USE_LIVE_STRIPE } from '../../../App';
import { launchToastCard } from '../../../actions/layout/toast';
import { useTranslation } from 'react-i18next';

const DelivererSetupModal = ({ auth, showModal, setShowModal, loadUser, launchToastCard,USE_LIVE_STRIPE }) => {

  const [verify, setVerify] = useState(false);
  const { t } = useTranslation();

  const setUpAccountInBrowser = () => {
    const clientID = getStripeClientID(USE_LIVE_STRIPE);
    const link = `https://connect.stripe.com/express/oauth/authorize?client_id=${clientID}&state=${auth.user._id}&suggested_capabilities[]=transfers&stripe_user[email]=${auth.user.email}`;
    const browser = InAppBrowser.create(link, '_blank', {
      hidden: 'yes',
      zoom: 'no',
      hideurlbar: 'yes',
      hidenavigationbuttons: 'yes',
      toolbarcolor: '#073809',
      closebuttoncolor: '#ffffff',
      closebuttoncaption: 'Close',
    });
    try {
      browser.on('loadstart').subscribe((e) => {
        // console.log("on load start")
        setShowSpinner(true);
      });
      browser.on('loadstop').subscribe(async (e) => {
        setShowSpinner(false);
        setShowModal(false);
        console.log("on load stop")
        browser.show();
        console.log(e.url);
        if(e.url == "https://kargoroo-app-staging.web.app/"){
          browser.close();
          await loadUser();
          launchToastCard({ 
            msg: 'Stripe Account Creation Success',
            type: 'success',
            duration: 8500,
         });
        }
        if (
          e.url.startsWith(
            `http://kargorooapp.kargoroo.ca/api/payment/stripe/account/redirect?`
        )
        ) {
          await loadUser();
          browser.close();
        }
      });
    } catch (err) {
      console.error(err);
    }
    
  };

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (verify && auth && auth.user && !auth.user.stripeAccountID) {
      setShowModal(false);
      launchToastCard({ 
        msg: 'Stripe Account Creation Success',
        type: 'success',
        duration: 8500,
     });
    }
  }, [auth]);

  useEffect(() => {
    if (!showModal) setVerify(false)
  }, [showModal]);

  return (
    <Fragment>
      <GreenModal
        title={t("Setup Deliverer Account")}
        showModal={showModal}
        setShowModal={setShowModal}
        className="delivererSetupModal"
        backBtnOnClickFxn={() => setShowModal(false)}
        onWillDismiss = {()=> setShowModal(false)}
      >
        <FullPageModalCard title={t("Setup Instructions")}>
          <div className="delivererSetupModalBody">
            {showSpinner ? (
              <Spinner />
            ) : (
              <Fragment>
                <p>
                  <strong>
                    {t("KargoROO uses Stripe to manage payouts to you, the deliverer.")}
                  </strong>
                </p>
                <p>
                  {t("Click the")} <strong>{t("Connect with Stripe")}</strong> {t("button to begin the onboarding process to become a KargoROO Deliverer and get paid for your service.")}
                </p>
                <p>
                {t("You will need to create a Stripe Account (or link an existing account) and provide Stripe with personal information to complete the onboarding process.")}
                </p>
                <div className="stripeButtonContainer">
                  <img
                    src={require('./blue-on-dark.png')}
                    alt=""
                    onClick={() => {
                      setVerify(true);
                      setUpAccountInBrowser();
                    }}
                  />
                  {/* {verify && <div className="completeVerify" onClick={() => {
                      loadUser();
                    }}>
                      <p className='completetxtbtn'>{t("Click here when complete")}</p>
                    </div>} */}
                </div>
                
              </Fragment>
            )}
          </div>
        </FullPageModalCard>
      </GreenModal>
    </Fragment>
  );
};

DelivererSetupModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  launchToastCard: PropTypes.func.isRequired,
  USE_LIVE_STRIPE : state.USE_LIVE_STRIPE
});

export default connect(mapStateToProps, { loadUser, launchToastCard })(DelivererSetupModal);