import { SET_KEYBOARD_OPEN, SET_KEYBOARD_CLOSE } from "../actions/keyboard/keyboard-types";

const isKeyboardVisible = (state = false,action) => {
    const {type,payload} = action
    switch(type){
        case SET_KEYBOARD_OPEN:
            return payload;
        case SET_KEYBOARD_CLOSE:
            return payload;
        default:
            return state;
    }

}

export default isKeyboardVisible;