import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { IonContent, IonIcon, IonModal } from '@ionic/react'
import { closeOutline, saveSharp } from 'ionicons/icons'

import './AdditionalNote.scss'
import { useTranslation } from 'react-i18next'

const AdditionalNote = ({ showModal, setShowModal, note, setNote }) => {

    const [noteText, setNoteText] = useState('')
    const { t } = useTranslation();
    const close = () => {
        setShowModal(false)
    }

    const saveAndClose = () => {
        setNote(noteText)
        setShowModal(false)
    }

    return (
        <IonModal
        className="PostRQAdditionalNote"
        isOpen={showModal}
        breakpoints={[0, 0.8]}
        initialBreakpoint={0.8}
        onIonModalDidDismiss={close}        
    >
        <IonContent>
            <div className="addItemContent">
                <div className="addItemTitle">
                    <p>{t("Additional Notes to Deliverer")}</p>
                </div>
                <div className="closeBtn">
                    <IonIcon icon={closeOutline} onClick={close} />
                </div>
                <div className="inputs-container">
                    <div className="inputs">
                        <div className="item-desc input-item">
                            <p className='input-label'>{t("Notes")}:</p>
                            <textarea value={noteText} onChange={(e) => setNoteText(e.target.value)} />
                        </div>
                    </div>
                    <div className="btnContainer">
                        <div className="addItemBtn" onClick={saveAndClose}>
                            <IonIcon icon={saveSharp} />
                            <p>{t("Save Note")}</p>
                        </div>
                    </div>
                </div>                
            </div>
        </IonContent>
    </IonModal>
    )
}

AdditionalNote.propTypes = {
}

export default AdditionalNote;
