import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IonIcon, IonAlert } from '@ionic/react';
import {chatbubbleEllipsesOutline, closeCircleOutline, walletOutline } from 'ionicons/icons';
import ConfirmDeliveryModal from '../../../layout/RQCard/Feed/RQInfoModal/ConfirmDeliveryModal/ConfirmDeliveryModal';
import { updateRooQuestWithoutCall, hideRooQuest, cancelRooQuestDelivery, getMyDeliveries, getRooQuestByID } from '../../../../actions/rooquest/rooquest';
import { AdditionalNotesCard, PickupDropoffCard } from '../RooQuestInformation';
import RooQuestChecklistItemsList from '../RQ/RooQuestItemsList/RooQuestChecklistItemsList';
import { useTranslation } from 'react-i18next';
import { requestMoreFunds } from '../../../../actions/payments/payments';
import { launchToastCard } from '../../../../actions/layout/toast';
const MyDeliveryInfo = ({ auth, rooquest, openChat, closeModal, cancelRooQuestDelivery, getMyDeliveries,requestMoreFunds,getRooQuestByID,launchToastCard }) => {

    const [mydeliv_showMarkAsOnTheWay, mydeliv_setShowMarkAsOnTheWay] = useState(false);
    const [mydeliv_showMarkAsDelivered, mydeliv_setShowMarkAsDelivered] = useState(false);
    const [mydeliv_showContactRequester, mydeliv_setShowContactRequester] = useState(false);
    const [mydeliv_showCancelDelivery, mydeliv_setShowCancelDelivery] = useState(false);
    const [showRequestFundsAlert, setShowRequestFundsAlert] = useState(false);

    const [ showConfirmationDeliveryModal, setShowConfirmationDeliveryModal, ] = useState(false);
    const openDeliveryConfirmationModal = () => { setShowConfirmationDeliveryModal(true); };
    
    const [showCancelDeliveryAlert, setShowCancelDeliveryAlert] = useState(false);

    const { t } = useTranslation();
    useEffect(() => {
        if (!rooquest) return;
        const status = rooquest.status;
        
        mydeliv_setShowMarkAsOnTheWay(
            status === 'RQ_ACCEPTED'
        )

        mydeliv_setShowMarkAsDelivered(
            status === 'RQ_ONTHEWAY'
        )

        mydeliv_setShowContactRequester(
            status === 'RQ_ACCEPTED' ||
            status === 'RQ_ONTHEWAY' ||
            status === 'ROOQUEST_DELIVERED'||
            status === 'UNDER_REVIEW'||
            status === 'ROOQUEST_WAITING_CLIENT_CONFIRMATION'
        )
        
        mydeliv_setShowCancelDelivery(
            status === 'RQ_ACCEPTED'
        )
    }, [rooquest]);

    

    const cancelDelivery = async (reason) => {
        await cancelRooQuestDelivery(rooquest._id, reason)
        updateRooQuestWithoutCall(null);
        await getMyDeliveries();
        closeModal()
    };

    return (
        <>
        
            <div className="actionBtns">
            {/* {mydeliv_showMarkAsOnTheWay && 
                <div className="deliverBtn" >
                        <p>{`Mark As On The Way`}</p>
                </div>} */}
                {/* {mydeliv_showMarkAsDelivered && <div className="deliverBtn" onClick={openDeliveryConfirmationModal}>
                        <p>{`Mark As Delivered`}</p>
                </div>} */}
                {mydeliv_showContactRequester && <div className="contactBtn" onClick={async () => openChat()}>
                    <IonIcon icon={chatbubbleEllipsesOutline} />
                </div>}  
                {mydeliv_showCancelDelivery && <div className="contactBtn" onClick={() => setShowCancelDeliveryAlert(true)}>
                    <IonIcon icon={closeCircleOutline} />
                </div>}
                {mydeliv_showCancelDelivery && <div className="contactBtn" onClick={() => setShowRequestFundsAlert(true)}>
                    <IonIcon icon={walletOutline} />
                </div>}   
            </div>
            <div className="mainContent">
                {/* <div className="rqinfo-section">
                    <p className="sectionTitle">RooQuest Status:</p>
                    <WhiteCard>
                        <div className="statusCard">
                            <p>{`Status: ${getStatusTextAndColour(rooquest.status, null, 'mydeliveries',auth.user.color_theme).text}`}</p>
                        </div>
                    </WhiteCard>
                </div> */}

                <RooQuestChecklistItemsList rooquest={rooquest} />
                {/* <PickupDropoffCard rooquest={rooquest} showFullAddress={true} /> */}
                <AdditionalNotesCard rooquest={rooquest} />
            </div>

            
            <ConfirmDeliveryModal
                showModal={showConfirmationDeliveryModal}
                setShowModal={setShowConfirmationDeliveryModal}
            />

            <IonAlert
                isOpen={showCancelDeliveryAlert}
                onDidDismiss={() => setShowCancelDeliveryAlert(false)}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Cancel Delivery')} ?`}
                message={
                    `${t('Are you sure you want to cancel the delivery? This will be kept on your deliverer record and can lead to account penalties.')}`
                }
                inputs={[ { name: 'cancellationReason', type: 'text', placeholder: `${t('Reason for cancellation')}` }, ]}
                buttons={[
                    { text: `${t('Close')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Cancel Delivery')}`,
                        handler: async (alertData) => {
                        await cancelDelivery(alertData.cancellationReason);
                        },
                    },
                ]}
            />

            <IonAlert
                isOpen={showRequestFundsAlert}
                onDidDismiss={() => setShowRequestFundsAlert(false)}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Need More Funds')} ?`}
                message={
                    `${t('How much would you want to request to complete the delivery ?')}`
                }
                inputs={[ { name: 'amount', type: 'number', placeholder: `${t('Amount Needed')}` }, ]}
                buttons={[
                    { text: `${t('Cancel')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Confirm')}`,
                        handler: async (alertData) => {
                            if(rooquest.delivererIsWaitingForFunds)
                            {
                                launchToastCard({
                                    type: 'error',
                                    msg: 'You already have a pending request for funds.',
                                    duration: 3000,
                                })
                            }
                            else{
                                await requestMoreFunds(rooquest._id,parseFloat(alertData.amount));
                                
                                await getRooQuestByID(rooquest._id);
                            }
                        },
                    },
                ]}
            />
        </>
    )
};

MyDeliveryInfo.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { cancelRooQuestDelivery, getMyDeliveries,requestMoreFunds,getRooQuestByID,launchToastCard })(MyDeliveryInfo);
