import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { IonContent, IonIcon, IonModal, IonSpinner } from '@ionic/react'
import { connect } from 'react-redux'
import { setSelectedTab, setShowEnlargeImageModal, setShowInfoModal, setShowLocationOnMapModal } from '../../../actions/layout/navigation'

import './RooQuestInformation.scss'
import WhiteCard from '../components/ui/WhiteCard'
import { chevronBack, chevronForward, mapOutline } from 'ionicons/icons'
import VerticalAddressComponent from '../components/VerticalAddressComponent'

import { getConversation, setShowChatModal } from '../../../actions/chat/chat'
import { cancelRooQuestDelivery, deleteRooQuestByID, deliverRooQuest, getMyDeliveries, getMyRooQuests, hideRooQuest, markRQAsDelivered, markRQAsOnTheWay, postDeliveryId, updateRooQuestWithoutCall } from '../../../actions/rooquest/rooquest'
import { launchToastCard } from '../../../actions/layout/toast'
import { acceptDeliveryOffer, rejectDeliveryOffer, rescindDeliveryOffer } from '../../../actions/rooquest/offers'
import PublicRooQuestInfo from './RQ/PublicRooQuestInfo'
import MyDeliveryInfo from './MyDeliv/MyDeliveryInfo'
import MyRooQuestDelivery, { DeliveryOnTheWay } from './RQ/MyRooQuestDelivery/MyRooQuestDelivery'
import { calculateCO2Savings, roundTwo } from '../PostNewRooQuest'
import { useTranslation } from 'react-i18next'
import DeliveryStepper from './RQ/MyRooQuestDelivery/DeliveryStepper'
import OnTheWayModal from '../../layout/RQCard/Feed/RQInfoModal/ConfirmOnTheWayModal/OnTheWayModal'

import { USE_GPS_TRACKING } from '../../../App'
import { uploadDeliveryImages, uploadReceiptImages } from '../../../actions/firebase/firebase-storage';
import { Geolocation } from '@capacitor/geolocation';
import DeliverySuccess from '../form_pages/DeliverySuccess'
import DeliveryModal from '../../layout/RQCard/Feed/RQInfoModal/ConfirmDeliveryModal/DeliveryModal'
import DeliveryTracking from './DeliveryTracking'
import StartingDelivery from './StarttingDelivery'
import { getTaxRate } from '../../../utils/getTaxRate'
import { GET_PLATFORM_FEES_AND_TAXES } from '../../../firebase'
const RooQuestInformation = ({ 
    rooquest, navigation, setShowInfoModal, setShowEnlargeImageModal, setShowLocationOnMapModal,
    getConversation, setShowChatModal, auth, updateRooQuestWithoutCall, 
    getMyDeliveries, getMyRooQuests,markRQAsOnTheWay,launchToastCard, markRQAsDelivered,postDeliveryId,cancelRooQuestDelivery,collectedtotalPrice
}) => {

    const { showRQModal: { visible, type } } = navigation;
    const { t } = useTranslation();
    const [ showConfirmationOnTheWayModal, setShowConfirmationOnTheWayModal, ] = useState(false);
    // Get Image
  const [receiptImages, setReceiptImages] = useState([]);
  const [step, setStep] = useState(0);
  const [title,setTitle] = useState('');
  const [driveDate, setDriveDate] = useState(null);
  const [amountPaid, setAmountPaid] = useState( 0 );
  const [didNotConfirmError, setDidNotConfirmError] = useState({
    error: false,
    style: null,
  });
  const [checked, setChecked] = useState(false);
  const [deliveryImages, setDeliveryImages] = useState([]);
  const [notes, setNotes] = useState('');
  const [disabledPrev, setDisabledPrev] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const [processing,setProcessing] = useState(false);
  const [trackDelivery, setTrackDelivery] = useState(false)
    const openOnTheWayConfirmationModal = () => { setShowConfirmationOnTheWayModal(true); };
    const [isOpen,setIsOpen] = useState(false);
    const [show,setShow] = useState(true);
    const modalRef = useRef(null);
    const [modalHeight,setModalHeight] = useState(0);
    const [stepContainerStyle,setStepContainerStyle] = useState({})

    useEffect(()=> {
        setIsOpen(true);

    },[])
    useEffect(()=> {
        if(processing)
        {
            setDisabledNext(true);
            setDisabledPrev(true);
        }
        else {
            setDisabledNext(false);
            setDisabledPrev(false);
        }
    },[processing])
    useEffect(() => {
        if (!rooquest) return;
        const status = rooquest.status;

        if (type === 'RQ' && (status === 'RQ_ACCEPTED' || status === 'RQ_ON_THE_WAY' || status === 'RQ_DELIVERED')) {
            if (rooquest.requester === auth.user._id) {
                // setShowInfoModal("MYRQ", true);
                console.log("my rooquest")
            }
            else {
                // setShowInfoModal("MYDELIV", true);
                console.log("my deliv")
            }
        }
        console.log('visible')
        if(type === 'MYDELIV' && visible) // if status === delivered nothing but congrats mmessage, if on the step = driver, if accepted  step = 0 etc..
        {
            console.log(`visible = ${visible}`)
            if(status === 'ROOQUEST_DELIVERED')
            {
                setStep(5);
                // setStep(0);
            }
            else if(status === 'RQ_ACCEPTED' && step !== 1)
            {
                setStep(0);
                // setStep(0);
            }
            else if(status === 'RQ_ONTHEWAY')
            {
                setStep(3);
                // setTrackDelivery(true);
                // setStep(0);
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rooquest, type])

    const postDataWithLatLong = async () => {
        console.log('post data with lat/lng')
        let geo = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        });
        console.log(`geo lat = ${geo.coords.latitude} ${geo.coords.longitude}`)
        let res = await postDeliveryId(rooquest._id,geo.coords.latitude,geo.coords.longitude);
        if (res) {
        console.log(res);
        }
    }

    const launchNavigator = (destination) => {
        // launchnavigator.navigate(destination);
        //  const x = new LaunchNavigator()
    }
    
    useEffect(()=> {
        let data;
        if (trackDelivery && USE_GPS_TRACKING) {
            console.log('start tracking')
            data = setInterval(function(){ 
                console.log("setinterval")
                postDataWithLatLong();
                }, 12000);
        }
        else if(!trackDelivery && USE_GPS_TRACKING )
        {
            console.log('clear intervall')
            clearInterval(data);
        }

        //cleanup function
        return () => {
            if(data)
                clearInterval(data);
        };
    },[trackDelivery])


    const handleOnTheWay = async () => {
        if (checked) {
            setProcessing(true);
            if (amountPaid <= parseFloat(rooquest.costs.totalToCapture)) 
            {
                const images = await uploadReceiptImages(receiptImages);
                const imagesString = images.toString();
        
                const rq = await markRQAsOnTheWay(
                rooquest._id,
                'RQ_ONTHEWAY',
                amountPaid,
                imagesString
                )
        
                if (!rq) { 
                return;
                }  
                    
                await updateRooQuestWithoutCall(rq)
                setTrackDelivery(true)
                setChecked(false);
                setDidNotConfirmError({error: false,style: null,});
            } 
            else 
            {
                launchToastCard({ 
                    msg: 'The amount paid should not be greater than the estimated cost of goods.',
                    type: 'error',
                    duration: 8500,
                })
                setChecked(false);
            }
            
            setProcessing(false)
        } else {
          setDidNotConfirmError({ error: true, style: { color: '#eb0000' } });
        }
    };
    
   useEffect(()=> {

    if(modalHeight && modalHeight !== 0)
    {
        const height = modalHeight*0.85; //95% of modal height
        setStepContainerStyle({
            height : `${height}px`
        })
    }
    else{
        setStepContainerStyle({})
    }

   },[modalHeight])


    useEffect(()=> {
        
        switch(step){
            case 0:setTitle(`${t('Rooquest Information')}`);
                    break;
            case 1:
                setModalHeight(modalRef.current.clientHeight);
                setTitle(`${t('Collect Items')}`)
                break;
            case 2:
                setTitle(`${t('Receipt Photo')}`);
                setModalHeight(0)
                break;
            case 3:
                setStepContainerStyle({
                    height : `100vh`
                })
                setTitle(`${t('Delivery Map')}`)
                break;
            case 4:
                setTitle(`${t('Confirm Delivery')}`);
                setStepContainerStyle({})
                break;
            default:
                setTitle(`${t('RooQuest Information')}`);
                
        }

        try{
            console.log("step just turneds")
            console.log(modalRef.current.clientHeight)
        }
        catch(err)
        {
            console.log(err)
        }
    },[step])
    

    const checkAllItemsCollected = () => {
        let count = 0;
        for (let i = 0; i < rooquest.items.length; i++) {
            if (rooquest.items[i].collectionInfo.collected) { count++; }
        }
        return (count === rooquest.items.length)

    }
    const next = () => {
        if( step === 2 && receiptImages.length === 0 )
        {
            //launch a toast notification when the user didn't provide at least one photo
            launchToastCard({ 
                msg: `${t('Please make sure you provided the photo of the receipt before proceeding.')}`,
                type: 'error',
                duration: 8500,
            })
        }
        else if(step === 2 && receiptImages.length !== 0){
            handleOnTheWay();
        }
        else if(step === 1)
        {
            if (!checkAllItemsCollected()) {
                console.log("launch toast card")
                launchToastCard({ 
                    msg: `${t('All items must be collected before proceeding')}`,
                    type: 'error',
                    duration: 8500,
                })
            }
            else{
                console.log("ici")
                setAmountPaid(collectedtotalPrice)
                setStep(step+1)
            }
        }
        else if(step === 4 && deliveryImages.length === 0)
        {
            launchToastCard({ 
                msg: `${t('Please make sure you provided the photo of the rooquest at the right location before proceeding.')}`,
                type: 'error',
                duration: 8500,
            })
        }
        else if(step === 4 && deliveryImages.length !== 0)
        {
            
            setProcessing(true);
            confirmDelivery();
        }
        else if(step === 5)
        {
            closeModal();
        }
        else if(step === 3)
        {
            setTrackDelivery(false);
            setStep(step+1)
        }
        else{
            setStep(step+1)
        }
    }

    const prev = () => {
        if (disabledPrev) return;
        setStep(step-1);
    }
    const closeModal = () => {
        if (type === 'MYRQ') {
            updateRooQuestWithoutCall(null);
            getMyRooQuests();
        }
        else if (type === 'MYDELIV') {
           //updateRooQuestWithoutCall(null);
            getMyDeliveries();
            setIsOpen(false)
            // //stop the tracking
            setTrackDelivery(false);
            setShow(false)
        }
        setShowInfoModal(type, false)
        console.log(visible)
    }

    useEffect(()=>{

        console.log("visible === false")
        if(!visible)
        {
            console.log('clearing arrays')
            setReceiptImages([]);
            setDeliveryImages([]);
            setNotes('');
        }
    },[visible]);

    const changeType = (type) => {
        setShowInfoModal(type, true);
    }
            

    const openChat = async () => {
        const rq = rooquest;
        let images = rq.imgURLS;
        let rqImage = (images.length > 0) ? images[0] : null;
        const res = await getConversation(
          rq._id,
          rq.title,
          rqImage,
          rq.requester,
          rq.requesterFirstName,
          (type === 'RQ') ? auth.user._id : rq.deliverer,
          (type === 'RQ') ? auth.user.firstName : rq.delivererFirstName
        );
        if (res) setShowChatModal(true);
      };

      // DeliveryModal functions
      const confirmDelivery = async () => {
        console.log(` checked = ${checked}`)
        if (checked) {
          const images = await uploadDeliveryImages(deliveryImages);
          const imagesString = images.toString();

          const delivererAmountPaid = parseFloat(rooquest.delivererAmountPaid)
        //   console.log(delivererAmountPaid)
          const costOfDeliverer = parseFloat(rooquest.costOfDeliverer);
          const value = rooquest.costs.fees.rooFee.base + (( delivererAmountPaid+ costOfDeliverer) * rooquest.costs.fees.rooFee.percentage)
        //   console.log(`rooFee.base = ${rooquest.costs.fees.rooFee.base} + ((totalCost = ${delivererAmountPaid} + delivererFee = ${costOfDeliverer}) * rooFee.percentage = ${ rooquest.costs.fees.rooFee.percentage}`)
          const newTotalRooFee = roundTwo(value);
          const config = await GET_PLATFORM_FEES_AND_TAXES();
          const provincialTaxes = JSON.parse(config.provincialTaxes);
          const newAmountTaxable = costOfDeliverer + newTotalRooFee;
            const taxObj = getTaxRate(rooquest.pickupAddress.administrative_area_level_1, provincialTaxes);
            let tax = roundTwo(newAmountTaxable * taxObj.rate);

            //amount in cents
          const newAmountToCapture = roundTwo((delivererAmountPaid + newTotalRooFee + costOfDeliverer + tax))*100;
        //   const receipt = {
        //     delivererAmountPaid,
        //     costOfDeliverer,
        //     newTotalRooFee,
        //     tax,
        //     newAmountToCapture
        // }
          console.log(newAmountToCapture.toFixed(2))
    
          const rq = await markRQAsDelivered(rooquest._id, notes,imagesString,newAmountToCapture)
    
          if (rq) {
            updateRooQuestWithoutCall(rq);
          }
    
          setTrackDelivery(false);
          setProcessing(false);
          setChecked(false)
          
        } else {
            setDidNotConfirmError({ error: true, style: { color: '#eb0000' } });
            setProcessing(false);
        }
      };

      const cancelDelivery = async (reason) => {
        await cancelRooQuestDelivery(rooquest._id, reason)
        updateRooQuestWithoutCall(null);
        await getMyDeliveries();
        closeModal()
    };

    return (
        rooquest && (
            // <OrderSummary 
            // rooquest={rooquest}
            // next = {next}
            // />
            <IonModal 
            mode="ios" 
            isOpen={visible} 
            class="RooQuestInformation"  
            onDidDismiss={() => closeModal()}
            >
                <IonContent>
                    <div className="modalContent">
                        <div className="header">
                            <div className="backBtn" onClick={closeModal}>
                                <IonIcon icon={chevronBack} />
                            </div>
                            <h1>{type === 'MYDELIV'? t(title):t('RooQuest Information')}</h1>
                        </div>

                        {type === 'RQ' && 
                            <PublicRooQuestInfo 
                                rooquest={rooquest} 
                                openChat={openChat} 
                            />
                        }
                        
                        {type === 'MYRQ' &&
                            <MyRooQuestDelivery
                                rooquest={rooquest} 
                                openChat={openChat} 
                                closeModal={closeModal} 
                                changeType={changeType}
                                next = {next}
                                show = {show}
                                setShow = {setShow}
                            />
                        }
                        
                        {console.log(`step = ${step}`)}
                        {type === 'MYDELIV' &&
                            <div ref = {modalRef} className={"MyRooQuestDelivery " + (step === 5 ? "MyRooQuestDeliveryCompleted":"")}>
                                <div className='stepContainer ' style = {stepContainerStyle}>
                                    <DeliveryStepper step={step} />
                                     {step === 0 && <StartingDelivery
                                                        rooquest = {rooquest}
                                                        t = {t}
                                                        next = {next}
                                                        launchNavigator = {launchNavigator}
                                                        openChat = {openChat}
                                                        closeModal = {closeModal}
                                                    />  }
                                     {step === 1 && <MyDeliveryInfo
                                                        rooquest={rooquest}
                                                        closeModal={closeModal} 
                                                        openChat={openChat}
                                                    />}   
                                    {step === 2 && <OnTheWayModal 
                                                    step = {step} 
                                                    checked = {checked}
                                                    setChecked = {setChecked}
                                                    receiptImages ={receiptImages} 
                                                    setReceiptImages = {setReceiptImages}
                                                    didNotConfirmError ={didNotConfirmError}
                                                    setDidNotConfirmError = {setDidNotConfirmError}
                                                    amountPaid = {amountPaid}
                                                    setAmountPaid = {setAmountPaid}
                                                    openChat = {openChat}
                                                    cancelDelivery = {cancelDelivery}
                                                    /> }

                                    {step === 3 && <DeliveryTracking 
                                                    rooquest={rooquest}
                                                    openChat = {openChat}
                                                    t = {t}
                                                    closeModal = {closeModal}
                                                    next = {next}
                                                    show = {show}
                                                    setShow = {setShow}
                                                    step = {step}
                                                    />}
                                    
                                    {step === 4 &&  <DeliveryModal 
                                                    setDidNotConfirmError = {setDidNotConfirmError}
                                                    didNotConfirmError = {didNotConfirmError}
                                                    deliveryImages = {deliveryImages}  
                                                    setDeliveryImages = {setDeliveryImages}
                                                    notes = {notes}
                                                    setNotes = {setNotes}
                                                    checked = {checked}
                                                    setChecked = {setChecked} 
                                                    openChat = {openChat}
                                                    closeModal = {closeModal}
                                                    />}

                                    {step === 5 &&  <DeliverySuccess
                                                        rooquest = {rooquest}
                                                        next = {next}
                                                    />}
                                </div>

                                <div className={`footer ${(step === 0|| step === 5 || step === 3) && 'rmBack'}`}>
                                    <div className={`backBtn controlBtn ${disabledPrev && 'disabled'}`} onClick={prev}>
                                        <IonIcon icon={chevronBack} />
                                        <p>{t('Back')}</p>
                                    </div>

                                    { 
                                        step === 5
                                            ? <></> 
                                            :
                                            processing ? 
                                            (
                                                <div className={`continueBtn controlBtn ${disabledNext && 'disabled'}`} >
                                                    <IonSpinner name="crescent" />
                                                </div>   
                                            ) :    
                                            
                                            (
                                                (step === 1 || step=== 2 || step ===4) && <div className={`continueBtn controlBtn ${disabledNext && 'disabled'}`} onClick={next}>
                                                    <p>{t('Continue')}</p> 
                                                    <IonIcon icon={chevronForward} />
                                                </div>
                                            )                            
                                    } 
                                </div>
                            </div>
                        
                            
                        }                      
                    </div>
                </IonContent>
          </IonModal>
        )
    );
}

RooQuestInformation.propTypes = {
    rooquest: PropTypes.object,
    navigation: PropTypes.object.isRequired,
    setShowInfoModal: PropTypes.func.isRequired,
    setShowEnlargeImageModal: PropTypes.func.isRequired,
    getConversation: PropTypes.func.isRequired,
    setShowChatModal: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
    deliverRooQuest: PropTypes.func.isRequired,
    hideRooQuest: PropTypes.func.isRequired,
    deleteRooQuestByID: PropTypes.func.isRequired,
    launchToastCard: PropTypes.func.isRequired,
    updateRooQuestWithoutCall: PropTypes.func.isRequired,
    cancelRooQuestDelivery: PropTypes.func.isRequired,
    getMyDeliveries: PropTypes.func.isRequired,
    getMyRooQuests: PropTypes.func.isRequired,
    rejectDeliveryOffer: PropTypes.func.isRequired,
    rescindDeliveryOffer: PropTypes.func.isRequired,
    acceptDeliveryOffer: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    navigation: state.navigation,
    rooquest: state.rooquest.rooquest,
    auth: state.auth,
    collectedtotalPrice: state.collectedtotalPrice
})

export default connect(mapStateToProps, 
    { 
        setShowInfoModal, 
        setShowEnlargeImageModal, 
        setShowLocationOnMapModal,
        setShowChatModal,
        getConversation,
        setSelectedTab,
        deliverRooQuest,
        hideRooQuest,
        deleteRooQuestByID,
        launchToastCard,
        updateRooQuestWithoutCall, 
        cancelRooQuestDelivery,
        getMyDeliveries,
        getMyRooQuests,
        rejectDeliveryOffer,
        rescindDeliveryOffer,
        acceptDeliveryOffer,
        markRQAsOnTheWay,
        markRQAsDelivered,
        postDeliveryId
    }
)(RooQuestInformation)


////////////////////////////////////////////////////

const PickupDropoffCardConnected = ({ rooquest, showFullAddress, setShowLocationOnMapModal }) => {

 
    const { t } = useTranslation();

    

    return (
        <div className="deliveryInfo">
            <p className="sectionTitle">{t('Pickup & Dropoff')}</p>
            <WhiteCard>
                <div className="addressContainer">
                    <VerticalAddressComponent 
                        pickupAddress={rooquest.pickupAddress} 
                        dropoffAddress={rooquest.deliveryAddress}
                        showFullAddress={showFullAddress}                                            
                    />
                </div>
                
                <div 
                    className="seeOnMapContainer"
                    onClick={() =>
                        setShowLocationOnMapModal(
                            true,
                            rooquest.pickupAddress,
                            rooquest.deliveryAddress,
                            showFullAddress
                        )
                    }
                >
                    <IonIcon icon={mapOutline} />
                    <p>{t("Open on map")}</p>
                </div>
            </WhiteCard>
        </div>
    )
}

export const PickupDropoffCard = connect(null, { setShowLocationOnMapModal })(PickupDropoffCardConnected)

export const AdditionalNotesCard = ({ rooquest }) => {
    const { t } = useTranslation();
    return (
        rooquest.additionalNote ? 
            <div className="rqinfo-section">
                <p className="sectionTitle">{t('Additional Notes')}:</p>
                <WhiteCard>
                    <div className="additionalNoteContainer">
                        <p>{rooquest.additionalNote}</p>
                    </div>
                </WhiteCard>
            </div> : null
        
    )
}