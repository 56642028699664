import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  EMAIL_LOGIN_SUCCESS,
  EMAIL_LOGIN_FAIL,
  LOGOUT,
  USER_LOADED,
  AUTH_ERROR,
  SOCIAL_LOGIN_FAIL,
  SOCIAL_LOGIN_SUCCESS,
  USER_LOADING,
} from '../actions/auth/auth-types';
import {
  SAVE_ROOQUEST,
  UNSAVE_ROOQUEST,
} from '../actions/rooquest/rooquest-types';
import {
  ADD_NEW_DELIVERY_ADDRESS,
  DELETE_DELIVERY_ADDRESS,
  UPDATE_THEME,
  UPDATE_LANGUAGE,
  UPDATE_UNIT_METHOD,
  UPDATE_THEMES,
  UPDATE_REQ_EMAIL_NOTIFICATION_METHOD,
  UPDATE_DEL_EMAIL_NOTIFICATION_METHOD,
  UPDATE_REQ_NOTIFICATION_METHOD,
  UPDATE_DEL_NOTIFICATION_METHOD,
  UPDATE_NEW_REQ_NOTIFICATION_METHOD,
  UPDATE_USER_MODE
} from '../actions/user/user-types';
import setAuthToken from '../utils/setAuthToken';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };

    case REGISTER_SUCCESS:
    case EMAIL_LOGIN_SUCCESS:
      setAuthToken(payload.token); // Set Headers
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload.user,
      };

    case SOCIAL_LOGIN_SUCCESS:
      setAuthToken(payload.token); // Set Headers
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload.user,
      };

    case AUTH_ERROR:
    case REGISTER_FAIL:
    case EMAIL_LOGIN_FAIL:
    case SOCIAL_LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        loading: false,
      };

    // USER ACTIONS
    case SAVE_ROOQUEST:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          savedRooQuests: [...state.user.savedRooQuests, payload],
        },
      };
    case UNSAVE_ROOQUEST:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          savedRooQuests: state.user.savedRooQuests.filter(
            (rqID) => rqID !== payload
          ),
        },
      };
      case UPDATE_UNIT_METHOD:
         return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          distanceUnits:payload
        },
      };
    case UPDATE_REQ_EMAIL_NOTIFICATION_METHOD:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          is_requester_email_send:payload
        },
      };
    case UPDATE_DEL_EMAIL_NOTIFICATION_METHOD:
    return {
      ...state,
      loading: false,
      user: {
        ...state.user,
        is_deliverer_email_send:payload
      },
    };
    case UPDATE_REQ_NOTIFICATION_METHOD:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          is_requester_notification_send:payload
        },
      };
    case UPDATE_DEL_NOTIFICATION_METHOD:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          is_deliverer_notification_send:payload
        },
      };
    case UPDATE_NEW_REQ_NOTIFICATION_METHOD:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          is_deliverer_show_new_rooquest:payload
        },
      };
    case ADD_NEW_DELIVERY_ADDRESS:
      return {
        ...state,
        user: {
          ...state.user,
          deliveryAddresses: [...state.user.deliveryAddresses, payload],
        },
        loading: false,
      };

    case DELETE_DELIVERY_ADDRESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          deliveryAddresses: state.user.deliveryAddresses.filter(
            (address) => address._id !== payload
          ),
        },
      };

    case UPDATE_THEME:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          preferences: {
            ...state.user.preferences,
            theme: payload,
          },
        },
      };

      case UPDATE_THEMES:
        return {
          ...state,
          loading: false,
          user: {
             ...state.user,
             color_theme: payload,
          },
        };
      case UPDATE_LANGUAGE:
        return {
          ...state,
          loading: false,
          user: {
            ...state.user,
            default_language:payload
          },
        };
        //test mode or live mode
        case UPDATE_USER_MODE:
          return {
            ...state,
            user: {
              ...state.user,
              mode : payload
            }
          };
    default:
      return state;
  }
}
