import React, { useState } from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonCol,IonButton } from '@ionic/react';
import GreenHeader from '../../layout/GreenHeader/GreenHeader';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { payForRooQuest_Stripe,refundAndPayForRooQuest_Stripe } from '../../../actions/payments/payments';
import './SingleItemPayment.scss';
import { GET_LOADED_COST_OF_GOODS_MULTIPLIER, GET_LOADED_KARGOROO_FEE } from '../../../firebase';
import { launchToast,launchToastCard } from '../../../actions/layout/toast';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const SingleItemPayment = ({
    rooquest,
    payForRooQuest_Stripe,
    refundAndPayForRooQuest_Stripe
}) => {
        const { t } = useTranslation();
        let myrooquest = rooquest.rooquest;
        if (!myrooquest) {
          myrooquest = {
              costOfGoods: 0,
              costOfDeliverer: 0
          }
      }
        let rqcostOfDeliverer = parseFloat(myrooquest.costOfDeliverer).toFixed(2)
        let costOfGoodsMultiplier = GET_LOADED_COST_OF_GOODS_MULTIPLIER();
        let amount = (Number(myrooquest.costOfGoods) * Number(costOfGoodsMultiplier)).toFixed(2);
        let delivcost = (Number(rqcostOfDeliverer) * Number(costOfGoodsMultiplier)).toFixed(2);
        let RooFee = GET_LOADED_KARGOROO_FEE();
        let roo = parseFloat(JSON.stringify(RooFee)).toFixed(2);
        let Totalcost = parseFloat(amount) + parseFloat(delivcost) + parseFloat(roo)

    const history = useHistory();
    const goToBack = () => {
        history.goBack();
    }
    const getStripePaymentAmount = () => {
      return (
        (parseFloat(amount) +
          parseFloat(delivcost) +
          parseFloat(roo)) *
        100
      );
    };
     // Payment Components
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#012e0b',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '19px',
        lineHeight:'1.429',
        '::placeholder': {
          color: '#7e858c',
          lineHeight:'1.429'
        },
      },
      invalid: {
        color: '#f2311f',
        iconColor: '#f2311f',
      },
    },
    hidePostalCode: true,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = useState('ready');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState([]);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const payForRooQuest = async () => {
    setStatus('loading');
    let amountdata  =  getStripePaymentAmount();
    let finalamount = parseFloat(JSON.stringify(amountdata)).toFixed(0);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        address: {
          line1: myrooquest.deliveryAddress.streetAddress,
          city: myrooquest.deliveryAddress.city,
          postal_code: myrooquest.deliveryAddress.postal,
          state: myrooquest.deliveryAddress.province,
        },
      },
    });

    if (error) {
      setStatus('ready');
      // dispatch(launchToast(error.message, 3500, 'bottom'));
      dispatch(launchToastCard({ 
        msg: error.message,
        type: 'error',
        duration: 8500,
    }))
    } else {
      const { id } = paymentMethod;
      if(!!myrooquest.stripePaymentIntentID){
        const res = await refundAndPayForRooQuest_Stripe(
          rooquest.rooquest,
          id,
          finalamount,
          RooFee,
          costOfGoodsMultiplier,
        );
          
        if (res && res.type === 'success') {
          setStatus('success');
          history.push('./singleitemconfirm');
        } else {
          setErrors([
            {
              type: res ? _.get(res, 'type', 'error') : 'error',
              msg: res ? _.get(res, 'msg', '') : 'Request Error',
            },
          ]);
          setStatus('error');
          dispatch(launchToast(res ? _.get(res, 'msg', 'messsage') : 'Rooquest Error. Please Try again', 3500, 'bottom'));
        }

      } else {
        
      const res = await payForRooQuest_Stripe(
        rooquest.rooquest,
        id,
        finalamount,
        RooFee,
        costOfGoodsMultiplier
      );

      console.log(res);
        
      if (res.type === 'success') {
        setStatus('success');
        history.push('./singleitemconfirm');
      } else {
        setErrors([
          {
            type: res.type,
            msg: res.msg,
          },
        ]);
        setStatus('error');
      }
      }

    }
  };

    return (
        <IonPage className="singleitempayment">
            <IonContent>
                <GreenHeader />
                <div className="FullQuest">
                    <div className="questcontainer">
                        <div>
                            <p className="questTitle questLeft">{t('Single Item Quest')}</p>
                            <p className="questTitle questRight">{t('Step')} 4 / 4</p>
                        </div>
                        <div className="fullInnerContainer">
                            <div className="questBodyContent">
                                <div className="questHeaderReviewText">
                                    <p className="questheadertext">{t('Please complete your')}</p>
                                    <p className="questheadertext">{t('payment details')}</p>
                                </div>
                                 <IonGrid className="questcardpadding">
                                   <IonRow>
                                        <IonCol size="12">
                                            <p className="singlepaymentlabel">
                                            {t('Roo Will charge your card with a pre-authorized payment that will only be charged once your item has picked up by the deliver.')}
                                    </p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('Item Cost')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                            <p className="singlereviewrighttext">
                                                <span className="percentagetext">${parseFloat(myrooquest.costOfGoods).toFixed(2)} x {costOfGoodsMultiplier}  = </span>${amount}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('Deliverer Cost')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                        <p className="singlereviewrighttext"><span className="percentagetext">${parseFloat(rqcostOfDeliverer).toFixed(2)} x {costOfGoodsMultiplier} = </span>${delivcost}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('ROO Platform Fee')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                            <p className="singlereviewrighttext">${RooFee.toFixed(2)}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="singlereviewtotalbg">
                                        <IonCol size="5" className="singleTotalpaddingnone">
                                            <p className="singlereviewtotalbtn">{t('Total')}</p>
                                        </IonCol>
                                        <IonCol size="7">
                                            <p className="singlereviewrighttext">${Totalcost.toFixed(2)}</p>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid> 
                                    <div className="cardElementContainer singleitempaymentcard">
                                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                                    </div>
                               
                            </div>
                            <div className="bottombuttonSingleItem">
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6" text-center>
                                <IonButton
                            className="prevbtn"
                            color="prevgrey"
                            onClick={() => goToBack()}
                            >
                            {t('Go Back')}
                            </IonButton>
                            
                                </IonCol>
                                <IonCol size="6" text-center>
                                <IonButton
                            className="nextbtn"
                            color="kargoroo_green"
                            onClick={() => payForRooQuest()}
                            >
                            {t('Pay Now')}
                            </IonButton>
                                </IonCol>
                                </IonRow>
                                </IonGrid> 
                                </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
SingleItemPayment.propTypes = {
    rooquest: PropTypes.object.isRequired,
    payForRooQuest_Stripe: PropTypes.func.isRequired,
    refundAndPayForRooQuest_Stripe:PropTypes.func.isRequired
 };
const mapStateToProps = (state) => ({
   rooquest: state.rooquest
 });
export default connect(mapStateToProps,{
    payForRooQuest_Stripe,
    refundAndPayForRooQuest_Stripe
}) (SingleItemPayment);
