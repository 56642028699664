import React, { useState, useRef, Fragment, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  useIonViewDidEnter,
  useIonViewWillEnter,
  IonMenuButton,
  IonMenuToggle,
  useIonViewWillLeave,
  IonModal,
  IonBadge,
} from '@ionic/react';
import './Landing.scss';
import Navbar from '../../components/layout/Navbar/Navbar';
import { connect } from 'react-redux';
import { menuController } from '@ionic/core';

import PropTypes from 'prop-types';
import { loadUser } from '../../actions/auth/auth';
import FeedMap from '../../components/feed/map/FeedMap';
import ModalCard from '../../components/layout/Modal/ModalCard';
import { exitOutline, notificationsOutline } from 'ionicons/icons';
import BottomDrawer from '../../components/home/BottomDrawer/BottomDrawer';
import MyRooQuestsPopover from './MyRooQuestsPopover/MyRooQuestsPopover';
import MyDeliveriesPopover from './MyDeliveriesPopover/MyDeliveriesPopover';
import MessagesPopover from './MessagesPopover/MessagesPopover';
import { getAllRooQuests,getConfirmRooquest, getMyDeliveries, getMyRooQuests } from '../../actions/rooquest/rooquest';
import BuyerConfirmDeliveryModal from '../.././components/layout/RQCard/Feed/RQInfoModal/BuyerConfirmDeliveryModal/BuyerConfirmDeliveryModal';
import {updateRooQuestWithoutCall,getRooQuestByID } from '../../actions/rooquest/rooquest';

import {
  setSelectedTab,
  setShowNotifModal,
  setShowPostRQModal,
  setShowTutorialModal,
} from '../../actions/layout/navigation';
// import { Plugins } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { useTranslation } from 'react-i18next';
import { getAllNotifications } from '../../actions/notificationmenu/notificationmenu';
import { PushNotifications } from '@capacitor/push-notifications';
import { setShowInfoModal } from '../../actions/layout/navigation';
import { Device } from '@capacitor/device';
import LoaderContainer from './LoaderContainer';
import { setIsLoading } from '../../actions/loading/loading';
import { launchToastCard } from '../../actions/layout/toast';
import { getAllConversations } from '../../actions/chat/chat';
import { compareDates } from '../../utils/formatDate';
import { updateUserLocation } from '../../actions/notificationrange/notificationrange';
// const { Geolocation } = Plugins;

const Landing = ({
  auth,
  rooquest,
  getAllRooQuests,
  navigation,
  setSelectedTab,
  getConfirmRooquest,
  chat,
  setShowPostRQModal,
  filters,
  setShowTutorialModal,
  history,
  getAllNotifications,
  notificationmenu,
  updateRooQuestWithoutCall,
  getRooQuestByID,
  setShowInfoModal,
  getMyDeliveries,
  getMyRooQuests,
  setIsLoading,
  launchToastCard,
  getAllConversations,
  updateUserLocation,
  setShowNotifModal
}) => {
  const { t, i18n } = useTranslation();
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return i18n.t('Good Morning');
    else if (hour < 17) return i18n.t('Good Afternoon');
    else return i18n.t('Good Evening');
  };
  const [
    showBuyerConfirmationDeliveryModal,
    setBuyerShowConfirmationDeliveryModal,
  ] = useState(false);
  const [rooquestid, setrooquestid] = useState('');
  const [rooquestname, setrooquestname] = useState('');

  const [showCloseModal, setShowCloseModal] = useState(false);
  
  const [position,setPosition] = useState({ lat: 0,lng: 0 })

  const goToQuest = () => {
    // history.push('/questreq')
    updateRooQuestWithoutCall(null);
    history.push('/postrooquest')
    // history.push('/singleitemquest/new')
    
  }

  const goToDeliveryPage = () => {
    updateRooQuestWithoutCall(null);
    history.push('/delivernow')

  }
  const logoRef = useRef(null);
  const rotateLogo = () => {
    console.log('click');
    logoRef.current.classList.add('rotate');
    setTimeout(() => logoRef.current.classList.remove('rotate'), 1000);
  };

  const openMenu = async () => {    
    await menuController.open('leftMenu');    
  };

  const openNotifications = async () => {
    await menuController.open('notificationMenu');
  };

  // const openNotifications = async () => {
  //   setShowNotifModal(true);

  // }
  const landingRef = useRef(null);
  const delivererFeedContentRef = useRef(null);
  // const toggleView = () => {
  //   landingRef.current.classList.toggle('hidden');
  //   delivererFeedContentRef.current.classList.toggle('hidden');
  // };

  useEffect(() => {
    
    // Open Tutorial If First Time
    if (auth && auth.user && !auth.user.wasIntroScreenShown) {
      setShowTutorialModal(true, { showActivationCode: true });
    }

    if(auth && auth.user)
    {
      
      setIsLoading(true)
      getAllNotifications();
      getMyDeliveries();
      getMyRooQuests();
      
    
    const asyncGetConvos = async () => { 
      await getAllConversations(auth.user._id);
      
    let latitude = auth.user.loc.coordinates[1]
    let longitude =  auth.user.loc.coordinates[0];
    if(compareDates(auth.user.geolocationPosition.timestamp)) //if the last position we got was 30 min ago take another position else use the last known position
    {
      try{
        let geo = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
        });
        latitude = geo.coords.latitude;
        longitude = geo.coords.longitude;
        await getAllRooQuests(
          filters.rooquestSearchRadius,
          latitude,
          longitude
        );
        await updateUserLocation(latitude,longitude,geo);

         
        setPosition({lat:latitude,lng:longitude});
      }
      catch(err)
      {
        await getAllRooQuests();
        setIsLoading(false);
        launchToastCard({
          msg: 'Location Disabled. Please Enable Location',
          type: 'error',
          duration: 8500,
        })
        console.error(err);
      }
    }
    else{
      
      await getAllRooQuests(
        filters.rooquestSearchRadius,
        latitude,
        longitude
      );
      setPosition({lat:latitude,lng:longitude});
    };
  }
  
    asyncGetConvos();
    }
    if(!!auth && !!auth.user && !!auth.user.default_language){
      i18n.changeLanguage(auth.user.default_language);
  }
    if(!!auth && !!auth.user && !!auth.user.color_theme){
      document.body.classList.value = auth.user.color_theme;
  }
  }, [auth, setShowTutorialModal, i18n]);

  useIonViewWillEnter(async () => {
   
    if (window.location.search.includes('?myrooquests')) {
      setSelectedTab('MyRooQuests');
    }
    else if(window.location.search.includes('?stripe-redirect=success')) {
      setShowCloseModal(true)
    }
    else {
      setSelectedTab('Home');
      
    }
    // remove the query string
    window.history.replaceState({}, document.title, window.location.pathname);
    
    let device = await Device.getInfo();

    if (device.platform === 'ios' || device.platform === 'android') {
    // PushNotifications.addListener('pushNotificationActionPerformed',
    // async (notification) => {
    //   // console.log('Push action performed: ' + JSON.stringify(notification));
    //   // console.log('Push action rqid: ' + (notification.notification.data.rooquest));
    //   if (notification.notification.data.type == "ROOQUEST"){
    //     let roodata = await getRooQuestByID(notification.notification.data.rooquest);
    //     console.log("rooreqdata",JSON.stringify(roodata))
    //     updateRooQuestWithoutCall(roodata);
    //     setShowInfoModal('MYRQ', true);
    //   }
    //   if(notification.notification.data.type == "DELIVER"){
    //     let roodata = await getRooQuestByID(notification.notification.data.rooquest);
    //     console.log("roodeldata",JSON.stringify(roodata))
    //     updateRooQuestWithoutCall(roodata);
    //     setShowInfoModal('MYDELIV', true);
    //   }
    // });
    // return () => {
    //   PushNotifications.removeAllListeners()
    // }
  }
  })

  useIonViewWillLeave(() => {
    setSelectedTab(null)
  })

  useIonViewDidEnter(async () => {
    console.log("did enter")
      
    const confirmRequestData = await getConfirmRooquest();
   
    if(!!confirmRequestData && confirmRequestData.length > 0){
      confirmRequestData.forEach(element => {
        if(element){
          setBuyerShowConfirmationDeliveryModal(true);
          setrooquestid(element._id);
          setrooquestname(element.title)
        }
      });
    }
    if(!!confirmRequestData&& confirmRequestData.length <= 0){
      // try {
      //   let geo = await Geolocation.getCurrentPosition({
      //     enableHighAccuracy: true,
      //     timeout: 20000
      //   });
      //   setPosition({lat:geo.coords.latitude,
      //     lng:geo.coords.longitude})
      //   await getAllRooQuests(
      //     filters.rooquestSearchRadius,
      //     geo.coords.latitude,
      //     geo.coords.longitude
      //   );
      //   // await updateUserLocation(geo.coords.latitude,geo.coords.longitude,auth.user._id);
      //   // console.log("test")
      // } catch (err) {
      //   await getAllRooQuests();
      //   console.error(err);
        
      // }
    }
  });


  const { selectedTab } = navigation;

  const [homeView, setHomeView] = useState('landing');
  const [mapState, setMapState] = useState('landing');

  const checkIfUnreadNotifications = () => {
    if (notificationmenu && !notificationmenu.loading && notificationmenu.notifications.length > 0) {
      for (let i = 0; i < notificationmenu.notifications.length; i++) {
        if (!notificationmenu.notifications[i].viewed) return true
      }
    }    
    return false;    
  }

  const countUnreadNotifications = () => {
    let result = 0;
    if (notificationmenu && !notificationmenu.loading && notificationmenu.notifications.length > 0) {
      for (let i = 0; i < notificationmenu.notifications.length; i++) {
        if (!notificationmenu.notifications[i].viewed) 
          result++;
      }
    }    
    return result; 
  }

  return (
    <div className="landingPage">
      <IonPage>
        <LoaderContainer />
        <IonContent forceOverscroll={true}>
          <div className="landingHeader">
            {/* <div className="menuButton" onClick={async () => await openMenu()}>
              <IonIcon icon={menuOutline}></IonIcon>
            </div> */}
            <IonMenuButton className="menuButton" menu='leftMenu'></IonMenuButton>

            <img
              ref={logoRef}
              id="logo"
              src={require('./logo.png')}
              alt=""
              onClick={() => rotateLogo()}
            />

            <IonMenuToggle>
            <div
              className="plusButton"
              // onClick={() => goToQuest()}
              onClick={() => {
                openNotifications()
              }}
              
            >
              <IonIcon icon={notificationsOutline}></IonIcon>
              {checkIfUnreadNotifications() &&  <IonBadge className='notif_unreadBadge' color="danger">{countUnreadNotifications()}</IonBadge>}
            </div>
            </IonMenuToggle>
            
          </div>

          {selectedTab === 'Home' && (
            <Fragment>
              {homeView === 'landing' && (
                <div className="landingContent" ref={landingRef}>
                  {/* {auth.user && <RooQuestItems />} */}
                  {auth.user && (
                    <div className="actionCardContainer">
                      <ModalCard
                        title={`${getGreeting()}${auth.user.firstName.split(" ")[0]}`}
                      >
                        <div className="updateContainer">
                          {/* <div className="text">
                            <p>12 Deliverers ready to deliver</p>
                            <p>to you in your area</p>
                          </div> */}
                        </div>
                        <div className="updateContainer">
                          <div className="text">
                            <p>
                              {rooquest.rooquests.length} {t('RooQuests ready to be')}{' '}
                            </p>
                            <p>{t('delivered in your area')}</p>
                          </div>
                        </div>
                        <div className="actionButtonContainer">
                          <IonButton 
                          //onClick={() => setShowPostRQModal(true)}
                          onClick={() => {
                            goToQuest()
                          }}
                          color="kargoroo_green"
                          >
                            <div className="buttonText">
                              {/* <h5>Need a</h5>
                              <h5>RooQuest</h5> */}
                              <h5>{t('ORDER')}</h5>
                              {/* <h5>{t('NOW')}</h5> */}
                            </div>
                          </IonButton>
                          <IonButton
                            onClick={() => {
                              goToDeliveryPage();
                            }}
                            color="kargoroo_green"
                          >
                            <div className="buttonText">
                              {/* <h5>Deliver a</h5>
                              <h5>RooQuest</h5> */}
                              <h5>{t('DELIVER')}</h5>
                              {/* <h5>{t('NOW')}</h5> */}
                            </div>
                          </IonButton>
                        </div>
                      </ModalCard>
                    </div>
                  )}
                </div>
              )}
              {homeView === 'deliver' && (
                <div className="delivererFeedContainer">
                  <BottomDrawer
                    setHomeView={setHomeView}
                    showRef={delivererFeedContentRef}
                    setMapState={setMapState}
                  />
                </div>
              )}
            </Fragment>
          )}

          {selectedTab === 'MyRooQuests' && <MyRooQuestsPopover />}
          {selectedTab === 'MyDeliveries' && <MyDeliveriesPopover />}
          {selectedTab === 'Messages' && <MessagesPopover />}

          <FeedMap
            rooquests={rooquest.rooquests}
            auth = {auth}
            showZoom={false}
            state={mapState}
            sendDataToParent={null}
            dataParentToChild = {null}
            position = {position}
            setPosition = {setPosition}
          />
        </IonContent>
        {/* <IonButton onClick={() => toggleView()}>Toggle</IonButton> */}

        <Navbar selected={selectedTab} setSelected={setSelectedTab} setHome={() => {
          setHomeView('landing');
          setMapState('landing');
        }} />
        <BuyerConfirmDeliveryModal
                    showModal={showBuyerConfirmationDeliveryModal}
                    setShowModal={setBuyerShowConfirmationDeliveryModal}
                     rooquestId = {rooquestid}
                     rooquestName = {rooquestname}
                  />
      </IonPage>
      <IonModal
        mode="ios" 
        isOpen={showCloseModal} 
        class="CloseWindow">
        <IonContent>
              <div className="content">
                <IonIcon icon={exitOutline} />
                <h1>You may now close this window</h1>
              </div>
        </IonContent>

      </IonModal>
    </div>
  );
};

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  loadUser: PropTypes.func.isRequired,
  getAllRooQuests: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  setShowPostRQModal: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setShowTutorialModal: PropTypes.func.isRequired,
  getConfirmRooquest:PropTypes.func.isRequired,
  notificationmenu: PropTypes.object.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  setShowInfoModal: PropTypes.func.isRequired,
  getRooQuestByID: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  chat: state.chat,
  rooquest: state.rooquest,
  navigation: state.navigation,
  filters: state.feedFilterBar.filters,
  notificationmenu: state.notificationmenu,
});

export default connect(mapStateToProps, {
  loadUser,
  getAllRooQuests,
  setSelectedTab,
  setShowPostRQModal,
  setShowTutorialModal,
  getConfirmRooquest,
  getAllNotifications,
  updateRooQuestWithoutCall,
  setShowInfoModal,
  getRooQuestByID,
  getMyDeliveries,
  getMyRooQuests,
  setIsLoading,
  getAllConversations,
  launchToastCard,
  updateUserLocation,
  setShowNotifModal
})(Landing);
