import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IonInput, IonItem, IonLabel } from '@ionic/react';

import './GoogleAddressAutoComplete.scss';
// import { Plugins } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// const { Geolocation } = Plugins

const GoogleAddressAutoComplete = ({
  address,
  setAddress,
  addLabel,
  addressError,
  setAddressError,
  dupaddressError
}) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const [input, setInput] = useState('');

  const defaultPickupAddressState = {
    streetAddress: '',
    city: '',
    postal: '',
    province: '',
    country: '',
    lat: '',
    lng: '',
  };
  useEffect(() => {
    const onAddressChange = (addressObj) => {
      console.log(addressObj);
      const getField = (addressArr, fieldName) => {
        for (let i = 0; i < addressArr.length; i++) {
          if (addressArr[i].types[0] === fieldName)
            return addressArr[i].long_name;
        }
        return '';
      };
      const addressArr = addressObj.address_components;
      console.log(addressArr);
      setAddress({
        streetAddress: `${getField(addressArr, 'street_number')} ${getField(
          addressArr,
          'route'
        )}`,
        city: getField(addressArr, 'locality'),
        postal: getField(addressArr, 'postal_code'),
        province: getField(addressArr, 'administrative_area_level_1'),
        country: getField(addressArr, 'country'),
        lat: addressObj.geometry.location.lat(),
        lng: addressObj.geometry.location.lng(),
      });
    };

    // Init Google Address AutoComplete
    const initAutoComplete = async () => {
      let initialAdress = _.get(address, 'streetAddress', '') + (address.streetAddress && _.trim(address.streetAddress).length ? ', ' : '')
          + _.get(address, 'city', '') + (address.city ? ', ' : '') + _.get(address, 'province', '') + (address.province ? ', ' : '') + address.country;
          setInput(initialAdress);
      const innerInputElement = await inputRef.current.getInputElement();

      const GoogleMapsAutoCompleteObj = new google.maps.places.Autocomplete(
        innerInputElement,
        {
          types: ['geocode'],
          fields: ['address_component', 'geometry'],
        }
      );
      GoogleMapsAutoCompleteObj.addListener('place_changed', () => {
        console.log('place_changed');
        const location = GoogleMapsAutoCompleteObj.getPlace();
        onAddressChange(location);
      });

      // Set Bias For Google Address AutoComplete
      const setBiasToAutoComplete = async () => {
        let coords = {
          lat: 45.50884,
          lng: -73.58781,
        };
        let radius = 10000;
        try {
          const geolocation = await Geolocation.getCurrentPosition();
          coords = {
            lat: geolocation.coords.latitude,
            lng: geolocation.coords.longitude,
          };
          radius = geolocation.coords.accuracy / 100;
        } catch (err) {
          console.error(err);
        }
        const circle = new google.maps.Circle({
          center: coords,
          radius: radius,
        });
        GoogleMapsAutoCompleteObj.setBounds(circle.getBounds());
      };
      await setBiasToAutoComplete();

      // Add Listener on Changes
      
    };
    
    if (inputRef !== null) {
      initAutoComplete();
    }
    else {
      let initialAdress = _.get(address, 'streetAddress', '') + (address.streetAddress && _.trim(address.streetAddress).length ? ', ' : '')
          + _.get(address, 'city', '') + (address.city ? ', ' : '') + _.get(address, 'province', '') + (address.province ? ', ' : '') + address.country;
          setInput(initialAdress);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAddress]);

  return (
    <div className="GoogleAddressAutoCompleteContainer">
      <IonItem className="GAAC_input inputField" mode="ios">
        {addLabel && <IonLabel position="stacked">{t('Address')}:</IonLabel>}
        {addressError && (
          <p className="formErrorMsg">Please enter a valid address</p>
        )}
        {dupaddressError&& (
          <p className="formErrorMsg">This address is already exists,please enter another address</p>
        ) }
        <IonInput
          ref={inputRef}
          className="googleAutoCompleteInput"
          placeholder="Start Typing Address..."
          value={input}
          autocomplete="off"
          onIonChange={(e) => {
            if (setAddressError) setAddressError(false);
            setInput(e.detail.value!);
            if (!e.detail.value || _.trim(e.detail.value) === '') {
              setAddress(defaultPickupAddressState); // Disable as it makes address input empty without changing while moving back/next within screens - DaisyB
            }
          }}
        ></IonInput>
      </IonItem>
    </div>
  );
};

GoogleAddressAutoComplete.propTypes = {
  address: PropTypes.object.isRequired,
  setAddress: PropTypes.func.isRequired,
  addressError: PropTypes.bool,
  setAddressError: PropTypes.func,
  addLabel: PropTypes.bool,
};

export default GoogleAddressAutoComplete;
