import { DirectionsRenderer, GoogleMap, Marker } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import './MapInstance.scss'
import { personCircleOutline } from "ionicons/icons";
import MapOverlay from "./MapOverlay";
import useWindowDimensions from "../../../utils/useWindowDimensions";

/*
driver position and delivery address are used when tracking delivery, otherwise there are null
*/
const MapInstance = ({center,
    zoom,
    markers,
    driverPosition,
    deliveryDestination,
    showPickupAndDropoff = false,
    pickupLocation,
    dropoffLocation,
    onMarkerLoad,
    options,
    showLabels = false,
    enableDirections = false,
    rooquest,
    next = null,
    navigator = null,
    openChat = null,
    closeModal = null,
    showMapOverLay = false,
    markerRef = null,
    mapRef = null,
    isClient = false
}) => {

    
    const [directions,setDirections] = useState(null);
    const [overlayHeight,setOverlayHeight] = useState(null);
    const { height, width } = useWindowDimensions();
    const overlayRef = useRef(null);

  

    // const mapRef = useRef(null)
    const onMapLoad = (map) => {
        console.log("use callback")
        mapRef.current = map;    
        // fitBounds(driverPosition,deliveryDestination);
    };

    const isValid = (position) => {
        return (position.lat !==0 && position.lng !==0);
    }

    useEffect(()=>{
        console.log(driverPosition)
        if(!driverPosition || !isValid(driverPosition))
            return;
        fitBounds(driverPosition,deliveryDestination)

    },[driverPosition])

    useEffect(() => {
        // console.log(!!overlayRef)
        try{
            // console.log(overlayRef.current)
        }
        catch(err)
        {
            console.log(err)
        }
        if(!overlayRef || !overlayRef.current)
            return;
        setOverlayHeight(overlayRef.current.clientHeight)
    })

    useEffect(()=>{
        if(!overlayHeight)
            return;
        setTimeout(()=>{
            console.log("panTo")
            let bound = new google.maps.LatLngBounds();
            bound.extend(pickupLocation)
            bound.extend(dropoffLocation);
            console.log(pickupLocation)
            console.log(dropoffLocation)
            console.log(`overlayHeight = ${overlayHeight}`)
            mapRef.current.fitBounds(bound,{top:25,bottom:overlayHeight+25,left:25,right:25})
        },700)

    },[overlayHeight])
    useEffect(()=>{
        if(!enableDirections)
            return;
        
        const service = new google.maps.DirectionsService();
        
        service.route(
            {
                origin: pickupLocation,
                destination: dropoffLocation,
                travelMode: google.maps.TravelMode.DRIVING
            },
            (result,status) => {
                if(status === "OK" && result){
                    setDirections(result);
                }
                else{
                    // fitBounds(pickupLocation,dropoffLocation)
                }
            }
        );
    },[]);


    const fitBounds = (firstMarker, secondMarker) => {
        // console.log("fitbounnds")
        let bound = new google.maps.LatLngBounds();
        bound.extend(firstMarker)
        bound.extend(secondMarker);
        let modalHeight = height*0.33;
        if(isClient)
        {
            modalHeight = 0;
        }
        // console.log("fitbounds")
        // console.log(`modal Height = ${modalHeight}`)
        setTimeout(()=>{
            mapRef.current.fitBounds(bound,{left:25,right:25,bottom:modalHeight,top:25})
        },500)
        // if(!isClient)
        // {
        //     setTimeout(() => {
        //         console.log("set timeout")
        //         mapRef.current.panTo(markerRef.current.getPosition());
                
        //     }, 5000);
        // }
     }
    
    return(
        <div className="mapContainer">
            <GoogleMap 
                center={center}
                zoom = {8}
                mapContainerClassName = "map"
                onLoad={onMapLoad}
                options = {options}
            >
                {markers && markers.length > 0 && markers.map((marker) =>{
                    return <Marker position={marker}/>
                })}

                {driverPosition && <Marker 
                                        position={driverPosition} 
                                        icon={{url : require('./location-pin-blk.png'),
                                        scaledSize:  new google.maps.Size(40,50)
                                    }}
                                        onLoad = {onMarkerLoad}
                                    />
                }

                { deliveryDestination && <Marker position={deliveryDestination} 
                                                icon={{url : personCircleOutline,scaledSize:  new google.maps.Size(40,50)}}  
                                        /> }

                { (showPickupAndDropoff || !directions) && pickupLocation && <Marker position={pickupLocation}
                                            label = {"A"} 
                                    /> }

                { (showPickupAndDropoff || !directions) && dropoffLocation && <Marker position={dropoffLocation}
                                            label = {"B"}
                                    /> }

                {
                    enableDirections && directions &&  <DirectionsRenderer directions={directions}
                                                            options={{polylineOptions : {
                                                                zIndex:50,
                                                                strokeColor: "#1976D2",
                                                                strokeWeight:5,
                                                            }}} 
                                        />
                }

                {
                    showMapOverLay &&  <MapOverlay rooquest={rooquest} navigator = {navigator} next = {next} openChat = {openChat} closeModal = {closeModal} overlayRef={overlayRef} /> 
                }

            </GoogleMap>

        </div>
    );
}

export default MapInstance;