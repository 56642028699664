import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonModal,
  IonButton,
  IonItem,
  IonLabel,
  IonTextarea,
  IonList,
  IonRadioGroup,
  IonRadio
} from '@ionic/react';

import './BuyerConfirmDeliveryModal.scss';
import { connect } from 'react-redux';
import {
  updateRooQuestWithoutCall,
  markDeliveryComplete,
  sendmailrooquestIssue
} from '../../../../../../actions/rooquest/rooquest';
import { setShowEnlargeImageModal } from '../../../../../../actions/layout/navigation';
import { useHistory } from 'react-router-dom';
const BuyerConfirmDeliveryModal = ({
  rooquest,
  showModal,
  setShowModal,
  rooquestId,
  rooquestName,
  markDeliveryComplete,
  sendmailrooquestIssue,
  updateRooQuestWithoutCall,
}) => {
  const [selected, setSelected] = useState<string>('');
  const [titleError, setTitleError] = useState(false);
  const [notes, setNotes] = useState('');
  const [confirmselectError, setconfirmselectError] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (!!selected) {
      setconfirmselectError(false);
    }
    if(!!selected && selected === "delsuccess"){
      setTitleError(false);
    }
  }, [selected]);
  const confirmDelivery = async () => {
    if (selected === "delsuccess") {
     const deliverysuccess =  await markDeliveryComplete(rooquestId)
        if(deliverysuccess){
          await updateRooQuestWithoutCall(deliverysuccess);
          setShowModal(false);
          history.push('/home');
        }
    }
    if(selected === "delnotsuccess"){
      if (!notes || notes.length < 5){
        setTitleError(true);
      } else {
        const emailsend = await sendmailrooquestIssue(rooquestId,notes);
        if(emailsend){
          setNotes('');
          setShowModal(false);
          history.push('/home');
        }
      }
    }
    if(selected === ""){
      setconfirmselectError(true);
    }
  };

  return (
    <div className="confirmDeliveryModalDiv">
      <IonModal
        mode="ios"
        isOpen={showModal}
        class={'confirmDeliveryModal'}
        showBackdrop={true}
        backdropDismiss={false}
        onWillDismiss={() => setShowModal(false)}
      >
        <div className="modalContent">
          <h2 className="modalTitle">Confirm Delivery</h2>

          <div className="inputElements">
            <div className="receiptAndAmountContainer">     
             <p className="order-name">Your Order: <b>{rooquestName}</b></p>
              <p className="imageOfReceiptMessage">
                {/* If the requester was not present during the delivery attempt,{' '} */}
                <strong>
                  Please confirm receipt of delivery.
                </strong>{' '}
                {/* for proof of delivery. */}
              </p>
            </div>
    <div>
            <IonList>
          <IonRadioGroup value={selected} onIonChange={e => setSelected(e.detail.value)}>
            <IonItem>
              <IonLabel>Delivery complete</IonLabel>
              <IonRadio slot="start" value="delsuccess" />
            </IonItem>

            <IonItem>
              <IonLabel>Problem with my rooquest</IonLabel>
              <IonRadio slot="start" value="delnotsuccess" />
            </IonItem>
          </IonRadioGroup>
        </IonList>
         {selected === "delnotsuccess" ? <div className="notes">
              <IonItem>
                <IonTextarea
                  placeholder="Please Describe Your Rooquest Problem here"
                  rows={4}
                  value={notes}
                  onIonChange={(e) => {
                    setNotes(e.detail.value!);
                    if(e.detail.value.length >5){
                      setTitleError(false)
                    }
                  }}
                />
              </IonItem>
              </div> : null}
              {titleError && (
                      <p className="formErrorMsg">
                        Please enter 5 or more characters
                      </p>
                    )}
              {confirmselectError && (
                      <p className="formErrorMsg">
                        Please select atleast one option
                      </p>
                    )}  
            </div>
            <div className="actionButtons">
            <div className="row">
              {/* <IonButton
                onClick={() => setShowModal(false)}
                disabled={rooquest.loading}
              >
                Close
              </IonButton> */}
              <IonButton
                onClick={() => confirmDelivery()}
                // disabled={rooquest.loading}
              >
                Confirm
                {/* {!rooquest.loading ? 'Confirm' : 'Confirming'} */}
              </IonButton>
            </div>
          </div>
          </div>
        </div>
      </IonModal>
    </div>
  );
};

BuyerConfirmDeliveryModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  rooquest: PropTypes.object.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
  markDeliveryComplete:PropTypes.func.isRequired,
  sendmailrooquestIssue:PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  rooquest: state.rooquest,
});

export default connect(mapStateToProps, {
  markDeliveryComplete,
  sendmailrooquestIssue,
  updateRooQuestWithoutCall,
  setShowEnlargeImageModal,
})(BuyerConfirmDeliveryModal);
