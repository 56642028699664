import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import GoogleAddressAutoComplete from '../../settings/GoogleAddressAutoComplete/GoogleAddressAutoComplete';

import './PeekingAddAddressModal.scss';
import { connect } from 'react-redux';
import { addDeliveryAddress } from '../../../actions/user/user';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const PeekingAddAddressModal = ({
  setShowModal,
  addDeliveryAddress,
  formData,
  setFormData,
  auth
}) => {
  const { t } = useTranslation();
  const defaultAddressState = {
    streetAddress: '',
    city: '',
    postal: '',
    province: '',
    country: '',
    lat: '',
    lng: '',
  };

  const [newDeliveryAddress, setNewDeliveryAddress] = useState(
    defaultAddressState
  );
  const {
    streetAddress,
    city,
    postal,
    province,
    country,
    lat,
    lng,
  } = newDeliveryAddress;

  const [addressName, setAddressName] = useState('');

  const [addressError, setAddressError] = useState(false);
  const [dupaddressError, setDupAddressError] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);
  const [addressNameError, setAddressNameError] = useState(false);
  let dupAddress: any = false;
  let dupAddressname: any = false;
  const submitNewDeliveryAddress = async () => {
    setSendDisabled(true);
    let mounted = true;

    let addrErr = false;
    let addrNameErr = false;
    // Validation
    if (
        _.trim(streetAddress) === '' ||
        _.trim(city) === '' ||
        _.trim(postal) === '' ||
        _.trim(province) === '' ||
        _.trim(country) === '' ||
      lat === '' ||
      lng === ''
    ) {
      setAddressError(true);
      setDupAddressError(false);
      setSendDisabled(false);
      addrErr = true;
    }

    if (addressName === '') {
      setAddressNameError(true);
      addrNameErr = true;
    }
    if (!addrErr && !addrNameErr && mounted) {
      // Create New Address
      const addressObj = {
        addressName: addressName,
        ...newDeliveryAddress,
      };
      if(auth.user){
        if(auth.user.deliveryAddresses.length > 0) {
          auth.user.deliveryAddresses.forEach((element) => {
            if(element.streetAddress === newDeliveryAddress.streetAddress){
                        dupAddress = true
            }
            if(element.addressName === addressObj.addressName){
                    dupAddressname = true
              }
            });
        }
        
          if(dupAddressname){
            setAddressNameError(true);
            setSendDisabled(false);
          }
          if(!dupAddressname){
            setAddressNameError(false);
          if(!dupAddress){
            const newAddress = await addDeliveryAddress(addressObj);
    
            // console.log(newAddress);
      
            // If New Address Needed, Set It
            console.log(formData, setFormData);
            if (setFormData) {
              setFormData({ ...formData, deliveryLocation: newAddress });
            }
      
            // // Clear Fields and Close
            setNewDeliveryAddress(defaultAddressState);
            // setAddressName('');
            setSendDisabled(false);
            setShowModal(false);
          }else{
            setDupAddressError(true);
            setSendDisabled(false);
          }
        }
          return () => (mounted = false);
          }
      }
     
     
  };

  return (
    <Fragment>
      <div className="inputGroup">
        <IonItem className="addressNameInput">
          <IonLabel position="stacked">{t('Address Name')}:</IonLabel>
          {addressNameError && (
            <p className="formErrorMsg">
              Please enter a nickname for your address
            </p>
          )}
          <IonInput
            name="addressName"
            value={addressName}
            placeholder="ie. Home, Work"
            onIonChange={(e) => setAddressName(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <GoogleAddressAutoComplete
          address={newDeliveryAddress}
          setAddress={setNewDeliveryAddress}
          addressError={addressError}
          setAddressError={setAddressError}
          dupaddressError = {dupaddressError}
          addLabel={true}
        />
      </div>
      <IonButton
        className="saveAddressBtn"
        expand="block"
        disabled={sendDisabled}
        onClick={() => submitNewDeliveryAddress()}
      >
        {t('Save Address')}
      </IonButton>
    </Fragment>
  );
};

PeekingAddAddressModal.propTypes = {
  auth: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
  addDeliveryAddress: PropTypes.func.isRequired,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { addDeliveryAddress })(PeekingAddAddressModal);
