import { SET_SHOW_ALERT } from '../../actions/layout/appAlert-types';
import {
  SET_SELECTED_TAB,
  SET_SHOW_RQ_INFO_MODAL,
  SET_SHOW_MYRQ_INFO_MODAL,
  SET_SHOW_MYDELIV_INFO_MODAL,
  SET_SHOW_ENLARGE_IMAGE_MODAL,
  SET_SHOW_RATINGS_MODAL,
  SET_SHOW_POSTRQ_MODAL,
  SET_SHOW_EDITRQ_MODAL,
  SET_SHOW_PROMO_MODAL,
  SET_SHOW_BUG_REPORT_MODAL,
  SET_SHOW_MAP_MODAL,
  SET_SHOW_TUTORIAL_MODAL,
  SET_SHOW_LOCATION_ON_MAP_MODAL,
  SET_SHOW_MYADDRESSES_MODAL,
  SET_SHOW_NOTIFICATION_RANGE_MODAL,
  SET_SHOW_ITEM_RATINGS_NOTIF_MODAL,
  SET_SHOW_NOTIF_MODAL,
  SET_SHOW_FIRST_OFFER_SUCCESS_MODAL,
} from '../../actions/layout/navigation-types';

const initialState = {
  selectedTab: null,
  showRQModal: { visible: false, type: null },
  showNotificationModal : false,
  showMyRQModal: { visible: false, type: null },
  showMyDelivModal: { visible: false, type: null },
  showEnlargeImageModal: { visible: false, src: null },
  showPostRQModal: false,
  showEditRQModal: false,
  showRatingModal: { visible: false, type: null },
  showFirstOfferSuccessModal : false,
  showItemRatingsNotifModal:false,
  setShowItemRatingsNotifModal:false,
  showReviewModal:false,
  ShowPromoModal: false,
  showBugReportModal: false,
  showMapModal: { visible: false, coords: null },
  showTutorialModal: { visible: false, options: null },
  showLocationOnMapModal: {
    visible: false,
    pickupLocation: null,
    deliveryLocation: null,
    showFullAddress: true,
  },
  showMyAddressesModal: false,
  showNotificationRangeModal : false,
  showAppAlert:false
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: payload,
      };
    }
    case SET_SHOW_ALERT:
      return {
        ...state,
        showAppAlert: payload,
      };
    case SET_SHOW_RQ_INFO_MODAL: {
      return {
        ...state,
        showRQModal: payload,
      };
    }
    case SET_SHOW_NOTIF_MODAL : {
      return {
        ...state,
        showNotificationModal : payload
      }
    }
    case SET_SHOW_MYRQ_INFO_MODAL: {
      return {
        ...state,
        showMyRQModal: payload,
      };
    }
    case SET_SHOW_MYDELIV_INFO_MODAL: {
      return {
        ...state,
        showMyDelivModal: payload,
      };
    }
    case SET_SHOW_ENLARGE_IMAGE_MODAL: {
      return {
        ...state,
        showEnlargeImageModal: payload,
      };
    }
    case SET_SHOW_RATINGS_MODAL: {
      return {
        ...state,
        showRatingModal: payload,
      };
    }
    case SET_SHOW_FIRST_OFFER_SUCCESS_MODAL:{
      return {
        ...state,
        showFirstOfferSuccessModal : payload
      }
    }
    case SET_SHOW_ITEM_RATINGS_NOTIF_MODAL: {
      return {
        ...state,
        showItemRatingsNotifModal: payload,
      };
    }
    case SET_SHOW_POSTRQ_MODAL: {
      return {
        ...state,
        showPostRQModal: payload,
      };
    }
    case SET_SHOW_EDITRQ_MODAL: {
      return {
        ...state,
        showEditRQModal: payload,
      };
    }
    case SET_SHOW_PROMO_MODAL: {
      return {
        ...state,
        ShowPromoModal: payload,
      };
    }
    case SET_SHOW_BUG_REPORT_MODAL:
      return {
        ...state,
        showBugReportModal: payload,
      };
    case SET_SHOW_MAP_MODAL:
      return {
        ...state,
        showMapModal:
          payload.visible !== null
            ? payload
            : {
                ...state.showMapModal,
                coords: payload.coords,
              },
      };

    case SET_SHOW_LOCATION_ON_MAP_MODAL:
      return {
        ...state,
        showLocationOnMapModal: payload,
      };

    case SET_SHOW_TUTORIAL_MODAL:
      return {
        ...state,
        showTutorialModal: payload,
      };
    case SET_SHOW_MYADDRESSES_MODAL:
      return {
        ...state,
        showMyAddressesModal: payload,
      };
      case SET_SHOW_NOTIFICATION_RANGE_MODAL:
      return {
        ...state,
        showNotificationRangeModal: payload,
      };
    default:
      return state;
  }
}