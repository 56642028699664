import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { IonButton, IonIcon, IonPage } from '@ionic/react'
import { checkmarkDoneOutline, chevronForward } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import './DeliverySuccess.scss'
import { calculateCO2Savings } from '../PostNewRooQuest'

const DeliverySuccess = ({rooquest,next  }) => {
    const { t } = useTranslation();
    const [moneySaved, setMoneySaved] = useState("0");
    const distance = parseFloat(rooquest.costs.fees.delivererFee.distance);
    const costPerKm = parseFloat(rooquest.costs.fees.delivererFee.costPerKm);
    const date = new Date(rooquest.deliveredDate).toLocaleString('en-CA')
    const time = new Date(rooquest.deliveredDate).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
    // let time = "";
    
    function convertDate(inputFormat) {
      function pad(s) { return (s < 10) ? '0' + s : s; }
      var d = new Date(inputFormat)
      return [d.getFullYear(), pad(d.getMonth()+1), pad(d.getDate())].join('/')
    }
    // useEffect(()=> {
    //     const results = calculateTimeLeft(rooquest.acceptedDate, rooquest.deliveredDate)
    //     time += `${results.days} days ${results.hours} ${results.minutes}` 

    // },[])

    const calculateTimeLeft = (endDate,startDate) => {
        let difference = +new Date(endDate) - +new Date(startDate);
                    
        if (difference > 0) {
            return {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }
        
        return {};
    }

    return (
      <div className='deliverySuccess'>
         <div className="modal-content">
              <div className="modal-header">
                {/* <button type="button" className="btn-close" data-mdb-dismiss="modal" aria-label="Close">X</button> */}
              </div>
              <div className="modal-body">
                <h5 className="modal-title" id="exampleModalLabel">{rooquest.delivererFirstName}</h5>
                <h4 className="thanks-title">{t('Thanks for your delivery')}</h4>
                <p className="payment-summary">{t('Delivery Summary')}</p>
                <hr className="delimiter"/>

                <div className="items">
                  <p className="item">{t('Date')}</p>
                  <p className="item-price">{convertDate(rooquest.deliveredDate)}</p>
                </div>

                <div className="items">
                  <p className="item">{t('Time')}</p>
                  <p className="item-price">{time}</p>
                </div>

                <div className="items">
                  <p className="item">{t("Co2 Saved")}</p>
                  <p className="item-price">{calculateCO2Savings(parseFloat(rooquest.totalDistance)).toFixed(2)} kg</p>
                </div>

                
                <div className="items">
                  <p className="item">{t('Delivery Distance')}</p>
                  <p className="item-price">{`${parseFloat(rooquest.totalDistance).toFixed(2)} Km`}</p>
                </div>

                <div className="items">
                  <p className="item">{t('Gas Cost')}</p>
                  <p className="item-price">$ {(parseFloat(rooquest.totalDistance)*parseFloat(rooquest.costs.fees.delivererFee.costPerKm)).toFixed(2)} </p>
                </div>

                <div className="items">
                  <p className="item">{t('Item Total')}</p>
                  <p className="item-price">$ {rooquest.delivererAmountPaid} </p>
                </div>

                <div className="items">
                  <p className="item">{t('Roo Transfer')}</p>
                  <p className="item-price">$ {(parseFloat(rooquest.delivererAmountPaid)+ parseFloat(rooquest.costOfDeliverer)).toFixed(2)} </p>
                </div>

                <div className="total">
                  <p className="total-title">{t('Roo Earnings')}</p>
                  <p className="total-price">{`$ ${parseFloat(rooquest.costOfDeliverer).toFixed(2)}`}</p>
                </div>
              </div>
             
            </div>
            
            <div className="modal-footer">
              <div className={`continueBtn controlBtn`} onClick={next}>
                                                    <p>{t('Close')}</p>
                                                    <IonIcon icon={chevronForward} />
                                                </div>
                
              </div>


</div>
    )
}

DeliverySuccess.propTypes = {

}

export default DeliverySuccess
