import { Capacitor } from "@capacitor/core";
import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";

import { backendAddress } from '../App';

// BACKGROUND TRACKING
export const updateBackgroundGeolocationConfig = (user) => {
  console.log("HERE1")
  BackgroundGeolocation.setConfig({
    params: {
      userID: user._id,
    },
  }).then((state) => {
    console.log('[setConfig] success: ', state);
  });
};

export const checkToEnableBackgroundGeolocation = (user) => {
  // Start Background Service if in Transit
  try {
    console.log(user)
    // console.log('CHECKING...');
    // Check if web
    if (Capacitor.isPluginAvailable("BackgroundGeolocation")) {
      if (user.isInTransit) {
        console.log('IS IN TRANSIT: ' + user.isInTransit);
        console.log('Starting Background Geolocation');
        BackgroundGeolocation.ready({
          desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
          distanceFilter: 30,
          // useSignificantChangesOnly : true,
          // stopOnTerminate: false,
          startOnBoot: true,
          locationAuthorizationRequest:"Always",
          url: `${backendAddress}/api/rooquests/updateDelivererLocationBackground`,
          params: {
              userID: user._id,
          }
        }).then((state) => {
          console.log("[ready] BackgroundGeolocation is configured and ready to use");

          // This handler fires whenever bgGeo receives a location update.
          BackgroundGeolocation.onLocation(location => {
              console.log(new Date())
              console.log("[location] ", location);
          }, error => {
              console.log("[location] ERROR: ", error);
          });
          
          // This handler fires on HTTP responses
          BackgroundGeolocation.onHttp(response => {
              console.log("[http] ", response);
          });
        
          BackgroundGeolocation.start().then((state) => {
              console.log('[start] success - ', state);
            });
        })
      } else {
        // console.log('Stopping Background Geolocation');
        BackgroundGeolocation.stop().then((state) => {
          console.log('[stop] success - ', state);
        });
      }
    }
  } catch (err) {
    // console.log('NOT NATIVE APP - NOT ENABLING BACKGROUND GEOLOCATION');
  }
};

// export const  stopBackgroundGeolocation = () => {

// }

export function animateMarkerTo(marker, newPosition) {
  var options = {
      duration: 3000,
      easing: function (x, t, b, c, d) { // jquery animation: swing (easeOutQuad)
          return -c *(t/=d)*(t-2) + b;
      }
  };

  window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
  window.cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

  // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
  marker.AT_startPosition_lat = marker.getPosition().lat();
  marker.AT_startPosition_lng = marker.getPosition().lng();
  var newPosition_lat = newPosition.lat;
  var newPosition_lng = newPosition.lng;

  // crossing the 180° meridian and going the long way around the earth?
  if (Math.abs(newPosition_lng - marker.AT_startPosition_lng) > 180) {
      if (newPosition_lng > marker.AT_startPosition_lng) {
          newPosition_lng -= 360;
      } else {
          newPosition_lng += 360;
      }
  }

  var animateStep = function(marker, startTime) {
      var ellapsedTime = (new Date()).getTime() - startTime;
      var durationRatio = ellapsedTime / options.duration; // 0 - 1
      var easingDurationRatio = options.easing(durationRatio, ellapsedTime, 0, 1, options.duration);

      if (durationRatio < 1) {
          marker.setPosition({
              lat: (
                  marker.AT_startPosition_lat +
                  (newPosition_lat - marker.AT_startPosition_lat)*easingDurationRatio
              ),
              lng: (
                  marker.AT_startPosition_lng +
                  (newPosition_lng - marker.AT_startPosition_lng)*easingDurationRatio
              )
          });

          // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
          if (window.requestAnimationFrame) {
              marker.AT_animationHandler = window.requestAnimationFrame(function() {animateStep(marker, startTime)});
          } else {
              marker.AT_animationHandler = setTimeout(function() {animateStep(marker, startTime)}, 17);
          }

      } else {
          marker.setPosition(newPosition);
      }
  }

  // stop possibly running animation
  if (window.cancelAnimationFrame) {
      window.cancelAnimationFrame(marker.AT_animationHandler);
  } else {
      clearTimeout(marker.AT_animationHandler);
  }

  animateStep(marker, (new Date()).getTime());
    // if(!isClient)
    // {
    //     mapRef.current.panTo(newPosition);
    // }
}
