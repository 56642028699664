import { UPDATE_NAVBAR_UNREAD_MESSAGES,UPDATE_NAVBAR_ACTIVE_DELIVERIES,UPDATE_NAVBAR_ACTIVE_ROOQUESTS } from '../../actions/layout/navbar-types';

const initialState = {
  unreadMessages: 0,
  activeDeliveries: 0,
  activeRooquests:0
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_NAVBAR_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: payload,
      };
   case UPDATE_NAVBAR_ACTIVE_DELIVERIES:
        return {
          ...state,
          activeDeliveries: payload,
        };
    case UPDATE_NAVBAR_ACTIVE_ROOQUESTS:
        return {
          ...state,
          activeRooquests:payload
        }
    default:
      return state;
  }
}
