import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import './AddItems.scss'
import { IonIcon } from '@ionic/react'
import { addOutline, refreshCircleOutline} from 'ionicons/icons'
import AddItemModal from '../components/AddItemModal'
import FlipNumbers from 'react-flip-numbers';
import { RooquestItem } from './RooQuestTypes'
import { connect } from 'react-redux'

import { launchToastCard } from '../../../actions/layout/toast'
import { useTranslation } from 'react-i18next'
import CartHistoryModal from './CartHistoryModal'
import { getPastItems } from '../../../actions/inventory/storeitems'

const AddItemsListItem = ({ title, quantity, cost, onClick }) => {
    return (
        <div className="item" onClick={onClick}>
            <div className="item-title">
                <p>{title}</p>
            </div>
            <div className="cost">
                <p>{quantity} x ${parseFloat(cost).toFixed(2)}</p>
                <p className='total'>${(quantity * parseFloat(cost)).toFixed(2)}</p>
            </div>
        </div>
    )
}

const AddItems = ({ rq, setRQ, launchToastCard,activeIndex,getPastItems }) => {
    const { t } = useTranslation();

    const { items } = rq;
    const {pickupAddress} = rq;
    const bottomRef = useRef(null);
    const setItems = (items) => { setRQ({ ...rq, items, triggerChange: !rq.triggerChange }) }
    
    const totalCost = rq.costs.items;
    const setTotalCost = (total) => { setRQ({ ...rq, costs: { ...rq.costs, items: total } }) }

    const [showAddModal, setShowAddModal] = useState({ show: false, index: -1 });
    const [pastItems,setPastItems] = useState([]);
    const [showPastItems,setShowPastItems] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const openAddItemModal = (index) => {
        if (items.length >= 75 && index === -1) { // Trying to more than 75 item
            launchToastCard({ 
                msg: "You have hit the maximum number of items!",
                type: 'info',
                duration: 7500,
             });
        }
        else {
            setShowAddModal({ show: true, index });
        }
    }

    const addItem = (item: RooquestItem) => {
        console.log("TEST")
        console.log(item)
        setItems([...items, item])
    }

    const editItem = (item: RooquestItem, index: number) => {
        const newItems = [...items];
        newItems[index] = item;
        setItems(newItems);
    }

    const removeItem = (index: number) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    }

    useEffect(() => {
        let total = 0;
        items.forEach(item => {
            total += item.quantity * parseFloat(item.costPerUnit);
        });
        setTotalCost(total);
        try{
            bottomRef.current.scrollIntoView();
        }
        catch(err)
        {
            console.log(err)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(()=>{
        const fetchData = async () => {
            console.log(pickupAddress)
            const data = await getPastItems(pickupAddress);
            
            console.log(data)
            setPastItems(data);
        }
        if (showPastItems){
            fetchData();
        }
    },[showPastItems])

    useEffect(()=>{
        if (activeIndex === 1){
            setShowPastItems(true);
        }
        else{
            setShowPastItems(false);
        }
    },[activeIndex])

    return (
        activeIndex === 1 ? <div className='AddItems'>
            <div className="itemsTitle">
                <p className='title'>{t("Your Cart")} {pastItems.length > 0 && <IonIcon icon={refreshCircleOutline} onClick = {()=>{setShowModal(true)}} />}</p>
                <FlipNumbers 
                height={14} 
                width={9.5} 
                color="#10be5f" 
                // background= "white" 
                play perspective={100} 
                duraction={0.4}
                numbers={'$'+ totalCost.toFixed(2)} />
            </div>
            <div className="items">
                {items.length > 0 
                    ? items.map((item, index) => 
                    <AddItemsListItem 
                        key={`addItemListItem-index-${index}`} 
                        quantity={item.quantity}
                        title={item.title} 
                        cost={item.costPerUnit}
                        onClick={() => openAddItemModal(index)}
                    />)
                    : 
                    <div className="item">
                        <p className="noItems">{t("Click below to add items")}</p>    
                    </div>        
                    } 
                    <div className="addNewItem"  ref = {bottomRef}>
                        <IonIcon icon={addOutline} onClick={() => openAddItemModal(-1)} />
                    </div>
            </div >
            <AddItemModal 
                showAddModal={showAddModal} 
                setShowAddModal={setShowAddModal} 
                addItem={addItem} 
                editItem={editItem} 
                removeItem={removeItem}
                items={items}
                pickupAddress = {pickupAddress}
            />

            <CartHistoryModal 
                pastItems = {pastItems}
                addItem={addItem}
                setItems = {setItems} 
                showModal = {showModal}
                setShowModal = {setShowModal}
            />
        </div>:<></>
    )
}

AddItems.propTypes = {

}

export default connect(null, { launchToastCard,getPastItems })(AddItems);
