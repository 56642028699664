import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IonAccordion, IonAccordionGroup, IonCheckbox, IonContent, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonListHeader, IonModal, IonPopover, IonRadio, IonRadioGroup, IonSpinner } from '@ionic/react';

import './PickItemModal.scss';
import { closeOutline, createOutline, duplicateOutline } from 'ionicons/icons';
import { connect } from 'react-redux';
import CurrencyInput from 'react-currency-input-field';
import { setShowEnlargeImageModal } from '../../../../../actions/layout/navigation';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { collectItem,sendCollectItemNotification } from '../../../../../actions/rooquest/rooquestItem';
import {incrementCollectedItemsTotal,decrementCollectedItemsTotal} from '../../../../../actions/collectedItems/collectedItems';
import { getRooQuestByID,saveRooQuest } from '../../../../../actions/rooquest/rooquest';
import { useTranslation } from 'react-i18next';
import { launchToastCard } from '../../../../../actions/layout/toast';

const PickItemModal = ({ show, setShow, item, setShowEnlargeImageModal, collectItem, rooquest,totalCostOfGoods,collectedtotalPrice,incrementCollectedItemsTotal,decrementCollectedItemsTotal,saveRooQuest,launchToastCard,getRooQuestByID }) => {
    
    const { t } = useTranslation();


    const close = () => {
        setShow(false)
    }

    const [loading, setLoading] = useState(false);
    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined })
    const costRef = useRef(null);
    const [error,setError] = useState(false)

    const [formData, setFormData] = useState({
        itemID: item.itemID,
        quantity: item.quantity,
        shelfPrice: item.costPerUnit,
        shelfPriceType: 'perUnit',
        images: [],
        problem: {
            hasProblem: item.collectionInfo.problem.hasProblem ? item.collectionInfo.problem.hasProblem : false,
            reason: item.collectionInfo.problem.reason ? item.collectionInfo.problem.reason : '',
            note: item.collectionInfo.problem.note ? item.collectionInfo.problem.note : '',
        }
    });
    const [checkItem,setCheckItem]=useState(false);

    const collect = async () => {
        // console.log(`collected totasl price${collectedtotalPrice}`);
        setLoading(true);
        if(!formData.shelfPrice)
        {
            costRef.current.focus();
            setError(true);
            setLoading(false);
            launchToastCard({ 
                msg: 'Please provide the shelf price for this item.',
                type: 'error',
                duration: 8500,
            });

            return;
        }

        let priceToCompare = 0;
        if(formData.shelfPriceType === 'perUnit' && formData.problem && !formData.problem.hasProblem) { 
            priceToCompare = formData.shelfPrice ? collectedtotalPrice+formData.shelfPrice*formData.quantity :  collectedtotalPrice
            console.log(collectedtotalPrice)
        }
        else if(formData.shelfPriceType !== 'perUnit' && formData.problem && !formData.problem.hasProblem){
            
            priceToCompare = formData.shelfPrice ? collectedtotalPrice+ parseFloat(formData.shelfPrice) : collectedtotalPrice
        }
        else {
            priceToCompare = collectedtotalPrice;
        }

        // console.log(`price to compare ${priceToCompare}`)
        // console.log(totalCostOfGoods)

        try{
            console.log(`delivery started ${rooquest.deliveryStarted}`)
            if(!rooquest.deliveryStarted)
            {
                await sendCollectItemNotification({
                                ...formData,
                                rqID: rooquest._id,
                                itemID: item._id,
                            })
            }
        }
        catch(err){
            console.log(err)
        }
        
        const res = await collectItem({
            ...formData,
            rqID: rooquest._id,
            itemID: item._id,
            totalCostOfGoods,
            priceToCompare
        })
        if (res && res.status && (res.status === 'success')) {
            setLoading(false);
            setError(false)
            await getRooQuestByID(rooquest._id);
            // await saveRooQuest(rooquest._id)
            close();
        }
        setLoading(false);
    }

    // useEffect(()=> {
    //     //send notification to the user to inform that his items are being collected
    //     if(!notifRef.current)
    //     {
    //         notifRef.current = true;
    //         return;
    //     }
    //     const sendNotificationToUser = async () => {
    //         const res = await sendCollectItemNotification({
    //             ...formData,
    //             rqID: rooquest._id,
    //             itemID: item._id,
    //         })
    //     }
    //     sendNotificationToUser()

    // },[startPickup])

    // useEffect(()=>{
        
    //     if (firstUpdate.current) {
    //         firstUpdate.current = false;
    //         return;
    //       }
    //     console.log(collectedtotalPrice)
    //     const t = async() =>{
    //         setLoading(true);

    //         const res = await collectItem({
    //             ...formData,
    //             rqID: rooquest._id,
    //             itemID: item._id,
    //             totalCostOfGoods,
    //             collectedtotalPrice
    //         })
    //         if (res && res.status && (res.status === 'success')) {
    //             setLoading(false);
    //             await getRooQuestByID(rooquest._id);
    //             close();
    //         }
    //         setLoading(false);
    //     }
    //     t();
       
    // },[checkItem])

    const getImage = async () => {
        const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        height: 500,
        source: CameraSource.Photos
        });

        (await fetch(image.webPath)).blob().then((blobImage) => {
            setFormData({
                ...formData, 
                images: [ ...formData.images, { blobImage: blobImage, src: image.webPath }]
            });        
        });
    };

    const calculateTotalItemCost = (quantity, costPerUnit) => {
        const total = quantity * costPerUnit;
        return total.toFixed(2);
    }

    return (
        <IonModal
            mode="ios" 
            isOpen={show} 
            class="PickItemModal"
            breakpoints={[0, 1]}
            initialBreakpoint={1}
            onIonModalDidDismiss={close}        
        >
            <IonContent>
                <div className="PickItemModalContent">
                    <div className="header">
                        <div className="title">
                            <p>{t('Collect Item')}</p>
                        </div>
                        <div className="closeBtn">
                            <IonIcon icon={closeOutline} onClick={close} />
                        </div>
                    </div>                    
                    <div className="main">
                        <div className="description">
                            <div className="itemContainer">
                                <IonAccordionGroup value={`pickItemModalItem`}>
                                    <IonAccordion mode='ios' value={`pickItemModalItem`} >
                                        <IonItem slot="header" tabIndex={-1}>
                                            <IonLabel className="infoRQ-item-info">
                                                <div className="item-title">
                                                    <p>{item.title}</p>
                                                </div>
                                                <div className="cost">
                                                    <p>{item.quantity} x ${parseFloat(item.costPerUnit).toFixed(2)}</p>
                                                    <p className="total">${calculateTotalItemCost(item.quantity, item.costPerUnit)}</p>
                                                </div>
                                                
                                            </IonLabel>
                                        </IonItem>
                                        <IonList className="infoRQ-item-moreinfo" slot="content">
                                            {item.description !== '' && <div className="infoRQ-moreinfo-section">
                                                <p className='infoRQ-sectionTitle'>Description:</p>
                                                <p className='infoRQ-sectionContent'>{item.description}</p>
                                            </div>}
                                            {item.sku !== "" && <div className="infoRQ-moreinfo-section">
                                                <p className='infoRQ-sectionTitle'>SKU:</p>
                                                <p className='infoRQ-sectionContent'>{item.sku}</p>
                                            </div>}
                                            {item.images.length > 0 && <div className="infoRQ-moreinfo-section">
                                                <p className='infoRQ-sectionTitle'>Images:</p>
                                                <div className="photosContainer">
                                                    {item.images.map((image, index) => (
                                                        <img 
                                                            key={`infoRQ-item-image-${index}`} 
                                                            src={image} 
                                                            alt="item"
                                                            onClick={() => setShowEnlargeImageModal(true, image)} 
                                                        />
                                                    ))}
                                                </div>
                                            </div>}
                                            {item.description === '' && item.sku === "" && item.images.length === 0 && 
                                            <div className="infoRQ-moreinfo-section">
                                                <p className='infoRQ-sectionTitle'>{t("No additional information available")}</p>
                                            </div>}
                                        </IonList>
                                    </IonAccordion> 
                                </IonAccordionGroup>
                            {/* <p>Please enter item information:</p> */}
                            </div>
                        </div>
                        { !formData.problem.hasProblem ? <div className="inputsContainer">
                            <div className="inputSection quantity">
                                <div className="question">
                                    <p>{t('Quantity Picked')}:</p>
                                    {/* <p className='subInfo'>(Max 5 days)</p> */}
                                </div>
                                <input type="number" min={0} value={formData.quantity} 
                                onChange={(e) => {
                                    if(e.target.value[0] === '0')
                                    {
                                        e.target.value = e.target.value.substring(1)
                                        console.log(e.target.value)
                                    }
                                    if(!e.target.value)
                                    {
                                        e.target.value = '0'
                                    }
                                    console.log(e.target.value)
                                    console.log(parseInt(e.target.value))
                                    
                                    setFormData({ ...formData, quantity: parseInt(e.target.value)})}} />
                            </div>
                            <div className="inputSection price">
                                <div className="question">
                                    <p>{t('Shelf Price')}:</p>
                                    {/* <p className='subInfo'>(Max 5 days)</p> */}
                                   
                                </div>
                                <div className="inputItem">
                                    {/* <input
                                    type='number'
                                    min = {0}
                                    value={formData.shelfPrice}
                                    /> */}
                                    <CurrencyInput
                                        ref={costRef}
                                        value={formData.shelfPrice}
                                        allowNegativeValue = {false}
                                        defaultValue = "0"
                                        decimalsLimit={2}
                                        prefix={'$'}
                                        min = {0}
                                        required = {true}
                                        className = {`currencyInput ${error ? 'error':''}`}
                                        onBlur={() => {
                                            // If the user leaves the field empty, set the value to 0
                                            if (formData.shelfPrice === "") {
                                                setFormData({ ...formData, shelfPrice: "0" })
                                            }

                                            // If the user enters a value with one decimal, add a 0 to the end
                                            if (
                                                formData.shelfPrice.indexOf(".") !== -1 &&
                                                formData.shelfPrice.indexOf(".") === formData.shelfPrice.length - 2) {
                                                    setFormData({ ...formData, shelfPrice: formData.shelfPrice + "0" })
                                            }
                                        }}
                                        onValueChange={(value) => {
                                            setFormData({
                                                ...formData,
                                                shelfPrice: value
                                            }) 
                                            
                                            // setErrors({
                                            //     ...errors,
                                            //     shelfPrice: ''
                                            // })
                                            // costRef.current.classList.remove("error")
                                        }} 
                                    />
                                    <p 
                                        className="shelfPriceType"
                                        onClick={(e) => setShowPopover({ showPopover: true, event: e })}>
                                        {formData.shelfPriceType === 'perUnit' ? t('Per Unit') : t('For Total Quantity')}
                                        <IonIcon icon={createOutline} />
                                    </p>
                                    <IonPopover
                                        mode="ios"
                                        className='chooseTypePopover'
                                        event={popoverState.event}
                                        side='right'
                                        arrow={false}
                                        isOpen={popoverState.showPopover}
                                        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                                    >
                                        <div className="choices">
                                            <p 
                                                onClick={() => {
                                                    setFormData({ ...formData, shelfPriceType: 'perUnit'})
                                                    setShowPopover({ showPopover: false, event: undefined })
                                                }} 
                                                className={`${(formData.shelfPriceType === 'perUnit') ? 'selected' : ''}`}>
                                            Per Unit</p>
                                            <p 
                                                onClick={() => {
                                                    setFormData({ ...formData, shelfPriceType: 'totalQuantity'})
                                                    setShowPopover({ showPopover: false, event: undefined })
                                                }} 
                                                className={`${(formData.shelfPriceType === 'totalQuantity') ? 'selected' : ''}`}>
                                            {t('For Total Quantity')}</p>
                                            
                                        </div>
                                    </IonPopover>
                                </div>
                            </div>
                            {/* <div className="inputSection images">
                                <div className="question">
                                    <p>Upload Pictures of Item</p>
                                    <p className='subInfo'>(Max 3 images)</p>
                                    <div className="uploadImageSection">
                                        <div className="upload-img-bar">
                                            <div className="list-of-images">
                                                { formData.images.map((image, index) => {
                                                    return (
                                                        <div className="upload-img"key={`upload-image-item-${index}`}                                            
                                                        >
                                                            <div className="removeImg">
                                                                <IonIcon icon={closeOutline} onClick={() => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        images: formData.images.filter((img, i) => i !== index)
                                                                    })
                                                                } } />
                                                            </div>
                                                            <img src={image.src} 
                                                                alt="" 
                                                                onClick={() => setShowEnlargeImageModal(true, image.src)} 
                                                            />
                                                        </div>
                                                    )
                                                })}                             
                                            </div>                                
                                            { formData.images.length < 3 &&
                                                <div className="upload-img uploadBtn" onClick={async () => await getImage()}>
                                                    <IonIcon icon={duplicateOutline} />
                                                </div>
                                            }
                                        </div>
                                    </div>                                    
                                </div>
                            </div> */}
                        </div> 
                        :
                        <div className="inputsContainer problemWithItem">
                            <div className="inputSection reason">
                                <div className="question">
                                    <p>{t("Reason for problem with item")}:</p>
                                    {/* <p className='subInfo'>(Max 5 days)</p> */}
                                </div>
                                <IonList>

                                    <IonRadioGroup 
                                        value={formData.problem.reason} 
                                        onIonChange={e => setFormData({ ...formData, problem: { ...formData.problem, reason: e.detail.value } })}
                                        >
                                        <IonItem>
                                            <IonLabel>{t('Unable to find item')}</IonLabel>
                                            <IonRadio slot="start" value="unable-to-find" />
                                        </IonItem>

                                        <IonItem>
                                            <IonLabel>{t('Item out of stock')}</IonLabel>
                                            <IonRadio slot="start" value="no-stock" />
                                        </IonItem>

                                        <IonItem>
                                            <IonLabel>{t('Other')}</IonLabel>
                                            <IonRadio slot="start" value="other" />
                                        </IonItem>
                                    </IonRadioGroup>
                                </IonList>                               
                            </div>
                            {formData.problem.reason === 'other' ? 
                            <div className="inputSection reason-note">
                                <div className="question">
                                    <p>{t('Add comment')}:</p>
                                </div>
                                <textarea placeholder={t('Add a comment')} rows={3} value={formData.problem.note} onChange={(e) => setFormData({ 
                                    ...formData, problem: { ...formData.problem, note: e.target.value }
                                })} />
                            </div> :
                             <></>}
                            
                        </div>
                        }
                        <div className="footer">
                            <div className="notAvailable">
                                <IonCheckbox color='danger' checked={formData.problem.hasProblem} onIonChange={(e) => setFormData({
                                    ...formData, problem: { ...formData.problem, hasProblem: e.detail.checked, }
                                })} />
                                <IonLabel onClick={() => setFormData({ 
                                    ...formData, problem: { ...formData.problem, hasProblem: !formData.problem.hasProblem, } 
                                    })}>{t('Problem with item')}</IonLabel>
                            </div>
                            <div className={`collectItemBtn ${loading ? 'loading' : ''}`} onClick={collect}>
                                {!loading ? (!formData.problem.hasProblem ? <p>{t('Collect Item')}</p> : <p>{t('Submit')}</p>) : <IonSpinner name="crescent" />}
                            </div>
                        </div>
                    </div>                   
                </div>
            </IonContent>
        </IonModal>);
};

PickItemModal.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    item: PropTypes.object.isRequired,
    setShowEnlargeImageModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    rooquest: state.rooquest.rooquest,
});

export default connect(mapStateToProps, { setShowEnlargeImageModal,launchToastCard, collectItem,incrementCollectedItemsTotal,decrementCollectedItemsTotal,saveRooQuest,getRooQuestByID })(PickItemModal);
