import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { IonIcon } from '@ionic/react'
// import '../components/AutoCompleteWithMap.scss'

import './test.scss'
import { useTranslation } from 'react-i18next'
import { closeCircleOutline} from 'ionicons/icons'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { compareDates } from '../../../utils/formatDate'
import { Geolocation } from '@capacitor/geolocation';
import { connect } from 'react-redux'
import { loadUser } from '../../../actions/auth/auth'
import { updateUserLocation } from '../../../actions/notificationrange/notificationrange'


export const isBusiness = (address,addressName = null) => {
    // Check if address.types includes 'store', 'establishment', 'point_of_interest', 'food'
    // and that the name does not include the street number
    if (address.types.length === 0) return false;

    const name = addressName ? addressName:address.name;

    console.log("isbusiness")
    console.log(name)
    console.log(address.name)
    if ((address.types.includes('store')
        || address.types.includes('establishment')
        || address.types.includes('point_of_interest')
        || address.types.includes('food'))
        && (!name.includes(address.street_number))) {
        return true;
    }
    else return false;
}
const Test = ({ isKeyboardVisible,loadUser,pickupAddress, setPickupAddress, clearPickupAddress,auth,clearDropoffAddress,dropoffAddress,setDropoffAddress,editRQ,updateUserLocation }) => {
    const autoCompleteRef = useRef(null);
    const secondAutoCompleteRef = useRef(null);
    const inputRef = useRef(null);
    const secondInputRef = useRef(null);
   const [location,setLocation]= useState({lat: 37.4221, lng: -122.0841});
   const [firstMarker,setFirstMarker] = useState({lat: 0, lng: 0});
   const [secondMarker,setSecondMarker] = useState({lat: 0, lng: 0});
   const [showMap,setshowMap]=useState(false);
   const [bounds,setBounds] = useState(new google.maps.LatLngBounds());
   const [resetMap,setResetMap] = useState(false);
   const [controlButtonDivText,setControlButtonDivText] = useState('Add Pin On Map');
   const [dragend,setDragend] = useState(false);
   const [inputContainerHeight,setInputContainerHeight] = useState(null);
   const inputsRef = useRef(null);
//    const [activeMarker,setActiveMarker] = useState(null);
   
   const mapRef = useRef(null);
   const markerRef = useRef(null);
   const secondMarkerRef = useRef(null);
   const controlButtonRef = useRef(null);
   const { t } = useTranslation();

   const options = {
        componentRestrictions: { country: ["us", "ca", "ind"] },
        fields: ["address_components", "geometry", "name", "type", "formatted_address"],
    };
    const mapOptions = useMemo(()=> ({
        // mapId:"ce8355f67058c4c1",  // change style of the map
        disableDefaultUI:true, // remove default buttons like +/- to zoom or map/satelite view button
        clickableIcons: false, // can't click the default icons present in google map
        // maxZoom: 15
    }),[]);

    //will fetch all the data when user reload the page
    // useEffect(() => {
    //     const fetchUserData =async () => {
    //         await loadUser();
    //     }
    //     if (performance.navigation.type === 1) {
    //       console.log("This page is reloaded x");
    //       if(auth.user)
    //         return;
    //       fetchUserData();
    //     }
    //   });

    useEffect(() => {
        // console.log(!!overlayRef)
        try{
            // console.log(overlayRef.current)
        }
        catch(err)
        {
            console.log(err)
        }
        if(!inputsRef || !inputsRef.current)
            return;
        
        setInputContainerHeight(inputsRef.current.clientHeight)
    });

    useEffect(() => {
        console.log("inputContainerHeight")
        console.log(inputContainerHeight)
    },[inputContainerHeight])

    const setBiasToAutoComplete = async () => {
        let coords = {
            lat: 45.50884,
            lng: -73.58781,
        };
        let radius = 10000;
        try
        {
            coords = {
                lat : auth.user.loc.coordinates[1],
                lng : auth.user.loc.coordinates[0]
            }
            setLocation(coords);
            // setFirstMarker(coords);
            radius = auth.user.geolocationPosition.coords.accuracy
        }
        catch(err)
        {
            console.log(err)
        }
        try {
            if(compareDates(auth.user.geolocationPosition.timestamp)) //if the last position we got was 30 min ago take another position else use the last known position
            {
                console.log(auth.user.geolocationPosition.timestamp)
                const geolocation = await Geolocation.getCurrentPosition();
                coords = {
                    lat: geolocation.coords.latitude,
                    lng: geolocation.coords.longitude,
                };
                
                await updateUserLocation(geolocation.coords.latitude,geolocation.coords.longitude,geolocation);
                console.log("setbias to autocomplete")
                console.log(geolocation.coords.accuracy)
                radius = geolocation.coords.accuracy / 100;
            }
        } catch (err) {
            console.error(err);
        }
        const circle = new google.maps.Circle({
            center: coords,
            radius: radius,
        });
        autoCompleteRef.current.setBounds(circle.getBounds());
        secondAutoCompleteRef.current.setBounds(circle.getBounds());
    };

    useEffect(() => {
        //will set bias to the autocomplete results according to the user current position
        

    const fetchUserData = async () => {
        await loadUser();
    }

    if(!auth.user)
    {
        fetchUserData();
    }
        //input 1
    autoCompleteRef.current = new google.maps.places.Autocomplete(
    inputRef.current,
    options
    );
    //input 2
    secondAutoCompleteRef.current = new google.maps.places.Autocomplete(
        secondInputRef.current,
        options
    );
    setBiasToAutoComplete()


    autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        const center = {
            lat: place.geometry.location.lat(),
            lng:place.geometry.location.lng()
        }

        //set the pickup address 
        const street_number = place.address_components.find(component => component.types.includes('street_number'));
        const route = place.address_components.find(component => component.types.includes('route'));
        const locality = place.address_components.find(component => component.types.includes('locality'));
        const postal_code = place.address_components.find(component => component.types.includes('postal_code'));
        const administrative_area_level_1 = place.address_components.find(component => component.types.includes('administrative_area_level_1'));
        const country = place.address_components.find(component => component.types.includes('country'));
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        setPickupAddress({
            name: place.name,
            hasBusinessName: isBusiness(place),
            types: place.types,
            street_number: street_number ? street_number.long_name : "",
            route: route ? route.long_name : "",
            locality: locality ? locality.long_name : "",
            postal_code: postal_code ? postal_code.long_name : "",
            administrative_area_level_1: administrative_area_level_1 ? administrative_area_level_1.long_name : "",
            country: country ? country.long_name : "",
            lat: lat,
            lng: lng,
            formatted_address: place.formatted_address,
        })
        setFirstMarker(center)
        setLocation(center)
    });

    secondAutoCompleteRef.current.addListener("place_changed", async function () {
        const place = await secondAutoCompleteRef.current.getPlace();
        const center = {
            lat: place.geometry.location.lat(),
            lng:place.geometry.location.lng()
        }
        //set the dropoff address
        const street_number = place.address_components.find(component => component.types.includes('street_number'));
        const route = place.address_components.find(component => component.types.includes('route'));
        const locality = place.address_components.find(component => component.types.includes('locality'));
        const postal_code = place.address_components.find(component => component.types.includes('postal_code'));
        const administrative_area_level_1 = place.address_components.find(component => component.types.includes('administrative_area_level_1'));
        const country = place.address_components.find(component => component.types.includes('country'));
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();


        setDropoffAddress({
            name: place.name,
            hasBusinessName: isBusiness(place),
            types: place.types,
            street_number: street_number ? street_number.long_name : "",
            route: route ? route.long_name : "",
            locality: locality ? locality.long_name : "",
            postal_code: postal_code ? postal_code.long_name : "",
            administrative_area_level_1: administrative_area_level_1 ? administrative_area_level_1.long_name : "",
            country: country ? country.long_name : "",
            lat: lat,
            lng: lng,
            formatted_address: place.formatted_address,
        })

        setSecondMarker(center)
        setLocation(center)
        setshowMap(true)
    });
    }, []);

    useEffect(()=> {

        if(!auth.user)
            return;

        setBiasToAutoComplete();
    },[auth])
    useEffect(()=> 
    {
        console.log("edit rq initiated")
         //fill input fields when edit mode
        if(editRQ)
        {
            console.log('filling time')
            console.log(pickupAddress)
            inputRef.current.value = pickupAddress.formatted_address
            secondInputRef.current.value = dropoffAddress.formatted_address
        }

    },[editRQ])


  //check if both markers are set before showing the map
  useEffect(()=> {
    if(isValid(firstMarker) && isValid(secondMarker) && !dragend)
    {
        setLocation(findMidPoint(firstMarker,secondMarker))
        fitBounds(firstMarker,secondMarker)
        setshowMap(true)
    }
    // checkPinOnMap();
    console.log("showing first marker")
    console.log(firstMarker)
    setDragend(false);

 },[firstMarker,secondMarker])

 useEffect(()=>{
    if(location)
        mapRef.current?.panTo(location)

 },[location])

 //will clear the 2 inputs when a payment has succeded
 useEffect(()=> {
    if(pickupAddress.formatted_address === "" && dropoffAddress.formatted_address === "")
    {
        inputRef.current.value = '';
        secondInputRef.current.value = ''
        setFirstMarker({lat: 0, lng: 0})
        setSecondMarker({lat: 0, lng: 0})
    }
    else if(editRQ)
    {
        console.log("edit rq detected")
        inputRef.current.value = pickupAddress.formatted_address 
        secondInputRef.current.value = dropoffAddress.formatted_address
        setFirstMarker({lat: pickupAddress.lat, lng: pickupAddress.lng})
        if(dropoffAddress.lat && dropoffAddress.lng)
        {
            setSecondMarker({lat: dropoffAddress.lat, lng: dropoffAddress.lng})
        }
        else {
            setSecondMarker({lat: 0, lng: 0})
        }
    }

 },[pickupAddress,dropoffAddress])

 const isValid = (marker) => 
 {
    return (marker.lat !==0 && marker.lng !==0)
 }

 const findMidPoint = (firstMarker, secondMarker) => 
 {

    const x = (firstMarker.lat + secondMarker.lat)/2
    const y = (firstMarker.lng + secondMarker.lng)/2
    const result = {
        lat: x,
        lng : y
    } 
    return result
 }

 const handleFirstMarkerDragend = () => {
    // setActiveMarker(null);
    setDragend(true);
    const position = markerRef.current?.getPosition();
    const latitude= position.lat();
    const longitude = position.lng()
    getReverseGeocodingData(latitude,longitude,inputRef)
    setFirstMarker({lat:latitude,lng:longitude});
    // setActiveMarker({lat:latitude,lng:longitude})
 }

 const handleSecondMarkerDragend = () => {
    // setActiveMarker(null);
    setDragend(true);
    const position = secondMarkerRef.current?.getPosition();
    const latitude= position.lat();
    const longitude = position.lng()
    getReverseGeocodingData(latitude,longitude,secondInputRef)
    setSecondMarker({lat:latitude,lng:longitude});
    // setActiveMarker({lat:latitude,lng:longitude})
 }

 const fitBounds = (firstMarker, secondMarker) => {
    let bound = new google.maps.LatLngBounds();
    bound.extend(firstMarker)
    bound.extend(secondMarker);
    mapRef.current.fitBounds(bound,{top:inputContainerHeight*2+80,left:10,right:10,bottom:10});
    setBounds(bound)
    console.log(bound)
 }

 const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlButtonRef.current);
},[]);


const getReverseGeocodingData = (lat, lng,ref) => {
    let latlng = new google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: latlng},  (results, status) =>{
        if (status == google.maps.GeocoderStatus.OK) {
          console.log('geocoding')
            console.log(results);
            const place = results[0];
            console.log(place)
            //set the pickup address 
            const street_number = place.address_components.find(component => component.types.includes('street_number'));
            const route = place.address_components.find(component => component.types.includes('route'));
            const locality = place.address_components.find(component => component.types.includes('locality'));
            const postal_code = place.address_components.find(component => component.types.includes('postal_code'));
            const administrative_area_level_1 = place.address_components.find(component => component.types.includes('administrative_area_level_1'));
            const country = place.address_components.find(component => component.types.includes('country'));
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            let name = place.address_components[0].short_name;
            if(place.address_components.length >1)
            {
                name+= ' '+ place.address_components[1].short_name
            }
            if(ref === inputRef)
            {
                inputRef.current.value = place.formatted_address
                setPickupAddress({
                    name: name,
                    hasBusinessName: isBusiness(place,name),
                    types: place.types,
                    street_number: street_number ? street_number.long_name : "",
                    route: route ? route.long_name : "",
                    locality: locality ? locality.long_name : "",
                    postal_code: postal_code ? postal_code.long_name : "",
                    administrative_area_level_1: administrative_area_level_1 ? administrative_area_level_1.long_name : "",
                    country: country ? country.long_name : "",
                    lat: lat,
                    lng: lng,
                    formatted_address: place.formatted_address,
                })
            }
            else
            {
                secondInputRef.current.value = results[0].formatted_address
                setDropoffAddress({
                    name: name,
                    hasBusinessName: isBusiness(place,name),
                    types: place.types,
                    street_number: street_number ? street_number.long_name : "",
                    route: route ? route.long_name : "",
                    locality: locality ? locality.long_name : "",
                    postal_code: postal_code ? postal_code.long_name : "",
                    administrative_area_level_1: administrative_area_level_1 ? administrative_area_level_1.long_name : "",
                    country: country ? country.long_name : "",
                    lat: lat,
                    lng: lng,
                    formatted_address: place.formatted_address,
                })
            }
        }
    });
  }

  const onMarkerLoad = useCallback((marker) => {
    markerRef.current = marker
},[]);

const onSecondMarkerLoad = useCallback((marker) => {
    secondMarkerRef.current = marker
},[]);

const handleControlButtonDiv = () => {
    setResetMap(!resetMap);
}

useEffect(()=> {
    if(!resetMap)
    {
        const resetPosition = {lat:0,lng:0}
        inputRef.current.value = '';
        clearPickupAddress();
        setFirstMarker(resetPosition)
        secondInputRef.current.value = '';
        clearDropoffAddress();
        setSecondMarker(resetPosition)
    }
    else
    {
        setControlButtonDivText('Reset Map')
    }
},[resetMap])
// const checkPinOnMap = () => {
//     if(resetMap)
//         return;
//     if(isValid(firstMarker) || isValid(secondMarker))
//     {
//         setControlButtonDivText('Edit Pin Location')
//     }
//     else
//     {
//         setControlButtonDivText('Add Pin On Map')
//     }
// }

const onMapClick = (e) => {
    const latitude = e.latLng.lat();
    const longitude =  e.latLng.lng();
    const center = {lat:latitude,lng: longitude}
    if(!isValid(firstMarker))
    {
        setFirstMarker(center)
        setLocation(center)
        getReverseGeocodingData(latitude,longitude,inputRef)
    }
    else{
        setSecondMarker(center)
        setLocation(center)
        getReverseGeocodingData(latitude,longitude,secondInputRef)
    }
    // checkPinOnMap();
}


    return (
        <div className='AutoCompleteWithMap'>
            <div className="google-autocomplete-container" ref={inputsRef}>
            <div className="inputContainer">

                <div className='pickup'>
                    <input
                        ref={inputRef}
                        id="pickup-address"
                        name="pickup-address"
                        required
                        autoComplete="off"
                        type='search'
                        tabIndex={-1}
                        placeholder={t("ex: Walmart, Paul St, Dieppe",{nsSeparator: false})}
                        onChange = {(e)=> {
                            if(e.target.value === ''){
                                clearPickupAddress()
                            }
                        }}
                        readOnly = {!editRQ ? false: true}
                    />
                    {!editRQ ? <span onClick={() => {
                        inputRef.current.value = '';
                        clearPickupAddress();
                        setFirstMarker({lat:0,lng:0})
                        // checkPinOnMap()
                    }}><IonIcon icon={closeCircleOutline} /></span>
                    :<></> }
                    
                </div>

                <div className='dropoff'>
                    <input
                        ref={secondInputRef}
                        id="dropoff-address"
                        name="pickup-address"
                        required
                        autoComplete="off"
                        type='search'
                        tabIndex={-1}
                        placeholder={t('ex: 555 Main Street, Moncton, NB',{nsSeparator: false})}
                        onChange = {(e)=> {
                            if(e.target.value === ''){
                                clearDropoffAddress()
                            }
                        }}
                    />
                    <span onClick={() => {
                        secondInputRef.current.value = '';
                        clearDropoffAddress();
                        setSecondMarker({lat:0,lng:0})
                        // checkPinOnMap();
                    }}><IonIcon icon={closeCircleOutline} /></span>
                </div>
            </div>

        </div>

        <div className={`google-map-confirm-container ${showMap && 'hasMap'}`}>
            <div id="google-map-pickup-confirm">
                <GoogleMap 
                zoom= {15}
                center = {location}
                mapContainerClassName = "google-map-container"
                onClick = {onMapClick}
                onLoad = {onMapLoad}
                options = {mapOptions}
                >
                     {isValid(firstMarker) && <Marker
                        position = {firstMarker}
                        label = {"A"}
                        draggable = {true}
                        onDragEnd = {handleFirstMarkerDragend}
                        onLoad = {onMarkerLoad}
                    >
                        
                        </Marker>}
                    {isValid(secondMarker) && <Marker
                        position = {secondMarker}
                        label = {"B"}
                        draggable = {true}
                        onDragEnd = {handleSecondMarkerDragend}
                        onLoad = {onSecondMarkerLoad}
                    >
                        </Marker>}
                </GoogleMap>
                {/* <button className='controlButtonDiv' ref = {controlButtonRef} onClick = {handleControlButtonDiv}><IonIcon src={locateOutline}/></button> */}

             </div>
                </div>

            </div>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    isKeyboardVisible:state.isKeyboardVisible,
  });
export default connect(mapStateToProps,{updateUserLocation,loadUser})(Test);
