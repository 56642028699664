import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import {IonIcon, IonPage, IonContent, IonModal, useIonViewWillLeave, isPlatform, IonFab, IonFabButton, useIonLoading, useIonViewDidLeave, useIonViewDidEnter, IonRefresher, IonRefresherContent, useIonViewWillEnter } from '@ionic/react';
// import { Plugins } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { connect } from 'react-redux';
import RQCard from '../layout/RQCard/RQCard';
import {
  getAllRooQuests,
  getFilteredRooquests,
  updateRooQuestWithoutCall,
} from '../../actions/rooquest/rooquest';
import MyRQCardTop from '../layout/RQCard/MyRooQuests/MyRQCardTop';
import SaveRooQuestComponent from '../layout/RQCard/Feed/SaveRooQuestComponent';
import { setPanToLocation } from '../../actions/layout/map';
import RouteInfoVertical from '../layout/RQCard/Feed/RouteInfo/RouteInfoVertical';
import { compareDates, getDateElements } from '../../utils/formatDate';
import { setShowInfoModal } from '../../actions/layout/navigation';
import { useTranslation } from 'react-i18next';
import { regularMapColor, darkModeMapColor } from '../feed/map/MapStyles';
import {
    GoogleMap,
    Marker,
    Circle,
  } from "@react-google-maps/api";
  import { MarkerClusterer } from "@googlemaps/markerclusterer";
import './DeliverNow.scss'
import {setDeliverNowFilters, setRooQuestSearchRadiusFilter } from '../../actions/layout/feedFilterBar';
import Header from './Header';
import { list } from 'ionicons/icons';
import ListModal from './ListModal';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { loadUser } from '../../actions/auth/auth';
import useWindowDimensions from '../../utils/useWindowDimensions';
import LoaderContainer from '../../pages/Landing/LoaderContainer';
import { setIsLoading } from '../../actions/loading/loading';
import { updateUserLocation } from '../../actions/notificationrange/notificationrange';
const initialFilterState = {
    // {"costs.fees.delivererFee.total":5}
    minDelivererFee : -1,
    storeName : "",
    maxPrice : -1,
    sortBy: "",
    radius: 350

}
const DeliverNow = ({ auth,
    rooquests,
    getAllRooQuests,
    getFilteredRooquests,
    updateRooQuestWithoutCall,
    setShowInfoModal,
    filters,
    loadUser,
    setIsLoading,
    updateUserLocation
})=>{

    const [ zoom, setZoom ] = useState(14);
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const circleRef = useRef(null);
    const clustererRef = useRef(null);
    const autocompleteRef = useRef(null);
    const [showPopover, setShowPopover] = useState(false);
    const [filtersState,setFiltersState]= useState(initialFilterState);
    let [slidingRooquests,setSlidingRooquests]= useState([]);
    const { height, width } = useWindowDimensions();
	const [showSaved, setShowSaved] = useState(false);
    // let device = await Device.getInfo();



    const options = useMemo(()=> ({
        // mapId:"ce8355f67058c4c1",  // change style of the map
        disableDefaultUI:true, // remove default buttons like +/- to zoom or map/satelite view button
        clickableIcons: false, // can't click the default icons present in google map
        // maxZoom: 15
    }),[]);


    // const autocompleteOptions = {
    //     restrictions: { country: ["ind"] },
    //     fields: ["address_components", "geometry", "name", "type", "formatted_address"],
    // };

    const { t } = useTranslation();
    const [position,setPosition]= useState({lat:0,lng:0});
    // const [radius,setRadius] = useState(350);
    const radiusUpperRange = 5000;
    const [activeMarkerIndex,setActiveMarkerIndex] = useState(-1);
    const [openInfoWindow,setOpenInfoWindow] = useState(false);
    const [showOfferText,setShowOfferText] = useState(null);
    const [dateElements,setDateElements] = useState(null);
    const [inputText,setInputText] = useState('');
    const [infoWindowRQCard, setWindowInfoRQCard] = useState(null);
    const [isDraggable,setIsDraggable] = useState(false);
    const [zIndex, setZIndex] = useState(-1)
    const [memory,setMemory] = useState(null);
    const [showList,setShowList] = useState(false);
    // const [present, dismiss] = useIonLoading();
    const [locations,setLocations] = useState([]);

    const circleOptions = {
        strokeColor : 'transparent',
        strokeOpacity: 0,
        strokeWeight:5,
        // fillColor:'#FF0000',
        fillColor : '#063970',
        fillOpacity:0.2
    }

    //will get the rooquests that correspond to the filters, if an error occurs get all the active rooquests.
    const getRQs = async (latitude,longitude) => {
        try {

            //use the saved filters bu the user to make the requests for the data
           return await getFilteredRooquests(
                auth.user._id,
                {},
                latitude,
                longitude
                );
        } catch (err) {
        await getAllRooQuests();
        console.error(err);
        }
        // await dismiss();
    };

    //this function get the current position of the user and get all the rooquests that are within the range of the user.
    const getCurrentPosition = async (shouldGetPosition = false) =>{
        try {
            console.log("i am getting the current position")
            let latitude = auth.user.loc.coordinates[1]
            let longitude =  auth.user.loc.coordinates[0];
            if(compareDates(auth.user.geolocationPosition.timestamp) || shouldGetPosition) //if the last position we got was 30 min ago take another position else use the last known position
            {
                console.log("get current position header")
                const geolocation = await Geolocation.getCurrentPosition({
                    enableHighAccuracy: true,
                });
                latitude = geolocation.coords.latitude
                longitude =  geolocation.coords.longitude;
                
            await updateUserLocation(latitude,longitude,geolocation);
            }
            setPosition( {
                lat: latitude,
                lng:longitude,
            });
            getReverseGeocodingData(latitude,longitude)
        } catch (err) {
            console.error(err);
        }
    }
    const getMapStyle = () => {
        let mapStyle = regularMapColor;
        if (
            auth.user &&
            auth.user.color_theme === 'dark'
        ) {
            mapStyle = darkModeMapColor;
        }
        return mapStyle;
    };

    const isValid = (position) => {
        return (position.lat !== 0 && position.lng !== 0)
    }

    const shiftMapPosition = () => {
       
        const midHeight = height/2;
        const visibleMidHeight = (height-(height*0.6))/2
        const shiftBy = midHeight - visibleMidHeight
        if(showList){
            mapRef.current.panBy(0,shiftBy)
        }
        else{
            mapRef.current.panBy(0,-shiftBy)
        }
        
        console.log("panby")
    }

    const [searchTerm,setSearchTerm] = useState("")

    useEffect(() => {

        if(!auth.user)
            return;
        setFiltersState({
            ...filtersState,
            radius : auth.user.settings.filters.radius / 1000 , 
            storeName : auth.user.settings.filters.storeName,
            minDelivererFee : auth.user.settings.filters.minDelivererFee,
            maxPrice : auth.user.settings.filters.maxPrice,
            sortBy : auth.user.settings.filters.sortBy
        })
        console.log(auth.user.settings.filters.radius)
        getCurrentPosition();
        setIsLoading(false);
        if(!mapRef.current || !circleRef.current)
            return;
        console.log("ici")
        //should fit bounds of the markers not the circle
        mapRef.current.fitBounds(circleRef.current.getBounds());

    }, []);

    useIonViewWillLeave(()=>{
        setShowList(false);
    })

    useIonViewWillEnter(()=> {
        setIsLoading(true);
    })

    //handle the refresh button and force to wait for user to load before doing anything
    useEffect(()=>{

        if(!auth.user || rooquests.length !== 0)
            return;

        const isLoading = async () =>{
            // await present({
            //     message: 'Loading...',
            //     spinner: 'circles'
            //   })
        }
        isLoading();

        setFiltersState({
            ...filtersState,
            radius : auth.user.settings.filters.radius / 1000 , 
            storeName : auth.user.settings.filters.storeName,
            minDelivererFee : auth.user.settings.filters.minDelivererFee,
            maxPrice : auth.user.settings.filters.maxPrice,
            sortBy : auth.user.settings.filters.sortBy
        })
        // setRadius(auth.user.settings.filters.radius / 1000);
        // setFilterText(auth.user.settings.filters.storeName);
        // setMinDelivererFee(auth.user.settings.filters.minDelivererFee);
        // setMaxPrice(auth.user.settings.filters.maxPrice);
        // console.log(auth.user.settings.filters.sortBy)
        // setSortBy(auth.user.settings.filters.sortBy)
        getCurrentPosition();
        if(!mapRef.current || !circleRef.current)
            return;
        console.log("ici")
        // mapRef.current.fitBounds(circleRef.current.getBounds());

        // console.log("ici")
        // console.log(auth)
        // console.log(auth.user.settings.filters.radius);
        // setRadius(auth.user.settings.filters.radius/1000);

        //     const isLoading = async () =>{
        //         // await present({
        //         //     message: 'Loading...',
        //         //     spinner: 'circles'
        //         //   })
        //     }
        //     isLoading();
            
        //     getCurrentPosition();
        //     if(!mapRef.current || !circleRef.current)
        //         return;
        //     console.log("ici")
        //     mapRef.current.fitBounds(circleRef.current.getBounds());

    },[auth])

    useEffect(()=> {
        if(!mapRef.current || !circleRef.current || !isValid(position))
            return;
    // mapRef.current.fitBounds(circleRef.current.getBounds())

      getRQs(position.lat,position.lng).then(data => {
        if(data == "done"){
            setShowList(true)
        }
      }
        )
        if(showList)
        {
            shiftMapPosition();
        }
        setIsLoading(false);
        

    },[position])
   
    useEffect(() => {
        if(mapRef.current)
        {
            shiftMapPosition();
        }

    },[showList])


   

    useEffect(()=> {
        if(!mapRef.current || !circleRef.current)
            return;
        if(filtersState.radius>=4500)
            return;
        console.log("ici3")
        mapRef.current.fitBounds(circleRef.current.getBounds())

    },[filtersState.radius]);

    useEffect(()=> {

        if(!rooquests || rooquests.length ===0)
            return;
        let results = [];
        rooquests.forEach(rq => {
            results.push({lat:rq.pickupAddress.lat,lng:rq.pickupAddress.lng})
        });
        clearCurrentMarkers()
        setLocations([...results]);

    },[rooquests]);

    

    useEffect(()=>{

        if(locations.length ===0 || !mapRef || !mapRef.current)
            return;
        
        console.log(mapRef);
        //add markers and clusterer to the map 
        markerRef.current =locations.map((position,index) => {
            const marker = new google.maps.Marker({
              position : position,
              map : mapRef.current,
            })
            marker.addListener("click", () => {
                markerOnClick(rooquests[index],index)
              });
            return marker;
        })
        clustererRef.current = new MarkerClusterer({ map:mapRef.current,markers: markerRef.current, onClusterClick : (e,cluster)=>{
            console.log(mapRef.current.getZoom())
            if(mapRef.current.getZoom() < 5)
            {
                setShowList(false);
                const bounds = cluster.bounds;
                mapRef.current.fitBounds(bounds)
                return;
            }
            setShowList(false)
            const results = rooquests.filter(rq =>  cluster.markers.some(marker => (rq.pickupAddress.lat === marker.getPosition().lat() && rq.pickupAddress.lng === marker.getPosition().lng())));
            console.log(results)
            setSlidingRooquests([...results]);
            setOpenInfoWindow(true);
        } });
    },[locations])
    const onMapLoad = useCallback((map) => {
        mapRef.current = map
    },[]);
    const onMarkerLoad = useCallback((marker) => {
        markerRef.current = marker
    },[]);
    const onCircleLoad = useCallback((circle) => {
        circleRef.current = circle
    },[])

    const onClustererLoad = useCallback((clusterer) => {
        console.log('clusterer')
        clustererRef.current = clusterer;
    },[])

    //will remove all markers and cluster from the map
    const clearCurrentMarkers = () => {
        
        console.log(clustererRef)
        console.log(markerRef)
        if(!clustererRef.current || !markerRef.current)
            return;
        console.log("clearing markers")
        clustererRef.current.setMap(null)
        clustererRef.current = null;
        markerRef.current.forEach(marker => {
            marker.setMap(null);
            marker = null;
        });
        markerRef.current = null;
    }

    const onAutocompleteLoad = useCallback((autocomplete) => {
        autocompleteRef.current = autocomplete;
        // setBiasToAutoComplete(autocompleteRef);
    },[])

    const onDragendMarker = () => {
        const position = markerRef.current?.getPosition();
        const latitude= position.lat();
        const longitude = position.lng()
        getReverseGeocodingData(latitude,longitude)
    }

    const openItemModal = (rq) => {
        if(rq)
        {
            console.log("tesy");
          updateRooQuestWithoutCall(rq);
        }
        else
        {
            console.log('else')
            updateRooQuestWithoutCall(rooquests[activeMarkerIndex]);
        }
        setShowInfoModal('RQ', true);
      };

      const markerOnClick = (rq,index) => {
        console.log('set active marker')
        setActiveMarkerIndex(index);
        const result = [rq]
        setWindowInfoRQCard([...result]);
        // console.log(result);
        // updateRooQuestWithoutCall(rooquest);
        setSlidingRooquests([...result]);

        setOpenInfoWindow(true);
      }

      const getReverseGeocodingData = (lat, lng) => {
        let latlng = new google.maps.LatLng(lat, lng);
        // This is making the Geocode request
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({location: latlng},  (results, status) =>{
            if (status == google.maps.GeocoderStatus.OK) {
              console.log('geocoding')
                console.log(results);

                setPosition({lat:lat,lng:lng});
                setInputText(results[0].formatted_address);
                // var address = (results[0].formatted_address);
            }
        });
      }

      useEffect(()=> {
        if(activeMarkerIndex === -1)
            return
        const date = getDateElements(rooquests[activeMarkerIndex].date);
        setDateElements(date);

        if (rooquests[activeMarkerIndex] && rooquests[activeMarkerIndex].offers && rooquests[activeMarkerIndex].offers.length > 0) {
            const OfferText = rooquests[activeMarkerIndex].offers.filter(item => item.status === "ACTIVE" && auth && auth.user  && auth.user._id == item.delivererID )
            setShowOfferText(OfferText);
        }

      },[activeMarkerIndex]);

      const closeInfoWindow = () => {

        if(!openInfoWindow)
            return;
        console.log('closing info window')
        setOpenInfoWindow(false);
        setActiveMarkerIndex(-1);
      }

      const handleClusterClick = (cluster) => {
        console.log("cluster click")
        console.log("cluster")
        
        if(mapRef.current.getZoom() < 7)
        {
           
            const markers = cluster.getMarkers();
            const results = rooquests.filter(rq => markers.some(marker => (rq.pickupAddress.lat === marker.position.lat() && rq.pickupAddress.lng === marker.position.lng())));
            setSlidingRooquests([...results]);
            setOpenInfoWindow(true);
            const bounds = cluster.getBounds();
            mapRef.current.fitBounds(bounds)
            return;
        }
        setShowList(false)
        const markers = cluster.getMarkers();
        const results = rooquests.filter(rq => markers.some(marker => (rq.pickupAddress.lat === marker.position.lat() && rq.pickupAddress.lng === marker.position.lng())));
        setSlidingRooquests([...results]);
        setOpenInfoWindow(true);
      }

      const saveModalFilters = async (latitude,longitude) => {
        try {
            //use the saved filters bu the user to make the requests for the data
            // const filtersToSend = {
            //     // minDelivererFee : minDelivererFee,
            //     // storeName : "",
            //     // maxPrice : maxPrice,
            //     // sortBy: sortBy,
            //     // radius: filersStateradius
            //     ...filtersState
            // }
            await getFilteredRooquests(
                auth.user._id,
                filtersState,
                latitude,
                longitude
                );
            await loadUser();
        //   setRooQuestSearchRadiusFilter(radius*1000);
        } catch (err) {
        await getAllRooQuests();
        console.error(err);
        }
      };

      const cleanup = () => {
        setShowPopover(false);
        setShowList(false);
      }

      const clearFilters = () => {
        setFiltersState({
            ...filters
        })
      }

      const onClickMap = (e) => {
        if(openInfoWindow)
            setOpenInfoWindow(false);
        setShowList(false)
      }

      const onCircleClick = () =>{
        setShowList(false);
        setOpenInfoWindow(false);
      }

      useIonViewWillLeave(() => {
        setShowList(false);
      })



      const editPinLocation = () => {
        if(!isDraggable)
        {
            setZIndex(1);
            setMemory({inputText : inputText,loc: {position}});
        }
        else
        {
            setZIndex(-1);
            setPosition(memory.loc.position);
            setInputText(memory.inputText)
        }

        setIsDraggable(!isDraggable);

      }

      const handleClick = () => {
        setShowList(!showList);
      }

      const onDragMap = () => {
        setShowList(false);
      }

    

    return(
        <IonPage>
           <LoaderContainer />
            <IonContent>
            <div className = "DeliverNow">


            <Header
                    position={position}
                    setPosition = {setPosition}
                    inputText = {inputText}
                    setInputText = {setInputText}
                    getCurrentPosition = {getCurrentPosition}
                    showPopover = {showPopover}
                    setShowPopover = {setShowPopover}
                    // radius = {filtradius}
                    // setRadius = {setRadius}
                    radiusUpperRange = {radiusUpperRange}
                    filtersState = {filtersState}
                    setFiltersState = {setFiltersState}
                    saveModalFilters={saveModalFilters}
                    clearFilters = {clearFilters}
                    setShowList = {setShowList}
                    auth = {auth}
                    getRQs= {getRQs}
                />

            <div className="map" style={{height:'100%'}}>
				{  rooquests &&
					<>

						<GoogleMap
                        onClick={ e => onClickMap(e)}
                        center={ position }
                        zoom={ zoom }
                        options = {options}
                        mapContainerStyle= {{width:'100%', height:'100%'}}
                        onLoad = {onMapLoad}
                        
                        >

                        <Marker
                            position={position}
                            icon ={require('./my-location-blue.png')}
                            draggable = {isDraggable}
                            onLoad = {onMarkerLoad}
                            onDragEnd = {onDragendMarker}
                            zIndex = {-1}
                        />
                         {/* <MarkerClusterer
                            onLoad = {onClustererLoad}
                            options={clustererOptions}
                            onClick={handleClusterClick}
                            zoomOnClick = {false}
                        > */}

                            {/* {
                                 rooquests && rooquests.length !== 0 && rooquests.map((rq, index) => {

                                    return (
                                    <>
                                    <Marker
                                    onClick = {() => markerOnClick(rq,index)}
                                    key={ index }
                                    position={rq.pickupAddress}
                                    // clusterer = {clusterer}
                                    />
                                    </>
                                    )
                                    })
                            } */}
                        {/* </MarkerClusterer> */}
                            <Circle center={position} radius={filtersState.radius*1000} options ={circleOptions} onLoad={onCircleLoad} onClick ={onCircleClick} />

						</GoogleMap>

                        {/* openInfoWindow */}{console.log(openInfoWindow)}
                        {openInfoWindow && <div className={ `swiper animate__animated` } >
                        
						<Swiper spaceBetween={ 12 } watchOverflow = {true} navigation = {true}  slidesPerView={'auto'} >
                            {
                                
                               slidingRooquests && slidingRooquests.length !== 0 && slidingRooquests.map((rq) => {
                                    let showOfferText = [];
                                    if (rq && rq.offers && rq.offers.length > 0) {
                                        showOfferText = rq.offers.filter(item => item.status === "ACTIVE" && auth && auth.user  && auth.user._id == item.delivererID )
                                    }
                                    const dateElements = getDateElements(rq.date);
                                    return (
                                        <SwiperSlide className='slide-container'>
                                        <div

                                        // className="clickableDiv"
                                          onClick={(e) => openItemModal(rq)}
                                        key={`feedActive_${rq._id}`}
                                        >
                                        <RQCard
                                            rooquest={rq}
                                            extraStyling={{ borderRight: '4px solid #048800' }}
                                        >
                                            <MyRQCardTop rooquest={rq} type="feed" />
                                            <div className="RQFeedCardContent">
                                            <div className="mainContent">
                                                <div className="leftRouteInfo">
                                                <RouteInfoVertical rooquest={rq} />
                                                </div>
                                                <div className="rightRQInfo">
                                                <div className="actionButtons">
                                                    <div className="saveComponent DONT_TRIGGER_CARD_CLICK">
                                                    <SaveRooQuestComponent
                                                        rooquest={rq}
                                                        extraStyling={{}}
                                                    />
                                                    </div>
                                                </div>

                                                <div className="posted">
                                                    <p>
                                                    <span>{t('Posted')}:</span> <br /> {dateElements.month}{' '}
                                                    {dateElements.date} {dateElements.year} <br />{' '}
                                                    {dateElements.formattedTime}
                                                    </p>
                                                </div>
                                                {showOfferText && showOfferText.length > 0 && < div className='offersubmitted'>
                                                    <p className='offersubmittext'>{t('Offer Submitted')}</p>
                                                </div>
                                                }
                                                </div>
                                            </div>
                                            </div>
                                        </RQCard>
                                        </div>
                                        </SwiperSlide>
                              );
                            })
                            }
						</Swiper>
					</div>}

                        {/* <IonFab vertical="bottom" horizontal="end" slot="fixed" onClick={ () => setMoveMode(!moveMode) }>
							<IonFabButton>
								<IonIcon icon={ moveMode ? flashOffOutline : flashOutline } />
							</IonFabButton>
						</IonFab> */}
                        {/* <SliderListCards rooquests = {rooquests} auth = {auth} /> */}

						<IonFab vertical="bottom" horizontal="end" slot="fixed" onClick={ () => {setShowList(!showList);setOpenInfoWindow(false);} }>
							<IonFabButton className='fabButton'>
								<IonIcon icon={ list } />
							</IonFabButton>
						</IonFab>

						<IonModal isOpen={ showList } mode = 'ios' onDidDismiss={ () => setShowList(false) } swipeToClose={ true } initialBreakpoint={ 0.6 } breakpoints={ [0, 1] } backdropBreakpoint={ 0.6 } className = "modal">
                        {/* <LoaderContainer /> */}
                            <ListModal records={ rooquests} auth={auth} openItemModal = {openItemModal} height = {height} saveModalFilters = {saveModalFilters} position={position} setShowPopover = {setShowPopover} showSaved = {showSaved} setShowSaved = {setShowSaved}  />
						</IonModal>
					</>
				}
			</div>
            </div>
            </IonContent>
		</IonPage>
    )
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    rooquest: state.rooquest,
    rooquests : state.rooquest.rooquests,
    feedFilterBar: state.feedFilterBar,
    filters: state.feedFilterBar.filters,
    isLoading : state.isLoading
  });

  export default connect(mapStateToProps, {
    getAllRooQuests,
    getFilteredRooquests,
    setPanToLocation,
    updateRooQuestWithoutCall,
    setShowInfoModal,
    setRooQuestSearchRadiusFilter,
    setDeliverNowFilters,
    loadUser,
    setIsLoading,
    updateUserLocation
  })(DeliverNow);
