import {
  SET_SELECTED_TAB,
  SET_SHOW_RQ_INFO_MODAL,
  SET_SHOW_MYRQ_INFO_MODAL,
  SET_SHOW_MYDELIV_INFO_MODAL,
  SET_SHOW_ENLARGE_IMAGE_MODAL,
  SET_SHOW_RATINGS_MODAL,
  SET_SHOW_POSTRQ_MODAL,
  SET_SHOW_EDITRQ_MODAL,
  SET_SHOW_PROMO_MODAL,
  SET_SHOW_BUG_REPORT_MODAL,
  SET_SHOW_MAP_MODAL,
  SET_SHOW_TUTORIAL_MODAL,
  SET_SHOW_LOCATION_ON_MAP_MODAL,
  SET_SHOW_MYADDRESSES_MODAL,
  SET_SHOW_NOTIFICATION_RANGE_MODAL,
  SET_SHOW_ITEM_RATINGS_NOTIF_MODAL,
  SET_SHOW_NOTIF_MODAL,
  SET_SHOW_FIRST_OFFER_SUCCESS_MODAL
} from './navigation-types';

export const setSelectedTab = (selectedTab) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_TAB,
    payload: selectedTab,
  });
};

export const setShowInfoModal = (type, bool) => (dispatch) => {
  switch (type) {
    case 'RQ':
      dispatch({
        type: SET_SHOW_RQ_INFO_MODAL,
        payload: {visible: bool, type: type},
      });
      break;
    case 'MYRQ':
      dispatch({
        // type: SET_SHOW_MYRQ_INFO_MODAL,
        type: SET_SHOW_RQ_INFO_MODAL,
        payload: {visible: bool, type: type},
      });
      break;
    case 'MYDELIV':
      dispatch({
        // type: SET_SHOW_MYDELIV_INFO_MODAL,
        type: SET_SHOW_RQ_INFO_MODAL,
        payload: {visible: bool, type: type},
      });
      break;
    default:
    // Do Nothing
  }
};

export const setShowNotifModal = (visible) => (dispatch) => {
  dispatch({
    type : SET_SHOW_NOTIF_MODAL,
    payload : visible
  })

}

export const setShowEnlargeImageModal = (visible, src) => (dispatch) => {
  dispatch({
    type: SET_SHOW_ENLARGE_IMAGE_MODAL,
    payload: { visible: visible, src: src },
  });
};

export const setShowRatingsModal = (visible, type) => (dispatch) => {
  dispatch({
    type: SET_SHOW_RATINGS_MODAL,
    payload: { visible, type },
  });
};

export const setShowFirstOfferSuccessModal = (visible)  => (dispatch) => {
  dispatch({
    type:SET_SHOW_FIRST_OFFER_SUCCESS_MODAL,
    payload : visible
  })

}

export const setShowItemRatingsNotifModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_SHOW_ITEM_RATINGS_NOTIF_MODAL,
    payload: bool,
  });
};

export const setShowPostRQModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_SHOW_POSTRQ_MODAL,
    payload: bool,
  });
};

export const setShowEditRQModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_SHOW_EDITRQ_MODAL,
    payload: bool,
  });
};

export const setShowPromoModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_SHOW_PROMO_MODAL,
    payload: bool,
  });
};

export const setShowBugReportModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_SHOW_BUG_REPORT_MODAL,
    payload: bool,
  });
};

export const setShowMyAddressesModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_SHOW_MYADDRESSES_MODAL,
    payload: bool,
  });
};

export const setShowNotificationRangeModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_SHOW_NOTIFICATION_RANGE_MODAL,
    payload: bool,
  });
};

export const setShowMapModal = (visible, coords = null) => (dispatch) => {
  dispatch({
    type: SET_SHOW_MAP_MODAL,
    payload: { visible, coords },
  });
};

export const setShowLocationOnMapModal = (
  visible,
  pickupLocation = null,
  deliveryLocation = null,
  showFullAddress = true
) => (dispatch) => {
  dispatch({
    type: SET_SHOW_LOCATION_ON_MAP_MODAL,
    payload: { visible, pickupLocation, deliveryLocation, showFullAddress },
  });
};

export const setShowTutorialModal = (visible, options = null) => (dispatch) => {
  dispatch({
    type: SET_SHOW_TUTORIAL_MODAL,
    payload: { visible, options },
  });
};
