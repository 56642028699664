import axios from 'axios'; // Register User By Email
import { backendAddress } from '../../App';
import { launchToast,launchToastCard } from '../layout/toast';
import {
  ADD_NEW_DELIVERY_ADDRESS,
  DELETE_DELIVERY_ADDRESS,
  UPDATE_THEME,
  UPDATE_LANGUAGE,
  UPDATE_UNIT_METHOD,
  UPDATE_THEMES,
  UPDATE_REQ_EMAIL_NOTIFICATION_METHOD,
  UPDATE_DEL_EMAIL_NOTIFICATION_METHOD,
  UPDATE_REQ_NOTIFICATION_METHOD,
  UPDATE_DEL_NOTIFICATION_METHOD,
  UPDATE_NEW_REQ_NOTIFICATION_METHOD,
  UPDATE_USER_MODE
} from './user-types';
import { USER_LOADED } from '../auth/auth-types';
import { deleteUser } from '../auth/auth';

export const addDeliveryAddress = (address) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(address);

  const errors = [];
  Object.keys(address).forEach((key) => {
    if (address[key] === '') {
      errors.push(key);
    }
  });

  if (errors.length === 0) {
    try {
      const res = await axios.put(
        backendAddress + '/api/users/add-address',
        body,
        config
      );

      dispatch({
        type: ADD_NEW_DELIVERY_ADDRESS,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      console.error(err);
    }
  } else {
    return { msg: 'All Fields Required', errors };
  }
};

export const updateUnitMethod = (unitdata) => async (dispatch) => {
  try {
    await axios.put(backendAddress + '/api/users/updateDistanceUnit/' ,  { distanceUnit: unitdata });
    dispatch({
      type: UPDATE_UNIT_METHOD,
      payload: unitdata,
    });

    // dispatch(launchToast('Unit Method Updated Successfully', 2000, 'bottom'));
    dispatch(launchToastCard({ 
      msg: 'Unit Method Updated Successfully',
      type: 'success',
      duration: 8500,
  }))
    return unitdata;
  } catch (err) {
    console.error(err);
    // dispatch(launchToast(err, 2000, 'bottom'));
    dispatch(launchToastCard({ 
      msg: err,
      type: 'error',
      duration: 8500,
  }))
  }
};

export const updateRooquestEmailMethod = (data) => async (dispatch) => {
  try {
    await axios.put(backendAddress + '/api/users/updateRooquesterEmail/' + data);
    dispatch({
      type: UPDATE_REQ_EMAIL_NOTIFICATION_METHOD,
      payload: data,
    });
    dispatch(launchToastCard({ 
      msg: 'Rooquest Email Notification Settings Updated Successfully',
      type: 'success',
      duration: 8500,
   }))
    return data;
  } catch (err) {
    console.error(err);
    dispatch(launchToastCard({ 
      msg: err,
      type: 'error',
      duration: 8500,
   }))
  }
};

export const updateRooquestNotificationMethod = (data) => async (dispatch) => {
  try {
    await axios.put(backendAddress + '/api/users/updateRooquesterNotification/' + data);
    dispatch({
      type: UPDATE_REQ_NOTIFICATION_METHOD,
      payload: data,
    });
    dispatch(launchToastCard({ 
      msg: 'Rooquest Notification Settings Updated Successfully',
      type: 'success',
      duration: 8500,
   }))
    return data;
  } catch (err) {
    console.error(err);
    dispatch(launchToastCard({ 
      msg: err,
      type: 'error',
      duration: 8500,
   }))
  }
};

export const updateNewRooquestNotificationMethod = (data) => async (dispatch) => {
  try {
    await axios.put(backendAddress + '/api/users/updateNewRooquestNotification/' + data);
    dispatch({
      type: UPDATE_NEW_REQ_NOTIFICATION_METHOD,
      payload: data,
    });
    dispatch(launchToastCard({ 
      msg: 'New Rooquest Created By User Notification Settings Updated Successfully',
      type: 'success',
      duration: 8500,
   }))
    return data;
  } catch (err) {
    console.error(err);
    dispatch(launchToastCard({ 
      msg: err,
      type: 'error',
      duration: 8500,
   }))
  }
};

export const updateDelivererNotificationMethod = (data) => async (dispatch) => {
  try {
    await axios.put(backendAddress + '/api/users/updateDelivererNotification/' + data);
    dispatch({
      type: UPDATE_DEL_NOTIFICATION_METHOD,
      payload: data,
    });
    dispatch(launchToastCard({ 
      msg: 'Deliverer Notification Settings Updated Successfully',
      type: 'success',
      duration: 8500,
   }))
    return data;
  } catch (err) {
    console.error(err);
    dispatch(launchToastCard({ 
      msg: err,
      type: 'error',
      duration: 8500,
   }))
  }
};

export const updateDelivererEmailMethod = (data) => async (dispatch) => {
 try {
   await axios.put(backendAddress + '/api/users/updateDelivererEmail/' + data);
   dispatch({
     type: UPDATE_DEL_EMAIL_NOTIFICATION_METHOD,
     payload: data,
   });
   dispatch(launchToastCard({ 
    msg: 'Deliverer Email Notification Settings Updated Successfully',
    type: 'success',
    duration: 8500,
 }))
   return data;
 } catch (err) {
   console.error(err);
   dispatch(launchToastCard({ 
    msg: err,
    type: 'error',
    duration: 8500,
 }))
 }
};

export const deleteDeliveryAddress = (addressID) => async (dispatch) => {
  try {
    await axios.put(backendAddress + '/api/users/remove-address/' + addressID);

    dispatch({
      type: DELETE_DELIVERY_ADDRESS,
      payload: addressID,
    });

    // dispatch(launchToast('Address Removed', 2000, 'bottom'));
    dispatch(launchToastCard({ 
      msg: 'Address Removed',
      type: 'success',
      duration: 8500,
  }))
  } catch (err) {
    console.error(err);
  }
};

export const updateTheme = (theme) => async (dispatch) => {
  document.body.classList.toggle('dark', theme === 'dark');
  await axios.put(backendAddress + '/api/users/preferences/theme/' + theme);
  dispatch({
    type: UPDATE_THEME,
    payload: theme,
  });
};

export const updateLanguage = (language) => async (dispatch) => {
  try {
  await axios.put(backendAddress + '/api/users/updateUserLanguage/' + language);
  dispatch({
    type: UPDATE_LANGUAGE,
    payload: language,
  });
  // dispatch(launchToast('User language has been updated successfully', 2000, 'bottom'));
  dispatch(launchToastCard({ 
    msg: 'User language has been updated successfully',
    type: 'success',
    duration: 8500,
}))
  return language;
 } catch (err) {
    console.error(err);
    // dispatch(launchToast(err, 2000, 'bottom'));
    dispatch(launchToastCard({ 
      msg: err,
      type: 'error',
      duration: 8500,
  }))
  }
} 

export const updateUserMode = (mode) => async (dispatch) => {
  try {
  const res = await axios.put(backendAddress + '/api/users/updateUserMode/' + mode);
  dispatch({
    type: UPDATE_USER_MODE,
    payload: mode,
  });
  // dispatch(launchToast('User language has been updated successfully', 2000, 'bottom'));
//   dispatch(launchToastCard({ 
//     msg: `The app has been successfully switched to ${mode} mode`,
//     type: 'success',
//     duration: 8500,
// }))
  return mode;
 } catch (err) {
    console.error(err);
    // dispatch(launchToast(err, 2000, 'bottom'));
    dispatch(launchToastCard({ 
      msg: err,
      type: 'error',
      duration: 8500,
  }))
  }
}

//change theme
export const updateThemes = (theme) => async (dispatch) => {
  try {
  await axios.put(backendAddress + '/api/users/updateUserColorTheme/' + theme);
  dispatch({
    type: UPDATE_THEMES,
    payload: theme,
  });
  // dispatch(launchToast('User theme has been updated successfully', 2000, 'bottom'));
  dispatch(launchToastCard({ 
    msg: 'User theme has been updated successfully',
    type: 'success',
    duration: 8500,
}))
  return theme;
 } catch (err) {
    console.error(err);
    // dispatch(launchToast(err, 2000, 'bottom'));
    dispatch(launchToastCard({ 
      msg: err,
      type: 'error',
      duration: 8500,
  }))
  }
} 

// Load Theme
export const loadTheme = (user) => {
  if (user && user.preferences && user.preferences.theme) {
    document.body.classList.toggle('dark', user.preferences.theme === 'dark');
  }
};

export const loadThemes = (user) => {
  if (user && user.theme) {
    document.body.classList.value = user.theme;
  }
};
export const tutorialCompleted = () => async (dispatch) => {
  try {
    const res = await axios.post(
      backendAddress + '/api/auth/intro-screen-shown'
    );
    if (res.data) {
      dispatch({
        type: USER_LOADED,
        payload: res.data.user,
      });
    }

    // dispatch(launchToast('Address Removed', 2000, 'bottom'));
  } catch (err) {
    console.error(err);
  }
};

//will remove the user from the User collection in mongodb, but will keep a copy in a backup collection
export const removeUserAccount = async () => {
  try {
    const res = await axios.delete(backendAddress+"/api/users/deleteUser")
  }catch(err){
    console.log(err)
  }
  await deleteUser();
}
// function res(res: any, arg1: number, arg2: string): any {
//   throw new Error('Function not implemented.');
// }

