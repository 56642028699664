import { IonAlert } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setShowAppAlert } from './actions/layout/appAlert';

const AddFundsRequestAlert = ({showAppAlert,setShowAppAlert}) => {

    const {t}  = useTranslation();
    return (
        <div>
        
        <IonAlert
                isOpen={showAppAlert}
                onDidDismiss={() => setShowAppAlert(false)}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Insufficient Funds')}!`}
                message={
                    `${t('Your courrier is requesting more funds to complete your delivery. You can chat with him for more details. To accept his request, go in MyRooquests and click on the appropriate Rooquest. Then click on the "Approve Requested Funds" button.')}`
                }
                // inputs={[ { name: 'amount', type: 'radio', label:'Do not show this again'} ]}
                // inputs={[ { name: 'amount', type: 'number', placeholder: `${t('Amount Needed')}` }, ]}
                buttons={[
                    // { text: `${t('Cancel')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Confirm')}`,
                        handler: async (alertData) => {
                          setShowAppAlert(false);
                        },
                    },
                ]}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    showAppAlert: state.navigation.showAppAlert,
});
export default connect(mapStateToProps, { setShowAppAlert})(AddFundsRequestAlert);
