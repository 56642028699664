import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import './ToastCards.scss'
import { connect } from 'react-redux'
import { IonIcon, } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { removeToastCard } from '../../actions/layout/toast'

import { CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next'

export type ToastCard = {
    id?: string,
    msg: string,
    type?: string,
    duration?: number,
    button?: {
        text: string,
        onClick: () => void,
    }
}

const ToastCard = ({ toastCard, removeToastCard }) => {
    const { 
        id,
        msg = "Temporary Message with a very long message that will be truncated",
        type = "error", 
        duration = 10000, 
        button = null,
    } = toastCard;

    const [fadeout, setFadeout] = useState(false);
    const [percentage, setPercentage] = useState(3000);
    const intervalRef = useRef(null);
    const { t } = useTranslation();

    const removeToast = () => {
        setFadeout(true);
        setTimeout(() => removeToastCard(id), 350);
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => setFadeout(true), duration - 350);
        return () => clearTimeout(timeoutId);
    }, [duration]);


    const decreaseNum = () => setPercentage((prev) => prev - 50);
    useEffect(() => {
        setPercentage(duration + 300);
        intervalRef.current = setInterval(decreaseNum, 50);
        return () => clearInterval(intervalRef.current);
      }, [duration]);
        


    return (
        <div className={`ToastCard animate__animated animate__bounceInDown ${fadeout && 'fadeout'}`}>
            <div className={`cardContent ${type === 'success' ? 'success' : type === 'info' ? 'info' : ""}`}>

                <div className="main">
                    <div className="text">
                    <p>{t(msg)}</p>
                    </div>
                    
                    <div className="buttons">
                        {button && 
                            <div className="actionBtn" onClick={() => {
                                removeToast();
                                setTimeout(() => button.onClick(), 100);                                
                            }}>
                                <p>{button.text}</p>
                            </div>                            
                        }
                        <div className="progressCircle" onClick={removeToast}>
                            <CircularProgressbarWithChildren 
                                value={percentage} 
                                minValue={450}
                                maxValue={duration}
                                strokeWidth={5}
                            >
                                <IonIcon icon={closeOutline} className="closeIcon" />
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>

                    
                </div>
                                           
               


                        {/* {buttons.length > 0 &&
                            <div className="toast-buttons">
                                {buttons.map(button => (
                                    <div key={button.text} className="toast-button" onClick={() => {
                                        setTimeout(() => {
                                            button.onClick();
                                            setFadeout(true);
                                            setTimeout(() => removeToastCard(id), 350);
                                        }, 150);
                                    }}>
                                        <p>{button.text}</p>
                                    </div>
                                ))}
                            </div>
                        } */}
            </div>
        </div>

    )
}

const ToastCards = ({ toastCards, removeToastCard }) => {
    return (   
        <div className="ToastCards">
            { 
                toastCards !== null && toastCards.length > 0 &&
                toastCards.map((toastCard) => 
                     <ToastCard key={toastCard.id} toastCard={toastCard} removeToastCard={removeToastCard} />
                 )
            }
        </div>     
       
    )
}

ToastCards.propTypes = {
}

const mapStateToProps = (state) => ({
    toastCards: state.toast.toastCards,
})

export default connect(mapStateToProps, { removeToastCard })(ToastCards)
