import { IonAccordion, IonAccordionGroup, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { calculateCO2Savings } from '../PostNewRooQuest'
import FlipNumbers from 'react-flip-numbers';
import './Savings.scss'
import { toDaysMinutesSeconds } from '../../../utils/formatDate';


//this component show all the savings of the user on a specific rooquest
const Savings = ({rooquest}) => {

    const [moneySaved, setMoneySaved] = useState("0");
    const [showSavings, setShowSavings] = useState(0);
    const { costs,distance } = rooquest;
    const { t } = useTranslation();
    const accordionGroupRef = useRef(null);

    useEffect(() => {
        if (costs) {
            const distance = parseFloat(costs.fees.delivererFee.distance);
            const costPerKm = parseFloat(costs.fees.delivererFee.costPerKm);
            const saved = (distance * costPerKm).toFixed(2).toString();
            setMoneySaved(saved);

            const co2Saved = calculateCO2Savings(distance)  
            setShowSavings(co2Saved);
        }
    }, [costs]);

    return (
        <div className='savings'>
            <div className="rqinfo-section">
                <div className="sectionTitleRow">
                    <p className="sectionTitle">{t('Savings')}:</p>
                    <FlipNumbers 
                            height={14} 
                            width={9.5} 
                            color="#10be5f" 
                            // background="white" 
                            play perspective={100} 
                            duraction={0.4}
                            numbers={'$'+ moneySaved} />
                </div>
                <div className="RQCosts">
                    <IonAccordionGroup mode='ios' ref={accordionGroupRef} >
                        <IonAccordion mode='ios' value="deliveryFee">
                            <IonItem slot="header" tabIndex={-1}>
                                <IonLabel className="costAccordionTitle">
                                    <p className="numItems">{t("CO2 emissions")}</p>
                                </IonLabel>
                                <p className="itemCost">{`${showSavings.toFixed(1)} Kg`}</p>
                            </IonItem>
                        </IonAccordion>

                        {/* <IonAccordion mode='ios' value="deliveryFee">
                            <IonItem slot="header" tabIndex={-1}>
                                <IonLabel className="costAccordionTitle">
                                    <p className="numItems">{t('Travel Distance')}</p>
                                </IonLabel>
                                <p className="itemCost">{(parseFloat(costs.fees.delivererFee.distance)*2).toFixed(2)} Km</p>
                            </IonItem>
                        </IonAccordion> */}

                        <IonAccordion mode='ios' value="deliveryFee">
                            <IonItem slot="header" tabIndex={-1}>
                                <IonLabel className="costAccordionTitle">
                                    <p className="numItems">{t("Gas saved")}</p>
                                </IonLabel>
                                <p className="itemCost">{`$${moneySaved}`}</p>
                            </IonItem>
                        </IonAccordion>

                        <IonAccordion mode='ios' value="deliveryFee">
                            <IonItem slot="header" tabIndex={-1}>
                                <IonLabel className="costAccordionTitle">
                                    <p className="numItems">{t('Time saved')}</p>
                                </IonLabel>
                                <p className="itemCost">{toDaysMinutesSeconds(distance.seconds * 2)}</p>
                            </IonItem>
                        </IonAccordion>
                    </IonAccordionGroup>
                </div>
            </div>
        </div>
    )

}

export default Savings