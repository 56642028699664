import React, { Fragment } from 'react';
import { IonFab, IonButtons, IonFabButton, IonIcon } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const GreenBackButton = ({
  to,
  onClickFxn,
  secondaryButton = null,
  secondaryOnClickFxn,
}) => {
  const history = useHistory();

  const doFunction = () => {
    if (onClickFxn) onClickFxn();
    if (to) history.push(to);
  };

  return (
    <Fragment>
      <IonFab vertical="top" horizontal="start" slot="fixed">
        <IonButtons>
          <IonFabButton
            size="small"
            routerDirection="back"
            onClick={doFunction}
          >
            <IonIcon icon={arrowBackOutline} />
          </IonFabButton>
        </IonButtons>
      </IonFab>
      {secondaryButton && (
        <IonFab vertical="top" horizontal="end" slot="fixed">
          <IonButtons>
            <IonFabButton
              size="small"
              routerDirection="back"
              onClick={secondaryOnClickFxn}
            >
              <IonIcon icon={secondaryButton} />
            </IonFabButton>
          </IonButtons>
        </IonFab>
      )}
    </Fragment>
  );
};

GreenBackButton.propTypes = {
  to: PropTypes.string,
  onClickFxn: PropTypes.func,
  secondaryOnClickFxn: PropTypes.func,
  secondaryButton: PropTypes.any,
};

export default GreenBackButton;
