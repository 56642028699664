export const formatDateTime = (date) => {
  // Returns:  8:45PM Aug 6 2020
  const dateObj = new Date(date);

  // Time Element
  const time = dateObj.toLocaleTimeString('en-US');
  const timeComponents = time.split(':');
  const AMPM = time.split(' ');
  const formattedTime = `${timeComponents[0]}:${timeComponents[1]}${AMPM[1]}`;

  // Date Element
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  return `${formattedTime} ${
    monthNames[dateObj.getMonth()]
  } ${dateObj.getDate()} ${dateObj.getFullYear()}`;
};

export const getDateElements = (date) => {
  const dateObj = new Date(date);

  // Time Element
  const time = dateObj.toLocaleTimeString('en-US');
  const timeComponents = time.split(':');
  const AMPM = time.split(' ');
  const formattedTime = `${timeComponents[0]}:${timeComponents[1]}${AMPM[1]}`;

  // Date Element
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  return {
    formattedTime: formattedTime,
    month: monthNames[dateObj.getMonth()],
    date: dateObj.getDate(),
    year: dateObj.getFullYear(),
  };
};

export const formatRelativeTime = (date) => {
  const dateObj = new Date(date);
  const currentDate = new Date();

  // Time Element
  const time = dateObj.toLocaleTimeString('en-US');
  const timeComponents = time.split(':');
  const AMPM = time.split(' ');
  const formattedTime = `${timeComponents[0]}:${timeComponents[1]}${AMPM[1]}`;

  // Date Element
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  let dateToReturn;
  if (dateObj.getFullYear() !== currentDate.getFullYear()) {
    // If different year
    dateToReturn = `${
      monthNames[dateObj.getMonth()]
    } ${dateObj.getDate()} ${dateObj.getFullYear()} at ${formattedTime}`;
  } else if (
    dateObj.getMonth() !== currentDate.getMonth() ||
    dateObj.getDate() !== currentDate.getDate()
  ) {
    // If different month/day
    dateToReturn = `${
      monthNames[dateObj.getMonth()]
    } ${dateObj.getDate()} at ${formattedTime}`;
  } else {
    // If same day
    dateToReturn = `${formattedTime}`;
  }

  return dateToReturn;
};

export const toDaysMinutesSeconds = (totalSeconds) => {
  // const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const days = Math.floor(totalSeconds / (3600 * 24));

  // const secondsStr = makeHumanReadable(seconds, 'second');
  const minutesStr = makeHumanReadable(minutes, 'min');
  const hoursStr = makeHumanReadable(hours, 'h');
  const daysStr = makeHumanReadable(days, 'd');

  return `${daysStr}${hoursStr}${minutesStr}`;
}

function makeHumanReadable(num, singular) {
  return num > 0
    ? num + (num === 1 ? `${singular} ` : `${singular} `)
    : '';
}

export const addMinutes = (timestamp, minutes) => {
  console.log("add minutes")
  console.log(new Date(timestamp))
  const date = new Date(timestamp)
  return new Date(date.getTime() + minutes*60000);
}

export const compareDates = (timestamp) => {
  console.log("i am comparing dates")
  const currentDate = new Date();
  const dateToCompare = addMinutes(timestamp,30);
  console.log(currentDate > dateToCompare)
  return (currentDate > dateToCompare);
}

export const calculateTimeLeft = (date) => {
        
  let difference = +new Date(date) - +new Date();
              
  if (difference > 0) {
      return {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
      };
  }
  
  return {};
}