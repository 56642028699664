import React from 'react';
import PropTypes from 'prop-types';

import './FullPageModalCard.scss';

const FullPageModalCard = ({ title, children }) => {
  return (
    <div className="FULLPAGEMODALCARD">
      <div className="cardContainer">
        <h5 className="cardTitle">{title}</h5>
        <div className="cardInnerContainer">{children}</div>
      </div>
    </div>
  );
};

FullPageModalCard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FullPageModalCard;
