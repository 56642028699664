import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonModal,
  IonButton,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonAlert,
} from '@ionic/react';

import './OnTheWayModal.scss';
import { connect } from 'react-redux';
import { Camera, CameraResultType } from '@capacitor/camera';
import { addCircleOutline, chatbubbleEllipsesOutline, closeCircleOutline, closeOutline } from 'ionicons/icons';
import {
  updateRooQuestWithoutCall,
  postDeliveryId
} from '../../../../../../actions/rooquest/rooquest';
import { markRQAsOnTheWay } from '../../../../../../actions/rooquest/rooquest';
import { setShowEnlargeImageModal } from '../../../../../../actions/layout/navigation';
import { uploadReceiptImages } from '../../../../../../actions/firebase/firebase-storage';
import { USE_GPS_TRACKING } from '../../../../../../App';
import { useTranslation } from 'react-i18next';
import { Geolocation } from '@capacitor/geolocation'; 
// const { Geolocation } = Plugins;

const OnTheWayModal = ({
  rooquest,
  markRQAsOnTheWay,
  updateRooQuestWithoutCall,
  setShowEnlargeImageModal,
  postDeliveryId,
  step,
  checked,
  setChecked,
  receiptImages,
  setReceiptImages,
  didNotConfirmError,
  setDidNotConfirmError,
  amountPaid,
  setAmountPaid,
  openChat,
  cancelDelivery,
  isKeyboardVisible
}) => {
  const { t } = useTranslation();
  const [showCancelDeliveryAlert, setShowCancelDeliveryAlert] = useState(false);
  const modalRef = useRef(null);
  const [modalheight,setModalHeight] = useState(0);

  useEffect(() => {
    try{
      setModalHeight(modalRef.current.clientHeight)
    }
    catch(err)
    {
      console.log(err)
    }
  },[])
  const getImage = async () => {
    try
    {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        height: 500,
      });
  
      (await fetch(image.webPath)).blob().then((blobImage) => {
        setReceiptImages([
          ...receiptImages,
          { blobImage, webpath: image.webPath },
        ]);
      });
    }
    catch(err)
    {
      console.log("photo not taken")
      console.log(err)
    }
    
  };

  const enlargeImage = (e, src) => {
    if (e.target.tagName !== 'ION-ICON') {
      setShowEnlargeImageModal(true, src);
    }
  };
  const [amountPaidError, setAmountPaidError] = useState(false);
  
  return (
    <div className='onthewaymodal' ref={modalRef} style = {{minHeight : isKeyboardVisible && `${modalheight}px`}}>
    <div className="actionBtns">
            {/* {mydeliv_showMarkAsOnTheWay && 
                <div className="deliverBtn" >
                        <p>{`Mark As On The Way`}</p>
                </div>} */}
                {/* {mydeliv_showMarkAsDelivered && <div className="deliverBtn" onClick={openDeliveryConfirmationModal}>
                        <p>{`Mark As Delivered`}</p>
                </div>} */}
                 <div className="contactBtn" onClick={async () => openChat()}>
                    <IonIcon icon={chatbubbleEllipsesOutline} />
                </div>
                <div className="contactBtn" onClick={() => setShowCancelDeliveryAlert(true)}>
                    <IonIcon icon={closeCircleOutline} />
                </div> 
            </div>
    <div className='confirmOnTheWayModal'>
      <div className="confirmOnTheWayModalDiv">
          <div className="modalContent">
            <div className="inputElements">
              <div className="receiptAndAmountContainer">
                {/* <p className="imageOfReceiptMessage">
                {t('Please provide')} { step === 1 ?<strong>{t('image of receipt')}</strong>:<strong>{t('image of the address and grocery')}</strong>} {step === 1 ? `${t('for proof of purchase of goods and the amount paid')}` : `${t('for proof that you delivered at the right address')}`} {t('(if applicable)')}.
                </p> */}
                <p className="imageOfReceiptMessage">
                  {t('Ask store clerk for a')} <strong>{t('gift receipt.')}</strong> {t('Please pay for all items on one receipt,confirm the price and updload the receipt for proof of purchase of goods.')}
                </p>

                <div className="rowOfImages">
                  {receiptImages.map((image, index) => (
                    <div
                      key={`receiptImage_${index}`}
                      className="imageContainer"
                      onClick={(e) => enlargeImage(e, image.webpath)}
                    >
                      <img
                        src={image.webpath}
                        alt="receipt"
                        className="receiptImage"
                      />
                      <div
                        className="removeImage"
                        onClick={() => {
                          const newArr = [...receiptImages];
                          newArr.splice(index, 1);
                          setReceiptImages(newArr);
                        }}
                      >
                        <IonIcon icon={closeOutline} />
                      </div>
                    </div>
                  ))}
                  {receiptImages.length < 5 && (
                    <div className="addImageButton">
                      <IonButton onClick={async () => await getImage()}>
                        <IonIcon icon={addCircleOutline} />
                      </IonButton>
                    </div>
                  )}
                </div>

                <div className="amountPaid">
                  <IonLabel mode={'ios'} position="stacked" slot="start">
                    {t('Amount Paid')}:
                  </IonLabel>
                  <IonItem>
                    <IonInput
                      placeholder="0.00"
                      type="number"
                      value={parseFloat(amountPaid.toFixed(2))}
                      max={(parseFloat(rooquest.rooquest.costOfGoods)).toString()}
                      onIonChange={(e) => {
                        setAmountPaidError(false);
                        setAmountPaid(parseFloat(e.detail.value));
                      }}
                    />
                  </IonItem>
                </div>
                {amountPaidError &&
                  (amountPaid > rooquest.rooquest.costOfGoods ? (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '11px',
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      Enter valid amount not greater than cost of goods.
                    </p>
                  ) : (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '11px',
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      Please enter a valid amount.
                    </p>
                  ))}
              </div>

              
            </div>
          </div>
          
      </div>
      <div className="confirmationCheckBox">
              <IonItem>
                <IonCheckbox
                  checked={checked}
                  onIonChange={(e) => {
                    setDidNotConfirmError({ error: true, style: null });
                    setChecked(e.detail.checked);
                  }}
                />
                <IonLabel style={didNotConfirmError.style}>
                  {t("I have picked up the RooQuest and are ready to deliver the RooQuest as per Roo's Terms of Service.")}
                </IonLabel>
              </IonItem>
            </div>

    
    </div>
    <IonAlert
                isOpen={showCancelDeliveryAlert}
                onDidDismiss={() => setShowCancelDeliveryAlert(false)}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Cancel Delivery')} ?`}
                message={
                    `${t('Are you sure you want to cancel the delivery? This will be kept on your deliverer record and can lead to account penalties.')}`
                }
                inputs={[ { name: 'cancellationReason', type: 'text', placeholder: `${t('Reason for cancellation')}` }, ]}
                buttons={[
                    { text: `${t('Close')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Cancel Delivery')}`,
                        handler: async (alertData) => {
                        await cancelDelivery(alertData.cancellationReason);
                        },
                    },
                ]}
            />
    </div>
  );
};

OnTheWayModal.propTypes = {
  rooquest: PropTypes.object.isRequired,
  markRQAsOnTheWay: PropTypes.func.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
  postDeliveryId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  rooquest: state.rooquest,
  isKeyboardVisible : state.isKeyboardVisible
});

export default connect(mapStateToProps, {
  markRQAsOnTheWay,
  updateRooQuestWithoutCall,
  setShowEnlargeImageModal,
  postDeliveryId
})(OnTheWayModal);
