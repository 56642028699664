import store from "../store";
import { updateRooQuestWithoutCall,getRooQuestByID, getMyDeliveries } from "../actions/rooquest/rooquest";
import { setShowInfoModal } from "../actions/layout/navigation";
import { setShowChatModal } from "../actions/chat/chat";
import { getConversationByChatID} from "../actions/chat/chat";
export const openRooQuest = (rqID) => {
    try{
        const myRooquests = store.getState().rooquest.myrooquests;
        const rooquest = myRooquests.find((rq) => rq._id.toString() === rqID)
        store.dispatch(getRooQuestByID(rqID))
        store.dispatch(updateRooQuestWithoutCall(rooquest));
        store.dispatch(setShowInfoModal('MYRQ', true));
    }
    catch(err)
    {
        console.log(err)
    }
};

export const openDelivery = (rqID,url) => { 
    try{
        store.dispatch(getMyDeliveries());
        const myDeliveries = url !== '/offerrejected' ? store.getState().rooquest.mydeliveries : store.getState().rooquest.rooquests;
        const rooquest = myDeliveries.find((rq) => rq._id.toString() === rqID)
        store.dispatch(updateRooQuestWithoutCall(rooquest));
        if(url === '/offerrejected')
        {
            store.dispatch(setShowInfoModal('RQ', true));
        }
        else{
            store.dispatch( setShowInfoModal('MYDELIV', true));
        }

    }
    catch(err)
    {
        console.log(err)
    }
};

export const openChat = (convoID) => {
    try{
        const updatedConvo = store.dispatch(getConversationByChatID(convoID));
        // store.dispatch(setConversation(updatedConvo));
        store.dispatch(setShowChatModal(true));
    }
    catch(err)
    {
        console.log(err)
    }
}
