import { SET_SHOW_ALERT } from "./appAlert-types";

export const setShowAppAlert = (show) => async (dispatch) => {
    try {
      dispatch({
        type: SET_SHOW_ALERT,
        payload: show,
      });
    } catch (err) {
      console.error(err);
    }
  }