import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPopover, IonRange, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
import { Autocomplete } from "@react-google-maps/api";
import { arrowUndoCircle, closeCircleOutline, closeSharp, filterOutline, locateOutline, locationOutline, optionsOutline } from "ionicons/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import './Header.scss'

const Header = ({
    position,
    setPosition,
    inputText,
    setInputText,
    getCurrentPosition,
    showPopover,
    setShowPopover,
    radiusUpperRange,
    filtersState,
    setFiltersState,
    saveModalFilters,
    clearFilters,
    setShowList,
    auth,
    getRQs}) => {
    
    const autocompleteRef = useRef(null);
    const { t } = useTranslation();
   
    const onAutocompleteLoad = useCallback((autocomplete) => {
        autocompleteRef.current = autocomplete;
        // setBiasToAutoComplete(autocompleteRef);
    },[])

    const closeList = () => {
        setShowList(false)
    }

    const handleSearch = () => {
        saveModalFilters(position.lat,position.lng);
        // getRQs(position.lat,position.lng);
        setShowPopover(false);
    }

    return(
        <div className="header">
            <IonHeader className="ion-no-border GreenHeader">
                    <IonToolbar className="new-background-color">
                        <IonButtons slot="start">
                            <span onClick = {closeList}>
                            <IonBackButton defaultHref="/" text="" />
                            </span>
                        </IonButtons>
                        <IonTitle>{t("Deliver Now")}</IonTitle>
                    </IonToolbar>
                </IonHeader>
            <div className='searchBar'>
           
            <Autocomplete
            onLoad={onAutocompleteLoad}
            fields = {["address_components", "geometry", "name", "type", "formatted_address"]}
            restrictions = {{ country: ["ca","us", "ind"] }}
            onPlaceChanged = {()=> {
                try{
                const place = autocompleteRef.current.getPlace()
                console.log(place)
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setPosition({lat : lat, lng : lng});
                setInputText(place.formatted_address)}
                catch(err){
                    console.log(err);
                }
            }
            }
            // options = {autocompleteOptions}
            >
               
                <div className='row'>
                <div className='searchInput'>
                    
                    <span >
                        <IonIcon icon={locationOutline} />
                    </span>
                    <input 
                        type='text'
                        value={inputText}
                        placeholder={t('ex: 555 Main Street, Moncton, NB',{nsSeparator: false})}
                        onClick={() =>{setShowList(false)}}
                        onChange={(e)=> {
                            setInputText(e.target.value)
                        }}
                    />
                    <span onClick={() =>{setInputText('')}}><IonIcon icon={closeCircleOutline} /></span>
                    <span onClick={() =>{getCurrentPosition(true)}}><IonIcon icon={locateOutline} /></span>
                </div>
              
                <IonButton className="options" color="kargoroo-card-bg-color" onClick={() => setShowPopover(true)}>
                     <span>{t('Filters')}</span>
                    <span className='filterweb'><IonIcon className="optionicon" slot="end" icon={filterOutline}></IonIcon></span>
                </IonButton>
                <span className='filterspan'><IonIcon className="optionicon filtermob" onClick={() => setShowPopover(true)} slot="end" icon={filterOutline}></IonIcon></span>
                
                <IonPopover size="auto" id="popover-bottom"  isOpen={showPopover} onDidDismiss={() => setShowPopover(false)} >
                <div className='showInlinebtn'>
                
                <div
              className="closeButton"
              onClick={() => {
                setShowPopover(false)
              }}
            >
              <IonIcon icon={closeSharp}></IonIcon>
            </div>
                </div>
                    <IonGrid>
                    <IonRow>
                        <IonCol size-lg="3" size-md="3" size-sm="12" size="12">
                            <IonRange
                                min={0}
                                max={radiusUpperRange}
                                value={filtersState.radius}
                                mode="md"
                                pin={true}
                                onIonChange={(e) =>{
                                    // setRadius(e.detail.value as number);
                                    setFiltersState({...filtersState, radius :e.detail.value as number })
                                }
                                }
                            >
                                <IonLabel className="radiusLabel" slot="start">
                                    {t('Distance')}
                                </IonLabel>   
                                <IonLabel className="radiusLabel" slot="end">
                                    {filtersState.radius} km 
                                </IonLabel>
                            </IonRange>
                        </IonCol>
                        {/* <IonCol size-lg="3" size-md="3" size-sm="12" size="12">
                                <span className='selectlabel'>{t('Store Name')}</span>
                                        <IonItem  lines="none">
                                            <Autocomplete
                                                types={['establishment']}
                                                restrictions = {{ country: ["ca","us", "ind"] }}
                                                // fields = { ["name"]}
                                            >
                                                <input
                                                    className="storeName" 
                                                    type='text'
                                                    value={filterText}
                                                    placeholder={t('ex: Walmart,Canadian Tire, Costco etc.',{nsSeparator: false})}
                                                    onChange={(e)=> {
                                                        setFilterText(e.target.value)
                                                        setFiltersState({
                                                            ...filtersState,
                                                            storeName : e.target.value
                                                        })
                                                    }}
                                                />
                                        </Autocomplete>
                                        </IonItem>
                        </IonCol> */}
                        <IonCol size-lg="3" size-md="3" size-sm="12" size="12">
                        <span className='selectlabel'>{t('Minimum Delivery Fee')}</span>
                                        <IonItem  lines="none">
                                            <IonSelect className='deliverselect' value={String(filtersState.minDelivererFee)} onIonChange={(e) => {
                                                                                                                                setFiltersState({...filtersState,minDelivererFee:e.detail.value});
                                                                                                                                // setMinDelivererFee(e.detail.value)
                                                                                                                                }}>
                                                <IonSelectOption value="0">0$</IonSelectOption>
                                                <IonSelectOption value="10">10$</IonSelectOption>
                                                <IonSelectOption value="20">20$</IonSelectOption>
                                                <IonSelectOption value="30">30$</IonSelectOption>
                                                <IonSelectOption value="40">40$</IonSelectOption>
                                                <IonSelectOption value="50">50$</IonSelectOption>
                                            </IonSelect>
                                        </IonItem>
                        </IonCol>
                        <IonCol size-lg="3" size-md="3" size-sm="12" size="12">
                        <span className='selectlabel'>{t('Rooquest Max Price')}</span>
                                        <IonItem  lines="none">
                                        <IonSelect className='deliverselect' value={String(filtersState.maxPrice)} onIonChange={(e) =>{ 
                                                                                                                        setFiltersState({...filtersState,maxPrice:e.detail.value})
                                                                                                                        // setMaxPrice(e.detail.value)
                                                                                                                        }}>
                                                <IonSelectOption value="-1">{t('None')}</IonSelectOption>
                                                <IonSelectOption value="50">50$</IonSelectOption>
                                                <IonSelectOption value="100">100$</IonSelectOption>
                                                <IonSelectOption value="150">150$</IonSelectOption>
                                                <IonSelectOption value="200">200$</IonSelectOption>
                                                <IonSelectOption value="250">250$</IonSelectOption>
                                                <IonSelectOption value="300">300$</IonSelectOption>
                                            </IonSelect>
                                        </IonItem>
                        </IonCol>
                        {/* <IonCol size-lg="3" size-md="3" size-sm="12" size="12" >
                            <span className='selectlabel'>Listed Since</span>
                                <IonItem  lines="none">
                                    <IonSelect className='deliverselect' placeholder="mm/dd/yy" id="date">
                                        <IonPopover trigger="date" style={{'--background' : 'white'}} >
                                            <IonDatetime ></IonDatetime>
                                        </IonPopover>
                                    </IonSelect>
                                </IonItem>
                        </IonCol> */}
                        <IonCol size-lg="3" size-md="3" size-sm="12" size="12" >
                            <span className='selectlabel'>{t('Sort By')}</span>
                                        <IonItem  lines="none">
                                        <IonSelect className='deliverselect' value={filtersState.sortBy} onIonChange={(e) => {
                                                                                            setFiltersState({...filtersState,sortBy:e.detail.value});
                                                                                            // setSortBy(e.detail.value)
                                                                                            }}>
                                            <IonSelectOption value="none">{t('None')}</IonSelectOption>
                                            <IonSelectOption value="Price: Low To High">{t('Price')} : {t('Low To High')}</IonSelectOption>
                                            <IonSelectOption value="Price: High to Low">{t('Price')} : {t('High to Low')}</IonSelectOption>
                                            <IonSelectOption value="Listing Date: New to Old">{t('Listing Date')} : {t('New to Old')}</IonSelectOption>
                                            <IonSelectOption value="Listing Date: Old to New">{t('Listing Date')} : {t('Old to New')}</IonSelectOption>
                                            <IonSelectOption value="Distance (Nearest First)">{t('Distance (Nearest First)')}</IonSelectOption>
                                            <IonSelectOption value="Distance (Farthest First)">{t('Distance (Farthest First)')}</IonSelectOption>
                                        </IonSelect>
                                        </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className='delivermodalbtn'>
                        <IonCol  size-lg="6" size-md="6" size-sm="6" size="6">
                                        <IonButton item-end className="resetbtn" onClick={()=>{setShowPopover(false)}}>
                                            {t('Cancel')}
                                         <span className='reseticonspan'><IonIcon className="reseticon" slot="end" icon={arrowUndoCircle}></IonIcon></span>
                                        </IonButton>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-sm="6" size="6">
                            <IonButton className="searchbtn" onClick={handleSearch}>{t('Search')}</IonButton>
                        </IonCol>
                    </IonRow>
                    </IonGrid>
                </IonPopover>
                </div>
             </Autocomplete>
        </div> 
        </div>
        )
}

export default Header;