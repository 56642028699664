import { actionSheetController } from "@ionic/core";

const collectedtotalPrice = (state=0,action) => {
    switch(action.type){
        case 'INCREMENT_COLLECTED_TOTAL_PRICE':
            return state + action.payload;
        case 'DECREMENT_COLLECTED_TOTAL_PRICE':
            return state - action.payload;
        case 'SET_COLLECTED_TOTAL_PRICE':
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default collectedtotalPrice;