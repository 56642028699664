import React from 'react';
import { IonButton, IonGrid, IonRow, IonCol, IonContent, IonPage } from '@ionic/react';
import GreenHeader from '../../layout/GreenHeader/GreenHeader';
import { useHistory } from 'react-router-dom';
import './SingleItemReview.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GET_LOADED_COST_OF_GOODS_MULTIPLIER, GET_LOADED_KARGOROO_FEE } from '../../../firebase';
import { useTranslation } from 'react-i18next';

const SingleItemReview = (rooquest) => {
    const { t } = useTranslation();
    let myrooquest = rooquest.rooquest;
    if (!myrooquest) {
        myrooquest = {
            costOfGoods: 0,
            costOfDeliverer: 0,
            imgURLS:[],
            deliveryAddress:{
                country:''
            }
        }
    }
    let deladdress = myrooquest.deliveryAddress.country;
    let rqcostofgood = parseFloat(myrooquest.costOfGoods).toFixed(2);
    let costOfGoodsMultiplier = GET_LOADED_COST_OF_GOODS_MULTIPLIER();
    let rqcostofdeliverer = parseFloat(myrooquest.costOfDeliverer).toFixed(2);
    let itemcost = Number(rqcostofgood) * Number(costOfGoodsMultiplier);
    let delivcost = Number(rqcostofdeliverer) * Number(costOfGoodsMultiplier);
    let RooFee = GET_LOADED_KARGOROO_FEE();
    let Totalcost = Number(itemcost) + Number(delivcost) + Number(RooFee);
       const history = useHistory();
    const goSingleItemPayment = () => {
        history.push('./singleitempayment')
    }
    const goToBack = () => {
        history.goBack();
    }

    return (
  
        <IonPage className="singleitemreview">
               <IonContent>
                <GreenHeader />
                <div className="FullQuest">
                    <div className="questcontainer">
                        <div>
                            <p className="questTitle questLeft">{t('Single Item Quest')}</p>
                            <p className="questTitle questRight">{t('Step')} 3 / 4</p>
                        </div>
                        <div className="fullInnerContainer">
                            <div className="questBodyContent">
                                <div className="questHeaderReviewText">
                                    <p className="questheadertext">{t('Please review the details')}</p>
                                    <p className="questheadertext">{t('of your RooQuest')}</p>
                                </div>
                                <IonGrid className="questcardpadding">
                                  <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('Item Name')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                            <div className="singlereviewtext">{myrooquest.title} </div>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('Item Description')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                            <p className="singlereviewtext">{myrooquest.description}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('Images Uploaded')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                     <div className="firstimage">
                                           <div className="preview_images_main_wrapper">
                                                {
                                                myrooquest.imgURLS.map((item , index)=> {
                                                    return (
                                                    <div className="image_preview_wrapper" key={index}>
                                                    <div className="img_wrp">
                                                    <img  className="uploadedImage" src={item} alt="uploaded"/>
                                                    </div>
                                                    </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('Pickup From')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                            { myrooquest.pickupLocationType === "store" ? <p className="singlereviewtext">{myrooquest.pickupLocation } </p>  : null }
                                            { myrooquest.pickupLocationType === "address" ? <p className="singlereviewtext">{myrooquest.pickupAddress.streetAddress} ,{myrooquest.pickupAddress.city},{myrooquest.pickupAddress.country}  </p> : null}
                                            { myrooquest.pickupLocationType === "any" ?  <p className="singlereviewtext">{myrooquest.pickupLocationType}  </p> : null}
                                            { myrooquest.pickupLocationType === "other" ?  <p className="singlereviewtext">{myrooquest.pickupLocationType}  </p> : null}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('Deliver To')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                          { deladdress !== "Undefined" ? <p className="singlereviewtext">{myrooquest.deliveryAddress.streetAddress} ,{myrooquest.deliveryAddress.city},{myrooquest.deliveryAddress.country} </p> :null}
                                          { deladdress === "Undefined" ? <p className="singlereviewtext">{myrooquest.deliveryAddress.lat} ,{myrooquest.deliveryAddress.lng} </p> :null}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('Additional Notes')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                            <p className="singlereviewtext">{myrooquest.additionalNote}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('Item Cost')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                            <p className="singlereviewrighttext">
                                                <span className="percentagetext">${parseFloat(myrooquest.costOfGoods).toFixed(2)} x {costOfGoodsMultiplier} = </span>${itemcost.toFixed(2)}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('Deliverer Cost')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                            <p className="singlereviewrighttext"><span className="percentagetext">${parseFloat(myrooquest.costOfDeliverer).toFixed(2)} x {costOfGoodsMultiplier} = </span>${delivcost.toFixed(2)}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5">
                                            <p className="singlereviewlabel">{t('ROO Platform Fee')}:</p>
                                        </IonCol>
                                        <IonCol size="7">
                                            <p className="singlereviewrighttext">${RooFee.toFixed(2)}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="singlereviewtotalbg">
                                        <IonCol size="5" className="singleTotalpaddingnone">
                                            <p className="singlereviewtotalbtn">{t('Total')}</p>
                                        </IonCol>
                                        <IonCol size="7">
                                            <p className="singlereviewrighttext">${Totalcost.toFixed(2)}</p>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                              
                        </div>
                        <div className="bottombuttonSingleItem">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="6" text-center>
                                            <IonButton
                                                className="prevbtn"
                                                color="prevgrey"
                                              onClick={() => goToBack()}
                                            >
                                                {t('Go Back')}
                                            </IonButton>
                                        </IonCol>
                                        <IonCol size="6" text-center>
                                            <IonButton
                                                className="nextbtn"
                                                color="kargoroo_green"
                                              onClick={() => goSingleItemPayment()}
                                            >
                                                {t('Continue')}
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid> 
                                </div>
                    </div>
             </div>
          </div>
              </IonContent> 
              </IonPage>
    )
}
SingleItemReview.propTypes = {
     rooquest: PropTypes.object.isRequired,
  };
const mapStateToProps = (state) => ({
    rooquest: state.rooquest.rooquest
  });
export default connect(mapStateToProps)(SingleItemReview);