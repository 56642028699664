import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  IonButton,
  IonIcon,
  IonItem,
  IonModal,
  IonTextarea,
} from '@ionic/react';
import {
  setShowBugReportModal,
  setShowEnlargeImageModal,
} from '../../../actions/layout/navigation';
import sendRequests from '../../../hooks/sendRequests';
import { addCircleOutline, closeOutline } from 'ionicons/icons';
import { CameraResultType, Camera } from '@capacitor/camera';
import './ReportBug.scss';
import { uploadScreenshots } from '../../../actions/firebase/firebase-storage';
import { useTranslation } from 'react-i18next';
const BugReport = ({
  showBugReportModal,
  setShowBugReportModal,
  setShowEnlargeImageModal,
  auth,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [deliveryImages, setDeliveryImages] = useState([]);

  const closeModal = () => {
    setShowBugReportModal(false);
  };
  const enlargeImage = (e, src) => {
    if (e.target.tagName !== 'ION-ICON') {
      setShowEnlargeImageModal(true, src);
    }
  };

  const getImage = async () => {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      height: 500,
    });

    (await fetch(image.webPath)).blob().then((blobImage) => {
      setDeliveryImages([
        ...deliveryImages,
        { blobImage, webpath: image.webPath },
      ]);
    });
  };

  const onSubmitReport = async () => {
    closeModal();
    const urls = await uploadScreenshots(deliveryImages);
    const { doRequest } = sendRequests({
      url: '/api/bug-report/',
      method: 'post',
      body: {
        firebaseUrls: urls,
        message: text,
        userFirstName: auth.user.firstName,
        userLastName: auth.user.lastName,
        userEmail: auth.user.email,
      },
      onSuccess: (data) => {
        console.log('sent data');
      },
    });
    await doRequest();
    setText('');
    setDeliveryImages([]);
  };

  return (
    <IonModal
      mode="ios"
      isOpen={showBugReportModal}
      class="reportBugModal"
      showBackdrop={true}
      backdropDismiss={true}
      onWillDismiss={() => closeModal()}
    >
      <div className="modalContent">
        <h1 className="modalTitle">{t('Describe the Bug')}</h1>

        <div className="inputElements">
          <div className="radius">
            <IonItem lines='full'>
              <IonTextarea
                value={text}
                placeholder={t("Provide information about bug and/or leave feedback")}
                onIonChange={(e) => setText(e.detail.value!)}
                rows={3}
              ></IonTextarea>
            </IonItem>
          </div>
          <p>{t('Upload screenshots')}</p>
          <div className="confirmDeliveryModalDiv">
            <div className="rowOfImages">
              {deliveryImages.map((image, index) => (
                <div
                  key={`receiptImage_${index}`}
                  className="imageContainer"
                  onClick={(e) => enlargeImage(e, image.webpath)}
                >
                  <img
                    src={image.webpath}
                    alt="receipt"
                    className="receiptImage"
                  />
                  <div
                    className="removeImage"
                    onClick={() => {
                      const newArr = [...deliveryImages];
                      newArr.splice(index, 1);
                      setDeliveryImages(newArr);
                    }}
                  >
                    <IonIcon icon={closeOutline} />
                  </div>
                </div>
              ))}
              {deliveryImages.length < 3 && (
                <div className="addImageButton">
                  <IonButton onClick={async () => await getImage()}>
                    <IonIcon icon={addCircleOutline} />
                  </IonButton>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="actionButtons">
          <div className="row">
            <IonButton onClick={() => closeModal()}>{t('Close')}</IonButton>
            <IonButton onClick={onSubmitReport}>{t('Send report')}</IonButton>
          </div>
        </div>
      </div>
    </IonModal>
  );
};

BugReport.propTypes = {
  showBugReportModal: PropTypes.bool.isRequired,
  setShowBugReportModal: PropTypes.func.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  showBugReportModal: state.navigation.showBugReportModal,
  auth: state.auth,
});

// @ts-ignore
export default connect(mapStateToProps, {
  setShowBugReportModal,
  setShowEnlargeImageModal,
})(BugReport);
