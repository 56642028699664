export const GETTING_CONVERSATIONS = 'GETTING_CONVERSATIONS';
export const CONVERSATIONS_LOADED = 'CONVERSATIONS_LOADED';
export const GET_CONVERSATIONS_ERROR = 'GET_CONVERSATIONS_ERROR';

export const GETTING_CONVERSATION = 'GETTING_CONVERSATION';
export const CONVERSATION_LOADED = 'CONVERSATION_LOADED';
export const CLEAR_CONVERSATION = 'CLEAR_CONVERSATION';

export const GETTING_MESSAGES_BY_CHATID = 'GETTING_MESSAGES_BY_CHATID';
export const MESSAGES_LOADED = 'MESSAGES_LOADED';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const MESSAGES_ERROR = 'MESSAGES_ERROR';

export const SEND_PRIVATE_MESSAGE = 'SEND_PRIVATE_MESSAGE';
export const RECEIPT_OF_MESSAGE = 'RECEIPT_OF_MESSAGE';
export const ADD_UNREAD_TO_CONVERSATION = 'ADD_UNREAD_TO_CONVERSATION';
export const UPDATE_CONVERSATION_UNREAD_COUNT =
  'UPDATE_CONVERSATION_UNREAD_COUNT';

export const SHOW_TYPING_ANIMATION = 'SHOW_TYPING_ANIMATION';
export const HIDE_TYPING_ANIMATION = 'HIDE_TYPING_ANIMATION';

export const SET_SHOW_CHAT_MODAL = 'SET_SHOW_CHAT_MODAL';
