import React, { Fragment } from 'react';
import spinner from './spinner.svg';

export default () => (
  <Fragment>
    <img
      src={spinner}
      style={{ width: '160px', margin: 'auto', display: 'block' }}
      alt="Loading..."
    />
    {/* https://loading.io/spinner/custom/164145 */}
  </Fragment>
);
