import React, { useEffect, useRef, useState } from 'react'

const DeliveryStepper = ({ step = 0 }) => {
    return (
        <div className="DeliveryStepper">
            <div className="steps">
                <div className={`step ${step >= 0 ? 'selected' : null} ${step === 0 ? 'current': null}`}></div>
                <div className={`step ${step >= 1 ? 'selected' : null} ${step === 1 ? 'current': null}`}></div>
                <div className={`step ${step >= 2 ? 'selected' : null} ${step === 2 ? 'current': null}`}></div>
                <div className={`step ${step >= 3 ? 'selected' : null} ${step === 3 ? 'current': null}`}></div>
                <div className={`step ${step >= 4 ? 'selected' : null}`}></div>
            </div>
        </div>
    )
}

export default DeliveryStepper