import React from 'react';
import {IonCol, IonContent, IonGrid,IonList,IonRefresher,IonRefresherContent,IonRow} from "@ionic/react";
import styles from "./styles/ViewAll.module.scss";
import MyRQCardTop from '../layout/RQCard/MyRooQuests/MyRQCardTop';
import RouteInfoVertical from '../layout/RQCard/Feed/RouteInfo/RouteInfoVertical';
import SaveRooQuestComponent from '../layout/RQCard/Feed/SaveRooQuestComponent';
import { getDateElements } from '../../utils/formatDate';
import { useTranslation } from 'react-i18next';
import RQCard from '../layout/RQCard/RQCard';
import FeedFilterBar from '../feed/FilterBar/FeedFilterBar';

const ListModal = ({ records,auth,openItemModal,height,saveModalFilters,position,setShowPopover,showSaved,setShowSaved }) => {

	const {t} = useTranslation()

	const handleRefresh = (event) => {
		setTimeout(() => {
		  // Any calls to load data go here
		  saveModalFilters(position.lat,position.lng)
		  event.detail.complete();
		}, 2000);
	  }

	  const refreshFxn = () => {
		saveModalFilters(position.lat,position.lng)
	  }


	return (

		<div className='DeliverNow'>
		<IonGrid>
			<IonRow>
				<IonCol size="12">
					<div className = {styles.space}>
					<FeedFilterBar refreshFxn={refreshFxn} setShowPopover = {setShowPopover} showSaved = {showSaved} setShowSaved = {setShowSaved}/>

					</div>
					{/* <IonSearchbar placeholder="Search for a place..." value={ searchTerm }  /> */}
				</IonCol>
			</IonRow>
			
			<IonRow>
				<div className={ styles.viewCardContainerModal } style = {{height : `${height-55}px`}}>	
			<IonContent className='content'>
			{/* <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
			<IonRefresherContent></IonRefresherContent>
		</IonRefresher>					 */}
							<IonList>

				{ records && records.length > 0 ? (
					records.filter((rq)=>{
						if(showSaved)
							return auth.user.savedRooQuests.indexOf(rq._id) !== -1
						return true; 
					}).map((rq) => {
					let showOfferText = [];
					if (rq && rq.offers && rq.offers.length > 0) {
						showOfferText = rq.offers.filter(item => item.status === "ACTIVE" && auth && auth.user  && auth.user._id == item.delivererID )
					}
					const dateElements = getDateElements(rq.date);
					return (
						<div
						className="clickableDiv"
						  onClick={(e) => openItemModal(rq)}
						key={`feedActive_${rq._id}`}
						>
						<RQCard
							rooquest={rq}
							extraStyling={{ borderRight: '4px solid #048800' }}
						>
							<MyRQCardTop rooquest={rq} type="feed" />
							<div className="RQFeedCardContent">
							<div className="mainContent">
								<div className="leftRouteInfo">
								<RouteInfoVertical rooquest={rq} />
								</div>
								<div className="rightRQInfo">
								<div className="actionButtons">
									<div className="saveComponent DONT_TRIGGER_CARD_CLICK">
									<SaveRooQuestComponent
										rooquest={rq}
										extraStyling={{}}
									/>
									</div>
								</div>

								<div className="posted">
									<p>
									<span>{t('Posted')}:</span> <br /> {dateElements.month}{' '}
									{dateElements.date} {dateElements.year} <br />{' '}
									{dateElements.formattedTime}
									</p>
								</div>
								{showOfferText && showOfferText.length > 0 && < div className='offersubmitted'>
									<p className='offersubmittext'>{t('Offer Submitted')}</p>
								</div>
								}
								</div>
							</div>
							</div>
						</RQCard>
						</div>
              );
            })
          ) : (
            <img
              className="noRQs"
              src={require('./NoRooQuestsToShow.png')}
              alt=""
            />
          )}
	  </IonList>
			</IonContent>
				</div>
			</IonRow>
			
		</IonGrid>
		</div>
	);
}

export default ListModal;