import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { IonModal, IonButton, IonContent } from '@ionic/react';
import GreenBackButton from '../layout/GreenBackButton/GreenBackButton';

import './RQPaymentModal.scss';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import {
  updateRooQuestStatus,
  getMyRooQuests,
} from '../../actions/rooquest/rooquest';
import { payForRooQuest_Stripe } from '../../actions/payments/payments';
import { connect, useDispatch } from 'react-redux';
import { launchToast,launchToastCard } from '../../actions/layout/toast';
import { GET_LOADED_KARGOROO_FEE, GET_KARGOROO_FEE } from '../../firebase';

let payForRQResponse = null;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#012e0b',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '19px',
      '::placeholder': {
        color: '#7e858c',
      },
    },
    invalid: {
      color: '#f2311f',
      iconColor: '#f2311f',
    },
  },
  hidePostalCode: true,
};

const RQPaymentModal = ({
  showPaymentModal = { visible: false, rq: null },
  setShowPaymentModal,
  rooquest,
  payForRooQuest_Stripe,
  getMyRooQuests,
}) => {
  const dispatch = useDispatch();

  let rq = showPaymentModal.rq;

  // PAYMENT STATUS
  const [status, setStatus] = useState('ready');
  const [errors, setErrors] = useState([]);

  // PAY FOR ROOQUEST
  const stripe = useStripe();
  const elements = useElements();

  let KARGOROO_FEE = GET_LOADED_KARGOROO_FEE();
  const getKargoROOFee = async () => {
    KARGOROO_FEE = await GET_KARGOROO_FEE();
  };

  const calculateCost = () => {
    if (!rq.costOfGoods && !rq.costOfDeliverer) return KARGOROO_FEE;
    if (!rq.costOfGoods) return parseFloat(rq.costOfDeliverer) + KARGOROO_FEE;
    if (!rq.costOfDeliverer) return parseFloat(rq.costOfGoods) + KARGOROO_FEE;
    return (
      parseFloat(rq.costOfGoods) + parseFloat(rq.costOfDeliverer) + KARGOROO_FEE
    );
  };

  const payForRooQuest = async () => {
    setStatus('loading');
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        address: {
          line1: rooquest.deliveryAddress.streetAddress,
          city: rooquest.deliveryAddress.city,
          postal_code: rooquest.deliveryAddress.postal,
          state: rooquest.deliveryAddress.province,
        },
      },
    });

    const getStripePaymentAmount = () => {
      return (
        (parseFloat(rq.costOfGoods) +
          parseFloat(rq.costOfDeliverer) +
          KARGOROO_FEE) *
        100
      );
    };

    if (error) {
      setStatus('ready');
      // dispatch(launchToast(error.message, 3500, 'bottom'));
      dispatch(launchToastCard({ 
        msg: error.message,
        type: 'error',
        duration: 8500,
    }))
    } else {
      const { id } = paymentMethod;

      const res = await payForRooQuest_Stripe(
        rooquest,
        id,
        getStripePaymentAmount(),
        KARGOROO_FEE
      );
      if (res.type === 'success') {
        payForRQResponse = {
          rooquest: res.rooquest,
          rqGeneratedID: res.rqGeneratedID,
        };
        getMyRooQuests();
        setStatus('success');
      } else {
        setErrors([
          {
            type: res.type,
            msg: res.msg,
          },
        ]);
        setStatus('error');
      }
    }
  };

  return (
    <Fragment>
      <IonModal
        isOpen={showPaymentModal.visible}
        class="paymentModal"
        mode="ios"
        onWillDismiss={() => {
          setShowPaymentModal({ visible: false, rq: null });
        }}
        onDidDismiss={() => {
          setShowPaymentModal({ visible: false, rq: null });
        }}
        onWillPresent={async () => {
          await getKargoROOFee();
        }}
      >
        <div className="paymentModalContent">
          <div className="paymentModal-header-container">
            <GreenBackButton
              onClickFxn={() => {
                setShowPaymentModal({ visible: false, rq: null });
              }}
            />
            <h3 id="paymentModal-title">Pay For Your RooQuest</h3>
          </div>
          <IonContent>
            {(status === 'ready' || status === 'loading') && (
              <div className="paymentModalBodyContent">
                {/* <div className="paymentModal-body-container">
                  <h5 className="paymentModal-sectionTitle">
                    RooQuest Details
                  </h5>
                  <div className="paymentModal-body-contentcontainer">
                    <div className="paymentModal-titleContainer">
                      <img
                        id="paymentModal-rqImg"
                        src={
                          rooquest.image
                            ? rooquest.image
                            : require('../feed/NoImageAvailable.png')
                        }
                        alt=""
                      />
                      <h5 className="paymentModal-rqTitle">{rooquest.title}</h5>
                    </div>
                    <h5 className="paymentModal-location">
                      <span>Pickup at:</span>
                    </h5>
                    <div className="paymentModal-locationContainer">
                      <h5 className="paymentModal-location">
                        <span>Deliver to: </span>
                        {rooquest.deliveryAddress.streetAddress},{' '}
                        {rooquest.deliveryAddress.city},{' '}
                        {rooquest.deliveryAddress.postal},{' '}
                        {rooquest.deliveryAddress.province},{' '}
                        {rooquest.deliveryAddress.country}
                      </h5>
                    </div>
                  </div>
                </div> */}

                <div className="paymentModal-body-container">
                  <h5 className="paymentModal-sectionTitle">Payment Details</h5>
                  <div className="paymentModal-body-contentcontainer">
                    <div className="paymentModal-paymentContainer">
                      <div className="paymentItemLabels">
                        <h5 className="paymentModal-paymentDetails">
                          Cost of Item(s):
                        </h5>
                        <h5 className="paymentModal-paymentDetails">
                          Delivery Driver Fee:
                        </h5>
                        <h5 className="paymentModal-paymentDetails">
                          KargoROO Fee:
                        </h5>
                        <h5 className="paymentModal-paymentDetails total">
                          Total:
                        </h5>
                      </div>

                      <div className="paymentItemPrices">
                        <h5 className="paymentModal-paymentDetails">
                          $
                          {rq &&
                            rq.costOfGoods &&
                            parseFloat(rq.costOfGoods).toFixed(2)}
                        </h5>
                        <h5 className="paymentModal-paymentDetails">
                          $
                          {rq &&
                            rq.costOfDeliverer &&
                            parseFloat(rq.costOfDeliverer).toFixed(2)}
                        </h5>
                        <h5 className="paymentModal-paymentDetails">
                          ${KARGOROO_FEE.toFixed(2)}
                        </h5>
                        <h5 className="paymentModal-paymentDetails total">
                          ${rq && calculateCost().toFixed(2)} CAD
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="paymentModal-body-container">
                  <h5 className="paymentModal-sectionTitle">Pay Now</h5>

                  <div className="paymentModal-body-contentcontainer">
                    <div className="cardElementContainer">
                      <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </div>
                    {status === 'ready' ? (
                      <IonButton id="payForRQBtn" onClick={payForRooQuest}>
                        Confirm Payment
                      </IonButton>
                    ) : (
                      <IonButton id="payForRQBtn">Processing...</IonButton>
                    )}
                  </div>
                </div>
              </div>
            )}
            {status === 'error' && (
              <div className="paymentModalBodyContent">
                <div className="paymentModal-body-container errors">
                  <h5 className="paymentModal-sectionTitle">Error</h5>
                  <div className="paymentModal-body-contentcontainer">
                    <div className="errorContainer">
                      <h5 className="errorMsg">{errors[0].msg}</h5>
                      <IonButton
                        onClick={() => {
                          setStatus('ready');
                        }}
                      >
                        Return To Payment
                      </IonButton>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {status === 'success' && (
              <div className="paymentModalBodyContent">
                <div className="paymentModal-body-container">
                  <h5 className="paymentModal-sectionTitle">Success</h5>
                  <div className="paymentModal-body-contentcontainer">
                    <div className="successContainer">
                      <h5 className="successMsg">
                        Your payment completed successfully. Your RooQuest is
                        now posted and active.
                      </h5>
                      <h5 className="successMsg">
                        Your RooQuestID is:{' '}
                        <strong>{payForRQResponse.rqGeneratedID}</strong>
                      </h5>
                      <h5 className="successMsg">
                        Current Status is:{' '}
                        <strong>{payForRQResponse.rooquest.status}</strong>
                      </h5>
                      <IonButton
                        onClick={() => {
                          setStatus('ready');
                          setShowPaymentModal({ visible: false, rq: null });
                        }}
                      >
                        Close
                      </IonButton>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </IonContent>
        </div>
      </IonModal>
    </Fragment>
  );
};

RQPaymentModal.propTypes = {
  showPaymentModal: PropTypes.any.isRequired,
  setShowPaymentModal: PropTypes.func.isRequired,
  rooquest: PropTypes.object.isRequired,
  updateRooQuestStatus: PropTypes.func.isRequired,
  payForRooQuest_Stripe: PropTypes.func.isRequired,
  getMyRooQuests: PropTypes.func.isRequired,
};

export default connect(null, {
  updateRooQuestStatus,
  payForRooQuest_Stripe,
  getMyRooQuests,
})(RQPaymentModal);
