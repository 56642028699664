import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonButton,
  IonItem,
  IonInput,
  IonCheckbox,
  IonLabel,
  IonIcon
} from '@ionic/react';


// import {eye} from 'ionicons/icons';

import PropTypes from 'prop-types';

import './Home.scss';
import { connect } from 'react-redux';

import {
  signInWithGoogleFirebase,
  signInWithGoogleIOS,
  loginWithEmailAndPasswordFirebase,
  signInWithAppleFirebase,
} from '../../actions/auth/auth';

import {
  eye,
  eyeOffOutline,
} from 'ionicons/icons';

import { Link, Redirect } from 'react-router-dom';

// import '@codetrix-studio/capacitor-google-auth';
// import { Plugins } from '@capacitor/core';
import { Device } from '@capacitor/device';
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import PasswordResetModal from '../SettingsPages/PasswordResetModal/PasswordResetModal';

import { cfaSignIn } from 'capacitor-firebase-auth';
import { setSelectedTab } from '../../actions/layout/navigation';
import { launchToast,launchToastCard } from '../../actions/layout/toast';
import { ROO_VERSION_NUMBER } from '../../App';
import { sha256 } from 'js-sha256';


const Home = ({
  loginWithEmailAndPasswordFirebase,
  isAuthenticated,
  signInWithGoogleFirebase,
  signInWithGoogleIOS,
  signInWithAppleFirebase,
  setSelectedTab,
  launchToast,
  launchToastCard
}) => {
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: '',
    rememberme: false
  });

  const { email, password,rememberme } = loginFormData;
  const [checked, setChecked] = useState(false);

  const onChange = (e) => {
    setLoginFormData({ ...loginFormData, [e.target.name]: e.target.value.trim() });
  };

  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [showPassword,setShowPassord] = useState(false)

  const submitLogin = async (e) => {
    e.preventDefault();
    // loginWithEmail(email, password);
    loginWithEmailAndPasswordFirebase({
      email,
      password,
      rememberme
    });
  };

   const SignInWithGoogle = async () => {
    cfaSignIn('google.com').subscribe(
      (user) => {
        signInWithGoogleIOS(user).then((result) => {});
      },
      (err) => {
        console.error(err);
        // launchToast(err.message, 2000);
        launchToastCard({ 
          msg: err.message,
          type: 'error',
          duration: 8500,
      })
        
      }
    );
  };

  const [showAppleLogin, setShowAppleLogin] = useState(false);
  const showApple = async () => {
    // const { Device } = Plugins;
    let device = await Device.getInfo();
    setShowAppleLogin(device.platform === 'ios'); 
  };
  useEffect(() => {
    setSelectedTab(null);
    showApple();
  }, [setSelectedTab]);

  const SignInWithAppleFxn = async () => {
    const nonce = 'nonce';
    const hashedNonceHex = sha256(nonce);
    let options: SignInWithAppleOptions = {
      clientId: 'com.kargoroo.roo.app',
      redirectURI: 'https://app.kargoroo.ca',
      scopes: 'email,name',
      state: '12345',
      nonce: hashedNonceHex,
    };
    
    // const { SignInWithApple } = Plugins;
    // try {
    //   const res = await SignInWithApple.Authorize();
    //   if (res.response && res.response.identityToken) {
    //     signInWithAppleFirebase(res.response);
    //   } else {
    //   }
    // } catch (err) {
    //   console.log(err);
    //   launchToast(`ERROR: ${err.message}`, 2000);
    // }
    SignInWithApple.authorize(options)
  .then((res: SignInWithAppleResponse) => {
      if (res.response && res.response.identityToken) {
            signInWithAppleFirebase(res.response);
          } else {
          }
  })
  .catch(error => {
    console.log(error)
    // launchToast(`ERROR: ${error.message}`, 2000);
    launchToastCard({ 
      msg: error.message,
      type: 'error',
      duration: 8500,
  })
  });
  };

   const getRememberVal = (data) => {
     setChecked(data);
     setLoginFormData({ ...loginFormData, rememberme: data});
   }
  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }
  const handleKeyDown = (event) => {
    if(event.key === 'Enter'){
      submitLogin(event);
    }
  }
  return (
    <IonPage className="home">
      <IonContent className="homepage">
        <div className="versionContainer">
          <div className="home-versionNumber">
            <p>v{ROO_VERSION_NUMBER}</p>
          </div>
        </div>        
        <div className="home-container">
          <div className="homepage-content">
            <img
              className="top-pic"
              src={require('./signin-pic.png')}
              alt="Kargoroo header"
            />
            <form>
              <IonItem className="form-inputs form-email" color=" ">
                <IonInput
                  className="form-input"
                  placeholder="Email"
                  name="email"
                  autocomplete="on"
                  value={email}
                  onIonInput={(e) => onChange(e)}
                ></IonInput>
              </IonItem>
              <IonItem className="form-inputs form-password eye-password" color=" ">
                <IonInput
                  className="form-input"
                  type={showPassword ? "text":"password"}
                  placeholder="Password"
                  autocomplete="on"
                  name="password"
                  value={password}
                  onIonInput={(e) => onChange(e)}
                  onKeyPress={handleKeyDown}
                ></IonInput>
                <IonIcon icon={showPassword ?  eyeOffOutline:eye} onClick= {()=>{setShowPassord(!showPassword)}} />
                <IonButton
                  buttonType="text"
                  onClick={() => setShowPasswordResetModal(true)}
                >
                  Forgot?
                </IonButton>
              </IonItem>
              <IonItem className="form-inputs" color=" ">
            <IonCheckbox checked={checked} onIonChange={e => getRememberVal(e.detail.checked)} />
            <IonLabel className="form-input rem-mar-left" >Remember Me</IonLabel>
              </IonItem>
            </form>
            <IonButton
              className="signin-btn"
              expand="block"
              fill="solid"
              color="kargoroo_green"
              shape="round"
              text-capitalize="false"
              type="button"
              onClick={(e) => submitLogin(e)}
            >
              <span>Sign in now</span>
            </IonButton>
            <Link to="/register" className="createaccount-btn">
              <span>Create an account</span>
            </Link>

            <div className="alternateLoginContainer">
              <span>Or Continue With</span>
              <div className="row">
                {/* <IonButton
                  className="social_login"
                  fill="outline"
                  color="kargoroo_green"
                  shape="round"
                  text-capitalize="false"
                  href={backendAddress + '/api/auth/google'}
                >
                  <span className="google">
                    <img
                      className="googlelogo"
                      src={require('./google-icon.svg')}
                      alt="google logo"
                    />{' '}
                    <span className="google-text">Google</span>
                  </span>
                </IonButton> */}
                <IonButton
                  className="social_login"
                  // fill="outline"
                  shape="round"
                  text-capitalize="false"
                  onClick={() => SignInWithGoogle()}
                >
                  <span className="google">
                    <img
                      className="googlelogo"
                      src={require('./google-icon.svg')}
                      alt="google logo"
                    />{' '}
                    <p>Sign in with Google</p>
                  </span>
                </IonButton>
                {showAppleLogin && (
                  <IonButton
                    className="social_login"
                    // fill="outline"
                    shape="round"
                    text-capitalize="false"
                    onClick={() => SignInWithAppleFxn()}
                  >
                    <span className="apple">
                      <img
                        className="applelogo"
                        src={require('./apple-icon.png')}
                        alt="apple logo"
                      />{' '}
                      <p>Sign in with Apple</p>
                    </span>
                  </IonButton>
                    )} 
              </div>
            </div>
          </div>
        </div>
        <PasswordResetModal
          showModal={showPasswordResetModal}
          setShowModal={setShowPasswordResetModal}
        />
      </IonContent>
    </IonPage>
  );
};

Home.propTypes = {
  // register: PropTypes.func.isRequired,
  // loginWithEmail: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  signInWithGoogleFirebase: PropTypes.func,
  signInWithGoogleIOS: PropTypes.func,
  signInWithAppleFirebase: PropTypes.func,
  loginWithEmailAndPasswordFirebase: PropTypes.func,
  setSelectedTab: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  // register,
  // loginWithEmail,
  launchToast,
  launchToastCard,
  signInWithGoogleFirebase,
  signInWithGoogleIOS,
  signInWithAppleFirebase,
  loginWithEmailAndPasswordFirebase,
  setSelectedTab,
})(Home);


