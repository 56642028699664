import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  saveRooQuest,
  unsaveRooQuest,
} from '../../../../actions/rooquest/rooquest';
import { IonIcon } from '@ionic/react';
import { heart, heartOutline } from 'ionicons/icons';

import './SaveRooQuestComponent.scss';
import { useTranslation } from 'react-i18next';

const SaveRooQuestComponent = ({
  auth,
  saveRooQuest,
  unsaveRooQuest,
  rooquest,
  extraStyling,
}) => {
  const user = auth.user;
  const { t } = useTranslation();
  const isSaved = () => {
    if (user.savedRooQuests.length === 0) {
      return false;
    }
    return user.savedRooQuests.indexOf(rooquest._id) !== -1;
  };

  const [savedRQ, setSavedRQ] = useState(isSaved());

  const toggleSaved = (event) => {
    event.stopPropagation();
    if (!savedRQ === true) {
      saveRooQuest(rooquest._id);
    } else {
      unsaveRooQuest(rooquest._id);
    }
    setSavedRQ(!savedRQ);
  };
  return (
    <div className="saveRQBtn" style={extraStyling}>
      {savedRQ ? (
        <div className="saveContainer" onClick={toggleSaved}>
          <p className="DONT_TRIGGER_CARD_CLICK">{t('Unsave')}</p>
          <IonIcon
            className="icon saveRQButton DONT_TRIGGER_CARD_CLICK"
            icon={heart}
          ></IonIcon>
        </div>
      ) : (
        <div className="saveContainer" onClick={toggleSaved}>
          <p className="DONT_TRIGGER_CARD_CLICK">{t('Save')}</p>
          <IonIcon
            className="icon saveRQButton DONT_TRIGGER_CARD_CLICK"
            icon={heartOutline}
          ></IonIcon>
        </div>
      )}
    </div>
  );
};

SaveRooQuestComponent.propTypes = {
  auth: PropTypes.object.isRequired,
  saveRooQuest: PropTypes.func.isRequired,
  unsaveRooQuest: PropTypes.func.isRequired,
  rooquest: PropTypes.object.isRequired,
  extraStyling: PropTypes.any,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { saveRooQuest, unsaveRooQuest })(
  SaveRooQuestComponent
);
