/* eslint-disable no-useless-escape */
import React,{ Fragment, useRef, useState, useEffect,RefObject  } from 'react';
import PropTypes from 'prop-types';
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonPage,
 } from '@ionic/react';

import './SingleItemQuest.scss';
/// Image Upload
import { uploadRooQuestImage } from '../../../actions/firebase/firebase-storage';
import GoogleAddressAutoComplete from '../../settings/GoogleAddressAutoComplete/GoogleAddressAutoComplete';
import PeekingModal from '../../layout/PeekingModal/PeekingModal';
import GreenHeader from '../../layout/GreenHeader/GreenHeader';
import { launchToast,launchToastCard } from '../../../actions/layout/toast';
import { postNewRooQuest,userUpdatedRooQuest,updateRooQuestWithoutCall,deleteRooQuestByID } from '../../../actions/rooquest/rooquest';
import { connect, useDispatch } from 'react-redux';
import { close } from 'ionicons/icons';
import {
  setShowMapModal,
  setShowEnlargeImageModal
} from '../../../../src/actions/layout/navigation';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { GET_LOADED_COST_OF_GOODS_MULTIPLIER } from '../../../firebase';
import { useTranslation } from 'react-i18next';

const SingleItemQuest = ({
  auth,
  navigation,
  setShowMapModal,
  postNewRooQuest,
  userUpdatedRooQuest,
  updateRooQuestWithoutCall,
  deleteRooQuestByID,
  rooquest,
  setShowEnlargeImageModal
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [globalVar, setglobalVar] = useState(true);
  const [imgarray, setImageArray] = useState([]);
  const [sendDisabled,setSendDisabled] = useState(false)
  let getpickupData = false;
  let oldprice = null;
  const dispatch = useDispatch();
   let formDataDefaultState = {
        title: '',
        description: '',
        image: [],
        // imgURL: [],
        imgURLS:[],
        pickupLocationType: 'address',
        pickupLocation: null,
        pickupAddress: null,
        deliveryLocation: null,
        deliveryAddress: null,
        deliveryTime: null,
        additionalNote: '',
        costOfGoods: '0.00',
        costOfDeliverer: '5.00',
        requester: '',
        requesterFirstName: '',
        status: 'PENDING_PAYMENT',
      };

      let pageStatus = { status:'' }
          pageStatus = useParams();

      let defaultDateTime = {
            date: null,
            time: null,
        };    

        const enlargeImage = (e, src) => {
          if (e.target.tagName !== 'ION-ICON') {
            setShowEnlargeImageModal(true, src);
          }
        };
       
  
      if(pageStatus.status === "edit"){
        const rq = rooquest.rooquest;
        if(!!rq){
          oldprice = Number(rq.costOfGoods) + Number(rq.costOfDeliverer);
          let pickupdata = rq.pickupLocation
          if(rq.pickupLocationType === "address"){
            pickupdata = rq.pickupAddress
          }
          let deliveryCountry = rq.deliveryAddress ? rq.deliveryAddress.country : null;
          defaultDateTime = {
            date: rq.deliveryTime,
            time: rq.deliveryTime
          }
          formDataDefaultState = {
            ...formDataDefaultState,
           title: rq.title,
           description: rq.description,
           image: [],
           imgURLS: rq.imgURLS,
           pickupLocationType: rq.pickupLocationType,
           pickupLocation: pickupdata,
           pickupAddress: rq.pickupAddress,
           deliveryLocation: {
            ...rq.deliveryAddress,
            addressName: !deliveryCountry || _.trim(deliveryCountry) === '' || deliveryCountry === 'Undefined' ? 'Current Location' : ''
           },
           deliveryAddress: rq.deliveryAddress,
           deliveryTime: rq.deliveryTime,
           additionalNote: rq.additionalNote,
           costOfGoods: rq.costOfGoods,
           costOfDeliverer: rq.costOfDeliverer,
           requester: rq.requester,
           requesterFirstName: rq.requesterFirstName,
           status: 'PENDING_PAYMENT',   
         };
        }
      } 

    const [formData, setFormData] = useState(formDataDefaultState);
      let {
        title,
        description,
        image,
        pickupLocationType,
        pickupLocation,
        deliveryLocation,
        additionalNote,
        costOfGoods,
        costOfDeliverer,
      } = formData;

      const defaultPickupAddressState = formDataDefaultState.pickupAddress ? formDataDefaultState.pickupAddress : {
        streetAddress: '',
        city: '',
        postal: '',
        province: '',
        country: '',
        lat: '',
        lng: '',
      };
      useEffect(() => {
        console.log("outside",pageStatus.status)
        if(pageStatus.status === "new"){
          console.log("sharad",pageStatus.status)
          setFormData(formDataDefaultState);
          setPickupAddress({
            streetAddress: '',
            city: '',
            postal: '',
            province: '',
            country: '',
            lat: '',
            lng: '',
          });
          setTimeout(() => {
            setFormData(formDataDefaultState);
            setTimeout(() => {
                setPickupAddress({
                  streetAddress: '',
                  city: '',
                  postal: '',
                  province: '',
                  country: '',
                  lat: '',
                  lng: '',
                });
            }, 0);
          }, 0);
        }
              
      }, [pageStatus.status]); 

      //for singleitemreview page to 1/2 singleitemquest
      useEffect(() => {
        if(history.action ==="POP" || history.action ==="REPLACE"){
          setglobalVar(false)
          console.log(pageStatus.status)
          console.log(rooquest.rooquest)
        }
      },[history.action]);
           
      const [pickupAddress, setPickupAddress] = useState(defaultPickupAddressState);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [preProcessedDeliveryTime, setPreProcessedDeliveryTime] = useState(defaultDateTime);

      // Error States
      const [titleError, setTitleError] = useState(false);
      const [titleExpError, setTitleExpError] = useState(false);
      const [descriptionError, setDescriptionError] = useState(false);
      const [descriptionExpError, setDescriptionExpError] = useState(false);
      const [deliveryLocationError, setDeliveryLocationError] = useState(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [deliveryTimeError, setDeliveryTimeError] = useState(false);
      const [pickupLocationError, setPickupLocationError] = useState(false);
      const [currentLocationUser, setCurrentLocationUser] = useState(false);
      const [estimatedCostItem, setEstimatedCostItem] = useState(false);
      const [estimatedCostDeliverer, setEstimatedCostDeliverer] = useState(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [dupaddressError, setDupAddressError] = useState(false);
       // Form Changes
  const onFormChange = (e) => {
    if (e.target.name === 'title') setTitleError(false);
    if (e.target.name === 'description') setDescriptionError(false);
    // if (e.target.name === 'costOfGoods')setEstimatedCostItem(false);
    // if (e.target.name === 'costOfDeliverer') setEstimatedCostDeliverer(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if(e.target.name === 'title' && e.target.value.length >= 5 ){
      let regExp = new RegExp('(\d*[a-zA-Z]+\d*)+');
      if (!regExp.test(e.target.value)) { setTitleExpError(true) } else { setTitleExpError(false) }
    }
    if(e.target.name === 'description' && e.target.value.length >= 5 ){
      let regExp = new RegExp('(\d*[a-zA-Z]+\d*)+');
      if (!regExp.test(e.target.value)) { setDescriptionExpError(true) } else { setDescriptionExpError(false) }
    }
  };
  const oncostChange  = (e)=> {
    if (e.target.name === 'costOfGoods'){
      if(e.target.value.length > 7){
        setEstimatedCostItem(true);
      } else {
        setEstimatedCostItem(false);
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } 
  }
  const onEstimatedCostDelivererChange  = (e)=> {
    if (e.target.name === 'costOfDeliverer'){
      if(e.target.value.length > 7){
        setEstimatedCostDeliverer(true);
      } else {
        setEstimatedCostDeliverer(false);
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } 
  }
  const onDeliveryAddressChange = (e) => {
    if (e.target.value === 'ADD_NEW_ADDRESS') {
      setShowAddressModal(true);
    } else if (e.target.value === 'CURRENT_LOCATION') {
      setShowMapModal(true);
      setCurrentLocationUser(true);
    } else {
      setFormData({ ...formData, deliveryLocation: e.target.value });
    }
  };
  const deleteImage = (index) =>{
    var filearray = Array.from(image);
    imgarray.splice(index,1);
    setImageArray( imgarray);
      filearray.splice(index,1);
    setFormData({ ...formData, image: filearray,imgURLS:imgarray });
  }
  const deleteEditImage= (index) =>{
    formDataDefaultState.imgURLS.splice(index,1);
    setFormData((formData) => ({ ...formData, imgURLS: formDataDefaultState.imgURLS }));
    }
  const updateCurrentLocationuser = () => {
    const setDeliveryLoc = {
      addressName: 'Current Location',
      streetAddress: 'Undefined',
      city: 'Undefined',
      postal: 'Undefined',
      province: 'Undefined',
      country: 'Undefined',
      lat: navigation.showMapModal.coords.lat(),
      lng: navigation.showMapModal.coords.lng(),
    };
    setFormData({
      ...formData,
      deliveryLocation: setDeliveryLoc,
    });
    setFormData({ ...formData, deliveryLocation: setDeliveryLoc });

    formData.deliveryLocation = setDeliveryLoc;
    deliveryLocation = setDeliveryLoc;
    console.log(currentLocationUser);
    setShowMapModal(null, null);
  };

  useEffect(() => {
    if (navigation.showMapModal.coords !== null) {
      updateCurrentLocationuser();
    }
    // eslint-disable-next-line
  }, [navigation.showMapModal.coords]);
  const validateThenMove = async () => {
    let invalidPickupAddress = false;
    let regExp = new RegExp('(\d*[a-zA-Z]+\d*)+');
    if (!title || title.length < 5) setTitleError(true);
    if (!regExp.test(title)) { setTitleExpError(true) } else { setTitleExpError(false) }
    if (!description || description.length < 5) setDescriptionError(true);
    if (!regExp.test(description)) { setDescriptionExpError(true) } else { setDescriptionExpError(false) }


    //Pickup address validation
    console.log('validateThenMove ', pickupAddress);
    if (pickupLocationType === 'address' && pickupAddress)  {
        setFormData((formData) => ({
            ...formData,
            pickupAddress: pickupAddress,
        }));
        if ((!pickupAddress.city || _.trim(pickupAddress.city) === '') ||
            (!pickupAddress.postal || _.trim(pickupAddress.postal) === '') ||
            (!pickupAddress.province || _.trim(pickupAddress.province) === '') ||
            (!pickupAddress.country || _.trim(pickupAddress.country) === '') ||
            (!pickupAddress.lat || _.trim(pickupAddress.lat) === '') ||
            (!pickupAddress.lng || _.trim(pickupAddress.lng) === '')) {
            invalidPickupAddress = true;
            setPickupLocationError(true);
        }
    }

    if (pickupLocationType === 'store' && (!pickupLocation || _.trim(pickupLocation) === '')) {
        invalidPickupAddress = true;
    }

    if (invalidPickupAddress) {
        setPickupLocationError(true);
    } else {
        setPickupLocationError(false);
        getpickupData = true;
    }

    //Pickup address validation

    /*if (pickupLocationType && (pickupLocation !== null || JSON.stringify(pickupAddress) !== JSON.stringify(defaultPickupAddressState) || pickupLocationType === 'any') ) {
      if (pickupLocationType === 'address' && pickupAddress) {
        setFormData((formData) => ({
                ...formData,
                pickupAddress: pickupAddress,
            }));
      }
      getpickupData = true;
    }else {
      if (
        !(
          pickupLocationType &&
          (pickupLocation !== null ||
            JSON.stringify(pickupAddress) !==
              JSON.stringify(defaultPickupAddressState) ||
            pickupLocationType === 'any')
        )
      ) {
        setPickupLocationError(true);
      }
    }*/
    setFormData((formData) => ({
      ...formData,
      requester: auth.user._id,
      requesterFirstName: auth.user.firstName,
      deliveryAddress: deliveryLocation,
    }));
  
    if (!deliveryLocation) setDeliveryLocationError(true);
    if(!!title && title.length >= 5 && !!description &&description.length >= 5 && !!deliveryLocation && getpickupData && !invalidPickupAddress){
      if(pageStatus.status === "new"){
        if (image) {
          let imageUrls = [];
          const imgPromise =  Array.from(image).map(async element => {
            setSendDisabled(true);
            const imgURL = await uploadRooQuestImage(element);
            imageUrls.push(imgURL)
            if(imageUrls.length === image.length){
              setFormData((formData) => ({ ...formData, imgURLS: imageUrls , image:image}));
            }   
            return imgURL
          })
          Promise.all(imgPromise).then(async resp =>{
            setSendDisabled(false);
            setglobalVar(false);
          })
        } else {
          setglobalVar(false);
        }  
    } 
    if(pageStatus.status === "edit"){
      if (image) {
        let images = [...formDataDefaultState.imgURLS];
        let imageUrls = [];
        const imgPromise =  Array.from(image).map(async element => {
          setSendDisabled(true);
          const imgURL = await uploadRooQuestImage(element);
          imageUrls.push(imgURL)
          if(imageUrls.length === image.length){
            imageUrls  = images.concat(imageUrls);
            setFormData((formData) => ({ ...formData, imgURLS: imageUrls }));
          }   
          return imgURL
        })
        Promise.all(imgPromise).then(async resp =>{
          setSendDisabled(false);
          setglobalVar(false);
        })
      } else {
        setglobalVar(false);
      }    
     }
  }
  }
  const goSingleItemReview = async () => {
    let newFormData = formData;
    //if(preProcessedDeliveryTime.date && preProcessedDeliveryTime.time){
    // const datetime = processDateTime();
    //   if (datetime) {
    //     newFormData = {
    //           ...formData,
    //           deliveryTime: datetime,
    //     };

    //     setFormData((formData) => ({
    //       ...formData,
    //       deliveryTime: datetime,
    //     }));
    //   }
    // } else {
    //   setDeliveryTimeError(true);
    // }
   if (!costOfGoods) setEstimatedCostItem(true);
  if (!costOfDeliverer) setEstimatedCostDeliverer(true);
  //&& preProcessedDeliveryTime.date && preProcessedDeliveryTime.time
    if(costOfGoods && costOfDeliverer){
      await postNewRooQuest(newFormData);
      setglobalVar(true);
      history.push('/singleitemreview')   
    }
  }
  const goNeweditSingleItemReview = async () => {
    const rq = rooquest.rooquest;
    let neweditFormData = formData;
    const updatedRQ = await userUpdatedRooQuest(rq._id, neweditFormData);
    if (updatedRQ) {
      updateRooQuestWithoutCall(updatedRQ);
      // dispatch(launchToast('RooQuest Updated!', 2000, 'top'));
      dispatch(launchToastCard({ 
        msg: 'RooQuest Updated!',
        type: 'success',
        duration: 8500,
    }))
      setglobalVar(true);
      history.push('/singleitemreview') 
    } else {
      // dispatch(launchToast('RooQuest Error. Please try again.'));
      dispatch(launchToastCard({ 
        msg: 'RooQuest Error. Please try again.',
        type: 'error',
        duration: 8500,
    }))
    }
  }
 const goeditSingleItemReview = async () => {
  let editFormData = formData;
  if (!costOfGoods) setEstimatedCostItem(true);
  if (!costOfDeliverer) setEstimatedCostDeliverer(true);
  if(preProcessedDeliveryTime.date && preProcessedDeliveryTime.time){
    const datetime = processDateTime();
    if (datetime) {
        editFormData = {
          ...formData,
          deliveryTime: datetime,
      };
      setFormData((formData) => ({
        ...formData,
        deliveryTime: datetime,
      }));
    }
  } else {
    setDeliveryTimeError(true);
  }
  if(costOfGoods && costOfDeliverer){
    const rq = rooquest.rooquest;
       if( Number(costOfGoods) + Number(costOfDeliverer) > Number(oldprice)){
     const updatedRQ = await userUpdatedRooQuest(rq._id, editFormData);
     if (updatedRQ) {
       updateRooQuestWithoutCall(updatedRQ);
      //  dispatch(launchToast('RooQuest Updated!', 2000, 'top'));
      dispatch(launchToastCard({ 
        msg: 'RooQuest Updated!',
        type: 'success',
        duration: 8500,
    }))
       history.push('/singleitemreview')  
     } else {
      //  dispatch(launchToast('RooQuest Error. Please try again.'));
      dispatch(launchToastCard({ 
        msg: 'RooQuest Error. Please try again.',
        type: 'error',
        duration: 8500,
    }))
     }
   } else {
       if(rq.status === "ACTIVE"){
         let latesteditFormData = editFormData;
         latesteditFormData = {
           ...formData,
           status:"ACTIVE"
       };
       const updatedRQ = await userUpdatedRooQuest(rq._id, latesteditFormData);
       if (updatedRQ) {
         updateRooQuestWithoutCall(updatedRQ);
         setglobalVar(true);
        //  dispatch(launchToast('RooQuest Updated!', 2000, 'top'));
        dispatch(launchToastCard({ 
          msg: 'RooQuest Updated!',
          type: 'success',
          duration: 8500,
      }))
         history.push('/home') 
       } else {
        //  dispatch(launchToast('RooQuest Error. Please try again.'));
        dispatch(launchToastCard({ 
          msg: 'RooQuest Error. Please try again.',
          type: 'error',
          duration: 8500,
      }))
       }
     } else {
       const updatedRQ = await userUpdatedRooQuest(rq._id, editFormData);
       if (updatedRQ) {
         updateRooQuestWithoutCall(updatedRQ);
         setglobalVar(true);
        //  dispatch(launchToast('RooQuest Updated!', 2000, 'top'));
        dispatch(launchToastCard({ 
          msg: 'RooQuest Updated!',
          type: 'success',
          duration: 8500,
      }))
         history.push('/home') 
       } else {
        //  dispatch(launchToast('RooQuest Error. Please try again.'));
        dispatch(launchToastCard({ 
          msg: 'RooQuest Error. Please try again.',
          type: 'error',
          duration: 8500,
      }))
       }
     }
    
   }
   }
 }

  useEffect(() => {
    let tempArray = [];
    Array.from(image).forEach(element => {
      var reader = new FileReader();
      reader.onload = function (e) {
        tempArray.push(e.target.result);
        if (tempArray.length === image.length) {
          setImageArray(tempArray);
        }
      }
      // you have to declare the file loading
      reader.readAsDataURL(element);
    });
  }, [image]);
  const goToBack = () => {
    setglobalVar(true);
  }
  const goToQuest = () => {
    history.goBack();
    updateRooQuestWithoutCall(null);
  }
  const dropdownRef = useRef(null);
    const onButtonClick = async () => {
      dropdownRef.current.click();
     }
   const [showAddressModal, setShowAddressModal] = useState(false);
   // Choose Photo Settings
   let inputOpenFileRef : RefObject<HTMLInputElement> = React.createRef();
    const getImage = async () => {
      if(inputOpenFileRef && inputOpenFileRef.current){
       inputOpenFileRef.current.click();
      }
    };
   //Process Date Time Picker Values
  const processDateTime = () => {
    return new Date(
      `${preProcessedDeliveryTime.date.split('T')[0]}T${
        preProcessedDeliveryTime.time.split('T')[1]
      }`
    ).toISOString();
  };
    const compareWith = (o1, o2) => {
        return o1 && o2 ? o1.lat === o2.lat : o1 === o2;
    };

  const deletereq = async () => {
    const deleterq = rooquest.rooquest;
    const deletemsg= await deleteRooQuestByID(deleterq._id);
    // dispatch(launchToast(deletemsg.msg , 2000, 'top'));
    dispatch(launchToastCard({ 
      msg: deletemsg.msg,
      type: 'success',
      duration: 8500,
  }))
    history.push('/home');
  }
    return (
    <IonPage className="singleitempage">
      <IonContent >
    <GreenHeader />
    <div className="FullQuest">
            <div className="questcontainer">
            { globalVar ?
                <div>
                    <p className="questTitle questLeft">{t('Single Item Quest')}</p>
                    <p className="questTitle questRight">{t('Step')} 1 / 4</p>
                </div> :
                <div>
                    <p className="questTitle questLeft">{t('Single Item Quest')}</p>
                    <p className="questTitle questRight">{t('Step')} 2 / 4</p>
                </div> }
                <div className="fullInnerContainer">
                { globalVar ?
                <div>
                 <div className="questBodyContent">
                <IonItem className="inputField" mode="ios">
                    <IonLabel position="stacked">
                    {t('Item Name')}: <span style={{ color: 'red' }}>*</span>
                    </IonLabel>      
                    <IonInput
                      type="text"
                      name="title"
                      // placeholder="Title"
                      maxlength={80}
                      value={title}
                      onIonChange={(e) => onFormChange(e)}
                    ></IonInput>
                    </IonItem>
                    <span className="text-desc-err">
                    {titleError && (
                      <p className="formErrorMsg">
                        {t('Please enter 5 or more characters')}
                      </p>
                    )}   
                    {titleExpError && (
                      <p className="formErrorMsg">
                        {t('only numbers are not allowed')}
                      </p>
                    )}
                    </span>
                <IonItem className="inputField" mode="ios">
                    <IonLabel position="stacked">
                    {t('Item Description')}:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    <IonTextarea
                      name="description"
                      // placeholder="Describe Your RooQuest"
                      maxlength={1500}
                      rows={3}
                      // autoGrow={true}
                      mode="md" 
                      value={description}
                      onIonChange={(e) => onFormChange(e)}
                    ></IonTextarea>
                  </IonItem>
                  <span className="text-desc-err">
                  {descriptionError && (
                      <p className="formErrorMsg">
                        {t('Please enter 5 or more characters')}
                      </p>
                    )}
                    {descriptionExpError && (
                      <p className="formErrorMsg">
                        {t('only numbers are not allowed')}
                      </p>
                    )}
                    </span>
                  <IonItem className="inputField" lines="none" mode="ios">
                    <IonLabel position="stacked">
                    {t('Upload Images of Item')}:{' '}
                      <span className="disabledopts">(Optional)</span>
                    </IonLabel>
                      <div className="firstimage">
                        <div className="preview_images_main_wrapper">
                            {
                            formDataDefaultState.imgURLS.map((item , index)=> {
                                return (
                                <div className="image_preview_wrapper" key={index}>
                                <div className="img_wrp" onClick={(e) => enlargeImage(e, item)}>
                                <img className="uploadedImage" src={item} alt="uploaded"/>
                                <IonIcon className="close" icon={close}  onClick={() => deleteEditImage(index)}></IonIcon>
                                </div>
                                </div>
                                )
                            })
                            }
                            {
                            imgarray.map((item , index)=> {
                                return (
                                <div className="image_preview_wrapper" key={index}>
                                <div className="img_wrp" onClick={(e) => enlargeImage(e, item)}>
                                <img  className="uploadedImage" src={item} alt="uploaded" />
                                <IonIcon className="close" icon={close} onClick={() => deleteImage(index)}></IonIcon>
                                </div>
                                </div>
                                )
                            })
                            }
                        </div>
                        <div className="singleItemBtn" onClick={() => getImage()}>
                        <input onChange={e => {
                           setFormData({ ...formData, image: [...image ,...Array.from(e.target.files)] });
                         }} ref={inputOpenFileRef} type="file" multiple accept="image/*" style={{ display: "none" }}/> 
                        <div className="plusbtn">
                          +
                        </div>
                        </div>
                      </div>
                  
                  </IonItem>
                               
                  <IonItem className="inputField" mode="ios" lines="none">
                    <IonLabel>
                    {t('Pickup Item From')}:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    <IonSelect
                      ref={dropdownRef}
                      style={{ display: "none" }}
                      id="pickLocationTypeSelector"
                      // css-class="pickLocationTypeSelector"
                      name="pickupLocationType"
                      value={pickupLocationType}
                      okText="Select"
                      cancelText="Close"
                      mode="ios"
                      interface="alert"
                      interfaceOptions={{
                        header: ' Location Type',
                        subHeader: 'Select the most appropriate',
                        class: 'pickLocationTypeSelector',
                      }}
                      onIonChange={(e) => {
                        setFormData({
                          ...formData,
                          pickupLocationType: e.detail.value,
                          pickupLocation: null,
                        });
                        setPickupAddress(defaultPickupAddressState);
                        setPickupLocationError(false);
                      }}
                    >
                      <IonSelectOption value="address">
                        Specific Address (ie. 123 Street)
                      </IonSelectOption>
                      <IonSelectOption value="store">
                        Specific Store (ie. Walmart)
                      </IonSelectOption>
                      <IonSelectOption value="any">
                        Any Location that Contains RooQuest
                      </IonSelectOption>
                      <IonSelectOption value="other">Other</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <div className="flex-container modify-container">
                  <div className="flex-child-left">
                  {pickupLocationType === 'address' && (
                    <Fragment>
                      <GoogleAddressAutoComplete
                        address={pickupAddress}
                        dupaddressError = {dupaddressError}
                        setAddress={setPickupAddress}
                        setAddressError={setPickupLocationError}
                      />
                    </Fragment>
                  )} 

                  {pickupLocationType === 'store' && (
                    <IonItem className="inputField" mode="ios">
                      <IonInput
                        name="pickupLocation"
                        placeholder="Enter Store Name..."
                        value={pickupLocation}
                        onIonChange={(e) => {
                          onFormChange(e);
                        }}
                      ></IonInput>
                    </IonItem>
                  )}
                  {pickupLocationType === 'any' && (
                    <IonItem className="inputField" mode="ios">
                      <IonInput 
                        value="Any Location that Contains RooQuest"
                      ></IonInput>
                    </IonItem>
                  )}
                  {pickupLocationType === 'other' && (
                    <IonItem className="inputField" mode="ios">
                      <IonTextarea
                        name="pickupLocation"
                        placeholder="Enter Pickup Information..."
                        value={pickupLocation}
                        rows={3}
                        // autoGrow={true}
                        onIonChange={(e) => {
                          onFormChange(e);
                          setPickupLocationError(false);
                        }}
                      ></IonTextarea>
                    </IonItem>
                  )}
                  </div>
                  <div className="flex-child-right">
                  <div className="modify-btn" onClick={() => onButtonClick()}>Modify Type</div>
                  </div>
                  </div>
                  {pickupLocationError && (
                      <p className="formErrorMsg">
                        {t('Please enter Pickup Location Type and a valid Pickup Address')}
                      </p>
                    )}
                   <IonItem className="inputField" mode="ios" lines="none">
                    <IonLabel position="stacked" className="selectlabeldisable">
                    {t('Deliver Item To')}:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    <IonSelect
                      placeholder="Choose Location"
                      compareWith={compareWith}
                      className="deliveryselection"
                      id="deliverToSelector"
                      name="deliveryLocation"
                      value={deliveryLocation}
                      mode="ios"
                      interface="alert"
                      interfaceOptions={{
                        header: 'Deliver RooQuest to',
                        subHeader: 'Select address or add a new one',
                        class: 'deliverToSelector',
                        buttons: ['Cancel', 'Open Modal', 'Delete'],
                      }}
                      onIonChange={(e) => {
                        setDeliveryLocationError(false);
                        onDeliveryAddressChange(e);
                      }}
                    >
                      {auth.user &&
                        auth.user.deliveryAddresses.map((address) => (
                          <IonSelectOption key={address._id} value={address}>
                            {address.addressName} {address.streetAddress ? `(${address.streetAddress})` : ''}
                          </IonSelectOption>
                        ))}

                      {auth.user &&
                        deliveryLocation &&
                        deliveryLocation.addressName === 'Current Location' && (
                          <IonSelectOption
                            // key={`${formData.deliveryLocation.lat}-${formData.deliveryLocation.lng}`}
                            key={`test`}
                            value={deliveryLocation}
                          >
                            {`${parseFloat(deliveryLocation.lat).toFixed(
                              4
                            )}, ${parseFloat(deliveryLocation.lng).toFixed(4)}`}
                            {console.log(deliveryLocation)}
                          </IonSelectOption>
                        )}
                      <IonSelectOption
                        class="NEWADDRESS_SELECTOPTION"
                        value="ADD_NEW_ADDRESS"
                      >
                         {t('Add New Address')}
                      </IonSelectOption>
                      <IonSelectOption
                        class="NEWADDRESS_SELECTOPTION"
                        value="CURRENT_LOCATION"
                      >
                        {t('Use Current Location')}
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  {deliveryLocationError && (
                      <p className="formErrorMsg">
                        {t('Please enter location to deliver RooQuest')}
                      </p>
                    )}
                  <PeekingModal
                    title="Add New Address"
                    showPeekingModal={showAddressModal}
                    setShowPeekingModal={setShowAddressModal}
                    formData={formData}
                    setFormData={setFormData}
                  ></PeekingModal>
               
                </div> 
                                   <div className="bottombuttonSingleItem">
                                   <IonGrid >
                                     <IonRow>
                                        <IonCol size="6" text-center>
                                        <IonButton
                                      className="prevbtn"
                                      color="prevgrey"
                                       onClick={() => goToQuest()}
                                    >
                                      {t('Go Back')}
                                    </IonButton>
                                        </IonCol>
                                   <IonCol size="6" text-center>
                                        <IonButton
                                      className="nextbtn"
                                      color="kargoroo_green"
                                      disabled={sendDisabled}
                                      onClick={async () => await validateThenMove()}
                                    >
                                   {sendDisabled ? 'Loading...':'Continue'}
                                    </IonButton>
                                        </IonCol> 
                                        </IonRow>
                                    {pageStatus.status === "edit" ?
                                      <IonRow>
                                        <IonCol size="3" ></IonCol>
                                        <IonCol size="6" >
                                          <IonButton
                                            className="deletebtn"
                                            color="danger"
                                            onClick={() => deletereq()}
                                          >
                                            {t('Delete Request')}
                                        </IonButton>
                                        </IonCol>
                                        <IonCol size="3" ></IonCol>
                                      </IonRow>
                                      : null}
                                        </IonGrid> 
                                        </div>
                                        </div>
                : 
                <div>
                <div className="questBodyContent">
                   <IonItem className="inputField" mode="ios">
                    <IonLabel position="stacked">
                    {t('Estimated Cost of item')}:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    <IonInput
                      name="costOfGoods"
                      // placeholder="Enter Cost..."
                      type="number"
                      inputMode="decimal"
                      value={costOfGoods}
                      onIonChange={(e) => {
                        oncostChange(e);
                      }}
                    ></IonInput> 
                  </IonItem>
                  {estimatedCostItem && (
                      <p className="formErrorMsg">
                        {t('Please enter cost of item')}
                      </p>
                    )}
                  {/* <div className="estimatedcosttext">{t('Please note we will preauthorize')} <span className="estimatedSeeMore">{GET_LOADED_COST_OF_GOODS_MULTIPLIER()}x</span><br/> {t('your Estimated Cost of Goods')}.</div> */}
                  {/* <span className="estimatedSeeMore">See More</span> */}
                  <IonItem className="inputField" mode="ios">
                    <IonLabel position="stacked">
                    {t('Price willing to pay Deliverer')}:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    <IonInput
                      name="costOfDeliverer"
                      // placeholder="Enter Price..."
                      type="number"
                      inputMode="decimal"
                      value={costOfDeliverer}
                      onIonChange={(e) => {
                        onEstimatedCostDelivererChange(e)
                        // setFormData({
                        //   ...formData,
                        //   costOfDeliverer: e.detail.value!,
                        // });
                        // onFormChange(e);
                      }}
                    ></IonInput>
                  </IonItem>
                  {estimatedCostDeliverer && (
                      <p className="formErrorMsg">
                        {t('Please enter Price to Pay Deliverer')}
                      </p>
                    )}
                  <div className="estimatedcosttext">{t('RQMultiplierStatement')} {GET_LOADED_COST_OF_GOODS_MULTIPLIER()}x <br />{t('RQMultiplierStatement2')}</div>
                  {/* <IonItem className="inputField" mode="ios">
                      Preferred Delivery Time:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    <IonDatetime
                      placeholder="Select Date"
                      displayFormat="DDDD MMMM D YYYY"
                      pickerFormat="MMMM D YYYY"
                      min={new Date().toISOString()}
                      max={new Date(
                        (new Date().getFullYear() + 2).toString()
                      ).toISOString()}
                      value={preProcessedDeliveryTime.date}
                      onIonChange={(e) => {
                        e.preventDefault();
                        setDeliveryTimeError(false);
                        setPreProcessedDeliveryTime({
                          ...preProcessedDeliveryTime,
                          date: e.detail.value!,
                        });
                      }}
                    ></IonDatetime>
                    <IonDatetime
                      placeholder="Select Time"
                      displayFormat="h:mm a"
                      minuteValues="0,15,30,45"
                      value={preProcessedDeliveryTime.time}
                      onIonChange={(e) => {
                        e.preventDefault();
                        setDeliveryTimeError(false);
                        setPreProcessedDeliveryTime({
                          ...preProcessedDeliveryTime,
                          time: e.detail.value!,
                        });
                      }}
                    ></IonDatetime>
                  </IonItem>
                  {deliveryTimeError && (
                      <p className="formErrorMsg">
                        Please enter valid time to deliver RooQuest
                      </p>
                    )} */}
                  <IonItem className="inputField" mode="ios">
                    <IonLabel position="stacked">
                    {t('Additional Notes to Deliverer')}:{' '}
                    </IonLabel>
                    <IonTextarea
                      name="additionalNote"
                      placeholder="Enter Additonal Information..."
                      value={additionalNote}
                      rows={3}
                      onIonChange={(e) => onFormChange(e)}
                    ></IonTextarea>
                  </IonItem>
                </div>
                <div className="bottombuttonSingleItem">
                <IonGrid>
                   <IonRow>
                      <IonCol size="6" text-center>
                      <IonButton
                    className="prevbtn"
                    color="prevgrey"
                    onClick={() => goToBack()}
                  >
                    {t('Go Back')}
                  </IonButton>
                  
                      </IonCol>
                      {pageStatus.status === "new" && !rooquest.rooquest ?  <IonCol size="6" text-center>
                      <IonButton
                    className="nextbtn"
                    color="kargoroo_green"
                    onClick={() => goSingleItemReview()}
                  >
                    {t('Continue')}
                  </IonButton>
                      </IonCol> : null}
                      
                  {pageStatus.status === "new" && rooquest.rooquest ? <IonCol size="6" text-center>
                      <IonButton
                    className="nextbtn"
                    color="kargoroo_green"
                    onClick={() => goNeweditSingleItemReview()}
                  >
                    {t('Continue')}
                  </IonButton>
                      </IonCol>:null}

                   {pageStatus.status === "edit" ? <IonCol size="6" text-center>
                      <IonButton
                    className="nextbtn"
                    color="kargoroo_green"
                    onClick={() => goeditSingleItemReview()}
                  > 
                    {t('edit')}
                  </IonButton>
                      </IonCol>:null}
                      </IonRow>
                      {pageStatus.status === "edit" ?
                                      <IonRow>
                                        <IonCol size="3" ></IonCol>
                                        <IonCol size="6" >
                                          <IonButton
                                            className="deletebtn"
                                            color="danger"
                                            onClick={() => deletereq()}
                                          >
                                            {t('Delete Request')}
                                        </IonButton>
                                        </IonCol>
                                        <IonCol size="3" ></IonCol>
                                      </IonRow>
                                      : null}
                      </IonGrid> 
                      </div>
                      </div>
                }

                </div> 
            </div>
            </div>
            </IonContent>
            </IonPage>
)
}

SingleItemQuest.propTypes = {
  auth: PropTypes.object.isRequired,
  rooquest: PropTypes.object.isRequired,
  postNewRooQuest: PropTypes.func.isRequired,
  userUpdatedRooQuest: PropTypes.func.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
  setShowMapModal: PropTypes.func.isRequired,
  deleteRooQuestByID:PropTypes.func.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  rooquest: state.rooquest,
  navigation: state.navigation,
});
export default connect(mapStateToProps, {
  postNewRooQuest,
  setShowMapModal,
  userUpdatedRooQuest,
  updateRooQuestWithoutCall,
  deleteRooQuestByID,
  setShowEnlargeImageModal
})(SingleItemQuest);