import { IonIcon, IonRow, IonCol, IonButton, IonCardTitle, IonAlert } from "@ionic/react";
import { navigateOutline, cartOutline, chatbubblesOutline, closeCircleOutline, pinOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { cancelRooQuestDelivery, getMyDeliveries, updateRooQuestWithoutCall } from "../../../actions/rooquest/rooquest";

import "./MapOverlay.scss";

const MapOverlay = ({ rooquest,next,navigator,openChat,closeModal,cancelRooQuestDelivery, getMyDeliveries, updateRooQuestWithoutCall,overlayRef }) => {

	const [showCancelDeliveryAlert, setShowCancelDeliveryAlert] = useState(false);
	const { t } = useTranslation();

	const cancelDelivery = async (reason) => {
		await cancelRooQuestDelivery(rooquest._id, reason)
		updateRooQuestWithoutCall(null);
		await getMyDeliveries();
		closeModal()
	};

	return(
	<div className="overlayContainer" ref = {overlayRef}>

		<IonCardTitle className="earn-money">{ t('Earn') }{` $${parseFloat(rooquest.costOfDeliverer).toFixed(2)}`}</IonCardTitle>
		<hr className="delimiter"/>
		{/* <IonBadge color="dark">{ 2 } star rating</IonBadge> */}

		<p>
			<IonIcon icon={ cartOutline } />
			<span>{ `${rooquest.items.length}`} {t('item')}{rooquest.items.length !== 1 ? "s" : ""}</span>
		</p>

		<p>
			<IonIcon icon={ navigateOutline } />
			<span>{ `${parseFloat(rooquest.costs.fees.delivererFee.distance).toFixed(2)} Km`}</span>
		</p>

		
		<p>
			<IonIcon icon={ cartOutline } />
			<span>{ rooquest.pickupAddress.formatted_address }</span>
		</p>

		<p>
			<IonIcon icon={ pinOutline } />
			<span>{ rooquest.deliveryAddress.formatted_address}</span>
		</p>


		<IonRow className="ion-no-padding ion-no-margin">
			
			{ true && 
				<IonCol size="4" className="ion-no-padding ion-no-margin">
					<IonButton color="primary" fill="outline" size="small" expand="block" onClick={openChat}>
						<IonIcon icon={ chatbubblesOutline } />
					</IonButton>
				</IonCol>
			}

			<IonCol size="4" className="ion-no-padding ion-no-margin">
				<IonButton color="primary" fill="outline" size="small" expand="block" onClick={navigator}>
					<IonIcon icon={ require('./direction.svg') } color="green" />
				</IonButton>
			</IonCol>

			<IonCol size="4" className="ion-no-padding ion-no-margin">
				<IonButton color="primary" fill="outline" size="small" expand="block" onClick={() => setShowCancelDeliveryAlert(true)}>
					<IonIcon icon={ closeCircleOutline } />
				</IonButton>
			</IonCol>
		</IonRow>

		<IonRow className="ion-no-padding ion-no-margin" onClick={next}>
			<IonCol size="12" className="ion-no-padding ion-no-margin">
				<IonButton color="primary" fill="solid" size="small" expand="block">
					{t('Collect Items')} &rarr;
				</IonButton>
			</IonCol>
		</IonRow>

		<IonAlert
                isOpen={showCancelDeliveryAlert}
                onDidDismiss={() => setShowCancelDeliveryAlert(false)}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Cancel Delivery')} ?`}
                message={
                    `${t('Are you sure you want to cancel the delivery? This will be kept on your deliverer record and can lead to account penalties.')}`
                }
                inputs={[ { name: 'cancellationReason', type: 'text', placeholder: `${t('Reason for cancellation')}` }, ]}
                buttons={[
                    { text: `${t('Close')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Cancel Delivery')}`,
                        handler: async (alertData) => {
                        await cancelDelivery(alertData.cancellationReason);
                        },
                    },
                ]}
            />
	</div>
	)
}

export default connect(null,{cancelRooQuestDelivery, getMyDeliveries, updateRooQuestWithoutCall})(MapOverlay);