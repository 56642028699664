// // <!-- FIREBASE SETUP START HERE -->
// //   <!-- The core Firebase JS SDK is always required and must be listed first -->
//   <script src="https://www.gstatic.com/firebasejs/7.15.1/firebase-app.js"></script>

//   <!-- TODO: Add SDKs for Firebase products that you want to use
//      https://firebase.google.com/docs/web/setup#available-libraries -->
//   <script src="https://www.gstatic.com/firebasejs/7.15.1/firebase-analytics.js"></script>

//
//     // Initialize Firebase
//     firebase.initializeApp(firebaseConfig);
//     firebase.analytics();
//     const storage = firebase.app().storage('gs://kargoroo-app-rqimages');
//   </script>
//   <!-- FIREBASE SETUP ENDS HERE -->

import firebase from 'firebase';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/remote-config';
import 'firebase/auth';
import 'firebase/messaging';
import { provincialTaxes_Local } from './utils/getTaxRate';
// import { launchToast} from './actions/layout/toast';
// import store from './store';

export const firebaseConfig = {
  apiKey: 'AIzaSyCnfDtepp0ZCVM222VM9ZqMYUM_D_GatJk',
  authDomain: 'kargoroo-app.firebaseapp.com',
  databaseURL: 'https://kargoroo-app.firebaseio.com',
  projectId: 'kargoroo-app',
  storageBucket: 'kargoroo-app.appspot.com',
  messagingSenderId: '69777905869',
  appId: '1:69777905869:web:ea5587e3287e9bf2987184',
  measurementId: 'G-SVJMN78FLZ',
};

const Firebase = async () => {
  const fb = firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  firebase.storage();
  firebase.auth();
  try {
  // const messaging = firebase.messaging();
  } catch (err) {
    console.error(err);
  }

  // REMOTE CONFIG SETTINGS
  const remoteConfig = firebase.remoteConfig();
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000,
    fetchTimeoutMillis: 3600000,
  };
  remoteConfig.defaultConfig = {
    kargoroo_fee: 1.5,
    minimum_deliverer_cost: 5.0,
    costOfGoodsMultiplier: 1.05,

    // >3.0.0
    provincialTaxes: JSON.stringify(provincialTaxes_Local),
    rooFee: JSON.stringify({ base: 2.50, percentage: 0.05 }),
    delivererFeeRecommendation: JSON.stringify({ minimum: 5.0, avgCostPerKm: 0.15, perItemFee: 0.5,base:4.5 }),
    preAuth : JSON.stringify({percentage : 0.25})
  };
  await remoteConfig.fetchAndActivate();

  return fb;
};

// {
//   accessToken: credentials.accessToken,
//   idToken: credentials.idToken,
// }
export const GET_LOADED_KARGOROO_FEE = () => {
  return firebase.remoteConfig().getValue('kargoroo_fee').asNumber();
};

export const GET_KARGOROO_FEE = async () => {
  await firebase.remoteConfig().fetchAndActivate();
  return firebase.remoteConfig().getValue('kargoroo_fee').asNumber();
};

export const GET_LOADED_COST_OF_GOODS_MULTIPLIER = () => {
  return firebase.remoteConfig().getValue('costOfGoodsMultiplier').asNumber();
};

export const GET_COST_OF_GOODS_MULTIPLIER = async () => {
  await firebase.remoteConfig().fetchAndActivate();
  return firebase.remoteConfig().getValue('costOfGoodsMultiplier').asNumber();
};

export const GET_LOADED_MINIMUM_DELIVERER_FEE = () => {
  return firebase.remoteConfig().getValue('minumum_deliverer_fee').asNumber();
};

export const GET_MINIMUM_DELIVERER_FEE = async () => {
  await firebase.remoteConfig().fetchAndActivate();
  return firebase.remoteConfig().getValue('minumum_deliverer_fee').asNumber();
};

export const GET_PLATFORM_FEES_AND_TAXES = async () => {
  await firebase.remoteConfig().fetchAndActivate();
  return {
    rooFee: firebase.remoteConfig().getValue('rooFee').asString(),
    delivererFeeRecommendation: firebase.remoteConfig().getValue('delivererFeeRecommendation').asString(),
    provincialTaxes: firebase.remoteConfig().getValue('provincialTaxes').asString(),
    preAuth : firebase.remoteConfig().getValue('preAuth').asString()
  };
};

export default Firebase;
