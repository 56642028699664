import React from 'react'
import PropTypes from 'prop-types'

import './WhiteCard.scss'

const WhiteCard = ({ children }) => {
    return (
        <div className='WhiteCard'>
            {children}
        </div>
    )
}

WhiteCard.propTypes = {

}

export default WhiteCard
