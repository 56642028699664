import { v4 as uuidv4 } from 'uuid';
import { ToastCard } from '../../components/ToastCards/ToastCards';
import { LAUNCH_TOAST, LAUNCH_TOAST_CARD, REMOVE_TOAST, REMOVE_TOAST_CARD } from './toast-types';

export const launchToast = (
  msg,
  duration = 1500,
  position = 'bottom',
  redirectTo = null,
  onClick = null,
) => (dispatch) => {
  const id = uuidv4();
  dispatch({
    type: LAUNCH_TOAST,
    payload: {
      msg,
      id,
      position,
      redirectTo,
      onClick,
    },
  });

  setTimeout(() => dispatch({ type: REMOVE_TOAST, payload: id }), duration);
};

export const launchToastCard = (toastProps: ToastCard) => (dispatch) => {
  const id = uuidv4();
  const duration = toastProps.duration || 3000;
  dispatch({
    type: LAUNCH_TOAST_CARD,
    payload: {
      ...toastProps,
      id,
    },
  });
  setTimeout(() => dispatch({ type: REMOVE_TOAST_CARD, payload: id }), duration);
}

export const removeToastCard = (id) => ({
  type: REMOVE_TOAST_CARD,
  payload: id,
});

