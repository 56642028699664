import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { IonCheckbox } from '@ionic/react'
// import AutoCompleteWithMap, { isBusiness } from '../components/AutoCompleteWithMap'
import AutocompleteMap, {isBusiness} from '../components/AutocompleteMap'

import './Pickup.scss'
import { initialAddressState } from './RooQuestTypes'
import { useTranslation } from 'react-i18next'
import Test from './Test'

const NewPickup = ({ rq, setRQ, status, dropoff = false, editRQ = false,setAddressConfirmedVisible,error,setError,addressConfirmed,setAddressConfirmed }) => {
    const { t } = useTranslation();
    const setAddress = (addressValidated) => {
        // if (dropoff) setRQ({ ...rq, dropoffAddress: address })
        // else setRQ({ ...rq, pickupAddress: address })
        if(!addressValidated && !editRQ)
        {
            console.log(`address validated == ${addressValidated}`)
            setRQ({ ...rq, dropoffAddress: initialAddressState, pickupAddress:initialAddressState })
        }
        else{
            console.log(dropoffAddress)
            setRQ({ ...rq, dropoffAddress: dropoffAddress, pickupAddress:pickupAddress })
        }
        
    }

    
    const [pickupAddress, setPickupAddress] = useState(initialAddressState);
    const [dropoffAddress, setDropoffAddress] = useState(initialAddressState);

    const checkBoxRef = useRef(null);
    useEffect(() => {
        if (rq && rq._id) {
            if (dropoff) {
                setDropoffAddress(rq.deliveryAddress);
                setRQ({ ...rq, dropoffAddress: rq.deliveryAddress })
                setAddressConfirmed(true)
            }
            else {
                setPickupAddress(rq.pickupAddress);
                setAddressConfirmed(true)
            }
        }
    }, [rq._id])
    const isAddressValid = (address) => {
        if (address.postal_code === ""
            || address.administrative_area_level_1 === ""
            || address.country === ""
            || address.lat === null
            || address.lng === null
            || address.formatted_address === "") {
            return false;
        }
        else return true;
    }

    const addressValid = () => {
        // Make sure all fields are not empty
        setAddressConfirmedVisible(true);
        return true;
    }



    const resetPickupAddress = () => {
        setPickupAddress(initialAddressState);
        setAddressConfirmed(false);
    }

    const resetDropoffAddress = () => {
        setDropoffAddress(initialAddressState);
        setAddressConfirmed(false);
    }

    useEffect(() => {
        console.log(`address confirmed ${addressConfirmed}`)
        if (addressConfirmed) {
            setAddress(true);
            setError(false);
        }
        else {
            setAddress(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressConfirmed]);

    useEffect(() => {
        const pickupStatus = status.find(status => status.name === 'Pickup');
        const dropoffStatus = status.find(status => status.name === 'Dropoff');
        if ((pickupStatus.status === 'problem' || dropoffStatus.status === 'problem') && !addressConfirmed) {
            setError(true);
        }
    }, [addressConfirmed, dropoff, status])

    useEffect(() => {
        setAddressConfirmed(false);
    }, [pickupAddress,dropoffAddress]);

    useEffect(() => {
        if (status[4].payment.succeeded) {
            console.log('clearing')
            resetPickupAddress();
            resetDropoffAddress();
        }
    }, [status])

    useEffect(() => {
        if (editRQ) {
            setPickupAddress(rq.pickupAddress);
            setDropoffAddress(rq.dropoffAddress);
        }
    }, [dropoff, editRQ, rq.dropoffAddress, rq.pickupAddress]);

    return (
        <div className='Pickup minHeightPickup'>
                <>
                    {/* <AutocompleteMap
                        pickupAddress={pickupAddress} 
                        setPickupAddress={setPickupAddress}
                        clearPickupAddress={resetPickupAddress} 
                        clearDropoffAddress={resetDropoffAddress}
                        dropoffAddress={dropoffAddress} 
                        setDropoffAddress={setDropoffAddress}
                        editRQ = {editRQ} 
                    />  */}
                    <Test 
                     pickupAddress={pickupAddress} 
                     setPickupAddress={setPickupAddress}
                     clearPickupAddress={resetPickupAddress} 
                     clearDropoffAddress={resetDropoffAddress}
                     dropoffAddress={dropoffAddress} 
                     setDropoffAddress={setDropoffAddress}
                     editRQ = {editRQ} 
                    />
                    {addressValid() &&
                        <div className="confirmAddress">
                            <IonCheckbox mode="ios" slot="start" ref={checkBoxRef} checked={addressConfirmed} onIonChange={e => {
                                setAddressConfirmed(e.detail.checked)
                            }
                            } />
                            <p className={`${error === true && 'error'}`} onClick={() => {
                                setAddressConfirmed(!addressConfirmed);
                            }}>
                                {t('I confirm the above locations are correct for this RooQuest')}</p>
                        </div>}
                </>
        </div>
    )
}

NewPickup.propTypes = {
    rq: PropTypes.object.isRequired,
    setRQ: PropTypes.func.isRequired,
    status: PropTypes.array.isRequired,
    dropoff: PropTypes.bool
}

export default NewPickup
