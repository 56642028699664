import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonList, IonCheckbox, IonIcon, IonAlert } from '@ionic/react';
import { setShowEnlargeImageModal } from '../../../../../actions/layout/navigation';
import WhiteCard from '../../../components/ui/WhiteCard';
import FlipNumbers from 'react-flip-numbers';

import './RooQuestChecklistItemsList.scss';
import { pencilOutline } from 'ionicons/icons';
import PickItemModal from './PickItemModal';
import { connect } from 'react-redux';
import { uncollectItem } from '../../../../../actions/rooquest/rooquestItem';
import { useTranslation } from 'react-i18next';

import {incrementCollectedItemsTotal,decrementCollectedItemsTotal,setCollectedItemsTotal} from '../../../../../actions/collectedItems/collectedItems';



const RooQuestChecklistItemsList = ({ rooquest,collectedtotalPrice,decrementCollectedItemsTotal,setCollectedItemsTotal }) => {
    const { t } = useTranslation();
    const [total,setTotal] = useState(0);
    const accordionGroup = useRef(null);
    const [openAccordionRows, setOpenAccordionRows] = useState([]);
    useEffect(()=>{
        const add =(a,b)=>{
            if (b.collected && b.shelfPriceType === 'perUnit' && !b.problem.hasProblem)
            {
                return a+ b.quantity*b.shelfPrice;
            }
            else if(b.collected && b.shelfPriceType === 'totalQuantity' && !b.problem.hasProblem)
            {
                return a+b.shelfPrice
            }
            return a;
        }
        const arr = rooquest.items.map((a)=>{return a.collectionInfo});
        const result = arr.reduce(add,0);
        setCollectedItemsTotal(result);
        setTotal(result);
    })

    useEffect(()=> {

        console.log(openAccordionRows)
        if (!accordionGroup.current || openAccordionRows.length === 0) {
            return;
        }
          
        console.log('accordion ref');
        accordionGroup.current.value = openAccordionRows;
    },[openAccordionRows]);



    const handleAccordionChange = (e) => {
        console.log("handle accordion change")
        setOpenAccordionRows(e.detail.value);
      };
    // const handleAccordionChange = (e) => {
    //     console.log('loaded')
        
    //     let res= [];
    //     rooquest.items.map((items,index)=> {
    //       res.push(`infoRQ-item-${index}`)
    //     })
    //     setOpenAccordionRows(res);

    // }
    return (
        <div className="RooQuestChecklistItemsList">
            <p className="sectionTitle"><span>{rooquest.items.length} </span>{t('Item')}{rooquest.items.length >1 ? 's':''}</p>
            <WhiteCard>
                <div className="estimatedCostContainer">
                    <p>{t("Estimated Total Cost of Goods")}: </p>
                    <FlipNumbers 
                        height={14} 
                        width={9.5} 
                        color="#10be5f" 
                        // background="white" 
                        play perspective={100} 
                        duraction={0.4}
                        numbers={'$'+ parseFloat(rooquest.costOfGoods).toFixed(2)} 
                        numberStyle={{ fontWeight: 'bold'}}
                    />
                    
                    

                </div>
                <IonAccordionGroup multiple={true} mode='ios' onLoad={()=>{console.log('loaded')}} onIonChange={handleAccordionChange} ref={accordionGroup}>
                    {rooquest.items.map((item, index) => (
                         <RooQuestCheckListItem key={`infoRQ-item-${index}`} item={item} index={index} rq={rooquest} collectedtotalPrice={collectedtotalPrice} />
                    ))} 
                    <div className='RooQuestCheckListItem'>
                            <IonCheckbox className='hidden-checkbox'/>
                            <IonAccordion mode='ios' value={`infoRQ-item-${rooquest.items.length}`}  >
                                <IonItem slot="header" tabIndex={-1}>
                                    <IonLabel className="infoRQ-item-info">
                                        <div className="item-title">
                                            {/* <p>{item.title}</p> */}
                                        </div>
                                        <div className="cost">
                                        <p>{t('Total Collected')}:</p>                        
                                        <p className={`total`}>${total.toFixed(2)}</p>
                                        </div>
                                        
                                    </IonLabel>
                                </IonItem>
                            </IonAccordion> 
                     </div>
                    {/* <IonCheckbox></IonCheckbox>
                    <IonItem>
                        <span className='checkbox-space'></span><div className='infoRQ-item-info'><div className='cost'>Total Items Collected: <span className='total'>${total.toFixed(2)}</span> </div></div>   
                    </IonItem>                             */}
                </IonAccordionGroup>
                
            </WhiteCard>                                
        </div>
    )
}

RooQuestChecklistItemsList.propTypes = {};

const mapStateToProps = state => ({
    collectedtotalPrice: state.collectedtotalPrice
});
export default connect(mapStateToProps, { decrementCollectedItemsTotal,setCollectedItemsTotal })(RooQuestChecklistItemsList);


///////////////////////////////////////////////////////////////////////////////////////////////////////


const RooQuestCheckListItemLocal = ({ item, index, rq, uncollectItem,collectedtotalPrice }) => {
    const { t } = useTranslation();

    const calculateTotalItemCost = (quantity, costPerUnit) => {
        const total = quantity * costPerUnit;
        return total.toFixed(2);
    }

    const [showPickModal, setShowPickModal] = useState(false);
    const [showEditOrUnpickAlert, setShowEditOrUnpickAlert] = useState(false);

    const accordionRef = useRef(null);

    const handleCheckboxChange = (e) => {
        if (item.collectionInfo.collected) {
            setShowEditOrUnpickAlert(true);
        } else {
            setShowPickModal(true);
        }
    } 

    const calculateNewItemCost = (quantity, shelfPrice, shelfPriceType) => {
        if (shelfPriceType === 'perUnit') {
            const total = quantity * shelfPrice;
            return total.toFixed(2);
        }
        else {
            const total = shelfPrice;
            return total.toFixed(2);
        }
    }

    const calculateTotalEstimatedCost = () => {
        let total = 0;
        rq.items.forEach(item => {
            if (item.collectionInfo.collected && item.collectionInfo.problem && !item.collectionInfo.problem.hasProblem) {
                total += parseFloat(calculateNewItemCost(item.collectionInfo.quantity, item.collectionInfo.shelfPrice, item.collectionInfo.shelfPriceType));
            }
            else if (item.collectionInfo.collected && item.collectionInfo.problem && item.collectionInfo.problem.hasProblem) {
                // Do nothing
            }
            else {
                total += parseFloat(calculateTotalItemCost(item.quantity, item.costPerUnit));

            }

        });
        return total.toFixed(2);
    }


    return (
        <div className='RooQuestCheckListItem'>
            <IonCheckbox 
                color={(item.collectionInfo.collected && item.collectionInfo.problem && item.collectionInfo.problem.hasProblem) ? 'danger' : 'success'} 
                checked={item.collectionInfo.collected} onClick={handleCheckboxChange} 
                disabled={rq.status !== 'RQ_ACCEPTED'}
            />
            <IonAccordion mode='ios' value={`infoRQ-item-${index}`} ref={accordionRef} >
                <IonItem slot="header" tabIndex={-1}>
                    <IonLabel className="infoRQ-item-info">
                        <div className="item-title">
                            <p>{item.title}</p>
                        </div>
                        <div className="cost">
                        <p>{item.collectionInfo.collected && item.collectionInfo.problem && !item.collectionInfo.problem.hasProblem ?item.collectionInfo.quantity:item.quantity} {item.collectionInfo.shelfPriceType === 'totalQuantity' ? 'for':'x'} ${item.collectionInfo.collected && item.collectionInfo.problem && !item.collectionInfo.problem.hasProblem
                            ? parseFloat(item.collectionInfo.shelfPrice).toFixed(2) 
                            : parseFloat(item.costPerUnit).toFixed(2)}</p>
                        
                        {item.collectionInfo.collected && item.collectionInfo.problem && !item.collectionInfo.problem.hasProblem 
                        ?
                        <p className={`total new`}>${calculateNewItemCost(item.collectionInfo.quantity,item.collectionInfo.shelfPrice,item.collectionInfo.shelfPriceType)}</p> 
                        
                        :                        
                        <p className={`total ${item.collectionInfo.collected ? 'strikethrough' : ''}`}>${calculateTotalItemCost(item.quantity, item.costPerUnit, )}</p>}
                        </div>
                        
                    </IonLabel>
                </IonItem>
                <IonList className="infoRQ-item-moreinfo" slot="content">
                    {item.description !== '' && <div className="infoRQ-moreinfo-section">
                        <p className='infoRQ-sectionTitle'>Description:</p>
                        <p className='infoRQ-sectionContent'>{item.description}</p>
                    </div>}
                    {item.sku !== "" && <div className="infoRQ-moreinfo-section">
                        <p className='infoRQ-sectionTitle'>SKU:</p>
                        <p className='infoRQ-sectionContent'>{item.sku}</p>
                    </div>}
                    {item.images.length > 0 && <div className="infoRQ-moreinfo-section">
                        <p className='infoRQ-sectionTitle'>Images:</p>
                        <div className="photosContainer">
                            {item.images.map((image, index) => (
                                <img 
                                    key={`infoRQ-item-image-${index}`} 
                                    src={image} 
                                    alt="item"
                                    onClick={() => setShowEnlargeImageModal(true, image)} 
                                />
                            ))}
                        </div>
                    </div>}
                    {item.description === '' && item.sku === "" && item.images.length === 0 && 
                    <div className="infoRQ-moreinfo-section">
                        <p className='infoRQ-sectionTitle'>{t('No additional information available')}</p>
                    </div>}
                </IonList>
            </IonAccordion> 
            <PickItemModal item={item} setShow={setShowPickModal} show={showPickModal} totalCostOfGoods = {parseFloat(rq.costOfGoods).toFixed(2)} collectedtotalPrice= {collectedtotalPrice}/>
            <IonAlert
                isOpen={showEditOrUnpickAlert}
                onDidDismiss={() => setShowEditOrUnpickAlert(false)}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={'Unselect or Edit?'}
                message={
                '<p>Would you like to unselect this item, or would you like to edit the collection information?</p>'
                }
                buttons={[
                    { text: 'Close', role: 'cancel', cssClass: 'secondary' },
                    { text: 'Unselect', handler: async () => {
                        // decrementCollectedItemsTotal(item.collectionInfo.shelfPrice)
                        // console.log(item.collectionInfo.shelfPrice)
                        await uncollectItem({ rqID: rq._id, itemID: item._id });
                    }
                    },
                    { text: 'Edit',
                        handler: async () => {
                            await uncollectItem({ rqID: rq._id, itemID: item._id });
                            setShowPickModal(true);
                        },
                    },
                ]}
            />
        </div>
    )
}

RooQuestCheckListItemLocal.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
}

const RooQuestCheckListItem = connect(null, { uncollectItem,decrementCollectedItemsTotal })(RooQuestCheckListItemLocal);
