import React, { useState,useEffect,useMemo, useRef  } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonModal, IonRange, IonSegment } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Geolocation } from '@capacitor/geolocation';
import { regularMapColor, darkModeMapColor } from '../../../components/feed/map/MapStyles';
import { connect } from 'react-redux';
import { setShowNotificationRangeModal } from '../../../actions/layout/navigation';
import { updateNotificationRange } from '../../../actions/notificationrange/notificationrange';
import './NotificationRange.scss';
import { loadUser } from '../../../actions/auth/auth';
import MapInstance from '../../../components/DeliverNow/MapInstance/MapInstance';

//map component with a slider to show the range
const NotificationRange = ({auth,showNotificationRangeModal,setShowNotificationRangeModal,updateNotificationRange}) => {

    const onCloseModal = () => {
        setShowNotificationRangeModal(false)
    }

    const options = useMemo(()=> ({
      // mapId:"ce8355f67058c4c1",  // change style of the map
      disableDefaultUI:true, // remove default buttons like +/- to zoom or map/satelite view button
      clickableIcons: false, // can't click the default icons present in google map
  }),[]);

    const [position,setPosition] = useState({lat: 0,lng: 0})
    const [radius,setRadius] = useState(350);
    const radiusUpperRange = 5000;
    const mapRef = useRef(null);
    const { t } = useTranslation();

    useEffect(()=>{

      if(!auth.user)
        return;

        const r = auth.user.notificationRange/1000 ;
        setRadius(r);

      
        console.log('auth user')
    },[auth])


    const getCurrentPosition = async () =>{
        try {
            const geolocation = await Geolocation.getCurrentPosition({
                enableHighAccuracy: true,
              });
            setPosition( {
                lat: geolocation.coords.latitude,
                lng: geolocation.coords.longitude,
            });
        } catch (err) {
            console.error(err);
        }
    }

 const metersToKilometers = (value) => {
    return (value / 1000)
  }

  const saveModalFilters = async () => {
    try {
        const r = radius*1000;

        await updateNotificationRange(r,auth.user._id); // change the backend
    } catch (err) {
      console.error(err);
    }
  };
  
    
    return auth.user ? (    
        <IonModal
        mode="ios"
        isOpen={showNotificationRangeModal}
        class="filterModal"
        // showBackdrop={true}
        backdropDismiss={true}
        onWillDismiss={onCloseModal}
      >
        <div className="modalContent">
          <h2 className="modalTitle">Distance</h2>

          <div className="inputElements">
            <div className="radius">
              <IonItem lines="none">
                <IonRange
                  min={0}
                  max={radiusUpperRange}
                  value={radius}
                  mode="md"
                  pin={true}
                  onIonChange={(e) =>
                    setRadius(e.detail.value as number)
                  }
                >
                  <IonLabel className="radiusLabel" slot="start">
                    {t('Radius')}
                  </IonLabel>
                  <IonLabel className="radiusLabel" slot="end">
                    {radius}km
                  </IonLabel>
                </IonRange>
              </IonItem>
                <IonInput
                      type='number'
                      minlength={0}
                      value={radius}
                      onIonChange={(e) => setRadius(parseInt(e.detail.value))}
                      className='radius-center'
                />
              {/* <input type="number" min={0} value={radiusSliderValue} onChange={(e) => setRadiusSliderValue(parseInt(e.target.value))} />  */}
              <IonSegment
                onIonChange={(e) => {
                //   setRadiusUpperRange(parseInt(e.detail.value));
                //   if (e.detail.value === '2500' && radiusSliderValue > 2500) {
                //     setRadiusSliderValue(2500);
                //   }
                }}
                value={radiusUpperRange.toString()}
              >
                {/* <IonSegmentButton value="150">
                  <IonLabel>{t('Short Range')}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="2000">
                  <IonLabel>{t('Long Range')}</IonLabel>
                </IonSegmentButton> */}
              </IonSegment>
            </div>
          </div>
          {/* <div className='mapPosition'>
          <MapInstance
            center={position}
            zoom = {18}
            markers = {[]}
            driverPosition = {null}
            deliveryDestination = {null}
            pickupLocation = {null}
            dropoffLocation = {null}
            onMarkerLoad = {null}
            options = {options}
            rooquest = {null}
            markerRef = {null}
            mapRef = {mapRef}
            isClient = {null}
            />
          </div> */}

          <div className="actionButtons">
            <div className="row">
              <IonButton onClick={onCloseModal}>
                {t('Close')}
              </IonButton>
              <IonButton onClick={() => saveModalFilters()}>{t('Save')}</IonButton>
            </div>
          </div>
        </div>
      </IonModal>
    ) :
    (<>{console.log('...loading')}</>);
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    showNotificationRangeModal : state.navigation.showNotificationRangeModal
  });

export default connect(mapStateToProps,{setShowNotificationRangeModal,updateNotificationRange,loadUser})(NotificationRange)