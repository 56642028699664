import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { IonContent, IonFooter, IonIcon, IonPage, IonSpinner, useIonViewDidEnter } from '@ionic/react'
import Reward from 'react-rewards';

import './PostNewRooQuest.scss'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import AddItems from './form_pages/AddItems';
import ProgressBarStepper from './components/ProgressBarStepper';
import { chevronBack, chevronForward, closeOutline } from 'ionicons/icons';
import Pickup from './form_pages/Pickup';
import { launchToast, launchToastCard } from '../../actions/layout/toast';
import { connect } from 'react-redux';
import Review from './form_pages/Review';
import { RooQuestFormData, initialAddressState, initialRooCosts } from './form_pages/RooQuestTypes';
import { GET_PLATFORM_FEES_AND_TAXES } from '../../firebase';
import { getTaxRate } from '../../utils/getTaxRate';
import Payment from './form_pages/Payment';
import ConfirmationPage from './form_pages/ConfirmationPage';
import { useLocation } from 'react-router';
import { getMyRooQuests, getRooQuestByID } from '../../actions/rooquest/rooquest';
import { useTranslation } from 'react-i18next';
import NewPickup from './form_pages/NewPickup';
import { Keyboard } from "@capacitor/keyboard";
import { Capacitor } from '@capacitor/core';
import { haversine } from '../../utils/haversine';

const PostNewRooQuest = ({ history, launchToastCard, getRooQuestByID, getMyRooQuests }) => {
    const { t } = useTranslation();
    const location = useLocation();
    console.log('re rendering')


    const swiperRef = useRef(null);
    const confettiRef = useRef(null);
    const headerRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0)
    const pageTitles = [`${t("Pickup and Dropoff")}`, `${t("Add Items")}`, `${t("Review")}`, `${t("Payment")}`, `${t("Confirmation")}`];
    const [shouldCallDistanceMatrix, setShouldCallDistanceMatrix] = useState(true);
    const [disabledNext, setDisabledNext] = useState(false);
    const [disabledPrev, setDisabledPrev] = useState(false);
    const [editRQ, setEditRQ] = useState(false);
    const nextBtnRef = useRef(null);
    // const { height, width } = useWindowDimensions();
    const [initialHeight,setInitialHeight] = useState(window.outerHeight);
    // const [contentHeight,setContentHeight] = useState(height);
    const [isKeyboardVisible, setKeyboardVisible] = useState(false);
    // const [userMode,setUserMode] = useState(null);
    const [addressConfirmedVisible,setAddressConfirmedVisible] = useState(false);
    const [addressConfirmed, setAddressConfirmed] = useState(false);
    
    const [error, setError] = useState(false);
    const launchConfetti = () => {
        confettiRef.current.rewardMe();
    }

    const currentDateTime = new Date();
    const initialFormData: RooQuestFormData = {
        items: [],
        pickupAddress: initialAddressState,
        dropoffAddress: initialAddressState,
        costs: initialRooCosts,
        distance: { km: 0, seconds: 0, text:'' },
        co2Saved: { kg: 0, },
        expiryDate: new Date(currentDateTime.getTime() + (5 * 24 * 60 * 60 * 1000)),
        additionalNotes: '',
        id: null,
        paymentDetails: {
            brand: null,
            last4: null,
        },
        triggerChange: false,
    }
    
    const [rq, setRQ] = useState<RooQuestFormData>(initialFormData);
    

    /*
    * If in edit mode, retrieve RooQuest data
    */
    useEffect(() => {
        const getRQ = async () => {
            const retrievedRQ = await getRooQuestByID(location.pathname.split("/")[2]);
            console.log(retrievedRQ)
            setRQ(rq => ({
                ...rq,
                pickupAddress: {
                    ...retrievedRQ.pickupAddress,
                },
                dropoffAddress: {
                    ...retrievedRQ.deliveryAddress,
                },    
                items: retrievedRQ.items,
                id: retrievedRQ._id,      
                paymentDetails: retrievedRQ.paymentDetails, 
                costs: {
                    ...retrievedRQ.costs,
                }         
            }));    
        }

        if (location.pathname.includes("editrooquest")) {   
            setEditRQ(true)  
            getRQ();         
        }
    }, [getRooQuestByID, location.pathname])

    useEffect(() => {
        console.log(rq);
    }, [rq]);

    const initialStatus = [
        // Statuses: 'incomplete', 'complete', 'problem', 'locked
        { name: "Pickup", status: "incomplete" }, 
        { name: "Add Items", status: "incomplete" },
        { name: "Dropoff", status: "incomplete" },
        { name: "Review", status: "locked" },
        { name: "Payment", status: "locked", payment: { succeeded: false, error: null, processing: false, disabled: true }},
        { name: "Confirmation", status: "locked", code: null },
    ];
    const [status, setStatus] = useState(initialStatus)

    const setStatusOf = (stepName: string, status: string) => {
        setStatus(statusList => {
            const newStatusList = statusList.map(statusItem => {
                if (statusItem.name === stepName) return { ...statusItem, status }
                return statusItem
            })
            return newStatusList
        })
    }

    const addItemsValid = () => rq.items.length > 0;
    const pickupValid = () => rq.pickupAddress !== initialAddressState;
    const dropoffValid = () => rq.dropoffAddress !== initialAddressState;
    const rqExpiryValid = () => {
        // expiry is valid if more than 1 minute from now
        const expiryDate = new Date(rq.expiryDate);
        const now = new Date();
        const diff = expiryDate.getTime() - now.getTime();
        const moreThan1Minute = diff > 60000;

        // expiry is valid if less than 5 days from now
        const lessThan5Days = diff < (5 * 24 * 60 * 60 * 1000);

        return moreThan1Minute && lessThan5Days;        
    }

    // console.log(document.querySelectorAll<HTMLElement>('.pac-container'));
    // try{
    //     console.log("trying to get pickup address")
    // console.log((document.getElementById("pickup-address") as HTMLInputElement).value);
    // console.log((document.getElementById("-address") as HTMLInputElement).value);

    // }
    // catch(err){
    //     console.log(err)
    // }
    /*
    * Retrieve lastest platform fees on component mount
    */
    const [remoteConfigLoaded, setRemoteConfigLoaded] = useState(false);
    const [rooFee, setRooFee] = useState(null);
    const [recDelivFee, setRecDelivFee] = useState(null);
    const [provincialTaxes, setProvincialTaxes] = useState(null);
    const [preAuth,setPreAuth] = useState(null);

    useEffect(() => {
        const setPlatformFeesAndTaxes = async () => {
            const config = await GET_PLATFORM_FEES_AND_TAXES();
            setRooFee(JSON.parse(config.rooFee));
            setProvincialTaxes(JSON.parse(config.provincialTaxes));
            setRecDelivFee(JSON.parse(config.delivererFeeRecommendation));
            
            console.log(JSON.parse(config.preAuth))
            setPreAuth(JSON.parse(config.preAuth))
            setRemoteConfigLoaded(true);
        }
        setPlatformFeesAndTaxes();
        if (Capacitor.isNativePlatform()) {
        try{
            
            const keyboardDidShowListener = Keyboard.addListener(
                'keyboardDidShow',
                () => {
                  setKeyboardVisible(true); // or some other action
                }
              );
              const keyboardDidHideListener = Keyboard.addListener(
                'keyboardDidHide',
                () => {
                  setKeyboardVisible(false); // or some other action
                }
              );
        }
        catch(err)
        {
            console.log(err)
        }
    }
    else{
        window.addEventListener("resize", (e) => {
            // console.log(initialHeight)
            // console.log(window.outerHeight)
            
            if(initialHeight === window.outerHeight){
                setKeyboardVisible(false); // or some other action
          }
          else{
            setKeyboardVisible(true); // or some other action
          }
        });
    }
    }, []);


    /*
    * Unlock Pages When Required Fields Are Filled
    */
    useEffect(() => {        
        // Unlock Review and Payment if items, pickup, dropoff are completed
        if (addItemsValid() && pickupValid() && dropoffValid()) {
            setStatusOf("Review", "incomplete")
            setStatusOf("Payment", "locked")
        } else if (status[4].payment.succeeded) {
            setStatusOf("Review", "complete")
            setStatusOf("Payment", "complete")
        } else {
            setStatusOf("Review", "locked")
            setStatusOf("Payment", "locked")
        }    
        
        if(editRQ)
        {
            console.log(rq)
            setStatusOf("dropoff","incomplete")
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rq])


    /*
    * Addresses has changed, need to determine new distance
    */
    useEffect(() => {
        setShouldCallDistanceMatrix(true);
    }, [rq.pickupAddress, rq.dropoffAddress])   


    const getTotalWithPreAuth = (delivererFee,taxObj) => {
        const preAuthToTal = rq.costs.items * preAuth.percentage;

        const preAuthTotalCost = rq.costs.items + preAuthToTal;
        const preAuthTotalRooFee = roundTwo(rooFee.base + ((preAuthTotalCost + delivererFee) * rooFee.percentage));
        const preAuthAmountTaxable = delivererFee + preAuthTotalRooFee;
        let preAuthTax = roundTwo(preAuthAmountTaxable * taxObj.rate);
        const result = roundTwo(rq.costs.items+ preAuthToTal + preAuthTotalRooFee + delivererFee + preAuthTax);
        return result;
    }
    const handleScroll = event => {
        const { scrollTop } = event.target;
        const autocompleteContainer = document.querySelectorAll<HTMLElement>('.pac-container');
        autocompleteContainer.forEach((t)=>{
                t.style.marginTop = `-${scrollTop}px`
            })
    }

    /*
    * Move to success page if payment succeeded and manage buttons
    */
    useEffect(() => {
        console.log("test")
        if (status[4].payment.succeeded) {
            swiperRef.current.slideNext();
            
            window.scrollTo(0, 0);
            setTimeout(() => {
                launchConfetti();
            }, 350)
        }
        else if (status[4].payment.processing) {
            setDisabledNext(true);
            setDisabledPrev(true);
        }
        else if(editRQ && pressNext && activeIndex === 0)
        {
            const res = previousStepsValidated(status, launchToastCard, swiperRef, rq, remoteConfigLoaded,t,addressConfirmed,editRQ,setError);
            if(res)
                swiperRef.current.slideNext();
        }
        else if (activeIndex === 0 && status[activeIndex].status === "complete" && status[2].status === "complete" && pressNext )
        {
            // console.log(status)
            // console.log(rq.pickupAddress)
            console.log(rq.dropoffAddress)
            swiperRef.current.slideNext();
            
            window.scrollTo(0, 0);
        }
        // else if(activeIndex!==2 && status[activeIndex].status === "complete" && pressNext){
        //     swiperRef.current.slideNext();
        // }
        else if( activeIndex === 0 && pressNext)
        {
            previousStepsValidated(status, launchToastCard, swiperRef, rq, remoteConfigLoaded,t,addressConfirmed,editRQ,setError)
        }
        else if(activeIndex ===1 && status[activeIndex].status !== "complete" && pressNext)
        {
            previousStepsValidated(status, launchToastCard, swiperRef, rq, remoteConfigLoaded,t,addressConfirmed,editRQ,setError)
        }
        else if(activeIndex ===1 && status[activeIndex].status === "complete" && pressNext){
            if (previousStepsValidated(status, launchToastCard, swiperRef, rq, remoteConfigLoaded,t,addressConfirmed,editRQ,setError)) {

                if (!shouldCallDistanceMatrix) {

                    // Calculate Deliverer Fee
                    const delivererFeeBase = recDelivFee.base;
                    const itemBonus = rq.items.length * recDelivFee.perItemFee;
                    const initialDeliveryFee = delivererFeeBase + itemBonus;
                    let delivererFee = roundTwo((rq.distance.km * recDelivFee.avgCostPerKm) + itemBonus);
                    if (delivererFee < initialDeliveryFee) delivererFee = initialDeliveryFee;

                    if (rq.costs.fees.delivererFee.overwrittenByUser) delivererFee = rq.costs.fees.delivererFee.total;

                    
                   
                    // Calculate Roo Fee. Total Cost is the sum of each item price plus the preauthorization
                    const totalCost = rq.costs.items;
                    let totalRooFee = roundTwo(rooFee.base + ((totalCost + delivererFee) * rooFee.percentage));

                    // Calculate Taxes
                    const amountTaxable = delivererFee + totalRooFee;
                    //get tax of the province where the pickup address is located
                    const taxObj = getTaxRate(rq.pickupAddress.administrative_area_level_1, provincialTaxes);
                    let tax = roundTwo(amountTaxable * taxObj.rate);

                    // Calculate Total
                    const total = roundTwo(rq.costs.items+totalRooFee + delivererFee + tax);

                    const result = getTotalWithPreAuth(delivererFee,taxObj); // is the total amount with preauthorization added. This is the amount we should capture

                    setRQ({
                        ...rq,
                        costs: { 
                            ...rq.costs, 
                            fees: { ...rq.costs.fees,
                                delivererFee: {
                                    ...rq.costs.fees.delivererFee,
                                    total: delivererFee,
                                },
                                rooFee: {
                                    base: rooFee.base, 
                                    percentage: rooFee.percentage, 
                                    total: totalRooFee
                                },
                                preAuth : {
                                    percentage : preAuth.percentage,
                                    total :  rq.costs.items * preAuth.percentage
                                }
                            },
                            tax: {
                                ...rq.costs.tax,
                                total: tax
                            },
                            totalToCapture : result, // amount to capture (total plus preauth)    
                            total: total
                        }
                    })    
                    
                    swiperRef.current.slideNext();
                    
            window.scrollTo(0, 0);

                } else {

                    const pickup = new google.maps.LatLng(rq.pickupAddress.lat, rq.pickupAddress.lng);
                    const dropoff = new google.maps.LatLng(rq.dropoffAddress.lat, rq.dropoffAddress.lng);
        
                    const distanceMatrixService = new google.maps.DistanceMatrixService();
                    distanceMatrixService.getDistanceMatrix({
                        origins: [pickup],
                        destinations: [dropoff],
                        travelMode: google.maps.TravelMode.DRIVING,
                        unitSystem: google.maps.UnitSystem.METRIC,
                    }, (response, status) => {
                        if (status === google.maps.DistanceMatrixStatus.OK) {
                            console.log(response)
                            let distance;
                            let distanceText;
                            let drivingTime = null;
                            let drivingTimeInMinutes = null;
                            try{
                                // Get Distance and Compute Deliverer Fees
                                distance = response.rows[0].elements[0].distance.value;
                                const distanceText = response.rows[0].elements[0].distance.text;
                                drivingTime = response.rows[0].elements[0].duration.value;
                                drivingTimeInMinutes = drivingTime / 60
                            }
                            catch(err)
                            {
                                distance = haversine(rq.pickupAddress.lat,rq.pickupAddress.lng,rq.dropoffAddress.lat,rq.dropoffAddress.lng);
                                distanceText = `${distance} m`
                                alert(`i am doing an error = ${distance} ===> ${distanceText}`)
                            }
                            // Get Distance and Compute Deliverer Fees
                            // const distance = response.rows[0].elements[0].distance.value;
                            // const distanceText = response.rows[0].elements[0].distance.text;
                            // const distanceInKm = (distance * 2 / 1000)
                            const distanceInKm = distance / 1000;
        
                            // const drivingTime = response.rows[0].elements[0].duration.value;
                            // let drivingTimeInMinutes = drivingTime / 60
    
                            // Calculate CO2 Savings
                            const co2Saved = calculateCO2Savings(distanceInKm)                            

                            // Calculate Deliverer Fee
                            const delivererFeeBase = recDelivFee.base;
                            console.log(`distance in km = ${distanceInKm}`)
                            const itemBonus = rq.items.length * recDelivFee.perItemFee;
                            const initialDeliveryFee = delivererFeeBase + itemBonus;
                            let delivererFee = roundTwo((distanceInKm * recDelivFee.avgCostPerKm) + itemBonus);
                            if (delivererFee < initialDeliveryFee) delivererFee = initialDeliveryFee ;

                            if (rq.costs.fees.delivererFee.overwrittenByUser) delivererFee = rq.costs.fees.delivererFee.total;

                            // Calculate Roo Fee. Total Cost is the sum of each item price plus the preauthorization
                            const totalCost = rq.costs.items;
                            console.log(`total Cost = ${totalCost}`)
                            console.log(`delivererFee = ${delivererFee}`)
                            let totalRooFee = roundTwo(rooFee.base + ((totalCost + delivererFee) * rooFee.percentage));
                            
                            // Calculate Taxes
                            const amountTaxable = delivererFee + totalRooFee;
                            const taxObj = getTaxRate(rq.pickupAddress.administrative_area_level_1, provincialTaxes);
                            let tax = amountTaxable * taxObj.rate;
                            tax = roundTwo(tax);
                            // Calculate Total
                            const total = roundTwo(rq.costs.items+ totalRooFee + delivererFee + tax);

                            const result = getTotalWithPreAuth(delivererFee,taxObj); // is the total amount with preauthorization added. This is the amount we should capture
                            console.log(result)
                            setRQ({
                                ...rq,
                                distance: { km: distanceInKm, seconds: drivingTime,text:distanceText },

                                co2Saved: { kg: co2Saved },
                                expiryDate: new Date(currentDateTime.getTime() + (5 * 24 * 60 * 60 * 1000)),
                                costs: { 
                                    ...rq.costs, 
                                    fees: { ...rq.costs.fees,
                                        delivererFee: {
                                            ...rq.costs.fees.delivererFee,
                                            base: 0,
                                            distance: distanceInKm,
                                            costPerKm: recDelivFee.avgCostPerKm,
                                            total: delivererFee,
                                        },
                                        rooFee: {
                                            base: rooFee.base, 
                                            percentage: rooFee.percentage, 
                                            total: totalRooFee
                                        },
                                        preAuth : {
                                            percentage : preAuth.percentage,
                                            total : rq.costs.items * preAuth.percentage
                                        }
                                    },
                                    tax: {
                                        province: rq.pickupAddress.administrative_area_level_1,
                                        taxes: taxObj.taxes,
                                        rate: taxObj.rate,
                                        total: tax
                                    },
                                    totalToCapture : result,   
                                    total: total
                                }
                            })

                            setShouldCallDistanceMatrix(false);

                            swiperRef.current.slideNext();
                            
                            window.scrollTo(0, 0);
                        } else {
                            console.log("Distance Matrix Error: " + status);
                            launchToastCard({ msg: "Internal Error. Please try again.", type: 'error', duration: 8500, })
                        } 
                    });
                }
            }
        }
        else {
            setDisabledNext(false);
            setDisabledPrev(false);
        }
        setPressNext(false)
    }, [status]);
    

    //should check who call this one
    useEffect(() => {
        if (recDelivFee === null || !preAuth ) return;

        console.log("Updating RooQuest Costs")

        // Calculate Deliverer Fee
        const delivererFeeBase = recDelivFee.base;
        const itemBonus = rq.items.length * recDelivFee.perItemFee;
        const initialDeliveryFee = delivererFeeBase + itemBonus;
        let delivererFee = roundTwo((rq.distance.km * recDelivFee.avgCostPerKm) + itemBonus);
        if (delivererFee < initialDeliveryFee) delivererFee = initialDeliveryFee ;
        if (rq.costs.fees.delivererFee.overwrittenByUser) {
            console.log("Deliverer Fee Overwritten", rq.costs.fees.delivererFee.total)
            delivererFee = rq.costs.fees.delivererFee.total;
        }    
        // 25% of items total price
        console.log(preAuth)
        console.log(recDelivFee)
        // Calculate Roo Fee
        const totalCost = rq.costs.items;
        let totalRooFee = roundTwo(rooFee.base + ((totalCost + delivererFee) * rooFee.percentage));
        
        // Calculate Taxes
        const amountTaxable = delivererFee + totalRooFee;
        const taxObj = getTaxRate(rq.pickupAddress.administrative_area_level_1, provincialTaxes);
        let tax = roundTwo(amountTaxable * taxObj.rate);

        // Calculate Total
        const total = roundTwo(rq.costs.items + totalRooFee + delivererFee + tax);
        const result = getTotalWithPreAuth(delivererFee,taxObj); // is the total amount with preauthorization added. This is the amount we should capture
        

        setRQ(rq => ({
            ...rq,
            costs: { 
                ...rq.costs, 
                fees: { ...rq.costs.fees,
                    delivererFee: {
                        ...rq.costs.fees.delivererFee,
                        total: delivererFee,
                    },
                    rooFee: {
                        base: rooFee.base, 
                        percentage: rooFee.percentage, 
                        total: totalRooFee
                    },
                    preAuth : {
                        percentage : preAuth.percentage,
                        total : rq.costs.items * preAuth.percentage
                    }
                },
                tax: {
                    ...rq.costs.tax,
                    total: tax
                },
                totalToCapture:result,   
                total: total
            }
        }))   
    }, [recDelivFee, rq.items, rq.triggerChange]);

    /*
    * On 'Continue' button click event handler
    */
   const [pressNext,setPressNext] = useState(false);
    const next = async () => {
        if (disabledNext) return;
        
        switch(activeIndex) {
            case 0:
                setPressNext(true)
                console.log(status)
                console.log(rq.dropoffAddress)
                setStatusOf("Pickup", pickupValid() ? "complete" : "problem")
                setStatusOf("Dropoff", dropoffValid() ? "complete" : "problem")
                //if(previousStepsValidated(status, launchToastCard, swiperRef, rq, remoteConfigLoaded))
                break;
            case 1:
                setPressNext(true)
                setStatusOf("Add Items", addItemsValid() ? "complete" : "problem")
                //if(previousStepsValidated(status, launchToastCard, swiperRef, rq, remoteConfigLoaded))
                break;
            case 2:
                if (rqExpiryValid()) {
                    setStatusOf("Review", "complete");
                    setStatusOf("Payment", "incomplete")
                    swiperRef.current.slideNext();
                }
                else {
                    setStatusOf("Review", "problem");
                    launchToastCard({ msg: "Invalid RooQuest Expiry.", type: 'error', duration: 8500, })
                }                
                break;
                
            case 4:
                // Reset Form
                setRQ(initialFormData);
                setStatus(initialStatus);
                getMyRooQuests();
                returnToHome();
                swiperRef.current.slideTo(0, 0);
                break;

            default:
                break;
        }
    }

    const prev = () => {
        if (disabledPrev) return;
        swiperRef.current.slidePrev();
    }

    const returnToHome = () => {
        if (status[4].payment.succeeded) {
            return history.push('/home?myrooquests')
        }
        else {
            return history.push('/home')
        }
    }

    return (
        <div className="PostNewRooQuest">
            <IonPage>
                <IonContent>
                    <div className="content">
                        {!(isKeyboardVisible && (activeIndex === 0 || activeIndex === 3)) && <div className="header" ref={headerRef}>
                            <ProgressBarStepper activeIndex={activeIndex} numSlides={5} status={status} />
                        </div> }
                        <div className="main">
                            <div className="mainContent">
                                <div className="info">
                                    <p>{`${editRQ ? `${t('Edit')}` : `${t('Post')}`}`}  {t("RooQuest")}  • {t('Step')} {`${activeIndex + 1}`} {`${t("of")}`} 5</p>
                                    <h1>{pageTitles[activeIndex]}</h1>
                                </div>
                                <div className="closeBtn">
                                    <IonIcon icon={closeOutline} onClick={returnToHome} />
                                </div>
                                <div className={`swiperAndFooterContainer ${activeIndex !== 0 && 'hiddenScroll'}`} onScroll = {handleScroll} > 

                                    <div className={`swiperContainer ${activeIndex === 0 &&'minHeightSwiperContainer' } ${activeIndex === 0 && 'minHeightSwiper'} ${addressConfirmedVisible && activeIndex === 0 && 'addressConfirmedVisible'}`}>
                                    
                                    {rq !== null && <Swiper
                                        onSwiper={(swiper) => {
                                            swiperRef.current = swiper; 
                                        }}
                                        onActiveIndexChange={(swiper) => {
                                            setActiveIndex(swiper.activeIndex)
                                        }}
                                        allowTouchMove={false}
                                        pagination={
                                            {
                                                "type": "progressbar"
                                            }} 
                                        className={`swiper ${activeIndex === 0 && 'minHeightSwiper'} ${addressConfirmedVisible && activeIndex === 0 && 'addressConfirmedVisible'}` }
                                        initialSlide={0}
                                        >
                                            <SwiperSlide>
                                                {/* <Pickup rq={rq} setRQ={setRQ} status={status} editRQ={editRQ} /> */}
                                                <NewPickup rq={rq} setRQ={setRQ} status={status} editRQ={editRQ} setAddressConfirmedVisible = {setAddressConfirmedVisible} error = {error} setError = {setError} addressConfirmed = {addressConfirmed} setAddressConfirmed = {setAddressConfirmed} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                            <AddItems rq={rq} setRQ={setRQ} activeIndex = {activeIndex} />
                                            </SwiperSlide>
                                            {/* <SwiperSlide>
                                                <Pickup rq={rq} setRQ={setRQ} dropoff={true} status={status} editRQ={editRQ} />  
                                            </SwiperSlide> */}
                                            <SwiperSlide>
                                                <Review rq={rq} setRQ={setRQ} activeIndex = {activeIndex} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Payment 
                                                    activeIndex={activeIndex}
                                                    rq={rq} 
                                                    status={status} 
                                                    setStatus={setStatus} 
                                                    button={activeIndex === 3 && nextBtnRef} 
                                                    editRQ={editRQ}
                                                />
                                                
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <ConfirmationPage 
                                                    rq = {rq}
                                                    activeIndex = {activeIndex} 
                                                />
                                            </SwiperSlide>
                                    </Swiper>}
                                </div>
                                    <div className={`footer ${(activeIndex === 0 || activeIndex === 4) && 'rmBack'}`}>
                                        <div className={`backBtn controlBtn ${disabledPrev && 'disabled'}`} onClick={prev}>
                                            <IonIcon icon={chevronBack} />
                                            <p>{t('Back')}</p>
                                        </div>

                                        { 
                                            activeIndex === 3
                                            ? (
                                                <div 
                                                    className={`continueBtn controlBtn 
                                                    ${(
                                                        disabledNext || 
                                                        status[4].payment.processing || 
                                                        status[4].payment.succeeded || 
                                                        status[4].payment.disabled) 
                                                        && 'disabled'}`} 
                                                        ref={nextBtnRef} 
                                                >
                                                    { !status[4].payment.processing ? 
                                                    (
                                                        <>
                                                            <p>{t("Pay Now")}</p> 
                                                            <IonIcon icon={chevronForward} />
                                                        </>
                                                    ) : 
                                                    (
                                                        <IonSpinner name="crescent" />
                                                    )                                                
                                                }
                                                </div>
                                            ) : 
                                            
                                            activeIndex === 4
                                                ? (
                                                    <div className={`continueBtn controlBtn ${disabledNext && 'disabled'}`} onClick={next}>
                                                        <p>{t('Close')}</p>
                                                        <IonIcon icon={chevronForward} />
                                                    </div>
                                                ) 
                                                : 
                                                (
                                                <div className={`continueBtn controlBtn ${disabledNext && 'disabled'}`} onClick={next}>
                                                    <p>{t('Continue')}</p> 
                                                    <IonIcon icon={chevronForward} />
                                                </div>
                                                )                                               
                                        } 
                                    </div>
                                
                                </div>
                            
                            </div>                            
                        </div>
                        <div className="confetti">
                            <Reward
                                ref={confettiRef}
                                type='confetti'
                                config={{
                                    zIndex: 100,
                                    angle: 90,
                                    spread: 60,
                                    elementSize: 16,
                                    lifetime: 350,
                                    startVelocity: 50,
                                    elementCount: 40,
                                    colors: [
                                        '#36c99a',
                                        '#33cc7c',
                                        '#2fd058',
                                        '#25da27',
                                        '#08f712',
                                        '#0ff07b',
                                        '#27d86c',
                                    ],
                                }}
                                >
                                <div className="confettiHolder"></div>
                            </Reward>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        </div>        
    )
}

PostNewRooQuest.propTypes = {

}

export default connect(null, { launchToastCard, getRooQuestByID, getMyRooQuests })(PostNewRooQuest)


// Helpers
const getRightMessage = (index,t) => {
    let msg="";
    switch(index){
        case 0:
            msg =  t("Please provide a valid pickup and dropoff location before proceeding.");
            break;
        case 1:
            msg = t("Please add items to your cart before proceeding.");
            break;
        case 2:
            msg = t("Please provide dropoff location before proceeding.");
            break;
        default:
            break;
    }
    return msg;
}
const previousStepsValidated = (status, launchToastCard, swiperRef, rq, remoteConfigLoaded,t,addressConfirmed,editRQ,setError) => {
    
    if(editRQ && swiperRef.current.activeIndex === 0 && !addressConfirmed)
    {
        setError(true);
        launchToastCard({ 
            msg: getRightMessage(swiperRef.current.activeIndex,t),
            type: 'info',
            duration: 4500,
         });
        return false;
    }

    if (status[swiperRef.current.activeIndex].status === "problem") {
        launchToastCard({ 
            msg: getRightMessage(swiperRef.current.activeIndex,t),
            type: 'info',
            duration: 4500,
         });
        return false;
    }
    else if(status[swiperRef.current.activeIndex].status === "incomplete")
    {
        launchToastCard({ 
            msg: getRightMessage(swiperRef.current.activeIndex,t),
            type: 'info',
            duration: 4500,
         });
        return false;
    }
    else if (!remoteConfigLoaded) {
        launchToastCard({ 
            msg: "Internal Error. Please try again.",
            type: 'info',
            duration: 4500,
         });
        return false;
    }
    return true;
}

export const calculateCO2Savings = (distanceInKm) => {
    const emissionsPerLitre = 2.3; //kg
    const litresUsed = (1/100) * distanceInKm;
    const co2Saved = litresUsed * emissionsPerLitre;
    return co2Saved;
}

export const roundTwo = (value) => {

    const roundingValue = (value*100).toFixed(2)
    return Math.round(parseFloat(roundingValue)) / 100;
}