import React from 'react';
import PropTypes from 'prop-types';
import './MyRQCardTop.scss';
import { useTranslation } from 'react-i18next';
import { isBusiness } from '../../../PostRQForm/components/AutoCompleteWithMap';

const MyRQCardTop = ({ rooquest, statusObj, type = 'status',isInmyDelivPopover = false }) => {
  const { t } = useTranslation();
  return (
    <div className="myRQ_CardTop">
      <div className="row">
        <img
          className="rqImg"
          src={
            (rooquest.imgURLS && rooquest.imgURLS.length) > 0 ? rooquest.imgURLS[0] : require('../NoImageAvailable.png')
          }
          alt=""
        />
        <div className="titleComponent">
          <h3 className="title">{`${rooquest.items.length} ${rooquest.items.length > 1 ? `${t('items')}` : `${t('item')}`}${isBusiness(rooquest.pickupAddress) ? (" from " + rooquest.pickupAddress.name) : ""}`}</h3>
          <h5 className="costs">
            ${parseFloat(rooquest.costOfGoods).toFixed(2)}
            {type !== 'feed' &&
              ` + $${parseFloat(rooquest.costOfDeliverer).toFixed(2)}`}
          </h5>
        </div>
        {type === 'status' && (
          <div className="statusComponent">
            <h5 className="title">{t('Status')}:</h5>
            <h3 className="status" style={statusObj.colour}>
              {rooquest.status === 'ACTIVE' && rooquest.offers.length > 0 ? (isInmyDelivPopover ? `Offer Submitted`:`${rooquest.offers.length} ${t('Offers')}`) : `${t(statusObj.text)}`}
            </h3>
          </div>
        )}
        {type === 'feed' && (
          <div className="earnComponent">
            <h5 className="title">{t('Earn')}:</h5>
            <h3 className="delivCost">
              ${parseFloat(rooquest.costOfDeliverer).toFixed(2)}
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

MyRQCardTop.propTypes = {
  rooquest: PropTypes.object.isRequired,
  statusObj: PropTypes.object,
  type: PropTypes.string,
};

export default MyRQCardTop;
