import { SET_SHOW_SHOW_APPROVE_REQUESTED_FUNDS_ALERT } from "../actions/payments/payment-types";

export default function (state = false, action) {
    const { type, payload } = action;
  
    switch (type) {
      case SET_SHOW_SHOW_APPROVE_REQUESTED_FUNDS_ALERT:
        return payload
      default:
        return state;
    }
  }