import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './RouteInfoVertical.scss';
import { formatRelativeTime } from '../../../../../utils/formatDate';
import { ExpiryCountdown } from '../../../../../pages/Landing/MyRooQuestsPopover/MyRooQuestsPopover';
import { useTranslation } from 'react-i18next';

const RouteInfoVertical = ({ rooquest, long = false, fullAddress = false, showExpiredDate = true }) => {
  let showCarComponent =
    rooquest.pickupLocationType === 'address' && rooquest.hasGeolocation;

  const deliverBy = (rooquest.deliveryTime) ? formatRelativeTime(rooquest.deliveryTime) : null;
  const { t } = useTranslation();

  return (
    <div className="RouteInfoVertical">
      <div className="routeObjContainer">
        {showCarComponent && (
          <Fragment>
            <div className="car">
              <img src={require('./car.svg')} alt="routecomponent" />
              <p className="youLabel">You</p>
            </div>
            <div className="carRouteComponent">
              <div className="verticalLine" />
              <p>
                {rooquest.route &&
                  rooquest.route.routes &&
                  `${rooquest.route.routes[0].legs[0].duration.text} · ${rooquest.route.routes[0].legs[0].distance.text}`}
              </p>
            </div>
          </Fragment>
        )}
        <div className="box">
          <img src={require('./box.svg')} alt="routecomponent" />
          {(rooquest.pickupLocationType === 'store' ||
            rooquest.pickupLocationType === 'other') && (
            <p className="pickupLocation">{rooquest.pickupLocation}</p>
          )}
          {rooquest.pickupLocationType === 'any' && (
            <p className="pickupLocation">Any Location</p>
          )}
          {rooquest.pickupLocationType === 'address' && (
            <p className="pickupLocation">
              {fullAddress && rooquest.pickupAddress.streetAddress && rooquest.pickupAddress.streetAddress.length && ` ${rooquest.pickupAddress.streetAddress}, `}
              {rooquest.pickupAddress.city === '' ? `${rooquest.pickupAddress.formatted_address}`:`${rooquest.pickupAddress.city}, `}
              {fullAddress && ` ${rooquest.pickupAddress.postal}, `}
              {`${rooquest.pickupAddress.province}`}
              {fullAddress && `, ${rooquest.pickupAddress.country}`}
            </p>
          )}
        </div>
        <div className="packageRouteComponent">
          <div className="verticalLine" />
          <p className='totalDistance'>{rooquest && rooquest.totalDistance}</p>
          <p>
            {rooquest.route &&
              rooquest.route.routes &&
              `${rooquest.route.routes[0].legs[1].duration.text} · ${rooquest.route.routes[0].legs[1].distance.text}`}
          </p>
        </div>
        <div className="pin">
          <img src={require('./pin.svg')} alt="routecomponent" />
          <p className="dropoffLocation">
            {fullAddress &&
              rooquest.deliveryAddress.streetAddress !== 'Undefined' &&
              ` ${rooquest.deliveryAddress.streetAddress}, `}
            {rooquest.deliveryAddress.city !== 'Undefined' && (rooquest.deliveryAddress.city === '' ? `${rooquest.deliveryAddress.formatted_address}`:
              `${rooquest.deliveryAddress.city}, `)}
            {fullAddress &&
              rooquest.deliveryAddress.postal !== 'Undefined' &&
              ` ${rooquest.deliveryAddress.postal}, `}
            {rooquest.deliveryAddress.province !== 'Undefined' &&
              `${rooquest.deliveryAddress.province}`}
            {fullAddress &&
              rooquest.deliveryAddress.country !== 'Undefined' &&
              `, ${rooquest.deliveryAddress.country}`}
            {rooquest.deliveryAddress.streetAddress === 'Undefined' &&
              'Specific Coordinates'}
            {fullAddress &&
              rooquest.deliveryAddress.streetAddress === 'Undefined' &&
              `: ${parseFloat(rooquest.deliveryAddress.lat).toFixed(
                4
              )}, ${parseFloat(rooquest.deliveryAddress.lng).toFixed(4)}`}
          </p>
        </div>
        {deliverBy !== null && <div className="deliverByComponent">
          <p className="deliverBy">
            {/* <span className="title">
              {!long ? `${t('Delivery By')}: `: 'Preferred Delivery By: '}
            </span>
            {deliverBy} */}
          </p>
        </div>}
        {showExpiredDate && rooquest.expiryDate && <div className="deliverByComponent">
          <p className="deliverBy">
            <span className="title">
              {t('Expires in')} {' '}
            </span>
            <ExpiryCountdown rooquest={rooquest} />
          </p>
        </div>}
      </div>
    </div>
  );
};

RouteInfoVertical.propTypes = {
  rooquest: PropTypes.object.isRequired,
  long: PropTypes.bool,
  fullAddress: PropTypes.bool,
};

export default RouteInfoVertical;
