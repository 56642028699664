import React, { useEffect, useState, Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact,useIonAlert, useIonViewDidEnter} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Register from './pages/Login/Register';
import { App as AppCapacitor } from '@capacitor/app';
import { v4 as uuidv4 } from 'uuid';

/* Redux */
import { Provider } from 'react-redux';
import store from './store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './index.css';

/* Fonts */
import './fonts/Montserrat-Regular.ttf';

import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth/auth';
import { setUseLiveStripeValue } from './actions/stripe/stripe';
import PrivateRoute from './utils/routing/PrivateRoute';
import Toast from './components/layout/Toast';
import LeftMenu from './components/layout/LeftMenu/LeftMenu';
import Home from './pages/Login/Home';
import { launchToast, launchToastCard } from './actions/layout/toast';
import { RECEIPT_OF_MESSAGE } from './actions/chat/chat-types';
import { updateNavbarWithUnreadMessages } from './actions/layout/navbar';
import { getAllConversations } from './actions/chat/chat';
import Firebase from './firebase';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import ChatModal from './pages/Landing/MessagesPopover/ChatModal';
import MyRQInfoModal from './components/layout/RQCard/Feed/RQInfoModal/MyRQInfoModal';
import MyDelivInfoModal from './components/layout/RQCard/Feed/RQInfoModal/MyDelivInfoModal';
import ReviewPopup from './components/reviews/reviewPopups/reviewPopups';
import ReportBug from './components/layout/reportBug/ReportBug';
import MapPopup from './components/layout/Map/Map';

import Landing from './pages/Landing/Landing';
import EnlargeImage from './components/EnlargeImage/EnlargeImage';

import PostRQModal from './components/post_rooquest/PostRQModal';
import { Elements } from '@stripe/react-stripe-js';
import { getStripeClientID, getStripePromise } from './utils/initStripe';

import TutorialModal from './components/settings/TutorialModal/TutorialModal';
import LocationMapModal from './components/layout/Map/LocationMapModal';
import QuestReq from './components/single_item_quest/quest_req/QuestReq';
import SingleItemQuest from './components/single_item_quest/single_item_quest/SingleItemQuest';
import SingleItemReview from './components/single_item_quest/single_item_review/SingleItemReview';
import SingleItemPayment from './components/single_item_quest/single_item_payment/SingleItemPayment';
import SingleItemConfirm from './components/single_item_quest/single_item_confirm/SingleItemConfirm';
import BuyerConfirmDeliveryModal from './components/layout/RQCard/Feed/RQInfoModal/BuyerConfirmDeliveryModal/BuyerConfirmDeliveryModal';
import Settings from './pages/SettingsPages/Settings/Settings';
import NotificationSettings from './pages/SettingsPages/NotificationSettings/NotificationSettings';
import EmailNotificationSettings from './pages/SettingsPages/EmailNotificationSettings/EmailNotificationSettings';
import socketIOClient from 'socket.io-client';
import DeliverNavMap from './components/deliver_nav_map/deliverNavMap';
import firebase from 'firebase';

// import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";

// import { configureBackgroundGeolocation } from './utils/backgroundGeolocation';
import NotificationMenu from './components/NotificationMenu/NotificationMenu';
import { getAllNotifications } from './actions/notificationmenu/notificationmenu';

import { Capacitor } from '@capacitor/core';
import PostNewRooQuest from './components/PostRQForm/PostNewRooQuest';
import DeliverNow from './components/DeliverNow/DeliverNow';
import 'swiper/swiper.scss';
import ToastCards from './components/ToastCards/ToastCards';
import RooQuestInformation from './components/PostRQForm/RooQuestInformation/RooQuestInformation';
import { getMyDeliveries, getMyRooQuests, getRooQuestByID } from './actions/rooquest/rooquest';
import { PushNotifications } from '@capacitor/push-notifications';
import { UPDATE_ROOQUEST_DATA } from './actions/rooquest/rooquest-types';
import { SET_SHOW_RATINGS_MODAL } from './actions/layout/navigation-types';
import NotificationRange from './pages/SettingsPages/Settings/NotificationRange';
import { Device } from '@capacitor/device';
import { refreshData } from './utils/notifications';
import { setKeyboardVisible } from './actions/keyboard/keyboard';
import { Keyboard } from '@capacitor/keyboard';
import InformationPopup from './components/PostRQForm/RooQuestInformation/RQ/InformationPopup';
import {setShowApproveRequestedFundsAlert} from './actions/payments/payments';
import { setShowAppAlert } from './actions/layout/appAlert';
import AddFundsRequestAlert from './AddFundsRequestAlert';

// BACKEND DOMAIN ADDRESS:
// export const backendAddress = 'http://192.168.2.26:5000';
// export const backendAddress = 'http://10.0.0.29:5000';
//  export const backendAddress = 'http://localhost:5000';
export const backendAddress = 'https://kargoroo-backend-nodejs-c9vu7.ondigitalocean.app';


// BUILD VERSION NUMBER:
// Current iOS Version: 3.0.4
// Current Android Version: 3.1.1
// export const ROO_VERSION_NUMBER = '3.1.0.a14';
// export const ROO_VERSION_NUMBER = '3.1.3.a34';
export const ROO_VERSION_NUMBER = '4.1.5';

// STRIPE API
// if false, TESTING API will be used
// export let USE_LIVE_STRIPE = false;

// export const setUseLiveStripeValue = (value) =>{
//   USE_LIVE_STRIPE = value;
// }

// ENABLE GPS TRACKING
export const USE_GPS_TRACKING = false;

// SOCKET IO
export const socket = socketIOClient(backendAddress);
global.driverInterval = undefined;

setupIonicReact({
  hardwareBackButton: true, // Disable Back Button
});


if (localStorage.token) {
  setAuthToken(localStorage.token); // Set Headers
}

const App = () => {
  // const [response, setResponse] = useState('');
  const [rooquestid, setrooquestid] = useState('');
  const [rooquestname, setrooquestname] = useState('');
  const [showBuyerConfirmationDeliveryModal, setBuyerShowConfirmationDeliveryModal] = useState(false);
  const [present] = useIonAlert();
  const [promptModal, setpromptModal] = useState(false);
  const [isBackground,setIsBackground] = useState(false);
  /////// socket start //////
  // console.log('before socket');
  socket.off('connection_for_mark_as_delievered').on('connection_for_mark_as_delievered', (data) => {
    if (!!data.rooquest) {
      if (data.rooquest.requester === store.getState().auth.user._id) {
        console.log('open modal');
        setrooquestid(data.rooquest._id);
        setrooquestname(data.rooquest.title);
        setBuyerShowConfirmationDeliveryModal(true);
      }
    }
    // console.log('event received', data);
    store.dispatch(loadUser());
    // setDriverLocation({ lat: data.lat, lng: data.lng })
  });
  // console.log('after socket');
  //////////////
  //////////////
  //////////////

  useEffect(() => {
  var promptdata = localStorage.getItem('prompt');
  const askLocation = async () => {
    await present({
      cssClass: 'promptmodal',
      header: 'Background Location Access',   
      message: 'Roo collects location data to enable background delivery tracking of your RooQuests and provide you with up-to-date updates of RooQuests in your area even when the app is closed or not in use.',
      buttons: [
        { text: 'Acknowledge', handler: (d) => callPromptalert()},
      ],
      onDidDismiss: (e) => callPromptalert(true),
    })

  }
  if(!promptdata){
    askLocation();
  }
  });
  const callPromptalert = () => {
      localStorage.setItem('prompt',true);
      setpromptModal(true);
    }

  const checkDevice = async () => {
    let device = await Device.getInfo();
    console.log("device platform = ",device.platform)
    console.log(device.platform === 'ios' || device.platform === 'android')

    return (device.platform === 'ios' || device.platform === 'android')

  }
  useEffect(() => {
   
      const onStart = async () => {
        
  // const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
  // console.log('app rerrendering')
  // if (isPushNotificationsAvailable) {
  //   console.log("add listener to push notification")
  //   PushNotifications.addListener('pushNotificationReceived', (notification) => {
  //     console.log(isBackground)
  //     if(!isBackground)
  //       return;
  //     console.log('NOTIFICATION_RECEIVED');
  //     store.dispatch(loadUser());
  //     store.dispatch(getAllNotifications());
  //     // let parsedNotification = JSON.parse(notification);
  //     if (notification.data && notification.data.message && typeof notification.data.message === 'string') {
  //       notification.data.message = JSON.parse(notification.data.message);
  //     }
  //     if ( 
  //       notification.data &&
  //       notification.data.type === 'NEW_MESSAGE' &&
  //       notification.data.message.senderID !== store.getState().auth.user._id
  //     ) {
  //       if (
  //         store.getState().chat.conversation !== null &&
  //         store.getState().chat.conversation._id === notification.data.message.chatID
  //       ) {
  //         store.dispatch({
  //           type: RECEIPT_OF_MESSAGE,
  //           payload: { ...notification.data.message, _id: uuidv4(), date: new Date() },
  //         });
  //       } else {
  //         console.log("11111")
  //         // store.dispatch(launchToast(notification.body, 3500, 'top'));
  //         store.dispatch(launchToastCard({ 
  //           msg: notification.body,
  //           type: 'info',
  //           duration: 8500,
  //          }));
  //         store.dispatch(updateNavbarWithUnreadMessages(store.getState().navbar.unreadMessages + 1));
  //         if (store.getState().navigation.selectedTab === 'Messages') {
  //           store.dispatch(getAllConversations(store.getState().auth.user._id));
  //         }
  //       }
  //     } else if (notification.data.message.senderID !== store.getState().auth.user._id) {
  //       console.log("22222")

  //       // store.dispatch(launchToast(notification.body, 3500, 'top'));
  //       store.dispatch(launchToastCard({ 
  //         msg: notification.body,
  //         type: 'info',
  //         duration: 8500,
  //        }));
  //     }
  //   });
  // } 
        var promptsdata = localStorage.getItem('prompt');
        try {
          if(promptModal || promptsdata){
            // Check if web
            if (Capacitor.device.platform !== 'web') {
              // console.log('platform')
              console.log(Capacitor.device.platform)
              // if (USE_GPS_TRACKING) await configureBackgroundGeolocation();
            }
          }
        } catch (err) {
          // console.log('NOT NATIVE APP - NOT ENABLING BACKGROUND GEOLOCATION');
        }
        console.log('load user on start')
        store.dispatch(loadUser());
        store.dispatch(setUseLiveStripeValue(false))
        try{
          await Firebase();
        }
        catch(err)
        {
          console.log(err);
        }
        setFirebaseLoaded(true);
  
        // Background / Foreground Events
  
        // Set Screen Orientation to Portrait
        try {
          await ScreenOrientation.lock('portrait');
        } catch (err) {
          console.error(err);
        }
        AppCapacitor.addListener('appStateChange', ({ isActive }) => {
          setIsBackground(!isActive);
          console.log('App state changed. Is active?', isActive);
        });

        AppCapacitor.addListener('resume',()=>{
          //refresh app when the user is back from background mode
          console.log("on resume listener")
          refreshData();
        })

        if (Capacitor.isNativePlatform()) {
          try{
              
              const keyboardDidShowListener = Keyboard.addListener(
                  'keyboardDidShow',
                  () => {
                    console.log("keyboard did show")
                    store.dispatch(setKeyboardVisible(true))
                  }
                );
                const keyboardDidHideListener = Keyboard.addListener(
                  'keyboardDidHide',
                  () => {
                    console.log("keyboard did hide")
                    store.dispatch(setKeyboardVisible(false))
                  }
                );
          }
          catch(err)
          {
              console.log(err)
          }
      }

      };
      onStart();  
  }, [promptModal]);

  // useEffect(()=> {
    
  // },[])


  //should not uncomment
  //  useEffect(() => {
  //   store.dispatch(getMyRooQuests());
  //   store.dispatch(getMyDeliveries());
  //  }, [])

   
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);

  // PWA Notifications
  if (firebaseLoaded) {
    try {
      let messaging = firebase.messaging();

      try {
        messaging.onBackgroundMessage((notif) => {
          console.log('BACKGROUND');
          console.log(notif);
          // store.dispatch(launchToast('NOTIF RECEIVED', 6000, 'top'));
          // console.log("33333")

          store.dispatch(launchToastCard({ 
            msg: 'NOTIF RECEIVED',
            type: 'info',
            duration: 8500,
           }));
        });
      } catch (error) {
        // console.error('ERROR ON BACKGROUND', error);
      }
      
      // messaging.onMessage((notification) => {
      //   console.log('PWA Message Received: ', notification);
      //   if (notification && notification?.data?.type !== "DISTANCE_MESSAGE") store.dispatch(loadUser());
      //   store.dispatch(getAllNotifications());
      //   if (notification.data && notification.data.message && typeof notification.data.message === 'string') {
      //     notification.data.message = JSON.parse(notification.data.message);
      //   }        

      //   if (
      //     notification.data &&
      //     notification.data.type === 'NEW_MESSAGE' &&
      //     notification.data.message.senderID !== store.getState().auth.user._id
      //   ) {
      //     if (
      //       store.getState().chat.conversation !== null &&
      //       store.getState().chat.conversation._id === notification.data.message.chatID
      //     ) {
      //       store.dispatch({
      //         type: RECEIPT_OF_MESSAGE,
      //         payload: { ...notification.data.message, _id: uuidv4(), date: new Date() },
      //       });
      //     } else {
      //       store.dispatch(launchToastCard({ 
      //         msg: notification.notification.body,
      //         type: 'info',
      //         duration: 8500,
      //      }));
      //       store.dispatch(updateNavbarWithUnreadMessages(store.getState().navbar.unreadMessages + 1));
      //       if (store.getState().navigation.selectedTab === 'Messages') {
      //         store.dispatch(getAllConversations(store.getState().auth.user._id));
      //       }
      //     }
      //   // } else if (notification.data && notification.data.message.senderID !== store.getState().auth.user._id) {
      //   } else if (notification.notification && notification.notification.body !== "") {
      //     store.dispatch(getMyRooQuests());
      //     store.dispatch(getMyDeliveries());
          
      //     if (store.getState().rooquest.rooquest !== null) {
      //       store.dispatch(getRooQuestByID(store.getState().rooquest.rooquest._id));
      //     }

      //     store.dispatch(launchToastCard({ 
      //       msg: notification.notification.body,
      //       type: 'info',
      //       duration: 8500,
      //    }));
      //   }
      //   else if (notification.data && notification.data.type) {
      //     if (notification.data.type === 'MY_RQ_ITEM_UPDATED') {
      //       store.dispatch(getMyRooQuests());
      //       if (store.getState().rooquest.rooquest !== null) {
      //         store.dispatch(getRooQuestByID(store.getState().rooquest.rooquest._id));
      //       }
      //     }
      //   }
      // });
    } catch (err) {
      console.error(err);
    }
  }


  //send the user ID when a client connect to the server
  socket.on('connect',()=>{

    const user = store.getState().auth.user;
    if (user)
    {
      console.log('envoi user')
      socket.emit('enter',user._id)
    }
  })

  socket.on('collectsuccess',async (res)=> {
    const isMobile = await checkDevice();
    if(isMobile)
      return;
    console.log("collect success")
    console.log(res.rooquest)
    if (res && res.rooquest && res.status === 'success') {
      store.dispatch({
        type:UPDATE_ROOQUEST_DATA,
        payload : res.rooquest
      })
    }
  })

  socket.on('uncollect',async (res)=> {
    const isMobile = await checkDevice();
    console.log("uncollect success")

    if(isMobile)
      return;
    if (res && res.rooquest && res.status === 'success') {
      store.dispatch({
        type:UPDATE_ROOQUEST_DATA,
        payload : res.rooquest
      })
    }
  })

  socket.on('under review', async (res)=> {
    const isMobile = await checkDevice();
    console.log("under review")
    // if(isMobile)
    //   return;
    if (res && res.rooquest && res.status === 'success') {
      store.dispatch({
        type:UPDATE_ROOQUEST_DATA,
        payload : res.rooquest
      })
    }

  })

  socket.on('rescind',async (res)=> {
      const isMobile = await checkDevice();
    console.log("rescind success")
    console.log(res.rooquest)
    // if(isMobile)
    //   return;
    if (res && res.rooquest && res.status === 'success') {
      store.dispatch({
        type:UPDATE_ROOQUEST_DATA,
        payload : res.rooquest
      })
    }
  })

  // socket.on('')

  useEffect(() => {

    const unsubscribe = store.subscribe(()=> {
      const user = store.getState().auth.user
      if(user)
      {
         socket.emit('enter', user._id);
         unsubscribe(); 
      }
    })
    socket.on('PWA MESSAGE', async (notification)=> {
      const isMobile = await checkDevice();
      if(isMobile)
        return;
      console.log("in pwa message check type", isMobile)
      // console.log(isBackground)
      // if(isBackground)
      //   return;
      console.log('Socket PWA Message Received: ', notification);

      if(notification && notification.data && notification.data.type === 'REQUEST_FUNDS_RESPONSE'){
        store.dispatch(launchToastCard({
          msg: notification.notification.body,
          type: 'info',
          duration: 8500,
        }));
        return;
      
      }

      if(notification && notification.data && notification.data.type === 'REQUEST_FUNDS'){

        console.log("riiioght plaaace")
        store.dispatch(launchToastCard({
          msg: notification.notification.body,
          type: 'info',
          duration: 8500,
        }));

        store.dispatch(getRooQuestByID(notification.data.rooquest));
        
        if(store.getState().navigation.showRQModal.type === 'MYRQ' && store.getState().navigation.showRQModal.visible ){
          store.dispatch(setShowApproveRequestedFundsAlert(true));
        }
        else{
          console.log("dispatch show app alert")
            store.dispatch(setShowAppAlert(true));
        }
        return;
      }

      if (notification && notification?.data?.type !== "DISTANCE_MESSAGE") 
      {
        // console.log("dispatch load user ")
      // store.dispatch(loadUser());
    }
      store.dispatch(getAllNotifications());
      if (notification.data && notification.data.message && typeof notification.data.message === 'string') {
        notification.data.message = JSON.parse(notification.data.message);
      }        

      if (
        notification.data &&
        notification.data.type === 'NEW_MESSAGE' &&
        notification.data.message.senderID !== store.getState().auth.user._id
      ) {
        if (
          store.getState().chat.conversation !== null &&
          store.getState().chat.conversation._id === notification.data.message.chatID
        ) {
          store.dispatch({
            type: RECEIPT_OF_MESSAGE,
            payload: { ...notification.data.message, _id: uuidv4(), date: new Date() },
          });
        } else {
          // console.log("44444")

          store.dispatch(launchToastCard({ 
            msg: notification.notification.body,
            type: 'info',
            duration: 8500,
          }));
          store.dispatch(updateNavbarWithUnreadMessages(store.getState().navbar.unreadMessages + 1));
          if (store.getState().navigation.selectedTab === 'Messages') {
            store.dispatch(getAllConversations(store.getState().auth.user._id));
          }
        }
      // } else if (notification.data && notification.data.message.senderID !== store.getState().auth.user._id) {
      } else if (notification.notification && notification.notification.body !== "") {

        // console.log("pwa ici")
        store.dispatch(getMyRooQuests());
        store.dispatch(getMyDeliveries());
        //commit below two line because after deliverer receive a notification redirected to another page 
        if (store.getState().rooquest.rooquest !== null) {
          store.dispatch(getRooQuestByID(store.getState().rooquest.rooquest._id));
        }
        if(notification.notification && notification.notification.title == "RooQuest Delivery Completed!"){
          store.dispatch({
            type: SET_SHOW_RATINGS_MODAL,
            payload: { visible:true, type:'REQUESTER_REVIEW' },
          });
        }
        store.dispatch(launchToastCard({ 
          msg: notification.notification.body,
          type: 'info',
          duration: 8500,
        }));
      }
      else if (notification.data && notification.data.type) {
        if (notification.data.type === 'MY_RQ_ITEM_UPDATED') {
          store.dispatch(getMyRooQuests());
          if (store.getState().rooquest.rooquest !== null) {
            store.dispatch(getRooQuestByID(store.getState().rooquest.rooquest._id));
          }
        }

        if( notification.data.type === 'ROOQUEST')
        {
          store.dispatch(getMyRooQuests());
        }
      }
    });
  }, [])
  //////


  return (
    <Provider store={store}>
        { firebaseLoaded && <IonApp>
          <IonReactRouter>
            <IonRouterOutlet>
              {/* <Route path="/" render={() => <Redirect to="/" />} exact={true} /> */}
              <Route path="/" component={Home} exact={true} />
              <Route path="/register" component={Register} exact={true} />

              <PrivateRoute path="/questreq" component={QuestReq} exact={true} />
              <PrivateRoute path="/singleitemquest/:status" component={SingleItemQuest} exact={true} />
              <PrivateRoute path="/singleitemreview" component={SingleItemReview} exact={true} />
              <PrivateRoute path="/singleitempayment" component={SingleItemPayment} exact={true} />
              <PrivateRoute path="/SingleItemConfirm" component={SingleItemConfirm} exact={true} />
              <PrivateRoute path="/Settings" component={Settings} exact={true} />
              <PrivateRoute path="/NotificationSettings" component={NotificationSettings} exact={true} />
              <PrivateRoute path="/EmailNotificationSettings" component={EmailNotificationSettings} exact={true} />
              <PrivateRoute path="/deliverNavMap/:id" component={DeliverNavMap} exact={true} />

              <PrivateRoute path="/postrooquest" component={PostNewRooQuest} exact={true} />
              <PrivateRoute path="/delivernow" component={DeliverNow} exact={true} />
              <PrivateRoute path="/editrooquest/:id" component={PostNewRooQuest} exact={true} />
              <PrivateRoute path="/home" component={Landing} exact={true} />
              {/* Catch All Redirect */}
              <Route render={() => <Redirect to="/home" />} />
            </IonRouterOutlet>
            <Toast />
            <ToastCards />
            <div id="notificationMenuDiv"></div>
            <div id="leftMenuDiv"></div>
            <NotificationMenu />
            <LeftMenu />
            {/* <Elements stripe={getStripePromise(store.getState().USE_LIVE_STRIPE)}>

              {firebaseLoaded && (
                <Fragment>
                  <PostRQModal /> */}
                  {/* <EditRQModal /> */}
                {/* </Fragment>
              )}
            </Elements> */}

            <BuyerConfirmDeliveryModal
              showModal={showBuyerConfirmationDeliveryModal}
              setShowModal={setBuyerShowConfirmationDeliveryModal}
              rooquestId={rooquestid}
              rooquestName={rooquestname}
            />
            <TutorialModal />
            {/* <RQInfoModal /> */}
            <RooQuestInformation />
            <MyRQInfoModal />
            <MyDelivInfoModal />
            <EnlargeImage />
            <ChatModal /> 
            <ReviewPopup />
            {/* <PromosPopups /> */}
            <ReportBug />
            <MapPopup />
            <LocationMapModal />
            {/* <MyAddressesModal />  */}
            <NotificationRange />: <></>
            <InformationPopup />
            <AddFundsRequestAlert />
          </IonReactRouter>
        </IonApp>}
    </Provider>
  );
};

export default App;