import axios from 'axios';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  EMAIL_LOGIN_SUCCESS,
  EMAIL_LOGIN_FAIL,
  LOGOUT,
  USER_LOADED,
  AUTH_ERROR,
  USER_LOADING,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAIL,
} from './auth-types';
import { backendAddress,} from '../../App';
import { launchToast,launchToastCard } from '../layout/toast';
import setAuthToken from '../../utils/setAuthToken';
import { CLEAR_ROOQUESTS } from '../rooquest/rooquest-types';
import { loadThemes } from '../user/user';

import { getAllConversations } from '../chat/chat';
import { registerForPushNotification, saveFCMTokenToUserObject } from '../push-notifications';

import firebase from 'firebase';
// import '@codetrix-studio/capacitor-google-auth';
// import { Plugins } from '@capacitor/core';
import { Device } from '@capacitor/device';
// import BackgroundGeolocation from 'cordova-background-geolocation-lt';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { checkToEnableBackgroundGeolocation } from '../../utils/backgroundGeolocation';
import { SET_STRIPE_API_KEY } from '../stripe/stripe-types';

declare global {
  interface Window {
      mozRequestAnimationFrame :any;
      webkitRequestAnimationFrame :(callback: FrameRequestCallback) => number;
      msRequestAnimationFrame:any;
      mozCancelAnimationFrame : any
  }
}


// On Authentication Actions
const onAuthentication = async (user) => {
  // If localStorage contains token, apply it.
  if (localStorage.token) {
    setAuthToken(localStorage.token); // Set Headers
  }

  // Set Theme
  // loadTheme(user);
     loadThemes(user);

  // Get User's notifications

  // Check if Background Geolocation should be enabled
  checkToEnableBackgroundGeolocation(user);

  // Connect To Socket
  // socket.connect();
  // socket.emit('user_connected', user);

  // Register for Push Notifications
  // const { Device } = Plugins;
  let device = await Device.getInfo();
  // console.log(device)
  if (device.platform === 'ios' || device.platform === 'android') {
    // Issue of firebase due to which app is crashing in android after login
    registerForPushNotification(user);
  }
  else {
    // console.log("Requesting Permission...")
    Notification.requestPermission().then(async function(result) {
      if (result === 'granted') {
        let messaging = firebase.messaging();
        let FCM_token = await messaging.getToken({ vapidKey: 'BANTVjNpeN2BjcC5jOel6iUrQn21BXce-nTR5rblnEGjf_aDdOgTr50OefzVf-r8b9ufvxMmJtQWIb7Uv9OhpSo'});
        saveFCMTokenToUserObject(FCM_token);        
      }
    })
  }

  
  // console.log("onAUTH")
  // const platforms = getPlatforms();
  // console.log(platforms)

  // console.log("Is a Browser / Mobile PWA app.");
  
  // Notification.requestPermission().then(
  //   (permission) => {
  //     console.log(permission);
  // })
  
};

// Load User
export const loadUser = () => async (dispatch) => {
  console.log("load user")
  dispatch({
    type: USER_LOADING,
  });
  try {
    const res = await axios.get(backendAddress + '/api/auth');

    if(res.data.mode === 'live')
    {
      dispatch({
        type: SET_STRIPE_API_KEY,
        payload: true
    });
    }
    else{
      dispatch({
        type: SET_STRIPE_API_KEY,
        payload: false
    });
    }
    await onAuthentication(res.data);

    // if(socket.connected)
    //   socket.emit('join', res.data._id)
    //   dispatch({
    //     type: IS_RENDERED
    //   })

    // Get Conversations / Messages
    dispatch(getAllConversations(res.data._id));

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });

    // if (!user.FCM_tokens.contains(token.value)) {
    //   console.log("Saving FCM_Token");
    //   saveFCMTokenToUserObject(token.value);
    // }

    return res.data;
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User By Email
export const register = (
  firstName,
  lastName,
  email,
  phoneNumber,
  password
) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
  });

  try {
    const res = await axios.post(
      backendAddress + '/api/auth/email/register',
      body,
      config
    );

    if (res.data.token) {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: REGISTER_FAIL,
    });
    // dispatch(launchToast(err.response.data.errors[0].msg, 1500, 'bottom'));
    dispatch(launchToastCard({ 
      msg: err.response.data.errors[0].msg,
      type: 'error',
      duration: 8500,
   }));
  }
};

export const loginWithEmail = (email, password) => async (dispatch) => {
  // console.log(email, password);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    email,
    password,
  });

  try {
    const res = await axios.post(
      backendAddress + '/api/auth/email/login',
      body,
      config
    );
    if (res.data.user) {
      await dispatch({
        type: EMAIL_LOGIN_SUCCESS,
        payload: res.data,
      });
      onAuthentication(res.data.user);
    } else {
      dispatch({
        type: EMAIL_LOGIN_FAIL,
      });
      // dispatch(launchToast(res.data.msg, 2000));
      dispatch(launchToastCard({ 
        msg: res.data,
        type: 'error',
        duration: 8500,
     }));
    }
  } catch (err) {
    dispatch({
      type: EMAIL_LOGIN_FAIL,
    });
  }
};

export const registerWithEmailAndPasswordFirebase = (newUserDetails) => async (
  dispatch
) => {
  try {
    if (!newUserDetails.firstName || newUserDetails.firstName.length < 2) {
      // eslint-disable-next-line
      throw { message: 'First Name Required' };
    }
    if (!newUserDetails.lastName || newUserDetails.lastName.length < 2) {
      // eslint-disable-next-line
      throw { message: 'Last Name Required' };
    }
    if (!newUserDetails.email || newUserDetails.email.length < 2) {
      // eslint-disable-next-line
      throw { message: 'Email Required' };
    }

    await firebase
      .auth()
      .createUserWithEmailAndPassword(
        newUserDetails.email,
        newUserDetails.password
      );
    const user = firebase.auth().currentUser;
    // eslint-disable-next-line
    if (!user) throw 'NoUserError';
    else {
      const token = { token: await user.getIdToken() };
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = {
        firstName: newUserDetails.firstName,
        lastName: newUserDetails.lastName,
        email: user.email,
        token: token,
        // flag: newUserDetails.rememberme
      };

      // Send to backend
      try {
        const backendResponse = await axios.post(
          backendAddress + '/api/auth/register/email',
          body,
          config
        );
        if (backendResponse.data.token) {
          onAuthentication(backendResponse.data.user);
          await dispatch({
            type: EMAIL_LOGIN_SUCCESS,
            payload: {
              user: backendResponse.data.user,
              token: backendResponse.data.token.token,
            },
          });
        }
      } catch (err) {
        await dispatch({
          type: EMAIL_LOGIN_FAIL,
        });
        if (err.response.data.message) {
          // dispatch(launchToast(err.response.data.message, 2000, 'top'));
          dispatch(launchToastCard({ 
            msg: err.response.data.message,
            type: 'error',
            duration: 8500,
         }));
        }
        console.error(err);
      }
    }
  } catch (err) {
    if (err.message) {
      // dispatch(launchToast(err.message, 2000, 'top'));
      dispatch(launchToastCard({ 
        msg: err.message,
        type: 'error',
        duration: 8500,
     }));
    }
    await dispatch({
      type: EMAIL_LOGIN_FAIL,
    });
    console.error(err);
  }
};

export const loginWithEmailAndPasswordFirebase = (userDetails) => async (
  dispatch
) => {
  try {
    if (!userDetails.email || userDetails.email.length < 2) {
      // eslint-disable-next-line
      throw { message: 'Email Required' };
    }
    if (!userDetails.password || userDetails.password.length < 2) {
      // eslint-disable-next-line
      throw { message: 'Password Required' };
    }

    await firebase
      .auth()
      .signInWithEmailAndPassword(userDetails.email, userDetails.password);
    const user = firebase.auth().currentUser;
    // eslint-disable-next-line
    if (!user) throw 'NoUserError';
    else {
      const token = { token: await user.getIdToken() };
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = {
        email: user.email,
        token: token,
        flag: userDetails.rememberme
      };

      try {
        const backendResponse = await axios.post(
          backendAddress + '/api/auth/login/email',
          body,
          config
        );
        if (backendResponse.data.token) {
          onAuthentication(backendResponse.data.user);
          await dispatch({
            type: EMAIL_LOGIN_SUCCESS,
            payload: {
              user: backendResponse.data.user,
              token: backendResponse.data.token.token,
            },
          });
        }
      } catch (err) {
        await dispatch({
          type: EMAIL_LOGIN_FAIL,
        });
        console.error(err);
      }
    }
  } catch (err) {
    if (err.message) {
      // dispatch(launchToast(err.message, 2000, 'top'));
      dispatch(launchToastCard({ 
        msg: err.message,
        type: 'error',
        duration: 8500,
     }));
    }
    await dispatch({
      type: EMAIL_LOGIN_FAIL,
    });
    console.error(err);
  }
};

export const sendPasswordReset = async (email) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    return { error: null };
  } catch (err) {
    console.error(err);
    if(err.code === "auth/user-not-found"){
     return { error: "There is no user record corresponding to this Email Id. The user may have been deleted" }
    }
    return { error: err.message };
  }
};

export const signInWithGoogleFirebase = (response) => async (dispatch) => {
  try {
    await firebase
      .auth()
      .signInWithCredential(
        firebase.auth.GoogleAuthProvider.credential(response.authentication)
      );
    const user = firebase.auth().currentUser;

    // eslint-disable-next-line
    if (!user) throw 'NoUserError';
    else {
      const token = { token: await user.getIdToken() };
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = {
        firstName: response.givenName,
        lastName: response.familyName,
        email: user.email,
        imageURL: response.imageUrl,
        token: token,
      };

      // Send to backend
      try {
        const backendResponse = await axios.post(
          backendAddress + '/api/auth/social/googlelogin',
          body,
          config
        );
        if (backendResponse.data.token) {
          onAuthentication(backendResponse.data.user);
          await dispatch({
            type: SOCIAL_LOGIN_SUCCESS,
            payload: {
              user: backendResponse.data.user,
              token: backendResponse.data.token.token,
            },
          });
        }
      } catch (err) {
        await dispatch({
          type: SOCIAL_LOGIN_FAIL,
        });
        console.error(err);
      }
    }
  } catch (err) {
    await dispatch({
      type: SOCIAL_LOGIN_FAIL,
    });
    // dispatch(launchToast('Error: Please try again.', 2000, 'top'));
    dispatch(launchToastCard({ 
      msg: 'Error: Please try again.',
      type: 'error',
      duration: 8500,
   }));
    console.error(err);
  }
};

export const signInWithGoogleIOS = (user) => async (dispatch) => {
  // console.log('signInWithGoogleIOS');
  try {
    // eslint-disable-next-line
    if (!user) throw 'NoUserError';
    else {
      const token = { token: await user.getIdToken() };
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = {
        firstName: user.displayName,
        lastName: user.displayName,
        email: user.email,
        imageURL: user.imageUrl,
        token: token,
      };

      // Send to backend
      try {
        const backendResponse = await axios.post(
          backendAddress + '/api/auth/social/googlelogin',
          body,
          config
        );

        if (backendResponse.data.token) {
          onAuthentication(backendResponse.data.user);
          await dispatch({
            type: SOCIAL_LOGIN_SUCCESS,
            payload: {
              user: backendResponse.data.user,
              token: backendResponse.data.token.token,
            },
          });
        }
      } catch (err) {
        await dispatch({
          type: SOCIAL_LOGIN_FAIL,
        });
        console.error(err);
      }
    }
  } catch (err) {
    await dispatch({
      type: SOCIAL_LOGIN_FAIL,
    });
    // dispatch(launchToast('Error: Please try again.', 2000, 'top'));
    dispatch(launchToastCard({ 
      msg: 'Error: Please try again.',
      type: 'error',
      duration: 8500,
   }));
    console.error(err);
  }
};
const nonce = 'nonce';
export const signInWithAppleFirebase = (appleResponse) => async (dispatch) => {
  logToBackendDebug({ data: 'SIGN IN WITH APPLE FIREBASE START' });
  logToBackendDebug({ data: 'APPLE RESPONSE' });
  logToBackendDebug({ data: appleResponse });

  const provider = new firebase.auth.OAuthProvider('apple.com');
  // Create sign in credentials with our token
  const credential = provider.credential({
    idToken: appleResponse.identityToken,
    rawNonce: nonce
  });
  // Call the sign in with our created credentials
  firebase
    .auth()
    .signInWithCredential(credential)
    .then(() => {
      logToBackendDebug({ data: 'FIREBASE SIGN IN WITH CREDENTIAL' });

      const user = firebase.auth().currentUser;
      if(user.displayName == null &&  appleResponse.givenName){
        user.updateProfile({
          displayName: appleResponse.givenName,
        }).then(() => {
          console.log("name Update successfully")
        }).catch((error) => {
          console.log("name not Updated")
        });  
      }
   
      user.getIdToken().then((token) => {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        console.log('firebase current user getidtoken success');
        console.log(user);
        console.log(appleResponse);
        const body = {
          firstName: user.displayName ? user.displayName : appleResponse.givenName,
          lastName: user.displayName ? user.displayName : appleResponse.givenName,
          email: user.email,
          token: token,
        };
        logToBackendDebug({ data: 'CALL AXIOS' });

        axios
          .post(backendAddress + '/api/auth/social/googlelogin', body, config)
          .then((backendResponse) => {
            logToBackendDebug({ data: '.then' });
            logToBackendDebug({ data: backendResponse });

            console.log('backendResponse');
            console.log(backendResponse);
            if (backendResponse.data.token) {
              onAuthentication(backendResponse.data.user);
              dispatch({
                type: SOCIAL_LOGIN_SUCCESS,
                payload: {
                  user: backendResponse.data.user,
                  token: backendResponse.data.token.token,
                },
              });
            }
          })
          .catch((err) => {
            console.error(err);
            logToBackendDebug({ data: 'AXIOS CATCH BLOCK' });
            logToBackendDebug({ data: err });
          });
      });
    })
    .catch((err) => {
      console.log('sign credential error');
      console.log(err);
      // dispatch(launchToast(`ERROR HERE: ${err.message}`));
      dispatch(launchToastCard({ 
        msg: err.message,
        type: 'error',
        duration: 8500,
     }));
      logToBackendDebug({ data: 'OUTER CATCH BLOCK' });
      logToBackendDebug({ data: err });
    });
};

//Logout
export const logout = () => async (dispatch) => {
try {
  document.body.classList.value = "null"
  await GoogleAuth.signOut();
  await firebase.auth().signOut();
} catch (err) {
  console.error(err)
}
  dispatch({ type: CLEAR_ROOQUESTS });
  dispatch({ type: LOGOUT });
};

export const logToBackendDebug = (body) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const res = axios.post(backendAddress + '/api/auth/debug', body, config);
  console.log(res);
};

//remove an user account
export const deleteUser = async () => {
  await firebase.auth().currentUser.delete().then(async () => {
    console.log("user deleted");
    // User deleted.
  }).catch((error) => {
    console.log(error)
    // An error ocurred
    // ...
  });
}
// function configureBackgroundGeolocation() {
//   throw new Error('Function not implemented.');
// }

