import { IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonIcon, IonText, IonCardTitle, IonCardSubtitle, IonButton, IonAlert, isPlatform, IonModal, IonFab, IonFabButton } from "@ionic/react";
import { time } from "console";
import { setISODay } from "date-fns/esm";
import { homeOutline, chatbubbleEllipsesOutline, closeCircleOutline, list } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { cancelRooQuestDelivery, getMyDeliveries, updateRooQuestWithoutCall } from "../../../actions/rooquest/rooquest";
import './DeliveryModalContent.scss';

const DeliveryModalContent = ({openChat,rooquest,t,closeModal,next,close,cancelRooQuestDelivery, getMyDeliveries, updateRooQuestWithoutCall}) => {
	const [showCancelDeliveryAlert, setShowCancelDeliveryAlert] = useState(false);
	let click=0;
	const navigator = async () => {
		try{

			const geocoords = rooquest.deliveryAddress.lat + ',' + rooquest.deliveryAddress.lng;
			  if (isPlatform('ios')) {
				window.open('maps://?q=' + geocoords, '_system');
			  }
			  else {
				var label = encodeURI(rooquest.deliveryAddress.formatted_address); // encode the label!
				window.open('geo:0,0?q=' + geocoords + '(' + label + ')', '_system');
			  }
		}
		catch(err)
		{
			console.log(err);
		}
	}

	const cancelDelivery = async (reason) => {
		await cancelRooQuestDelivery(rooquest._id, reason)
		updateRooQuestWithoutCall(null);
		await getMyDeliveries();
		closeModal()
	};

	const handleArrivedButton = async () =>{
		close();
	}

    return(
        <IonContent>
			<div className="DeliveryModalContent">

				<IonGrid>
					<IonRow className="ion-justify-content-center">
						<IonCol size="12" className="ion-justify-content-center ion-align-items-center ion-text-center">
							<IonCard className="profileHeader">

								<IonCardContent>

									<IonRow>
										<IonCol size="4" className="dropoffIcon">
											{/* <img src={homeOutline} alt="avatar" className="avatar" /> */}
                                            <IonIcon src={homeOutline} />
										</IonCol>

										<IonCol size="8">
											<IonRow className="profileInfo">
												<IonCol size="12">
													<IonText color="dark" className="profileName">
														<p>{t('Dropoff Address')}</p>
													</IonText>
													<IonText color="medium">
														<p>{rooquest.deliveryAddress.formatted_address}</p>
													</IonText>
												</IonCol>
											</IonRow>

											<IonRow className="profileStats">

												<IonCol className="profileStat">

													<IonCardTitle>{t('Customer Note')}</IonCardTitle>
													{rooquest.additionalNote ? <IonCardContent><p>{rooquest.additionalNote} </p> </IonCardContent>: <IonCardContent><p>{t('No additional Note provided by the client')} </p> </IonCardContent>}

												</IonCol>
											</IonRow>
										</IonCol>
									</IonRow>

									<IonRow className="buttons">
									<IonCol size="4">
                            <span onClick={async () => openChat()}>
                            <IonCardTitle>
								<div className="contactBtn del-modal-btn">
									<IonIcon icon={ chatbubbleEllipsesOutline } />
								</div>
							</IonCardTitle>
                            <IonCardSubtitle className="del-modal-btn-text">{t('Chat')}</IonCardSubtitle>
                            </span>
                        </IonCol>

                        <IonCol size="4">
                            <span onClick={navigator}>
                            <IonCardTitle>
								<div className="contactBtn del-modal-btn">
									<IonIcon icon={require('./direction.svg')} />
								</div>
							</IonCardTitle>
                            <IonCardSubtitle className="del-modal-btn-text">{t('Directions')}</IonCardSubtitle>
                            </span>
                        </IonCol>

                        <IonCol size="4">
                            <span onClick={() => setShowCancelDeliveryAlert(true)}>
                                <IonCardTitle>
									<div className="contactBtn del-modal-btn">
										<IonIcon icon={ closeCircleOutline } />
									</div>
                                </IonCardTitle>
                                <IonCardSubtitle className="del-modal-btn-text">{t('Cancel Trip')}</IonCardSubtitle>
                            </span>
                        </IonCol>
									</IonRow>
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>

					<IonRow className="profileActionContainer">
						<IonCol size="12">
                            <IonButton color="--mrq-button-main-background" fill="outline" expand="block" shape="round" onClick={handleArrivedButton}>ARRIVED</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
				</div>
				<IonAlert
                isOpen={showCancelDeliveryAlert}
                onDidDismiss={() => setShowCancelDeliveryAlert(false)}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Cancel Delivery')} ?`}
                message={
                    `${t('Are you sure you want to cancel the delivery? This will be kept on your deliverer record and can lead to account penalties.')}`
                }
                inputs={[ { name: 'cancellationReason', type: 'text', placeholder: `${t('Reason for cancellation')}` }, ]}
                buttons={[
                    { text: `${t('Close')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Cancel Delivery')}`,
                        handler: async (alertData) => {
                        await cancelDelivery(alertData.cancellationReason);
                        },
                    },
                ]}
            />

			</IonContent>

    );
}

export default connect(null,{cancelRooQuestDelivery, getMyDeliveries, updateRooQuestWithoutCall})(DeliveryModalContent);