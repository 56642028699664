import {
  GET_ALL_ROOQUESTS,
  ROOQUEST_ERROR,
  GET_ROOQUEST_BY_ID,
  POST_ROOQUEST,
  GET_MY_ROOQUESTS,
  GET_MY_DELIVERIES,
  CLEAR_ROOQUESTS,
  DELIVER_ROOQUEST,
  GETTING_ROOQUESTS,
  UPDATE_ROOQUEST_STATUS,
  UPDATE_ROOQUEST_ROUTES,
  SET_HAS_GEOLOCATION,
  ROOQUEST_ACTION_LOADING,
  HIDE_ROOQUEST,
  UPDATE_ROOQUEST_DATA,
  ROOQUEST_LOADING
} from '../actions/rooquest/rooquest-types';

const initialState = {
  rooquests: [],
  rooquest: null,
  myrooquests: [],
  mydeliveries: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETTING_ROOQUESTS:
      return {
        ...state,
        loading: true,
      };

    case ROOQUEST_ACTION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_ROOQUESTS:
      return {
        ...state,
        rooquests: payload,
        loading: false,
      };
    case GET_MY_ROOQUESTS:
      return {
        ...state,
        myrooquests: payload,
        loading: false,
      };
    case GET_MY_DELIVERIES:
      return {
        ...state,
        mydeliveries: payload,
        loading: false,
      };

    case GET_ROOQUEST_BY_ID:
      return {
        ...state,
        rooquest: payload,
        loading: false,
      };

    case POST_ROOQUEST:
      return {
        ...state,
        myrooquests: [...state.myrooquests, payload],
        loading: false,
      };

    case UPDATE_ROOQUEST_DATA:
      // console.log('update rooquest data ')
      // console.log('payload ID')
      // console.log(payload._id) 
      try {
        let newRooquest = state.rooquest;
        if(state.rooquest._id === payload._id)
          newRooquest = payload
        console.log("update rooquest data")
        console.log(state.rooquest)  
        console.log(newRooquest)
        return {
          ...state,
          rooquests: state.rooquests.map((rq) => {
            if (rq._id === payload._id) {
              return {
                ...payload
              };
            }
            return rq;
          }),
          rooquest: {...newRooquest}
        }
        console.log("done successfully")
      }
      catch(err){
        console.log("errr ici")
        console.log(err)
      }
      

    case DELIVER_ROOQUEST:
      return {
        ...state,
        mydeliveries: [...state.mydeliveries, payload],
        loading: false,
      };

    case UPDATE_ROOQUEST_STATUS:
      return {
        ...state,
        rooquest: payload,
        loading: false,
      };

      case HIDE_ROOQUEST:
        return {
          ...state,
          rooquest: payload,
          loading: false,
        };

    case UPDATE_ROOQUEST_ROUTES:
      return {
        ...state,
        rooquests: state.rooquests.map((rq) => {
          if (rq._id === payload.id) {
            return {
              ...rq,
              route: payload.route,
            };
          }
          return rq;
        }),
        loading: false,
      };

    case CLEAR_ROOQUESTS:
      return {
        rooquests: [],
        rooquest: null,
        myrooquests: [],
        mydeliveries: [],
        loading: false,
      };

    case ROOQUEST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case ROOQUEST_LOADING:
        return {
          ...state,
          loading: payload,
        };
        
    case SET_HAS_GEOLOCATION:
      return {
        ...state,
        rooquests: state.rooquests.map((rq) => {
          if (rq._id === payload.rqID) {
            return {
              ...rq,
              hasGeolocation: payload.bool,
            };
          }
          return rq;
        }),
        loading: false,
      };

    default:
      return state;
  }
}
