import React, { useRef, useEffect, useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButton,
  IonMenu,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
} from '@ionic/react';

import './LeftMenu.scss';
import {
  logOutOutline,
  businessOutline,
  globeOutline,
  linkOutline,
  bugOutline,
  helpCircleOutline,
  settingsOutline
} from 'ionicons/icons';

import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import { logout } from '../../../actions/auth/auth';
import { menuController } from '@ionic/core';
import { updateTheme } from '../../../actions/user/user';
import Spinner from '../Spinner/Spinner';
import { getStripeAccountLink } from '../../../actions/payments/payments';

import { loadUser } from '../../../actions/auth/auth';

import { InAppBrowser } from '@ionic-native/in-app-browser';

import DelivererSetupModal from '../../settings/DelivererSetupModal/DelivererSetupModal';
import {
  setShowPromoModal,
  setShowBugReportModal,
  setShowTutorialModal,
  setShowMyAddressesModal,
} from '../../../actions/layout/navigation';
import { ROO_VERSION_NUMBER} from '../../../App';
import { useHistory } from 'react-router-dom';
import { launchToast,launchToastCard } from '../../../actions/layout/toast';
import { useTranslation } from 'react-i18next';
import ActivationCodeModal from '../../promos/ActivationCodeModal';
const LeftMenu = ({
  logout,
  auth,
  updateTheme,
  setShowPromoModal,
  setShowBugReportModal,
  navigation,
  setShowTutorialModal,
  setShowMyAddressesModal,
  USE_LIVE_STRIPE
}) => {
  const menuRef = useRef(null);
  const { t } = useTranslation();
  const closeMenu = async () => {
    await menuController.close('leftMenu');
  };

  const [showPromo, setShowPromo] = useState(false);

  const logoutUser = async () => {
    await closeMenu();
    logout();
  };

  const dispatch = useDispatch();

  const goToAccountInBrowser = async () => {
    const link = await getStripeAccountLink(auth.user.stripeAccountID);
    if (link) {
      InAppBrowser.create(link, '_blank', {
        zoom: 'no',
        hideurlbar: 'yes',
        hidenavigationbuttons: 'yes',
        toolbarcolor: '#073809',
        closebuttoncolor: '#ffffff',
        closebuttoncaption: 'Close',
      });
    }
    else {
      // dispatch(launchToast("Invalid Stripe Link. Please remove Stripe account in Settings.", 3500, 'top'));
      dispatch(launchToastCard({ 
        msg: 'Invalid Stripe Link. Please remove Stripe account in Settings.',
        type: 'error',
        duration: 8500,
    }))
    }
  };

  const history = useHistory();
  const goToSettings = async () => {
    await menuController.close('leftMenu');
      history.push('/Settings')
  }
  // const checkDeliveryAccount = async () => {
  //   await menuController.close('leftMenu');
  //   // setShowCreateDeliveryAccountModal(true);
  // };

  const [
    showCreateDeliveryAccountModal,
    setShowCreateDeliveryAccountModal,
  ] = useState(false);
  // const createDeliveryAccountModalRef = useRef(null);

  return !auth.loading ? (
    <div className="leftMenu">
      <IonMenu
        ref={menuRef}
        contentId="leftMenuDiv"
        class="leftMenuDiv"
        menuId="leftMenu"
        side="start"
        type="overlay"
        disabled={navigation.selectedTab === null}
      >
        <IonHeader>
          <IonToolbar color="primary" >
            {auth && auth.user && (
              <div className="title">
                <h1>{t('Hello')} {auth.user.firstName}!</h1>
              </div>
            )}
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            {/* <IonItem>
              <IonButton
                buttonType="string"
                onClick={() => setShowMyAddressesModal(true)}
              >
                <IonIcon icon={businessOutline}></IonIcon>
                <IonLabel className="btnLabel">{t('My Addresses')}</IonLabel>
              </IonButton>
            </IonItem> */}

            {auth && auth.user && !auth.user.stripeAccountID ? (
              <IonItem>
                <IonButton
                  buttonType="string"
                  onClick={async () => {
                    await menuController.close('leftMenu');
                    setShowCreateDeliveryAccountModal(true);
                    // createDeliveryAccountModalRef.current.present();
                  }}
                >
                  <IonIcon icon={globeOutline}></IonIcon>
                  <IonLabel className="btnLabel">
                  {t('Set up Deliverer Account')}
                  </IonLabel>
                </IonButton>
              </IonItem>
            ) : (
              <IonItem>
                <IonButton buttonType="string" onClick={goToAccountInBrowser}>
                  <IonIcon icon={globeOutline}></IonIcon>
                  <IonLabel className="btnLabel">{t('See Delivery Account')}</IonLabel>
                </IonButton>
              </IonItem>
            )}

            {!auth.user?.isLinkedToPromoCode && (
              <IonItem>
                <IonButton
                  buttonType="string"
                  onClick={async () => {
                    await menuController.close('leftMenu');
                    setShowPromo(true);
                  }}
                >
                  <IonIcon icon={linkOutline}></IonIcon>
                  <IonLabel className="btnLabel">
                  {t('Activation/Promo Codes')}
                  </IonLabel>
                </IonButton>
                <ActivationCodeModal show={showPromo} setShow={setShowPromo} />
              </IonItem>
            )}

            <IonItem>
              <IonButton
                buttonType="string"
                onClick={async () => {
                  // await closeMenu();
                  menuRef.current.close();
                  setShowTutorialModal(true, { showActivationCode: true });
                }}
              >
                <IonIcon icon={helpCircleOutline}></IonIcon>
                <IonLabel className="btnLabel">{t('Open Tutorial')}</IonLabel>
              </IonButton>
            </IonItem>

            {/* <IonItem>
              <IonButton
                buttonType="string"
                onClick={async () => {
                  await menuController.close('leftMenu');
                  setShowTutorialModal(true, 'FIRST_LOGIN');
                }}
              >
                <IonIcon icon={helpCircleOutline}></IonIcon>
                <IonLabel className="btnLabel">Open Tutorial</IonLabel>
              </IonButton>
            </IonItem> */}

            <IonItem>
              <IonButton
                buttonType="string"
                onClick={() => {menuRef.current.close();setShowBugReportModal(true)}}
              >
                <IonIcon icon={bugOutline}></IonIcon>
                <IonLabel className="btnLabel">{t('Report Bug/Feedback')}</IonLabel>
              </IonButton>
            </IonItem>

            <IonItem>
            <IonButton
                buttonType="string"
                onClick={() => goToSettings()}
              >
                <IonIcon icon={settingsOutline}></IonIcon>
                <IonLabel className="btnLabel">{t('Settings')}</IonLabel>
            </IonButton>
          </IonItem>
            <IonItem>
              <IonButton buttonType="string" onClick={logoutUser}>
                <IonIcon icon={logOutOutline}></IonIcon>
                <IonLabel className="btnLabel">{t('Logout')}</IonLabel>
              </IonButton>
            </IonItem>
          </IonList>

          {/* <IonItem
            style={{
              position: 'absolute',
              bottom: '0px',
              width: '100%',
            }}
          >
            <IonLabel>Toggle Dark Mode</IonLabel>
            <IonToggle
              color="dark"
              checked={
                auth.user &&
                auth.user.preferences &&
                auth.user.preferences.theme === 'dark'
              }
              onIonChange={(e) => toggleDarkMode(e)}
            />
          </IonItem> */}
          <p
            style={{
              position: 'absolute',
              bottom: '0px',
              width: '100%',
              textAlign: 'center',
              color: 'grey',
            }}
          >
            {`Version ${ROO_VERSION_NUMBER}${USE_LIVE_STRIPE ? '' : ' (testing)'}`}
          </p>
        </IonContent>
        <DelivererSetupModal
          showModal={showCreateDeliveryAccountModal}
          setShowModal={setShowCreateDeliveryAccountModal}
        />
      </IonMenu>
    </div>
  ) : (
    <Spinner />
  );
};

LeftMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  updateTheme: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  setShowPromoModal: PropTypes.func.isRequired,
  setShowBugReportModal: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
  setShowTutorialModal: PropTypes.func.isRequired,
  setShowMyAddressesModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  navigation: state.navigation,
  USE_LIVE_STRIPE : state.USE_LIVE_STRIPE
});

export default connect(mapStateToProps, {
  logout,
  updateTheme,
  loadUser,
  setShowPromoModal,
  setShowBugReportModal,
  setShowTutorialModal,
  setShowMyAddressesModal,
})(LeftMenu);
