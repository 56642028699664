import axios from "axios";
import { backendAddress } from "../../App";
import { launchToastCard } from "../layout/toast";
import { UPDATE_ROOQUEST_STATUS, ROOQUEST_ERROR } from "./rooquest-types";

export const collectItem = (data) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const res = await axios.put(
        backendAddress + '/api/rq/item/collect', JSON.stringify(data), config
      );
  
      if (res && res.data && res.data.status === 'success') {
        dispatch({
            type: UPDATE_ROOQUEST_STATUS,
            payload: res.data.rooquest,
        });
      }
      return res.data;
    } catch (err) {
      console.error(err);
      dispatch(handleErrors(err, 'There was an error making your delivery offer.'));
    }
  };

  export const uncollectItem = (data) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const res = await axios.put(
        backendAddress + '/api/rq/item/uncollect', JSON.stringify(data), config
      );
  
      if (res && res.data && res.data.status === 'success') {
        dispatch({
            type: UPDATE_ROOQUEST_STATUS,
            payload: res.data.rooquest,
        });
      }
      return res.data;
    } catch (err) {
      console.error(err);
      dispatch(handleErrors(err, 'There was an error making your delivery offer.'));
    }
  };

  export const sendCollectItemNotification = async (data) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.put(
        backendAddress + '/api/rq/item/sendCollectItemNotification', JSON.stringify(data), config
      );
    } catch (err) {
      console.error(err);
    }

  }

  

/////////////////////////////////////////////////////////////////////

  const handleErrors = (err, msg) => (dispatch) => {
    if (err.response && err.response.data) {
        if (err.response.data.status === 'error' && err.response.data.errors.length > 0) {
            if (err.response.data.rooquest) {
                dispatch({
                    type: UPDATE_ROOQUEST_STATUS,
                    payload: err.response.data.rooquest,
                });
            }

            dispatch(launchToastCard({ 
                msg: err.response.data.errors[0].msg,
                type: 'error',
                duration: 8500,
            })) 
            return err.response.data;
        }
        else {
          dispatch(launchToastCard({ 
            msg: msg,
            type: 'error',
            duration: 8500,
         }))
         return { status: 'error'};
        }
      }
      dispatch({
        type: ROOQUEST_ERROR,
        payload: err,
      });
  }