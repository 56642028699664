import React, { useEffect, useRef, Fragment,useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { regularMapColor, darkModeMapColor } from './MapStyles';
import { IonIcon, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import { locateOutline } from 'ionicons/icons';

import './FeedMap.scss';
import { setPanToLocation } from '../../../actions/layout/map';
import { launchToast,launchToastCard } from '../../../actions/layout/toast';
import RQInfoModal from '../../layout/RQCard/Feed/RQInfoModal/RQInfoModal';
import { updateRooQuestWithoutCall } from '../../../actions/rooquest/rooquest';
import { setShowInfoModal } from '../../../actions/layout/navigation';
import { getDriverLocation } from '../../../actions/rooquest/rooquest';
// import socketIOClient from "socket.io-client";
import { socket } from '../../../App';
import { Geolocation } from '@capacitor/geolocation';  
import AskLocationModal from './AskLocationModal';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import {setIsLoading} from '../../../actions/loading/loading';
import { updateUserLocation } from '../../../actions/notificationrange/notificationrange';

const FeedMap = ({
    rooquest,
    auth,
    showZoom = true,
    state,
    setPanToLocation,
    mapState,
    launchToast,
    launchToastCard,
    updateRooQuestWithoutCall,
    setShowInfoModal,
    requestData,
    getDriverLocation, 
    sendDataToParent,
    dataParentToChild,
    setIsLoading,
    position = null,
    setPosition=null,
    updateUserLocation
}) => {
    let feedGoogleMapRef = useRef(null);
    const panButton = useRef(null);
    const firstRender = useRef(true);
    const currentPositionMarker = useRef([]);
    const [time, setTime] = useState(null);
    const [distance, setDistance] = useState(null);
    // Map
    let map = useRef(null);
    let markerArray = useRef([]);
    let routeArray = useRef([]);
    // Default Map Center (Moncton)
    let mapCenter = (state === 'trackDelivery' && requestData && requestData.pickupLocation) ? requestData.pickupLocation : {
        lat: 46.09454,
        lng: -64.7965,
    };

    const [askForLocationModal, setAskForLocationModal] = useState(false);
    const [hasAsked, setHasAsked] = useState(false);
    const { height, width } = useWindowDimensions();

    // Get Current Location Function
    const getCurrentLocation = async (position) => {
        try {
            let geolocationPosition = {};
            if(position)
            {
                console.log("position has been set")
                mapCenter = {
                    lat: position.lat,
                    lng: position.lng,
                };
                
                await updateUserLocation(mapCenter.lat,mapCenter.lng,geolocationPosition);
            }
            else{
                console.log("recalling get current position")
                
                // Check if we have permission to get the user's location
                const hasPermission = await Geolocation.checkPermissions();
                if (hasPermission.location !== 'granted' && hasPermission.coarseLocation !== 'granted' && !hasAsked) {
                    setAskForLocationModal(true);
                    setHasAsked(true);
                } 
                let coords = await Geolocation.getCurrentPosition({
                    enableHighAccuracy: true,
                });
                console.log(coords)
                geolocationPosition = {
                    coords : coords.coords,
                    timestamp : coords.timestamp
                };
                mapCenter = {
                    lat: coords.coords.latitude,
                    lng: coords.coords.longitude,
                };
                if(firstRender.current)
                {
                    firstRender.current =false;
                }
                console.log(geolocationPosition)
                //update the current position of the user in mongo
                await updateUserLocation(mapCenter.lat,mapCenter.lng,geolocationPosition);
                setPosition(mapCenter)
            }
            // console.log(auth.user._id)
            addMarker(
                null,
                mapCenter,
                require('./my-location-blue.png'),
                true,
                null,
                false
            );
            return true;
        } catch (err) {

            console.log(err);

            let perms = await Geolocation.checkPermissions();
            if (perms.location !== 'granted') { 
                // launchToast('Location Disabled. Please Enable Location', 3000, 'top');
                launchToastCard({ 
                    msg: 'Location Disabled. Please Enable Location',
                    type: 'error',
                    duration: 8500,
                })
                setIsLoading(false);
            }

            if (err.code !== 1) console.error(err);
            return false;
        }
    };

    const getCurrentLocationAndPan = async (position) => {
        if (await getCurrentLocation(position)) {
            map.current.panTo(mapCenter);
            shiftMapPosition();
        }
        setIsLoading(false);
        console.log("pan to location")
    };
    const shiftMapPosition = () => {
       //70 is the height of the navbar and 209 is the height of the order now/deliver now component 
        const midHeight = (height - 70) /2;
        const visibleMidHeight = ((height - 70)-209)/2
        const shiftBy = Math.abs(midHeight - visibleMidHeight)
        console.log(height)
        console.log(midHeight)
        console.log(`shiftBy = ${shiftBy}`)
        map.current.panBy(0,shiftBy)
        
        console.log("panby");
    }

    // Function to get the latest address of the driver from the API and creating the socket connection
    const getdriveraddress = async () => {
        const res = await getDriverLocation(requestData.requestId);
        if (res) {
            console.log('abc', res);
            setTime(res.data.time);
            if(dataParentToChild){
                sendDataToParent(res.data.time);
            }
            
            setDistance(res.data.distance);
            setDriverLocation({ lat: res.data.delivererLocation.lat, lng: res.data.delivererLocation.lng })
            // socket.on("driverlocation", (data) => {
            //     console.log("event received", data);
            //     setDriverLocation({ lat: data.lat, lng: data.lng })
            // });
            console.log("socket", socket);
            // global.driverInterval = setInterval(function(){ 
            //     console.log("setinterval");
            // }, 1000);
            // socket.emit('change color', "White");
        }
    }

    // On First Load, Create Map
    useIonViewDidEnter(async () => {
        // Add Listener to Current Location Pan Button
        if (panButton && panButton.current) {
            panButton.current.addEventListener('click', async () => {
                await getCurrentLocationAndPan(null);
            });
        }

        // const getMapStyle = () => {
        //     let mapStyle = regularMapColor;
        //     if (
        //         auth.user &&
        //         auth.user.color_theme === 'dark'
        //     ) {
        //         mapStyle = darkModeMapColor;
        //     }
        //     return mapStyle;
        // };

        // if (feedGoogleMapRef.current) {
        //     map.current = new window.google.maps.Map(feedGoogleMapRef.current, {
        //         zoom: 13,
        //         maxZoom: 14,
        //         zoomControl: showZoom,
        //         center: mapCenter,
        //         streetViewControl: false,
        //         fullscreenControl: false,
        //         mapTypeControlOptions: {
        //             mapTypeIds: [],
        //         },
        //         styles: getMapStyle(),
        //         clickableIcons: false,
        //     });
        // }

        // if (state !== 'trackDelivery') {
        //     getCurrentLocationAndPan();
        // }
    });

    useEffect(() => {
        const getMapStyle = () => {
            let mapStyle = regularMapColor;
            if (
                auth.user &&
                auth.user.color_theme === 'dark'
            ) {
                mapStyle = darkModeMapColor;
            }
            return mapStyle;
        };

        if (feedGoogleMapRef.current) {
            map.current = new window.google.maps.Map(feedGoogleMapRef.current, {
                zoom: 13,
                maxZoom: 16,
                zoomControl: showZoom,
                center: mapCenter,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControlOptions: {
                    mapTypeIds: [],
                },
                styles: getMapStyle(),
                clickableIcons: false,
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.user]);


    // Pan To A Requested Location
    useEffect(() => {
        if (mapState.panToLocation) {
            map.current.panTo(mapState.panToLocation);
            map.current.setZoom(14);
            setPanToLocation(null);
        }
     
    }, [mapState.panToLocation, setPanToLocation]);

    // Click Event on Marker => Open RooQuest Modal
    const openRooQuest = (rq) => {
        updateRooQuestWithoutCall(rq);
        setShowInfoModal('RQ', true);
    };

    // Add / Remove Markers To Map


    const addMarker = (
        rq,
        position,
        icon,
        currentPosition = false,
        label = null,
        animation = false,
        title = null,
    ) => {
        const zIndex = currentPosition ? 1000 : 500;

        const marker = new google.maps.Marker({
            position: position,
            map: map.current,
            icon: icon,
            animation: animation ? google.maps.Animation.DROP : null,
            label: label,
            optimized: false,
            zIndex: zIndex,
            title: title,
        });

        if (!currentPosition) {
            markerArray.current.push(marker);
        } else {
            console.log(currentPositionMarker.current.length)
            currentPositionMarker.current.forEach((marker) => {
                marker.setMap(null);
                marker = null;
            });
            currentPositionMarker.current = []
            currentPositionMarker.current.push(marker);
        }
    };

    // Remove All Markers on Map
    const removeMarkers = () => {
        markerArray.current.forEach((marker) => marker.setMap(null));
        markerArray.current = [];
    };

    // Display Pickup Locations on Map
    const showPickupLocation = () => {
        rooquest.rooquests.forEach(async (rq) => {
            if (
                rq &&
                rq.route &&
                rq.route.routes &&
                rq.pickupLocationType === 'address'
            ) {
                const leg0 = rq.route.routes[0].legs[0];
                addMarker(rq, leg0.start_location, {
                    url: require('./location-pickup-box.png'),
                    scaledSize: new google.maps.Size(58, 52),
                    anchor: new google.maps.Point(28, 26),
                });
            }
        });
    };

    // Add Route to Map
    const addRoutes = async () => {
        if (state !== 'trackDelivery') {
            rooquest.rooquests.forEach(async (rq) => {
                // Map Route Colour
                const getRandomColour = () => {
                    const colours = [
                        '#0b2e00',
                        '#134f00',
                        '#1d7800',
                        '#2b6e16',
                        '#094712',
                        '#246e2f',
                        '#08871b',
                    ];
                    return colours[Math.floor(Math.random() * colours.length)];
                };
    
                if (rq.pickupLocationType === 'address') {
                    const DirectionsRenderer = new google.maps.DirectionsRenderer({
                        suppressMarkers: true,
                        polylineOptions: {
                            strokeColor: getRandomColour(),
                        },
                    });
                    routeArray.current.push(DirectionsRenderer);
    
                    if (rq.route) {
                        DirectionsRenderer.setDirections(rq.route);
                    }
                    DirectionsRenderer.setMap(map.current);
    
                    if (rq && rq.route && rq.route.routes) {
                        const leg0 = rq.route.routes[0].legs[0];
                        const leg1 = rq.route.routes[0].legs[1];
    
                        addMarker(rq, leg0.start_location, {
                            url: require('./location-pickup-box.png'),
                            scaledSize: new google.maps.Size(58, 52),
                            anchor: new google.maps.Point(28, 26),
                        });
                        // addMarker(rq, leg0.end_location, require('./location-dropoff.png'));
                        addMarker(rq, leg1.end_location, require('./location-dropoff.png'));
                    }
                } else if (rq.pickupLocationType === 'store') {
                    addMarker(
                        rq,
                        { lat: rq.deliveryAddress.lat, lng: rq.deliveryAddress.lng },
                        {
                            url: require('./location-dropoff.png'),
                            labelOrigin: new google.maps.Point(27, -5),
                        },
                        false,
                        rq.pickupLocation && rq.pickupLocation.length < 12
                            ? { text: rq.pickupLocation, fontWeight: '3px' }
                            : 'Store'
                    );
                } else if (rq.pickupLocationType === 'any') {
                    addMarker(
                        rq,
                        { lat: rq.deliveryAddress.lat, lng: rq.deliveryAddress.lng },
                        {
                            url: require('./location-dropoff.png'),
                            labelOrigin: new google.maps.Point(27, -5),
                        },
                        false,
                        'Any Location'
                    );
                }
            });
        }
        // Code added for showing delivery route between driver and client
        if (state === 'trackDelivery') {
            console.log("requestData", requestData);
            let rq = requestData.rooquest;
            console.log("rq", rq);
            let deliveryLocation = rq.deliveryAddress;
            if (rq.pickupLocationType === 'address') {
                // Setup of directions service
                const DirectionsService = new google.maps.DirectionsService();
                // Setup of directions renderer
                const DirectionsRenderer = new google.maps.DirectionsRenderer({
                    suppressMarkers: true,
                    polylineOptions: {
                        strokeColor: '#0b2e00',
                    },
                });
                routeArray.current.push(DirectionsRenderer);
                DirectionsRenderer.setMap(map.current);
                let pickupLocation = rq.pickupAddress;
                if (pickupLocation && pickupLocation.lat && pickupLocation.lng) {
                    // Add the marker for pickup location
                    addMarker(null, pickupLocation, {
                        url: require('./location-pickup-box.png'),
                        scaledSize: new google.maps.Size(58, 52),
                        anchor: new google.maps.Point(28, 26),
                    });
                    if (map.current) {
                        map.current.panTo(pickupLocation);
                    }
                }
                // Add the directions between the pickup and delivery locations
                DirectionsService.route({
                    origin: pickupLocation,
                    destination: deliveryLocation,
                    travelMode: google.maps.TravelMode.DRIVING,
                    optimizeWaypoints: true,
                }, (response, status) => {
                    if (status === 'OK') {
                        DirectionsRenderer.setDirections(response);
                    } else {
                        // Show alert that we could not fetch the directions
                    }
                });
            }
            else if (map.current) {
                map.current.panTo(deliveryLocation);
            }

            // Add the marker for delivery location
            addMarker(null, deliveryLocation, require('./location-dropoff.png'));

        }

    };

    // Remove all Routes on Map
    const removeRoutes = () => {
        routeArray.current.forEach((route) => route.setMap(null));
        routeArray.current = [];
    };

    // Function to remove the previous pin of the driver
    const removeDriverPin = () => {
        for (let i = 0; i < markerArray.current.length; i++) {
            if (markerArray.current[i].title && markerArray.current[i].title === 'driver') {
                markerArray.current[i].setMap(null);
                markerArray.current.splice(i, 1);
                break;
            }
        }
    };

    // Function to set the marker of driver's location and change the map center to it
    const setDriverLocation = (location) => {
        console.log("setDriverLocation", location);
        removeDriverPin();
        addMarker(null, location, {
            url: require('./map-marker.png'),
            scaledSize: new google.maps.Size(35, 49),
            // anchor: new google.maps.Point(28, 26),
        }, false, "", null, "driver");
        if (map.current) {
            map.current.setCenter(location);
        }
    };

    useEffect(()=>{
        console.log('position changed')
        if(!position || (position.lat === 0 && position.lng === 0))
            return;

            try{
                getCurrentLocationAndPan(position);
                firstRender.current = false;
            }
            catch(err)
            {
                console.log(err);
                setIsLoading(false); 
            }
    },[position])

    useEffect(() => {
        switch (state) {
            case 'landing':
                console.log("appel")
                // getCurrentLocation(); 
                // removeRoutes();
                // removeMarkers();
                // showPickupLocation();
                break;
            case 'deliver':
                getCurrentLocation(null);
                removeRoutes();
                removeMarkers(); 
                addRoutes();
                break;
            // case 'trackDelivery':
            //     setTimeout(() => {
            //         // getCurrentLocation();
            //         removeRoutes();
            //         removeMarkers();
            //         addRoutes();
            //         getdriveraddress();
            //     }, 1000);
            //     break;
            default:
                // Do Nothing
                console.log('do nothing');
        }
        // eslint-disable-next-line
    }, [state]);

    return (
        <Fragment>
          
            <div
                ref={feedGoogleMapRef}
                style={{ width: '100%', height: '100%', zIndex: 7 }}
            >
            </div>
            {state === 'trackDelivery' && !!time && !!distance && dataParentToChild != "myrooQuestdelivery" ?
             <div className="arriving">
                <div><p className="arrivingtext">ARRIVING IN: <span  className="arrivetimekm">{time}</span></p></div>
                <div><p className="arrivingtext arrivingtextcenter"><span  className="arrivetimekm">{distance} away</span></p></div>
            </div> : null}
           
            {state !== 'trackDelivery' && <div ref={panButton} id="panButton">
                <div className="panToCurrentLocation">
                    <IonIcon icon={locateOutline}></IonIcon>
                </div>
            </div>}
            {/* <RQInfoModal /> */}
            {/* <AskLocationModal show={askForLocationModal} setShow={setAskForLocationModal} /> */}
        </Fragment>
    );
};

FeedMap.propTypes = {
    rooquests: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    showZoom: PropTypes.bool,
    state: PropTypes.string.isRequired,
    setPanToLocation: PropTypes.func,
    launchToast: PropTypes.func,
    launchToastCard: PropTypes.func,
    updateRooQuestWithoutCall: PropTypes.func.isRequired,
    setShowInfoModal: PropTypes.func.isRequired,
    requestData: PropTypes.object,
    getDriverLocation: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    // auth: state.auth,
    rooquest: state.rooquest,
    mapState: state.map,
});

export default connect(mapStateToProps, {
    setPanToLocation,
    launchToast,
    launchToastCard,
    updateRooQuestWithoutCall,
    setShowInfoModal,
    getDriverLocation,
    setIsLoading,
    updateUserLocation,
})(FeedMap);
