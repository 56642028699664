import { UPDATE_NAVBAR_UNREAD_MESSAGES,UPDATE_NAVBAR_ACTIVE_DELIVERIES,UPDATE_NAVBAR_ACTIVE_ROOQUESTS } from './navbar-types';

export const updateNavbarWithUnreadMessages = (number) => (dispatch) => {
  dispatch({
    type: UPDATE_NAVBAR_UNREAD_MESSAGES,
    payload: number,
  });
};

export const updateNavbarWithActiveDeliveries = (number) => (dispatch) => {
  dispatch({
    type: UPDATE_NAVBAR_ACTIVE_DELIVERIES,
    payload: number,
  });
};

export const updateNavbarWithActiveRooquests = (number) => (dispatch) => {
  dispatch({
    type: UPDATE_NAVBAR_ACTIVE_ROOQUESTS,
    payload: number,
  });
};