//this redux state checks if it is the first time that the app is rendering. If It is the first render state is true.
const isLoading = (state=false,action) => {
    const { type, payload } = action;
    switch(type){
        case 'IS_LOADING':
            return payload;
        default:
            return state;
    }
}

export default isLoading;