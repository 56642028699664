import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonModal
} from '@ionic/react';

import './reviewPopups.scss';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setShowRatingsModal,setShowItemRatingsNotifModal } from '../../../actions/layout/navigation';
import sendRequests from '../../../hooks/sendRequests';
import { updateRooQuestWithoutCall } from '../../../actions/rooquest/rooquest';
import ReactStars from "react-rating-stars-component";
import { useTranslation } from 'react-i18next';
import { launchToastCard } from '../../../actions/layout/toast';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

const ReviewPopup = ({
  setShowRatingsModal,
  showRatingModal,
  setShowItemRatingsNotifModal,
  auth,
  rooquest,
  updateRooQuestWithoutCall,
  launchToastCard
}) => {
  const [rating,setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [initialHeight,setInitialHeight] = useState(window.outerHeight);
  // const [isOpen,setIsOpen] = useState(false)
  const [showFeedback,setShowFeedback] = useState(false)
  const {t} = useTranslation();

  useEffect(()=>{

    if (Capacitor.isNativePlatform()) {
      try{
          
          const keyboardDidShowListener = Keyboard.addListener(
              'keyboardDidShow',
              () => {
                setKeyboardVisible(true); // or some other action
              }
            );
            const keyboardDidHideListener = Keyboard.addListener(
              'keyboardDidHide',
              () => {
                setKeyboardVisible(false); // or some other action
              }
            );
      }
      catch(err)
      {
          console.log(err)
      }
  }
  },[])

  const closeRatingModal = () => {
    setShowItemRatingsNotifModal(false);
    setShowRatingsModal(false, null);
  };

  const submitReview = async () => {
    const type_of_request = showRatingModal.type;

    if (type_of_request === 'DELIVERER_REVIEW') {
      let rooquests = rooquest.rooquest;

      const rooquestId = rooquests._id;
      const delivererId = rooquests.deliverer;
      const requester = rooquests.requester;

      const { doRequest } = sendRequests({
        url: '/api/reviews/',
        method: 'post',
        body: {
          userReviewedId: delivererId,
          reviewerId: requester,
          comment: feedback,
          numberOfStars: rating,
          TypeOfReview: type_of_request,
          rooquestId: rooquestId,
        },
        onSuccess: (data) => {
        launchToastCard({ 
          msg: 'Review has been sent successfully.',
          type: 'success',
          duration: 8500,
      });
      if (data && data.data.rq) {
        updateRooQuestWithoutCall(data.data.rq);
        }
      }
      });
      await doRequest();
    } else {
      let rooquests = rooquest.rooquest;

      const rooquestId = rooquests._id;
      const delivererId = rooquests.deliverer;
      const requester = rooquests.requester;
      const { doRequest } = sendRequests({
        url: '/api/reviews/',
        method: 'post',
        body: {
          userReviewedId: requester,
          reviewerId: delivererId,
          comment: feedback,
          numberOfStars: rating,
          TypeOfReview: type_of_request,
          rooquestId: rooquestId,
        },
        onSuccess: (data) => {
          launchToastCard({ 
            msg: 'Review has been sent successfully.',
            type: 'success',
            duration: 8500,
           });
          if (data && data.data.rq) {
            updateRooQuestWithoutCall(data.data.rq);
          }
          
        },
      });
      await doRequest();
    }
    setFeedback('');
    setShowRatingsModal(false, null);
    setShowItemRatingsNotifModal(false);
  };

  const closeModal = () => {
    // setIsOpen(false)
    setShowRatingsModal(false, null);
    setShowItemRatingsNotifModal(false);
  }

  const ratingChanged = (newRating) => {
    setShowFeedback(true)
    setRating(newRating);
    console.log(newRating);
  };
  return (
    <IonModal
      mode="ios"
      isOpen={showRatingModal.visible}
      class="reviewModal"
      showBackdrop={true}
      backdropDismiss={true}
      onWillDismiss={() => closeRatingModal()}
    >

<div className="modal fade" id="form">
    <div className="modal-dialog modal-dialog-centered">
        <div className={`modal-content`}>
            <div className="cross" onClick={closeModal}> X </div>
            <div className={`card-body text-center  ${isKeyboardVisible && 'visible'}`}> <img src=" https://i.imgur.com/d2dKtI7.png" />
                <div className="comment-box text-center">
                <h4>{t('How was your journey ?')}</h4>
                <div className="rating"> 
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={35}
                  activeColor="#ffd700"
                />
                </div>
                <div className="comment-area"> 
                  <textarea className="form-control" placeholder={t('Add a comment')} rows={3} value={feedback} onChange={(e) => setFeedback(e.target.value)} />
                </div>
                    
                <div className="text-center mt-4 send-button"> <IonButton  color="kargoroo_green" expand="block" className="ion-margin-top" onClick={submitReview} >{t('Submit')} &rarr;</IonButton>
            </div>
            </div>
        </div>
    </div>
</div>
</div>

        {/* <div className="card">
        <div className="card-header">
          <p className="title">{t('How was your journey ?')}</p>
        </div>

        <div className="card-body">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={35}
            activeColor="#ffd700"
          />
          {showFeedback && <div className="inputSection reason-note">
                                <div className="question">
                                    <p>{t("What's your feedback")}:</p>
                                </div>
                                <textarea placeholder={t('Add a comment')} rows={3} value={feedback} onChange={(e) => setFeedback(e.target.value)} />
                            </div>}

            <button onClick={submitReview}>{t('Submit')}</button>
        </div>
      </div> */}
        
    </IonModal>
  );
};

ReviewPopup.propTypes = {
  showRatingModal: PropTypes.object.isRequired,
  setShowRatingsModal: PropTypes.func.isRequired,
  setShowItemRatingsNotifModal:PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  rooquest: PropTypes.object.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  launchToastCard: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  showRatingModal: state.navigation.showRatingModal,
  rooquest: state.rooquest,
  auth: state.auth,
  showReviewModal : state.navigation.showReviewModal
});

export default connect(mapStateToProps, {
  setShowRatingsModal,
  setShowItemRatingsNotifModal,
  updateRooQuestWithoutCall,
  launchToastCard
})(ReviewPopup);