const getStatusTextAndColour = (status, isRequester, page = 'feed',mode) => {
  switch (status) {
    case 'ACTIVE':
      return {
        text: 'Awaiting Offers',
        colour: {
          color: (mode == 'dark') ? '#00bd00':'#056100',
        },
        borderStyle: {
          borderRight: (mode == 'dark') ?  '4.5px solid #00bd00': '4.5px solid #056100' ,
        },
      };
    case 'RQ_ACCEPTED':
      if (page === 'myrooquests') {
        return {
          text: 'Accepted',
          colour: {
            color: '#00a8e6',
          },
          borderStyle: {
            borderRight: '4.5px solid #00a8e6',
          },
        };
      } else if (page === 'mydeliveries') {
        return {
          text: 'Deliver Now',
          colour: {
            color: '#00a8e6',
          },
          borderStyle: {
            borderRight: '4.5px solid #00a8e6',
          },
        };
      } else {
        break;
      }

    case 'RQ_ONTHEWAY':
      return {
        text: 'On The Way',
        colour: {
          color: (mode == 'dark') ? '#4297FF' : '#004dab',
        },
        borderStyle: {
          borderRight: '4.5px solid #004dab',
        },
      };

    case 'ROOQUEST_DELIVERED':
      return {
        text: 'Delivered',
        colour: {
          color: (mode == 'dark') ? '#00bd00':'#056100',
        },
        borderStyle: {
          borderRight: (mode == 'dark') ?  '4.5px solid #00bd00': '4.5px solid #056100',
        },
      };

    case 'PENDING_PAYMENT':
      return {
        text: 'Payment Required',
        colour: {
          color: '#d41e1e',
        },
        borderStyle: {
          borderRight: '4.5px solid #d41e1e',
        },
      };
      case 'ROOQUEST_WAITING_CLIENT_CONFIRMATION':
        return {
          text: 'Waiting Client Approval',
          colour: {
            color: '#008B8B',
          },
          borderStyle: {
            borderRight: '4.5px solid #008B8B',
          },
        };
      case 'UNDER_REVIEW':
          return {
            text: 'Under Review',
            colour: {
              color: '#8A2BE2',
            },
            borderStyle: {
              borderRight: '4.5px solid #8A2BE2',
            },
          };
      case 'EXPIRED':
            return {
              text: 'Rooquest Expired',
              colour: {
                color: '#FF0000',
              },
              borderStyle: {
                borderRight: '4.5px solid #FF0000',
              },
            };
    default:
      return {
        text: 'Error',
        colour: {
          color: (mode == 'dark') ? '#00bd00':'#056100',
        },
        borderStyle: {
          borderRight: '4.5px solid #048800',
        },
      };
  }
};

export default getStatusTextAndColour;
