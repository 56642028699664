import React, { useState, useEffect } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonPage } from '@ionic/react';
import './deliverNavMap.scss';
import FeedMap from '../feed/map/FeedMap';
import {getDriverLocation } from '../../actions/rooquest/rooquest';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory,useParams } from 'react-router-dom';
const DeliverNavMap = ({
    auth,
    getDriverLocation
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [mapState, setMapState] = useState('trackDelivery');
    // const [marker, setMarker] = useState(null);
    let mapid = {
        id:''
    }
     mapid = useParams();
     let history = useHistory();

    useEffect(() => {
        //   getDriverLocation(mapid.id).then(res => {
        //     if (res) {
        //         console.log(res)
        //         setMarker({
        //             pickupLat:res.data.pickupLocation.lat,
        //             pickupLng:res.data.pickupLocation.lng,
        //             delLat:res.data.deliveryLocation.lat,
        //             delLng:res.data.deliveryLocation.lng
        //         })
        //     }
        // })
      }, []);

    return (
        <IonPage className="deliverNavMap">
            <IonContent forceOverscroll={true}>
                <IonHeader className="ion-no-border GreenHeader">
                    <IonToolbar className="new-background-color">
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/" text="" />
                        </IonButtons>
                        <IonTitle>Delivery Tracking</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {/* <FeedMap
                    rooquests={[]}
                    showZoom={false}
                    state={mapState}
                    requestData={{ rooquest: (history && history.location && history.location.state && history.location.state['rooquest']) ? history.location.state['rooquest']: {}, requestId: mapid.id }}
                    sendDataToParent={null}
                    dataParentToChild = {null}
                /> */}
            </IonContent>
        </IonPage> 

    )
}

DeliverNavMap.propTypes = {
    getDriverLocation: PropTypes.func.isRequired
}
const mapStateToProps = (state) => ({
    auth: state.auth,
  });
export default connect (mapStateToProps, {
getDriverLocation
})(DeliverNavMap);