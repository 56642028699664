export const ADD_NEW_DELIVERY_ADDRESS = 'ADD_NEW_DELIVERY_ADDRESS';
export const DELETE_DELIVERY_ADDRESS = 'DELETE_DELIVERY_ADDRESS';

export const UPDATE_THEME = 'UPDATE_THEME';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_UNIT_METHOD = 'UPDATE_UNIT_METHOD';
export const UPDATE_THEMES = 'UPDATE_THEMES';
export const UPDATE_REQ_EMAIL_NOTIFICATION_METHOD = 'UPDATE_REQ_EMAIL_NOTIFICATION_METHOD';
export const UPDATE_DEL_EMAIL_NOTIFICATION_METHOD = 'UPDATE_DEL_EMAIL_NOTIFICATION_METHOD';
export const UPDATE_REQ_NOTIFICATION_METHOD = 'UPDATE_REQ_NOTIFICATION_METHOD';
export const UPDATE_DEL_NOTIFICATION_METHOD = 'UPDATE_DEL_NOTIFICATION_METHOD';
export const UPDATE_NEW_REQ_NOTIFICATION_METHOD = 'UPDATE_NEW_REQ_NOTIFICATION_METHOD';
export const UPDATE_USER_MODE = 'UPDATE_USER_MODE';