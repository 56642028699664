import React, { Fragment, useRef,useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IonButton, IonModal } from '@ionic/react';
import { setShowLocationOnMapModal } from '../../../actions/layout/navigation';
import RQInfoModal from '../RQCard/Feed/RQInfoModal/RQInfoModal';
import './LocationMapModal.scss';
import { regularMapColor, darkModeMapColor } from '../../feed/map/MapStyles';
import { isBusiness } from '../../PostRQForm/components/AutoCompleteWithMap';
import { launchToastCard } from "../../../actions/layout/toast";
import { Clipboard } from '@capacitor/clipboard';
import { useTranslation } from 'react-i18next';
import { findMidPoint, fitCirclesBounds } from '../../../utils/mapFunctions';

const LocationMapModal = ({
  auth,
  setShowLocationOnMapModal,
  showLocationOnMapModal,
  rooquest,
  launchToastCard
}) => {
  const closeModal = () => {
    zoomOutLocationPins.current = [];
    setShowLocationOnMapModal(false, null);
  };
  const { t } = useTranslation();
  const firstCircleRef = useRef(null);
  const secondCircleRef = useRef(null);

  // Default Values
  let mapCenter = {
    lat: 46.09454,
    lng: -64.7965,
  };

  // Refs / States
  let currentLocationMap = useRef(null);
  let map = useRef(null);
  let zoomOutLocationPins = useRef([]);

  const writeToPickupDataClipboard = async (address) => {
    let pickupdata;
    if (address && address.pickupLocationType === 'address' && showLocationOnMapModal.showFullAddress) {
      pickupdata = `${address.pickupAddress.streetAddress}, ${address.pickupAddress.city}, ${address.pickupAddress.postal}, ${address.pickupAddress.province}, ${address.pickupAddress.country}`
    } else if (address && address.pickupLocationType === 'address' && !showLocationOnMapModal.showFullAddress) {
      pickupdata = `${address.pickupAddress.city}, ${address.pickupAddress.province}, ${address.pickupAddress.country}`
    } else if (address && address.pickupLocationType === 'any'){
      pickupdata = `Any Location Containing Item`
    } else if (address && (address.pickupLocationType === 'store' || address.pickupLocationType === 'other')) {
      pickupdata = `${address.pickupLocation}`
    } else {
      pickupdata = ""
    }
    // await Clipboard.write({
    //   string: pickupdata
    // });

    // launchToastCard({ 
    //     msg: `${t('Text has been copied to clipboard')}`,
    //     type: 'info',
    //     duration: 8500,
    //  })
  };
  const writeToDeliverDataClipboard= async (address) => {
    let deliverdata;
    if (address && address.deliveryAddress && address.deliveryAddress.streetAddress !== 'Undefined' && showLocationOnMapModal.showFullAddress) {
        deliverdata = `${address.deliveryAddress.streetAddress}, ${address.deliveryAddress.city}, ${address.deliveryAddress.postal}, ${address.deliveryAddress.province}, ${address.deliveryAddress.country}`
    } else if (address && address.deliveryAddress && address.deliveryAddress.streetAddress !== 'Undefined' && !showLocationOnMapModal.showFullAddress) {
      deliverdata = `${address.deliveryAddress.city}, ${address.deliveryAddress.province}, ${address.deliveryAddress.country}`
    } else if (address && address.deliveryAddress && address.deliveryAddress.streetAddress === 'Undefined' && !showLocationOnMapModal.showFullAddress){
      deliverdata = `Specific Coordinates`
    } else if (address && address.deliveryAddress && address.deliveryAddress.streetAddress === 'Undefined' && showLocationOnMapModal.showFullAddress){
      deliverdata = `${parseFloat(rooquest.deliveryAddress.lat).toFixed(4)}, ${parseFloat(rooquest.deliveryAddress.lng).toFixed(4)}`
    } else {
      deliverdata = ""
    }
 
    // await Clipboard.write({
    //   string: deliverdata
    // });

    // launchToastCard({ 
    //     msg: `${t('Text has been copied to clipboard')}`,
    //     type: 'info',
    //     duration: 8500,
    //  })
  };
  const getMapStyle = () => {
      let mapStyle = regularMapColor;
      if (
          auth.user &&
          auth.user.color_theme === 'dark'
      ) {
          mapStyle = darkModeMapColor;
      }
      return mapStyle;
  };
  // On First Load, Create Map && Init
  const initMap = async () => {
    
    // Init
    if (currentLocationMap.current) {
      map.current = new window.google.maps.Map(currentLocationMap.current, {
        zoom: 15,
        center: mapCenter,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControlOptions: {
          mapTypeIds: [],
        },
        styles:getMapStyle(),
        clickableIcons: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER,
        },
      });
      // google.maps.event.addListener(map.current, 'zoom_changed', () => {
      //   // console.log('HERE!', zoomOutLocationPins.current);
      //   if (
      //     map.current.getZoom() <= 13 &&
      //     !showLocationOnMapModal.showFullAddress
      //   ) {
      //     if (zoomOutLocationPins.current.length === 0) {
      //       if (showLocationOnMapModal.pickupLocation) {
      //         const marker = new google.maps.Marker({
      //           position: {
      //             lat: showLocationOnMapModal.pickupLocation.lat,
      //             lng: showLocationOnMapModal.pickupLocation.lng,
      //           },
      //           map: map.current,
      //           animation: google.maps.Animation.DROP,
      //           optimized: false,
      //           zIndex: 50,
      //           icon: {
      //             url: require('./location-pickup-box.png'),
      //             scaledSize: new google.maps.Size(58, 52),
      //             anchor: new google.maps.Point(28, 26),
      //           },
      //         });
      //         zoomOutLocationPins.current.push(marker);
      //       }
      //       if (showLocationOnMapModal.deliveryLocation) {
      //         const marker = new google.maps.Marker({
      //           position: {
      //             lat: showLocationOnMapModal.deliveryLocation.lat,
      //             lng: showLocationOnMapModal.deliveryLocation.lng,
      //           },
      //           map: map.current,
      //           animation: google.maps.Animation.DROP,
      //           optimized: false,
      //           zIndex: 50,
      //           icon: require('./location-dropoff.png'),
      //         });
      //         zoomOutLocationPins.current.push(marker);
      //       }
      //     } else {
      //       zoomOutLocationPins.current.forEach((marker) =>
      //         marker.setVisible(true)
      //       );
      //     }
      //   } else {
      //     zoomOutLocationPins.current.forEach((marker) =>
      //       marker.setVisible(false)
      //     );
      //   }
      // });
    }

    if (showLocationOnMapModal.deliveryLocation) {
      mapCenter = {
        lat: showLocationOnMapModal.deliveryLocation.lat,
        lng: showLocationOnMapModal.deliveryLocation.lng,
      };

      if (showLocationOnMapModal.showFullAddress) {
        new google.maps.Marker({
          position: mapCenter,
          map: map.current,
          animation: google.maps.Animation.DROP,
          optimized: false,
          zIndex: 50,
          icon: require('./location-dropoff.png'),
        });
        map.current.panTo(mapCenter);
      } else {
        const getRandomOffset = () => {
          let random = Math.random();
          random *= Math.floor(Math.random() * 2) === 1 ? 1 : -1;
          return random / 1200;
        };

        map.current.set('maxZoom', 15);
        firstCircleRef.current = new google.maps.Circle({
          map: map.current,
          center: {
            lat: mapCenter.lat + getRandomOffset(),
            lng: mapCenter.lng + getRandomOffset(),
          },
          radius: 300,
          fillColor: '#df2323',
          strokeColor: '#8d0303',
        });
      }
    }

    if (showLocationOnMapModal.pickupLocation) {
      // console.log('SHOW FULL:', showLocationOnMapModal.pickupLocation);
      mapCenter = {
        lat: showLocationOnMapModal.pickupLocation.lat,
        lng: showLocationOnMapModal.pickupLocation.lng,
      };

      if (showLocationOnMapModal.showFullAddress) {
        new google.maps.Marker({
          position: mapCenter,
          map: map.current,
          animation: google.maps.Animation.DROP,
          optimized: false,
          zIndex: 50,
          icon: {
            url: require('./location-pickup-box.png'),
            scaledSize: new google.maps.Size(58, 52),
            anchor: new google.maps.Point(28, 26),
          },
        });
        map.current.panTo(mapCenter);
      } else {
        const getRandomOffset = () => {
          let random = Math.random();
          random *= Math.floor(Math.random() * 2) === 1 ? 1 : -1;
          return random / 1200;
        };

        map.current.set('maxZoom', 15);
        secondCircleRef.current = new google.maps.Circle({
          map: map.current,
          center: {
            lat: mapCenter.lat + getRandomOffset(),
            lng: mapCenter.lng + getRandomOffset(),
          },
          radius: 300,
          fillColor: '#0592e4',
          strokeColor: '#004094',
        });

        mapCenter = findMidPoint(showLocationOnMapModal.pickupLocation,showLocationOnMapModal.deliveryLocation)

        console.log(`mapCenter = ${mapCenter}`)
      }
    }
 //  black line route outline on map between point A and B 
  //   const DirectionsService = new google.maps.DirectionsService();
  //   const DirectionsRenderer = new google.maps.DirectionsRenderer({
  //     suppressMarkers: true,
  //     polylineOptions: {
  //         strokeColor: '#0b2e00',
  //     },
  // });
  // DirectionsRenderer.setMap(map.current);
  //   // Add the directions between the pickup and delivery locations
  //   DirectionsService.route({
  //     origin: showLocationOnMapModal.pickupLocation,
  //     destination: showLocationOnMapModal.deliveryLocation,
  //     travelMode: google.maps.TravelMode.DRIVING,
  //     optimizeWaypoints: true,
  // }, (response, status) => {
  //     if (status === 'OK') {
  //         DirectionsRenderer.setDirections(response);
  //     } else {
  //         // Show alert that we could not fetch the directions
  //     }
  // });
  };

  return (
    <IonModal
      mode="ios"
      isOpen={showLocationOnMapModal.visible}
      class="currentLocationModal"
      showBackdrop={true}
      onWillDismiss={closeModal}
      onDidPresent={async () => {
        await initMap();
        map.current.panTo(mapCenter);
        const bounds = fitCirclesBounds(firstCircleRef.current.getBounds(),secondCircleRef.current.getBounds());
        map.current.fitBounds(bounds)
      }}
      // @ts-ignore
    >
      <Fragment>
        <div className="deliveryInfoContainer">
          <div
            className="pickupLocationInfoContainer"
            onClick={() => {
              if (
                showLocationOnMapModal.pickupLocation &&
                showLocationOnMapModal.pickupLocation.lat &&
                showLocationOnMapModal.pickupLocation.lng
              ) {
                map.current.panTo({
                  lat: showLocationOnMapModal.pickupLocation.lat,
                  lng: showLocationOnMapModal.pickupLocation.lng,
                });
              }
            }}
          >
            <h5>{t("Pickup")}:</h5>
            <p className='addresscursor' onClick={() => writeToPickupDataClipboard(rooquest)}>
              {rooquest &&
                rooquest.pickupLocationType === 'address' &&
                showLocationOnMapModal.showFullAddress &&
                `${rooquest.pickupAddress.formatted_address}`}
              {rooquest &&
                rooquest.pickupLocationType === 'address' &&
                !showLocationOnMapModal.showFullAddress && (rooquest.pickupAddress.city === "" ? `${rooquest.pickupAddress.formatted_address}`:
                `${rooquest.pickupAddress.city}, ${rooquest.pickupAddress.province}, ${rooquest.pickupAddress.country}`)}
              {rooquest &&
                rooquest.pickupLocationType === 'any' &&
                `Any Location Containing Item`}
              {rooquest &&
                (rooquest.pickupLocationType === 'store' ||
                  rooquest.pickupLocationType === 'other') &&
                `${rooquest.pickupLocation}`}
            </p>
          </div>
          {showLocationOnMapModal.deliveryLocation && (
            <div
              className="deliveryLocationInfoContainer"
              onClick={() => {
                if (
                  showLocationOnMapModal.deliveryLocation &&
                  showLocationOnMapModal.deliveryLocation.lat &&
                  showLocationOnMapModal.deliveryLocation.lng
                ) {
                  map.current.panTo({
                    lat: showLocationOnMapModal.deliveryLocation.lat,
                    lng: showLocationOnMapModal.deliveryLocation.lng,
                  });
                }
              }}
            >
              <h5>{t("Deliver To")}:</h5>
              <p className='addresscursor' onClick={() => writeToDeliverDataClipboard(rooquest)}>
                {rooquest &&
                  rooquest.deliveryAddress &&
                  rooquest.deliveryAddress.streetAddress !== 'Undefined' &&
                  showLocationOnMapModal.showFullAddress &&
                  `${rooquest.deliveryAddress.formatted_address}`}                
                  {rooquest &&
                  rooquest.deliveryAddress &&
                  rooquest.deliveryAddress.streetAddress !== 'Undefined' &&
                  !showLocationOnMapModal.showFullAddress &&
                  rooquest.deliveryAddress.city === "" ? `${rooquest.deliveryAddress.formatted_address}`: `${rooquest.deliveryAddress.city}, ${rooquest.deliveryAddress.province}, ${rooquest.deliveryAddress.country}`}
                {rooquest &&
                  rooquest.deliveryAddress &&
                  rooquest.deliveryAddress.streetAddress === 'Undefined' &&
                  !showLocationOnMapModal.showFullAddress &&
                  `Specific Coordinates`}
                {rooquest &&
                  rooquest.deliveryAddress &&
                  rooquest.deliveryAddress.streetAddress === 'Undefined' &&
                  showLocationOnMapModal.showFullAddress &&
                  `${parseFloat(rooquest.deliveryAddress.lat).toFixed(
                    4
                  )}, ${parseFloat(rooquest.deliveryAddress.lng).toFixed(4)}`}
              </p>
            </div>
          )}
        </div>
        <div
          ref={currentLocationMap}
          style={{ width: '100%', height: '100%', zIndex: 7 }}
        ></div>
        <div className="actionButtons">
          <div className="row">
            <IonButton onClick={() => closeModal()}>{t("Close")}</IonButton>
          </div>
        </div>
        {/* <RQInfoModal /> */}
      </Fragment>
    </IonModal>
  );
};

LocationMapModal.propTypes = {
  showLocationOnMapModal: PropTypes.object.isRequired,
  setShowLocationOnMapModal: PropTypes.func.isRequired,
  rooquest: PropTypes.object,
};

const mapStateToProps = (state) => ({
  rooquest: state.rooquest.rooquest,
  showLocationOnMapModal: state.navigation.showLocationOnMapModal,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setShowLocationOnMapModal,launchToastCard
})(LocationMapModal);
