import React, { useState,useRef } from 'react';
import {
  IonModal,
  IonIcon,
  IonFabButton,
  useIonViewDidEnter,
  IonToggle,
  IonList,
  IonItem,
  IonLabel,
  IonSlides,
  IonSlide,
} from '@ionic/react';
import GreenBackButton from '../../../GreenBackButton/GreenBackButton';
import PropTypes from 'prop-types';

import './MyRQInfoModal.scss';
import {
  chatbubbleEllipsesOutline,
  pencil,
  arrowForwardCircleOutline,
  cardOutline,
  receiptOutline,
  pencilOutline,
  map
} from 'ionicons/icons';
import { connect } from 'react-redux';
import { setPanToLocation } from '../../../../../actions/layout/map';
import {
  deliverRooQuest,
  updateRooQuestWithoutCall,
  hideRooQuest,
  getMyRooQuests
} from '../../../../../actions/rooquest/rooquest';
import {
  getConversation,
  setShowChatModal,
} from '../../../../../actions/chat/chat';
import RouteInfoVertical from '../RouteInfo/RouteInfoVertical';

import RQPaymentModal from '../../../../payment/RQPaymentModal';
import { Elements } from '@stripe/react-stripe-js';
import { getStripePromise } from '../../../../../utils/initStripe';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { useHistory } from 'react-router-dom';
import {
  setShowInfoModal,
  setShowRatingsModal,
  setShowEditRQModal,
  setShowLocationOnMapModal,
} from '../../../../../actions/layout/navigation';
import getStatusTextAndColour from '../../../../../utils/getStatusTextAndColour';
import { USE_GPS_TRACKING} from '../../../../../App';
import { useTranslation } from 'react-i18next';
import {
   setShowEnlargeImageModal
} from '../../../../../actions/layout/navigation';

const MyRQInfoModal = ({
  auth,
  getConversation,
  setShowChatModal,
  navigation,
  setShowInfoModal,
  rooquest,
  updateRooQuestWithoutCall,
  setShowRatingsModal,
  setShowEditRQModal,
  setShowLocationOnMapModal,
  hideRooQuest,
  getMyRooQuests,
  setShowEnlargeImageModal,
  USE_LIVE_STRIPE
}) => {
  const { t } = useTranslation();
  const { showMyRQModal } = navigation;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checked, setChecked] = useState(false);
  const slideOpts = {
    on: {
      beforeInit() {
        const swiper = this;
        swiper.classNames.push(`${swiper.params.containerModifierClass}flip`);
        swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);
        const overwriteParams = {
          slidesPerView: 1,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
          watchSlidesProgress: true,
          spaceBetween: 0,
          virtualTranslate: true,
        };
        swiper.params = Object.assign(swiper.params, overwriteParams);
        swiper.originalParams = Object.assign(swiper.originalParams, overwriteParams);
      },
      setTranslate() {
        const swiper = this;
        const { slides, rtlTranslate: rtl } = swiper;
        for (let i = 0; i < slides.length; i += 1) {
          const $slideEl = slides.eq(i);
          let progress = $slideEl[0].progress;
          if (swiper.params.flipEffect.limitRotation) {
            progress = Math.max(Math.min($slideEl[0].progress, 1), -1);
          }
          const offset$$1 = $slideEl[0].swiperSlideOffset;
          const rotate = -180 * progress;
          let rotateY = rotate;
          let rotateX = 0;
          let tx = -offset$$1;
          let ty = 0;
          if (!swiper.isHorizontal()) {
            ty = tx;
            tx = 0;
            rotateX = -rotateY;
            rotateY = 0;
          } else if (rtl) {
            rotateY = -rotateY;
          }
  
           $slideEl[0].style.zIndex = -Math.abs(Math.round(progress)) + slides.length;
  
           if (swiper.params.flipEffect.slideShadows) {
            // Set shadows
            let shadowBefore = swiper.isHorizontal() ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
            let shadowAfter = swiper.isHorizontal() ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom');
            if (shadowBefore.length === 0) {
              shadowBefore = swiper.$(`<div class="swiper-slide-shadow-${swiper.isHorizontal() ? 'left' : 'top'}"></div>`);
              $slideEl.append(shadowBefore);
            }
            if (shadowAfter.length === 0) {
              shadowAfter = swiper.$(`<div class="swiper-slide-shadow-${swiper.isHorizontal() ? 'right' : 'bottom'}"></div>`);
              $slideEl.append(shadowAfter);
            }
            if (shadowBefore.length) shadowBefore[0].style.opacity = Math.max(-progress, 0);
            if (shadowAfter.length) shadowAfter[0].style.opacity = Math.max(progress, 0);
          }
          $slideEl
            .transform(`translate3d(${tx}px, ${ty}px, 0px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`);
        }
      },
      setTransition(duration) {
        const swiper = this;
        const { slides, activeIndex, $wrapperEl } = swiper;
        slides
          .transition(duration)
          .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
          .transition(duration);
        if (swiper.params.virtualTranslate && duration !== 0) {
          let eventTriggered = false;
          // eslint-disable-next-line
          slides.eq(activeIndex).transitionEnd(function onTransitionEnd() {
            if (eventTriggered) return;
            if (!swiper || swiper.destroyed) return;
  
            eventTriggered = true;
            swiper.animating = false;
            const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
            for (let i = 0; i < triggerEvents.length; i += 1) {
              $wrapperEl.trigger(triggerEvents[i]);
            }
          });
        }
      }
    }
  };
  const slidesRef = useRef(null);
  const history = useHistory();
  const goToSingleItemquest = () => {
    setShowInfoModal('MYRQ', false)
    history.push('./singleitemquest/edit');
  }
  const goToTrackDeliver = (id, rq) => {
    setShowInfoModal('MYRQ', false)
    history.push(`/deliverNavMap/${id}`, {rooquest: rq})
  }
  let rq = rooquest.rooquest;
  const openChat = async () => {
    let images = rq.imgURLS;
    let rqImage = (images.length > 0) ? images[0] : null;
    const res = await getConversation(
      rq._id,
      rq.title,
      rqImage,
      rq.requester,
      rq.requesterFirstName,
      rq.deliverer,
      rq.delivererFirstName
    );
    if (res) setShowChatModal(true);
  };

  const [showPaymentModal, setShowPaymentModal] = useState({
    visible: false,
    rq: null,
  });

  // const openPaymentModal = async (rq) => {
  //   setShowPaymentModal({ visible: true, rq: rq });
  // };
  const goToSingleItempayment = () => {
    setShowInfoModal('MYRQ', false)
    history.push('./singleitempayment');
  }
  const openReceipt = async () => {
    InAppBrowser.create(rq.stripeReceiptURL, '_blank', {
      zoom: 'no',
      hideurlbar: 'yes',
      hidenavigationbuttons: 'yes',
      toolbarcolor: '#073809',
      closebuttoncolor: '#ffffff',
      closebuttoncaption: 'Close',
    });
  };

  let statusInfo;
  useIonViewDidEnter(() => {
    console.log('here on ion view');
    statusInfo = getStatusTextAndColour(
      rq.status,
      rq.deliverer === auth.user._id,
      'mydeliveries',
      auth.user.color_theme
    );
    slidesRef.current.update();
  });

  const closeModal = () => {
    updateRooQuestWithoutCall(null);
    getMyRooQuests();
    setShowInfoModal('MYRQ', false);
  };

  const changeRequestToggle = async (e) => {
    if(e.detail.checked === true){
        // setChecked(true);
       await hideRooQuest(rq._id, e.detail.checked);
    } else {
        // setChecked(false);
       await hideRooQuest(rq._id, e.detail.checked);
    }  
  }

  const enlargeImage = (e, src) => {
    if (e.target.tagName !== 'ION-ICON') {
      setShowEnlargeImageModal(true, src);
    }
  };

  return (
    rooquest.rooquest ? (
      <IonModal
        mode="ios"
        isOpen={showMyRQModal.visible}
        class="MyRooQuestInfoModal"
        onWillPresent={async () => {
          if(slidesRef.current){
            await slidesRef.current.update();
          }
        }}
      >
        <div className="modalContent">
          <div className="infoContent">
            <GreenBackButton onClickFxn={() => closeModal()} />
            <div className="rqImg">
              {/* <img
                src={
                  rq.imgURLS && rq.imgURLS[0]
                    ? rq.imgURLS[0]
                    : require('../../../../feed/NoImageAvailable.png')
                }
                alt=""
                
              /> */}
            {rq.imgURLS.length > 0 ? <IonSlides ref={slidesRef} pager={rq.imgURLS.length > 1} options={slideOpts} className="slide-img">
                 {rq.imgURLS.map((item , index)=> {
                                return (
                                  <IonSlide onClick={(e) => enlargeImage(e, item)} key={index}>
                                      <img src={item} alt="slide" />
                                  </IonSlide>
                                )
                              }
                        )}
              </IonSlides> : <img src={require('../../../../feed/NoImageAvailable.png')} alt=""/>
              }
            </div>
            <div className="rqTitleContainer">
              <div className="title">
                <h1>{rq.title}</h1>
              </div>
              <div className="costContainer">
                <p>
                  <span>{t('Cost of Goods')}: </span>${rq.costOfGoods}
                </p>
                <p>
                  <span>{t('Delivery Fee')}: </span>${rq.costOfDeliverer}
                </p>
              </div>
            </div>
            <div
              className="rqRouteInfoContainer"
              onClick={() =>
                setShowLocationOnMapModal(
                  true,
                  rq.pickupAddress,
                  rq.deliveryAddress,
                  true
                )
              }
            >
              <RouteInfoVertical rooquest={rq} long={true} fullAddress={true} />
            </div>
            <div className="rqOtherInfo">
            {(rq.status === 'ACTIVE' ||
                rq.status === 'RQ_ACCEPTED' ||
                rq.status === 'PENDING_PAYMENT') && (<div className="rqvisible">
                           <IonList>
                            <IonItem className="reqtext" lines="none">
                            <IonLabel>{t('Visible to public')} :</IonLabel>
                                 <IonToggle checked={rq.request_enable}  onIonChange={e => changeRequestToggle(e)} /><b className="unitmethod">{checked }</b>
                            </IonItem>
                            </IonList>
              </div>
                )}
              <div className="description">
                <h5>{t('Description')}:</h5>
                <p>{rq.description}</p>
              </div>
              {rq.additionalNote && (
                <div className="description">
                  <h5>{t('Additional Notes')}:</h5>
                  <p>{rq.additionalNote}</p>
                </div>
              )}
            </div>
          </div>
          <div className="actionContent">
            {statusInfo && (
              <div className="statusRow">
                <p style={statusInfo.colour}>
                  <span>Status: </span>
                  {statusInfo.text}{' '}
                  {(rq.status === 'RQ_ACCEPTED' ||
                    rq.status === 'ROOQUEST_DELIVERED') &&
                    `by ${rq.delivererFirstName}`}{' '}
                  <IonIcon icon={arrowForwardCircleOutline} />
                </p>
              </div>
            )}
            <div className="actionRow">
              {(rq.status === 'ACTIVE' ||
                rq.status === 'RQ_ACCEPTED' ||
                rq.status === 'PENDING_PAYMENT') && (
                <div className="leftActionBtn">
                  <IonFabButton 
                     onClick={() => goToSingleItemquest()}
                  >
                    <IonIcon icon={pencil}></IonIcon>
                  </IonFabButton>
                  <p>
                    {t('Edit')} <br /> {t('RooQuest')}
                  </p>
                </div>
              )}


              {rq.status === 'ROOQUEST_DELIVERED' && rq.stripeReceiptURL && (
                <div className="leftActionBtn">
                  <IonFabButton onClick={async () => openReceipt()}>
                    <IonIcon icon={receiptOutline}></IonIcon>
                  </IonFabButton>
                  <p>
                    {t('Open')} <br /> {t('Receipt')}
                  </p>
                </div>
              )}

              {(rq.status === 'RQ_ACCEPTED' ||
                rq.status === 'RQ_ONTHEWAY' ||
                rq.status === 'ROOQUEST_DELIVERED'||
                rq.status === 'UNDER_REVIEW'||
                rq.status === 'ROOQUEST_WAITING_CLIENT_CONFIRMATION') && (
                <div className="rightActionBtn">
                  <p>
                    {t('Contact')} <br />  {t('Deliverer')}
                  </p>
                  <IonFabButton onClick={async () => openChat()}>
                    <IonIcon icon={chatbubbleEllipsesOutline}></IonIcon>
                  </IonFabButton>
                </div>
              )}
              {(rq.status === 'RQ_ONTHEWAY') && USE_GPS_TRACKING && (
                  <div className="leftActionBtn">
                    <IonFabButton 
                      onClick={() => goToTrackDeliver(rq._id, rq)}
                    >
                      <IonIcon icon={map}></IonIcon>
                    </IonFabButton>
                    <p className="leftActionBtn" 
                      onClick={() => goToTrackDeliver(rq._id, rq)}
                    >
                      Track <br /> Deliverer
                    </p>
              </div>
            )}
              {rq.status === 'PENDING_PAYMENT' && (
                <div className="rightActionBtn">
                  <p>
                    {t('Pay For')} <br /> {t('RooQuest')}
                  </p>
                  <IonFabButton
                    style={{
                      '--background': '#d41e1e',
                      '--background-activated': '#a61616',
                      '--background-focused': '#a61616',
                      '--background-hover': '#a61616',
                      '--border-color': '#800000',
                    }}
                    // onClick={async () => openPaymentModal(rq)}
                    onClick={() => goToSingleItempayment()}
                  >
                    <IonIcon icon={cardOutline}></IonIcon>
                  </IonFabButton>
                </div>
              )}
            </div>
            <div className="actionRow">
              {rq.status === 'ROOQUEST_DELIVERED' && rq.delivererRatingLeft && (
                <div className="leftActionBtn"></div>
              )}
              {rq.status === 'ROOQUEST_DELIVERED' && !rq.delivererRatingLeft && (
                <div className="leftActionBtn">
                  <IonFabButton
                    onClick={async () =>
                      setShowRatingsModal(true, 'DELIVERER_REVIEW')
                    }
                  >
                    <IonIcon icon={pencilOutline}></IonIcon>
                  </IonFabButton>
                  <p>
                    {t('Leave')} <br /> {t('a Review')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <Elements stripe={getStripePromise(USE_LIVE_STRIPE)}>
          <RQPaymentModal
            showPaymentModal={showPaymentModal}
            setShowPaymentModal={setShowPaymentModal}
            rooquest={rq}
          />
        </Elements>
      </IonModal>
    ):null
  );
};

MyRQInfoModal.propTypes = {
  deliverRooQuest: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  getConversation: PropTypes.func.isRequired,
  setPanToLocation: PropTypes.func.isRequired,
  setShowChatModal: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
  setShowInfoModal: PropTypes.func.isRequired,
  rooquest: PropTypes.object.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  setShowRatingsModal: PropTypes.func.isRequired,
  setShowEditRQModal: PropTypes.func.isRequired,
  setShowLocationOnMapModal: PropTypes.func.isRequired,
  hideRooQuest:PropTypes.func.isRequired,
  getMyRooQuests:PropTypes.func.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  map: state.map,
  navigation: state.navigation,
  rooquest: state.rooquest,
  USE_LIVE_STRIPE : state.USE_LIVE_STRIPE
});

export default connect(mapStateToProps, {
  setPanToLocation,
  getConversation,
  setShowChatModal,
  deliverRooQuest,
  setShowInfoModal,
  updateRooQuestWithoutCall,
  setShowRatingsModal,
  setShowEditRQModal,
  setShowLocationOnMapModal,
  hideRooQuest,
  getMyRooQuests,
  setShowEnlargeImageModal
})(MyRQInfoModal);
