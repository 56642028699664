//this redux state checks if it is the first time that the app is rendering. If It is the first render state is true.
const firstRender = (state=false,action) => {
    switch(action.type){
        case 'IS_RENDERED':
            return true;
        case 'IS_NOT_RENDERED':
            return false;
        default:
            return state;
    }
}

export default firstRender;