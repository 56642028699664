import React, { Component, useEffect, useRef, useState } from 'react'

const SuggestionsList = (props) => {
  
  //show suggestions when the filtered data is not empty. Otherwise show nothing
  return props.filteredSuggestions.length ? (
    <ul className="suggestions">
      {props.filteredSuggestions.map((suggestion, index) => {
        let className;
        let img =''
        // Flag the active suggestion with a class
        if (index === props.activeSuggestionIndex) {
          className = "suggestion-active";
        }
        for (var i = 0; i < suggestion['itemImg'].length; i++) {
          try{
            img = JSON.parse(suggestion['itemImg'])[i]
            break;
          }
          catch(err)
          {
            console.log(err)
          }
        }

        // console.log("suggestion = ", suggestion['itemImg'])
        return (
          <li className={className} key={index} onClick={props.suggestionClickHandler} onMouseOver = {() => props.suggestionHoverHandler(index)}>
            <img src={img}/>
            <span className='itemTitle'>{suggestion['itemTitle']}</span>
            <span className='itemPrice'>{suggestion['itemPrice']} $</span>
          </li>
        );
      })}
    </ul>
  ) : (
    <div className="no-suggestions">
    </div>
  );
};

export default SuggestionsList;