import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IonModal, IonButton, IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import { setShowEnlargeImageModal } from '../../actions/layout/navigation';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


import './EnlargeImage.scss';
import { addOutline, closeOutline, removeOutline } from 'ionicons/icons';

const EnlargeImage = ({ navigation, setShowEnlargeImageModal }) => {

  const [enlargeObj, setEnlargeObj] = useState(null);

  return (
      <IonModal
        mode="ios"
        isOpen={navigation.showEnlargeImageModal.visible}
        class="enlargeImageModal"
        onWillDismiss={() => setShowEnlargeImageModal(false, null)}
      >
        <div className="modalContent">
          {navigation && navigation.showEnlargeImageModal.src && (
            <div className="imageWrapper">
                {enlargeObj && 
                <div className="controls">
                  <div className="btn close" onClick={() => setShowEnlargeImageModal(false, null) }><IonIcon icon={closeOutline} /></div>
                    <div className="btn" onClick={() => enlargeObj.zoomIn() }><IonIcon icon={addOutline} /></div>
                    <div className="btn" onClick={() => enlargeObj.zoomOut()}><IonIcon icon={removeOutline} /></div>
                </div>}
                <TransformWrapper
                  initialScale={1}
                  centerOnInit={true}
                  minScale={0.25}
                  onInit={(obj) => {
                    setEnlargeObj(obj)
                  }}
                >
                  {({ zoomIn, zoomOut, ...rest }) => (
                    <>
                      <TransformComponent>
                        <img
                          className="enlargedPhoto"
                          src={navigation.showEnlargeImageModal.src}
                          alt="enlargedPhoto"
                        />
                      </TransformComponent>
                    </>
                  )}
                </TransformWrapper>                            
            </div>
          )}
        </div>
      </IonModal>
  );
};

EnlargeImage.propTypes = {
  navigation: PropTypes.object.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  navigation: state.navigation,
});

export default connect(mapStateToProps, { setShowEnlargeImageModal })(
  EnlargeImage
);
