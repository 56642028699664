import {INCREMENT_COLLECTED_TOTAL_PRICE,DECREMENT_COLLECTED_TOTAL_PRICE,SET_COLLECTED_TOTAL_PRICE} from './collectedItems-types'
export const incrementCollectedItemsTotal = (value) => (dispatch) => {
    dispatch({
        type: INCREMENT_COLLECTED_TOTAL_PRICE,
        payload: value
      });
}

export const decrementCollectedItemsTotal = (value) => (dispatch) => {
    dispatch({
        type: DECREMENT_COLLECTED_TOTAL_PRICE,
        payload: value
      });
}

export const setCollectedItemsTotal = (value) => (dispatch) => {
    dispatch({
        type: SET_COLLECTED_TOTAL_PRICE,
        payload:value
    });
}