import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonRow, IonCol, IonIcon, IonCheckbox, IonButtons, IonLabel, IonAvatar, IonBadge, IonFooter, IonImg, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonList, IonNote, IonPage } from '@ionic/react';
import { add, checkmarkSharp, chevronBackOutline,remove, trashOutline } from 'ionicons/icons';

import './CartHistoryModal.scss';
import styles from "./CartProducts.module.css";

const Product = ({product,selectedItems,setSelectedItems,position,checkedState,setCheckedState}) => {
  const [checked,setChecked] = useState(false);
  const [productCount, setProductCount] = useState(1);
  const firstUpdate = useRef(true);
  const removeItem = (index: number) => {
    const newItems = [...selectedItems];
    newItems.splice(index, 1);
    setSelectedItems(newItems);
}
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
          );
    setCheckedState(updatedCheckedState);
  },[checked])

  return(
    
    <IonItemSliding className={ styles.cartSlider }>
    <IonItem key={ position } lines="none" detail={ false } className={ styles.cartItem }>

        <IonAvatar>
            <IonImg src={product.images.length ? product.images[0]:require('../../feed/NoImageAvailable.png') } />
        </IonAvatar>
        <IonLabel className="ion-padding-start ion-text-wrap">
            <p>{ product.title }</p>
            <div className={ styles.cartActions }>
            <IonBadge color="dark">${ (product.costPerUnit*productCount).toFixed(2) }</IonBadge>
        </div>
        </IonLabel>

         
     <Stepper label="Quantity" setProductCount = {setProductCount} />
     <div className='checkbox'>

        <IonCheckbox
          checked={checked}
          onIonChange={(e) => {
              setChecked(e.detail.checked);
          }} 
        />
    </div>
    </IonItem>
    <IonItemOptions side="end">
        <IonItemOption color="danger" style={{ paddingLeft: "1rem", paddingRight: "1rem" }} onClick={()=>{}}>
            <IonIcon icon={ trashOutline } />
        </IonItemOption>
    </IonItemOptions>
</IonItemSliding>
  )
}


const Stepper = ({ label,setProductCount }) => {
  const [count, setCount] = useState(1);

  const incrementCount = () => {
    setCount(count + 1);
    setProductCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 0) {
      setCount(count - 1);
      setProductCount(count - 1);
    }
  };
  return (
    <div className="stepper">
      <div className='label'>
        <IonLabel>{label}</IonLabel>
      </div>

      <div className="stepper-buttons">
        <IonButton className="stepper-button" onClick={decrementCount}>
          <IonIcon icon={remove} />
        </IonButton>
        <IonLabel className="stepper-count">{count}</IonLabel>
        <IonButton className="stepper-button" onClick={incrementCount}>
          <IonIcon icon={add} />
        </IonButton>
      </div>
    </div>
  );
}


const IonicModal = ({
  products,  
  setShowModal,
  setSelectedItems,
  selectedItems}) => {

    const [checkedState, setCheckedState] = useState(
      new Array(products.length).fill(false)
    );

    useEffect(() => {
      
    },[checkedState])
  
    const addToCart = () => {
      console.log(" add to cart")
      // add to cart only checkedstate is true
      let newItems = [];
      products.map((product,index) => {
        if(checkedState[index]){
          newItems.push(product);
        }
      })
      console.log(newItems)
      setSelectedItems([...newItems]);
    }

    
return(<IonPage id="category-page" className={ styles.categoryPage }>
<IonHeader>
<IonToolbar>
        <IonButtons slot="start">
            <IonButton color="dark" onClick={()=>{setShowModal(false)}}>
                <IonIcon color="dark" icon={ chevronBackOutline } />&nbsp;
            </IonButton>
        </IonButtons>
<IonTitle>Buy it Again</IonTitle>
</IonToolbar>
</IonHeader>

<IonContent fullscreen>

        <IonRow className="ion-text-center ion-margin-top">
            <IonCol size="12">
                <IonNote>{ products && products.length } { (products.length > 1 || products.length === 0) ? " foods" : " food" } found</IonNote>
            </IonCol>
        </IonRow>

        <IonList>
            { products && products.map((product, index) => {
                    return (
                      <Product product={product} setSelectedItems = {setSelectedItems} selectedItems = {selectedItems} setCheckedState = {setCheckedState} checkedState ={checkedState} position = {index} key = {index}  />
                    );
            })}
        </IonList>
</IonContent>

<IonFooter className={ styles.cartFooter }>
    <div className={ styles.cartCheckout }>
        {/* <IonCardSubtitle>${ total }</IonCardSubtitle> */}

        <IonButton color="dark" onClick={addToCart}>
            <IonIcon icon={ checkmarkSharp } />&nbsp;Add to cart
        </IonButton>
    </div>
</IonFooter>
</IonPage>)

}


const CartHistoryModal = ({pastItems,addItem,setItems,showModal,setShowModal}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if(selectedItems.length > 0){
      // selectedItems.map((item) => {
      //   addItem(item);
      // });
      setItems([...selectedItems]);
      console.log("selected items ")
      console.log(selectedItems)
      setShowModal(false);
    }
  },[selectedItems]);

  return(
    <IonModal
    isOpen = {showModal}
    className = "cart-history-modal"
    >
      <IonicModal  products={pastItems} setSelectedItems = {setSelectedItems} selectedItems = {selectedItems} setShowModal = {setShowModal} />
    </IonModal>
  );

};

export default CartHistoryModal;
     