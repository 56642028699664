import React from 'react';

import './ModalCard.scss';

const ModalCard = ({ title, children }) => {
  return (
    <div className="MODALCARD">
      <div className="cardContainer">
        <h5 className="cardTitle">{title}</h5>
        <div className="cardBody">{children}</div>
      </div>
    </div>
  );
};

export default ModalCard;
