import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonModal,
  IonButton,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
} from '@ionic/react';

import './ConfirmOnTheWayModal.scss';
import { connect } from 'react-redux';
import { Camera, CameraResultType } from '@capacitor/camera';
import { addCircleOutline, closeOutline } from 'ionicons/icons';
import {
  markRQAsOnTheWay,
  updateRooQuestWithoutCall,
  postDeliveryId
} from '../../../../../../actions/rooquest/rooquest';
import { setShowEnlargeImageModal } from '../../../../../../actions/layout/navigation';
import { uploadReceiptImages } from '../../../../../../actions/firebase/firebase-storage';
import { USE_GPS_TRACKING } from '../../../../../../App';
import { useTranslation } from 'react-i18next';
import { Geolocation } from '@capacitor/geolocation'; 
// const { Geolocation } = Plugins;
declare var global;
const ConfirmOnTheWayModal = ({
  rooquest,
  showModal,
  setShowModal,
  markRQAsOnTheWay,
  updateRooQuestWithoutCall,
  setShowEnlargeImageModal,
  postDeliveryId
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [didNotConfirmError, setDidNotConfirmError] = useState({
    error: false,
    style: null,
  });

  // Get Image
  const [receiptImages, setReceiptImages] = useState([]);

  const getImage = async () => {
    try
    {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        height: 500,
      });
  
      (await fetch(image.webPath)).blob().then((blobImage) => {
        setReceiptImages([
          ...receiptImages,
          { blobImage, webpath: image.webPath },
        ]);
      });
    }
    catch(err)
    {
      console.log("photo not taken")
      console.log(err)
    }
    
  };

  const enlargeImage = (e, src) => {
    if (e.target.tagName !== 'ION-ICON') {
      setShowEnlargeImageModal(true, src);
    }
  };

  const [amountPaid, setAmountPaid] = useState(
    parseFloat(rooquest.rooquest.costOfGoods)
  );
  const [amountPaidError, setAmountPaidError] = useState(false);

  // const uploadAllImages = async () => {

  //   uploadReceiptImage()

  //   const imageURLs = [];
  //   receiptImages.forEach(async (image) => {
  //     const url = await uploadReceiptImage(image.blobImage);
  //     console.log(url);
  //     imageURLs.push(url);
  //     console.log(imageURLs);
  //   });
  //   await Promise.all(imageURLs);
  //   return imageURLs;
  // };

  const confirmOnTheWay = async () => {
    if (checked) {
      if (amountPaid <= parseFloat(rooquest.rooquest.costOfGoods) * parseFloat(rooquest.rooquest.costOfGoodsMultiplier)|| true) {
        const images = await uploadReceiptImages(receiptImages);
        const imagesString = images.toString();

        const rq = await markRQAsOnTheWay(
          rooquest.rooquest._id,
          'RQ_ONTHEWAY',
          amountPaid,
          imagesString
        )

        if (!rq) { 
          setShowModal(false);
          return;
        }  
              
        await updateRooQuestWithoutCall(rq)
        
        if (USE_GPS_TRACKING) {
          postDataWithLatLong();
          let data = setInterval(function(){ 
              postDataWithLatLong();
            }, 12000);
          global.driverInterval = data;
        }
        
        setShowModal(false);
      } else {
        setAmountPaidError(true);
      }
    } else {
      setDidNotConfirmError({ error: true, style: { color: '#eb0000' } });
    }
  };
  const postDataWithLatLong = async () => {
    let geo = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
    });
    let res = await postDeliveryId(rooquest.rooquest._id,geo.coords.latitude,geo.coords.longitude);
    if (res) {
     console.log(res);
    }
  }
  return (
    <div className="confirmOnTheWayModalDiv">
      <IonModal
        mode="ios"
        isOpen={showModal}
        class={'confirmOnTheWayModal'}
        // showBackdrop={true}
        backdropDismiss={true}
        onWillDismiss={() => setShowModal(false)}
      >
        <div className="modalContent">
          <h2 className="modalTitle">{t('Confirm On The Way')}</h2>

          <div className="inputElements">
            <div className="receiptAndAmountContainer">
              <p className="imageOfReceiptMessage">
              {t('Please provide')} <strong>`${t('image of receipt')}`</strong> {t('for proof of purchase of goods and the amount paid')} (if applicable).
              </p>

              <div className="rowOfImages">
                {receiptImages.map((image, index) => (
                  <div
                    key={`receiptImage_${index}`}
                    className="imageContainer"
                    onClick={(e) => enlargeImage(e, image.webpath)}
                  >
                    <img
                      src={image.webpath}
                      alt="receipt"
                      className="receiptImage"
                    />
                    <div
                      className="removeImage"
                      onClick={() => {
                        const newArr = [...receiptImages];
                        newArr.splice(index, 1);
                        setReceiptImages(newArr);
                      }}
                    >
                      <IonIcon icon={closeOutline} />
                    </div>
                  </div>
                ))}
                {receiptImages.length < 5 && (
                  <div className="addImageButton">
                    <IonButton onClick={async () => await getImage()}>
                      <IonIcon icon={addCircleOutline} />
                    </IonButton>
                  </div>
                )}
              </div>

              <div className="amountPaid">
                <IonLabel mode={'ios'} position="stacked" slot="start">
                  {t('Amount Paid')}:
                </IonLabel>
                <IonItem>
                  <IonInput
                    placeholder="0.00"
                    type="number"
                    value={amountPaid}
                    max={(parseFloat(rooquest.rooquest.costOfGoods) * parseFloat(rooquest.rooquest.costOfGoodsMultiplier)).toString()}
                    onIonChange={(e) => {
                      setAmountPaidError(false);
                      setAmountPaid(parseFloat(e.detail.value!));
                    }}
                  />
                </IonItem>
              </div>
              {amountPaidError &&
                (amountPaid > rooquest.rooquest.costOfGoods ? (
                  <p
                    style={{
                      color: 'red',
                      fontSize: '11px',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    Enter valid amount not greater than cost of goods.
                  </p>
                ) : (
                  <p
                    style={{
                      color: 'red',
                      fontSize: '11px',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    Please enter a valid amount.
                  </p>
                ))}
            </div>

            <div className="confirmCheckbox">
              <IonItem lines='full'>
                <IonCheckbox
                  checked={checked}
                  onIonChange={(e) => {
                    setDidNotConfirmError({ error: true, style: null });
                    setChecked(e.detail.checked);
                  }}
                />
                <IonLabel style={didNotConfirmError.style}>
                  {t("I have picked up the RooQuest and are ready to deliver the RooQuest as per Roo's Terms of Service.")}
                </IonLabel>
              </IonItem>
            </div>
          </div>

          <div className="actionButtons">
            <div className="row">
              <IonButton
                onClick={() => setShowModal(false)}
                disabled={rooquest.loading}
              >
                {t('Close')}
              </IonButton>
              <IonButton
                onClick={() => confirmOnTheWay()}
                disabled={rooquest.loading}
              >
                {!rooquest.loading ? 'Confirm' : 'Confirming'}
              </IonButton>
            </div>
          </div>
        </div>
      </IonModal>
    </div>
  );
};

ConfirmOnTheWayModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  rooquest: PropTypes.object.isRequired,
  markRQAsOnTheWay: PropTypes.func.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
  postDeliveryId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  rooquest: state.rooquest,
});

export default connect(mapStateToProps, {
  markRQAsOnTheWay,
  updateRooQuestWithoutCall,
  setShowEnlargeImageModal,
  postDeliveryId
})(ConfirmOnTheWayModal);
