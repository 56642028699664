import { combineReducers } from 'redux';

import auth from './auth';
import toast from './layout/toast';
import rooquest from './rooquest';
import feedFilterBar from './layout/feedFilterBar';
import map from './layout/map';
import navbar from './layout/navbar';
import navigation from './layout/navigation';
import chat from './chat';
import notificationmenu from './notificationmenu';
import collectedtotalPrice from './collectedItems';
import firstRender from './firstRender';
import notificationRange from './notificationRange';
import USE_LIVE_STRIPE from './stripe';
import isLoading from './loading';
import isKeyboardVisible from './keyboard';
import requestFunds from './requestFunds';

export default combineReducers({
  auth,
  toast,
  rooquest,
  feedFilterBar,
  map,
  navbar,
  chat,
  navigation,
  notificationmenu,
  collectedtotalPrice,
  firstRender,
  notificationRange,
  USE_LIVE_STRIPE,
  isLoading,
  isKeyboardVisible,
  showApproveRequestedFundsAlert: requestFunds,
});
