import React, { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonButton,
  IonItem,
  IonInput,
  IonIcon
} from '@ionic/react';

import PropTypes from 'prop-types';

import './Register.scss';
import { connect } from 'react-redux';

import {
  register,
  registerWithEmailAndPasswordFirebase,
} from '../../actions/auth/auth';

import Toast from '../../components/layout/Toast';

import { Link, Redirect } from 'react-router-dom';

import {
  eye,
  eyeOffOutline,
} from 'ionicons/icons';

const Register = ({
  register,
  isAuthenticated,
  registerWithEmailAndPasswordFirebase,
}) => {
  // const registerWithEmail = () => {
  //   const firstName = 'Matthew';
  //   const lastName = 'Goodman';
  //   const email = 'matthew@gmail.com';
  //   const phoneNumber = '514-707-3143';
  //   const password = 'matthew';
  //   register({ firstName, lastName, email, phoneNumber, password });
  // };

  const [registerFormData, setRegisterFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: ''
  });

  const { firstName, lastName, email, password} = registerFormData;
  // const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setRegisterFormData({
      ...registerFormData,
      [e.target.name]: e.target.value,
    });
    // console.log(password);
  };

  const [showPassword,setShowPassord] = useState(false)

  // const getRememberVal = (data) => {
  //   setChecked(data);
  //   setRegisterFormData({ ...registerFormData, rememberme: data});
  //  }

  const submitRegister = async (e) => {
    e.preventDefault();
    await registerWithEmailAndPasswordFirebase({
      firstName,
      lastName,
      email,
      password
    });
   setRegisterFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: ''
    })
    // if (user) {}
    // register(firstName, lastName, email, phoneNumber, password);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/feed" />;
  }
  const handleKeyDown = (event) => {
    if(event.key === 'Enter'){
      submitRegister(event);
    }
  }
  return (
    <IonPage className="register">
      <IonContent className="registerPage">
        <div className="home-container">
          <div className="homepage-content">
            <img
              className="top-pic"
              src={require('./signin-pic.png')}
              alt="kargoroo_header"
            />
            <form>
              <IonItem className="form-inputs form-email" color=" ">
                <IonInput
                  className="form-input"
                  placeholder="First Name"
                  name="firstName"
                  autocomplete="on"
                  value={firstName}
                  onIonInput={(e) => onChange(e)}
                ></IonInput>
              </IonItem>
              <IonItem className="form-inputs form-email" color=" ">
                <IonInput
                  className="form-input"
                  placeholder="Last Name"
                  name="lastName"
                  autocomplete="on"
                  value={lastName}
                  onIonInput={(e) => onChange(e)}
                ></IonInput>
              </IonItem>
              <IonItem className="form-inputs form-email" color=" ">
                <IonInput
                  className="form-input"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                  value={email}
                  onIonInput={(e) => onChange(e)}
                ></IonInput>
              </IonItem>
              {/* <IonItem className="form-inputs form-email" color=" ">
                <IonInput
                  className="form-input"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  autocomplete="on"
                  value={phoneNumber}
                  onIonInput={(e) => onChange(e)}
                ></IonInput>
              </IonItem> */}
              <IonItem className="form-inputs form-password" color=" ">
                <IonInput
                  className="form-input"
                  type={showPassword ? "text":"password"}
                  placeholder="Password"
                  autocomplete="off"
                  name="password"
                  value={password}
                  onIonInput={(e) => onChange(e)}
                  onKeyPress={handleKeyDown}
                  defaultValue=""
                ></IonInput>
                <IonIcon icon={showPassword ?  eyeOffOutline:eye} onClick= {()=>{setShowPassord(!showPassword)}} />
              </IonItem>
              {/* <IonItem className="form-inputs" color=" ">
                <IonCheckbox checked={checked} onIonChange={e => getRememberVal(e.detail.checked)} />
                <IonLabel className="form-input" >Remember Me</IonLabel>
              </IonItem> */}
            </form>
            <IonButton
              className="signin-btn"
              expand="block"
              fill="solid"
              color="kargoroo_green"
              shape="round"
              text-capitalize="false"
              type="button"
              onClick={(e) => submitRegister(e)}
            >
              <span>Register</span>
            </IonButton>

            <Link to="/" className="haveaccount-btn">
              <span>Already have an account?</span>
            </Link>

            <Toast />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  registerWithEmailAndPasswordFirebase: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  register,
  registerWithEmailAndPasswordFirebase,
})(Register);
