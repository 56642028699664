import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IonAccordion, IonAccordionGroup, IonAlert, IonIcon, IonItem, IonLabel, IonList, IonToggle } from '@ionic/react';
import { chatbubbleEllipsesOutline, trashOutline } from 'ionicons/icons';
import { launchToastCard } from '../../../../actions/layout/toast';
import { deleteRooQuestByID, hideRooQuest } from '../../../../actions/rooquest/rooquest';
import { acceptDeliveryOffer, rejectDeliveryOffer } from '../../../../actions/rooquest/offers';
import WhiteCard from '../../components/ui/WhiteCard';
import DeliveryOffer from '../RQ/DeliveryOffer';
import { AdditionalNotesCard, PickupDropoffCard } from '../RooQuestInformation';
import RooQuestItemsList from '../RQ/RooQuestItemsList/RooQuestItemsList';
import getStatusTextAndColour from '../../../../utils/getStatusTextAndColour';
import { RQCosts } from '../../form_pages/Review';
import FlipNumbers from 'react-flip-numbers';
import { useTranslation } from 'react-i18next';
import Savings from '../Savings';


const MyRooQuest = ({ showChat = true, auth, rooquest, openChat, closeModal, deleteRooQuestByID, launchToastCard, changeType, hideRooQuest, extraPadding = false }) => {

    const [myrq_showContactBtn, myrq_setShowContactBtn] = useState(false);
    const [myrq_showIsVisible, myrq_setShowIsVisible] = useState(false);
    const [myrq_showEdit, myrq_setShowEdit] = useState(false);

    const [showConfirmOfferAlert, setShowConfirmOfferAlert] = useState({ show: false, offer: null });
    const [showRejectOfferAlert, setShowRejectOfferAlert] = useState({ show: false, offer: null });
    const [reviewItem, setReviewItem] = useState([]);
    const offers = [...rooquest.offers].reverse()
    const { t } = useTranslation();
    useEffect(() => {
        if (!rooquest) return;
        const status = rooquest.status;
        
        myrq_setShowContactBtn(
            status === 'RQ_ACCEPTED' ||
            status === 'RQ_ONTHEWAY' ||
            status === 'ROOQUEST_DELIVERED'||
            status === 'UNDER_REVIEW'||
            status === 'ROOQUEST_WAITING_CLIENT_CONFIRMATION')

        myrq_setShowIsVisible(
            status === 'ACTIVE'
        )

        myrq_setShowEdit(
            status === 'ACTIVE' ||
            status === 'PENDING_PAYMENT'
        )
    }, [rooquest]);

    const deleteRooQuest = async () => {
        const deletemsg = await deleteRooQuestByID(rooquest._id);
        launchToastCard({ 
            msg: deletemsg.msg,
            type: 'success',
            duration: 8500,
         });
        closeModal()
    }

    const changeRequestToggle = async (e) => {
        if(e.detail.checked === true){
            // setChecked(true);
           await hideRooQuest(rooquest._id, e.detail.checked);
        } else {
            // setChecked(false);
           await hideRooQuest(rooquest._id, e.detail.checked);
        }  
    }   

    return (
        <>
            {showChat && <div className="actionBtns">
            {myrq_showContactBtn && 
                <div className="contactBtn" onClick={async () => openChat()}>
                    <IonIcon icon={chatbubbleEllipsesOutline} />
                </div>}
                {myrq_showEdit && <div className="contactBtn" onClick={deleteRooQuest}>
                    <IonIcon icon={trashOutline} />
                </div>
            }
            </div>}
            <div className="mainContent">
                
                {/* <div className="rqinfo-section">
                    <p className="sectionTitle">{t("RooQuest Status")}:</p>
                    <WhiteCard>
                        <div className="statusCard">
                            <p>{`${getStatusTextAndColour(rooquest.status, null, 'myrooquests',auth.user.color_theme).text}`}</p>
                            {myrq_showIsVisible && 
                                <div className="visibleToPublic">
                                    <p>{t("Visible To Public")}:</p>
                                    <IonToggle 
                                        checked={rooquest.request_enable} 
                                        onIonChange={e => changeRequestToggle(e)} />
                                </div>
                            }
                        </div>
                    </WhiteCard>
                </div> */}

                <RooQuestItemsList rooquest={rooquest} />
                
                {rooquest.offers.length > 0 &&
                    <div className="deliveryOffers">
                        <p className="sectionTitle">{t('Delivery Offers')}:</p>
                        <WhiteCard>
                            <div className="offersContainer">
                                {offers.map((offer, index) => {
                                    // console.log("rooo")
                                    // console.log(rooquest)
                                    
                                    return <DeliveryOffer 
                                        key={`rqoffer-${index}`}
                                        setShowAccept={(show) => setShowConfirmOfferAlert({show, offer})}
                                        setShowReject={(show) => setShowRejectOfferAlert({show, offer})}
                                        setShowRescind={null}
                                        rq_id={rooquest._id}
                                        deliveryOffer={offer}
                                        rq = {rooquest}    
                                                                                    
                                    />
                                })}                                           
                            </div>
                        </WhiteCard>
                    </div>
                }

                <Savings rooquest={rooquest} />

                <div className="rqinfo-section">
                    <div className="sectionTitleRow">
                    <p className="sectionTitle">{t('Cost Breakdown')}:</p>
                    <FlipNumbers 
                            height={14} 
                            width={9.5} 
                            color="#10be5f" 
                            // background="white" 
                            play perspective={100} 
                            duraction={0.4}
                            numbers={'$'+ parseFloat(rooquest.costs.total).toFixed(2)} />
                    </div>
                    
                    <RQCosts items={rooquest.items} setReviewItem={setReviewItem} costs={rooquest.costs} />
                </div>  


                <PickupDropoffCard rooquest={rooquest} showFullAddress={true} />
                <AdditionalNotesCard rooquest={rooquest} />
                
                {extraPadding && <div className="MyRQInfo-extraPadding"></div>}

            </div>

            <IonAlert
                isOpen={showConfirmOfferAlert.show}
                onDidDismiss={() => setShowConfirmOfferAlert({show: false, offer: null})}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Confirm Offer?')}`}
                message={
                `<p>${t('By accepting this offer, you start your delivery and automatically decline all other delivery offers. Ready to accept this offer?')}</p>`
                }
                buttons={[
                    { text: `${t('Close')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Accept Offer')}`,
                        handler: async () => {
                            acceptDeliveryOffer(rooquest._id, showConfirmOfferAlert.offer._id);
                            changeType('MYRQ')
                            setShowConfirmOfferAlert({show: false, offer: null});
                        },
                    },
                ]}
            />

            <IonAlert
                isOpen={showRejectOfferAlert.show}
                onDidDismiss={() => setShowRejectOfferAlert({show: false, offer: null})}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Reject Offer?')}`}
                message={
                    `<p>'${t('By rejecting this offer, you notify this deliverer that you are not interested in their delivery offer and you will not be able to accept it in the future. Are you sure?')}'</p>`
                }
                buttons={[
                    { text: `${t('Close')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Reject Offer')}`,
                        handler: async () => {
                            rejectDeliveryOffer(rooquest._id, showRejectOfferAlert.offer._id);
                            setShowRejectOfferAlert({show: false, offer: null});
                        },
                    },
                ]}
            />
        </>
    )
};

MyRooQuest.propTypes = {
    rooquest: PropTypes.object.isRequired,
    openChat: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    deleteRooQuestByID: PropTypes.func.isRequired,
    launchToastCard: PropTypes.func.isRequired,
    changeType: PropTypes.func.isRequired,
    hideRooQuest: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, { deleteRooQuestByID, launchToastCard, hideRooQuest })(MyRooQuest);
