import React from 'react'

import { ProgressBar, Step } from "react-step-progress-bar";

import { alertOutline, checkmarkOutline, lockClosedOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import "react-step-progress-bar/styles.css";
import './ProgressBarStepper.scss';

const ProgressBarStepper = ({ activeIndex, numSlides, status }) => {
    return (
        <div className="ProgressBarStepper">
            <ProgressBar 
                className="" 
                percent={(activeIndex / (numSlides-1)) * 100}
            >
                {
                    [...Array(numSlides)].map((_, index) => {
                        return (
                            <Step key={`progressbar-step-item-key-${index}`}>
                            {({ accomplished, index }) => {
                                
                            return (
                            <div
                                className={
                                    `indexedStep 
                                    ${accomplished ? "accomplished" : null} 
                                    ${index === activeIndex ? "active" : null} 
                                    ${status[index].status === "complete" ? "complete" : null}`
                                }
                                    
                            >
                                {(status[index].status === "incomplete" || index === activeIndex) 
                                    ? <div className="dot" />
                                    : <IonIcon icon={
                                        status[index].status === "complete" ? checkmarkOutline :
                                        status[index].status === "problem" ? alertOutline :
                                        status[index].status === "locked" ? lockClosedOutline :
                                        null
                                    } />
                                }
                                
                            </div>
                            )}}
                        </Step>
                        )
                    })
                }
            </ProgressBar>
        </div>
    )}
    

ProgressBarStepper.propTypes = {

}

export default ProgressBarStepper
