import axios from 'axios';
import { backendAddress } from '../../App';

//will get the items from the database to display it in the front end
export const getItems = async (input,pickupAddress)  => {
    const res = await axios.get(backendAddress+ '/api/find-items', {
        params:{
            title:input,
            pickupAddress:pickupAddress
        }
    });
//     const res = await axios.get("http://localhost:5000"+"/api/find-items", {
//         params:{
//             title:input,
//             pickupAddress:JSON.stringify(pickupAddress)
//         }
//     })
    return res["data"];
}

//will get the items from previous orders from the database to display it in the front end
export const getPastItems = (pickupAddress) => async (dispatch) => {
    console.log("get past items");
    const res = await axios.get(backendAddress+ '/api/find-items//find-past-items', {
        params:{
            pickupAddress:JSON.stringify(pickupAddress)
        }
    });
    console.log(res["data"])
    return res["data"];
}

//will use the url of the images to fetch them and show them in the front end
export const fetchImages = async (images) =>{
    const res= await axios.post(backendAddress+"/api/fetch-images",{
        images: images
    })
    // const res= await axios.post("http://localhost:5000"+"/api/fetch-images",{
    //     images: images
    // })
    let data = res.data
    data = data.map((arg)=>{
        const array = new Uint8Array(arg.blobImage.data)
        return {blobImage: new Blob([array],{type : 'image/jpeg'}), src: arg.src }
    })
    return data
}