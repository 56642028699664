import { IonButton, IonCheckbox, IonCol, IonGrid, IonItem, IonLabel, IonModal, IonRow } from "@ionic/react";
import React, { useState } from "react";
import { connect } from "react-redux";
import './InformationPopup.scss';
import { setShowFirstOfferSuccessModal } from "../../../../actions/layout/navigation";

const InformationPopup = ({showFirstOfferSuccessModal,setShowFirstOfferSuccessModal}) => {
    const [checked,setChecked] = useState(false);

    const closeInfoPopup = () => {
        setShowFirstOfferSuccessModal(false);
        if(checked)
            localStorage.setItem('showOfferPopupAgain','true');
    }

    return (
        <IonModal 
            id="example-modal" 
            isOpen={showFirstOfferSuccessModal}
            mode="ios"
            class = "information-popup"
            showBackdrop={true}
            backdropDismiss={true}
            onWillDismiss={() => closeInfoPopup()}
        >
            <IonGrid className="grid-container">
           <IonRow className="slide-content-container">
                     <IonCol size="auto" className="slide-content">
                         <h1>Your Offer has been sent successfully</h1>
                        
                         <img src={require('./BottomMenu.jpeg')} className="slide-main-image" />
                         <p> You can access this rooquest and see your offers in the MyDeliveries Tab. You will be notify when your offer either accepted or rejected. </p>
                         <IonItem lines="none" class="ion-no-padding">
                             <IonCheckbox 
                                slot="start" 
                                checked={checked}
                                onIonChange={(e) => {
                                    setChecked(e.detail.checked);
                                }}
                            ></IonCheckbox>
                             <IonLabel>Do not show this again</IonLabel>
                         </IonItem>
                            
                        <IonButton expand="block" fill="outline" onClick={closeInfoPopup}>Got It</IonButton>
                     </IonCol>
                 </IonRow>
                 </IonGrid>
        </IonModal>
    )

}
const mapStateToProps = (state) => ({
    showFirstOfferSuccessModal : state.navigation.showFirstOfferSuccessModal
  });
export default connect(mapStateToProps, {setShowFirstOfferSuccessModal})(InformationPopup);