import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './ChatMessage.scss';
import { getDateElements } from '../../../utils/formatDate';
import {
  setShowEnlargeImageModal,
} from '../../../actions/layout/navigation';
const ChatMessage = ({ messageObj, isSender, senderName,setShowEnlargeImageModal }) => {
  // Date Logic
  const messageObjDate = new Date(messageObj.date);
  const currentDate = new Date();
  const dateElements = getDateElements(messageObjDate);

  let msgSentDate;
  if (messageObjDate.getFullYear() !== currentDate.getFullYear()) {
    // If different year
    msgSentDate = `${dateElements.month} ${dateElements.date} ${dateElements.year}`;
  } else if (
    messageObjDate.getMonth() !== currentDate.getMonth() ||
    messageObjDate.getDate() !== currentDate.getDate()
  ) {
    // If different month/day
    msgSentDate = `${dateElements.month} ${dateElements.date} ${dateElements.formattedTime}`;
  } else {
    // If same day
    msgSentDate = `${dateElements.formattedTime}`;
  }

  const bubbleStyling = !isSender
    ? {
        backgroundColor: '#d9d9d9',
        borderRadius: '20px 20px 20px 0px',
      }
    : { backgroundColor: '#5be35b', borderRadius: '20px 20px 0px 20px' };

    const imagestyling = !isSender
    ? {
        borderRadius: '20px 20px 20px 0px',
      }
    : { borderRadius: '20px 20px 0px 20px' };

  const chatMessageStyling = !isSender
    ? {
        alignItems: 'flex-start',
      }
    : { alignItems: 'flex-end' };

    const enlargeImage = (e, src) => {
      if (e.target.tagName !== 'ION-ICON') {
        setShowEnlargeImageModal(true, src);
      }
    };
  return (
    <div className="ChatMessage" style={chatMessageStyling}>
    {!!messageObj.msgContent ? <div className="chatMessageBubble" style={bubbleStyling}>  
        <p>{messageObj.msgContent}</p>
      </div>:null}
      {!!messageObj.imgURL && messageObj.imgURL.length > 0 ? messageObj.imgURL.map((index,value) =><img  className="chatMessageImg" onClick={(e) => enlargeImage(e, messageObj.imgURL[value])}  src={messageObj.imgURL[value]} style={imagestyling} key={index} alt="enlarge" />):null }

      {/* {!!messageObj.imgURL ? <img  className="chatMessageImg" onClick={(e) => enlargeImage(e, messageObj.imgURL)} src={messageObj.imgURL} style={imagestyling} />:null} */}
      <p className="messageInfo">
        {senderName} · {msgSentDate}
      </p>
    </div>
  );
};

ChatMessage.propTypes = {
  messageObj: PropTypes.object.isRequired,
  isSender: PropTypes.bool.isRequired,
  senderName: PropTypes.string.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
};

export default connect(null, {
  setShowEnlargeImageModal,
})(ChatMessage);;
