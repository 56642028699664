import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { IonContent, IonIcon, IonModal } from '@ionic/react'
import { closeOutline, saveSharp } from 'ionicons/icons'
import './ReviewEditItemModal.scss'
import { useTranslation } from 'react-i18next'
const ReviewEditItemModal = ({ showModal, setShowModal, note, setNote, setUpdate }) => {

    const close = () => {
        setShowModal(false)
    }

    const saveAndClose = () => {
        const index = note.uniqueId
        delete note.uniqueId
        setUpdate(note, index)
        setShowModal(false)
    }
    const { t } = useTranslation();
    return (
        <IonModal
            className="PostRQAdditionalNote"
            isOpen={showModal}
            breakpoints={[0, 0.8]}
            initialBreakpoint={0.8}
            onIonModalDidDismiss={close}
        >
            <IonContent>
                <div className="addItemContent">
                    <div className="addItemTitle">
                        <p>{t('EDIT ITEM')}</p>
                    </div>
                    <div className="closeBtn">
                        <IonIcon icon={closeOutline} onClick={close} />
                    </div>
                    <div className="inputs-container">
                        {/* <div className="inputs">
                        <div className="item-desc input-item">
                            <p className='input-label'>Notes:</p>
                            <textarea value={noteText} onChange={(e) => setNoteText(e.target.value)} />
                        </div>
                    </div> */}
                        <div className="inputs">
                            <div className="item-name input-item">
                                <p className='input-label'>{t('Name')}: </p>
                                <input placeholder="What am I called?" type="text" value={note.title} onChange={(e) => {
                                    setNote({
                                        ...note,
                                        title: e.target.value
                                    })
                                }} />
                            </div>
                            <div className="item-desc input-item">
                                <p className='input-label'>{t('Description')}:</p>
                                <textarea placeholder={t("What do I look like? Colour? Weight? etc.")} value={note.description} onChange={(e) => {
                                    setNote({
                                        ...note,
                                        description: e.target.value
                                    })
                                }} />
                            </div>
                        </div>

                        <div className="btnContainer">
                            <div className="addItemBtn" onClick={saveAndClose}>
                                <IonIcon icon={saveSharp} />
                                <p>{t('Update Item')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}

ReviewEditItemModal.propTypes = {
}

export default ReviewEditItemModal;
