import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import './PasswordResetModal.scss';
import GreenModal from '../../../components/layout/Modal/GreenModal';
import FullPageModalCard from '../../../components/layout/Modal/FullPageModalCard';
import { IonItem, IonInput, IonButton } from '@ionic/react';
import { sendPasswordReset } from '../../../actions/auth/auth';

const PasswordResetModal = ({ showModal, setShowModal }) => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [showNoEmailError, setShowNoEmailError] = useState('');

  const closeModal = (bool = false) => {
    setEmail('');
    setEmailSent(bool);
    setShowModal(bool);
  };

  const sendEmail = async () => {
    if (!email || email.length < 2) setShowNoEmailError('Please Provide Email');
    else {
      const res = await sendPasswordReset(email);
      if (res.error) setShowNoEmailError(res.error);
      else {
        setEmailSent(true);
      }
    }
  };

  return (
    <GreenModal
      title="Password Reset"
      showModal={showModal}
      setShowModal={setShowModal}
      swipeToClose={true}
      backBtnOnClickFxn={() => closeModal(false)}
      className="passwordResetModal"
    >
      <FullPageModalCard title="Password Reset">
        <div className="passwordResetModalContent">
          {!emailSent ? (
            <Fragment>
              <h4 className="modalTitle textcolor">Enter Your Email Address:</h4>
              <IonItem>
                <IonInput
                  type="email"
                  placeholder="Email Address"
                  onIonChange={(e) => {
                    setEmail(e.detail.value!);
                    setShowNoEmailError('');
                  }}
                ></IonInput>
              </IonItem>
              <p className="emailError">{showNoEmailError}</p>
              <div className="buttonContainer">
                <IonButton
                  onClick={() => {
                    sendEmail();
                  }}
                >
                  Send Reset Link
                </IonButton>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <h4 className="modalTitle">Email Sent</h4>
              <p>
                If {email} is a valid email, you will be receiving an email
                shortly with password reset instructions. Make sure to check
                your junk mail.
              </p>
              <div className="buttonContainer">
                <IonButton
                  onClick={() => {
                    closeModal(false);
                  }}
                >
                  Close
                </IonButton>
              </div>
            </Fragment>
          )}
        </div>
      </FullPageModalCard>
    </GreenModal>
  );
};

PasswordResetModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default PasswordResetModal;
