import React from 'react';
import {
  IonTabButton,
  IonIcon,
  IonLabel,
  IonTabBar,
  IonBadge,
  IonTabs,
  IonRouterOutlet,
} from '@ionic/react';
import './Navbar.scss';
import {
  gridOutline,
  cartOutline,
  cubeOutline,
  chatbubblesOutline,
  grid,
  cart,
  cube,
  chatbubbles,
} from 'ionicons/icons';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { updateNavbarWithUnreadMessages } from '../../../actions/layout/navbar';
import { useTranslation } from 'react-i18next';

const Navbar = ({ auth, chat, navbar, selected = 'Home', setSelected, setHome }) => {
  const { t } = useTranslation();
  const isSelected = (tabName) => {
    return selected === tabName;
  };

  return (
    <div className="navbar">
        {/* <IonTabBar slot="bottom"> */}
          <IonTabButton
            tab="Home"
            selected={isSelected('Home')}
            onClick={(e) => {
              setSelected('Home');
              setHome()
            }}
          >
            <IonIcon icon={isSelected('Home') ? grid : gridOutline} />
            <IonLabel>{t('Home')}</IonLabel>
          </IonTabButton>
            
          <IonTabButton
            tab="MyRooQuests"
            selected={isSelected('MyRooQuests')}
            onClick={() => setSelected('MyRooQuests')}
          >
            <IonIcon icon={isSelected('MyRooQuests') ? cart : cartOutline} />
            <IonLabel>{t('My RooQuests')}</IonLabel>
            {auth && navbar && navbar.activeRooquests > 0 && (
              <IonBadge color="danger">{navbar.activeRooquests}</IonBadge>
            )}
          </IonTabButton>

          <IonTabButton
            tab="MyDeliveries"
            selected={isSelected('MyDeliveries')}
            onClick={() => setSelected('MyDeliveries')}
          >
            <IonIcon icon={isSelected('MyDeliveries') ? cube : cubeOutline} />
            <IonLabel>{t('My Deliveries')}</IonLabel>
            {auth && navbar && navbar.activeDeliveries > 0 && (
              <IonBadge color="danger">{navbar.activeDeliveries}</IonBadge>
            )}
          </IonTabButton>

          <IonTabButton
            tab="Messages"
            selected={isSelected('Messages')}
            onClick={() => setSelected('Messages')}
          >
            <IonIcon
              icon={isSelected('Messages') ? chatbubbles : chatbubblesOutline}
            />
            <IonLabel>{t('Messages')}</IonLabel>
            {auth && chat && navbar && navbar.unreadMessages > 0 && (
              <IonBadge color="danger">{navbar.unreadMessages}</IonBadge>
            )}
          </IonTabButton>
        {/* </IonTabBar> */}
    </div>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired,
  navbar: PropTypes.object.isRequired,
  updateNavbarWithUnreadMessages: PropTypes.func.isRequired,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  chat: state.chat,
  navbar: state.navbar,
});

export default connect(mapStateToProps, { updateNavbarWithUnreadMessages })(
  Navbar
);
