import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonMenu,
  IonList,
  IonItem,
  IonIcon,
  IonItemOptions,
  IonItemSliding,
  IonItemOption,
} from '@ionic/react';
import { menuController } from '@ionic/core';

import './NotificationMenu.scss';
import { connect } from 'react-redux';
import { notifications, trash } from 'ionicons/icons';
import { formatRelativeTime } from '../../utils/formatDate';
import { deleteNotification, getAllNotifications, markNotificationsAsRead } from '../../actions/notificationmenu/notificationmenu';
import { setShowInfoModal,setShowItemRatingsNotifModal } from '../../actions/layout/navigation';
import { getRooQuestByID,updateRooQuestWithoutCall } from '../../actions/rooquest/rooquest';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { launchToastCard } from '../../actions/layout/toast';


const NotificationItem = ({notifID, title, subtitle, datetime, viewed, rq=null, type, deleteNotification, setShowInfoModal, rooquest, getRooQuestByID,updateRooQuestWithoutCall,launchToastCard,markNotificationsAsRead,getAllNotifications,setShowItemRatingsNotifModal,closeNotifMenu }) => {
    const notifRef = React.createRef<any>();
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    
    const notifClick = async (notifRef, isOpen) => {
          closeNotifMenu();
        if(viewed){
            const notifIDs = [];
            notifIDs.push(notifID);
                markNotificationsAsRead(notifIDs);
        }
        if (rq) {
            if (type === "REQUESTER") {
                console.log('REQUESTER')
               const data = await getRooQuestByID(rq);
               if(data){
                if(data.status == "EXPIRED" || data.is_roo_notification_expired_ten_min == true){
                    history.push(`/editrooquest/${data._id}`)                 
                   } else {
                    setShowInfoModal('MYRQ', true);
                    if(data && !data.requesterRatingLeft){
                        setShowItemRatingsNotifModal(true);
                    }
                   }
               }
            }
            else if(type === "DELIVERER") {
                
                const data = await getRooQuestByID(rq)
                console.log(data.status)
                if(data){
                if(title === 'Your offer has been rejected' && data.status === 'ACTIVE')
                {
                    //if rooquest is still active the deliver guy can still make an offer
                    console.log(data.status)
                    // updateRooQuestWithoutCall(rq);
                    setShowInfoModal('RQ', true);
                    console.log("le titre est ici")
                }
                else if(title === 'Your offer has been rejected' && data.status !== 'ACTIVE')
                {
                    //launch a toast notification if the rooquest has already been accepted or is not active anymore.
                    console.log('ici')
                    launchToastCard({ 
                        msg: 'This rooquest is no longer available. You may try another rooquest.',
                        type: 'info',
                        duration: 8500,
                    })

                }
                else if (title === 'Your offer has been accepted!' && data.status == 'ACTIVE'){
                    launchToastCard({ 
                        msg: 'This rooquest is canceled or expired. You can still ask for new offer to this rooquest',
                        type: 'info',
                        duration: 8500,
                    })
                    setShowInfoModal('RQ', true);
                }
                else{
                    await getRooQuestByID(rq);
                    setShowInfoModal('MYDELIV', true);
                }
            }
            }            
        }
        else if (!isOpen) {
          notifRef.current.open('start');
        } else {
          notifRef.current.close();
        }
        // getAllNotifications();
    };
    return (
        <IonItemSliding ref={notifRef} onClick={() => {
            notifClick(notifRef, isOpen);
            setIsOpen(!isOpen);
        }}>
            <IonItem className={viewed ? "viewed" : ""}>
                <div className="notifContent">
                    <div className="notifIconDiv">
                        <IonIcon className="notifIcon" icon={notifications} />
                    </div>
                    <div className="notifDetails">
                        <p className="notif_title">{title}</p>
                        <p className="notif_rq">{subtitle}</p>
                        <p className="notif_datetime">{datetime}</p> 
                    </div>                   
                </div>
                       
            </IonItem>
            <IonItemOptions side="start">
              <IonItemOption onClick={() => deleteNotification(notifID)} color="danger"><IonIcon slot="icon-only" icon={trash} /></IonItemOption>
            </IonItemOptions>
        </IonItemSliding>
    )
}


const NotificationMenu = ({ 
    navigation, 
    notificationmenu, 
    deleteNotification, 
    markNotificationsAsRead, 
    getAllNotifications, 
    setShowInfoModal,
    rooquest,
    getRooQuestByID,
    updateRooQuestWithoutCall,
    launchToastCard,
    setShowItemRatingsNotifModal
}) => {
    const menuRef = useRef(null);
    const { t } = useTranslation();
    
    const closeNotifMenu = () => {
        menuRef.current.close();
    }
    return (
        <div className="NotificationMenu">
        <IonMenu
            ref={menuRef}
            contentId="notificationMenuDiv"
            class="notificationMenuDiv"
            menuId="notificationMenu"
            side="end"
            type="overlay"
        >
            <IonHeader>
            <IonToolbar color="primary"> 
                <div className="title">
                    <h1>{t('Notifications')}</h1>
                </div>          
            </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonList lines="full">
                    {(notificationmenu && notificationmenu.notifications.length > 0) ? 
                    (<div className="notifications">
                        {notificationmenu.notifications.map((notif) => 
                            <NotificationItem 
                                key={notif._id} 
                                notifID={notif._id} 
                                title={`${t(notif.title)}`} 
                                subtitle={notif.subtitle} 
                                datetime={formatRelativeTime(notif.date)} 
                                viewed={!notif.viewed} 
                                rq={notif.rq} 
                                type={notif.type} 
                                deleteNotification={deleteNotification} 
                                markNotificationsAsRead={markNotificationsAsRead}
                                setShowInfoModal={setShowInfoModal}
                                rooquest={rooquest}
                                getRooQuestByID={getRooQuestByID}
                                updateRooQuestWithoutCall = {updateRooQuestWithoutCall}
                                launchToastCard = {launchToastCard}
                                getAllNotifications = {getAllNotifications}
                                setShowItemRatingsNotifModal = {setShowItemRatingsNotifModal}
                                closeNotifMenu={closeNotifMenu}
                            />
                        )}
                    </div>) :
                    <div className="no_notifications">
                        <p>No New Notifications</p>
                    </div> }             
                </IonList>            
            </IonContent>
        
        </IonMenu>
        </div>
    )
}

NotificationMenu.propTypes = {
    navigation: PropTypes.object.isRequired,
    notificationmenu: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    deleteNotification: PropTypes.func.isRequired,
    markNotificationsAsRead: PropTypes.func.isRequired,
    getAllNotifications: PropTypes.func.isRequired,
    setShowInfoModal: PropTypes.func.isRequired,
    setShowItemRatingsNotifModal: PropTypes.func.isRequired,
    rooquest: PropTypes.object.isRequired,
    getRooQuestByID: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    navigation: state.navigation,
    notificationmenu: state.notificationmenu,
    rooquest: state.rooquest,
  });

export default connect(mapStateToProps, { deleteNotification, markNotificationsAsRead, getAllNotifications, setShowInfoModal,setShowItemRatingsNotifModal, getRooQuestByID,updateRooQuestWithoutCall,launchToastCard })(NotificationMenu)