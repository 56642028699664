import React, { useEffect, useState } from 'react';
import './MyDeliveriesPopover.scss';
import { IonSegment, IonSegmentButton, IonLabel, IonIcon, IonItem, IonSelectOption, IonSelect } from '@ionic/react';
import PopoverCard from '../../../components/layout/PopoverCard/PopoverCard';
import { connect } from 'react-redux';
import {
  getMyDeliveries,
  updateRooQuestWithoutCall,
} from '../../../actions/rooquest/rooquest';
import getStatusTextAndColour from '../../../utils/getStatusTextAndColour';
import RQCard from '../../../components/layout/RQCard/RQCard';
import MyRQCardTop from '../../../components/layout/RQCard/MyRooQuests/MyRQCardTop';
import { setShowInfoModal } from '../../../actions/layout/navigation';
import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ExpiryCountdown } from '../MyRooQuestsPopover/MyRooQuestsPopover';
import { time } from 'ionicons/icons';


const MyDeliveriesPopover = ({
  rooquest,
  getMyDeliveries,
  auth,
  setShowInfoModal,
  updateRooQuestWithoutCall,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    getMyDeliveries();
  }, [getMyDeliveries]);

  const [segmentState, setSegmentState] = useState('none');

  let mydeliveries = rooquest.mydeliveries;

  // Filters
  if (Array.isArray(rooquest.mydeliveries)) {
    if (segmentState === 'none') {
      mydeliveries = mydeliveries.sort((a,b)=>{
        return +new Date(b.acceptedDate) - +new Date(a.acceptedDate)
      }
      )
    }
    else if(segmentState === 'expired')
    {
      mydeliveries = mydeliveries.filter(
          (rq) => (rq.status === 'EXPIRED')
        );
    }
    else if(segmentState === 'active')
    {
      mydeliveries = mydeliveries.filter(
        (rq) => (rq.status === 'ACTIVE')
      );
    }
    else if(segmentState === 'accepted')
    {
      mydeliveries = mydeliveries.filter(
        (rq) => (rq.status === 'RQ_ACCEPTED')
      );
    }
    else if(segmentState === 'delivered')
    {
      mydeliveries = mydeliveries.filter(
        (rq) => (rq.status === 'ROOQUEST_DELIVERED')
      );
    }
    else if(segmentState === 'on the way')
    {
      mydeliveries = mydeliveries.filter(
        (rq) => (rq.status === 'RQ_ONTHEWAY')
      );
    }
    else if(segmentState === 'inactive')
    {
      mydeliveries = mydeliveries.filter(
        (rq) => (rq.status === 'INACTIVE')
      );
    }
    else if(segmentState === 'waiting')
    {
      mydeliveries = mydeliveries.filter(
        (rq) => (rq.status === 'ROOQUEST_WAITING_CLIENT_CONFIRMATION')
      );
    }
    else if(segmentState === 'under review')
    {
      mydeliveries = mydeliveries.filter(
        (rq) => (rq.status === 'UNDER_REVIEW')
      );
    }
    else if(segmentState === 'pending')
    {
      mydeliveries = mydeliveries.filter(
        (rq) => (rq.status === 'PENDING_PAYMENT')
      );
    }
  }
  const options = {
    cssClass: 'my-custom-interface'
  };
  // const history = useHistory();
  // const goToDeliverNavMap = () => {
  //     history.push('/deliverNavMap')
  // }
  return (
    <div className="MyDeliveriesPopover">
      <PopoverCard title={t('My Deliveries')}>
        <div className="segmentContainer">
          {/* <IonSegment
            mode="ios"
            value={segmentState}
            onIonChange={(e) => setSegmentState(e.detail.value!)}
          >
            <IonSegmentButton value="active" id="activeBtn">
              <IonLabel>{t('Active')}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="history">
              <IonLabel>{t('History')}</IonLabel>
            </IonSegmentButton>
          </IonSegment> */}
        </div>
        <div className='filter'>
        <IonItem>
          <IonLabel>{t('Sort By')}</IonLabel>
              <IonSelect interfaceOptions={options} value={segmentState} onIonChange={(e) => setSegmentState(e.detail.value)}>
              <IonSelectOption value="none">{t('Date Accepted')}</IonSelectOption>
              <IonSelectOption value="expired">{t('Expired Rooquests')}</IonSelectOption>
              {/* <IonSelectOption value="active">Active Rooquests</IonSelectOption> */}
              <IonSelectOption value="on the way">{t('On the way')}</IonSelectOption>
              <IonSelectOption value="delivered">{t('Rooquests Delivered')}</IonSelectOption>
              <IonSelectOption value="under review">{t('Under Review')}</IonSelectOption>
              <IonSelectOption value="waiting">{t('Pending Offers')}</IonSelectOption>
              {/* <IonSelectOption value="inactive">INACTIVE Rooquests</IonSelectOption>
              <IonSelectOption value="pending">PENDING_PAYMENT</IonSelectOption> */}
              </IonSelect>
        </IonItem>
        </div>
        <div className="bodyContent">
          <div className="rqCards">
            {mydeliveries &&
              mydeliveries.length > 0 &&
              mydeliveries.map((rq) => {
                const statusObj = getStatusTextAndColour(
                  rq.status,
                  rq.deliverer === auth.user._id,
                  'mydeliveries',
                  auth.user.color_theme
                );
                return (
                  <RQCard
                    rooquest={rq}
                    key={`myDeliv_active_${rq._id}`}
                    extraStyling={statusObj.borderStyle}
                    onClickFxn={() => {
                      updateRooQuestWithoutCall(rq);
                      if(rq.status === 'ACTIVE' && rq.offers.length > 0 && rq.potentialDeliverers && rq.potentialDeliverers.includes(rq._id))
                      {
                        setShowInfoModal('RQ', true);
                      }
                      else{
                        setShowInfoModal('MYDELIV', true);
                      }
                    }}
                  >
                    <MyRQCardTop rooquest={rq} statusObj={statusObj} isInmyDelivPopover = {true}/>
                    <div className="additionalInfoContainer">
                        {/* ACTIVE */}
                        {rq && rq.status !== 'EXPIRED' && rq.expiryDate && (
                            <div className="additionalInfo">
                              <IonIcon
                                icon={time}
                                style={{ color: statusObj.colour.color }}
                              />
                              <p>
                                <span>{t('Expires in')}: </span>
                                <ExpiryCountdown rooquest={rq} />
                              </p>
                            </div>
                        )}
                      </div>
                  </RQCard>
                );
              })}
            {!rooquest.loading &&
              mydeliveries &&
              (mydeliveries.length === 0 ||
                !Array.isArray(rooquest.mydeliveries)) && (
                <img
                  className="noRQs"
                  src={require('../NoRooQuestsToShow.png')}
                  alt="no rqs"
                />
              )}
          </div>
        </div>
      </PopoverCard>
    </div>
  );
};

MyDeliveriesPopover.propTypes = {
  setShowInfoModal: PropTypes.func.isRequired,
  rooquest: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  rooquest: state.rooquest,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getMyDeliveries,
  setShowInfoModal,
  updateRooQuestWithoutCall,
})(MyDeliveriesPopover);
