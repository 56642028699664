import axios from "axios";
import { backendAddress } from "../../App";
import store from "../../store";
import { loadUser } from "../auth/auth";
import { SET_SHOW_NOTIFICATION_RANGE_MODAL } from "../layout/navigation-types";
import { launchToastCard } from "../layout/toast";
import { SET_NOTIFICATION_RANGE } from "./notificationrange-types";

// Editing notification Range
export const updateNotificationRange = (radius,userID) => async (dispatch) => {
    try {
      const body = {
        radius : radius
      } 
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.put(backendAddress + '/api/users/settings/updatenotificationrange/'+userID ,
                  JSON.stringify(body),
                  config);
      if (res.data) {
        dispatch(loadUser());
          dispatch({
              type: SET_NOTIFICATION_RANGE,
              payload: radius
          });
          dispatch({
            type : SET_SHOW_NOTIFICATION_RANGE_MODAL,
            payload : false
          });
          dispatch(launchToastCard({ 
            msg: 'Distance updated successfully',
            type: 'success',
            duration: 8500,
           }))
      }
      
    } catch (error) {
      console.error(error)
    }
};

export const updateUserLocation = (lat,lng,coords)=> async (dispatch) => {
  try {
    const userID=store.getState().auth.user._id;
    let obj = null
     
    const body = { 
      lat: lat,
      lng:lng,
      coords: obj,
    }

    if(coords && coords.timestamp)
    {
      body['timestamp'] = coords.timestamp
    }
    if(coords.coords)
    {
        obj ={
          accuracy:coords.coords.accuracy,
          altitude:coords.coords.altitude,
          altitudeAccuracy:coords.coords.altitudeAccuracy,
          heading: coords.coords.heading,
          speed: coords.coords.speed
        }
      body.coords = obj
    }
    console.log("update current position ")
    console.log(body)
    
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.put(backendAddress + '/api/users/settings/updateUserLocation/'+userID ,
                JSON.stringify(body),
                config);
    if (res.data) {
      console.log("ok")
    }
    
  } catch (error) {
    console.error(error)
  }
}

export const setNotificationRange = (data) => (dispatch) => {
    try {
        if (data) {
            dispatch({
                type: SET_NOTIFICATION_RANGE,
                payload: data
            })
        }
      } catch (error) {
        console.error(error)
      }
}

