export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const EMAIL_LOGIN_SUCCESS = 'EMAIL_LOGIN_SUCCESS';
export const EMAIL_LOGIN_FAIL = 'EMAIL_LOGIN_FAIL';

export const SOCIAL_LOGIN_SUCCESS = 'SOCIAL_LOGIN_SUCCESS';
export const SOCIAL_LOGIN_FAIL = 'SOCIAL_LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';
