import React, { Fragment, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IonButton,
  IonSlides,
  IonSlide,
  IonInput,
  IonItem,
  IonLabel,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonDatetime,
} from '@ionic/react';

import './PostRQModal.scss';
import FullPageModalCard from '../layout/Modal/FullPageModalCard';
import GreenModal from '../layout/Modal/GreenModal';

/// Image Upload
import { Camera, CameraResultType } from '@capacitor/camera';
import { uploadRooQuestImage } from '../../actions/firebase/firebase-storage';
// import GoogleAddressAutoComplete from '../settings/GoogleAddressAutoComplete/GoogleAddressAutoComplete';
import { connect, useDispatch } from 'react-redux';
import PeekingModal from '../layout/PeekingModal/PeekingModal';
import { GET_KARGOROO_FEE, GET_LOADED_KARGOROO_FEE } from '../../firebase';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { launchToast,launchToastCard } from '../../actions/layout/toast';
import { payForRooQuest_Stripe } from '../../actions/payments/payments';
import { postNewRooQuest } from '../../actions/rooquest/rooquest';
import {
  setShowPostRQModal,
  setShowMapModal,
} from '../../actions/layout/navigation';

const PostRQModal = ({
  auth,
  rooquest,
  navigation,
  payForRooQuest_Stripe,
  postNewRooQuest,
  getAllRooQuests,
  setShowPostRQModal,
  setShowMapModal,
}) => {
  // Redux States
  const { showPostRQModal } = navigation;

  // Input Fields

  const formDataDefaultState = {
    title: '',
    description: '',
    image: null,
    imgURL: '',
    pickupLocationType: 'address',
    pickupLocation: null,
    pickupAddress: null,
    deliveryLocation: null,
    deliveryAddress: null,
    deliveryTime: null,
    additionalNote: '',
    costOfGoods: '0.00',
    costOfDeliverer: '5.00',
    requester: '',
    requesterFirstName: '',
    status: 'PENDING_PAYMENT',
  };
  const [formData, setFormData] = useState(formDataDefaultState);
  let {
    title,
    description,
    image,
    pickupLocationType,
    pickupLocation,
    deliveryLocation,

    additionalNote,
    costOfGoods,
    costOfDeliverer,
  } = formData;

  const defaultPickupAddressState = {
    streetAddress: '',
    city: '',
    postal: '',
    province: '',
    country: '',
    lat: '',
    lng: '',
  };
  const [pickupAddress, setPickupAddress] = useState(defaultPickupAddressState);

  const [preProcessedDeliveryTime, setPreProcessedDeliveryTime] = useState({
    date: null,
    time: null,
  });

  let imageHolder;

  const [formComplete, setFormComplete] = useState(false);
  const [showEndSlide, setShowEndSlide] = useState(false);

  // Get KargoROO Fee from Firebase Remote Config
  let KARGOROO_FEE = GET_LOADED_KARGOROO_FEE();
  const getKargoROOFee = async () => {
    KARGOROO_FEE = await GET_KARGOROO_FEE();
  };

  // Error States
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [pickupLocationError, setPickupLocationError] = useState(false);
  const [deliveryLocationError, setDeliveryLocationError] = useState(false);
  const [deliveryTimeError, setDeliveryTimeError] = useState(false);
  const [currentLocationUser, setCurrentLocationUser] = useState(false);

  // Form Changes
  const onFormChange = (e) => {
    if (e.target.name === 'title') setTitleError(false);
    if (e.target.name === 'description') setDescriptionError(false);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const onDeliveryAddressChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === 'ADD_NEW_ADDRESS') {
      setShowAddressModal(true);
    } else if (e.target.value === 'CURRENT_LOCATION') {
      setShowMapModal(true);
      setCurrentLocationUser(true);
    } else {
      setFormData({ ...formData, deliveryLocation: e.target.value });
    }
  };

  const [showAddressModal, setShowAddressModal] = useState(false);

  const updateCurrentLocationuser = () => {
    const setDeliveryLoc = {
      addressName: 'Current Location',
      streetAddress: 'Undefined',
      city: 'Undefined',
      postal: 'Undefined',
      province: 'Undefined',
      country: 'Undefined',
      lat: navigation.showMapModal.coords.lat(),
      lng: navigation.showMapModal.coords.lng(),
    };
    setFormData({
      ...formData,
      deliveryLocation: setDeliveryLoc,
    });
    setFormData({ ...formData, deliveryLocation: setDeliveryLoc });

    formData.deliveryLocation = setDeliveryLoc;
    deliveryLocation = setDeliveryLoc;
    console.log(currentLocationUser);
    setShowMapModal(null, null);
  };

  useEffect(() => {
    if (navigation.showMapModal.coords !== null) {
      updateCurrentLocationuser();
    }
    // eslint-disable-next-line
  }, [navigation.showMapModal.coords]);

  // Validate Before Next Slide
  const validateThenMove = async () => {
    const slideIndex = await slidesRef.current.getActiveIndex();
    switch (slideIndex) {
      case 0:
        if (
          title &&
          title.length >= 5 &&
          description &&
          description.length >= 5
        ) {
          await slidesRef.current.lockSwipes(false);
          await slidesRef.current.slideNext();
          await slidesRef.current.lockSwipes(true);
        } else {
          if (title && title.length < 5) setTitleError(true);
          if (description && description.length < 5) setDescriptionError(true);
        }
        setFormComplete(() => false);
        break;
      case 1:
        if (
          pickupLocationType &&
          (pickupLocation !== null ||
            JSON.stringify(pickupAddress) !==
              JSON.stringify(defaultPickupAddressState) ||
            pickupLocationType === 'any') &&
          deliveryLocation &&
          preProcessedDeliveryTime.date &&
          preProcessedDeliveryTime.time
        ) {
          const datetime = processDateTime();

          if (datetime) {
            setFormData((formData) => ({
              ...formData,
              deliveryTime: datetime,
            }));
          }

          if (pickupLocationType === 'address' && pickupAddress) {
            setFormData((formData) => ({
              ...formData,
              pickupAddress: pickupAddress,
            }));
          }

          setFormComplete(() => false);

          await slidesRef.current.lockSwipes(false);
          await slidesRef.current.slideNext();
          await slidesRef.current.lockSwipes(true);
        } else {
          if (
            !(
              pickupLocationType &&
              (pickupLocation !== null ||
                JSON.stringify(pickupAddress) !==
                  JSON.stringify(defaultPickupAddressState) ||
                pickupLocationType === 'any')
            )
          ) {
            setPickupLocationError(true);
          }
          if (!deliveryLocation) setDeliveryLocationError(true);
          if (!(preProcessedDeliveryTime.date && preProcessedDeliveryTime.time))
            setDeliveryTimeError(true);
        }
        break;
      case 2:
        if (!costOfGoods) setFormData({ ...formData, costOfGoods: '0.00' });
        if (!costOfDeliverer)
          setFormData({ ...formData, costOfDeliverer: '0.00' });
        setFormComplete(() => true);

        setFormData((formData) => ({
          ...formData,
          requester: auth.user._id,
          requesterFirstName: auth.user.firstName,
          deliveryAddress: deliveryLocation,
        }));

        if (image) {
          const imgURL = await uploadRooQuestImage(image);
          if (imgURL)
            setFormData((formData) => ({ ...formData, imgURL: imgURL }));
        }

        await slidesRef.current.lockSwipes(false);
        await slidesRef.current.slideNext();
        await slidesRef.current.lockSwipes(true);
        break;
      case 3:
        // Post / Save RooQuest
        const newRQ = await postNewRooQuest(formData);

        if (newRQ) {
          // window.location.href = `/feed`;
          // window.location.href = `/create-rooquest/payment/${newRQ._id}`;
          // setShowPaymentModal(true);
          await slidesRef.current.lockSwipes(false);
          await slidesRef.current.slideNext();
          await slidesRef.current.lockSwipes(true);
        } else {
          // dispatch(launchToast('RooQuest Error. Please try again.'));
          dispatch(launchToastCard({ 
            msg: 'RooQuest Error. Please try again.',
            type: 'error',
            duration: 8500,
        }))
        }
        setShowMapModal(false, null);
        setCurrentLocationUser(false);
        break;

      case 4:
        await payForRooQuest();
        await slidesRef.current.update();
        await slidesRef.current.lockSwipes(false);
        await slidesRef.current.slideNext();
        await slidesRef.current.lockSwipes(true);
        resetData();
        break;
      default:
        await slidesRef.current.lockSwipes(false);
        await slidesRef.current.slideNext();
        await slidesRef.current.lockSwipes(true);
        resetData();
    }
  };

  const goBack = async () => {
    await slidesRef.current.lockSwipes(false);
    await slidesRef.current.slidePrev();
    await slidesRef.current.lockSwipes(true);
  };

  const resetData = () => {
    setShowMapModal(false, null);
    setCurrentLocationUser(false);
  };

  // Slide Settings
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };
  const slidesRef = useRef(null);

  // Choose Photo Settings

  const imageRef = useRef(null);

  const getImage = async () => {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      height: 500,
    });
    imageRef.current.src = image.webPath;

    (await fetch(image.webPath)).blob().then((blobImage) => {
      imageHolder = image.webPath;
      setFormData({ ...formData, image: blobImage });
    });
  };

  // Process Date Time Picker Values
  const processDateTime = () => {
    return new Date(
      `${preProcessedDeliveryTime.date.split('T')[0]}T${
        preProcessedDeliveryTime.time.split('T')[1]
      }`
    ).toISOString();
  };

  // Calculate Cost of RooQuest
  const calculateCost = () => {
    if (!costOfGoods && !costOfDeliverer) return KARGOROO_FEE;
    if (!costOfGoods) return parseFloat(costOfDeliverer) + KARGOROO_FEE;
    if (!costOfDeliverer) return parseFloat(costOfGoods) + KARGOROO_FEE;
    return parseFloat(costOfGoods) + parseFloat(costOfDeliverer) + KARGOROO_FEE;
  };

  // Payment Components
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#012e0b',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '19px',
        '::placeholder': {
          color: '#7e858c',
        },
      },
      invalid: {
        color: '#f2311f',
        iconColor: '#f2311f',
      },
    },
    hidePostalCode: true,
  };
  const [status, setStatus] = useState('ready');
  const [errors, setErrors] = useState([]);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const payForRooQuest = async () => {
    setStatus('loading');
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        address: {
          line1: deliveryLocation.streetAddress,
          city: deliveryLocation.city,
          postal_code: deliveryLocation.postal,
          state: deliveryLocation.province,
        },
      },
    });

    if (error) {
      setStatus('ready');
      // dispatch(launchToast(error.message, 3500, 'bottom'));
      dispatch(launchToastCard({ 
        msg: error.message,
        type: 'error',
        duration: 8500,
    }))
    } else {
      const { id } = paymentMethod;

      const getStripePaymentAmount = () => {
        return (
          (parseFloat(costOfGoods) +
            parseFloat(costOfDeliverer) +
            KARGOROO_FEE) *
          100
        );
      };

      const res = await payForRooQuest_Stripe(
        rooquest.rooquest,
        id,
        getStripePaymentAmount(),
        KARGOROO_FEE
      );

      if (res.type === 'success') {
        setStatus('success');
        setShowEndSlide(() => true);
      } else {
        setErrors([
          {
            type: res.type,
            msg: res.msg,
          },
        ]);
        setStatus('error');
        setShowEndSlide(() => true);
      }
    }
  };

  return (
    <Fragment>
      <GreenModal
        title="Post a RooQuest"
        showModal={showPostRQModal}
        setShowModal={setShowPostRQModal}
        className="postRQModal"
        backBtnOnClickFxn={() => {
          setShowPostRQModal(false);
          resetData();
        }}
        onWillDismiss={() => {
          setFormData(formDataDefaultState);
          setPickupAddress(defaultPickupAddressState);
          setShowPostRQModal(false);
          resetData();
        }}
        onWillPresent={async () => {
          await slidesRef.current.update();
          await getKargoROOFee();
        }}
      >
        <div className="postRQModalContent">
          <IonSlides
            ref={slidesRef}
            pager={true}
            options={slideOpts}
            onIonSlidesDidLoad={() => slidesRef.current.lockSwipes(true)}
          >
            {/* SLIDE 1 -----------------------------------------------------------------------------*/}
            <IonSlide>
              <FullPageModalCard title="RooQuest Details">
                <div className="cardBodyContent">
                  <IonItem className="titleInput inputField" mode="ios">
                    <IonLabel position="stacked">
                      Title of RooQuest: <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    {titleError && (
                      <p className="formErrorMsg">
                        Please enter 5 or more characters
                      </p>
                    )}
                    <IonInput
                      name="title"
                      placeholder="Title"
                      maxlength={80}
                      value={title}
                      onIonChange={(e) => onFormChange(e)}
                    ></IonInput>
                  </IonItem>
                  <IonItem className="descriptionInput inputField">
                    <IonLabel position="stacked">
                      Description of RooQuest:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    {descriptionError && (
                      <p className="formErrorMsg">
                        Please enter 5 or more characters
                      </p>
                    )}
                    <IonTextarea
                      name="description"
                      placeholder="Describe Your RooQuest"
                      maxlength={1500}
                      rows={4}
                      // autoGrow={true}
                      mode="md"
                      value={description}
                      onIonChange={(e) => onFormChange(e)}
                    ></IonTextarea>
                  </IonItem>
                  <IonItem className="descriptionInput inputField" lines="none">
                    <IonLabel position="stacked">
                      Upload Image of RooQuest:{' '}
                    </IonLabel>
                    <img
                      ref={imageRef}
                      className="uploadedImage"
                      src={require('./ImageUploadOptional.png')}
                      alt=""
                    />
                    {image ? (
                      <Fragment>
                        <IonButton
                          className="imageUploadBtn"
                          size="small"
                          onClick={() => getImage()}
                        >
                          Change Image
                        </IonButton>
                      </Fragment>
                    ) : (
                      <IonButton
                        className="imageUploadBtn"
                        onClick={() => getImage()}
                      >
                        Choose Image
                      </IonButton>
                    )}
                  </IonItem>
                </div>
                <div className="cardFooter">
                  <div className="navButtons">
                    <IonButton
                      className="backBtn"
                      fill="clear"
                      onClick={() => {
                        setShowPostRQModal(false);
                        resetData();
                      }}
                    >
                      Close
                    </IonButton>
                    <IonButton
                      className="nextBtn"
                      onClick={async () => await validateThenMove()}
                    >
                      Continue
                    </IonButton>
                  </div>
                </div>
              </FullPageModalCard>
            </IonSlide>

            {/* SLIDE 2 -----------------------------------------------------------------------------*/}
            <IonSlide>
              <FullPageModalCard title="Delivery Details">
                <div className="cardBodyContent">
                  <IonItem className="inputField" mode="ios">
                    <IonLabel position="stacked">
                      Pickup RooQuest From:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    {pickupLocationError && (
                      <p className="formErrorMsg">
                        Please enter Pickup Location Type and Pickup Location
                      </p>
                    )}
                    <IonSelect
                      id="pickLocationTypeSelector"
                      // css-class="pickLocationTypeSelector"
                      name="pickupLocationType"
                      value={pickupLocationType}
                      okText="Select"
                      cancelText="Close"
                      mode="ios"
                      interface="alert"
                      interfaceOptions={{
                        header: 'Pickup Location Type',
                        subHeader: 'Select the most appropriate',
                        class: 'pickLocationTypeSelector',
                      }}
                      onIonChange={(e) => {
                        setFormData({
                          ...formData,
                          pickupLocationType: e.detail.value,
                          pickupLocation: null,
                        });
                        setPickupAddress(defaultPickupAddressState);
                        setPickupLocationError(false);
                      }}
                    >
                      <IonSelectOption value="address">
                        Specific Address (ie. 123 Street)
                      </IonSelectOption>
                      <IonSelectOption value="store">
                        Specific Store (ie. Walmart)
                      </IonSelectOption>
                      <IonSelectOption value="any">
                        Any Location that Contains RooQuest
                      </IonSelectOption>
                      <IonSelectOption value="other">Other</IonSelectOption>
                    </IonSelect>
                  </IonItem>

                  {/* {pickupLocationType === 'address' && (
                    <Fragment>
                      <GoogleAddressAutoComplete
                        address={pickupAddress}
                        setAddress={setPickupAddress}
                        setAddressError={setPickupLocationError}
                      />
                    </Fragment>
                  )} */}

                  {pickupLocationType === 'store' && (
                    <IonItem className="inputField" mode="ios">
                      <IonInput
                        name="pickupLocation"
                        placeholder="Enter Store Name..."
                        value={pickupLocation}
                        onIonChange={(e) => {
                          onFormChange(e);
                          setPickupLocationError(false);
                        }}
                      ></IonInput>
                    </IonItem>
                  )}
                  {pickupLocationType === 'other' && (
                    <IonItem className="inputField" mode="ios">
                      <IonTextarea
                        name="pickupLocation"
                        placeholder="Enter Pickup Information..."
                        value={pickupLocation}
                        rows={3}
                        // autoGrow={true}
                        onIonChange={(e) => {
                          onFormChange(e);
                          setPickupLocationError(false);
                        }}
                      ></IonTextarea>
                    </IonItem>
                  )}
                  <IonItem className="inputField" mode="ios">
                    <IonLabel position="stacked">
                      Deliver RooQuest To:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    {deliveryLocationError && (
                      <p className="formErrorMsg">
                        Please enter location to deliver RooQuest
                      </p>
                    )}

                    <IonSelect
                      id="pickLocationTypeSelector"
                      name="deliveryLocation"
                      value={deliveryLocation}
                      mode="ios"
                      interface="alert"
                      interfaceOptions={{
                        header: 'Deliver RooQuest to',
                        subHeader: 'Select address or add a new one',
                        class: 'deliverToSelector',
                        buttons: ['Cancel', 'Open Modal', 'Delete'],
                      }}
                      onIonChange={(e) => {
                        setDeliveryLocationError(false);
                        onDeliveryAddressChange(e);
                      }}
                    >
                      {auth.user &&
                        auth.user.deliveryAddresses.map((address) => (
                          <IonSelectOption key={address._id} value={address}>
                            {address.addressName} ({address.streetAddress})
                          </IonSelectOption>
                        ))}

                      {auth.user &&
                        deliveryLocation &&
                        deliveryLocation.addressName === 'Current Location' && (
                          <IonSelectOption
                            // key={`${formData.deliveryLocation.lat}-${formData.deliveryLocation.lng}`}
                            key={`test`}
                            value={deliveryLocation}
                          >
                            {`${parseFloat(deliveryLocation.lat).toFixed(
                              4
                            )}, ${parseFloat(deliveryLocation.lng).toFixed(4)}`}
                            {console.log(deliveryLocation)}
                          </IonSelectOption>
                        )}
                      <IonSelectOption
                        class="NEWADDRESS_SELECTOPTION"
                        value="ADD_NEW_ADDRESS"
                      >
                        Add New Address
                      </IonSelectOption>
                      <IonSelectOption
                        class="NEWADDRESS_SELECTOPTION"
                        value="CURRENT_LOCATION"
                      >
                        Use Current Location
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <PeekingModal
                    title="Add New Address"
                    showPeekingModal={showAddressModal}
                    setShowPeekingModal={setShowAddressModal}
                    formData={formData}
                    setFormData={setFormData}
                  ></PeekingModal>

                  <IonItem className="inputField" mode="ios">
                    <IonLabel position="stacked">
                      Preferred Delivery Time:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    {deliveryTimeError && (
                      <p className="formErrorMsg">
                        Please enter valid time to deliver RooQuest
                      </p>
                    )}
                    <IonDatetime
                      placeholder="Select Date"
                      // displayFormat="DDDD MMMM D YYYY"
                      // pickerFormat="MMMM D YYYY"
                      min={new Date().toISOString()}
                      max={new Date(
                        (new Date().getFullYear() + 2).toString()
                      ).toISOString()}
                      value={preProcessedDeliveryTime.date}
                      onIonChange={(e) => {
                        e.preventDefault();
                        setDeliveryTimeError(false);
                        setPreProcessedDeliveryTime({
                          ...preProcessedDeliveryTime,
                          date: e.detail.value!,
                        });
                      }}
                    ></IonDatetime>
                    <IonDatetime
                      placeholder="Select Time"
                      // displayFormat="h:mm a"
                      minuteValues="0,15,30,45"
                      value={preProcessedDeliveryTime.time}
                      onIonChange={(e) => {
                        e.preventDefault();
                        setDeliveryTimeError(false);
                        setPreProcessedDeliveryTime({
                          ...preProcessedDeliveryTime,
                          time: e.detail.value!,
                        });
                      }}
                    ></IonDatetime>
                  </IonItem>
                  <IonItem className="inputField" mode="ios">
                    <IonLabel position="stacked">
                      Additional Notes to Deliverer:
                    </IonLabel>
                    <IonTextarea
                      name="additionalNote"
                      placeholder="Enter Additonal Information..."
                      value={additionalNote}
                      rows={3}
                      // autoGrow={true}
                      onIonChange={(e) => onFormChange(e)}
                    ></IonTextarea>
                  </IonItem>
                </div>
                <div className="cardFooter">
                  {' '}
                  <div className="navButtons">
                    <IonButton
                      className="backBtn"
                      fill="clear"
                      onClick={() => goBack()}
                    >
                      Go Back
                    </IonButton>
                    <IonButton
                      className="nextBtn"
                      onClick={async () => await validateThenMove()}
                    >
                      Continue
                    </IonButton>
                  </div>
                </div>
              </FullPageModalCard>
            </IonSlide>

            {/* SLIDE 3 -----------------------------------------------------------------------------*/}
            <IonSlide>
              <FullPageModalCard title="Payment Details">
                <div className="cardBodyContent ">
                  <IonItem className="inputField" mode="ios">
                    <IonLabel position="stacked">
                      Estimated Cost of Goods:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    <IonInput
                      name="costOfGoods"
                      placeholder="Enter Cost..."
                      type="text"
                      inputMode="decimal"
                      value={costOfGoods}
                      onIonChange={(e) => {
                        onFormChange(e);
                        // setPickupLocationError(false);
                      }}
                    ></IonInput>
                  </IonItem>
                  <IonItem className="inputField" mode="ios">
                    <IonLabel position="stacked">
                      Price willing to pay Deliverer:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                    <IonInput
                      name="costOfDeliverer"
                      placeholder="Enter Price..."
                      type="text"
                      inputMode="decimal"
                      value={costOfDeliverer}
                      onIonChange={(e) => {
                        setFormData({
                          ...formData,
                          costOfDeliverer: e.detail.value!,
                        });
                        // onFormChange(e);
                        // setPickupLocationError(false);
                      }}
                    ></IonInput>
                  </IonItem>
                  <IonItem className="inputField payment" mode="ios">
                    <IonLabel position="stacked">Summary:</IonLabel>
                    <div className="summaryContainer">
                      <div className="slide3 paymentDetails">
                        <div className="paymentItemLabels">
                          <h5 className="paymentItem">Cost of Item(s):</h5>
                          <h5 className="paymentItem">Delivery Driver Fee:</h5>
                          <h5 className="paymentItem">KargoROO Fee:</h5>
                          <h5 className="paymentItem total">Total:</h5>
                        </div>

                        <div className="paymentItemPrices">
                          <h5 className="paymentItem">
                            $
                            {costOfGoods
                              ? parseFloat(costOfGoods).toFixed(2)
                              : (0).toFixed(2)}{' '}
                            CAD
                          </h5>
                          <h5 className="paymentItem">
                            $
                            {costOfDeliverer
                              ? parseFloat(costOfDeliverer).toFixed(2)
                              : (0).toFixed(2)}{' '}
                            CAD
                          </h5>
                          <h5 className="paymentItem">
                            ${KARGOROO_FEE.toFixed(2)} CAD
                          </h5>
                          <h5 className="paymentItem total">
                            ${calculateCost().toFixed(2)} CAD
                          </h5>
                        </div>
                      </div>
                    </div>
                  </IonItem>
                </div>

                <div className="cardFooter">
                  {' '}
                  <div className="navButtons">
                    <IonButton
                      className="backBtn"
                      fill="clear"
                      onClick={() => goBack()}
                    >
                      Go Back
                    </IonButton>
                    <IonButton
                      className="nextBtn"
                      onClick={async () => await validateThenMove()}
                    >
                      Continue
                    </IonButton>
                  </div>
                </div>
              </FullPageModalCard>
            </IonSlide>

            {/* SLIDE 4 -----------------------------------------------------------------------------*/}
            <IonSlide>
              <FullPageModalCard title="Confirm Details">
                {formComplete && (
                  <div className="cardBodyContent">
                    <div className="confirmationSlide">
                      <div className="titleContainer">
                        <img
                          id="title-rqImg"
                          src={
                            image && imageHolder
                              ? imageHolder
                              : require('../feed/NoImageAvailable.png')
                          }
                          alt=""
                        />
                        <h5 className="rqTitle">{title}</h5>
                      </div>
                      <h5 className="rqinfo">
                        <span>Description: </span>
                        <br />
                        {description}
                      </h5>
                      <h5 className="rqinfo">
                        <span>Pickup Location Type: </span>
                        {pickupLocationType}
                      </h5>
                      {pickupLocationType === 'address' && (
                        <h5 className="rqinfo">
                          <span>Pickup Location: </span>
                          {pickupAddress.streetAddress}, {pickupAddress.city},{' '}
                          {pickupAddress.postal}, {pickupAddress.province},{' '}
                          {pickupAddress.country}
                        </h5>
                      )}

                      {(pickupLocationType === 'store' ||
                        pickupLocationType === 'other') && (
                        <h5 className="rqinfo">
                          <span>Pickup Location: </span>
                          {pickupLocation}
                        </h5>
                      )}
                      {deliveryLocation &&
                        deliveryLocation.streetAddress !== 'Undefined' && (
                          <h5 className="rqinfo">
                            <span>Delivery Location: </span>
                            {deliveryLocation?.streetAddress},{' '}
                            {deliveryLocation?.city}, {deliveryLocation?.postal}
                            , {deliveryLocation?.province},{' '}
                            {deliveryLocation?.country}
                          </h5>
                        )}
                      {deliveryLocation &&
                        deliveryLocation.streetAddress === 'Undefined' && (
                          <h5 className="rqinfo">
                            <span>Delivery Location: </span>
                            {parseFloat(deliveryLocation?.lat).toFixed(4)},{' '}
                            {parseFloat(deliveryLocation?.lng).toFixed(4)}
                          </h5>
                        )}
                      <h5 className="rqinfo">
                        <span>Additional Notes to Deliverer: </span>
                        <br />
                        {additionalNote && additionalNote.length > 0
                          ? additionalNote
                          : 'None'}
                      </h5>
                    </div>
                  </div>
                )}

                <div className="cardFooter">
                  {' '}
                  <div className="navButtons">
                    <IonButton
                      className="backBtn"
                      fill="clear"
                      onClick={() => goBack()}
                    >
                      Go Back
                    </IonButton>
                    <IonButton
                      className="nextBtn slide4"
                      onClick={async () => await validateThenMove()}
                    >
                      Save and Continue
                    </IonButton>
                  </div>
                </div>
              </FullPageModalCard>
            </IonSlide>

            {/* SLIDE 5 -----------------------------------------------------------------------------*/}
            <IonSlide>
              <FullPageModalCard title="Pay Now">
                <div className="cardBodyContent">
                  <div className="paymentSlide">
                    {' '}
                    <IonItem className="inputField payment" mode="ios">
                      <div className="titleContainer">
                        <img
                          id="title-rqImg"
                          src={
                            image && imageHolder
                              ? imageHolder
                              : require('../feed/NoImageAvailable.png')
                          }
                          alt=""
                        />
                        <h5 className="rqTitle">{title}</h5>
                      </div>
                      <IonLabel position="stacked">Summary:</IonLabel>
                      <div className="summaryContainer">
                        <div className="slide3 paymentDetails">
                          <div className="paymentItemLabels">
                            <h5 className="paymentItem">Cost of Item(s):</h5>
                            <h5 className="paymentItem">
                              Delivery Driver Fee:
                            </h5>
                            <h5 className="paymentItem">KargoROO Fee:</h5>
                            <h5 className="paymentItem total">Total:</h5>
                          </div>

                          <div className="paymentItemPrices">
                            <h5 className="paymentItem">
                              $
                              {costOfGoods
                                ? parseFloat(costOfGoods).toFixed(2)
                                : (0).toFixed(2)}{' '}
                              CAD
                            </h5>
                            <h5 className="paymentItem">
                              $
                              {costOfDeliverer
                                ? parseFloat(costOfDeliverer).toFixed(2)
                                : (0).toFixed(2)}{' '}
                              CAD
                            </h5>
                            <h5 className="paymentItem">
                              ${KARGOROO_FEE.toFixed(2)} CAD
                            </h5>
                            <h5 className="paymentItem total">
                              ${calculateCost().toFixed(2)} CAD
                            </h5>
                          </div>
                        </div>
                      </div>
                    </IonItem>
                    <div className="cardElementContainer">
                      <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </div>
                  </div>
                </div>
                <div className="cardFooter">
                  {' '}
                  <div className="navButtons">
                    <IonButton
                      className="backBtn"
                      fill="clear"
                      onClick={() => {
                        setShowPostRQModal(false);
                        resetData();
                      }}
                    >
                      Pay Later
                    </IonButton>
                    {status === 'ready' && (
                      <IonButton
                        className="nextBtn"
                        onClick={() => validateThenMove()}
                      >
                        Pay Now
                      </IonButton>
                    )}
                    {status === 'loading' && (
                      <IonButton className="nextBtn">Processing...</IonButton>
                    )}
                  </div>
                </div>
              </FullPageModalCard>
            </IonSlide>

            {/* SLIDE 6 (Post Payment) ----------------------------------------------------------------------*/}
            {showEndSlide && (
              <IonSlide>
                <FullPageModalCard
                  title={status === 'success' ? 'Success' : 'Error'}
                >
                  <div className="cardBodyContent">
                    <div className="paymentSlide">
                      <IonItem className="inputField" mode="ios">
                        <div className="titleContainer">
                          <img
                            id="title-rqImg"
                            src={
                              image && imageHolder
                                ? imageHolder
                                : require('../feed/NoImageAvailable.png')
                            }
                            alt=""
                          />
                          <h5 className="rqTitle">{title}</h5>
                        </div>
                      </IonItem>
                    </div>
                    {status === 'error' && (
                      <div className="errorContainer">
                        <h5 className="errorMsg">{errors[0].msg}</h5>
                        <IonButton
                          onClick={() => {
                            setStatus('ready');
                          }}
                        >
                          Return To Payment
                        </IonButton>
                      </div>
                    )}
                    {status === 'success' && (
                      <div className="successContainer">
                        <h5 className="successMsg">
                          Your payment completed successfully. Your RooQuest is
                          now posted and active.
                        </h5>
                        <h5 className="successMsg">
                          Your RooQuestID is:{' '}
                          <strong>{rooquest.rooquest?.rqGeneratedID}</strong>
                        </h5>
                        <h5 className="successMsg">
                          Current Status is:{' '}
                          <strong>{rooquest.rooquest?.status}</strong>
                        </h5>
                      </div>
                    )}
                    <div className="cardFooter">
                      <div className="navButtons">
                        {status === 'success' && (
                          <IonButton
                            className="nextBtn"
                            onClick={() => {
                              setShowPostRQModal(false);
                              resetData();
                            }}
                          >
                            Close
                          </IonButton>
                        )}
                        {status === 'error' && (
                          <Fragment>
                            <IonButton
                              className="backBtn"
                              fill="clear"
                              onClick={async () => {
                                await getAllRooQuests();
                                showPostRQModal(false);
                              }}
                            >
                              Try Later
                            </IonButton>
                            <IonButton
                              className="nextBtn"
                              onClick={() => goBack()}
                            >
                              Try Again
                            </IonButton>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </FullPageModalCard>
              </IonSlide>
            )}
          </IonSlides>
        </div>
      </GreenModal>
    </Fragment>
  );
};

PostRQModal.propTypes = {
  auth: PropTypes.object.isRequired,
  rooquest: PropTypes.object.isRequired,
  setShowPostRQModal: PropTypes.func.isRequired,
  payForRooQuest_Stripe: PropTypes.func.isRequired,
  postNewRooQuest: PropTypes.func.isRequired,
  getAllRooQuests: PropTypes.func,
  navigation: PropTypes.object.isRequired,
  setShowMapModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  rooquest: state.rooquest,
  navigation: state.navigation,
});

export default connect(mapStateToProps, {
  payForRooQuest_Stripe,
  postNewRooQuest,
  setShowPostRQModal,
  setShowMapModal,
})(PostRQModal);
