import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { IonContent, IonIcon, IonModal } from '@ionic/react'
import { closeOutline, saveSharp } from 'ionicons/icons'
import CurrencyInput from 'react-currency-input-field'

import './EditDeliveryFeeModal.scss'
import { useTranslation } from 'react-i18next'

const EditDeliveryFeeModal = ({ showModal, setShowModal, rq, setRQ }) => {

    const [fee, setFee] = useState('')
    const [error, setError] = useState('')
    const costRef = useRef(null)
    const { t } = useTranslation();


    const close = () => {
        setShowModal(false)
    }

    const validate = () => {
        if (fee === '' || (fee !== "" && parseFloat(fee) < 0)) {
            setError('Please enter a valid fee')
            return false
        } else {
            setError('')
            return true
        }
    }

    const saveAndClose = () => {
        const roundTwo = (value) => {
            return Math.round(value * 100) / 100;
        }

        if (validate()) {
          const total = roundTwo(rq.costs.items + rq.costs.fees.rooFee.total + parseFloat(fee) + rq.costs.tax.total);

          setRQ((rq) => ({
            ...rq,
            triggerChange: !rq.triggerChange,
            costs: {
              ...rq.costs,
              fees: {
                ...rq.costs.fees,
                delivererFee: {
                  ...rq.costs.fees.delivererFee,
                  total: parseFloat(fee),
                  overwrittenByUser: true,
                },
              },
              total: total,
            },
          }));
          setShowModal(false);
        }
    }

    return (
        <IonModal
        className="EditDeliveryFeeModal"
        isOpen={showModal}
        breakpoints={[0, 0.7]}
        initialBreakpoint={0.7}
        onIonModalDidDismiss={close}        
    >
        <IonContent>
            <div className="addItemContent">
                <div className="addItemTitle">
                    <p>{t("Modify Delivery Fee")}</p>
                    <p className='subText'>{t("100% of the Delivery Fee goes to the deliverer")}</p>
                </div>
                <div className="closeBtn">
                    <IonIcon icon={closeOutline} onClick={close} />
                </div>
                <div className="inputs-container">
                    <div className="inputs">
                        <div className="item-desc input-item">
                            <p className='input-label'>{t("Delivery Fee")}:</p>
                            <CurrencyInput
                                ref={costRef}
                                className={`feeInput ${(error !== '') ? 'error' : ''}`}
                                placeholder={t("Amount to Deliverer")}
                                value={fee}
                                decimalsLimit={2}
                                prefix={'$'}
                                onBlur={() => {
                                    // If the user leaves the field empty, set the value to 0
                                    if (fee === "") {
                                        setFee("0")
                                    }
                                    // If the user enters a value with one decimal, add a 0 to the end
                                    else if (
                                        fee.indexOf(".") !== -1 &&
                                        fee.indexOf(".") === fee.length - 2) {
                                            setFee(fee + "0")
                                    }
                                }}
                                onValueChange={(value) => {
                                    setFee(value) 
                                }} />
                                <p className='errorMsg'>{error}</p>
                        </div>
                    </div>
                    <div className="btnContainer">
                        <div className="addItemBtn" onClick={saveAndClose}>
                            <p>{t("Modify Delivery Fee")}</p>
                        </div>
                    </div>
                </div>                
            </div>
        </IonContent>
    </IonModal>
    )
}

EditDeliveryFeeModal.propTypes = {
}

export default EditDeliveryFeeModal;
