import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { checkmarkOutline, closeOutline, pencilOutline, repeatOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { connect } from "react-redux";

import './DeliveryOffer.scss';
import { useTranslation } from "react-i18next";
import rooquest from "../../../../reducers/rooquest";



const DeliveryOffer = ({ deliveryOffer, rq_id, setShowAccept, setShowReject, setShowRescind, own = false,rq }) => {

    const { eta, expiry } = deliveryOffer;
    // console.log("delivery ooferer")
    // console.log(deliveryOffer)
    // console.log(rq)
    const { t } = useTranslation();

    const calculateTimeLeft = (date) => {
        let difference = +new Date(date) - +new Date();
                    
        if (difference > 0) {
            return {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }
        
        return {};
    }

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('en-CA')
    }

    const formatTime = (date) => {
        return new Date(date).toLocaleTimeString()
    }

    const addTime = (date, eta) => {
        let value = new Date(date);
        if(eta.type === 'hours')
        {
            value.setTime(value.getTime() + eta.value * 60 * 60 * 1000);
        }
        else if(eta.type === 'days')
        {
            value.setTime(value.getTime() + eta.value * 24 * 60 * 60 * 1000);
        }
        else if(eta.type === 'minutes')
        {
            value.setTime(value.getTime() + eta.value * 60 * 1000);
        }

        return new Date(value);

    }

        const [countdown, setCountdown] = useState(calculateTimeLeft(expiry.utcdate_end))
        const [expiryWarning, setExpiryWarning] = useState(false)

        useEffect(() => {
            const timer = setTimeout(() => {
                const timeLeft = calculateTimeLeft(expiry.utcdate_end);
                setCountdown(timeLeft);

                // if timeleft < 1hr, show warning
                if (timeLeft && timeLeft.days < 1 && timeLeft.hours < 1) {
                    setExpiryWarning(true);
                }

            }, 1000);
            return () => clearTimeout(timer);
        });

        const timerComponents = [];

        Object.keys(countdown).forEach((interval, index) => {
            if (countdown[interval] === null) return;

            // Make sure component has 2 digits
            let value = (countdown[interval] === 0) 
                ? '00' 
                : (countdown[interval] < 10)
                    ? `0${countdown[interval]}`
                    : countdown[interval].toString();


            if (timerComponents.length === 0 && value === '00') return;
            
            timerComponents.push(
                <span key={`offer-countdown-${interval}-rqid-${rq_id}`}>
                {value}{(index !== Object.keys(countdown).length - 1) ? ":" : ""}
                </span>
            );
        });

    return (
        <div className="deliveryOffer">
            <div className="info">
                <div className="estimatedDelivery">
                    <p className="title">{t('Estimated Delivery Time')}:</p>
                    <p className="value">{deliveryOffer.status === 'ACCEPTED' ? <>{formatDate( addTime(rq.acceptedDate,eta) )} {formatTime( addTime(rq.acceptedDate,eta) )} </>:<>{eta.value} {t(eta.type)}</> }</p>
                </div>
                <div className="estimatedDelivery">
                    <p className="title">{t('Delivery Fee')}:</p>
                    <p className="value offerAmount">${deliveryOffer.deliveryOffer.amount} {(deliveryOffer.deliveryOffer.isDefault && <IonIcon icon={repeatOutline} />)}</p>
                </div>
                <div className="offerExpiry">
                    {deliveryOffer.status === "ACTIVE" ? (
                        <>
                            <p>{`${(timerComponents.length !== 0) ? `${t('Offer expires in')}` : ''}`}
                                <span className={`expiryspan ${expiryWarning && 'warning'}`}>
                                    {(timerComponents.length !== 0) 
                                        ? (timerComponents) 
                                        : <span className='warning'>{t('Offer Expired')}</span>}
                                </span>
                            </p>
                        </>
                    ) : (deliveryOffer.status === "EXPIRED")
                        ? <p><span className='expiryspan warning'>{t('Offer Expired')}</span></p>
                        : (deliveryOffer.status === "RESCINDED")
                        ? <p><span>{t('Offer Canceled')}</span></p>
                        : (deliveryOffer.status === "ACCEPTED")
                        ? <p><span className='expiryspan success'>{t('Offer Accepted')}</span></p>
                        : (deliveryOffer.status === "REJECTED")
                        ? <p><span className='expiryspan warning'>{t('Offer Rejected')}</span></p>
                        : (deliveryOffer.status === "CANCELED") ?
                        <p><span className='expiryspan warning'>{t('Offer Canceled')}</span></p>
                        :<p><span className='expiryspan warning'>{t('Problem with offer')}</span></p>
                    }
                </div>
            </div>
            <div className="offerActions">
               {(deliveryOffer.status === 'ACTIVE' && timerComponents.length !== 0) &&
               <>                   
                    {!own ? 
                    <>
                    <div className="offerAction accept">
                        <div className="circle" onClick={() => setShowAccept(true)}>
                            <IonIcon icon={checkmarkOutline}  />
                        </div>
                        <p>{t('Accept')}</p>
                    </div>
                    <div className="offerAction reject">
                        <div className="circle" onClick={() => setShowReject(true)}>
                            <IonIcon icon={closeOutline}  />
                        </div>
                        <p>{t('Reject')}</p>
                    </div>
                    </>
                    :
                    <div className="offerAction reject">
                        <div className="circle" onClick={() => setShowRescind(true)}>
                            <IonIcon icon={closeOutline}  />
                        </div>
                        <p>{t('Cancel')}</p>
                    </div>
                    }
                </>
                }
            </div>
        </div>
    )
}

DeliveryOffer.propTypes = {
    deliveryOffer: PropTypes.object.isRequired,
    rq_id: PropTypes.string.isRequired,
    setShowAccept: PropTypes.func.isRequired,
    setShowReject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, { })(DeliveryOffer);