import React, { CSSProperties } from "react";
import ClipLoader from 'react-spinners/ClipLoader';
import { connect } from 'react-redux';
import './LoaderContainer.scss'

const LoaderContainer = ({rooquest,isLoading}) => {
    //if rooquests are loading show the clipLoader
    return(
        // rooquest.loading ? <div className="loader-container">
        isLoading ? <div className="loader-container">
          <ClipLoader
            color={"#ffffff"}
            loading={true}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
        </div>: <> </>

    )

}

const mapStateToProps = (state) => ({
    rooquest: state.rooquest,
    isLoading : state.isLoading
  });

export default connect(mapStateToProps,{})(LoaderContainer);