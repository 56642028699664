import firebase from 'firebase';
import {v4 as uuidv4} from 'uuid';

export const uploadRooQuestImage = async (file) => {
    // Firebase Storage Init
    const storage = firebase.app().storage('gs://kargoroo-app-rqimages');
    const storageRef = storage.ref();

    // Create File metadata
    const metadata = {
        contentType: 'image/jpeg',
    };

    // Upload File and metadata to the object
    const uploadTask = await storageRef
        .child('images/' + createImageName())
        .put(file, metadata);

    const imgURL = await uploadTask.ref.getDownloadURL();
    return imgURL;
};

export const uploadRooQuestImages = async (images) => {
    const imageURLs = [];
    const promises = [];

    images.forEach(async (image) => {
        // Upload File and metadata to the object
        const urlPromise = new Promise((resolve) => {
            const uploadTask = firebase
                .app()
                .storage('gs://kargoroo-app-rqimages')
                .ref()
                .child('images/' + createImageName())
                .put(image.blobImage, {
                    contentType: 'image/jpeg',
                })
                .then((snapshot) => {
                    promises.push(
                        snapshot.ref.getDownloadURL().then((url) => {
                            imageURLs.push(url);
                            resolve(url);
                        })
                    );
                });
            promises.push(uploadTask);
        });
        promises.push(urlPromise);
    });

    await Promise.all(promises);
    return imageURLs;
};

export const uploadScreenshots = async (images) => {
    const imageURLs = [];
    const promises = [];

    images.forEach(async (image) => {
        // Upload File and metadata to the object
        const urlPromise = new Promise((resolve) => {
            const uploadTask = firebase
                .app()
                .storage('gs://kargoroo-app-screenshots')
                .ref()
                .child('images/' + createImageName())
                .put(image.blobImage, {
                    contentType: 'image/jpeg',
                })
                .then((snapshot) => {
                    promises.push(
                        snapshot.ref.getDownloadURL().then((url) => {
                            imageURLs.push(url);
                            resolve(url);
                        })
                    );
                });
            promises.push(uploadTask);
        });
        promises.push(urlPromise);
    });

    await Promise.all(promises);
    return imageURLs;
};

export const uploadReceiptImages = async (images) => {
    const imageURLs = [];
    const promises = [];

    images.forEach(async (image) => {
        // Upload File and metadata to the object
        const urlPromise = new Promise((resolve) => {
            const uploadTask = firebase
                .app()
                .storage('gs://kargoroo-app-receiptimages')
                .ref()
                .child('images/' + createImageName())
                .put(image.blobImage, {
                    contentType: 'image/jpeg',
                })
                .then((snapshot) => {
                    promises.push(
                        snapshot.ref.getDownloadURL().then((url) => {
                            imageURLs.push(url);
                            resolve(url);
                        })
                    );
                });
            promises.push(uploadTask);
        });
        promises.push(urlPromise);
    });

    await Promise.all(promises);
    return imageURLs;
};

export const uploadDeliveryImages = async (images) => {
    const imageURLs = [];
    const promises = [];

    images.forEach(async (image) => {
        // Upload File and metadata to the object
        const urlPromise = new Promise((resolve) => {
            const uploadTask = firebase
                .app()
                .storage('gs://kargoroo-app-deliveryimages')
                .ref()
                .child('images/' + createImageName())
                .put(image.blobImage, {
                    contentType: 'image/jpeg',
                })
                .then((snapshot) => {
                    promises.push(
                        snapshot.ref.getDownloadURL().then((url) => {
                            imageURLs.push(url);
                            resolve(url);
                        })
                    );
                });
            promises.push(uploadTask);
        });
        promises.push(urlPromise);
    });

    await Promise.all(promises);
    return imageURLs;
};

export const uploadReportProblemImages = async (images) => {
    const imageURLs = [];
    const promises = [];
    console.log(images)
    images.forEach(async (image) => {
        // Upload File and metadata to the object
            const urlPromise = new Promise((resolve) => {
                const uploadTask = firebase
                    .app()
                    .storage('gs://kargoroo-app-reportproblemimages')
                    .ref()
                    .child('images/' + createImageName())
                    .put(image.blobImage, {
                        contentType: 'image/jpeg',
                    })
                    .then((snapshot) => {
                        promises.push(
                            snapshot.ref.getDownloadURL().then((url) => {
                                imageURLs.push(url);
                                resolve(url);
                            })
                        );
                    });
                promises.push(uploadTask);
            });
            promises.push(urlPromise);
    });
    await Promise.all(promises);
    return imageURLs;
};

const createImageName = () => {
    const id = uuidv4();
    const date = new Date();
    return `${date}-${id}`;
};
