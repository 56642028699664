import axios from 'axios';
import { backendAddress } from '../../App';
import { DELETE_NOTIFICATION, GETTING_NOTIFICATIONS, GETTING_NOTIFICATIONS_ERROR, GOT_NOTIFICATIONS, MARK_NOTIFICATIONS_AS_READ } from './notificationmenu-types';


// Get All Notifications
export const getAllNotifications = () => async (dispatch) => {
  try {
    dispatch({
      type: GETTING_NOTIFICATIONS,
    });
    const res = await axios.get(backendAddress + '/api/notifications');
    if (res.data) {
        dispatch({
            type: GOT_NOTIFICATIONS,
            payload: res.data
        })
    }
    
  } catch (error) {
    console.error(error)
    dispatch({
      type: GETTING_NOTIFICATIONS_ERROR,
    });
  }
};

// Delete Notification
export const deleteNotification = (notifID) => async (dispatch) => {
    try {
      await axios.delete(backendAddress + '/api/notifications/' + notifID);
      dispatch({
        type: DELETE_NOTIFICATION,
        payload: notifID,
      })           
    } catch (error) {
      console.error(error);
    }
};

export const markNotificationsAsRead = (notifIDs) => async (dispatch) => {
    try {
        const config = {
            headers: {
              'Content-Type': 'application/json',
            },
          };      
          const body = JSON.stringify({
            notifIDs
          });
      
          const res = await axios.put(backendAddress + '/api/notifications', body, config);
          if (res.data.status === "Success") {
            dispatch({
              type: MARK_NOTIFICATIONS_AS_READ,
              payload: notifIDs,
            })   
          }
          
    } catch (error) {
      console.error(error);
    }
};
