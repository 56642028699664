import React, { useEffect, useRef, useState } from 'react'

import FlipNumbers from 'react-flip-numbers';

import './Review.scss'
import { IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonList, IonIcon, IonPopover, IonGrid, IonRow, IonCol } from '@ionic/react';
import { chevronForwardOutline, informationCircleOutline, locationOutline } from 'ionicons/icons';
import AdditionalNote from '../components/AdditionalNote';
import ReviewEditItemModal from '../components/ReviewEditItemModal';
import { createOutline } from 'ionicons/icons';
import { launchToastCard } from '../../../actions/layout/toast';
import { connect } from 'react-redux';
import EditDeliveryFeeModal from '../components/EditDeliveryFeeModal';
import { useTranslation } from 'react-i18next';
import AddItemModal from '../components/AddItemModal';
import { RooquestItem } from './RooQuestTypes'
import Savings from '../RooQuestInformation/Savings';


const LengthPicker = ({ length, setLength }) => {
    const { value, type } = length;

    const { t } = useTranslation();

    const setType = (type) => {
        const utcTime = calculateUTCDate(value, type);
        setLength(length => ({ ...length, type: type, utcdate_end: utcTime }));
    }

    const setValue = (value) => {
        const utcTime = calculateUTCDate(value, type);
        setLength(length => ({ ...length, value: value, utcdate_end: utcTime }));
    }

    const calculateUTCDate = (value, type) => {
        const currentDateTime = new Date();

        if (type === 'minutes') {
            return new Date(currentDateTime.getTime() + (value * 60 * 1000)).toUTCString();
        }
        else if (type === 'hours') {
            return new Date(currentDateTime.getTime() + (value * 60 * 60 * 1000)).toUTCString();
        }
        else if (type === 'days') {
            return new Date(currentDateTime.getTime() + (value * 24 * 60 * 60 * 1000)).toUTCString();
        }
        return currentDateTime.toUTCString();
    }

    useEffect(() => {
        if (value) {
            const date = calculateUTCDate(value, type);
            setLength(length => ({ ...length, utcdate_end: date }));
        }
        // @ts-ignore
    }, []);

    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });


    return (
        <div className='Lengthcenter'>
            <div className="LengthPicker">
                <div className="lengthPicker">
                    <input type="number" min={0} value={value} onChange={(e) => setValue(parseInt(e.target.value))} />
                    <p
                        className="lengthType"
                        onClick={(e) => setShowPopover({ showPopover: true, event: e })}>
                        {`${t(type)}`}
                        <div className='round-edit-btn'><IonIcon icon={createOutline} /></div>
                    </p>
                    <IonPopover
                        mode="ios"
                        className='chooseTypePopover'
                        event={popoverState.event}
                        side='right'
                        arrow={false}
                        isOpen={popoverState.showPopover}
                        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                    >
                        <div className="choices">
                            <p
                                onClick={() => {
                                    setType('days')
                                    setShowPopover({ showPopover: false, event: undefined })
                                }}
                                className={`${(type === 'days') ? 'selected' : ''}`}>
                                {t("days")}</p>
                            <p
                                onClick={() => {
                                    setType('hours')
                                    setShowPopover({ showPopover: false, event: undefined })
                                }}
                                className={`${(type === 'hours') ? 'selected' : ''}`}>
                                {t("hours")}</p>
                            <p
                                onClick={() => {
                                    setType('minutes')
                                    setShowPopover({ showPopover: false, event: undefined })
                                }}
                                className={`${(type === 'minutes') ? 'selected' : ''}`}>
                                {t("minutes")}</p>

                        </div>
                    </IonPopover>
                </div>
            </div>
        </div>
    )
}
const Review = ({ rq, setRQ, activeIndex }) => {
    const [showAdditionalNoteModal, setShowAdditionalNoteModal] = useState(false);
    const [reviewItem, setReviewItem] = useState([]);
    const [showEditDelivFeeModal, setShowEditDelivFeeModal] = useState(false);

    const [moneySaved, setMoneySaved] = useState("0");

    const { items, costs, co2Saved, pickupAddress, dropoffAddress } = rq;
    
    const [showPreAuthPopover, setShowPreAuthPopover] = useState({ showPopover: false, event: undefined });
    const { t } = useTranslation();
    useEffect(() => {
        if (items && items.length) setReviewItem(items)
    }, [items])



    const [expiryLength, setExpiryLength] = useState({
        value: 24,
        type: 'hours',
        utcdate_end: null,
    })

    useEffect(() => {
        if (costs) {
            const distance = parseFloat(costs.fees.delivererFee.distance) * 2;
            const costPerKm = parseFloat(costs.fees.delivererFee.costPerKm);
            const saved = (distance * costPerKm).toFixed(2).toString();
            setMoneySaved(saved);
        }
    }, [costs]);

    useEffect(() => {
        setRQ({ ...rq, expiryDate: expiryLength.utcdate_end })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expiryLength]);

    return (
        activeIndex === 2 ?  <div className='ReviewFormPage'>

            <div className="reviewContent">

                <div className="section cost">
                    <div className="cost-title-container">
                        <p className="sectionTitle">{t("Cost Summary")} <IonIcon icon={informationCircleOutline} onClick={(e) =>  {
                                            setShowPreAuthPopover({ showPopover: true, event: e })
                                        }} />
                                        <IonPopover
                                            mode="ios"
                                            class='estimatedCostPopoverInfo'
                                            event={showPreAuthPopover.event}
                                            isOpen={showPreAuthPopover.showPopover}
                                            onDidDismiss={() => setShowPreAuthPopover({ showPopover: false, event: undefined })}
                                            reference="event"
                                            side='right'
                                            alignment='center'
                                            arrow={true}
                                        >
                                            <p>{t(`You will temporarily be charged`)} ${costs.totalToCapture} {t(`to accomodate for price fluctuations. Your final charge will be the in-store total amount paid (including taxes and fees)`)}</p>
                                        </IonPopover></p>  
                        <FlipNumbers
                            height={14}
                            width={9.5}
                            color="#10be5f"
                            // background="white"
                            play perspective={100}
                            duraction={0.4}
                            numbers={'$' + parseFloat(costs.total).toFixed(2)} />

                    </div>

                    <RQCostsTest items={reviewItem} setReviewItem={setReviewItem} costs={costs} canEditDelivFee={true} setShowEditDelivFeeModal={setShowEditDelivFeeModal} rq = {rq} setRQ={setRQ}/>
                </div>
                <div className='review_savings'>
                <Savings rooquest={rq} />
                </div>
                <div className="section route">
                    <p className="sectionTitle">{t('Pickup & Dropoff')}</p>
                    <div className="routeContainer">
                        <div className="route-item">
                            <div className="iconContainer">
                                <IonIcon src={require("./storefront-outline.svg")} />
                                <div className="dottedLine" />
                            </div>
                            <p className="pickup-location"><span>{pickupAddress.hasBusinessName && (pickupAddress.name + ', ')}</span>{pickupAddress.formatted_address}</p>
                        </div>
                        <div className="route-item">
                            <div className="iconContainer">
                                <IonIcon icon={locationOutline} />
                            </div>
                            <p className="pickup-location"><span>{dropoffAddress.hasBusinessName && (dropoffAddress.name + ', ')}</span>{dropoffAddress.formatted_address}</p>
                        </div>
                        {/* <div className="co2Saved">
                            <p>{t("Your Savings")}:</p>
                            <p>{parseFloat(co2Saved.kg).toFixed(1)}{t("kg of CO2 emissions")}</p>
                            <p>${moneySaved} {t("of gas")}</p>
                        </div> */}
                    </div>
                </div>
                <div className="section route">
                    <p className="sectionTitle">{t("RooQuest Expiry")}</p>
                    <div className="routeContainer">
                        <div className="section">
                            <p className='roovalidsectionTitle'>{t("How long should this RooQuest be valid for?")} ({t("Max")} 5 {t("days")})</p>
                        </div>
                        <LengthPicker length={expiryLength} setLength={setExpiryLength} />
                        {/* <div className="footer">
                            <div className='makeOfferBtn' onClick={() => updateExpiry()}><p>Change rooquest expiration</p></div>
                        </div> */}
                    </div>
                </div>
                <AdditionalNote
                    showModal={showAdditionalNoteModal}
                    setShowModal={setShowAdditionalNoteModal}
                    note={rq.additionalNotes}
                    setNote={(note) => setRQ({ ...rq, additionalNotes: note })}
                />
                {rq.additionalNotes === '' ?
                    <div className="section additional">
                        <div className="additionalNotesBtn" onClick={() => setShowAdditionalNoteModal(true)}>
                            <p>{t("Add Note to Deliverer")}</p>
                        </div>

                    </div>

                    :

                    <div className="section additionalNotesHasNote">
                        <p className="sectionTitle">{t("Notes to Deliverer")}</p>
                        <div className="additionalNotesContainer" onClick={() => setShowAdditionalNoteModal(true)}>
                            <p>{rq.additionalNotes}</p>
                            <IonIcon icon={chevronForwardOutline} />
                        </div>
                    </div>
                }
            </div>
            <EditDeliveryFeeModal
                showModal={showEditDelivFeeModal}
                setShowModal={setShowEditDelivFeeModal}
                rq={rq}
                setRQ={setRQ}
            />
        </div>:<></>
    )
}

Review.propTypes = {

}

export default connect(null, { launchToastCard })(Review);

 
export const RQCosts = ({ items, costs, setReviewItem, canEditDelivFee = false, setShowEditDelivFeeModal = null }) => {

    const accordionGroupRef = useRef(null);

    const [showReviewEditItemModal, setShowReviewEditItemModal] = useState(false);
    const [testitem, Settestitem] = useState({});
    const [rqCostItems, setRqCostItems] = useState([]);
    const calculateTotalItemCost = (quantity, costPerUnit) => {
        const total = quantity * costPerUnit;
        return total.toFixed(2);
    }
    const { t } = useTranslation();
    useEffect(() => {
        if (items && items.length) setRqCostItems(items)
    }, [items])

    const openItemModal = (item, index) => {
        Settestitem({ ...item, uniqueId: index });
        setShowReviewEditItemModal(true);
    }
    return (
        <div className="RQCosts">
            <ReviewEditItemModal
                showModal={showReviewEditItemModal}
                setShowModal={setShowReviewEditItemModal}
                note={testitem}
                setUpdate={(note, i) => {
                    items.splice(i, 1, note)
                    setReviewItem(items)
                }}
                setNote={(note) => {
                    Settestitem({ ...note })
                }}
            />
            <IonAccordionGroup mode='ios' ref={accordionGroupRef} >
                <IonAccordion mode='ios' value="items">
                    <IonItem slot="header" tabIndex={-1}>
                        <IonLabel className="costAccordionTitle">
                            <p className="numItems">{`${rqCostItems.length}`} {`${t("item")}`}{`${(rqCostItems.length > 1) ? 's' : ""}`}</p>
                            <p className="itemCost">${parseFloat(costs.items).toFixed(2)}</p>

                        </IonLabel>
                    </IonItem>
                    <IonList className="reviewform-items" slot="content">
                        {rqCostItems.map((item, index) => (
                            <div className="reviewform-item" key={`reviewform-item-${index}`}>
                                <p className="itemTitle">{`${item.quantity > 1 ? `${item.quantity} x ` : ""}${item.title}`}</p>
                                <p className="itemCost">${calculateTotalItemCost(item.quantity, item.costPerUnit)}</p>
                                <div className="iconContainer" onClick={() => openItemModal(item, index)}><IonIcon src={require("./eye-outline.svg")} /></div>
                            </div>
                        ))}
                    </IonList>
                </IonAccordion>
                <IonAccordion mode='ios' value="deliveryFee">
                    <IonItem slot="header" tabIndex={-1}>
                        <IonLabel className="costAccordionTitle">
                            <p className="numItems">{t("Delivery Fee")}</p>
                            {canEditDelivFee
                                ? <p className="itemCost" onClick={(e) => {
                                    setShowEditDelivFeeModal(true)
                                }}><div className='round-modify-edit-btn'><IonIcon icon={createOutline} /></div> ${parseFloat(costs.fees.delivererFee.total).toFixed(2)}</p>
                                : <p className="itemCost">${parseFloat(costs.fees.delivererFee.total).toFixed(2)}</p>
                            }
                        </IonLabel>
                    </IonItem>
                    <IonList slot="content">
                        <div className="cost-info">
                            <p className='maininfo'>{t('Delivery Fee')}</p>
                            <p className='subinfo'>{t("100% goes to the deliverer")}</p>
                        </div>
                    </IonList>
                </IonAccordion>
                <IonAccordion mode='ios' value="rooFee">
                    <IonItem slot="header" tabIndex={-1}>
                        <IonLabel className="costAccordionTitle">
                            <p className="numItems">{t("Roo Platform Fee")}</p>
                            <p className="itemCost">${parseFloat(costs.fees.rooFee.total).toFixed(2)}</p>
                        </IonLabel>
                    </IonItem>
                    <IonList slot="content">
                        <div className="cost-info">
                            <p className='maininfo'>{t("Standard Platform Fee")}</p>
                            <p className='subinfo'>${parseFloat(costs.fees.rooFee.base).toFixed(2)} + {(costs.fees.rooFee.percentage * 100)}% {t("of items total and delivery fee")}</p>
                        </div>
                    </IonList>
                </IonAccordion>
                <IonAccordion mode='ios' value="taxes">
                    <IonItem slot="header" tabIndex={-1}>
                        <IonLabel className="costAccordionTitle">
                            <p className="numItems">{t("Taxes")}</p>
                            <p className="itemCost">${parseFloat(costs.tax.total).toFixed(2)}</p>
                        </IonLabel>
                    </IonItem>
                    <IonList slot="content">
                        <div className="cost-info">
                            <p className='maininfo'>{costs.tax.province} Tax ({costs.tax.taxes})</p>
                            <p className='subinfo'>{t("Applied to Platform and Delivery Fees")}</p>
                        </div>
                    </IonList>
                </IonAccordion>
            </IonAccordionGroup>
        </div>
    )
}

export const RQCostsTest = ({ items, costs, setReviewItem, canEditDelivFee = false, setShowEditDelivFeeModal = null,rq,setRQ }) => {

    const accordionGroupRef = useRef(null);

    const [showReviewEditItemModal, setShowReviewEditItemModal] = useState(false);
    const [testitem, Settestitem] = useState({});
    const [rqCostItems, setRqCostItems] = useState([]);
    const calculateTotalItemCost = (quantity, costPerUnit) => {
        const total = quantity * costPerUnit;
        return total.toFixed(2);
    }
    const { t } = useTranslation();
    useEffect(() => {
        if (items && items.length) setRqCostItems(items)
    }, [items])

    const openItemModal = (item, index) => {
        Settestitem({ ...item, uniqueId: index });
        setShowReviewEditItemModal(true);
    }

    const addItem = (item: RooquestItem) => {
        console.log("TEST")
        console.log(item)
        setItems([...items, item])
    }

    const editItem = (item: RooquestItem, index: number) => {
        const newItems = [...items];
        newItems[index] = item;
        setItems(newItems);
    }

    const removeItem = (index: number) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    }

    const setItems = (items) => { setRQ({ ...rq, items, triggerChange: !rq.triggerChange }) }
    const [showAddModal, setShowAddModal] = useState({ show: false, index: -1 });
    const openAddItemModal = (index) => {
        if (items.length >= 75 && index === -1) { // Trying to more than 75 item
            launchToastCard({ 
                msg: "You have hit the maximum number of items!",
                type: 'info',
                duration: 7500,
             });
        }
        else {
            setShowAddModal({ show: true, index });
        }
    }

    return (
        <div className="RQCosts">
            <AddItemModal 
                showAddModal={showAddModal} 
                setShowAddModal={setShowAddModal} 
                addItem={addItem} 
                editItem={editItem} 
                removeItem={removeItem}
                items={items}
                pickupAddress = {rq.pickupAddress}
            />
            <IonAccordionGroup mode='ios' ref={accordionGroupRef} >
                <IonAccordion mode='ios' value="items">
                    <IonItem slot="header" tabIndex={-1}>
                        <IonLabel className="costAccordionTitle">
                            <p className="numItems">{`${rqCostItems.length}`} {`${t("item")}`}{`${(rqCostItems.length > 1)?'s' : ""}`}</p>
                            <p className="itemCost">${parseFloat(costs.items).toFixed(2)}</p>

                        </IonLabel>
                    </IonItem>
                    <IonGrid   slot='content'>

                    {rqCostItems.map((item, index) => (
                            <IonRow className="reviewform-item">
                                <IonCol>{`${item.quantity > 1 ? `${item.quantity} x ` : ""}${item.title}`}</IonCol>
                                <IonCol className="itemCost">${calculateTotalItemCost(item.quantity, item.costPerUnit)}</IonCol>
                                <IonCol className="iconContainer" onClick={() => openAddItemModal(index)}><IonIcon src={require("./eye-outline.svg")} /></IonCol>
                            </IonRow>
                        ))}
                    </IonGrid>
                    
                    {/* should not be deleted <IonList className="reviewform-items" slot="content">
                        {rqCostItems.map((item, index) => (
                            <div className="reviewform-item" key={`reviewform-item-${index}`}>
                                <p className="itemTitle">{`${item.quantity > 1 ? `${item.quantity} x ` : ""}${item.title}`}</p>
                                <p className="itemCost">${calculateTotalItemCost(item.quantity, item.costPerUnit)}</p>
                                <div className="iconContainer" onClick={() => openAddItemModal(index)}><IonIcon src={require("./eye-outline.svg")} /></div>
                            </div>

                            
                        ))}

                            <div className="reviewform-item" key={`reviewform-item-${rqCostItems.length}`}>
                                <p className="itemTitle">{`Preauth`}</p>
                                <p className="itemCost">${costs.fees.preAuth.total}</p>
                                <IonIcon icon={informationCircleOutline} onClick={(e) =>  {
                                        setShowPreAuthPopover({ showPopover: true, event: e })
                                    }} />
                                    <IonPopover
                                        mode="ios"
                                        class='estimatedCostPopoverInfo'
                                        event={showPreAuthPopover.event}
                                        isOpen={showPreAuthPopover.showPopover}
                                        onDidDismiss={() => setShowPreAuthPopover({ showPopover: false, event: undefined })}
                                        reference="event"
                                        side='left'
                                        alignment='center'
                                        arrow={true}                                        
                                    >
                                        <p>{t("You will temporarily charged 25% of the total cost of the items to accomodate for ")}</p>
                                    </IonPopover>
                            </div>
                    </IonList> */}
                </IonAccordion>
                <IonAccordion mode='ios' value="deliveryFee">
                    <IonItem slot="header" tabIndex={-1}>
                        <IonLabel className="costAccordionTitle">
                            <p className="numItems">{t("Delivery Fee")}</p>
                            {canEditDelivFee
                                ? <p className="itemCost" onClick={(e) => {
                                    setShowEditDelivFeeModal(true)
                                }}><div className='round-modify-edit-btn'><IonIcon icon={createOutline} /></div> ${parseFloat(costs.fees.delivererFee.total).toFixed(2)}</p>
                                : <p className="itemCost">${parseFloat(costs.fees.delivererFee.total).toFixed(2)}</p>
                            }
                        </IonLabel>
                    </IonItem>
                    <IonList slot="content">
                        <div className="cost-info">
                            <p className='maininfo'>{t('Delivery Fee')}</p>
                            <p className='subinfo'>{t("100% goes to the deliverer")}</p>
                        </div>
                    </IonList>
                </IonAccordion>
                <IonAccordion mode='ios' value="rooFee">
                    <IonItem slot="header" tabIndex={-1}>
                        <IonLabel className="costAccordionTitle">
                            <p className="numItems">{t("Roo Platform Fee")}</p>
                            <p className="itemCost">${parseFloat(costs.fees.rooFee.total).toFixed(2)}</p>
                        </IonLabel>
                    </IonItem>
                    <IonList slot="content">
                        <div className="cost-info">
                            <p className='maininfo'>{t("Standard Platform Fee")}</p>
                            <p className='subinfo'>${parseFloat(costs.fees.rooFee.base).toFixed(2)} + {(costs.fees.rooFee.percentage * 100)}% {t("of items total and delivery fee")}</p>
                        </div>
                    </IonList>
                </IonAccordion>
                <IonAccordion mode='ios' value="taxes">
                    <IonItem slot="header" tabIndex={-1}>
                        <IonLabel className="costAccordionTitle">
                            <p className="numItems">{t("Taxes")}</p>
                            <p className="itemCost">${parseFloat(costs.tax.total).toFixed(2)}</p>
                        </IonLabel>
                    </IonItem>
                    <IonList slot="content">
                        <div className="cost-info">
                            <p className='maininfo'>{costs.tax.province} Tax ({costs.tax.taxes})</p>
                            <p className='subinfo'>{t("Applied to Platform and Delivery Fees")}</p>
                        </div>
                    </IonList>
                </IonAccordion>
            </IonAccordionGroup>
        </div>
    )
}