import React, { useState,useEffect  } from 'react';
import { IonContent, IonPage, IonToggle, IonList, IonItem, IonLabel, IonButton, IonAlert, IonSelect, IonSelectOption,IonIcon, IonChip } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import './NotificationSettings.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsHeader from '../../../components/layout/SettingsHeader/SettingsHeader';
import {updateRooquestNotificationMethod,updateDelivererNotificationMethod,updateNewRooquestNotificationMethod} from '../../../actions/user/user';
import { useTranslation } from 'react-i18next';
import { setShowNotificationRangeModal } from '../../../actions/layout/navigation';
import NotificationRange from '../../../reducers/notificationRange';
import { setRooQuestSearchRadiusFilter } from '../../../actions/layout/feedFilterBar';
const NotificationSettings = ({
    auth,
    updateRooquestNotificationMethod,
    updateDelivererNotificationMethod,
    updateNewRooquestNotificationMethod,
    setShowNotificationRangeModal
 }) => {
    const { t } = useTranslation();
    const [rooquestnotificationchecked, setRooquestNotificationChecked] = useState(null);
    const [deliverynotificationchecked, setDeliveryNotificationChecked] = useState(null);
    const [newrooquestnotificationchecked, setNewRooquestNotificationChecked] = useState(null);
    const [rooquestnotifiactionunits, setRooquestNotificationUnits] = useState("enable");
    const [deliverynotifiactionunits, setDeliveryNotificationUnits] = useState("enable");
    const [newrooquestnotifiactionunits, setNewRooquestNotificationUnits] = useState("enable");
    const [notificationRange, setNotificationRange] = useState(null);
    const [showRangeModal, setShowRangeModal] = useState(false);


    useEffect(() => {
        if (!!auth) {
            if(!!auth.is_requester_notification_send && auth.is_requester_notification_send){
                setRooquestNotificationChecked(true)
                setRooquestNotificationUnits("enable")
            } else {
                setRooquestNotificationChecked(false)
                setRooquestNotificationUnits("disable")
            }
            if(!!auth.is_deliverer_notification_send && auth.is_deliverer_notification_send){
                setDeliveryNotificationChecked(true)
                setDeliveryNotificationUnits("enable")
            } else {
                setDeliveryNotificationChecked(false)
                setDeliveryNotificationUnits("disable")
            }
            if(!!auth.stripeAccountID && !!auth.is_deliverer_show_new_rooquest && auth.is_deliverer_show_new_rooquest){
                setNewRooquestNotificationChecked(true)
                setNewRooquestNotificationUnits("enable")
            } else {
                setNewRooquestNotificationChecked(false)
                setNewRooquestNotificationUnits("disable")
            }
            }     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
 
    const changeMyRooquestsNotificationToggle = async (e) => {     
        //changes remaining for this page, here we need to do all notification related changes same as email
        if(e.detail.checked === true){
            setRooquestNotificationChecked(true);
            updateRooquestUnitMethoddata("enable");
           
        } else {
            setRooquestNotificationChecked(false);
            updateRooquestUnitMethoddata("disable");
        }  
      }
    const changeMyDeliveriesNotificationToggle = async (e) => {
        //changes remaining for this page, here we need to do all notification related changes same as email
        if(e.detail.checked === true){
            setDeliveryNotificationChecked(true);
            updateDeliveryUnitMethoddata("enable");
           
        } else {
            setDeliveryNotificationChecked(false);
            updateDeliveryUnitMethoddata("disable");
        }  
      }
    const changeNewRooquestNotificationToggle = async (e) => {
         if(e.detail.checked === true){
            setNewRooquestNotificationChecked(true);
            updatenewRooquestUnitMethoddata("enable");
           
        } else {
            setNewRooquestNotificationChecked(false);
            updatenewRooquestUnitMethoddata("disable");
        } 
    }
    const updatenewRooquestUnitMethoddata = async (unitdata) => {
        if (unitdata == "disable"){
            const updatedMethod = await updateNewRooquestNotificationMethod(false);
            if(updatedMethod == false){
                setNewRooquestNotificationUnits("disable");
            }
        } 
        if (unitdata == "enable"){
            const updatedMethod = await updateNewRooquestNotificationMethod(true);
            if(updatedMethod == true){
                setNewRooquestNotificationUnits("enable");
            }
        }
      } 
    const updateRooquestUnitMethoddata = async (unitdata) => {
        if (unitdata == "disable"){
            const updatedMethod = await updateRooquestNotificationMethod(false);
            if(updatedMethod == false){
                setRooquestNotificationUnits("disable");
            }
        } 
        if (unitdata == "enable"){
            const updatedMethod = await updateRooquestNotificationMethod(true);
            if(updatedMethod == true){
                setRooquestNotificationUnits("enable");
            }
        }
      } 
      const updateDeliveryUnitMethoddata = async (unitdata) => {
        if (unitdata == "disable"){
            const updatedMethod = await updateDelivererNotificationMethod(false);
            if(updatedMethod == false){
                setDeliveryNotificationUnits("disable");
            }
        } 
        if (unitdata == "enable"){
            const updatedMethod = await updateDelivererNotificationMethod(true);
            if(updatedMethod == true){
                setDeliveryNotificationUnits("enable");
            }
        }
      }

      const openRangeModal = () => {
        setShowNotificationRangeModal(true)
      }
    return (
        <IonPage className="questreq">
            <IonContent>
            <SettingsHeader value={t("Notification Settings")}/>
                <div className="FullQuest">
                    <div className="questcontainer questTitle">
                        <div className="fullInnerContainer">
                            <div className="questreqdisplay">
                                <IonList>
                                    <IonItem>
                                        <IonLabel>{t('Notification range')}</IonLabel>
                                        <IonButton color="danger" onClick={openRangeModal}>{t('Change')}</IonButton>
                                    </IonItem>
                                    {rooquestnotificationchecked !== null && <IonItem>
                                        <IonLabel>{t('My RooQuests')}</IonLabel>
                                        <IonToggle checked={rooquestnotificationchecked} onIonChange={e => changeMyRooquestsNotificationToggle(e)} /><b className="unitmethod">{t(rooquestnotifiactionunits)}</b>
                                    </IonItem>}
                                    {deliverynotificationchecked !== null && <IonItem>
                                        <IonLabel>{t('My Deliveries')}</IonLabel>
                                        <IonToggle checked={deliverynotificationchecked} onIonChange={e => changeMyDeliveriesNotificationToggle(e)} /><b className="unitmethod">{t(deliverynotifiactionunits)}</b>
                                    </IonItem>}
                                    {newrooquestnotificationchecked !== null && auth && auth.stripeAccountID && <IonItem>
                                        <IonLabel>{t('New Rooquest Created')}</IonLabel>
                                        <IonToggle checked={newrooquestnotificationchecked} onIonChange={e => changeNewRooquestNotificationToggle(e)} /><b className="unitmethod">{t(newrooquestnotifiactionunits)}</b>
                                    </IonItem>}
                                </IonList>
                            </div> 
                        </div>
                    </div>
                </div>
                
            </IonContent>
        </IonPage>

    )
}
const mapStateToProps = (state) => ({
    auth: state.auth.user
  });

  NotificationSettings.propTypes = {
    updateRooquestNotificationMethod:PropTypes.func.isRequired,
    updateDelivererNotificationMethod:PropTypes.func.isRequired,
    updateNewRooquestNotificationMethod:PropTypes.func.isRequired,

  };

export default connect(mapStateToProps,{
    updateRooquestNotificationMethod,
    updateDelivererNotificationMethod, 
    updateNewRooquestNotificationMethod,
    setShowNotificationRangeModal
})(NotificationSettings);
