import React from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButton,IonHeader, IonToolbar, IonTitle, useIonViewDidEnter } from '@ionic/react';
import './SingleItemConfirm.scss';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
const SingleItemConfirm = (rooquest) => {
    const { t } = useTranslation();
    const history = useHistory();
    const goToHome = () => {
        history.push('./home');
    }
    //for browser disable button
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
    };
  //for mobile disable button
    useIonViewDidEnter(() => {
        document.addEventListener("backbutton",function(e) {
          console.log("disable back button")
        }, false);
      })
    let confirmationid = rooquest.rooquest ? rooquest.rooquest.rqGeneratedID : '';
    return (
        <IonPage className="singleitemconfirm">
            <IonContent>
                {/* <GreenHeader /> */}
            <IonHeader className="ion-no-border GreenHeader">
                    <IonToolbar className="new-background-color">
                    {/* <IonButtons slot="start">
                        <IonBackButton defaultHref="/" text="" />
                    </IonButtons> */}
                    <IonTitle>{t('Post a RooQuest')}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className="FullQuest">
                    <div className="questcontainer">
                        <div>
                            <p className="questTitle questLeft">{t('Confirmation')}</p>
                        </div>
                        <div className="fullInnerContainer">
                            <div className="questBodyContent">
                                <div className="questHeaderReviewText">
                                    <p className="questheadertext">{t('Confirmation page')}</p><br/>
                                    <p className="questheadertext">{confirmationid}</p>
                                </div>
                            </div>
                            <div className="bottombuttonSingleItem">
                  <IonGrid>
                     <IonRow>
                     <IonCol size="3"></IonCol>
                        <IonCol size="6" text-center>
                        <IonButton
                      className="confirmbtn"
                      color="kargoroo_green"
                       onClick={() => goToHome()}
                    >
                      {t('Return')}
                    </IonButton>
                        </IonCol>
                        <IonCol size="3"></IonCol>
                        </IonRow>
                        </IonGrid> 
                        </div>
                        </div>
                    </div>
                  
                </div>
            </IonContent>
        </IonPage>
    )
}
const mapStateToProps = (state) => ({
    rooquest: state.rooquest.rooquest
  });
export default connect(mapStateToProps) (SingleItemConfirm);