import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonModal,
  IonButton,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonIcon,
  IonTextarea,
  IonInput,
  IonAlert,
} from '@ionic/react';

import './ConfirmDeliveryModal.scss';
import { connect } from 'react-redux';
import { Camera, CameraResultType } from '@capacitor/camera';
import { addCircleOutline, chatbubbleEllipsesOutline, closeCircleOutline, closeOutline } from 'ionicons/icons';
import {
  cancelRooQuestDelivery,
  getMyDeliveries,
  markRQAsDelivered,
  updateRooQuestWithoutCall,
} from '../../../../../../actions/rooquest/rooquest';
import { setShowEnlargeImageModal } from '../../../../../../actions/layout/navigation';
import { uploadDeliveryImages } from '../../../../../../actions/firebase/firebase-storage';
import { USE_GPS_TRACKING } from '../../../../../../App';
import { useTranslation } from 'react-i18next';
declare var global; 
const DeliveryModal = ({
  rooquest,
  showModal,
  setShowModal = null,
  markRQAsDelivered,
  updateRooQuestWithoutCall,
  setShowEnlargeImageModal,
  setDidNotConfirmError,
  didNotConfirmError,
  deliveryImages,
  setDeliveryImages,
  notes,
  setNotes,
  checked,
  setChecked,
  openChat,
  closeModal,
  cancelRooQuestDelivery, 
  getMyDeliveries,
  isKeyboardVisible
}) => {
  const { t } = useTranslation();
  const [showCancelDeliveryAlert, setShowCancelDeliveryAlert] = useState(false);  
  const modalRef = useRef(null);
  const [modalheight,setModalHeight] = useState(0);

  useEffect(() => {
    try{
      setModalHeight(modalRef.current.clientHeight)
    }
    catch(err)
    {
      console.log(err)
    }
  },[])

  const getImage = async () => {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      height: 500,
    });

    (await fetch(image.webPath)).blob().then((blobImage) => {
      setDeliveryImages([
        ...deliveryImages,
        { blobImage, webpath: image.webPath },
      ]);
    });
  };

  const enlargeImage = (e, src) => {
    if (e.target.tagName !== 'ION-ICON') {
      setShowEnlargeImageModal(true, src);
    }
  };

  const cancelDelivery = async (reason) => {
    await cancelRooQuestDelivery(rooquest._id, reason)
    updateRooQuestWithoutCall(null);
    await getMyDeliveries();
    closeModal()
};


  const confirmDelivery = async () => {
    if (checked) {
      const images = await uploadDeliveryImages(deliveryImages);
      const imagesString = images.toString();

      const rq = await markRQAsDelivered(rooquest.rooquest._id, notes, imagesString)

      if (rq) {
        updateRooQuestWithoutCall(rq);
      }

      setShowModal(false);

      if (USE_GPS_TRACKING) {
        console.log("global.driverInterval", global.driverInterval);
        if (global.driverInterval) {
          clearInterval(global.driverInterval);
        }
      }
      
    } else {
      setDidNotConfirmError({ error: true, style: { color: '#eb0000' } });
    }
  };

  return (
    <div className='deliveryModal' ref={modalRef} style = {{minHeight : isKeyboardVisible && `${modalheight}px`}}>
       <div className="actionBtns">
                 <div className="contactBtn" onClick={async () => openChat()}>
                    <IonIcon icon={chatbubbleEllipsesOutline} />
                </div> 
                 <div className="contactBtn" onClick={() => setShowCancelDeliveryAlert(true)}>
                    <IonIcon icon={closeCircleOutline} />
                </div>
            </div>

            <div className="confirmOnTheWayModal">
        <div className="modalContent">
          <div className="inputElements">
            <div className="receiptAndAmountContainer">
              <p className="imageOfReceiptMessage">
                {t('If the requester was not present during the delivery attempt')},{' '}
                <strong>
                {t('please provide an image of the RooQuest at the delivery location')}
                </strong>{' '}
                {t('for proof of delivery')}.
              </p>

              <div className="rowOfImages">
                {deliveryImages.map((image, index) => (
                  <div
                    key={`receiptImage_${index}`}
                    className="imageContainer"
                    onClick={(e) => enlargeImage(e, image.webpath)}
                  >
                    <img
                      src={image.webpath}
                      alt="receipt"
                      className="receiptImage"
                    />
                    <div
                      className="removeImage"
                      onClick={() => {
                        const newArr = [...deliveryImages];
                        newArr.splice(index, 1);
                        setDeliveryImages(newArr);
                      }}
                    >
                      <IonIcon icon={closeOutline} />
                    </div>
                  </div>
                ))}
                {deliveryImages.length < 3 && (
                  <div className="addImageButton">
                    <IonButton onClick={async () => await getImage()}>
                      <IonIcon icon={addCircleOutline} />
                    </IonButton>
                  </div>
                )}
              </div>
            </div>

            <div className="notes">
              <IonItem>
                <IonTextarea
                  placeholder={t("Notes")}
                  rows={2}
                  value={notes}
                  onIonChange={(e) => {
                    setNotes(e.detail.value!);
                  }}
                />
              </IonItem>
            </div>

            <div className="confirmationCheckBox">
              <IonItem>
                <IonCheckbox
                  checked={checked}
                  onIonChange={(e) => {
                    setDidNotConfirmError({ error: true, style: null });
                    setChecked(e.detail.checked);
                  }}
                />
                <IonLabel style={didNotConfirmError.style}>
                  {t("I have delivered the RooQuest as per Roo's Terms of Service.")}
                </IonLabel>
              </IonItem>
            </div>
          </div>
        </div>
    </div>
    <IonAlert
                isOpen={showCancelDeliveryAlert}
                onDidDismiss={() => setShowCancelDeliveryAlert(false)}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Cancel Delivery')} ?`}
                message={
                    `${t('Are you sure you want to cancel the delivery? This will be kept on your deliverer record and can lead to account penalties.')}`
                }
                inputs={[ { name: 'cancellationReason', type: 'text', placeholder: `${t('Reason for cancellation')}` }, ]}
                buttons={[
                    { text: `${t('Close')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Cancel Delivery')}`,
                        handler: async (alertData) => {
                        await cancelDelivery(alertData.cancellationReason);
                        },
                    },
                ]}
            />

    
    </div>
    
  );
};

DeliveryModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  // setShowModal: PropTypes.func.isRequired,
  rooquest: PropTypes.object.isRequired,
  markRQAsDelivered: PropTypes.func.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  rooquest: state.rooquest,
  isKeyboardVisible : state.isKeyboardVisible
});

export default connect(mapStateToProps, {
  markRQAsDelivered,
  updateRooQuestWithoutCall,
  setShowEnlargeImageModal,cancelRooQuestDelivery, getMyDeliveries
})(DeliveryModal);
