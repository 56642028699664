import axios from 'axios';
import { backendAddress } from '../App';

const SendRequests = ({ url, method, body, onSuccess }) => {
  const doRequest = async () => {
    try {
      const response = await axios[method](backendAddress + url, body);
      console.log(response);
      if (onSuccess) {
        onSuccess(response);
      }

      return response.data;
    } catch (err) {
      onSuccess(err.response);
      console.log(err);
    }
  };

  return { doRequest };
};
export default SendRequests;
