import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './ConfirmationPage.scss'
import { IonIcon } from '@ionic/react'
import { checkmarkDoneOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { toDaysMinutesSeconds } from '../../../utils/formatDate'

const ConfirmationPage = ({ rq,activeIndex }) => {
    const { t } = useTranslation();
    const {costs, co2Saved, distance} = rq;
    const [moneySaved, setMoneySaved] = useState("0");
    const timeSaved = toDaysMinutesSeconds(distance.seconds*2)

    useEffect(() => {
        if (costs) {
            const distance = parseFloat(costs.fees.delivererFee.distance);
            const costPerKm = parseFloat(costs.fees.delivererFee.costPerKm);
            const saved = (distance * costPerKm).toFixed(2).toString();
            setMoneySaved(saved);
        }
    }, [costs]);
    return (
        activeIndex === 4 ?<div className='PostRooQuestConfirmation'>
            <div className="checkmarkContainer">
                <div className="checkmark">
                    <IonIcon icon={checkmarkDoneOutline} />
                </div>
            </div>
            <div className="text">
                <h3>{t("Success!")}</h3>
                <h5>{t("Your RooQuest was posted!")}</h5>
            </div>

            
            <div className="confirmationCode">
                <div className="co2Saved">
                    <h5>{t("Your Savings")}:</h5>
                    <p>{parseFloat(co2Saved.kg).toFixed(1)}{t("kg of CO2 emissions")}</p>
                    <p>${moneySaved} {t("of gas")}</p>
                    <p>{timeSaved} {t("saved")}</p>
                </div>
            </div>
        </div>: <></>
    )
}

ConfirmationPage.propTypes = {

}

export default ConfirmationPage
