export const GETTING_ROOQUESTS = 'GETTING_ROOQUESTS';

export const ROOQUEST_ACTION_LOADING = 'ROOQUEST_ACTION_LOADING';

export const GET_ALL_ROOQUESTS = 'GET_ALL_ROOQUESTS';
export const GET_MY_ROOQUESTS = 'GET_MY_ROOQUESTS';
export const GET_MY_DELIVERIES = 'GET_MY_DELIVERIES';
export const GET_ROOQUEST_BY_ID = 'GET_ROOQUEST_BY_ID';
export const DELETE_ROOQUEST_BY_ID = 'DELETE_ROOQUEST_BY_ID';
export const GET_DRIVER_LOCATION = 'GET_DRIVER_LOCATION';

export const DELIVER_ROOQUEST = 'DELIVER_ROOQUEST';
export const UPDATE_ROOQUEST_STATUS = 'UPDATE_ROOQUEST_STATUS';
export const UPDATE_ROOQUEST_ROUTES = 'UPDATE_ROOQUEST_ROUTES';

export const SAVE_ROOQUEST = 'SAVE_ROOQUEST';
export const UNSAVE_ROOQUEST = 'UNSAVE_ROOQUEST';

export const POST_ROOQUEST = 'POST_ROOQUEST';
export const POST_DELIVERY_ID = 'POST_DELIVERY_ID';
export const CLEAR_ROOQUESTS = 'CLEAR_ROOQUESTS';
export const ROOQUEST_ERROR = 'ROOQUEST_ERROR';
export const HIDE_ROOQUEST = 'HIDE_ROOQUEST';
export const SET_HAS_GEOLOCATION = 'SET_HAS_GEOLOCATION';
export const UPDATE_ROOQUEST_DATA = 'UPDATE_ROOQUEST_DATA';
export const ROOQUEST_LOADING = 'ROOQUEST_LOADING';
