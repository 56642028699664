import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IonButton, IonIcon, IonModal } from '@ionic/react';
import { setShowMapModal } from '../../../actions/layout/navigation';
import { locateOutline } from 'ionicons/icons';
import RQInfoModal from '../RQCard/Feed/RQInfoModal/RQInfoModal';
// import { Plugins } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import './Map.scss';
import { useTranslation } from 'react-i18next';
// const { Geolocation } = Plugins;

const MapPopup = ({ setShowMapModal, showMapModal }) => {
  const { t } = useTranslation();
  const closeModal = (coords = null) => {
    geolocationMarkerRef.current = [];
    setShowMapModal(false, coords);
  };

  // Default Values
  let mapCenter = {
    lat: 46.09454,
    lng: -64.7965,
  };

  // Refs / States
  let currentLocationMap = useRef(null);
  let map = useRef(null);
  let geolocationButton = useRef(null);
  let geolocationMarkerRef = useRef([]);
  const [markerCoords, setMarkerCoords] = useState(
    new google.maps.LatLng(mapCenter)
  );

  // Get Current Location And Update Marker
  const getCurrentLocation = async () => {
    try {
      let coords = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });

      mapCenter = {
        lat: coords.coords.latitude,
        lng: coords.coords.longitude,
      };

      return true;
    } catch (err) {
      if (err.code !== 1) console.error(err);
      return false;
    } finally {
      if (geolocationMarkerRef.current.length === 0) {
        // If No Marker, Create Marker
        const newMarker = new google.maps.Marker({
          position: mapCenter,
          map: map.current,
          animation: google.maps.Animation.DROP,
          optimized: false,
          draggable: true,
          zIndex: 50,
        });

        // Add Listener for Drag Events And Update Coords
        newMarker.addListener('drag', (e) => {
          setMarkerCoords(e.latLng);
        });

        geolocationMarkerRef.current.push(newMarker);
        setMarkerCoords(newMarker.getPosition());
      } else {
        // Update Marker
        geolocationMarkerRef.current[0].setPosition(mapCenter);
        setMarkerCoords(geolocationMarkerRef.current[0].getPosition());
      }
    }
  };

  // Get Current Location, Update Marker, then Pan To Map Center
  const getCurrentLocationAndPan = async () => {
    if (await getCurrentLocation()) {
      map.current.panTo(mapCenter);
    }
  };

  // On First Load, Create Map && Init
  const initMap = async () => {
    // console.log('initted');
    // Add Listener to Current Location Pan Button
    if (geolocationButton && geolocationButton.current) {
      geolocationButton.current.addEventListener('click', async () => {
        await getCurrentLocationAndPan();
      });
    }

    // Init
    if (currentLocationMap.current) {
      map.current = new window.google.maps.Map(currentLocationMap.current, {
        zoom: 15,
        center: mapCenter,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControlOptions: {
          mapTypeIds: [],
        },
        clickableIcons: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
      });
    }

    await getCurrentLocationAndPan();
  };

  return (
    <IonModal
      mode="ios"
      isOpen={showMapModal.visible}
      class="currentLocationModal"
      showBackdrop={true}
      onDidPresent={() => initMap()}
    >
      <Fragment>
        <div className="coordinatesContainer">
          <h3>
            {markerCoords.lat().toFixed(4)}, {markerCoords.lng().toFixed(4)}
          </h3>
        </div>
        <div
          ref={currentLocationMap}
          style={{ width: '100%', height: '100%', zIndex: 7 }}
        ></div>
        <div ref={geolocationButton} id="geolocationButton">
          <div className="panToCurrentLocation">
            <IonIcon icon={locateOutline}></IonIcon>
          </div>
        </div>
        <div className="actionButtons">
          <div className="row">
            <IonButton onClick={() => closeModal()}>{t('Close')}</IonButton>
            <IonButton onClick={() => closeModal(markerCoords)}>
                  {t('SelectCoordinates')} 
            </IonButton>
          </div>
        </div>
        {/* <RQInfoModal /> */}
      </Fragment>
    </IonModal>
  );
};

MapPopup.propTypes = {
  showMapModal: PropTypes.object.isRequired,
  setShowMapModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  showMapModal: state.navigation.showMapModal,
});

export default connect(mapStateToProps, {
  setShowMapModal,
})(MapPopup);
