import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { IonContent, IonIcon, IonModal, IonPopover } from '@ionic/react'
import { closeOutline, duplicateOutline, addOutline, createOutline, trashOutline, informationCircleOutline } from 'ionicons/icons'
import CurrencyInput from 'react-currency-input-field'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import './AddItemModal.scss'
import { connect } from 'react-redux'
import { setShowEnlargeImageModal } from '../../../actions/layout/navigation'
import { RooquestItem } from '../form_pages/RooQuestTypes'
//modules added
import SuggestionsList from './SuggestionsListComponent'
import './Suggestions.scss'
import { getItems,fetchImages } from '../../../actions/inventory/storeitems'
import {getTaxRate,provincialTaxes_Local} from '../../../utils/getTaxRate'
import { useTranslation } from 'react-i18next'



const AddItemModal = ({showAddModal, setShowAddModal, addItem, editItem, removeItem, items, setShowEnlargeImageModal,pickupAddress}) => {

    const { show, index } = showAddModal;

    const initialState: RooquestItem = {
        title: "",
        description: "",
        costPerUnit: "",
        quantity: 1,
        sku: "",
        images: [],
    }

    const [showEstimatedCostPopover, setShowEstimatedCostPopover] = useState({ showPopover: false, event: undefined });


    const [newItem, setNewItem] = useState<RooquestItem>(initialState)
    const [errors, setErrors] = useState({
        title: '',
        costPerUnit: '',
        quantity: '',
    })

    //these state will be used for the suggestions
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
   
    const titleRef = useRef(null)
    const costRef = useRef(null)
    const quantityRef = useRef(null)
    const { t } = useTranslation();

    const validated = () => {
        let isValid = true
        if (newItem.title === "") {
            setError("Required", titleRef.current, 'title')
            isValid = false;
        }
        if (newItem.costPerUnit === "") {
            setError("Required", costRef.current, 'costPerUnit')
            isValid = false;
       }
        if (newItem.costPerUnit !== "" && parseFloat(newItem.costPerUnit) < 0) {
            setError("Must be at least 0", costRef.current, 'costPerUnit')
            isValid = false;
        }

        if (newItem.quantity < 1) {
            setError("> 0", quantityRef.current, 'quantity')
            isValid = false;
        }       
        return isValid;
    }

    const setError = (error: string, ref: any, field: string) => {
        ref.classList.add("error")
        setErrors(currentState => {
            return {
                ...currentState,
                [field]: error
            }
        });      
    }

    const close = () => {
        setShowAddModal({ show: false, index: -1 })
    }

    const validateAndClose = () => {
        console.log(newItem)
        if (validated()) {
            if (index === -1) {
                addItem(newItem)
            } else {
                editItem(newItem, index)
            }
            setNewItem(initialState);
            close();
        }        
    }
    
    const removeAndClose = () => {
        if (index !== -1) {
            removeItem(index)
            setNewItem(initialState);
            close();
        }
    }

    const getImage = async () => {
        const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        height: 500,
        source: CameraSource.Photos
        });

        (await fetch(image.webPath)).blob().then((blobImage) => {
            setNewItem({
                ...newItem, 
                images: [ ...newItem.images, { blobImage: blobImage, src: image.webPath }]
            });        
        });
    };

    const getImageFromUrl = async(images) => {
        const res = await fetchImages(images)
        return res;
    }

    useEffect(() => {
        if (newItem.quantity && quantityRef.current) {
            quantityRef.current.value = newItem.quantity;
        }
    }, [newItem]);

    useEffect(() => {
        console.log(`items == ${index}`)
        if (index !== -1) {
            setNewItem(items[index])
        }
    }, [items, index]);

   //get data in database and filter them using the user input to make suggestions
    const inputHandler = async (e) => {
        if(e.title)
        {
            const filteredData = await getItems(e.title,pickupAddress);
            setFilteredSuggestions(filteredData);
            setActiveSuggestionIndex(0);
            setShowSuggestions(true);
        }
        else
        {
            setFilteredSuggestions([]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
        }
    }

    // fill all the inputs field when the user clicks on a suggestion
    const autoFillInputs = async () => {
        let skuID="";
        const price = filteredSuggestions[activeSuggestionIndex]['itemPrice'].replace("$","");
        const province = pickupAddress['administrative_area_level_1'];
        const tax = getTaxRate(province,provincialTaxes_Local);
        const noTaxPrice = Number(price) * newItem['quantity']
        let cost = (noTaxPrice+(noTaxPrice*tax['rate'])).toFixed(2);
        try {
            skuID = filteredSuggestions[activeSuggestionIndex]['skuIDs']
        }
        catch(error){
            console.log(error)
        } 
        setNewItem({
            ...newItem,
            title: filteredSuggestions[activeSuggestionIndex]['itemTitle'],
            description: filteredSuggestions[activeSuggestionIndex]['itemDescription'],
            costPerUnit: String(cost),
            quantity: 1,
            sku:"",
            images: await getImageFromUrl(filteredSuggestions[activeSuggestionIndex]['itemImg'])
        });
        
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
    }
   
    // fill all the inputs field when the user clicks on a suggestion
    const suggestionClickHandler = async () =>{
        await autoFillInputs();
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
    }


    // fill all the inputs field when the user use arrow keys and the enter button
    const suggestionKeyHandler = async (e) =>{
        //user press enter
        if (e.keyCode === 13) {
            await autoFillInputs();
        } //user press up arrow, decrement the index
        else if (e.keyCode === 38 && activeSuggestionIndex>0) {
          setActiveSuggestionIndex(activeSuggestionIndex - 1 );
          setNewItem({
            ...newItem,
            title: filteredSuggestions[activeSuggestionIndex]['itemTitle']
        });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40 && activeSuggestionIndex< filteredSuggestions.length -1) {
          setActiveSuggestionIndex(activeSuggestionIndex + 1);
          setNewItem({
            ...newItem,
            title: filteredSuggestions[activeSuggestionIndex]['itemTitle']
        });
        }
        // setFilteredSuggestions([]);
        // setActiveSuggestionIndex(0);
        // setShowSuggestions(false);
    }

    //to handle hover on suggestions
    const suggestionHoverHandler = (index) => {
        setActiveSuggestionIndex(index)
    }

    return (
        <IonModal
        className="AddItemModal"
        isOpen={show}
        breakpoints={[0, 1]}
        initialBreakpoint={1}
        onIonModalDidDismiss={close}        
    >
        <IonContent>
            <div className="addItemContent">
                <div className="addItemTitle">
                    <p>{index === -1 ? `${t('Add New Item')}` : `${t('Edit Item')}`}</p>
                </div>
                <div className="closeBtn">
                    <IonIcon icon={closeOutline} onClick={close} />
                </div>
                <div className="inputs-container">
                    <div className="inputs">
                        <div className="item-name input-item">
                            <p className='input-label'>{t('Item Name')}: <span>*</span> <span className='errorMsg'>{errors.title}</span></p>
                            
                            <span className='notavailable'>{t("The item may not be available at the store")}</span>
                            {console.log(newItem)}
                            <input ref={titleRef} placeholder={t("(eggs, bread, meat,milk,screws,socks)")} type="text" value={newItem.title} onKeyDown={suggestionKeyHandler} onChange={(e) => {
                                setNewItem({
                                    ...newItem,
                                    title: e.target.value
                                })
                                inputHandler({
                                    ...newItem,
                                    title: e.target.value,
                                })
                                setErrors({
                                    ...errors,
                                    title: ''
                                })
                                e.target.classList.remove("error")
                            }} />
                            <SuggestionsList
                                suggestionClickHandler = {suggestionClickHandler}
                                suggestionHoverHandler = {suggestionHoverHandler}
                                filteredSuggestions = {filteredSuggestions}
                                activeSuggestionIndex = {activeSuggestionIndex}
                            />
                        </div>
                        <div className="item-desc input-item">
                            <p className='input-label'>{t('Description')}:</p>
                            <textarea placeholder={t("What do I look like? Colour? Weight? etc. Or, paste a website link of your item.")} value={newItem.description} onChange={(e) => {
                                setNewItem({
                                    ...newItem,
                                    description: e.target.value
                                })
                            }} />
                        </div>
                        <div className="multi-input-row">
                            <div className="item-quantity input-item">
                                <p className='input-label'>{t('Quantity')}: <span>*</span> <span className='errorMsg'>{errors.quantity}</span></p>
                                <input type="number" 
                                    ref={quantityRef}
                                    defaultValue={newItem.quantity}
                                    min={1}
                                    onBlur={(e) => {
                                        let newValue = parseInt(e.target.value);
                                        if (isNaN(newValue) || newValue <= 0) newValue = 1;
                                        setNewItem({
                                            ...newItem,
                                            quantity: newValue
                                        })
                                        setErrors({
                                            ...errors,
                                            quantity: ''
                                        })
                                        e.target.classList.remove("error")
                                    }}                                    
                                />
                            </div>
                            <div className="item-cost input-item">
                                <div className="inputLine">
                                    <p className='input-label'>{t("Cost Per Unit")}: <span>*</span> <span className='errorMsg'>{errors.costPerUnit}</span></p>
                                    <IonIcon icon={informationCircleOutline} onClick={(e) =>  {
                                        setShowEstimatedCostPopover({ showPopover: true, event: e })
                                    }} />
                                    <IonPopover
                                        mode="ios"
                                        class='estimatedCostPopoverInfo'
                                        event={showEstimatedCostPopover.event}
                                        isOpen={showEstimatedCostPopover.showPopover}
                                        onDidDismiss={() => setShowEstimatedCostPopover({ showPopover: false, event: undefined })}
                                        reference="event"
                                        side='left'
                                        alignment='center'
                                        arrow={true}                                        
                                    >
                                        <p>{t("Provide an over-estimation of the cost of the item. Include taxes. Only the amount paid at the store will be charged to your card.")}</p>
                                    </IonPopover>
                                </div>
                                <CurrencyInput
                                ref={costRef}
                                placeholder={t("Preauthorization Amount")}
                                value={newItem.costPerUnit}
                                decimalsLimit={2}
                                prefix={'$'}
                                onBlur={() => {
                                    // If the user leaves the field empty, set the value to 0
                                    if (newItem.costPerUnit === "" || !newItem.costPerUnit ) {
                                        setNewItem({ ...newItem, costPerUnit: "0" })
                                    }

                                    // If the user enters a value with one deciaml, add a 0 to the end
                                    if (
                                        newItem.costPerUnit.indexOf(".") !== -1 &&
                                        newItem.costPerUnit.indexOf(".") === newItem.costPerUnit.length - 2) {
                                            setNewItem({ ...newItem, costPerUnit: newItem.costPerUnit + "0" })
                                    }
                                }}
                                onValueChange={(value) => {
                                    setNewItem({
                                        ...newItem,
                                        costPerUnit: value
                                    }) 
                                    setErrors({
                                        ...errors,
                                        costPerUnit: ''
                                    })
                                    costRef.current.classList.remove("error")
                                }} />
                                
                            </div>
                        </div>                           
                        <div className="item-sku input-item">
                            <p className='input-label'>{t('Add Screenshots or Images')} ({t('Max')} 3):</p>
                            <div className="upload-img-bar">
                                <div className="list-of-images">
                                    { newItem.images.map((image, index) => {
                                        return (
                                            <div className="upload-img"key={`upload-image-item-${index}`}                                            
                                            >
                                                <div className="removeImg">
                                                    <IonIcon icon={closeOutline} onClick={() => {
                                                        setNewItem({
                                                            ...newItem,
                                                            images: newItem.images.filter((img, i) => i !== index)
                                                        })
                                                    } } />
                                                </div>
                                                <img src={image.src} 
                                                    alt="" 
                                                    onClick={() => setShowEnlargeImageModal(true, image.src)} 
                                                />
                                            </div>
                                        )
                                    })}                             
                                </div>                                
                                { newItem.images.length < 3 &&
                                    <div className="upload-img uploadBtn" onClick={async () => await getImage()}>
                                        <IonIcon icon={duplicateOutline} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="item-sku input-item">
                            <p className='input-label'>Identifier:</p>
                            <input type="text" placeholder={t("SKU / Model / Product / Item Number")}  value={newItem.sku} onChange={(e) => {
                                setNewItem({
                                    ...newItem,
                                    sku: e.target.value
                                })
                            }} />
                        </div>
                    </div>
                    <div className="btnContainer">
                        {index !== -1 && <div className="addItemBtn remove" onClick={removeAndClose}>
                            <IonIcon icon={trashOutline} />
                            <p>{t("Remove Item")}</p>
           
           
           
                           </div>}
                        <div className="addItemBtn" onClick={validateAndClose}>
                            <IonIcon icon={index === -1 ? addOutline : createOutline} />
                            <p>{index === -1 ? `${t('Add Item')}` : `${t('Edit Item')}`}</p>
                        </div>
                    </div>
                    
                </div>                
            </div>
        </IonContent>
    </IonModal>
    )
}

AddItemModal.propTypes = {
    setShowEnlargeImageModal: PropTypes.func.isRequired,
}

export default connect(null, { setShowEnlargeImageModal })(AddItemModal)
