
import { Dispatch } from 'redux';
import { getAllConversations, getConversationByChatID, setShowChatModal } from '../actions/chat/chat';
import { RECEIPT_OF_MESSAGE } from '../actions/chat/chat-types';
import { updateNavbarWithUnreadMessages } from '../actions/layout/navbar';
import { SET_SHOW_RATINGS_MODAL } from '../actions/layout/navigation-types';
import { launchToastCard } from '../actions/layout/toast';
import { getAllNotifications } from '../actions/notificationmenu/notificationmenu';
import { getMyDeliveries, getMyRooQuests, getRooQuestByID} from '../actions/rooquest/rooquest';
import { v4 as uuidv4 } from 'uuid';
import store from '../store';
import { openChat, openDelivery, openRooQuest } from './onClickPushNotifications';
import { registerForPushNotification } from '../actions/push-notifications';
import { Device } from '@capacitor/device';
import { setShowApproveRequestedFundsAlert } from '../actions/payments/payments';
import { setShowAppAlert } from '../actions/layout/appAlert';
const dispatchStore = store.dispatch as Dispatch<any>

export const handleReceivedNotification = (notification) => {
  
    console.log("dans x", notification)
    
    dispatchStore(getAllNotifications());
    if(notification && notification.data && notification.data.type === 'REQUEST_FUNDS_RESPONSE'){
      dispatchStore(launchToastCard({
        msg: notification.body,
        type: 'info',
        duration: 8500,
      }));
      return;
    
    }

    if(notification && notification.data && notification.data.type === 'REQUEST_FUNDS'){

      dispatchStore(launchToastCard({
        msg: notification.body,
        type: 'info',
        duration: 8500,
      }));

      dispatchStore(getRooQuestByID(notification.data.rooquest));

      if(store.getState().navigation.showRQModal.type === 'MYRQ' && store.getState().navigation.showRQModal.visible ){
        dispatchStore(setShowApproveRequestedFundsAlert(true));
      }
      else{
          dispatchStore(setShowAppAlert(true));
      }
      return;

    }
    
    if (notification.data && notification.data.message && typeof notification.data.message === 'string') {
      notification.data.message = JSON.parse(notification.data.message);
    }        
  
    if (
      notification.data &&
      notification.data.type === 'NEW_MESSAGE' &&
      notification.data.message.senderID !== store.getState().auth.user._id
    ) {
      if (
        store.getState().chat.conversation !== null &&
        store.getState().chat.conversation._id === notification.data.message.chatID
      ) {
        store.dispatch({
          type: RECEIPT_OF_MESSAGE,
          payload: { ...notification.data.message, _id: uuidv4(), date: new Date() },
        });
      } else {
        // console.log("44444")
  
        dispatchStore(launchToastCard({ 
          msg: notification.body,
          type: 'info',
          duration: 8500,
        }));
        dispatchStore(updateNavbarWithUnreadMessages(store.getState().navbar.unreadMessages + 1));
        if (store.getState().navigation.selectedTab === 'Messages') {
          dispatchStore(getAllConversations(store.getState().auth.user._id));
        }
      }
    // } else if (notification.data && notification.data.message.senderID !== store.getState().auth.user._id) {
    } else if (notification && notification.body && notification.body !== "") {
  
      // console.log("pwa ici")
      dispatchStore(getMyRooQuests());
      dispatchStore(getMyDeliveries());
      //commit below two line because after deliverer receive a notification redirected to another page 
      if (store.getState().rooquest.rooquest !== null) {
        dispatchStore(getRooQuestByID(store.getState().rooquest.rooquest._id));
      }
      if(notification && notification.title == "RooQuest Delivery Completed!"){
        store.dispatch({
          type: SET_SHOW_RATINGS_MODAL,
          payload: { visible:true, type:'REQUESTER_REVIEW' },
        });
      }
      dispatchStore(launchToastCard({ 
        msg: notification.body,
        type: 'info',
        duration: 8500,
      }));
    }
    else if (notification.data && notification.data.type) {
      if (notification.data.type === 'MY_RQ_ITEM_UPDATED') {
        dispatchStore(getMyRooQuests());
        if (store.getState().rooquest.rooquest !== null) {
          dispatchStore(getRooQuestByID(store.getState().rooquest.rooquest._id));
        }
      }
  
      if( notification.data.type === 'ROOQUEST')
      {
        dispatchStore(getMyRooQuests());
      }
    }
  }

  // this function will fetch all the updated data when the client were in background mode
  export const refreshData = async () => {
    const convo = store.getState().chat.conversation
    const selectedTab  = store.getState().navigation.selectedTab;
    
    let device = await Device.getInfo();
    if (device.platform === 'ios' || device.platform === 'android') {
      // Issue of firebase due to which app is crashing in android after login
      try{
        registerForPushNotification(store.getState().auth.user._id);
      }
      catch(err)
      {
        console.log(err)
      }
    }

    if(store.getState().rooquest.rooquest !== null)
    {
        let deliverer = store.getState().rooquest.rooquest?.deliverer;
        if(deliverer)
        {
          deliverer = deliverer.toString()
        }
        let userID = store.getState().auth.user._id;
        if(userID)
        {
          userID = userID.toString()
        }
        if(deliverer && userID && deliverer === userID)
            return;

        const showRQModal = store.getState().navigation.showRQModal;
        if(showRQModal.visible && showRQModal.type !== "MYDELIV")
        {
          dispatchStore(getRooQuestByID(store.getState().rooquest.rooquest._id));
        }
    }

    if(convo) //chat is open 
    {
      dispatchStore(getConversationByChatID(convo._id));
    }

    if ( selectedTab === 'Messages') {
      dispatchStore(getAllConversations(store.getState().auth.user._id))
    }
       
    // dispatchStore(getAllRooQuests(undefined,undefined,undefined))
    dispatchStore(getMyRooQuests());
    dispatchStore(getMyDeliveries());
    dispatchStore(getAllNotifications());

    //should handle rating system and new rooquest in your 
    //area rooquest and all rooquets in deliver now(getallrooquests)
    //should handle expired rooquest
  }

  //will open the right page when clicking on the notification (dynamic linking)
  export const handlePushNotificationActionPerformed = (notification) => {
    console.log("handle push notification action performed")
    console.log(notification)
    const type = notification.notification.data.type
    if(type && (type === "ROOQUEST" || type === "REQUEST_FUNDS") )
    {
      const rooquest_id = notification.notification.data.rooquest;
      openRooQuest(rooquest_id);
      if(type === "REQUEST_FUNDS")
      {
        dispatchStore(setShowApproveRequestedFundsAlert(true));
      }
    }

    //new rooquest in your area notification
    if(type && type === "DELIVERNOW")
    {
      // Reac();
    // const history = useHistory();
    //   history.push('/delivernow')
    }

    if(type && (type === "DELIVER" || type === "REQUEST_FUNDS_RESPONSE"))
    {
      const rooquest_id = notification.notification.data.rooquest;
      const url = notification.notification.data.url;
      openDelivery(rooquest_id,url)
    }

    if(type && type === "NEW_MESSAGE")
    {
      console.log("getting new message = ",JSON.parse(notification.notification.data.message))
      const message = JSON.parse(notification.notification.data.message);
      const convoID = message.chatID
      openChat(convoID);
    }
  }