export const findMidPoint = (firstMarker, secondMarker) => 
 {

    const x = (firstMarker.lat + secondMarker.lat)/2
    const y = (firstMarker.lng + secondMarker.lng)/2
    const result = {
        lat: x,
        lng : y
    } 
    return result
 }

 //For multiple circles use union instead of extend
 export const fitCirclesBounds = (firstCircle, secondCircle) => {
    // console.log("fitbounnds")
    let bound = new google.maps.LatLngBounds();
    console.log(firstCircle)
    bound.union(firstCircle)
    bound.union(secondCircle);
    return bound;

 }