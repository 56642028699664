import {
  SHOW_SAVED_ROOQUESTS,
  UNSHOW_SAVED_ROOQUESTS,
  SHOW_MAP_MODE_FEED,
  UNSHOW_MAP_MODE_FEED,
  SET_ROOQUEST_SEARCH_RADIUS,
  SET_DELIVER_NOW_FILTERS,
} from './feedFilterBar-types';

export const showSavedRQs = () => async (dispatch) => {
  dispatch({
    type: SHOW_SAVED_ROOQUESTS,
  });
};

export const unShowSavedRQs = () => async (dispatch) => {
  dispatch({
    type: UNSHOW_SAVED_ROOQUESTS,
  });
};

export const showMapMode = (show) => async (dispatch) => {
  if (show) {
    dispatch({
      type: SHOW_MAP_MODE_FEED,
    });
  } else {
    dispatch({
      type: UNSHOW_MAP_MODE_FEED,
    });
  }
};

export const setRooQuestSearchRadiusFilter = (radius) => async (dispatch) => {
  dispatch({
    type: SET_ROOQUEST_SEARCH_RADIUS,
    payload: radius,
  });
};

export const setDeliverNowFilters = (delivernowFilters) => async (dispatch) => {
  dispatch({
    type: SET_DELIVER_NOW_FILTERS,
    payload: delivernowFilters,
  });

}
