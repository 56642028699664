import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { chatbubbleEllipsesOutline } from 'ionicons/icons';
import { IonAlert, IonIcon } from '@ionic/react';
import DeliveryOffer from './DeliveryOffer';
import WhiteCard from '../../components/ui/WhiteCard';
import MakeOfferModal from './MakeOfferModal';
import { rescindDeliveryOffer } from '../../../../actions/rooquest/offers';
import DelivererSetupModal from '../../../settings/DelivererSetupModal/DelivererSetupModal';
import { AdditionalNotesCard, PickupDropoffCard } from '../RooQuestInformation';
import RooQuestItemsList from './RooQuestItemsList/RooQuestItemsList';
import { useTranslation } from 'react-i18next';

const PublicRooQuestInfo = ({ auth, rooquest, openChat, rescindDeliveryOffer }) => {

    const [showSetupDelivererModal, setShowSetupDelivererModal] = useState(false);
    const [showSetUpDeliverAccountAlert, setShowSetUpDeliverAccountAlert] = useState(false);

    const [showMakeOfferModal, setShowMakeOfferModal] = useState(false);
    const [showRescindOfferAlert, setShowRescindOfferAlert] = useState({ show: false, offer: null });   
    const offers = [...rooquest.offers].reverse()
    const { t } = useTranslation();


   const makeOffer = () => {
    if (auth && auth.user && !auth.user.stripeAccountID) {
            // Stripe Account Not Setup => Open Alert
            setShowSetUpDeliverAccountAlert(true);
        } else {
            // Open Make Offer
            setShowMakeOfferModal(true)
        }
   }

    return (
        <>
            <div className="actionBtns">
                <div className="deliverBtn" onClick={makeOffer}>
                    {/* <p>{` ${t("Make Offer")}  •  ${t("Earn")} ${parseFloat(rooquest.costs.fees.delivererFee.total).toFixed(2)}$`}</p> */}
                    <p>{` ${t("Make Offer")} `}</p>
                </div>
                {/* <div className="contactBtn" onClick={async () => openChat()}>
                    <IonIcon icon={chatbubbleEllipsesOutline} />
                </div> */}
            </div>
            <div className="mainContent">
                {/* <div className="rqinfo-section">
                    <p className="sectionTitle">RooQuest Status:</p>
                    <WhiteCard>
                        <div className="">
                            <p>{`Status: ${rooquest.status}`}</p>
                        </div>
                    </WhiteCard>
                </div> */}
 
                {rooquest.offers.findIndex(offer => offer.delivererID === auth.user._id) !== -1 &&
                    <div className="deliveryOffers">
                        <p className="sectionTitle">{t("My Offers")}:</p>
                        <WhiteCard>
                            <div className="offersContainer">
                                {offers
                                    .filter(offer => offer.delivererID === auth.user._id)
                                    .map((offer, index) => (
                                    <DeliveryOffer 
                                        key={`rqoffer-${index}`}
                                        setShowAccept={null}
                                        setShowReject={null}
                                        setShowRescind={(show) => setShowRescindOfferAlert({show, offer})}
                                        rq_id={rooquest._id}
                                        deliveryOffer={offer}
                                        own={true}    
                                        rq = {rooquest}                                            
                                    />
                                ))}                                           
                            </div>
                        </WhiteCard>
                    </div>
                }

                <PickupDropoffCard rooquest={rooquest} showFullAddress={false} />
                <RooQuestItemsList rooquest={rooquest} />
                <AdditionalNotesCard rooquest={rooquest} />

            </div>

            <DelivererSetupModal
                showModal={showSetupDelivererModal}
                setShowModal={setShowSetupDelivererModal}
            ></DelivererSetupModal>
            <IonAlert
                isOpen={showSetUpDeliverAccountAlert}
                onDidDismiss={() => setShowSetUpDeliverAccountAlert(false)}
                cssClass="deliveryAccountNotSetupAlert"
                header={t('Delivery Account Not Set Up!')}
                message={
                    `${t("Before accepting to deliver a RooQuest, you must first setup a delivery account. Would you like to setup an account now? (2 minutes required, please have bank information on hand)")}`
                }
                mode="ios"
                buttons={[
                    {
                    text: `${t('Close')}`,
                    role: 'cancel',
                    cssClass: 'secondary',
                    },
                    {
                    text: `${t('Setup Account')}`,
                    handler: () => {
                        setShowSetupDelivererModal(true);
                    },
                    },
                ]}
            />              

            <IonAlert
                isOpen={showRescindOfferAlert.show}
                onDidDismiss={() => setShowRescindOfferAlert({show: false, offer: null})}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={t('Cancel Offer')}
                message={
                t('By Cancelling this offer, the requester will no longer be able to accept your delivery offer. You can submit another offer. Are you sure?')
                }
                buttons={[
                    { text: t('Close'), role: 'cancel', cssClass: 'secondary' },
                    { text: t('Cancel Offer'),
                        handler: async () => {
                            rescindDeliveryOffer(rooquest._id, showRescindOfferAlert.offer._id);
                            setShowRescindOfferAlert({show: false, offer: null });
                        },
                    },
                ]}
            />

            {rooquest.requester !== auth.id && 
                <MakeOfferModal 
                    show={showMakeOfferModal} 
                    setShow={setShowMakeOfferModal} 
                    rooquest={rooquest} 
                />
            }
        </>
    )
};

PublicRooQuestInfo.propTypes = {
    auth: PropTypes.object.isRequired,
    rooquest: PropTypes.object.isRequired,
    openChat: PropTypes.func.isRequired,
    rescindDeliveryOffer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { rescindDeliveryOffer })(PublicRooQuestInfo);
