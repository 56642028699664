import { LAUNCH_TOAST, LAUNCH_TOAST_CARD, REMOVE_TOAST, REMOVE_TOAST_CARD } from '../../actions/layout/toast-types';

const initialState = {
  toasts: [],
  toastCards: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case LAUNCH_TOAST:
      return { ...state, toasts: [ ...state.toasts, payload ] }
    
    case REMOVE_TOAST:
      return { ...state, toasts: state.toasts.filter(toast => toast.id !== payload) }
    
    case LAUNCH_TOAST_CARD:
      return { ...state, toastCards: [ ...state.toastCards, payload ] }

    case REMOVE_TOAST_CARD:
      return { ...state, toastCards: state.toastCards.filter(toast => toast.id !== payload) }

    default:
      return state;
  }
}
