import axios from 'axios';

import { backendAddress } from '../App';

import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { handlePushNotificationActionPerformed, handleReceivedNotification } from '../utils/notifications';


export const registerForPushNotification = (user) => {
  console.log("register for push notifications")

  PushNotifications.removeAllListeners().then( async ()=>{
    PushNotifications.checkPermissions().then(status => {
      console.log("status = ", status)
      
    })
    PushNotifications.requestPermissions().then(result => {
      console.log('request permisssion result = ',result)
      if (result) {
        // Register with Apple / Google to receive push via APNS/FCM
        // PushNotifications.register();
      } else {
        // Show some error
      }
      });
      await PushNotifications.addListener('registration',
      (token: Token) => {
        console.log("returned token = ",token)
        if (!user.FCM_tokens.includes(token.value)) {
          console.log("Saving FCM_Token",token.value);
          saveFCMTokenToUserObject(token.value);
        }
      }
    );
  
    // Some issue with our setup and push will not work
    await PushNotifications.addListener('registrationError',
      (error: any) => {
       console.log('Error on registration: ' + JSON.stringify(error))
      }
    );

  
    // Show us the notification payload if the app is open on our device
    await PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
       console.log('Push received: ' + JSON.stringify(notification));
       handleReceivedNotification(notification);
      }
    );
  
    // Method called when tapping on a notification
    await PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        const type = notification.notification.data.type
       console.log('Push action performed: ' + JSON.stringify(notification));
       console.log("test")
       handlePushNotificationActionPerformed(notification)
      }
    );

  });
};

export const saveFCMTokenToUserObject = async (token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      token,
    });
    axios.put(backendAddress + '/api/users/fcm_token', body, config);
  } catch (err) {
    console.error(err);
  }
};
