import React from 'react';
import PropTypes from 'prop-types';
import GreenBackButton from '../../GreenBackButton/GreenBackButton';

import './ModalHeader.scss';

const ModalHeader = ({
  title,
  backBtnTo,
  backBtnOnClickFxn,
  secondaryButton,
  secondaryOnClickFxn,
}) => {
  return (
    <div className="modalHeader">
      <GreenBackButton
        to={backBtnTo}
        onClickFxn={backBtnOnClickFxn}
        secondaryButton={secondaryButton}
        secondaryOnClickFxn={secondaryOnClickFxn}
      />
      <div className="titleContainer">
        <h3 id="headerTitle">{title}</h3>
      </div>
    </div>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  backBtnTo: PropTypes.string,
  backBtnOnClickFxn: PropTypes.func,
  secondaryButton: PropTypes.any,
  secondaryOnClickFxn: PropTypes.func,
};

export default ModalHeader;
