import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IonButton, IonContent, IonDatetime, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonPopover, IonRadio, IonRadioGroup, IonSelect, IonSelectOption, IonSpinner, IonText, IonTextarea } from '@ionic/react';
import { format, parseISO } from 'date-fns';

import './ReportProblemModal.scss';
import { closeOutline, createOutline, duplicateOutline } from 'ionicons/icons';
import { connect } from 'react-redux';
import { sendmailreportProblem } from '../../../../../actions/rooquest/rooquest';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { useTranslation } from 'react-i18next'
import { report } from 'process';
import { uploadReportProblemImages } from '../../../../../actions/firebase/firebase-storage';


const ReportProblemForm = ({sendmailreportProblem,rooquest,closeForm}) => {
   

const submitData = async (data) => {
    console.log(reportProblemFormData)
    const images = await uploadReportProblemImages(reportProblemFormData.images)
    const imagesString = images.toString();
    const emailsend = await sendmailreportProblem(rooquest._id,reportProblemFormData,imagesString)
    if(emailsend)
    {
        setReportProblemFormData({
            subject: '',
            message: '',
            images: [],
          })
        closeForm();
    }
}

const [reportProblemFormData, setReportProblemFormData] = useState({
    subject: '',
    message: '',
    images: []
  });
  const [selected, setSelected] = useState('Missing Items')
  const { subject,message,images} = reportProblemFormData;
  const { t } = useTranslation();

  const onChange = (e) => {
    setReportProblemFormData({
      ...reportProblemFormData,
      [e.target.name]: e.target.value,
    });
  };

  const getImage = async () => {
    const image = await Camera.getPhoto({
    quality: 100,
    allowEditing: false,
    resultType: CameraResultType.Uri,
    height: 500,
    source: CameraSource.Photos
    });

    (await fetch(image.webPath)).blob().then((blobImage) => {
        setReportProblemFormData({
            ...reportProblemFormData, 
            images: [ ...reportProblemFormData.images, { blobImage: blobImage, src: image.webPath }]
        });        
    });
};

 return (
        <div className='form-pading'>
            <form onSubmit={submitData}>
                <div className='item'>
            <IonItem lines='full'>
                <IonLabel>{t('Subject')}</IonLabel>
                    <IonSelect value={selected} onIonChange={(e) => {
                        setSelected(e.detail.value);
                        setReportProblemFormData({
                            ...reportProblemFormData,
                            subject : e.detail.value
                        })}
                    }>
                        <IonSelectOption value="Missing Items">Missing Items</IonSelectOption>
                        <IonSelectOption value="Incorrect Items">Incorrect Items</IonSelectOption>
                        <IonSelectOption value="Damaged Items">Damaged Items</IonSelectOption>
                        <IonSelectOption value="Late Order">Late Order</IonSelectOption>
                    </IonSelect>
            </IonItem>
            
                
                <div className="item-sku input-item">
                            <p className='input-label'>{t('Add Screenshots or Images')} ({t('Max')} 3):</p>
                            <div className="upload-img-bar">
                                <div className="list-of-images">
                                    { reportProblemFormData.images.map((image, index) => {
                                        return (
                                            <div className="upload-img"key={`upload-image-item-${index}`}                                            
                                            >
                                                <div className="removeImg">
                                                    <IonIcon icon={closeOutline} onClick={() => {
                                                        setReportProblemFormData({
                                                            ...reportProblemFormData,
                                                            images: reportProblemFormData.images.filter((img, i) => i !== index)
                                                        })
                                                    } } />
                                                </div>
                                                <img src={image.src} 
                                                    alt="" 
                                                    // onClick={() => setShowEnlargeImageModal(true, image.src)} 
                                                />
                                            </div>
                                        )
                                    })}                             
                                </div>                                
                                { reportProblemFormData.images.length < 3 &&
                                    <div className="upload-img uploadBtn" onClick={async () => await getImage()}>
                                        <IonIcon icon={duplicateOutline} />
                                    </div>
                                }
                            </div>
                        </div>
                <IonItem lines='full'>
                    <IonTextarea value={message} name="message" onIonChange={(e) => onChange(e)} placeholder = "Your message" required={true}></IonTextarea>
                </IonItem>
                </div>
                <div className='buttons'  
                onClick={(e) => submitData(e)}
                >
                <p>{t('Submit')}</p>
                    {/* <IonButton
                    fill="solid"
                    color="kargoroo_green"
                    shape="round"
                    text-capitalize="false"
                    type="submit"
                    // onClick={(e) => submitData(e)}
                    >
                    <span>Submit</span>
                    </IonButton> */}
                </div>
                
                </form>
        
        </div>
    
 );

}


const ReportProblemModal = ({ show, setShow, sendmailreportProblem,rooquest }) => {
    const [showForm, setShowForm] = useState(false)
    const { t } = useTranslation();
    const close = () => {
        setShow(false);
    }

    const closeForm = () => {
        setShowForm(false);
    }

    const openForm = () => {
        setShowForm(true);
        close();
    }
    return (
        <div>
        <IonModal
            mode="ios" 
            isOpen={show} 
            class="ReportProblemModal"
            breakpoints={[0, 1]}
            initialBreakpoint={1}
            onIonModalDidDismiss={close}        
        >
            <IonContent>
                <div className="ReportProblemModalContent">
                    <div className="header">
                        <div className="title">
                            <p>{t("Report a Problem")}</p>
                        </div>
                        <div className="closeBtn">
                            <IonIcon icon={closeOutline} onClick={close} />
                        </div>
                    </div>                    
                    <div className="main">
                        <div className="description">
                            <p>{t("You can let us know about— Missing,Incorrect,Damaged items and Late orders.")}
                            </p>
                        </div>
                        <div className="footer">
                            <div className="reportBtn" onClick={openForm}>
                                <p>Report Problem</p>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </IonContent>
        </IonModal>
        
        <IonModal
        mode="ios" 
        isOpen={showForm} 
        class="reportProblemForm"
        breakpoints={[0, 1]}
        initialBreakpoint={1}
        onIonModalDidDismiss={closeForm}>
            <ReportProblemForm sendmailreportProblem = {sendmailreportProblem} rooquest = {rooquest} closeForm ={closeForm}/>
        </IonModal>
        </div>
    );
};

ReportProblemModal.propTypes = {
};

const mapStateToProps = (state) => ({
    rooquest: state.rooquest.rooquest,
  });

export default connect(mapStateToProps,{sendmailreportProblem})(ReportProblemModal);
