import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// import '@codetrix-studio/capacitor-google-auth';

import App, { backendAddress } from './App';
import * as serviceWorker from './serviceWorker';
import { IonAlert, isPlatform  } from '@ionic/react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { App as Appinfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import axios from 'axios';
const ServiceWorkerWrapper = ({children}) => {
    const [showAlert, setShowAlert] = useState(false);
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

    const getdata = async () => {
      if(Capacitor.isNativePlatform()) {
        const appversiondata = await axios.get(
          backendAddress + '/api/appversion'
        );
          const appdata = await Appinfo.getInfo();
          if (appversiondata.data.androidVersion > appdata.version){
            if(isPlatform('android')){
            setShowAlert(true)
            }
          }
          if (appversiondata.data.iosVersion > appdata.version){
            if(isPlatform('ios')){
            setShowAlert(true)
            }
          }
    } 
  }
  useEffect(() => {
    getdata();
  }, []);

  
    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
      //setShowReload(true);
      console.log("SWUpdate");
      setWaitingWorker(registration.waiting);
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    };
  
    useEffect(() => {
      serviceWorker.register({ onUpdate: onSWUpdate }); 
    }, []);
   
     const openStore = () => {
        if(isPlatform('android')){
          window.open('market://details?id=com.kargoroo_front.app', '_system');
        }
        if(isPlatform('ios')){
          window.open('https://apps.apple.com/us/app/roo/id1524515706', '_system');
        }
    }
    const reloadPage = async () => {
        waitingWorker.postMessage({ type: "SKIP_WAITING" });
        
        setShowReload(false);      
       
        //serviceWorker.unregister();
        console.log("Reloading...")
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href // Hard Reload
        window.location.reload();
    };
  
    return (
      // reload btn
      <>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header="Upgrade Required"
        cssClass= 'update-info-popup'
        backdropDismiss = {false}
        message="This version of app is outdated. Please visit the Store to upgrade the latest version"
        buttons={[{
          text: 'UPDATE',
          role: 'confirm',
          handler: () => {
               openStore();
          },
        },]}
        
      />
      {/* <IonToast isOpen={showReload} message={"A new version is available."} position="top" color="light" 
      buttons={[{text: "Update", handler: () => {console.log('UPDATING!'); reloadPage();}}]} /> */}
      {children}
      </>
    );
}


ReactDOM.render(<I18nextProvider i18n={i18n}><ServiceWorkerWrapper><App /></ServiceWorkerWrapper></I18nextProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

defineCustomElements(window);


