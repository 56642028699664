import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IonCard, IonButton, IonIcon } from '@ionic/react';
import {
  chevronDownOutline,
  chevronUpOutline,
  arrowBack,
  alertOutline,
  mapOutline,
} from 'ionicons/icons';
// import { Plugins } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import './BottomDrawer.scss';
import FeedFilterBar from '../../feed/FilterBar/FeedFilterBar';
import DelivererSetupModal from '../../settings/DelivererSetupModal/DelivererSetupModal';
import { connect } from 'react-redux';
import RQCard from '../../layout/RQCard/RQCard';
import {
  getAllRooQuests,
  updateRooQuestWithoutCall,
} from '../../../actions/rooquest/rooquest';
import MyRQCardTop from '../../layout/RQCard/MyRooQuests/MyRQCardTop';
import Spinner from '../../layout/Spinner/Spinner';
import SaveRooQuestComponent from '../../layout/RQCard/Feed/SaveRooQuestComponent';
import { setPanToLocation } from '../../../actions/layout/map';
import RouteInfoVertical from '../../layout/RQCard/Feed/RouteInfo/RouteInfoVertical';
import { getDateElements } from '../../../utils/formatDate';
import { setShowInfoModal } from '../../../actions/layout/navigation';
import { useTranslation } from 'react-i18next';
// const { Geolocation } = Plugins;

const BottomDrawer = ({
  showRef,
  setHomeView,
  setMapState,
  auth,
  rooquest,
  getAllRooQuests,
  setPanToLocation,
  feedFilterBar,
  updateRooQuestWithoutCall,
  setShowInfoModal,
  filters,
}) => {
  const { t } = useTranslation();
  let rooquests = rooquest.rooquests;

  const getRQs = async () => {
    try {
      let geo = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });
      await getAllRooQuests(
        filters.rooquestSearchRadius,
        geo.coords.latitude,
        geo.coords.longitude
      );
    } catch (err) {
      await getAllRooQuests();
      console.error(err);
    }
  };

  // Filters
  if (feedFilterBar.filters.showSaved) {
    rooquests = rooquests.filter(
      (rq) => auth.user.savedRooQuests.indexOf(rq._id) !== -1
    );
  } else {
    rooquests = rooquest.rooquests;
  }

  useEffect(() => {
    getRQs();
    if (rooquest.rooquests.length === 0) {
      getRQs();
    }
    // eslint-disable-next-line
  }, []);

  const delivererFeedContentRef = showRef;
  const drawerRef = useRef(null);

  const [drawerIcon, setDrawerIcon] = useState(chevronUpOutline);

  const toggleDrawer = () => {
    let c = drawerRef.current;
    if (c.dataset.open === 'true') {
      closeDrawer();
    } else {
      c.style.transition = '.5s ease-in';
      c.style.transform = `translateY(${-350}px) `;
      c.dataset.open = 'true';
      setDrawerIcon(chevronDownOutline);
    }
  };

  const closeDrawer = () => {
    let c = drawerRef.current;
    c.style.transition = '.5s ease-out';
    c.style.transform = '';
    c.dataset.open = 'false';
    setDrawerIcon(chevronUpOutline);
  };

  const openItemModal = (e, rq) => {
    if (!e.target.className.split(' ').includes('DONT_TRIGGER_CARD_CLICK')) {
      updateRooQuestWithoutCall(rq);
      setShowInfoModal('RQ', true);
    }
  };

  const [showSetupDelivererModal, setShowSetupDelivererModal] = useState(false);

  useEffect(() => {
    toggleDrawer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="delivererFeedContent hidden" ref={delivererFeedContentRef}>
      <IonCard className="bottom-drawer" ref={drawerRef}>
        <div style={{ textAlign: 'center' }}>
          <IonButton
            size="small"
            shape="round"
            style={{ height: 20, width: 120 }}
            onClick={toggleDrawer}
          >
            <IonIcon icon={drawerIcon}></IonIcon>
          </IonButton>
        </div>

        {/* <FeedFilterBar refreshFxn={getRQs} /> */}

        <div className="rqItems">
          {auth && auth.user && !auth.user.stripeAccountID && (
            <div
              className="setupDeliveryAccount"
              onClick={() => setShowSetupDelivererModal(true)}
            >
              <p>
                <IonIcon icon={alertOutline}></IonIcon>{t("Setup Delivery Account")}
              </p>
            </div>
          )}

          {auth.loading || rooquest.loading ? (
            <Spinner />
          ) : rooquests && rooquests.length > 0 ? (
            rooquests.map((rq) => {
              let showOfferText = [];
              if (rq && rq.offers && rq.offers.length > 0) {
                showOfferText = rq.offers.filter(item => item.status === "ACTIVE" && auth && auth.user  && auth.user._id == item.delivererID )
              }
              const dateElements = getDateElements(rq.date);
              return (
                <div
                  className="clickableDiv"
                  onClick={(e) => openItemModal(e, rq)}
                  key={`feedActive_${rq._id}`}
                >
                  <RQCard
                    rooquest={rq}
                    extraStyling={{ borderRight: '4px solid #048800' }}
                  >
                    <MyRQCardTop rooquest={rq} type="feed" />
                    <div className="RQFeedCardContent">
                      <div className="mainContent">
                        <div className="leftRouteInfo">
                          <RouteInfoVertical rooquest={rq} />
                        </div>
                        <div className="rightRQInfo">
                          <div className="actionButtons">
                            <div className="saveComponent DONT_TRIGGER_CARD_CLICK">
                              <SaveRooQuestComponent
                                rooquest={rq}
                                extraStyling={{}}
                              />
                            </div>
                            <div
                              className="viewOnMap"
                              onClick={() => {
                                closeDrawer();
                                if (rq.pickupLocationType === 'address') {
                                  setPanToLocation({
                                    lat: rq.pickupAddress.lat,
                                    lng: rq.pickupAddress.lng,
                                  });
                                } else {
                                  setPanToLocation({
                                    lat: rq.deliveryAddress.lat,
                                    lng: rq.deliveryAddress.lng,
                                  });
                                }
                              }}
                            >
                              <p className="DONT_TRIGGER_CARD_CLICK">{t('View')}</p>
                              <IonIcon
                                icon={mapOutline}
                                className="DONT_TRIGGER_CARD_CLICK"
                              />
                            </div>
                          </div>

                          <div className="posted">
                            <p>
                              <span>{t('Posted')}:</span> <br /> {dateElements.month}{' '}
                              {dateElements.date} {dateElements.year} <br />{' '}
                              {dateElements.formattedTime}
                            </p>
                          </div>
                          {showOfferText && showOfferText.length > 0 && < div className='offersubmitted'>
                            <p className='offersubmittext'>{t('Offer Submitted')}</p>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </RQCard>
                </div>
              );
            })
          ) : (
            <img
              className="noRQs"
              src={require('./NoRooQuestsToShow.png')}
              alt=""
            />
          )}
        </div>
      </IonCard>
      <div
        className="backMenuButton"
        onClick={() => {
          setHomeView('landing');
          setMapState('landing');
        }}
      >
        <IonIcon icon={arrowBack}></IonIcon>
      </div>

      <DelivererSetupModal
        showModal={showSetupDelivererModal}
        setShowModal={setShowSetupDelivererModal}
      ></DelivererSetupModal>
    </div>
  );
};

BottomDrawer.propTypes = {
  setHomeView: PropTypes.func.isRequired,
  setMapState: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  rooquest: PropTypes.object.isRequired,
  feedFilterBar: PropTypes.object.isRequired,
  getAllRooQuests: PropTypes.func.isRequired,
  setPanToLocation: PropTypes.func.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  setShowInfoModal: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  rooquest: state.rooquest,
  feedFilterBar: state.feedFilterBar,
  filters: state.feedFilterBar.filters,
});

export default connect(mapStateToProps, {
  getAllRooQuests,
  setPanToLocation,
  updateRooQuestWithoutCall,
  setShowInfoModal,
})(BottomDrawer);
