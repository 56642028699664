import React from 'react';
import PropTypes from 'prop-types';
import { IonToast } from '@ionic/react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { setConversation, setChatMessages } from '../../actions/chat/chat';
import { getRooQuestByID } from '../../actions/rooquest/rooquest';

const Toast = ({
  toasts,
}) => {
  const history = useHistory();

  const redirectToPage = async (location) => {
    history.push(location);
  };

  return (
    toasts !== null &&
    toasts.length > 0 &&
    toasts.map((toast) => (
      <div key={toast.id}>
        <IonToast
          isOpen={true}
          mode="ios"
          color="dark"
          message={toast.msg}
          position={toast.position}
          buttons={
            toast.redirectTo !== null
              ? [
                  {
                    side: 'end',
                    text: toast.redirectTo.message,
                    handler: () => {
                      redirectToPage(toast.redirectTo.page);
                    },
                  },
                ]
              : []
          }
        />
      </div>
    ))
  );
};

Toast.propTypes = {
  toasts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  toasts: state.toast.toasts,
});

export default connect(mapStateToProps, {
  setConversation,
  setChatMessages,
  getRooQuestByID,
})(Toast);
