import React, { useEffect, useState } from 'react';
import './MyRooQuestsPopover.scss';
import { IonSegment, IonSegmentButton, IonLabel, IonIcon, IonItem, IonSelectOption, IonSelect } from '@ionic/react';
import PopoverCard from '../../../components/layout/PopoverCard/PopoverCard';
import RQCard from '../../../components/layout/RQCard/RQCard';
import { connect } from 'react-redux';
import {
  getMyRooQuests,
  getRooQuestByID,
  updateRooQuestWithoutCall,
} from '../../../actions/rooquest/rooquest';
import PropTypes from 'prop-types';
import MyRQCardTop from '../../../components/layout/RQCard/MyRooQuests/MyRQCardTop';
import getStatusTextAndColour from '../../../utils/getStatusTextAndColour';
import { pencil, person, time, location,map } from 'ionicons/icons';
import { formatRelativeTime } from '../../../utils/formatDate';
import { setShowInfoModal } from '../../../actions/layout/navigation';
import { useHistory } from 'react-router-dom';
import { USE_GPS_TRACKING } from '../../../App';
import { useTranslation } from 'react-i18next';
import moment from 'moment'
const MyRooQuestsPopover = ({
  auth,
  rooquest,
  getMyRooQuests,
  updateRooQuestWithoutCall,
  setShowInfoModal,
  getRooQuestByID,
}) => {
  console.log('...rendering')
  console.log(rooquest)
  const { t } = useTranslation();
  useEffect(() => {
    getMyRooQuests();
  }, [getMyRooQuests]);

  const [segmentState, setSegmentState] = useState('none');
  let myrooquests = rooquest.myrooquests;

  // Filters
  if (Array.isArray(rooquest.myrooquests)) {
    if (segmentState === 'none') {
      myrooquests = myrooquests.sort((a,b)=>{
        return +new Date(b.date) - +new Date(a.date)
      }
      )
      // myrooquests = myrooquests.filter(
      //   (rq) => (rq.status !== 'ROOQUEST_DELIVERED' && rq.status !== 'EXPIRED')
      // );
    }
    else if(segmentState === 'expired')
    {
      myrooquests = myrooquests.filter(
          (rq) => (rq.status === 'EXPIRED')
        );
    }
    else if(segmentState === 'active')
    {
      myrooquests = myrooquests.filter(
        (rq) => (rq.status === 'ACTIVE')
      );
    }
    else if(segmentState === 'accepted')
    {
      myrooquests = myrooquests.filter(
        (rq) => (rq.status === 'RQ_ACCEPTED')
      );
    }
    else if(segmentState === 'delivered')
    {
      myrooquests = myrooquests.filter(
        (rq) => (rq.status === 'ROOQUEST_DELIVERED')
      );
    }
    else if(segmentState === 'on the way')
    {
      myrooquests = myrooquests.filter(
        (rq) => (rq.status === 'RQ_ONTHEWAY')
      );
    }
    else if(segmentState === 'inactive')
    {
      myrooquests = myrooquests.filter(
        (rq) => (rq.status === 'INACTIVE')
      );
    }
    else if(segmentState === 'waiting')
    {
      myrooquests = myrooquests.filter(
        (rq) => (rq.status === 'ROOQUEST_WAITING_CLIENT_CONFIRMATION')
      );
    }
    else if(segmentState === 'under review')
    {
      myrooquests = myrooquests.filter(
        (rq) => (rq.status === 'UNDER_REVIEW')
      );
    }
    else if(segmentState === 'pending')
    {
      myrooquests = myrooquests.filter(
        (rq) => (rq.status === 'PENDING_PAYMENT')
      );
    }

    
    // PENDING_PAYMENT: 'PENDING_PAYMENT',
    // RQ_ONTHEWAY: 'RQ_ONTHEWAY',
    // RQ_ACCEPTED: 'RQ_ACCEPTED',
    // ACTIVE: 'ACTIVE',
    // INACTIVE: 'INACTIVE',
    // ROOQUEST_DELIVERED: 'ROOQUEST_DELIVERED',
    // ROOQUEST_WAITING_CLIENT_CONFIRMATION: 'ROOQUEST_WAITING_CLIENT_CONFIRMATION',
    // UNDER_REVIEW: 'UNDER_REVIEW',
    // EXPIRED: 'EXPIRED',
    // if (segmentState === 'active') {
    //   myrooquests = myrooquests.filter(
    //     (rq) => (rq.status !== 'ROOQUEST_DELIVERED' && rq.status !== 'EXPIRED')
    //   );
    // } else {
    //   myrooquests = myrooquests.filter(
    //     (rq) => (rq.status === 'ROOQUEST_DELIVERED' || rq.status === 'EXPIRED')
    //   ) ;
    // }
  }
  const history = useHistory();
  const goToDeliverNavMap = (id, rq) => {
    history.push(`/deliverNavMap/${id}`, {rooquest: rq})
  }
  const options = {
    cssClass: 'my-custom-interface'
  };
  return (
    <div className="MyRooQuestsPopover">
      <PopoverCard title={t('My RooQuests')}>
        <div className="segmentContainer">
          {/* <IonSegment
            mode="ios"
            value={segmentState}
            onIonChange={(e) => setSegmentState(e.detail.value!)}
          >
            <IonSegmentButton value="active" id="activeBtn">
              <IonLabel>{t('Active')}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="history">
              <IonLabel>{t('History')}</IonLabel>
            </IonSegmentButton>
          </IonSegment> */}
          
        </div>
        <div className='filter'>
        <IonItem>
          <IonLabel>{t('Sort By')}</IonLabel>
              <IonSelect interfaceOptions={options} value={segmentState} onIonChange={(e) => setSegmentState(e.detail.value)}>
              <IonSelectOption value="none">{t('Date Posted')}</IonSelectOption>
              <IonSelectOption value="expired">{t('Expired Rooquests')}</IonSelectOption>
              <IonSelectOption value="active">{t('Active Rooquests')}</IonSelectOption>
              <IonSelectOption value="accepted">{t('Accepted Rooquests')}</IonSelectOption>
              <IonSelectOption value="on the way">{t('On the way')}</IonSelectOption>
              <IonSelectOption value="delivered">{t('Rooquests Delivered')}</IonSelectOption>
              {/* <IonSelectOption value="under review">Rooquests Under Review</IonSelectOption> */}
              {/* <IonSelectOption value="waiting">ROOQUEST_WAITING_CLIENT_CONFIRMATION</IonSelectOption> */}
              <IonSelectOption value="inactive">{t('INACTIVE Rooquests')}</IonSelectOption>
              {/* <IonSelectOption value="pending">PENDING_PAYMENT</IonSelectOption> */}
              </IonSelect>
        </IonItem>
        </div>

        <div className="bodyContent">
          <div className="rqCards">
            {myrooquests &&
              myrooquests.length > 0 && 
              myrooquests.map((rq) => {
                const statusObj = getStatusTextAndColour(
                  rq.status,
                  rq.requester,
                  'myrooquests',
                  auth.user.color_theme
                );
                return (
                  <RQCard
                    rooquest={rq}
                    key={`myRQ_active_${rq._id}`}
                    extraStyling={statusObj.borderStyle}
                    onClickFxn={() => {
                        getRooQuestByID(rq._id);
                        setShowInfoModal('MYRQ', true);
                    }}
                  >
                    <div className="rqCardContent">
                      <MyRQCardTop rooquest={rq} statusObj={statusObj} />
                      <div className="additionalInfoContainer">
                        {/* DELIVERER NAME */}
                        {rq &&
                          (rq.status === 'RQ_ACCEPTED' ||
                            rq.status === 'ROOQUEST_DELIVERED') && (
                            <div className="additionalInfo">
                              <IonIcon
                                icon={person}
                                style={{ color: statusObj.colour.color }}
                              />
                              <p>
                                <span>{t('Deliverer')}: </span>
                                {rq.delivererFirstName}
                              </p>
                            </div>
                          )}

                        {/* POSTED */}
                        {rq &&
                          (rq.status === 'ACTIVE' ||
                            rq.status === 'RQ_ACCEPTED' ||
                            rq.status === 'ROOQUEST_DELIVERED') && (
                            <div className="additionalInfo">
                              <IonIcon
                                icon={pencil}
                                style={{ color: statusObj.colour.color }}
                              />
                              <p>
                                <span>{t('Posted')}: </span>
                                {formatRelativeTime(rq.date)}
                              </p>
                            </div>
                        )}

                        {/* ACTIVE */}
                        {rq && rq.status === 'ACTIVE' && rq.expiryDate && (
                            <div className="additionalInfo">
                              <IonIcon
                                icon={time}
                                style={{ color: statusObj.colour.color }}
                              />
                              <p>
                                <span>{t('Expires in')}: </span>
                                <ExpiryCountdown rooquest={rq} />
                              </p>
                            </div>
                        )}

                        {/* ACCEPTED TIME */}
                        {rq &&
                          (rq.status === 'RQ_ACCEPTED' ||
                            rq.status === 'ROOQUEST_DELIVERED') && (
                            <div className="additionalInfo">
                              <IonIcon
                                icon={time}
                                style={{ color: statusObj.colour.color }}
                              />
                              <p>
                                <span>{t('Accepted')}: </span>
                                {formatRelativeTime(rq.acceptedDate)}
                              </p>
                            </div>
                          )}

                        {/* show tracking */}
                         {rq &&
                          rq.status === 'RQ_ONTHEWAY' && USE_GPS_TRACKING && (
                            <div className="additionalInfo rqtextcenter">
                              <IonIcon
                                icon={map}
                                style={{ color: statusObj.colour.color }}
                                onClick={(e) => { e.preventDefault(); setShowInfoModal('MYRQ', true); }}
                              />
                            </div>
                          )}

                        {rq &&
                          rq.status === 'RQ_ONTHEWAY' && USE_GPS_TRACKING && (
                            <div className="additionalInfo rqtextcenter">
                              <p>
                                <span onClick={(e) => { e.preventDefault(); setShowInfoModal('MYRQ', true); }}>{t('Track your order')} </span>
                              </p>


                            </div>
                          )}

                        {/* DELIVERED TIME */}
                        {rq && rq.status === 'ROOQUEST_DELIVERED' && (
                          <div className="additionalInfo">
                            <IonIcon
                              icon={location}
                              style={{ color: statusObj.colour.color }}
                            />
                            <p>
                              <span>{t('Delivered')}: </span>
                              {formatRelativeTime(rq.deliveredDate)}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </RQCard>
                );
              })}
            {!rooquest.loading &&
              rooquest.myrooquests &&
              (rooquest.myrooquests.length === 0 ||
                !Array.isArray(rooquest.myrooquests)) && (
                <img
                  className="noRQs"
                  src={require('../NoRooQuestsToShow.png')}
                  alt="no rqs"
                />
              )}
          </div>
        </div>
      </PopoverCard>
    </div>
  );
};

MyRooQuestsPopover.propTypes = {
  auth: PropTypes.object.isRequired,
  rooquest: PropTypes.object.isRequired,
  getMyRooQuests: PropTypes.func.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  setShowInfoModal: PropTypes.func.isRequired,
  getRooQuestByID: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  rooquest: state.rooquest,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getMyRooQuests,
  updateRooQuestWithoutCall,
  setShowInfoModal,
  getRooQuestByID
})(MyRooQuestsPopover);

export const ExpiryCountdown = ({rooquest}) => { 
  const calculateTimeLeft = (date) => {
    let difference = +new Date(date) - +new Date();
    if (difference > 0) {
        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }
    return {};
}

  const [countdown, setCountdown] = useState(calculateTimeLeft(rooquest.expiryDate));
    useEffect(() => {
        const timer = setTimeout(() => {
            const timeLeft = calculateTimeLeft(rooquest.expiryDate);
            setCountdown(timeLeft);
        }, 1000);
        return () => clearTimeout(timer);
    });

    const timerComponents = [];

    Object.keys(countdown).forEach((interval, index) => {
        if (countdown[interval] === null) return;

        // Make sure component has 2 digits
        let value = (countdown[interval] === 0) 
            ? '00' 
            : (countdown[interval] < 10)
                ? `0${countdown[interval]}`
                : countdown[interval].toString();

        // If interval is days, remove leading 0
        if (interval === 'days') {
            value = value.substr(1);
        }


        if (timerComponents.length === 0 && value === '00') return;

        if (interval !== 'days' && (countdown['days'] === 0 || countdown['days'] === null)) {
            timerComponents.push(
                <span key={`offer-countdown-${interval}}`}>
                {value}{(index !== Object.keys(countdown).length - 1) ? ":" : ""}
                </span>
            );
        }
        else if (interval === 'days') {
            timerComponents.push(
                <span key={`offer-countdown-${interval}}`}>
                {value}{(index !== Object.keys(countdown).length - 1) && " day" + (value > 1 ? 's' : '')}{' '}
                </span>
            );
        }        
    });

  return (
    <>
      {rooquest.expiryDate &&
          <>
              {(timerComponents.length !== 0) 
                  ? (timerComponents) 
                  : <span className='warning'>Expired</span>}
          </>
      }
    </>
  )
}

