import React, { useState, Fragment } from 'react';
import {
  IonRow,
  IonCol,
  IonChip,
  IonLabel,
  IonIcon,
  IonModal,
  IonButton,
  IonRange,
  IonItem,
  IonSegment,
  IonSegmentButton,
  IonInput
} from '@ionic/react';
import './FeedFilterBar.scss';
import {
  optionsOutline,
  heartOutline,
  heart,
  refreshOutline,
} from 'ionicons/icons';
import { connect } from 'react-redux';
import { getAllRooQuests } from '../../../actions/rooquest/rooquest';
import {
  showSavedRQs,
  unShowSavedRQs,
  showMapMode,
  setRooQuestSearchRadiusFilter,
} from '../../../actions/layout/feedFilterBar';

import PropTypes from 'prop-types';
// import { Plugins } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { launchToast,launchToastCard } from '../../../actions/layout/toast';
import { useTranslation } from 'react-i18next';
// const { Geolocation } = Plugins;

const FeedFilterBar = ({
  showSavedRQs,
  unShowSavedRQs,
  refreshFxn,
  setRooQuestSearchRadiusFilter,
  filters,
  getAllRooQuests,
  launchToast,
  launchToastCard,
  setShowPopover,
  showSaved,
  setShowSaved
}) => {
  const { t } = useTranslation();
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [radiusSliderValue, setRadiusSliderValue] = useState(
    filters.rooquestSearchRadius
  );
  const [radiusUpperRange, setRadiusUpperRange] = useState(2500);

  const toggleShowSaved = () => {
    setShowSaved(!showSaved);
  };

  const saveModalFilters = async () => {
    try {
      let geo = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });
      getAllRooQuests(
        radiusSliderValue,
        geo.coords.latitude,
        geo.coords.longitude
      );
      setRooQuestSearchRadiusFilter(radiusSliderValue);
      setShowFilterModal(false);
    } catch (err) {
      // launchToast('Location Disabled. Please Enable Location', 3000, 'top');
      launchToastCard({ 
        msg: 'Location Disabled. Please Enable Location',
        type: 'error',
        duration: 8500,
      })
      console.error(err);
      setShowFilterModal(false);
    }
  };

  const closeFilterModal = () => {
    setRadiusSliderValue(filters.rooquestSearchRadius);
    setShowFilterModal(false);
  };

  const setInputRadiusValue = (e) => {
    if(e)
      setRadiusSliderValue(e);
    else
      setRadiusSliderValue(0)
  }

  return (
    <Fragment>
      <IonRow>
        <IonCol>
          <div className="contentWrapper">
            <div className="filterIcon">
              <IonChip
                outline
                className="item"
                onClick={() => setShowPopover(true)}
              >
                <IonLabel></IonLabel>
                <IonIcon icon={optionsOutline} />
                <IonLabel>{t('Filters')}</IonLabel>
              </IonChip> 
            </div>
            <div className="savedIcon">
              <IonChip outline className="item" onClick={toggleShowSaved}>
                <IonLabel></IonLabel>
                {showSaved ? (
                  <IonIcon icon={heart} />
                ) : (
                  <IonIcon icon={heartOutline} />
                )}
                <IonLabel>{t('Saved')}</IonLabel>
              </IonChip>
            </div>
            <div className="refreshIcon">
              <IonChip outline className="item" onClick={() => refreshFxn()}>
                <IonLabel></IonLabel>
                <IonIcon icon={refreshOutline} />
                <IonLabel>{t('Refresh')}</IonLabel>
              </IonChip>
            </div>
          </div>
        </IonCol>
      </IonRow>
      <IonModal
        mode="ios"
        isOpen={showFilterModal}
        class="filterModal"
        // showBackdrop={true}
        backdropDismiss={true}
        onWillDismiss={() => closeFilterModal()}
      >
        <div className="modalContent">
          <h2 className="modalTitle">{t('Filters')}</h2>

          <div className="inputElements">
            <div className="radius">
              <IonItem lines="none">
                <IonRange
                  min={0}
                  max={radiusUpperRange}
                  value={radiusSliderValue}
                  mode="md"
                  pin={true}
                  onIonChange={(e) =>
                    setRadiusSliderValue(e.detail.value as number)
                  }
                >
                  <IonLabel className="radiusLabel" slot="start">
                    {t('Radius')}
                  </IonLabel>
                  <IonLabel className="radiusLabel" slot="end">
                    {radiusSliderValue}km
                  </IonLabel>
                </IonRange>
              </IonItem>
                <IonInput
                      type='number'
                      minlength={0}
                      value={radiusSliderValue}
                      onIonChange={(e) => setInputRadiusValue(parseInt(e.detail.value))}
                      className='radius-center'
                />
              {/* <input type="number" min={0} value={radiusSliderValue} onChange={(e) => setRadiusSliderValue(parseInt(e.target.value))} />  */}
              <IonSegment
                onIonChange={(e) => {
                  setRadiusUpperRange(parseInt(e.detail.value));
                  if (e.detail.value === '2500' && radiusSliderValue > 2500) {
                    setRadiusSliderValue(2500);
                  }
                }}
                value={radiusUpperRange.toString()}
              >
                {/* <IonSegmentButton value="150">
                  <IonLabel>{t('Short Range')}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="2000">
                  <IonLabel>{t('Long Range')}</IonLabel>
                </IonSegmentButton> */}
              </IonSegment>
            </div>
          </div>

          <div className="actionButtons">
            <div className="row">
              <IonButton onClick={() => setShowFilterModal(false)}>
                {t('Close')}
              </IonButton>
              <IonButton onClick={() => saveModalFilters()}>{t('Save')}</IonButton>
            </div>
          </div>
        </div>
      </IonModal>
    </Fragment>
  );
};

FeedFilterBar.propTypes = {
  filters: PropTypes.object.isRequired,
  showSavedRQs: PropTypes.func.isRequired,
  showMapMode: PropTypes.func.isRequired,
  refreshFxn: PropTypes.func,
  setRooQuestSearchRadiusFilter: PropTypes.func,
  getAllRooQuests: PropTypes.func.isRequired,
  launchToast: PropTypes.func.isRequired,
  launchToastCard:PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.feedFilterBar.filters,
});

export default connect(mapStateToProps, {
  showSavedRQs,
  getAllRooQuests,
  unShowSavedRQs,
  showMapMode,
  setRooQuestSearchRadiusFilter,
  launchToast,
  launchToastCard
})(FeedFilterBar);
