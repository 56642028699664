import React, { useEffect } from 'react';
import './MessagesPopover.scss';
import PopoverCard from '../../../components/layout/PopoverCard/PopoverCard';
import {
  getAllConversations,
  setConversation,
  setChatMessages,
  getConversationByChatID,
  setShowChatModal,
} from '../../../actions/chat/chat';
import { connect } from 'react-redux';
import MessageCard from './MessageCard';
import PropTypes from 'prop-types';
import Spinner from '../../../components/layout/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

const MessagesPopover = ({
  auth,
  chat,
  getAllConversations,
  setConversation,
  getConversationByChatID,
  setShowChatModal,
}) => {
  const { t } = useTranslation();
  const openChat = async (convo) => {
    const updatedConvo = await getConversationByChatID(convo._id);
    await setConversation(updatedConvo);
    setShowChatModal(true);
  };
 
  useEffect(() => {
    const asyncGetConvos = async () => {
      await getAllConversations(auth.user._id);
    };
    asyncGetConvos();
    // eslint-disable-next-line
  }, []);

  const checkForMessages = (convos) => {
    convos.forEach((convo) => {
      if (convo.messages && convo.messages.length > 0) {
        return true;
      }
    });
    return false;
  };

  return (
    <div className="MessagesPopover">
      <PopoverCard title={t('My Messages')}>
        <div className="bodyContent">
          <div className="messagesContainer">
            {chat && chat.loading && <Spinner />}
            {auth &&
              auth.user &&
              chat &&
              !chat.loading &&
              chat.conversations &&
              chat.conversations.map((convo) => {
                if (convo.messages.length > 0) {
                  return (
                    <MessageCard
                      key={`messageCard-${convo._id}`}
                      conversation={convo}
                      onClick={() => openChat(convo)}
                    />
                  );
                } else {
                  return null;
                }
              })}
            {chat &&
              !chat.loading &&
              chat.conversations &&
              chat.conversations.length === 0 &&
              !checkForMessages(chat.conversations) && (
                <img
                  className="noMessages"
                  src={require('./NoMessages.png')}
                  alt="no messages"
                />
              )}
          </div>
        </div>
      </PopoverCard>
    </div>
  );
};

MessagesPopover.propTypes = {
  auth: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired,
  rooquest: PropTypes.object.isRequired,
  setConversation: PropTypes.func.isRequired,
  getAllConversations: PropTypes.func.isRequired,
  setChatMessages: PropTypes.func.isRequired,
  getConversationByChatID: PropTypes.func.isRequired,
  setShowChatModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  chat: state.chat,
  rooquest: state.rooquest,
});

export default connect(mapStateToProps, {
  getAllConversations,
  setConversation,
  setChatMessages,
  getConversationByChatID,
  setShowChatModal,
})(MessagesPopover);
