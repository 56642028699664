import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './TutorialModal.scss';
import { connect } from 'react-redux';
import {
  IonModal,
  IonButton,
  IonSlides,
  IonSlide,
  IonItem,
  IonInput,
  useIonViewDidEnter,
  IonIcon,
} from '@ionic/react';
import { setShowTutorialModal } from '../../../actions/layout/navigation';
import { tutorialCompleted } from '../../../actions/user/user';
import sendRequests from '../../../hooks/sendRequests';
import { loadUser } from '../../../actions/auth/auth';
import { launchToast,launchToastCard } from '../../../actions/layout/toast';
import { useTranslation } from 'react-i18next';
import { arrowForwardSharp, arrowBackSharp} from 'ionicons/icons';

const TutorialModal = ({
  auth,
  navigation,
  setShowTutorialModal,
  tutorialCompleted,
  loadUser,
  launchToast,
  launchToastCard
}) => {
  const { t } = useTranslation();
  const { visible, options } = navigation.showTutorialModal;
  const [isFirstCard, setFirstCard]=useState(false);
  const [isLastCard, setLastCard]=useState(true);
  const logoRef = useRef(null);

  const showActivationCode =
    options && options.showActivationCode !== null
      ? options.showActivationCode
      : true;

  const slideOpts = {
    initialSlide: 0,
    speed: 300,
    flipEffect: {
      slideShadows: true,
    },
    on: {
      beforeInit() {
        const swiper = this;
        swiper.classNames.push(`${swiper.params.containerModifierClass}flip`);
        swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);
        const overwriteParams = {
          slidesPerView: 1,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
          watchSlidesProgress: true,
          spaceBetween: 0,
          virtualTranslate: true,
        };
        swiper.params = Object.assign(swiper.params, overwriteParams);
        swiper.originalParams = Object.assign(
          swiper.originalParams,
          overwriteParams
        );
      },
      setTranslate() {
        const swiper = this;
        const { slides, rtlTranslate: rtl } = swiper;
        for (let i = 0; i < slides.length; i += 1) {
          const $slideEl = slides.eq(i);
          let progress = $slideEl[0].progress;
          if (swiper.params.flipEffect.limitRotation) {
            progress = Math.max(Math.min($slideEl[0].progress, 1), -1);
          }
          const offset$$1 = $slideEl[0].swiperSlideOffset;
          const rotate = -180 * progress;
          let rotateY = rotate;
          let rotateX = 0;
          let tx = -offset$$1;
          let ty = 0;
          if (!swiper.isHorizontal()) {
            ty = tx;
            tx = 0;
            rotateX = -rotateY;
            rotateY = 0;
          } else if (rtl) {
            rotateY = -rotateY;
          }

          $slideEl[0].style.zIndex =
            -Math.abs(Math.round(progress)) + slides.length;

          if (swiper.params.flipEffect.slideShadows) {
            // Set shadows
            let shadowBefore = swiper.isHorizontal()
              ? $slideEl.find('.swiper-slide-shadow-left')
              : $slideEl.find('.swiper-slide-shadow-top');
            let shadowAfter = swiper.isHorizontal()
              ? $slideEl.find('.swiper-slide-shadow-right')
              : $slideEl.find('.swiper-slide-shadow-bottom');
            if (shadowBefore.length === 0) {
              shadowBefore = swiper.$(
                `<div class="swiper-slide-shadow-${
                  swiper.isHorizontal() ? 'left' : 'top'
                }"></div>`
              );
              $slideEl.append(shadowBefore);
            }
            if (shadowAfter.length === 0) {
              shadowAfter = swiper.$(
                `<div class="swiper-slide-shadow-${
                  swiper.isHorizontal() ? 'right' : 'bottom'
                }"></div>`
              );
              $slideEl.append(shadowAfter);
            }
            if (shadowBefore.length)
              shadowBefore[0].style.opacity = Math.max(-progress, 0);
            if (shadowAfter.length)
              shadowAfter[0].style.opacity = Math.max(progress, 0);
          }
          $slideEl.transform(
            `translate3d(${tx}px, ${ty}px, 0px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`
          );
        }
      },
      setTransition(duration) {
        const swiper = this;
        const { slides, activeIndex, $wrapperEl } = swiper;
        slides
          .transition(duration)
          .find(
            '.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left'
          )
          .transition(duration);
        if (swiper.params.virtualTranslate && duration !== 0) {
          let eventTriggered = false;
          // eslint-disable-next-line
          slides.eq(activeIndex).transitionEnd(function onTransitionEnd() {
            if (eventTriggered) return;
            if (!swiper || swiper.destroyed) return;

            eventTriggered = true;
            swiper.animating = false;
            const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
            for (let i = 0; i < triggerEvents.length; i += 1) {
              $wrapperEl.trigger(triggerEvents[i]);
            }
          });
        }
      },
    },
  };
  const slidesRef = useRef(null);

  const [activationCodeState, setActivationCodeState] = useState('none');
  const [activationCode, setActivationCode] = useState('');

  const onSubmitActivationCode = async () => {
    if (activationCode !== '') {
      const { doRequest } = sendRequests({
        url: '/api/promos/',
        method: 'post',
        body: {
          userId: auth.user._id,
          firstName: auth.user.firstName,
          lastName: auth.user.lastName,
          promoCode: activationCode,
          email: auth.user.email,
        },
        onSuccess: (data) => {
          const status = data.status;
          if (status === 200) {
            setActivationCodeState('success');
            // launchToast('Added Activation Code Successfully.', 2000, 'bottom');
            launchToastCard({ 
              msg: 'Promo Code Added Successfully.',
              type: 'success',
              duration: 8500,
          })
          } else {
            setActivationCodeState('failure');
            // launchToast('Invalid Activation Code.', 2000, 'bottom');
            launchToastCard({ 
              msg: 'Invalid Promo Code.',
              type: 'error',
              duration: 8500,
          })
          }
        },
      });
      await doRequest();
      await loadUser();
    } else {
      setActivationCodeState('failure');
      // launchToast('Invalid Activation Code.', 2000, 'bottom');
      launchToastCard({ 
        msg: 'Invalid Promo Code.',
        type: 'error',
        duration: 8500,
    })
    }
  };

  useIonViewDidEnter(() => {
    slidesRef.current.update();
  });
  const slidePrev = async () =>{
    const swiper = await slidesRef.current.getSwiper();
    swiper.slidePrev();
    if(swiper.activeIndex < 1){
      setFirstCard(false)
    }
    if(swiper.activeIndex < 4){
      setLastCard(true)
    }
  }
  const slideNext = async () =>{
    const swiper = await slidesRef.current.getSwiper();
    swiper.slideNext();
    if(swiper.activeIndex > 0){
      setFirstCard(true)
    }
    if(swiper.activeIndex > 3){
      setLastCard(false)
    }
  }
  return (
    <div className="tutorialModalHolder">
      <IonModal
        isOpen={visible}
        class={'tutorialModal'}
        mode="md"
        onWillPresent={async () => {
          await slidesRef.current.update();
        }}
        backdropDismiss={false}
      >
        <div className="tutorialModalContent">
          <div>
            {isFirstCard ? <div className='tutoriallefticon' onClick={slidePrev}>
              <IonIcon icon={arrowBackSharp}></IonIcon>
            </div> : null}
            {isLastCard ? <div className='tutorialrighticon' onClick={slideNext}>
              <IonIcon icon={arrowForwardSharp}></IonIcon>
            </div> : null}
          </div>
          
          { visible &&  <IonSlides ref={slidesRef} pager={true} options={slideOpts}>
            <IonSlide>
              <div className="slide1">
                <div className="slideContent">
                  <div className="topContent">
                    <img
                      className="confetti"
                      src={require('./confetti.png')}
                      alt="slide"
                    />
                    <img
                      className="logo"
                      src={require('./Bounce1-nb-lightgreen.png')}
                      alt="slide"
                    ></img>
                  </div>
                  <div className="bottomContent">
                    <h1>{t('Welcome to Roo!')}</h1>
                    <p>
                    {t('Save the time and hassle of going back and forth by getting goods delivered to you. Within the same app, you can also get paid to be the deliverer of goods near you!')}
                    </p>
                  </div>
                </div>
              </div>
            </IonSlide>

            {showActivationCode && (
              <IonSlide>
                <div className="slide2">
                  <div className="slideContent">
                    <div className="topContent">
                      <img
                        className="topImage"
                        src={require('./mobileRoo.svg')}
                        alt="slide"
                      ></img>
                    </div>
                    <div className="bottomContent">
                      <h1>{t("Promo Code")}?</h1>
                      <p>{t('Were you given a promo code? Enter it below!')}</p>
                      <IonItem className={activationCodeState}>
                        <IonInput
                          placeholder={t("Promo Code")}
                          value={activationCode}
                          onIonChange={(e) => {
                            setActivationCodeState('none');
                            setActivationCode(e.detail.value!);
                          }}
                        ></IonInput>
                        {activationCodeState !== 'success' && (
                          <IonButton
                            onClick={async () => {
                              // setActivationCodeState('failure');
                              await onSubmitActivationCode();
                            }}
                          >
                            {t("Submit Code")}
                          </IonButton>
                        )}
                      </IonItem>
                    </div>
                  </div>
                </div>
              </IonSlide>
            )}

            <IonSlide>
              <div className="slide3">
                <div className="slideContent">
                  <div className="topContent">
                    <img
                      className="topImage"
                      src={require('./req-delivery.svg')}
                      alt="slide"
                    ></img>
                    <h2>{t('Name It, Track It, Get It')}</h2>
                  </div>
                  <div className="bottomContent">
                    <h1>
                    {t('Get Anything Delivered')}
                    </h1>
                    <p>
                    {t('Describe what you need, where and when you want it. Track the delivery and receive your items!')}
                    </p>
                  </div>
                </div>
              </div>
            </IonSlide>

            <IonSlide>
              <div className="slide4">
                <div className="slideContent">
                  <div className="topContent">
                    {' '}
                    <img
                      className="topImage"
                      src={require('./deliv-delivery.svg')}
                      alt="slide"
                    ></img>
                    <h2>{t('Search, Deliver, Get Paid')}</h2>
                  </div>
                  <div className="bottomContent">
                    <h1>
                    {t('Get')} <span>{t('Paid')}</span> {t('to')} <br />
                      {t('Deliver Anything')}
                    </h1>
                    <p>
                      {t('Get paid for on the way deliveries. Just search for items needing to be delivered near you!')}
                    </p>
                  </div>
                </div>
              </div>
            </IonSlide>

            <IonSlide>
              <div className="slide5">
                <div className="slideContent">
                  <div className="topContent">
                    {' '}
                    <img
                      className="topImage"
                      ref={logoRef}
                      src={require('./logo.png')}
                      alt="slide"
                    ></img>
                  </div>
                  <div className="bottomContent">
                    <h1>{t('Ready to Begin?')}</h1>
                    <p>{t('Get started on your Quest!')}</p>
                    <IonButton
                      onClick={() => {
                        if (!auth.user.wasIntroScreenShown) {
                          tutorialCompleted();
                        }
                        logoRef.current.classList.add('rotate');
                        setTimeout(() => {
                          logoRef.current.classList.remove('rotate');
                          setShowTutorialModal(false);
                          setFirstCard(false);
                          setLastCard(true)
                        }, 1000);
                      }}
                    >
                      {t("Let's Go!")}
                    </IonButton>
                  </div>
                </div>
              </div>
            </IonSlide>
          </IonSlides> }
        </div>
      </IonModal>
    </div>
  );
};

TutorialModal.propTypes = {
  navigation: PropTypes.object.isRequired,
  setShowTutorialModal: PropTypes.func.isRequired,
  tutorialCompleted: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  launchToast: PropTypes.func.isRequired,
  launchToastCard: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  navigation: state.navigation,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setShowTutorialModal,
  tutorialCompleted,
  loadUser,
  launchToast,
  launchToastCard
})(TutorialModal);
