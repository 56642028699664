import React, { useState,useEffect  } from 'react';
import { IonContent, IonPage, IonToggle, IonList, IonItem, IonLabel, IonButton, IonAlert, IonSelect, IonSelectOption,IonIcon } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import './EmailNotificationSettings.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsHeader from '../../../components/layout/SettingsHeader/SettingsHeader';
import {updateRooquestEmailMethod,updateDelivererEmailMethod} from '../../../actions/user/user';
import { useTranslation } from 'react-i18next';
const EmailNotificationSettings = ({
    auth,
    updateRooquestEmailMethod,
    updateDelivererEmailMethod
 }) => {
    const { t } = useTranslation();
    const [rooquestchecked, setRooquestChecked] = useState(null);
    const [deliverychecked, setDeliveryChecked] = useState(null);
    const [rooquestunits, setRooquestUnits] = useState("enable");
    const [deliveryunits, setDeliveryUnits] = useState("enable");

    useEffect(() => {
        if (!!auth) {
            if(!!auth.is_requester_email_send && auth.is_requester_email_send){
                setRooquestChecked(true)
                setRooquestUnits("enable")
            } else {
                setRooquestChecked(false)
                setRooquestUnits("disable")
            }
            if(!!auth.is_deliverer_email_send && auth.is_deliverer_email_send){
                setDeliveryChecked(true)
                setDeliveryUnits("enable")
            } else {
                setDeliveryChecked(false)
                setDeliveryUnits("disable")
            }      
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
 
    const changeMyRooquestsToggle = async (e) => {     
        if(e.detail.checked === true){
            
            setRooquestChecked(true);
            updateRooquestUnitMethoddata("enable");
           
        } else {
            setRooquestChecked(false);
            updateRooquestUnitMethoddata("disable");
        }  
      }
    const changeMyDeliveriesToggle = async (e) => {
        if(e.detail.checked === true){
            setDeliveryChecked(true);
            updateDeliveryUnitMethoddata("enable");
           
        } else {
            setDeliveryChecked(false);
            updateDeliveryUnitMethoddata("disable");
        }  
      }
    const updateRooquestUnitMethoddata = async (unitdata) => {
        if (unitdata == "disable"){
            const updatedMethod = await updateRooquestEmailMethod(false);
            if(updatedMethod == false){
                setRooquestUnits("disable");
            }
        } 
        if (unitdata == "enable"){
            const updatedMethod = await updateRooquestEmailMethod(true);
            if(updatedMethod == true){
                setRooquestUnits("enable");
            }
        }
      }
      const updateDeliveryUnitMethoddata = async (unitdata) => {
        if (unitdata == "disable"){
            const updatedMethod = await updateDelivererEmailMethod(false);
            if(updatedMethod == false){
                setDeliveryUnits("disable");
            }
        } 
        if (unitdata == "enable"){
            const updatedMethod = await updateDelivererEmailMethod(true);
            if(updatedMethod == true){
                setDeliveryUnits("enable");
            }
        }
      }
    return (
        <IonPage className="questreq">
            <IonContent>
            <SettingsHeader value={t("Email Notification Settings")}/>
                <div className="FullQuest">
                    <div className="questcontainer questTitle">
                        <div className="fullInnerContainer">
                            <div className="questreqdisplay">
                                <IonList>
                                    {rooquestchecked !== null && <IonItem>
                                        <IonLabel>{t('My RooQuests')}</IonLabel>
                                        <IonToggle checked={rooquestchecked} onIonChange={e => changeMyRooquestsToggle(e)} /><b className="unitmethod">{t(rooquestunits)}</b>
                                    </IonItem>}
                                    {deliverychecked !== null && <IonItem>
                                        <IonLabel>{t('My Deliveries')}</IonLabel>
                                        <IonToggle checked={deliverychecked} onIonChange={e => changeMyDeliveriesToggle(e)} /><b className="unitmethod">{t(deliveryunits)}</b>
                                    </IonItem>}
                                </IonList>
                            </div> 
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>

    )
}
const mapStateToProps = (state) => ({
    auth: state.auth.user,
  });

  EmailNotificationSettings.propTypes = {
    updateRooquestEmailMethod:PropTypes.func.isRequired,
    updateDelivererEmailMethod:PropTypes.func.isRequired,

  };

export default connect(mapStateToProps,{
    updateRooquestEmailMethod,
    updateDelivererEmailMethod
})(EmailNotificationSettings);
