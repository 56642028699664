import React from 'react';
import PropTypes from 'prop-types';
import './RQCard.scss';

const RQCard = ({
  rooquest,
  children,
  extraStyling,
  onClickFxn = () => {},
}) => {
  return (
    <div className="rqCard" style={extraStyling} onClick={() => onClickFxn()}>
      <div className="cardContent">{children}</div>
    </div>
  );
};

RQCard.propTypes = {
  rooquest: PropTypes.object.isRequired,
  extraStyling: PropTypes.object,
  onClickFxn: PropTypes.func,
};

export default RQCard;
