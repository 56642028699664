
export type RooquestItem = {
    title: string,
    description: string,
    costPerUnit: string,
    quantity: number,
    sku: string,
    images: {
        blobImage: Blob,
        src: string,
    }[],
}

type AddressType = {
    name: string | null,
    types: string[],
    hasBusinessName: boolean,
    street_number: string,
    route: string,
    locality: string,
    postal_code: string,
    administrative_area_level_1: string,
    country: string,
    lat: number,
    lng: number,
    formatted_address: string,
}

type RQ_Fees = {
    rooFee: {
        base: number,
        percentage: number, 
        total: number,
    },
    delivererFee: {
        base: number,
        distance: number,
        costPerKm: number,
        total: number,
        overwrittenByUser: boolean,
    },
    preAuth:{
        percentage : number,
        total : number
    }
}

type RQ_Tax = {
    province: string,
    taxes: string,
    rate: number,
    total: number,
}

type Roo_Costs = {
    items: number,
    fees: RQ_Fees,
    tax: RQ_Tax,
    totalToCapture : number,
    total: number,
}

export const initialRooCosts: Roo_Costs = {
    items: 0,
    fees: {
        rooFee: {
            base: 0,
            percentage: 0,
            total: 0,
        },
        delivererFee: {
            base: 0,
            distance: 0,
            costPerKm: 0,
            total: 0,
            overwrittenByUser: false,
        },
        preAuth : {
            percentage : 0,
            total :0
        }
    },
    tax: {
        province: '',
        taxes: '',
        rate: 0,
        total: 0,
    },
    totalToCapture : 0,
    total: 0,
}

export const initialAddressState: AddressType = {
    name: null,
    types: [],
    hasBusinessName: false,
    street_number: "",
    route: "",
    locality: "",
    postal_code: "",
    administrative_area_level_1: "",
    country: "",
    lat: null,
    lng: null,
    formatted_address: "",
}

export type RooQuestFormData = {
    items: RooquestItem[],
    pickupAddress: AddressType,
    dropoffAddress: AddressType,
    distance: {
        km: number,
        seconds: number,
        text:string
    }
    co2Saved: {
        kg: number,
    },
    expiryDate:Date,
    costs: Roo_Costs,
    additionalNotes: string,
    id: String | null,
    paymentDetails: {
        brand: string | null,
        last4: string | null,
    }
    triggerChange: boolean,
}