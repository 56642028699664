import { SET_PAN_TO_LOCATION } from '../../actions/layout/map-types';

const initialState = {
  panToLocation: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PAN_TO_LOCATION:
      return {
        ...state,
        panToLocation: payload,
      };
    default:
      return state;
  }
}
