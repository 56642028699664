import React from 'react';
import PropTypes from 'prop-types';
import { IonModal } from '@ionic/react';
import './GreenModal.scss';
import ModalHeader from './ModalHeader/ModalHeader';

const GreenModal = ({
  title,
  showModal,
  setShowModal,
  className,
  backBtnTo,
  backBtnOnClickFxn,
  children,
  onWillPresent,
  onWillDismiss = null,
  onDidPresent,
  swipeToClose = false,
  secondaryButton,
  secondaryOnClickFxn,
}) => {

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  return (
    <div className={className}>
      <IonModal
        isOpen={showModal}
        className={'green-modal'}
        mode="ios"
        swipeToClose={swipeToClose}
        onWillDismiss={onWillDismiss}
        onWillPresent={onWillPresent}
        onDidPresent={onDidPresent}
      >
        <div className="modalContent">
          <ModalHeader
            title={title}
            backBtnTo={backBtnTo}
            backBtnOnClickFxn={backBtnOnClickFxn}
            secondaryButton={secondaryButton}
            secondaryOnClickFxn={secondaryOnClickFxn}
          />
          <div className="modalBody">{children}</div>
        </div>
      </IonModal>
    </div>
  );
};

GreenModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  backBtnTo: PropTypes.string,
  backBtnOnClickFxn: PropTypes.func,
  onWillPresent: PropTypes.func,
  onDidPresent: PropTypes.func,
  swipeToClose: PropTypes.bool,
  secondaryOnClickFxn: PropTypes.func,
  secondaryButton: PropTypes.any,
};

export default GreenModal;
