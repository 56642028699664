import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IonContent, IonIcon, IonModal, IonPopover, IonSpinner } from '@ionic/react';


import './MakeOfferModal.scss';
import { closeOutline, createOutline } from 'ionicons/icons';
import { makeDeliveryOffer } from '../../../../actions/rooquest/offers';
import { connect } from 'react-redux';
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';
import { launchToastCard } from '../../../../actions/layout/toast';
import { calculateTimeLeft } from '../../../../utils/formatDate';
import { setShowFirstOfferSuccessModal } from '../../../../actions/layout/navigation';


const LengthPicker = ({ length, setLength }) => {

    const { value, type } = length;
    const { t } = useTranslation();

    const setType = (type) => {
        const utcTime = calculateUTCDate(value, type);
        setLength(length => ({ ...length, type: type, utcdate_end: utcTime}));
    }

    const setValue = (value) => {
        const utcTime = calculateUTCDate(value, type);
        setLength(length => ({ ...length, value: value, utcdate_end: utcTime}));
    }

    const calculateUTCDate = (value, type) => {
        const currentDateTime = new Date();

        if (type === 'minutes') {
            return new Date(currentDateTime.getTime() + (value * 60 * 1000)).toUTCString();
        }
        else if (type === 'hours') {
            return new Date(currentDateTime.getTime() + (value * 60 * 60 * 1000)).toUTCString();
        }
        else if (type === 'days') {
            return new Date(currentDateTime.getTime() + (value * 24 * 60 * 60 * 1000)).toUTCString();
        }
        return currentDateTime.toUTCString();        
    }

    useEffect(() => {
        if (value) {
            const date = calculateUTCDate(value, type);
            setLength(length => ({ ...length, utcdate_end: date}));
        }
        // @ts-ignore
    }, []);

    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined })


    return (
        <div className="LengthPicker">
            <div className="lengthPicker">
                <input type="number" min={0} value={value} onChange={(e) => setValue(parseInt(e.target.value))} />
                <p 
                    className="lengthType"
                    onClick={(e) => setShowPopover({ showPopover: true, event: e })}>
                    {t(`${type}`)}
                    <div className='round-edit-btn'><IonIcon icon={createOutline} /> </div>
                </p>
                <IonPopover
                    mode="ios"
                    className='chooseTypePopover'
                    event={popoverState.event}
                    side='right'
                    arrow={false}
                    isOpen={popoverState.showPopover}
                    onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                >
                    <div className="choices">
                        <p 
                            onClick={() => {
                                setType('days')
                                setShowPopover({ showPopover: false, event: undefined })
                            }} 
                            className={`${(type === 'days') ? 'selected' : ''}`}>
                        {t('days')}</p>
                        <p 
                            onClick={() => {
                                setType('hours')
                                setShowPopover({ showPopover: false, event: undefined })
                            }} 
                            className={`${(type === 'hours') ? 'selected' : ''}`}>
                        {t('hours')}</p>
                        <p 
                            onClick={() => {
                                setType('minutes')
                                setShowPopover({ showPopover: false, event: undefined })
                            }} 
                            className={`${(type === 'minutes') ? 'selected' : ''}`}>
                        {t('minutes')}</p>
                        
                    </div>
                </IonPopover>
            </div>
        </div>
    )
}

const MakeOfferModal = ({ show, setShow, rooquest, makeDeliveryOffer,launchToastCard,setShowFirstOfferSuccessModal }) => {

    const { t } = useTranslation();

    const close = () => {
        setShow(false)
    }

    const [etaLength, setEtaLength] = useState({
        value: 2,
        type: 'hours',
        utcdate_end: null,
    })

    const [offerLength, setOfferLength] = useState({
        value: 2,
        type: 'hours',
        utcdate_end: null,
    })

    const [offerAmount, setOfferAmount] = useState("0");

    const [loading, setLoading] = useState(false);
    const [countdown, setCountdown] = useState(calculateTimeLeft(rooquest.expiryDate));

    const checkDates = () => {
        const expiryDate = new Date(rooquest.expiryDate);
        const utcdate_end = new Date(etaLength.utcdate_end);
        const utcOfferDate = new Date(offerLength.utcdate_end);
        //in minutes
        const drivingTime = rooquest.drivingTime;

        if(utcdate_end > expiryDate || utcOfferDate > expiryDate)
        {
            launchToastCard({
                msg: 'Your offer should not exceed the expiration date of the rooquest.',
                type: 'error',
                duration: 8500,
            })
            throw "Your offer should not exceed the expiration date of the rooquest.";    // throw a text
        }
    }

    useEffect(() => {
        setOfferAmount(rooquest.costs.fees.delivererFee.total.toFixed(2).toString())
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            const timeLeft = calculateTimeLeft(rooquest.expiryDate);
            setCountdown(timeLeft);
        }, 1000);
        return () => clearTimeout(timer);
    });


    const makeOffer = async () => {
        try
        {
            checkDates();
            setLoading(true);
            const res = await makeDeliveryOffer(rooquest._id, { 
                eta: etaLength, 
                expiry: offerLength, 
                offerAmount: offerAmount 
            })
    
            if (res && res.status && (res.status === 'success')) {
                setShow(false);
                if(!localStorage.getItem('showOfferPopupAgain') || localStorage.getItem('showOfferPopupAgain') !== 'true')
                    setShowFirstOfferSuccessModal(true)
            }  
            setLoading(false)
        }
        catch(err)
        {
            console.log(err);
        }
        
    }

    const timerComponents = [];

    Object.keys(countdown).forEach((interval, index) => {
        if (countdown[interval] === null) return;

        // Make sure component has 2 digits
        let value = (countdown[interval] === 0) 
            ? '00' 
            : (countdown[interval] < 10)
                ? `0${countdown[interval]}`
                : countdown[interval].toString();

        // If interval is days, remove leading 0
        if (interval === 'days') {
            value = value.substr(1);
        }


        if (timerComponents.length === 0 && value === '00') return;

        if (interval !== 'days') {
            timerComponents.push(
                <span key={`offer-countdown-${interval}}`}>
                {value}{(index !== Object.keys(countdown).length - 1) ? ":" : ""}
                </span>
            );
        }
        else {
            timerComponents.push(
                <span key={`offer-countdown-${interval}}`}>
                {value}{(index !== Object.keys(countdown).length - 1) ? t(" day") + ((value === 0 || value > 1) ? 's' : '') + t(" and ") : ""}
                </span>
            );
        }        
    });

    return (
        <IonModal
            mode="ios" 
            isOpen={show} 
            class="MakeOfferModal"
            breakpoints={[0, 1]}
            initialBreakpoint={1}
            onIonModalDidDismiss={close}        
        >
            <IonContent>
                <div className="MakeOfferModalContent">
                    <div className="header">
                        <div className="title">
                            <p>{t('Make Delivery Offer')}</p>
                        </div>
                        <div className="closeBtn">
                            <IonIcon icon={closeOutline} onClick={close} />
                        </div>
                    </div>                    
                    <div className="main">
                        <div className="description">
                            <p>{t('Submit an offer to the requester to deliver their goods based on the following')}:</p>
                        </div>
                        <div className="inputsContainer">
                            <div className="inputSection howMuch">
                                <div className="question">
                                    <p>{t('What is your delivery fee?')}</p>
                                    <p className='subInfo'>({t('Default is recommended')})</p>
                                </div>
                                <CurrencyInput
                                    className='deliveryFeeOffer'
                                    placeholder="Offer"
                                    defaultValue = "0" 
                                    value={offerAmount}
                                    decimalsLimit={2}
                                    prefix={'$'}
                                    onBlur={() => {
                                        // If the user leaves the field empty, set the value to 0
                                        if (offerAmount === "" || !offerAmount) setOfferAmount("0");
    
                                        // If the user enters a value with one deciaml, add a 0 to the end
                                        if (
                                            offerAmount.indexOf(".") !== -1 &&
                                            offerAmount.indexOf(".") === offerAmount.length - 2) {
                                                setOfferAmount(offerAmount + "0")
                                        }
                                    }}
                                    onValueChange={(value) => setOfferAmount(value)} 
                                />
                            </div>
                            <div className="inputSection howFast">
                                <div className="question">
                                    <p>{t('How fast can you complete the delivery?')}</p>
                                    <p className='subInfo'>({t('Max 5 days')})</p>
                                </div>
                                <LengthPicker length={etaLength} setLength={setEtaLength} />
                            </div>
                            <div className="inputSection validFor">
                                <div className="question">
                                    <p>{t('How long should this offer be valid for?')}</p>
                                    <p className='subInfo'>({t('Max 5 days')})</p>
                                </div>
                                <LengthPicker length={offerLength} setLength={setOfferLength} />
                            </div>
                        </div>
                        {rooquest.expiryDate ? <div className="deliveryMessage">
            <p>{`${(timerComponents.length !== 0) ? `${t("RooQuest expires in")} :` : ''}`}
                                <p>
                                    {(timerComponents.length !== 0) 
                                        ? (timerComponents) 
                                        : <span className='warning'>{t('RooQuest Expired')}</span>}
                                </p>
                            </p>
        </div>:null }
                        <div className="footer">
                            <div className={`makeOfferBtn ${loading ? 'loading' : ''}`} onClick={makeOffer}>
                                {!loading ? <p>{t('Make Offer')}</p> : <IonSpinner name="crescent" />}
                            </div>
                        </div>
                    </div>
                   
                </div>
            </IonContent>
        </IonModal>);
};

MakeOfferModal.propTypes = {
    makeDeliveryOffer: PropTypes.func.isRequired,
};

export default connect(null, { makeDeliveryOffer,launchToastCard,setShowFirstOfferSuccessModal })(MakeOfferModal);
