import React, { useRef, useState ,useEffect,RefObject } from 'react';
import PropTypes from 'prop-types';
import {
  IonModal,
  IonFabButton,
  IonIcon,
  useIonViewDidEnter,
} from '@ionic/react';
import GreenBackButton from '../../../components/layout/GreenBackButton/GreenBackButton';
import { paperPlaneOutline } from 'ionicons/icons';
import './ChatModal.scss';
import ChatMessage from './ChatMessage';
import { connect } from 'react-redux';
import {
  sendPrivateMessage,
  setConversation,
  markMessagesAsRead,
  getAllConversations,
  setShowChatModal,
} from '../../../actions/chat/chat';
import { updateNavbarWithUnreadMessages } from '../../../actions/layout/navbar';

import { setShowInfoModal,setShowEnlargeImageModal  } from '../../../actions/layout/navigation';

import { getRooQuestByID } from '../../../actions/rooquest/rooquest';
// import { Plugins } from '@capacitor/core';
import { uploadRooQuestImage } from '../../../actions/firebase/firebase-storage';
import { close } from 'ionicons/icons';
import {
  images
} from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
const ChatModal = ({
  auth,
  chat,
  sendPrivateMessage,
  setConversation,
  markMessagesAsRead,
  updateNavbarWithUnreadMessages,
  navbar,
  getAllConversations,
  setShowChatModal,
  getRooQuestByID,
  setShowInfoModal,
  setShowEnlargeImageModal
}) => {
  const { t } = useTranslation();
  const { showChatModal, conversation } = chat;

  const messagesRef = useRef(null);
  const [message, setMessage] = useState('');
  const [msgCounter, setMsgCounter] = useState(0);

  const [sendDisabled, setSendDisabled] = useState(false);
  // const [image, setimage] = useState(null);
  // const [imgdata , setimgdata] =  useState(null);
  //multiple images
  const [chatimages, setchatimages] = useState([]);
  const [imgarray, setImageArray] = useState([]);
  // const { Camera } = Plugins;  
  // const imageRef = useRef(null);
  let inputOpenFileRef : RefObject<HTMLInputElement> = React.createRef();
  const getImage = async () => {
    if(inputOpenFileRef && inputOpenFileRef.current){
     inputOpenFileRef.current.click();
    }
  };

  useEffect(() => {
    let tempArray = [];
    Array.from(chatimages).forEach(element => {
      var reader = new FileReader();
      reader.onload = function (e) {
        tempArray.push(e.target.result);
        if (tempArray.length === chatimages.length) {
          setImageArray(tempArray);
        }
      }
      // you have to declare the file loading
      reader.readAsDataURL(element);
    });
  }, [chatimages]);

  const deleteImage = (index) =>{
    let filearray = Array.from(chatimages);
    imgarray.splice(index,1);
    setImageArray( imgarray);
      filearray.splice(index,1);
      setchatimages([...Array.from(filearray)]);
      console.log(chatimages)
  }

//   useEffect(() => {
//     if(!!imgdata){
//       var reader = new FileReader();
//       reader.onload = function (e) {
//         setimage(e.target.result)
//       };
//     reader.readAsDataURL(imgdata)
   
//     }
// }, [imgdata]);

// const uploadChatImg = async () => {
   
// }
  // const getImage = async () => {
  //   const image = await Camera.getPhoto({
  //     quality: 100,
  //     allowEditing: false,
  //     resultType: CameraResultType.Uri,
  //     height: 500,
  //   });
  //   // imageRef.current.src = image.webPath;

  //   (await fetch(image.webPath)).blob().then((resp) => {
  //     setimgdata(resp);
  //   });
  // };

  const sendMessage = async () => {
    if (message.trim() !== '') {
      let imageUrls = [];
      const imgPromise = Array.from(chatimages).map(async element => {
        setSendDisabled(true);
        const imgURL = await uploadRooQuestImage(element);
        imageUrls.push(imgURL);
        return imgURL
      })  
      Promise.all(imgPromise).then(async resp =>{
        if (message.trim() !== '') {
          setSendDisabled(true);
          await sendPrivateMessage(conversation._id, {
            _id: msgCounter,
            msgContent: message,
            senderID: auth.user._id,
            date: new Date(),
            imgURL: imageUrls.length>0 ? imageUrls: null ,
            rooquest: {
              _id: conversation.rooquestID,
            },
          });
          setMsgCounter(msgCounter + 1);
          setMessage('');
          // setimgdata(null);
          // setimage(null);
          setchatimages([]);
          setImageArray([]);
          setSendDisabled(false);
        } else {
          setSendDisabled(true);
          await sendPrivateMessage(conversation._id, {
            _id: msgCounter,
            msgContent: null,
            senderID: auth.user._id,
            date: new Date(),
            imgURL: imageUrls.length>0 ? imageUrls: null ,
            rooquest: {
              _id: conversation.rooquestID,
            },
          });
          setMsgCounter(msgCounter + 1);
          setMessage('');
          // setimgdata(null);
          // setimage(null);
          setchatimages([]);
          setImageArray([]);
          setSendDisabled(false);
        }
      })
  }
   
  };
  const onCloseModal = async () => {
    setConversation(null);
    await markMessagesAsRead(conversation._id);
    await getAllConversations(auth.user._id);
    const count = countUnreadInConversation(conversation, auth.user._id);
  
    // dispatch({
    //   type: UPDATE_CONVERSATION_UNREAD_COUNT,
    //   payload: {
    //     chatID: conversation._id,
    //     amount: count,
    //   },
    // });
    updateNavbarWithUnreadMessages(
      // navbar.unreadMessages - conversation.numUnreadMessages
      navbar.unreadMessages - count
    );
    setMessage('');
    // setimgdata(null);
    setchatimages([]);
    setImageArray([]);
    setShowChatModal(false);
    setSendDisabled(false);
  };
  const countUnreadInConversation = (convo, userID) => {
    let count = 0;
    convo.messages.forEach((message) => {
      if (message && message.readBy){
        const messageReadBys = message.readBy.map((read) => read.userID);
        // console.log(messageReadBys, userID);
        if (!messageReadBys.includes(userID)) {
          count++;
        }
      }
    });
    return count;
  };
  useIonViewDidEnter(() => {
    console.log('here!!!', conversation);
    if (!conversation) {
      setShowChatModal(false);
    }
  });

  const enlargeImage = (e, src) => {
    if (e.target.tagName !== 'ION-ICON') {
      setShowEnlargeImageModal(true, src);
    }
  };
  return (
    <div>
      <IonModal
        mode="ios"
        isOpen={showChatModal}
        class="ChatModal"
        onWillDismiss={() => onCloseModal()}
      >
        <div className="modalContent">
          <div className="bodyContent">
            <GreenBackButton onClickFxn={() => setShowChatModal(false)} />

            {conversation && (
              <div
                className="itemInfo"
                onClick={async () => {
                  // Open RQ
                  if (auth.user._id === conversation.requesterID) {
                    // Open MyRQ Modal
                    console.log('open?');
                    await getRooQuestByID(conversation.rooquestID);
                    setShowInfoModal('MYRQ', true);
                  } else {
                    // Open RQ Modal
                    await getRooQuestByID(conversation.rooquestID);
                    setShowInfoModal('RQ', true);
                  }
                }}
              >
                <div className="textComponent">
                  <h5>
                    {conversation && auth.user._id === conversation.requesterID
                      ? conversation.delivererFirstName
                      : conversation.requesterFirstName}
                  </h5>
                  <p>{conversation.rooquestTitle}</p>
                </div>
                <img
                  className="rqImg"
                  src={
                    conversation && conversation.rooquestImage
                      ? conversation.rooquestImage
                      : require('./NoImageAvailable.png')
                  }
                  alt=""
                />
              </div>
            )}

            <div className="outerMessagesWrapper">
              <div className="messages" ref={messagesRef}>
                {chat &&
                  chat.conversation &&
                  chat.conversation.messages.map((msg) => (
                    <ChatMessage
                      key={msg._id}
                      messageObj={msg}
                      isSender={msg.senderID === auth.user._id}
                      senderName={
                        auth.user._id === msg.senderID
                          ? 'You'
                          : auth.user._id === conversation.requesterID
                          ? conversation.delivererFirstName
                          : conversation.requesterFirstName
                      }
                    />
                  ))}
              </div>
            </div>
          </div>
         {!!chatimages && chatimages.length > 0 ? <div className="chatactionContent">
            <div className="firstimage">
              <div className="preview_images_main_wrapper">
                {
                  imgarray.map((item, index) => {
                    return (
                      <div className="image_preview_wrapper" key={index}>
                        <div className="img_wrp" onClick={(e) => enlargeImage(e, item)}>
                          <img className="uploadedImage" src={item} alt="uploaded" />
                          <IonIcon className="close" icon={close} onClick={() => deleteImage(index)}></IonIcon>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>:null}

          <div className="actionContent">
                    
            <div className="row">
                {/* {imgdata ? <img
                      // ref={imageRef}
                      className="uploadedImage"
                      src={image}
                      alt=""
                    />:null} */}
                    <div className="singleItemBtn chatimagebtn" onClick={() => getImage()}>
                    <input onChange={e => { 
                      setchatimages([...Array.from(chatimages), ...Array.from(e.target.files)])
                    }} 
                    ref={inputOpenFileRef} type="file" multiple  accept="image/*" style={{ display: "none" }}/>
                    <IonIcon icon={images} style={{color:'green'}}/>
                    </div>
                      
              <textarea
              className="placeholdertextset"
                value={message}
                placeholder={t("Message")}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></textarea>
              <IonFabButton
                onClick={async () => await sendMessage()}
                disabled={sendDisabled}
              >
                <IonIcon icon={paperPlaneOutline}></IonIcon>
              </IonFabButton>
            </div>
          </div>
        </div>
      </IonModal>
    </div>
  );
};

ChatModal.propTypes = {
  sendPrivateMessage: PropTypes.func.isRequired,
  setConversation: PropTypes.func.isRequired,
  markMessagesAsRead: PropTypes.func.isRequired,
  updateNavbarWithUnreadMessages: PropTypes.func.isRequired,
  getAllConversations: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired,
  setShowChatModal: PropTypes.func.isRequired,
  getRooQuestByID: PropTypes.func.isRequired,
  setShowInfoModal: PropTypes.func.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  chat: state.chat,
  auth: state.auth,
  navbar: state.navbar,
});

export default connect(mapStateToProps, {
  sendPrivateMessage,
  setConversation,
  markMessagesAsRead,
  updateNavbarWithUnreadMessages,
  getAllConversations,
  setShowChatModal,
  getRooQuestByID,
  setShowInfoModal,
  setShowEnlargeImageModal
})(ChatModal);
