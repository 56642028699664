import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './MyRooQuestDelivery.scss'
import { IonAlert, IonIcon} from '@ionic/react';
import { 
    chatbubbleEllipsesOutline,
    checkmarkCircle,
    closeCircle,
    closeCircleOutline,
    createOutline,
    ellipse, 
    walletOutline} from 'ionicons/icons';
import MyRooQuestInfo from '../../MyRQ/MyRooQuestInfo';
import WhiteCard from '../../../components/ui/WhiteCard';
import DeliveryOffer from '../DeliveryOffer';
import { connect } from 'react-redux';
import { acceptDeliveryOffer, rejectDeliveryOffer } from '../../../../../actions/rooquest/offers';
import { setShowEnlargeImageModal,setShowRatingsModal,setShowItemRatingsNotifModal } from '../../../../../actions/layout/navigation';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import ReportProblemModal from './ReportProblemModal';
import FeedMap from '../../../../feed/map/FeedMap';
import ProgressBar from '../../../../progressBar/progressBar'
import { useHistory } from 'react-router-dom';
import { cancelOwnRooQuest, getDriverLocation, getMyRooQuests } from '../../../../../actions/rooquest/rooquest';
import { useTranslation } from 'react-i18next';
import DeliveryStepper from './DeliveryStepper';
import DeliveryTracking from '../../DeliveryTracking';
import { calculateTimeLeft } from '../../../../../utils/formatDate';
import { requestMoreFunds, setShowApproveRequestedFundsAlert } from '../../../../../actions/payments/payments';
const MyRooQuestDelivery = ({ auth, rooquest, openChat, closeModal, changeType, cancelOwnRooQuest,getMyRooQuests,getDriverLocation,next,show,setShow,showApproveRequestedFundsAlert,setShowApproveRequestedFundsAlert,requestMoreFunds }) => {

    const [step, setStep] = useState(0);
    const [showTracking, setShowTracking] = useState(true)
    const [driveDate, setDriveDate] = useState(null)
    const [showCancelRQModal, setShowCancelRQModal] = useState({rq: null, show: false})

    const history = useHistory();   
    const { t } = useTranslation();
    useEffect(() => {
        const checkForCheckedItems = (items) => {
            for (let i = 0; i < items.length; i++) {
                if (items[i].collectionInfo.collected) {
                    return true;
                }
            }
            return false;
        }
        const hasCheckedItems = checkForCheckedItems(rooquest.items);
        console.log('hasCheckedItems', hasCheckedItems);

        if (rooquest.status === 'ACTIVE') setStep(0);
        else if (rooquest.status === 'RQ_ACCEPTED' && !hasCheckedItems) setStep(1);
        else if (rooquest.status  === 'RQ_ACCEPTED' && hasCheckedItems) setStep(2);
        else if (rooquest.status  === 'RQ_ONTHEWAY') setStep(3);
        else if (rooquest.status === 'ROOQUEST_DELIVERED') setStep(4);
        else if (rooquest.status === 'UNDER_REVIEW') setStep(4);
        else if (rooquest.status === 'EXPIRED') setStep(5);
    }, [rooquest]);

    const getEstimatedDeliveryTime = () => {
    if (!driveDate) {
        const acceptedOffer = rooquest.offers.find(offer => offer.status === 'ACCEPTED');
        if(acceptedOffer){
            const endDate = new Date(acceptedOffer.eta.utcdate_end);

            // Countdown to end date
            const diff = endDate.getTime() - new Date().getTime();
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((diff / (1000 * 60)) % 60);
    
            let formattedDate = "";
            if (days > 0) formattedDate += `${days}d`;
            if (hours > 1) formattedDate += ` ${hours}hrs`;
            else if (hours > 0) formattedDate += ` ${hours}hr`;
            if (minutes > 1) formattedDate += ` ${minutes}mins`;
            else if (minutes > 0) formattedDate += ` ${minutes}min`;
    
            // Trim off leading space
            formattedDate = formattedDate.trim();
            return formattedDate;
        }
    }
}
    
    return (
        <>
           {showTracking ? 
            <div className="MyRooQuestDelivery">
                <div className="estimatedDelivery">
                    {rooquest.status !== 'ACTIVE' && <><p>{t('Estimated Delivery')}:</p><p className='time'>{driveDate !== null ? driveDate : getEstimatedDeliveryTime()}</p></>}
                </div>
                <DeliveryStepper step={step} />
                <div className='currentStep'>
                    {step === 0 && <DeliveryOffers rooquest={rooquest} />}
                    {step === 1 && <DeliveryAccepted rooquest={rooquest} />}
                    {step === 2 && <DeliveryCollectingItems rooquest={rooquest} />}
                    {step === 3 && <DeliveryTracking 
                                        rooquest={rooquest}
                                        openChat = {openChat}
                                        t = {t}
                                        closeModal = {closeModal}
                                        next = {next}
                                        show = {show}
                                        setShow = {setShow}
                                        isClient = {true}
                                        setDriveDate = {setDriveDate}
                                        />}
                    {/* {step === 3 && <DeliveryOnTheWay rooquest={rooquest} setDriveDate={setDriveDate} getDriverLocation = {getDriverLocation}/>} */}
                    {step === 4 && <DeliveryDelivered rooquest={rooquest} setDriveDate={setDriveDate} />}
                    {step === 5 &&<DeliveryExpired rooquest={rooquest} setDriveDate={setDriveDate} />}
                </div>                
            </div>
            : 
            <MyRooQuestInfo 
                rooquest={rooquest} 
                openChat={openChat} 
                closeModal={closeModal} 
                changeType={changeType}
                showChat={false}
                extraPadding={true}
            />
        }
            <div className="MyRooQuestDelivery-footerBtns">
                <div className="btn" onClick={() => setShowTracking(!showTracking)}>
                    <p>{showTracking ? `${t('See RooQuest')}` : `${t('Track Delivery')}`}</p>
                </div>
                <div className='MyRooQuestDelivery-footerRight'>

                    {(rooquest.status === 'ACTIVE' || rooquest.status === 'ACCEPTED' || rooquest.status === 'EXPIRED') &&
                        <div className="btn chat" onClick={() => {
                            closeModal();
                            // redirect to edit rooquest
                            history.push(`/editrooquest/${rooquest._id}`);

                        }}>
                            <IonIcon icon={createOutline} />
                        </div>
                    }

                    {(rooquest.status === 'ACTIVE' || rooquest.status === 'ACCEPTED') &&
                        <div className="btn chat" onClick={() => {setShowCancelRQModal({show: true, rq: rooquest._id})}}>
                            <IonIcon icon={closeCircleOutline} />
                        </div>
                    }

                    {(rooquest && rooquest.delivererIsWaitingForFunds )&&
                        <div className="btn chat" onClick={() => {setShowApproveRequestedFundsAlert(true)}}>
                            <IonIcon icon={walletOutline} />
                        </div>
                    }

                    {rooquest.deliverer && 
                        <div className="btn chat" onClick={() => openChat(rooquest.id)}>
                            <IonIcon icon={chatbubbleEllipsesOutline} />
                        </div>
                    }
                </div>                
            </div>
            <IonAlert
                isOpen={showCancelRQModal.show}
                onDidDismiss={() => setShowCancelRQModal({show: false, rq: null})}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Cancel RooQuest')}?`}
                message={
                `<p>${t("By cancelling this RooQuest, the RooQuest will no longer be active in the public feed. It will appear in your history as expired.")}</p>`
                }
                buttons={[
                    { text: `${t('Close')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Cancel RooQuest')}`,
                        handler: async () => {
                            cancelOwnRooQuest(showCancelRQModal.rq);
                            setShowCancelRQModal({show: false, rq: null});
                            await getMyRooQuests();
                            closeModal();
                        },
                    },
                ]}
            />

            
        <IonAlert
                isOpen={showApproveRequestedFundsAlert}
                onDidDismiss={() => setShowApproveRequestedFundsAlert(false)}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Insufficient Funds')}!`}
                message={
                    `${t(`${rooquest.delivererFirstName} needs $${rooquest.amountRequested} to complete your delivery. Do you approve this transaction and authorize us to charge you the amount requested ?`)}`
                }
                // inputs={[ { name: 'amount', type: 'number', placeholder: `${t('Amount Needed')}` }, ]}
                buttons={[
                    { text: `${t('No')}`, role: 'cancel', cssClass: 'secondary',handler: async () => {
                         await requestMoreFunds(rooquest._id,null,'No');
                        } },
                    { text: `${t('Yes')}`,
                        handler: async () => {
                        await requestMoreFunds(rooquest._id,null,'Yes');
                        },
                    },
                ]}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    showItemRatingsNotifModal:state.navigation.showItemRatingsNotifModal,
    showApproveRequestedFundsAlert:state.showApproveRequestedFundsAlert
})

MyRooQuestDelivery.propTypes = {
    auth: PropTypes.object.isRequired,
    rooquest: PropTypes.object.isRequired,
    openChat: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    changeType: PropTypes.func.isRequired,
    cancelOwnRooQuest: PropTypes.func.isRequired,
    getMyRooQuests: PropTypes.func.isRequired,
    setShowRatingsModal:PropTypes.func.isRequired,
    setShowItemRatingsNotifModal:PropTypes.func.isRequired
}

export default connect(mapStateToProps, {cancelOwnRooQuest, getMyRooQuests,getDriverLocation,setShowRatingsModal,setShowItemRatingsNotifModal,setShowApproveRequestedFundsAlert,requestMoreFunds})(MyRooQuestDelivery);





const DeliveryOffersConnected = ({ acceptDeliveryOffer, rejectDeliveryOffer, rooquest }) => {
    const { t } = useTranslation();
    const offers = [...rooquest.offers].reverse()
    const [showConfirmOfferAlert, setShowConfirmOfferAlert] = useState({ show: false, offer: null });
    const [showRejectOfferAlert, setShowRejectOfferAlert] = useState({ show: false, offer: null });
    const [countdown, setCountdown] = useState(calculateTimeLeft(rooquest.expiryDate));
    useEffect(() => {
        const timer = setTimeout(() => {
            const timeLeft = calculateTimeLeft(rooquest.expiryDate);
            setCountdown(timeLeft);
        }, 1000);
        return () => clearTimeout(timer);
    });

    const timerComponents = [];

    Object.keys(countdown).forEach((interval, index) => {
        if (countdown[interval] === null) return;

        // Make sure component has 2 digits
        let value = (countdown[interval] === 0) 
            ? '00' 
            : (countdown[interval] < 10)
                ? `0${countdown[interval]}`
                : countdown[interval].toString();

        // If interval is days, remove leading 0
        if (interval === 'days') {
            value = value.substr(1);
        }


        if (timerComponents.length === 0 && value === '00') return;

        if (interval !== 'days') {
            timerComponents.push(
                <span key={`offer-countdown-${interval}}`}>
                {value}{(index !== Object.keys(countdown).length - 1) ? ":" : ""}
                </span>
            );
        }
        else {
            timerComponents.push(
                <span key={`offer-countdown-${interval}}`}>
                {value}{(index !== Object.keys(countdown).length - 1) ? t(" day") + ((value === 0 || value > 1) ? 's' : '') + t(" and ") : ""}
                </span>
            );
        }        
    });

    return (
        <div className="RooQuestDeliverySection">
        <div className="deliveryMessage">
            <p>{t('Your RooQuest is active and ready to receive offers')}</p>
        </div>
        
        {rooquest.offers.length > 0 ?
            <div className="RQDeliv-deliveryOffers">
                <WhiteCard>
                    <div className="offersContainer">

                        
                        {offers.map((offer, index) => (
                            <DeliveryOffer 
                                key={`rqoffer-${index}`}
                                setShowAccept={(show) => setShowConfirmOfferAlert({show, offer})}
                                setShowReject={(show) => setShowRejectOfferAlert({show, offer})}
                                setShowRescind={null}
                                rq_id={rooquest._id}
                                deliveryOffer={offer}
                                rq = {rooquest}                                                
                            />                    
                        ))}                                   
                    </div>
                </WhiteCard>
            </div>
        :
            <div className="deliveryImg">
                <img src={require('./undraw_not_found.png')} alt="delivery guy" />
                <p>{t('Waiting for Offers')}</p>
            </div>    
        }
        {rooquest.expiryDate ? <div className="deliveryMessage">
            <p>{`${(timerComponents.length !== 0) ? `${t("RooQuest expires in")} :` : ''}`}
                                <p>
                                    {(timerComponents.length !== 0) 
                                        ? (timerComponents) 
                                        : <span className='warning'>{t('RooQuest Expired')}</span>}
                                </p>
                            </p>
        </div>:null }
         <IonAlert
                isOpen={showConfirmOfferAlert.show}
                onDidDismiss={() => setShowConfirmOfferAlert({show: false, offer: null})}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Confirm Offer?')}`}
                message={
                    `<p>${t('By accepting this offer, you start your delivery and automatically decline all other delivery offers. Ready to accept this offer?')}</p>`
                }
                buttons={[
                    { text: `${t('Close')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Accept Offer')}`,
                        handler: async () => {
                            acceptDeliveryOffer(rooquest._id, showConfirmOfferAlert.offer._id);
                            setShowConfirmOfferAlert({show: false, offer: null});
                        },
                    },
                ]}
            />

            <IonAlert
                isOpen={showRejectOfferAlert.show}
                onDidDismiss={() => setShowRejectOfferAlert({show: false, offer: null})}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Reject Offer?')}`}
                message={
                    `<p>'${t('By rejecting this offer, you notify this deliverer that you are not interested in their delivery offer and you will not be able to accept it in the future. Are you sure?')}'</p>`
                }
                buttons={[
                    { text: `${t('Close')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Reject Offer')}`,
                        handler: async () => {
                            rejectDeliveryOffer(rooquest._id, showRejectOfferAlert.offer._id);
                            setShowRejectOfferAlert({show: false, offer: null});
                        },
                    },
                ]}
            />
    </div>
    )
}

const DeliveryOffers = connect(null, {rejectDeliveryOffer, acceptDeliveryOffer,setShowRatingsModal,setShowItemRatingsNotifModal})(DeliveryOffersConnected);
            

const DeliveryAccepted = ({ rooquest }) => {
    const { t } = useTranslation();
    return (
        <div className="RooQuestDeliverySection">
            <div className="deliveryMessage">
                <p>{t("deliveritem",{deliveritemkey:rooquest.delivererFirstName})}</p>
            </div>
            <div className="deliveryImg">
                <img src={require('./undraw_on_the_way_roo.png')} alt="delivery guy" />
            </div>
            <div className="deliveryMessage">
                <p>{t(`You will be notified when the delivery is headed towards you`)}</p>
            </div>
        </div>
    )
}

const DeliveryCollectingItems = ({ rooquest }) => {
    const [totalCollectedItems,setTotalCollectedItems]= useState(0)
    const [percentage,setPercentage] = useState(0)

    useEffect(()=>{
        const arr = rooquest.items.map((a)=>{return a.collectionInfo});
        const result = calculateTotalItemsCollected(arr);
        const percent = ((result*100)/rooquest.items.length).toFixed(2)
        setPercentage(parseFloat(percent));
        setTotalCollectedItems(result);
    })

    const calculateTotalItemsCollected = (arr) => {
            let total=0
            arr.map((a)=> {
                if (a.collected)
                    total++;
            }) 
            return total;
    }

    const calculateNewItemCost = (quantity, shelfPrice, shelfPriceType) => {
        if (shelfPriceType === 'perUnit') {
            const total = quantity * shelfPrice;
            return total.toFixed(2);
        }
        else {
            console.log(shelfPrice)
            const total = shelfPrice;
            return total.toFixed(2);
        }
    }

    const { t } = useTranslation();

    return (
        <div className="RooQuestDeliverySection">
            <div className="deliveryMessage">
                <p>{`${rooquest.delivererFirstName} ${t('is collecting your items')}...`}</p>
            </div>
            <div className="collectingRooQuestItems">
                <WhiteCard>
                    <ProgressBar percentage ={percentage} totalCollectedItems={totalCollectedItems} totalItems = {rooquest.items.length}/>
                    <div className="collectingRQItems">
                        {rooquest.items.map((item, i) => {
                            return (
                                <div className="collectingRQItem" key={i}>
                                    <div className="rq-left">
                                        {item.collectionInfo.collected && item.collectionInfo.problem && !item.collectionInfo.problem.hasProblem ?
                                            <IonIcon className='collected' icon={checkmarkCircle} /> :
                                            item.collectionInfo.collected && item.collectionInfo.problem && item.collectionInfo.problem.hasProblem ?
                                                <IonIcon className='problem' icon={closeCircle} />
                                                :
                                                <IonIcon className='uncollected' icon={ellipse} />  
                                        }
                                        <div className="rq-text">
                                            <p>{item.collectionInfo.quantity} x {item.title}</p>

                                            {item.collectionInfo.collected && 
                                        item.collectionInfo.problem && item.collectionInfo.problem.hasProblem
                                            && 
                                                <div className='itemCollectionStatus'>
                                                    {item.collectionInfo.problem.reason ? 
                                                        (item.collectionInfo.problem.reason === 'no-stock') 
                                                            ? <p className='warning'>{t('Item out of stock')}</p>
                                                            : (item.collectionInfo.problem.reason === 'unable-to-find')
                                                                ? <p className='warning'>{t('Unable to find item')}</p>
                                                            : null
                                                        : null
                                                    }
                                                    {
                                                        item.collectionInfo.problem.reason === 'other'? item.collectionInfo.problem.note && 
                                                        <p className='warning'>{item.collectionInfo.problem.note}</p>:<></>
                                                    }
                                                </div>}

                                        </div>
                                    </div>
                                    <div className="rq-right">
                                    {item.collectionInfo.collected && item.collectionInfo.problem && !item.collectionInfo.problem.hasProblem &&
                                        <p className={`total new`}>${calculateNewItemCost(item.collectionInfo.quantity,item.collectionInfo.shelfPrice, item.collectionInfo.shelfPriceType)}</p>}
                                    </div>                                   
                                </div>
                            )
                        })}
                    </div>                         
                </WhiteCard>

            </div>
        </div>
    )
}

export const DeliveryOnTheWay = ({ rooquest, setDriveDate,getDriverLocation }) => {
    const [mapState, setMapState] = useState('trackDelivery');
    
    // const requestData={ rooquest: rooquest ? rooquest: {}, requestId: rooquest._id }
    // const [time, setTime] = useState(null);
    // const [distance, setDistance] = useState(null);
    // const [center,setCenter] = useState({lat :0,lng:0})
    const data = "myrooQuestdelivery"
    const sendDataToParent = (data) => {
         setDriveDate(data);
    };
    const { t } = useTranslation();
    // const [deliverCurrentPosition,setDeliverCurrentPosition] = useState(null);
    // const markerRef = useRef(null);
    // const options = useMemo(()=> ({
    //     // mapId:"ce8355f67058c4c1",  // change style of the map
    //     disableDefaultUI:true, // remove default buttons like +/- to zoom or map/satelite view button
    //     clickableIcons: false, // can't click the default icons present in google map
    // }),[]);
    // const onMarkerLoad = useCallback((marker) => {
    //     markerRef.current = marker
    // },[]);

    // function animateMarkerTo(marker, newPosition) {
    //     var options = {
    //         duration: 1000,
    //         easing: function (x, t, b, c, d) { // jquery animation: swing (easeOutQuad)
    //             return -c *(t/=d)*(t-2) + b;
    //         }
    //     };
      
    //     window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
    //     window.cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
      
    //     // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
    //     marker.AT_startPosition_lat = marker.getPosition().lat();
    //     marker.AT_startPosition_lng = marker.getPosition().lng();
    //     var newPosition_lat = newPosition.lat;
    //     var newPosition_lng = newPosition.lng;
      
    //     // crossing the 180° meridian and going the long way around the earth?
    //     if (Math.abs(newPosition_lng - marker.AT_startPosition_lng) > 180) {
    //         if (newPosition_lng > marker.AT_startPosition_lng) {
    //             newPosition_lng -= 360;
    //         } else {
    //             newPosition_lng += 360;
    //         }
    //     }
      
    //     var animateStep = function(marker, startTime) {
    //         var ellapsedTime = (new Date()).getTime() - startTime;
    //         var durationRatio = ellapsedTime / options.duration; // 0 - 1
    //         var easingDurationRatio = options.easing(durationRatio, ellapsedTime, 0, 1, options.duration);
      
    //         if (durationRatio < 1) {
    //             marker.setPosition({
    //                 lat: (
    //                     marker.AT_startPosition_lat +
    //                     (newPosition_lat - marker.AT_startPosition_lat)*easingDurationRatio
    //                 ),
    //                 lng: (
    //                     marker.AT_startPosition_lng +
    //                     (newPosition_lng - marker.AT_startPosition_lng)*easingDurationRatio
    //                 )
    //             });
      
    //             // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
    //             if (window.requestAnimationFrame) {
    //                 marker.AT_animationHandler = window.requestAnimationFrame(function() {animateStep(marker, startTime)});
    //             } else {
    //                 marker.AT_animationHandler = setTimeout(function() {animateStep(marker, startTime)}, 17);
    //             }
      
    //         } else {
    //             marker.setPosition(newPosition);
    //         }
    //     }
      
    //     // stop possibly running animation
    //     if (window.cancelAnimationFrame) {
    //         window.cancelAnimationFrame(marker.AT_animationHandler);
    //     } else {
    //         clearTimeout(marker.AT_animationHandler);
    //     }
      
    //     animateStep(marker, (new Date()).getTime());
    //   }

    // const getdriveraddress = async () => {
    //     const res = await getDriverLocation(requestData.requestId);
    //     if (res) {
    //         console.log('abc', res);
    //         setTime(res.data.time);
            
    //         setDistance(res.data.distance);
    //         const pos = { lat: res.data.delivererLocation.lat, lng: res.data.delivererLocation.lng }
    //         setDeliverCurrentPosition(pos)
    //         socket.on("driverlocation", (data) => {
    //             console.log("event received", data);
    //             animateMarkerTo(markerRef.current,{ lat: data.lat, lng: data.lng })
    //         });
    //         console.log("socket", socket);
    //     }
    // }

    // const findMidPoint = (firstMarker, secondMarker) => 
    // {

    // const x = (firstMarker.lat + secondMarker.lat)/2
    // const y = (firstMarker.lng + secondMarker.lng)/2
    // const result = {
    //     lat: x,
    //     lng : y
    // } 
    // return result
    // }

    // useEffect(()=>{
    //     const getdriveraddress = async () => {
    //         const res = await getDriverLocation(requestData.requestId);
    //         if (res) {
    //             console.log('abc', res);
    //             setTime(res.data.time);
                
    //             setDistance(res.data.distance);
    //             const pos = { lat: res.data.delivererLocation.lat, lng: res.data.delivererLocation.lng }
    //             setDeliverCurrentPosition(pos)
    //             const midPoint = findMidPoint(pos,rooquest.deliveryAddress)
    //             setCenter(midPoint)

    //             socket.on("driverlocation", (data) => {
    //                 console.log("event received", data);
    //                 animateMarkerTo(markerRef.current,{ lat: data.lat, lng: data.lng })
    //             });
    //             console.log("socket", socket);
    //             // global.driverInterval = setInterval(function(){ 
    //             //     console.log("setinterval");
    //             // }, 1000);
    //             // socket.emit('change color', "White");
    //         }
    //     }
    //     getdriveraddress();
    // },[])

    
    return (
        <div className="RooQuestDeliverySection">
            <div className="deliveryMessage">
                <p>{t('Delivery On The Way')}</p>
            </div>
            <div className="RQTrackingMap" >
            <FeedMap
                    rooquests={[]}
                    showZoom={false}
                    state={mapState}
                    requestData={{ rooquest: rooquest ? rooquest: {}, requestId: rooquest._id }}
                    sendDataToParent={sendDataToParent}
                    dataParentToChild = {data}
                />
           </div> 
           
        </div>
    )
   
}

const DeliveryExpired = ({ rooquest, setDriveDate }) => {
    useEffect(() => {
        setDriveDate(`${t('Expired')}`);
    }, [])
    const { t } = useTranslation();
    return (
        <div className="RooQuestDeliverySection">
            <div className="deliveryMessage">
                <p>{t("Rooquest Expired")}</p>
                </div>
                <div className={`${rooquest.deliveredNotes || rooquest.deliveryImages.length > 0 || rooquest.receiptImages.length > 0 ? 'small' : null} deliveryImg`}>
                <img  src={require('./undraw_package_arrived.png')} alt="delivery guy" />
            </div>
        </div>
    )}

const DeliveryDeliveredConnected = ({ rooquest, setShowEnlargeImageModal, setDriveDate,setShowRatingsModal,showItemRatingsNotifModal }) => {
    const [showReportProblemModal, setShowReportProblemModal] = useState(false);
    const { t } = useTranslation();

    const openStripeReceipt = async () => {
        const link = rooquest.stripeReceiptURL;
        if (link) {
          InAppBrowser.create(link, '_blank', {
            zoom: 'no',
            hideurlbar: 'yes',
            hidenavigationbuttons: 'yes',
            toolbarcolor: '#073809',
            closebuttoncolor: '#ffffff',
            closebuttoncaption: 'Close',
          });
        }
      };

    useEffect(() => {
        setDriveDate(`${t('Delivered')}`);
        if(showItemRatingsNotifModal && !rooquest.requesterRatingLeft){
            setShowRatingsModal(true,'REQUESTER_REVIEW');
        }
    }, [])

    return (
        <div className="RooQuestDeliverySection">
            <div className="deliveryMessage">
                <p>{t("Items Delivered")}</p>
            </div>
            {rooquest.status === 'UNDER_REVIEW' && <p className="RQUnderReview">{t('RooQuest Under Review')}</p>}

            <div className={`${rooquest.deliveredNotes || rooquest.deliveryImages.length > 0 || rooquest.receiptImages.length > 0 ? 'small' : null} deliveryImg`}>
                <img  src={require('./undraw_package_arrived.png')} alt="delivery guy" />
            </div>

            {rooquest.deliveredNotes || rooquest.deliveryImages.length > 0 || rooquest.receiptImages.length > 0
                ?
                    <div className="deliveryNotes">
                        <p className='title'>{t('Delivery Details')}:</p>
                        <WhiteCard>
                            <div className="deliveryNotesText">
                                {rooquest.deliveryImages.length > 0 &&
                                    <div className="deliveryImages">
                                        {rooquest.deliveryImages.map((image, i) => {
                                            return (
                                                <div 
                                                    className="deliveryImage" 
                                                    key={`deliveryimage-${i}`}
                                                    onClick={() => setShowEnlargeImageModal(true, image)}
                                                >
                                                        <img src={image} alt="delivery" />
                                                </div>
                                            )
                                        }).reverse()}
                                        {rooquest.receiptImages.map((image, i) => {
                                            return (
                                                <div 
                                                    className="deliveryImage" 
                                                    key={`deliveryreceipt-${i}`}
                                                    onClick={() => setShowEnlargeImageModal(true, image)}
                                                >
                                                        <img src={image} alt="receipt" />
                                                </div>
                                            )
                                        })}                                        
                                    </div>
                                }
                                <p>{rooquest.deliveredNotes}</p>
                            </div>
                        </WhiteCard>
                    </div>
                : null            
            }

            
           
            <div className="additionalBtns">
                <div className="additionalBtn" onClick={openStripeReceipt}>
                    <p>{t("See Stripe Receipt")}</p>
                </div>
                <div className="additionalBtn" onClick={() => setShowReportProblemModal(true)}>
                    <p>{t("Report a Problem")}</p>
                </div>
               {!rooquest.requesterRatingLeft ? <div className="additionalBtn" onClick={() => setShowRatingsModal(true, 'REQUESTER_REVIEW')}>
                    <p>{t("Add a Review")}</p>
                </div> : ''}
            </div>
            <ReportProblemModal show={showReportProblemModal} setShow={setShowReportProblemModal} />
        </div>
    )
}

const DeliveryDelivered = connect(mapStateToProps, { setShowEnlargeImageModal,getDriverLocation,setShowRatingsModal,setShowItemRatingsNotifModal })(DeliveryDeliveredConnected);