
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './DeliveryTracking.scss'
import { Geolocation } from '@capacitor/geolocation';
import MapInstance from '../../DeliverNow/MapInstance/MapInstance';
import { postDeliveryId } from '../../../actions/rooquest/rooquest';
import { connect } from 'react-redux';
import { IonModal, useIonViewDidLeave, IonHeader, IonToolbar} from '@ionic/react';
import DeliveryModalContent from './DeliveryModalContent';
import { socket } from '../../../App';
import { getDriverLocation } from '../../../actions/rooquest/rooquest';
import {setIsLoading} from '../../../actions/loading/loading'
import LoaderContainer from '../../../pages/Landing/LoaderContainer';
import { animateMarkerTo } from '../../../utils/backgroundGeolocation';

const DeliveryTracking = ({rooquest,
    postDeliveryId,
    openChat,
    t,
    closeModal,
    next,
    show,
    setShow,
    isClient= false,
    getDriverLocation,
    setDriveDate = null,
    setIsLoading,
    step = null
}) => {

   const [deliverCurrentPosition,setDeliverCurrentPosition] = useState(null);
   const [driverPosition,setDriverPosition] = useState({lat:0,lng:0});
   const [center,setCenter] = useState({lat:0,lng:0});
   const [time, setTime] = useState(null);
   const [distance, setDistance] = useState(null);
   const modal = useRef(null);

//    const [mapCenter,setMapCenter] = useState(null);
//    const [directions,setDirections] = useState(null);
   const [isTracking,setIsTracking] = useState(false);
   const [click,setClick] = useState(0)
   const [watchID,setWatchID]=useState(null);
   const mapRef = useRef(null);
   const markerRef= useRef(null);
   const options = useMemo(()=> ({
    // mapId:"ce8355f67058c4c1",  // change style of the map
    disableDefaultUI:true, // remove default buttons like +/- to zoom or map/satelite view button
    clickableIcons: false, // can't click the default icons present in google map
}),[]);

const close = () => {
    console.log('...closing')
    setIsTracking(false);
    setShow(false);
    setClick(click+1)
}

useIonViewDidLeave(()=> {
    setShow(false);
})

useEffect(()=>{
    if(click !== 0){
        next();
        setClick(0);
    }

},[click])

    //will get initial position of the driver and will set the center
   useEffect(()=> {
    const getInitialPosition = async () =>{
        try {
            if(!isClient)
            {
                setIsLoading(true); 
                console.log("test")
                //on the deliverer side get the location using the capacitor plugin
                const geolocation = await Geolocation.getCurrentPosition({
                    enableHighAccuracy: true,
                  });
                const position = {
                lat: geolocation.coords.latitude,
                lng: geolocation.coords.longitude,
                }
                setDeliverCurrentPosition(position);
                setDriverPosition(position);
                const midPoint = findMidPoint(position,rooquest.deliveryAddress)
                setCenter(midPoint);
                let res = await postDeliveryId(rooquest._id,geolocation.coords.latitude,geolocation.coords.longitude);
            }
            else {
                console.log("test2")
                //on client side get the location of the driver from the backend
                const res = await getDriverLocation(rooquest._id);
                console.log(res)
                if (res) {
                    console.log('abc', res);
                    // setTime(res.data.time);
                    setDriveDate(res.data.time)
                    // setDistance(res.data.distance);
                    setDriverPosition({ lat: res.data.delivererLocation.lat, lng: res.data.delivererLocation.lng })
                }
            }
        } catch (err) {
            console.error(err);
            // alert("location is not included in your plan")
            // const position = { lat : 0,lng:0}
            // setDeliverCurrentPosition(position);
            // setDriverPosition(position);
            // const midPoint = findMidPoint(position,rooquest.deliveryAddress)
            // setCenter(midPoint);
        }
    }
    setShow(true);
    getInitialPosition();    
    //get the driver location and animate the marker to the location
    socket.on("driverlocation", (data) => {
        // if(step && step === 3)
        // if(!isClient)
        //     return;
        console.log("event received", data);
        try{
            if(setDriveDate && rooquest._id.toString() !== data.requestId.toString())
                setDriveDate(data.time)
        }
        catch(err)
        {
            console.log(err)
            console.log(data.time)
        }
        // setDistance(data.distance);
        const destination = { lat: data.lat, lng: data.lng};
        animateMarkerTo(markerRef.current,destination);
    });
   },[])

   


   const findMidPoint = (firstMarker, secondMarker) =>
    {

    const x = (firstMarker.lat + secondMarker.lat)/2
    const y = (firstMarker.lng + secondMarker.lng)/2
    const result = {
        lat: x,
        lng : y
    }
    return result
    }

   useEffect(() => {
    if(deliverCurrentPosition && !isTracking && !isClient)
    {
        startTracking();
        setIsLoading(false);
    }
   },[deliverCurrentPosition])

const postDataWithLatLong = async () => {
  try{
    console.log('post data with lat/lng')
    let geo = await Geolocation.getCurrentPosition({
    enableHighAccuracy: true,
    });
    const destination = {lat:geo.coords.latitude,lng:geo.coords.longitude}
    let res = await postDeliveryId(rooquest._id,geo.coords.latitude,geo.coords.longitude);
    if (res) {
    console.log(res);
    }
    animateMarkerTo(markerRef.current,destination)
  }
  catch(err)
  {
    console.log(err)
  }

}

   useEffect(()=> {
    console.log("istracking changed")
    if(!isTracking && watchID)
        clearWatch();

    if(isTracking){
        watchPosition();
    }

   },[isTracking])

   const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    },[]);

    const onMarkerLoad = useCallback((marker) => {
        markerRef.current = marker
    },[]);

    const startTracking = () => {
        setIsTracking(true);
    }



    const watchPosition = () => {

        // BackgroundGeolocation.ready({
        //     desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
        //     distanceFilter: 250,
        //     // useSignificantChangesOnly : true,
        //     // stopOnTerminate: false,
        //     startOnBoot: true,
        //     locationAuthorizationRequest:"Always",
        //     url: `${backendAddress}/api/rooquests/updateDelivererLocationBackground`,
        //     params: {
        //         userID: rooquest.deliverer,
        //     }
        //   }).then((state) => {
        //     console.log("[ready] BackgroundGeolocation is configured and ready to use");

        //     // This handler fires whenever bgGeo receives a location update.
        //     BackgroundGeolocation.onLocation(location => {
        //         console.log(new Date())
        //         console.log("[location] ", location);
        //         const destination = {lat : location.coords.latitude , lng : location.coords.longitude}
        //         animateMarkerTo(markerRef.current,destination)
        //     }, error => {
        //         console.log("[location] ERROR: ", error);
        //     });
            
        //     // This handler fires on HTTP responses
        //     BackgroundGeolocation.onHttp(response => {
        //         console.log("[http] ", response);
        //     });
          
        //     BackgroundGeolocation.start().then((state) => {
        //         console.log('[start] success - ', state);
        //       });
        //   })

        // let data;
        // if (isTracking) {
        //     console.log('start tracking')
        //     data = setInterval(function(){
        //         console.log("setinterval")
        //         postDataWithLatLong();
        //         }, 6000);
        //       setWatchID(data)
        // }
        // else
        // {
        //     console.log('clear intervall')
        //     clearInterval(watchID);
        //     clearInterval(data)
        // }


        // //cleanup function
        // return () => {
        //     if(data)
        //         clearInterval(data);
        // };
    }

    const clearWatch = () => {
        console.log("clear watch")
        // BackgroundGeolocation.stop().then((state) => {
        //     console.log('[stop] success - ', state);
        //   });
        // console.log(watchID)
        // if (watchID) {
        //     console.log("ici")
        //     clearInterval(watchID)
        //     // Geolocation.clearWatch(watchID);
        // }
        setIsTracking(false);
    }

    return(
        <div className='deliveryTracking'>
            <LoaderContainer />
            <MapInstance
            center={center}
            zoom = {18}
            markers = {[]}
            driverPosition = {driverPosition}
            deliveryDestination = {rooquest.deliveryAddress}
            pickupLocation = {null}
            dropoffLocation = {null}
            onMarkerLoad = {onMarkerLoad}
            options = {options}
            rooquest = {rooquest}
            markerRef = {markerRef}
            mapRef = {mapRef}
            isClient = {isClient}
            />

            {!isClient &&
            <>
            <IonModal
                ref = {modal}
                isOpen = {show}
                mode = 'ios'
                initialBreakpoint= {0.33}
                breakpoints= {[0.1, 1]}
                backdropDismiss={false}
                // onDidDismiss={ () => setShow(false) }
                backdropBreakpoint =  {0.5}
            >
                    <IonHeader className='deliveryHeader'>
                        <IonToolbar className='deliveryToolbar'>
                        {/* <IonTitle slot='start'>Modal</IonTitle> */}
                        </IonToolbar>
                    </IonHeader>
                    <DeliveryModalContent
                    openChat = {openChat}
                    rooquest = {rooquest}
                    t = {t}
                    closeModal = {closeModal}
                    next = {next}
                    close = {close}
                    />
            </IonModal>
            {/* <IonFab vertical="bottom" horizontal="end" slot="fixed" onClick={ ()=>{setShow(true)}}>
							<IonFabButton className='fabButton'>
								<IonIcon icon={ list } />
							</IonFabButton>
						</IonFab> */}
                        </>}
        </div>
    )
}

export default connect(null,{postDeliveryId,getDriverLocation,setIsLoading})(DeliveryTracking);