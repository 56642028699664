import React, { useState,useEffect  } from 'react';
import { IonContent, IonPage, IonToggle, IonList, IonItem, IonLabel, IonButton, IonAlert, IonSelect, IonSelectOption,IonIcon } from '@ionic/react';
import { arrowForward, logOut } from 'ionicons/icons';
import './Settings.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsHeader from '../../../components/layout/SettingsHeader/SettingsHeader';
import {updateUnitMethod,updateLanguage,updateThemes, updateUserMode} from '../../../actions/user/user';
import { removeMyStripeAccount } from '../../../actions/payments/payments';
import { removeUserAccount } from '../../../actions/user/user';
import { loadUser, logout } from '../../../actions/auth/auth';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Device } from '@capacitor/device';
import { setUseLiveStripeValue } from '../../../actions/stripe/stripe';
import { launchToastCard } from '../../../actions/layout/toast';
const Settings = ({
    auth,
    updateUnitMethod,
    updateLanguage,
    updateThemes,
    updateUserMode,
    logout,
    setUseLiveStripeValue,
    loadUser,
    launchToastCard
 }) => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(true);
    const [checkedMode,setCheckedMode] = useState(false);
    const [units, setUnits] = useState("metrics");
    const [mode,setMode] = useState('test')
    const [showRemoveStripeAlert, setShowRemoveStripeAlert] = useState(false);
    const [showRemoveAccountAlert,setShowRemoveAccountAlert] = useState(false);
    const [showSwitchmodeAlert,setShowSwitchmodeAlert] = useState(false);
    const [language, setLanguage] = useState('en');
    const [theme, setTheme] = useState();

    
    useEffect(() => {

        const getDeviceLanguage = async ()=>{
            const language = (await Device.getLanguageCode()).value; // en-US
            console.log("getting device language")
            console.log(language)
            if(language.includes('fr')){
                setLanguage('fr');
            }
            else if(language.includes('ukr')){
                setLanguage('uk');
            }
            else if(language.includes('es') || language.includes('spa'))
            {
                setLanguage('sp');
            }
            else{
                setLanguage('en');
            }

            

            
        }
        if (!!auth) {
            if (!!auth.distanceUnits && auth.distanceUnits === "metrics") {
                setChecked(true)
                setUnits("metrics");
            }
            if (!!auth.distanceUnits && auth.distanceUnits === "imperial") {
                setChecked(false)
                setUnits("imperial");
            }
        if(!!auth.default_language){
            setLanguage(auth.default_language);
        }
        if(!!auth.mode){
            setMode(auth.mode);
            if(auth.mode === 'live')
            {
                setCheckedMode(true);
                setUseLiveStripeValue(true);
            }
            setUseLiveStripeValue(false);
        }
        else{
            getDeviceLanguage()
        }
        if(!!auth.color_theme){
            setTheme(auth.color_theme);
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     if(language){
    //         console.log(language)
    //     }
    // }, [language]);
    const history = useHistory();
    const selLanguage = (lang) => { 
        if(!!lang){
            i18n.changeLanguage(lang);
            updateLanguage(lang);
            setLanguage(lang);
        }
    }
    const seltheme = (selectedtheme) => {
        if(!!selectedtheme){
            updateThemes(selectedtheme);
            document.body.classList.value = selectedtheme;
            setTheme(selectedtheme);
        }
    }

    const liveModeToggle =  async (e) => {
        if(e.detail.checked === true){
            setCheckedMode(true);
            setMode('live');
            updateUserMode('live');
            setUseLiveStripeValue(true);
            history.push('/home')
            // await SplashScreen.show({
            //     autoHide: false,
            //   });
            window.location.reload();
            // launchToastCard({ 
            //     msg: `The app has been successfully switched to ${mode} mode`,
            //     type: 'success',
            //     duration: 8500,
            // })
           
        } else {
            setCheckedMode(false);
            setMode('test');
            updateUserMode('test');
            setUseLiveStripeValue(false);
            history.push('/home')
            // await SplashScreen.show({
            //     autoHide: false,
            //   });
            window.location.reload();
        } 

    }
    const darkModeToggle = async (e) => {
        if(e.detail.checked === true){
            setChecked(true);
            updateUnitMethoddata("metrics");
           
        } else {
            setChecked(false);
            updateUnitMethoddata("imperial");
        }  
      }
    const updateUnitMethoddata = async (unitdata) => {
        const updatedMethod = await updateUnitMethod(unitdata);
        if(updatedMethod === "metrics"){
            setUnits("metrics");
        }
        if(updatedMethod === "imperial"){
            setUnits("imperial");
        }
      }
    
    const removeStripeAccount = async () => {
        console.log("Removing Stripe Account");
        try{
            await removeMyStripeAccount();
            await loadUser();
            launchToastCard({ 
                msg: t(`Your Stripe account has successfully been removed`),
                type: 'success',
                duration: 8500,
            })

        }
        catch(err)
        {
            console.log(err)
        }
    }

     
    const removeAccount = async () => {
        console.log("Removing Roo Account");
        await removeUserAccount();
        logout();
    };

    const goToEmailNotificationSettings = async () => {
          history.push('/EmailNotificationSettings')
      }
      const goToNotificationSettings = async () => {
        history.push('/NotificationSettings')
    }
    const options = {
        cssClass: 'my-custom-interface'
      };
      
    return (
        <IonPage className="questreq">
            <IonContent>
            <SettingsHeader value={t("Settings")} />
                <div className="FullQuest">
                    <div className="questcontainer questTitle">
                        <div className="fullInnerContainer">
                            <div className="questreqdisplay">
                            <IonList>
                            {/* <IonItem>
                            <IonLabel>{t('Units')}: {t('Metrics')}/{t('Imperial')}</IonLabel>
                                 <IonToggle checked={checked}  onIonChange={e => darkModeToggle(e)} /><b className="unitmethod">{`${t(units)}`}</b>
                            </IonItem> */}
                            <IonItem>
                            <IonLabel>{t('Live')}/{t('Test')} {t('mode')}</IonLabel>
                                 <IonToggle checked={checkedMode}  onIonChange={e => setShowSwitchmodeAlert(true)} /><b className="unitmethod">{`${t(mode)}`}</b>
                            </IonItem>
                            <IonItem>
                            <IonLabel>{t('Remove Stripe Account')}</IonLabel>
                                 <IonButton color="danger" onClick={e => setShowRemoveStripeAlert(true)}>{t('Remove')}</IonButton>
                            </IonItem>
                            <IonItem>
                            <IonLabel>{t('Delete Roo Account')}</IonLabel>
                            <IonButton color="danger" onClick={e => setShowRemoveAccountAlert(true)}>{t('Delete')}</IonButton>
                            </IonItem>
                            <IonItem lines='inset'>
                            <IonLabel>{t('Change Language')}</IonLabel>
                                <IonSelect okText={t('Ok')} cancelText = {t('Cancel')} interfaceOptions={options} value={language} onIonChange={(e) => selLanguage(e.detail.value)}>
                                <IonSelectOption value="en">{t('English')}</IonSelectOption>
                                <IonSelectOption value="fr">{t('French')}</IonSelectOption>
                                <IonSelectOption value="uk">{t('Ukrainian')}</IonSelectOption>
                                <IonSelectOption value="sp">{t('Spanish')}</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem lines='inset'>
                            <IonLabel>{t('Change Theme')}</IonLabel>
                                <IonSelect okText={t('Ok')} cancelText = {t('Cancel')} interfaceOptions={options} value={theme} onIonChange={(e) => seltheme(e.detail.value)}>
                                <IonSelectOption value="null">{t('Light')}</IonSelectOption>
                                <IonSelectOption value="dark">{t('Dark')}</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem onClick={() => goToEmailNotificationSettings()}>
                            <IonLabel>{t('Email Notification Settings')}</IonLabel>
                                <IonIcon  icon={arrowForward} />
                            </IonItem>
                            <IonItem onClick={() => goToNotificationSettings()}>
                            <IonLabel>{t('Notification Settings')}</IonLabel>
                                <IonIcon  icon={arrowForward} />
                            </IonItem>
                            
                            </IonList>
                            </div> 
                        </div>
                    </div>
                    <IonAlert
                        isOpen={showRemoveStripeAlert}
                        mode="ios"
                        onDidDismiss={() => setShowRemoveStripeAlert(false)}
                        cssClass='my-custom-class'
                        header={t('Are you sure you want to remove your Stripe account?')}
                        buttons={[
                            {
                            text: t('Cancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                            }
                            },
                            {
                            text: t('Delete'),
                            handler: () => {
                                removeStripeAccount();
                            }
                            }
                        ]}
                        />
                        <IonAlert
                        isOpen={showRemoveAccountAlert}
                        mode="ios"
                        onDidDismiss={() => setShowRemoveAccountAlert(false)}
                        cssClass='my-custom-class button'
                        header={t('Are you sure you want to delete your Roo account?')}
                        buttons={[
                            {
                            text:t('Cancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                            }
                            },
                            {
                            text: t('Delete'),
                            cssClass: 'button',
                            handler: () => {
                                removeAccount();
                            }
                            }
                        ]}
                        />

                <IonAlert
                    isOpen={showSwitchmodeAlert}
                    onDidDismiss={() => setShowSwitchmodeAlert(false)}
                    mode="ios"
                    cssClass="confirmCancelRooQuestAlert"
                    header={`${t('Refresh Roo?')}`}
                    message={
                      `${t(`Roo needs to refresh to apply these changes.`)}`
                    }
                    buttons={[
                      {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                      },
                      {
                        text: 'Refresh',
                        handler: async (alertData) => {
                            const e = {
                                detail:{
                                    checked : !checkedMode
                                }
                            }
                          await liveModeToggle(e);
                        },
                      },
                    ]}
                  />
                </div>
            </IonContent>
        </IonPage>

    )
}
const mapStateToProps = (state) => ({
    auth: state.auth.user,
  });

Settings.propTypes = {
    updateUnitMethod: PropTypes.func.isRequired,
    updateLanguage:PropTypes.func.isRequired,
    updateThemes:PropTypes.func.isRequired
  };

export default connect(mapStateToProps,{
    updateUnitMethod,
    updateLanguage,
    updateThemes,
    updateUserMode,
    logout,
    setUseLiveStripeValue,
    launchToastCard,
    loadUser
})(Settings);
