import {
  GETTING_CONVERSATIONS,
  CONVERSATIONS_LOADED,
  GET_CONVERSATIONS_ERROR,
  GETTING_MESSAGES_BY_CHATID,
  CLEAR_MESSAGES,
  SEND_PRIVATE_MESSAGE,
  MESSAGES_ERROR,
  RECEIPT_OF_MESSAGE,
  CONVERSATION_LOADED,
  GETTING_CONVERSATION,
  CLEAR_CONVERSATION,
  MESSAGES_LOADED,
  SHOW_TYPING_ANIMATION,
  HIDE_TYPING_ANIMATION,
  ADD_UNREAD_TO_CONVERSATION,
  UPDATE_CONVERSATION_UNREAD_COUNT,
  SET_SHOW_CHAT_MODAL,
} from '../actions/chat/chat-types';

const initialState = {
  loading: true,
  conversations: [],
  conversation: null,
  messages: [],
  showTypingAnimation: false,
  showChatModal: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETTING_CONVERSATIONS:
    case GETTING_CONVERSATION:
    case GETTING_MESSAGES_BY_CHATID:
      return {
        ...state,
        loading: true,
      };
    case CONVERSATIONS_LOADED:
      return {
        ...state,
        loading: false,
        conversations: payload,
      };
    case CONVERSATION_LOADED:
      return {
        ...state,
        loading: false,
        conversation: payload,
      };
    case CLEAR_CONVERSATION:
      return {
        ...state,
        loading: false,
        conversation: null,
        messages: [],
        showTypingAnimation: false,
      };
    case GET_CONVERSATIONS_ERROR:
      return {
        ...state,
        loading: false,
        conversations: [],
        messages: [],
        conversation: null,
      };
    case MESSAGES_LOADED:
      return {
        ...state,
        loading: false,
        messages: payload,
      };
    case CLEAR_MESSAGES:
    case MESSAGES_ERROR:
      return {
        ...state,
        messages: [],
      };
    case SEND_PRIVATE_MESSAGE:
    case RECEIPT_OF_MESSAGE:
      //console.log(payload);
      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: [...state.conversation.messages, payload],
        },
      };
    case ADD_UNREAD_TO_CONVERSATION:
      state.conversations.map((convo) =>
        convo._id === payload.chatID
          ? (convo.numUnreadMessages += payload.amount)
          : convo
      );
      return {
        ...state,
      };
    case UPDATE_CONVERSATION_UNREAD_COUNT:
      state.conversations.map((convo) =>
        convo._id === payload.chatID
          ? (convo.numUnreadMessages = payload.amount)
          : convo
      );
      return {
        ...state,
      };
    case SHOW_TYPING_ANIMATION:
      return {
        ...state,
        showTypingAnimation: true,
      };
    case HIDE_TYPING_ANIMATION:
      return {
        ...state,
        showTypingAnimation: false,
      };
    case SET_SHOW_CHAT_MODAL:
      return {
        ...state,
        showChatModal: payload,
      };
    default:
      return state;
  }
}
