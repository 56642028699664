import axios from 'axios';
import {
  GET_ALL_ROOQUESTS,
  ROOQUEST_ERROR,
  SAVE_ROOQUEST,
  UNSAVE_ROOQUEST,
  GET_ROOQUEST_BY_ID,
  DELETE_ROOQUEST_BY_ID,
  POST_ROOQUEST,
  POST_DELIVERY_ID,
  GET_DRIVER_LOCATION,
  GET_MY_ROOQUESTS,
  GET_MY_DELIVERIES,
  DELIVER_ROOQUEST,
  GETTING_ROOQUESTS,
  UPDATE_ROOQUEST_STATUS,
  // UPDATE_ROOQUEST_ROUTES,
  SET_HAS_GEOLOCATION,
  ROOQUEST_ACTION_LOADING,
  HIDE_ROOQUEST,
  ROOQUEST_LOADING
} from './rooquest-types';
import {
  LOGOUT,
} from '../auth/auth-types';
import { CLEAR_ROOQUESTS } from '../rooquest/rooquest-types';
import { backendAddress } from '../../App';
import { launchToast, launchToastCard } from '../layout/toast';
import { loadUser } from '../auth/auth';
import { useTranslation } from 'react-i18next';
import { updateNavbarWithActiveRooquests,updateNavbarWithActiveDeliveries } from '../layout/navbar';
import { SET_SHOW_RATINGS_MODAL } from '../layout/navigation-types';


export const getFilteredRooquests =(userID,filters,latitude,longitude) => async (dispatch) => {
  
  dispatch({
    type: GETTING_ROOQUESTS,
  });
  try {
    // eslint-disable-next-line
    const rooquests = await axios.get(
      backendAddress +
      '/api/rooquests/filters',
      {
        params :{
          longitude: longitude,
          latitude: latitude,
          filters : filters,
          userID : userID
        }
      }
    );

    dispatch({
      type: GET_ALL_ROOQUESTS,
      payload: rooquests.data,
    });
    return 'done';
  } catch (err) {
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
}

export const getAllRooQuests = (
  radiusSliderValue,
  latitude,
  longitude
) => async (dispatch) => {
  dispatch({
    type: GETTING_ROOQUESTS,
  });
  try {
    // eslint-disable-next-line
    const rooquests = await axios.get(
      backendAddress +
      '/api/rooquests?' +
      'radiusSliderValue=' +
      radiusSliderValue +
      '&longitude=' +
      longitude +
      '&latitude=' +
      latitude
    );

    rooquests.data.forEach(async (rq) => {
      if (rq.pickupLocationType === 'address') {
        // await getRoutes(rq, callbackRoute, dispatch);
      }
    });

    dispatch({
      type: GET_ALL_ROOQUESTS,
      payload: rooquests.data,
    });
    return 'done';
  } catch (err) {
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  } finally {
    dispatch({
      type: ROOQUEST_LOADING,
      payload: false,
    })
  }
};

export const getMyRooQuests = () => async (dispatch) => {
  dispatch({
    type: GETTING_ROOQUESTS,
  });
  try {
    const rooquests = await axios.get(
      backendAddress + '/api/rooquests/myrooquests'
    );
    if(rooquests && rooquests.data){
      let activeRooquestCount = 0;
      console.log("ROOQUEST",rooquests.data)
      rooquests.data.forEach(element => {
        if(element.status == "ACTIVE" || element.status =="RQ_ONTHEWAY"){
          activeRooquestCount = activeRooquestCount + 1
        }
      });
      dispatch(updateNavbarWithActiveRooquests(activeRooquestCount));
    } else {
      dispatch(updateNavbarWithActiveRooquests(0));
    }
    
    dispatch({
      type: GET_MY_ROOQUESTS,
      payload: rooquests.data,
    });
  } catch (err) {
    if (err.response && err.response.data && err.response.data.msg) {
      // dispatch(launchToast(err.response.data.msg, 2000, 'top'));
      console.log(err.response.data.msg)
      if(err.response.data.msg.trim() !== "No token, authorization denied"){
      dispatch(launchToastCard({
        msg: err.response.data.msg,
        type: 'error',
        duration: 8500,
      }));
      dispatch({ type: CLEAR_ROOQUESTS });
    }
      // dispatch({ type: LOGOUT });
    }
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const getMyDeliveries = () => async (dispatch) => {
  dispatch({
    type: GETTING_ROOQUESTS,
  });
  try {
    const rooquests = await axios.get(
      backendAddress + '/api/rooquests/mydeliveries'
    );
    const msg = rooquests.data?.msg; 
    if(rooquests && rooquests.data){
      try{
        let activeDeliveriesCount = 0;
        console.log("DELIVERY",rooquests.data)
        rooquests.data.forEach(element => {
          if(element.status == "RQ_ACCEPTED" || element.status == "RQ_ONTHEWAY"){
            activeDeliveriesCount = activeDeliveriesCount + 1
          }
        });
        dispatch(updateNavbarWithActiveDeliveries(activeDeliveriesCount));
      }
      catch(err)
      {
        console.log(err)
      }
    } else {
      dispatch(updateNavbarWithActiveDeliveries(0));
    }
    dispatch({
      type: GET_MY_DELIVERIES,
      payload: rooquests.data,
    });
  } catch (err) {
    console.log(err)
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const getRooQuestByID = (id) => async (dispatch) => {
  console.log("AA")
  dispatch({
    type: GETTING_ROOQUESTS,
  });
  try {
    const rooquest = await axios.get(backendAddress + '/api/rooquests/' + id);

    dispatch({
      type: GET_ROOQUEST_BY_ID,
      payload: rooquest.data,
    });
    return rooquest.data;
  } catch (err) {
    console.log("EEEEEEEE", err)
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const getConfirmRooquest = () => async (dispatch) => {
  dispatch({
    type: GETTING_ROOQUESTS,
  });
  try {
    const rooquest = await axios.post(backendAddress + '/api/rooquests/requesterDeliveryListing');

    // dispatch({
    //   type: GET_ROOQUEST_BY_ID,
    //   payload: rooquest.data,
    // });
    return rooquest.data;
  } catch (err) {
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};
export const deleteRooQuestByID = (id) => async (dispatch) => {
  dispatch({
    type: GETTING_ROOQUESTS,
  });
  try {
    const rooquest = await axios.delete(backendAddress + '/api/rooquests/' + id);

    dispatch({
      type: DELETE_ROOQUEST_BY_ID,
      payload: rooquest.data,
    });
    return rooquest.data;
  } catch (err) {
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const cancelOwnRooQuest = (id) => async (dispatch) => {
  try {
    const rooquest = await axios.put(backendAddress + '/api/rooquests/cancelown/' + id);
    dispatch({
      type: UPDATE_ROOQUEST_STATUS,
      payload: rooquest.data,
    });
    return rooquest.data;
  } catch (err) {
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const saveRooQuest = (id) => async (dispatch) => {
  try {
    await axios.put(backendAddress + '/api/users/saveRQ/' + id);

    dispatch({
      type: SAVE_ROOQUEST,
      payload: id,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: ROOQUEST_ERROR,
      payload: { msg: 'ERROR SAVING ROOQUEST' },
    });
  }
};

export const unsaveRooQuest = (id) => async (dispatch) => {
  try {
    await axios.put(backendAddress + '/api/users/unsaveRQ/' + id);

    dispatch({
      type: UNSAVE_ROOQUEST,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const postNewRooQuest = (rq) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(rq);

  try {
    const returnedRQ = await axios.post(
      backendAddress + '/api/rooquests',
      body,
      config
    );

    dispatch({
      type: POST_ROOQUEST,
      payload: returnedRQ.data,
    });
    return returnedRQ.data;
  } catch (err) {
    console.error(err);
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const postDeliveryId = (rqid, rqlat, rqlong) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = {
    id: rqid,
    lat: rqlat,
    lng: rqlong
  };

  try {
    const returnedRQ = await axios.post(
      backendAddress + '/api/rooquests/updateDelivererLocation',
      JSON.stringify(body),
      config
    );

    dispatch({
      type: POST_DELIVERY_ID,
      payload: returnedRQ.data,
    });
    return returnedRQ.data;
  } catch (err) {
    console.error(err);
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const getDriverLocation = (rqid) => async (dispatch) => {
  
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = {
    request_id: rqid,

  };

  try {
    const returnedRQ = await axios.post(
      backendAddress + '/api/rooquests/getLatestDriverLocation',
      JSON.stringify(body),
      config
    );

    dispatch({
      type: GET_DRIVER_LOCATION,
      payload: returnedRQ.data,
    });
    return returnedRQ.data;
  } catch (err) {
    console.error("error", err);
    const { t } = useTranslation();
    // dispatch(launchToast("We do not have access to the driver's location.", 3000, 'top'));
    dispatch(launchToastCard({
      msg: `${t("We do not have access to the driver's location.")}`,
      type: 'error',
      duration: 8500,
    }));
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const deliverRooQuest = (rqID, firstName) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = {
    status: 'RQ_ACCEPTED',
    delivererFirstName: firstName,
  };

  try {
    const returnedRQ = await axios.put(
      backendAddress + '/api/rooquests/updatedeliverer/' + rqID,
      JSON.stringify(body),
      config
    );

    dispatch({
      type: DELIVER_ROOQUEST,
      payload: returnedRQ.data,
    });
    // dispatch(launchToast('RooQuest Accepted', 2000, 'top'));
    dispatch(launchToastCard({
      msg: "RooQuest Accepted",
      type: 'success',
      duration: 8500,
    }));
    return returnedRQ.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      // dispatch(launchToast(err.response.data.message, 2000, 'top'));
      dispatch(launchToastCard({
        msg: err.response.data.message,
        type: 'error',
        duration: 8500,
      }));
    }
    console.error(err);

    console.log(err.response);
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const hideRooQuest = (rqID, rqStatus) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = {
    request_id: rqID,
    request_enable: rqStatus,
  };

  try {
    const returnedRQ = await axios.post(
      backendAddress + '/api/rooquests/toggleRequest',
      JSON.stringify(body),
      config
    );

    dispatch({
      type: HIDE_ROOQUEST,
      payload: returnedRQ.data,
    });
    return returnedRQ.data;
  } catch (err) {
    console.error(err);
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const updateRooQuestStatus = (rqID, newStatus) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = {
    status: newStatus,
  };

  try {
    const returnedRQ = await axios.put(
      backendAddress + '/api/rooquests/updatestatus/' + rqID,
      JSON.stringify(body),
      config
    );

    dispatch({
      type: UPDATE_ROOQUEST_STATUS,
      payload: returnedRQ.data,
    });
    return returnedRQ.data;
  } catch (err) {
    console.error(err);
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const updateRooQuestWithoutCall = (rooquest) => async (dispatch) => {
  dispatch({
    type: UPDATE_ROOQUEST_STATUS,
    payload: rooquest,
  });
};

export const markRQAsOnTheWay = (
  rqID,
  newStatus,
  amountPaid,
  receiptImages
) => async (dispatch) => {
  dispatch({
    type: ROOQUEST_ACTION_LOADING,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = {
    delivererAmountPaid: amountPaid.toString(),
    status: newStatus,
    receiptImages,
  };

  try {
    const returnedRQ = await axios.put(
      backendAddress + '/api/rooquests/updatestatus/' + rqID,
      JSON.stringify(body),
      config
    );

    dispatch({
      type: UPDATE_ROOQUEST_STATUS,
      payload: returnedRQ.data,
    });
    // dispatch(launchToast('RooQuest On The Way', 2000, 'top'));
    dispatch(launchToastCard({
      msg: 'RooQuest On The Way',
      type: 'info',
      duration: 8500,
    }));
    dispatch(loadUser());
    return returnedRQ.data;
  } catch (err) {
    console.error(err);

    console.log(err.response.data);

    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(launchToastCard({
        msg: err.response.data.msg,
        type: 'error',
        duration: 8500,
      }))
    }

    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });

    return null;
  }
};

export const cancelRooQuestDelivery = (rqID, reason) => async (dispatch) => {
  dispatch({
    type: ROOQUEST_ACTION_LOADING,
  });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = {
    reason,
  };

  try {
    const returnedRQ = await axios.put(
      backendAddress + '/api/rooquests/canceldelivery/' + rqID,
      JSON.stringify(body),
      config
    );

    dispatch({
      type: UPDATE_ROOQUEST_STATUS,
      payload: returnedRQ.data,
    });
    // dispatch(launchToast('RooQuest Delivery Cancelled', 2000, 'top'));
    dispatch(launchToastCard({
      msg: 'RooQuest Delivery Cancelled',
      type: 'error',
      duration: 8500,
    }));
    dispatch(loadUser());

    return returnedRQ.data;
  } catch (err) {
    console.error(err);
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const markRQAsDelivered = (rqID, notes, deliveryImages,newAmountToCapture) => async (
  dispatch
) => {
  dispatch({
    type: ROOQUEST_ACTION_LOADING,
  });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = {
    deliveredNotes: notes,
    deliveryImages:deliveryImages,
    newAmountToCapture : newAmountToCapture
  };

  try {
    const returnedRQ = await axios.put(
      backendAddress + '/api/rooquests/markDelivered/' + rqID,
      JSON.stringify(body),
      config
    );

    dispatch({
      type: SET_SHOW_RATINGS_MODAL,
      payload: { visible:true, type:'DELIVERER_REVIEW' },
    });

    dispatch({
      type: UPDATE_ROOQUEST_STATUS,
      payload: returnedRQ.data,
    });

    // dispatch(launchToast('RooQuest Delivered', 2000, 'top'));
    //should not launch this notification
    // dispatch(launchToastCard({
    //   msg: 'RooQuest Delivered',
    //   type: 'success',
    //   duration: 8500,
    // }));
    dispatch(loadUser());
    return returnedRQ.data;
  } catch (err) {
    console.log(err)
    if (err.response.data.msg) {
      // dispatch(launchToast(err.response.data.msg, 3500, 'top'));
      dispatch(launchToastCard({
        msg: err.response.data.msg,
        type: 'error',
        duration: 8500,
      }));
    }
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const markDeliveryComplete = (rqID) => async (
  dispatch
) => {
  dispatch({
    type: ROOQUEST_ACTION_LOADING,
  });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };


  try {
    const returnedRQ = await axios.get(
      backendAddress + '/api/rooquests/clientConfirmationDeliverer/' + rqID,
      config
    );

    dispatch({
      type: SET_SHOW_RATINGS_MODAL,
      payload: { visible:true, type:'REQUESTER_REVIEW' },
    });

    dispatch({
      type: UPDATE_ROOQUEST_STATUS,
      payload: returnedRQ.data,
    });

    // dispatch(launchToast('RooQuest Delivered', 2000, 'top'));
    dispatch(launchToastCard({
      msg: 'RooQuest Delivered',
      type: 'success',
      duration: 8500,
    }));
    dispatch(loadUser());

    return returnedRQ.data;
  } catch (err) {
    console.error(err);
    if (err.response.data.msg) {
      // dispatch(launchToast(err.response.data.msg, 3500, 'top'));
      dispatch(launchToastCard({
        msg: err.response.data.msg,
        type: 'error',
        duration: 8500,
      }));
    }
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const sendmailrooquestIssue = (rqID, msgdata) => async (
  dispatch
) => {
  dispatch({
    type: ROOQUEST_ACTION_LOADING,
  });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = {
    message: msgdata,
  };

  try {
    const returnedRQ = await axios.post(
      backendAddress + '/api/rooquests/clientDelivererError/' + rqID,
      JSON.stringify(body),
      config
    );
    // dispatch(launchToast('Email send successfully', 2000, 'top'));
    dispatch(launchToastCard({
      msg: 'Email send successfully',
      type: 'success',
      duration: 8500,
    }));
    return returnedRQ.data;
  } catch (err) {
    console.error(err);
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const sendmailreportProblem = (rqID, reportProblemFormData,imagesString) => async (
  dispatch
) => {
  dispatch({
    type: ROOQUEST_ACTION_LOADING,
  });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const { subject,message,images} = reportProblemFormData

  const body = {
    subject:subject,
    message: message,
    images: imagesString
  };

  try {
    const returnedRQ = await axios.post(
      backendAddress + '/api/reportproblem/' + rqID,
      JSON.stringify(body),
      config
    );
    // dispatch(launchToast('Email send successfully', 2000, 'top'));
    dispatch(launchToastCard({
      msg: 'Thank you for your submission. We will look into this and get back to you soon.',
      type: 'success',
      duration: 8500,
    }));
    return returnedRQ.data;
  } catch (err) {
    console.error(err);
    dispatch({
      type: ROOQUEST_ERROR,
      payload: err,
    });
  }
};

export const userUpdatedRooQuest = (rqID, updatedRQ) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = {
    updatedRQ,
  };

  try {
    const returnedRQ = await axios.put(
      backendAddress + '/api/rooquests/updateown/' + rqID,
      JSON.stringify(body),
      config
    );

    // dispatch({
    //   type: UPDATE_ROOQUEST_STATUS,
    //   payload: returnedRQ.data,
    // });

    // dispatch(launchToast('Response', 2000, 'top'));
    return returnedRQ.data;
  } catch (err) {
    console.error(err);
  }
};

export const setHasGeolocation = (bool, rqID) => async (dispatch) => {
  dispatch({
    type: SET_HAS_GEOLOCATION,
    payload: { bool, rqID },
  });
};
