import React, { useEffect, useRef, useState } from 'react'
import { Geolocation } from '@capacitor/geolocation';
import { IonIcon } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';


import './AutoCompleteWithMap.scss';
import { regularMapColor, darkModeMapColor } from '../../feed/map/MapStyles';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';



export const isBusiness = (address) => {
    // Check if address.types includes 'store', 'establishment', 'point_of_interest', 'food'
    // and that the name does not include the street number
    if (address.types.length === 0) return false;

    if ((address.types.includes('store')
        || address.types.includes('establishment')
        || address.types.includes('point_of_interest')
        || address.types.includes('food'))
        && !address.name.includes(address.street_number)) {
        return true;
    }
    else return false;
}

const AutoCompleteWithMap = ({ address, setAddress, clearAddress,auth,dropoff }) => {

    const [mapInstantiated, setMapInstantiated] = useState(false);
    const addressInputRef = useRef(null);
    const autocompleteRef = useRef(null);
    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);
    const markerRef = useRef(null);
    const {t} = useTranslation();
    useEffect(() => {
        // If not address, then hide the map
        if (address.formatted_address === "") {
            mapContainerRef.current.style.display = 'none';
            addressInputRef.current.value = "";
        } else {
            let place:any = {
                geometry:{location:{lat:address.lat,lng:address.lng}}
            }
            mapContainerRef.current.style.display = 'block';
            addressInputRef.current.value = address.formatted_address;
            initMap(place);
        }
    }, [address])

    useEffect(() => {
        autocompleteRef.current = new google.maps.places.Autocomplete(addressInputRef.current, {
            componentRestrictions: { country: ["us", "ca", "ind"] },
            fields: ["address_components", "geometry", "name", "type", "formatted_address"],
        });
        autocompleteRef.current.addListener("place_changed", async (e) => {
            const place = await autocompleteRef.current.getPlace();
            if (place && place.geometry && place.address_components.length > 0) {
                if (!mapInstantiated) {
                    initMap(place);
                }
                else {
                    recenterMap(place);
                }

                mapContainerRef.current.style.display = 'block';

                const street_number = place.address_components.find(component => component.types.includes('street_number'));
                const route = place.address_components.find(component => component.types.includes('route'));
                const locality = place.address_components.find(component => component.types.includes('locality'));
                const postal_code = place.address_components.find(component => component.types.includes('postal_code'));
                const administrative_area_level_1 = place.address_components.find(component => component.types.includes('administrative_area_level_1'));
                const country = place.address_components.find(component => component.types.includes('country'));
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();


                setAddress({
                    name: place.name,
                    hasBusinessName: isBusiness(place),
                    types: place.types,
                    street_number: street_number ? street_number.long_name : "",
                    route: route ? route.long_name : "",
                    locality: locality ? locality.long_name : "",
                    postal_code: postal_code ? postal_code.long_name : "",
                    administrative_area_level_1: administrative_area_level_1 ? administrative_area_level_1.long_name : "",
                    country: country ? country.long_name : "",
                    lat: lat,
                    lng: lng,
                    formatted_address: place.formatted_address,
                })
            }
            // else {
            //     initMap(place.geometry.location = {lat:23.0225,lng:72.5714})
            // }
        });

        const setBiasToAutoComplete = async () => {
            let coords = {
                lat: 45.50884,
                lng: -73.58781,
            };
            let radius = 10000;
            try {
                const geolocation = await Geolocation.getCurrentPosition();
                coords = {
                    lat: geolocation.coords.latitude,
                    lng: geolocation.coords.longitude,
                };
                radius = geolocation.coords.accuracy / 100;
            } catch (err) {
                console.error(err);
            }
            const circle = new google.maps.Circle({
                center: coords,
                radius: radius,
            });
            autocompleteRef.current.setBounds(circle.getBounds());
        };
        setBiasToAutoComplete();

    }, [mapInstantiated, address, setAddress])

    const getMapStyle = () => {
        let mapStyle = regularMapColor;
        if (
            auth.user &&
            auth.user.color_theme === 'dark'
        ) {
            mapStyle = darkModeMapColor;
        }
        return mapStyle;
    };

    const initMap = (place) => {
        mapRef.current = new google.maps.Map(mapRef.current, {
            zoom: 15,
            center: { lat: 37.4221, lng: -122.0841 },
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: true,
            styles:getMapStyle(),
            streetViewControl: false,
        });
        markerRef.current = new google.maps.Marker({ map: mapRef.current, draggable: true });
        mapRef.current.setCenter(place.geometry.location);
        mapRef.current.panBy(0, -20);
        markerRef.current.setPosition(place.geometry.location);
        markerRef.current.setVisible(true);
        setMapInstantiated(true);
    }

    const recenterMap = (place) => {
        if (place) {
            mapRef.current.setCenter(place.geometry.location);
            mapRef.current.panBy(0, -20);
            markerRef.current.setPosition(place.geometry.location);
            markerRef.current.setVisible(true);
        }
    }

    return (
        <div className='AutoCompleteWithMap'>
            <div className="google-autocomplete-container">
                <div className="inputContainer">
                    <input
                        ref={addressInputRef}
                        id="pickup-address"
                        name="pickup-address"
                        required
                        autoComplete="off"
                        type='search'
                        tabIndex={-1}
                        placeholder={dropoff ? t("ex: 555 Main Street, Moncton, NB",{nsSeparator: false}) : t("ex: Walmart, Paul St, Dieppe",{nsSeparator: false})}

                    />
                    <span onClick={() => {
                        addressInputRef.current.value = '';
                        // hide map using css
                        if (mapInstantiated) {
                            markerRef.current.setVisible(false);
                            mapContainerRef.current.style.display = 'none';
                        }


                        clearAddress();
                    }}><IonIcon icon={closeCircleOutline} /></span>
                </div>

            </div>
            <div className={`google-map-confirm-container ${mapInstantiated && 'hasMap'}`} ref={mapContainerRef}>
                <div id="google-map-pickup-confirm" ref={mapRef}></div>
                {(address.postal_code !== "" || address.postal !== "") &&
                    <div className="address">
                        {address.hasBusinessName && <p className='name'>{address.name}</p>}
                        <p>{address.formatted_address}</p>
                    </div>
                }
            </div>
        </div>
    )
}

AutoCompleteWithMap.propTypes = {

}

const mapStateToProps = (state) => ({
    auth: state.auth,
  });



export default connect(mapStateToProps)(AutoCompleteWithMap)
