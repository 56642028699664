import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";

import './Payment.scss';
import { connect } from "react-redux";
import { preAuthAndPostRooQuest, updatePreAuthEditRQ } from "../../../actions/payments/payments";

import FlipNumbers from 'react-flip-numbers';
import { launchToastCard } from "../../../actions/layout/toast";
import { getStripePromise } from "../../../utils/initStripe";
import {IonSpinner } from "@ionic/react";
import { uploadRooQuestImages } from "../../../actions/firebase/firebase-storage";
import WhiteCard from "../components/ui/WhiteCard";
import { useTranslation } from "react-i18next";


const StripePaymentForm = ({ rq, preAuthAndPostRooQuest, status, setStatus, button, launchToastCard, updatePreAuthEditRQ, editRQ }) => {

    const [editRQInfo, setEditRQInfo] = useState({
      brand: '',
      last4: '',
    });

    // console.log(USE_LIVE_STRIPE)
    const [cardError, setCardError] = useState('');
    const [expiryError, setExpiryError] = useState('');
    const [cvcError, setCvcError] = useState('');
    const { t } = useTranslation();
    const setSucceeded = (succeeded) => {
      const newStatus = [...status];
      newStatus[4].payment.succeeded = succeeded;
      setStatus(newStatus);
    }

    const setError = (error) => {
      const newStatus = [...status];
      newStatus[4].payment.error = error;
      setStatus(newStatus);
    }

    const setProcessing = (processing) => {
      const newStatus = [...status];
      newStatus[4].payment.processing = processing;
      setStatus(newStatus);
    }

    const setDisabled = (disabled) => {
      const newStatus = [...status];
      newStatus[4].payment.disabled = disabled;
      setStatus(newStatus);
    }

    const setConfirmation = (confirmation) => {
      const newStatus = [...status];
      newStatus[5].code = confirmation;
      setStatus(newStatus);
    }

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
      if (button) button.current.onclick = (!editRQ) ? handleSubmit : handleEditSubmit;
      // eslint-disable-next-line
    }, [button]);


    const handleChange = async (event) => {
      // Listen for changes in the CardElement
      // and display any errors as the customer types their card details
      setDisabled(event.empty);
      setError(event.error ? event.error.message : "");

      if (event.elementType === 'cardNumber') {
        setCardError(event.error ? `${t(event.error.message)}` : "");
      }
      else if (event.elementType === 'cardExpiry') {
        setExpiryError(event.error ? `${t(event.error.message)}` : "");
      }
      else if (event.elementType === 'cardCvc') {
        setCvcError(event.error ? `${t(event.error.message)}` : "");
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault()

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      if (cardError !== '' || expiryError !== '' || cvcError !== '') return;
      if (status[4].payment.disabled) return;

      setProcessing(true);
      
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            line1: `${rq.dropoffAddress.street_number} ${rq.dropoffAddress.route}`,
            city: rq.dropoffAddress.locality,
            postal_code: rq.dropoffAddress.postal_code,
            state: rq.dropoffAddress.administrative_area_level_1,
          },
        },
      });

      if (error) {
        setError(`Payment failed. ${error.message}`);
        launchToastCard({ 
          msg: error.message,
          type: 'error',
          duration: 8500,
       });
        setProcessing(false);
        return;
      }

      const { id } = paymentMethod;

      const rqToUpload = rq;

      // Upload RooQuest Images to Firebase Storage
      for (let i = 0; i < rqToUpload.items.length; i++) {
        const item = rqToUpload.items[i];

        if (item.images.length > 0) {
          let images = await uploadRooQuestImages(item.images);
          if (images.length === 0) images = [];
          rqToUpload.items[i].images = images;
        }
      }

      const res = await preAuthAndPostRooQuest(
        rqToUpload,
        id,
        rq.costs.total,
      );
        
      if (res && res.type === 'success') {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
        setConfirmation(res.rqGeneratedID);

      } else {
        if (res && res.type === 'error' && res.msg) {
          setError(`Payment failed. ${res.msg}`);
          launchToastCard({ 
            msg: res.msg,
            type: 'error',
            duration: 8500,
         });

        }
        else {
          setError(`Payment failed.`);
          launchToastCard({ 
            msg: "Payment failed. Please try again.",
            type: 'error',
            duration: 8500,
         });
        }
        setProcessing(false);
      }     
    };

    const handleEditSubmit = async (e) => {
      e.preventDefault()
      console.log("Edit RQ")
      const rqToUpload = rq;
      console.log(rq)

      // Upload RooQuest Images to Firebase Storage
      for (let i = 0; i < rqToUpload.items.length; i++) {
        const item = rqToUpload.items[i];

        console.log(item.images)

        // if (item.images.length > 0) {
        //   let images = await uploadRooQuestImages(item.images);
        //   if (images.length === 0) images = [];
        //   rqToUpload.items[i].images = images;
        // }
      }

      const res = await updatePreAuthEditRQ(
        rqToUpload,
        rq.costs.total,
      );

      if (res && res.type === 'success') {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
        setConfirmation(res.rqGeneratedID);

      } else {
        if (res && res.type === 'error' && res.msg) {
          setError(`Payment failed. ${res.msg}`);
          launchToastCard({ 
            msg: res.msg,
            type: 'error',
            duration: 8500,
         });

        }
        else {
          setError(`Payment failed.`);
          launchToastCard({ 
            msg: "Payment failed. Please try again.",
            type: 'error',
            duration: 8500,
         });
        }
        setProcessing(false);
      }     
    };

    const cardItemStyle = {
      base: {
        color: '#012e0b',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '18px',
        lineHeight: '28px',
      },
      invalid: {
        iconColor: 'red',
        color: 'red',
      },
    }

    useEffect(() => {
      if (editRQ) {
        const rqLink = rq;

        setEditRQInfo({
          brand: rq.paymentDetails.brand,
          last4: rq.paymentDetails.last4,
        });
      }
      setDisabled(false);
    }, [rq, editRQ]);
    
    return (
      <div className="PostRQPaymentPage">
        <div className="itemsTitle">
          <p className='title'>{t("Enter Payment Details")}</p>
          <FlipNumbers 
              height={14} 
              width={9.5} 
              color="#10be5f" 
              // background="white" 
              play perspective={100} 
              duraction={0.4}
              numbers={'$'+ rq.costs.total.toFixed(2)} 
          />
        </div>
        {!editRQ ? <>
          <form id="payment-form" tabIndex={-1}>
            <div className="elementContainer">
              <p>{t("Card Number")}</p>
              <div className="cardElement">
                <CardNumberElement 
                  className="test"
                  onChange={handleChange} 
                  options={{ showIcon: true, style: cardItemStyle, }}
                />
              </div>
              <p className="error">{cardError}</p>
            </div>   
              <div className="form-row">
                <div className="elementContainer">
                  <p>{t("Expiry")}</p>
                  <div className="cardElement expiry">
                    <CardExpiryElement
                      onChange={handleChange} 
                      options={{ style: cardItemStyle, }} />
                  </div>
                  <p className="error">{expiryError}</p>
                </div>
                <div className="elementContainer">
                  <p>{t("CVC")}</p>
                  <div className="cardElement cvc">
                    <CardCvcElement
                      onChange={handleChange} 
                      options={{ style: cardItemStyle, }} />
                  </div>
                  <p className="error">{cvcError}</p>
                </div>
              </div>
          </form>
          <p><img className="stripe-logo" src={require('./stripe-badge-grey.png')}/></p>
          </>

          :

          <WhiteCard>
            <div className="newPreauthContainer">
              <p>Your old pre-authorization for this RooQuest will be cancelled and a new pre-authoriziation will be made on your <span>{editRQInfo.brand.toUpperCase()}</span> card ending in <span>{editRQInfo.last4}</span></p>
            </div>
          </WhiteCard>
        }
      </div>
    )
}

const Payment = ({ rq, preAuthAndPostRooQuest, status, setStatus, button, launchToastCard, activeIndex, updatePreAuthEditRQ, editRQ,USE_LIVE_STRIPE }) => {

  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const getPromise = async () => {
      const promise = await getStripePromise(USE_LIVE_STRIPE)
      setStripePromise(promise);
    }
    getPromise();
  }, []);

  return (
    stripePromise ? 
    <Elements stripe={stripePromise} options={
      {
        appearance: {
          theme: 'stripe',
        }
      }
    } >
      {activeIndex === 3 && 
      <StripePaymentForm 
          rq={rq} 
          preAuthAndPostRooQuest={preAuthAndPostRooQuest} 
          status={status} 
          setStatus={setStatus}
          button={button} 
          launchToastCard={launchToastCard}
          updatePreAuthEditRQ={updatePreAuthEditRQ}
          editRQ={editRQ}
      />  }
    </Elements> 
    : 
    <div className="postRQ_spinnerContainer">
      <IonSpinner name="crescent" />
    </div>
    )
}

Payment.propTypes = {
  rq: PropTypes.object.isRequired,
  preAuthAndPostRooQuest: PropTypes.func.isRequired,
  updatePreAuthEditRQ: PropTypes.func.isRequired,
  status: PropTypes.array.isRequired,
  setStatus: PropTypes.func.isRequired,
  button: PropTypes.any,
  editRQ: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  USE_LIVE_STRIPE : state.USE_LIVE_STRIPE
});

export default connect(mapStateToProps, { preAuthAndPostRooQuest, launchToastCard, updatePreAuthEditRQ })(Payment);
