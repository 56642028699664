import { SET_NOTIFICATION_RANGE } from "../actions/notificationrange/notificationrange-types";
  
  
  export default function (state = null, action) {
    const { type, payload } = action;
  
    switch (type) {
      case SET_NOTIFICATION_RANGE:
        return payload;
      default:
        return state;
    }
  }
  