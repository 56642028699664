import {
  SHOW_SAVED_ROOQUESTS,
  UNSHOW_SAVED_ROOQUESTS,
  SHOW_MAP_MODE_FEED,
  UNSHOW_MAP_MODE_FEED,
  SET_ROOQUEST_SEARCH_RADIUS,
  SET_DELIVER_NOW_FILTERS
} from '../../actions/layout/feedFilterBar-types';

const initialState = {
  mode: {
    map: false,
  },
  filters: {
    showSaved: false,
    rooquestSearchRadius: 350000,
    minDelivererFee : -1,
    storeName : "",
    maxPrice : -1,
    sortBy: "",
    radius: 350000
  },
  //radius in meters
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SAVED_ROOQUESTS:
      return {
        ...state,
        filters: {
          ...state.filters,
          showSaved: true,
        },
      };
    case UNSHOW_SAVED_ROOQUESTS:
      return {
        ...state,
        filters: {
          ...state.filters,
          showSaved: false,
        },
      };
    case SET_ROOQUEST_SEARCH_RADIUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          rooquestSearchRadius: payload,
        },
      };
    case SHOW_MAP_MODE_FEED:
      return {
        ...state,
        mode: {
          ...state.mode,
          map: true,
        },
      };
    case UNSHOW_MAP_MODE_FEED:
      return {
        ...state,
        mode: {
          ...state.mode,
          map: false,
        },
      };
      case SET_DELIVER_NOW_FILTERS:
        return {
          ...state,
          filters : {
            ...state.filters,
            minDelivererFee : payload.minDelivererFee,
            storeName : payload.storeName,
            maxPrice : payload.maxPrice,
            sortBy: payload.sortBy,
            radius: payload.radius
        }
        }
    default:
      return state;
  }
}
