import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

// TESTING
// export const stripePromise = loadStripe(
//   'pk_test_MWl8JXZjd9BrkaC6LPBciIDU00WIqbKIN3'
// );

// LIVE
export const stripePromise = loadStripe(
  'pk_live_WPlimM3NnFp3tiVvalXkucXM00ZOclAKOM'
);

export const getStripeClientID = (useLiveStripe) => {
  if (useLiveStripe) {
    return "ca_HVwf5K8eB6CIaKubMj6ZYlgnhZPVG2r4"
  }
  else {
    return "ca_HVwfgXIdVlb3JOI2rzWBi5iVR2D2WZsw"
  }
}

export const getStripePromise = async (useLiveStripe) => {
  if (useLiveStripe) {
    return await loadStripe('pk_live_WPlimM3NnFp3tiVvalXkucXM00ZOclAKOM');
  } else {
    return await loadStripe('pk_test_MWl8JXZjd9BrkaC6LPBciIDU00WIqbKIN3');
  }
};
