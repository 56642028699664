import { SET_STRIPE_API_KEY } from "../actions/stripe/stripe-types";
  
  
const USE_LIVE_STRIPE =  (state = false, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case SET_STRIPE_API_KEY:
        return payload;
      default:
        return state;
    }
  }

  export default USE_LIVE_STRIPE;