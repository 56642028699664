import React from 'react';
import { IonGrid, IonRow, IonCol, IonContent, IonPage } from '@ionic/react';
import './QuestReq.scss'
import GreenHeader from '../../layout/GreenHeader/GreenHeader';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const QuestReq = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const goToSingleItem = () => {
        history.push('/singleitemquest/new')
    }
     
  
    return (
        <IonPage className="questreq">
            <IonContent>
                <GreenHeader />
                <div className="FullQuest">
                    <div className="questcontainer">
                        <h5 className="questTitle">{t('Quest Type')}</h5>
                        <div className="fullInnerContainer">
                            <div className="questreqdisplay">
                                <p className="questHeaderText">{t('What type of RooQuest are you looking for today?')}</p>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="6" text-center onClick={() => { goToSingleItem() }}>
                                            <div className="enableformdiv"></div>
                                            <p className="enabletext">{t('Single Item')} </p>
                                        </IonCol>
                                        <IonCol size="6">
                                            <div className="disableformdiv"></div>
                                            <p className="disabletext">{t('Several Items')} </p>
                                            <p className="disabletext_cs">{t('Coming Soon')} </p>
                                        </IonCol>
                                        <IonCol size="6">
                                            <div className="disableformdiv"></div>
                                            <p className="disabletext">{t('Long Distance')}</p>
                                            <p className="disabletext_cs">{t('Coming Soon')} </p>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>

    )
}

export default QuestReq;

