import { IonPage, IonModal, IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonIcon, IonNote, IonRow, IonText, IonCardTitle, IonGrid, IonCol, IonItem, IonCardContent, IonContent, IonBadge, IonAlert, isPlatform } from "@ionic/react";
import { navigateOutline, arrowForward, chevronForward, personAddOutline, closeCircleOutline, chatbubbleEllipsesOutline, checkmarkCircleOutline } from "ionicons/icons";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cancelRooQuestDelivery, getMyDeliveries, updateRooQuestWithoutCall } from "../../../actions/rooquest/rooquest";
import MapInstance from "../../DeliverNow/MapInstance/MapInstance";
import './StartingDelivery.scss'
import { connect } from "react-redux";

const StartingDelivery = ({rooquest,t,next,launchNavigator,openChat,closeModal,cancelRooQuestDelivery, getMyDeliveries, updateRooQuestWithoutCall}) => {
   
const [showModal,setShowModal] = useState(true); 
const [center,setCenter] = useState({lat:0,lng:0});
const [zoom,setZoom] = useState(7);
const mapRef = useRef(null);
const [showCancelDeliveryAlert, setShowCancelDeliveryAlert] = useState(false);
const options = useMemo(()=> ({
    // mapId:"ce8355f67058c4c1",  // change style of the map
    disableDefaultUI:true, // remove default buttons like +/- to zoom or map/satelite view button
    clickableIcons: false, // can't click the default icons present in google map
    maxZoom: 20
}),[]);


const findMidPoint = (firstMarker, secondMarker) => 
{

   const x = (firstMarker.lat + secondMarker.lat)/2
   const y = (firstMarker.lng + secondMarker.lng)/2
   const result = {
       lat: x,
       lng : y
   } 
   return result
}
useEffect(()=>{
    const result = findMidPoint(rooquest.pickupAddress,rooquest.deliveryAddress);
    setCenter(result);

},[])

const cancelDelivery = async (reason) => {
    await cancelRooQuestDelivery(rooquest._id, reason)
    updateRooQuestWithoutCall(null);
    await getMyDeliveries();
    closeModal()
};



const navigator = async () => {
    try{

        const geocoords = rooquest.pickupAddress.lat + ',' + rooquest.pickupAddress.lng;
          if (isPlatform('ios')) {
            window.open('maps://?q=' + geocoords, '_system');
          }
          else {
            var label = encodeURI(rooquest.pickupAddress.formatted_address); // encode the label!
            window.open('geo:0,0?q=' + geocoords + '(' + label + ')', '_system');
          }
          
    }
    catch(err)
    {
        console.log(err);
    }
}
return(
    <IonPage className="startingDelivery">
        <IonContent>

                <MapInstance
                center={center} 
                zoom={zoom}
                markers = {null}
                driverPosition = {null}
                deliveryDestination = {null}
                pickupLocation={rooquest.pickupAddress}
                dropoffLocation = {rooquest.deliveryAddress}
                showPickupAndDropoff = {false}
                onMarkerLoad= {()=>{}}
                options = {options}
                showLabels = {false}
                enableDirections = {true}
                rooquest = {rooquest}
                next = {next}
                navigator = {navigator}
                openChat = {openChat}
                closeModal = {closeModal}
                showMapOverLay = {true}
                mapRef = {mapRef}
                />

                <IonAlert
                isOpen={showCancelDeliveryAlert}
                onDidDismiss={() => setShowCancelDeliveryAlert(false)}
                mode="ios"
                cssClass="confirmCancelRooQuestAlert"
                header={`${t('Cancel Delivery')} ?`}
                message={
                    `${t('Are you sure you want to cancel the delivery? This will be kept on your deliverer record and can lead to account penalties.')}`
                }
                inputs={[ { name: 'cancellationReason', type: 'text', placeholder: `${t('Reason for cancellation')}` }, ]}
                buttons={[
                    { text: `${t('Close')}`, role: 'cancel', cssClass: 'secondary' },
                    { text: `${t('Cancel Delivery')}`,
                        handler: async (alertData) => {
                        await cancelDelivery(alertData.cancellationReason);
                        },
                    },
                ]}
            />
               </IonContent>
</IonPage>
)
}

export default connect(null,{cancelRooQuestDelivery, getMyDeliveries, updateRooQuestWithoutCall})(StartingDelivery);