import axios from 'axios';
import { backendAddress } from '../../App';
import { UPDATE_ROOQUEST_STATUS } from '../rooquest/rooquest-types';
import { SET_SHOW_SHOW_APPROVE_REQUESTED_FUNDS_ALERT } from './payment-types';

// RETRIEVE STRIPE ACCOUNT, NOT CREATE. RENAME!
// export const createStripeAccount = () => async (dispatch) => {
//   try {
//     const res = await axios.post(
//       backendAddress + '/api/payment/stripe/account'
//     );
//     console.log(res);
//   } catch (err) {
//     console.error(err);
//   }
// };

export const getStripeAccountLink = async (accountID) => {
  try {
    const link = await axios.get(
      backendAddress + `/api/payment/stripe/account/link/${accountID}`
    );
    return link.data.url;
  } catch (err) {
    console.error(err);
  }
};

export const removeMyStripeAccount = async () => {
  try {
    const res = await axios.get(
      backendAddress + `/api/payment/stripe/account/remove`
    );
    return res.status;
  } catch (err) {
    console.error(err);
  }
};

export const payForRooQuest_Stripe = (
  rooquest,
  paymentMethodID,
  amount,
  kargorooFee,
  multiplier,
) => async (dispatch) => {
  try {
    const res = await axios.post(backendAddress + '/api/charge', {
      id: paymentMethodID,
      title: rooquest.title,
      amount: amount,
      rooquestID: rooquest._id,
      kargorooFee: kargorooFee,
      costOfGoodsMultiplier: multiplier
    });
    dispatch({
      type: UPDATE_ROOQUEST_STATUS,
      payload: res.data.rooquest,
    });

    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const refundAndPayForRooQuest_Stripe = (
  rooquest,
  paymentMethodID,
  amount,
  kargorooFee,
  multiplier
) => async (dispatch) => {
  try {
    const res = await axios.post(backendAddress + '/api/charge/refundCreateUserOrder', {
      id: paymentMethodID,
      title: rooquest.title,
      newAmount: amount,
      rooquestID: rooquest._id,
      kargorooFee: kargorooFee,
      costOfGoodsMultiplier: multiplier
    });
    dispatch({
      type: UPDATE_ROOQUEST_STATUS,
      payload: res.data.rooquest,
    });

    return res.data;
  } catch (err) {
    console.error(err);
  }
};

// v2
export const preAuthAndPostRooQuest = (
  rooquest,
  paymentMethodID,
  amount
) => async (dispatch) => {
  try {
    const res = rooquest._id ? await axios.post(backendAddress + '/api/charge/v2/editrooquest', {
      rooquest: rooquest,
      amount: amount,
    }) :
      await axios.post(backendAddress + '/api/charge/v2/postrooquest', {
        rooquest: rooquest,
        id: paymentMethodID,
        amount: amount,
    });
    dispatch({
      type: UPDATE_ROOQUEST_STATUS,
      payload: res.data.rooquest,
    });

    return res.data;
  } catch (err) {
    console.error(err);
    return err.response.data;
  }
};

// v2
export const updatePreAuthEditRQ = (
  rooquest,
  amount
) => async (dispatch) => {
  try {
    const res = await axios.post(backendAddress + '/api/charge/v2/editrooquest', {
      rooquest: rooquest,
      amount: amount,
    });
    dispatch({
      type: UPDATE_ROOQUEST_STATUS,
      payload: res.data.rooquest,
    });

    return res.data;
  } catch (err) {
    console.error(err);
    return err.response.data;
  }
};

export const requestMoreFunds = (rooquestID, amount,response=null) => async (dispatch)  => {
  try {
    console.log("request more funds")
    const res = await axios.post(
      backendAddress + `/api/charge/v2/requestfunds`,
      {
        rooquestID: rooquestID,
        amount: amount,
        response:response
      }
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const setShowApproveRequestedFundsAlert = (show) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SHOW_SHOW_APPROVE_REQUESTED_FUNDS_ALERT,
      payload: show,
    });
  } catch (err) {
    console.error(err);
  }
}