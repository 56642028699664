export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';

export const SET_SHOW_RQ_INFO_MODAL = 'SET_SHOW_RQ_INFO_MODAL';
export const SET_SHOW_MYRQ_INFO_MODAL = 'SET_SHOW_MYRQ_INFO_MODAL';
export const SET_SHOW_MYDELIV_INFO_MODAL = 'SET_SHOW_MYDELIV_INFO_MODAL';

export const SET_SHOW_ENLARGE_IMAGE_MODAL = 'SET_SHOW_ENLARGE_IMAGE_MODAL';

export const SET_SHOW_RATINGS_MODAL = 'SET_SHOW_RATINGS_MODAL';
export const SET_SHOW_FIRST_OFFER_SUCCESS_MODAL = 'SET_SHOW_FIRST_OFFER_SUCCESS_MODAL';

export const SET_SHOW_TUTORIAL_MODAL = 'SET_SHOW_TUTORIAL_MODAL';
export const SET_SHOW_POSTRQ_MODAL = 'SET_SHOW_POSTRQ_MODAL';
export const SET_SHOW_EDITRQ_MODAL = 'SET_SHOW_EDITRQ_MODAL';
export const SET_SHOW_PROMO_MODAL = 'SET_SHOW_PROMO_MODAL';
export const SET_SHOW_BUG_REPORT_MODAL = 'SET_SHOW_BUG_REPORT_MODAL';
export const SET_SHOW_MAP_MODAL = 'SET_SHOW_MAP_MODAL';
export const SET_SHOW_LOCATION_ON_MAP_MODAL = 'SET_SHOW_LOCATION_ON_MAP_MODAL';
export const SET_SHOW_MYADDRESSES_MODAL = 'SET_SHOW_MYADDRESSES_MODAL';
export const SET_SHOW_NOTIFICATION_RANGE_MODAL = 'SET_SHOW_NOTIFICATION_RANGE_MODAL';
export const SET_SHOW_ITEM_RATINGS_NOTIF_MODAL = 'SET_SHOW_ITEM_RATINGS_NOTIF_MODAL';
export const SET_SHOW_NOTIF_MODAL = 'SET_SHOW_NOTIF_MODAL';