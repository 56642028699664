import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './VerticalAddressComponent.scss'
import { IonIcon } from '@ionic/react'
import { locationOutline } from 'ionicons/icons'
import { isBusiness } from './AutoCompleteWithMap'
import { Clipboard } from '@capacitor/clipboard';
import { connect } from 'react-redux'
import { launchToastCard } from "../../../actions/layout/toast";
import { useTranslation } from 'react-i18next'
const VerticalAddressComponent = ({ pickupAddress, dropoffAddress, showFullAddress = true,launchToastCard }) => {
    const { t } = useTranslation();
    const [pickupAddr, setPickupAddr] = useState("");
    const [dropoffAddr, setDropoffAddr] = useState("");

    const writeToClipboard = async (address) => {
        await Clipboard.write({
          string: address
        });
        launchToastCard({ 
            msg: `${t('Text has been copied to clipboard')}`,
            type: 'info',
            duration: 8500,
         })
      };

    useEffect(() => {        
        if (showFullAddress) {
            setPickupAddr(pickupAddress.formatted_address);
            setDropoffAddr(dropoffAddress.formatted_address);
        }
        else if (isBusiness(pickupAddress)) {
            setPickupAddr(pickupAddress.formatted_address);
            setDropoffAddr(`${dropoffAddress.city}, ${dropoffAddress.province}, ${dropoffAddress.country}`);
        }
        else {
            setPickupAddr(`${pickupAddress.formatted_address}`);
            setDropoffAddr(`${dropoffAddress.formatted_address}`);
        }
    }, [pickupAddress, dropoffAddress, showFullAddress]);


    return (
        (pickupAddress && dropoffAddress) ?
        <div className='VerticalAddressComponent'>
            <div className="route-item">
                <div className="iconContainer">
                    <IonIcon src={require("./storefront-outline.svg")} />
                    <div className="dottedLine" />
                </div>
                <p className="pickup-location"><span>{isBusiness(pickupAddress) && (pickupAddress.name + ', ')}</span>{pickupAddr}</p>
                <div className="iconContainer" onClick={() => writeToClipboard(isBusiness(pickupAddress) && (pickupAddress.name + ', ') ? isBusiness(pickupAddress) && (pickupAddress.name + ', ') + pickupAddr:pickupAddr)}>
                <IonIcon src={require("./clipboard-outline.svg")} />
                </div>
            </div>
            <div className="route-item">   
                <div className="iconContainer">
                    <IonIcon icon={locationOutline} />
                </div>
                <p className="pickup-location"><span>{isBusiness(dropoffAddress) && (dropoffAddress.name + ', ')}</span>{dropoffAddr}</p>
                <div className="iconContainer" onClick={() => writeToClipboard(isBusiness(dropoffAddress) && (dropoffAddress.name + ', ') ? isBusiness(dropoffAddress) && (dropoffAddress.name + ', ') + dropoffAddr:dropoffAddr)}>
                <IonIcon src={require("./clipboard-outline.svg")} />
                </div>
            </div>
        </div> : null
    )
}

VerticalAddressComponent.propTypes = {

}

export default connect(null, { launchToastCard }) (VerticalAddressComponent);
