import axios from "axios";
import { backendAddress } from "../../App";
import { launchToastCard } from "../layout/toast";
import { UPDATE_ROOQUEST_STATUS, ROOQUEST_ERROR } from "./rooquest-types"



export const makeDeliveryOffer = (rqID, offer) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = {
      rqID, offer
    };
  
    try {
      const res = await axios.post(
        backendAddress + '/api/rq/offers', JSON.stringify(body), config
      );
  
      if (res && res.data && res.data.status === 'success') {
        dispatch(launchToastCard({ 
          msg: 'Success! Your delivery offer has been sent! Keep an eye on your notifications!',
          type: 'success',
          duration: 8500,
       }))
       dispatch({
        type: UPDATE_ROOQUEST_STATUS,
        payload: res.data.rooquest,
      });
      }
      return res.data;
    } catch (err) {
      console.error(err);
      dispatch(handleErrors(err, 'There was an error making your delivery offer.'));
    }
  };

  export const acceptDeliveryOffer = (rqID, offerID) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = {
      rqID, offerID
    };
  
    try {
      const res = await axios.post(
        backendAddress + '/api/rq/offers/accept', JSON.stringify(body), config
      );
  
      if (res && res.data && res.data.status === 'success') {
      //   dispatch(launchToastCard({ 
      //     msg: 'Success! You accepted the delivery offer!',
      //     type: 'success',
      //     duration: 8500,
      //  }))
       dispatch({
        type: UPDATE_ROOQUEST_STATUS,
        payload: res.data.rooquest,
      });
      }
      return res.data;
    } catch (err) {
        console.error(err);
        dispatch(handleErrors(err, 'There was an error accepting the offer'));
    }
  };

  export const rejectDeliveryOffer = (rqID, offerID) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = {
      rqID, offerID
    };

    console.log(rqID, offerID);
  
    try {
      const res = await axios.post(
        backendAddress + '/api/rq/offers/reject', JSON.stringify(body), config
      );
  
      if (res && res.data && res.data.status === 'success') {
        dispatch(launchToastCard({ 
          msg: 'Success! You rejected their delivery offer!',
          type: 'success',
          duration: 8500,
       }))
       dispatch({
        type: UPDATE_ROOQUEST_STATUS,
        payload: res.data.rooquest,
      });
      }
      return res.data;
    } catch (err) {
        console.error(err);
        dispatch(handleErrors(err, 'There was an error rejecting their offer'));
    }
  };

  export const rescindDeliveryOffer = (rqID, offerID) => async (dispatch) => {
    
    
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = {
      rqID, offerID
    };

    console.log(rqID, offerID);
  
    try {
      const res = await axios.post(
        backendAddress + '/api/rq/offers/rescind', JSON.stringify(body), config
      );
  
      if (res && res.data && res.data.status === 'success') {
        // const { t } = useTranslation();
        dispatch(launchToastCard({ 
          msg: `${'Success! You canceled your delivery offer!'}`,
          type: 'success',
          duration: 8500,
       }))
       dispatch({
        type: UPDATE_ROOQUEST_STATUS,
        payload: res.data.rooquest,
      });
      }
      return res.data;
    } catch (err) {
        console.error(err);
        dispatch(handleErrors(err, `${'There was an error  your offer'}`));
    }
  };

/////////////////////////////////////////////////////////////////////

  const handleErrors = (err, msg) => (dispatch) => {
    if (err.response && err.response.data) {
        if (err.response.data.status === 'error' && err.response.data.errors.length > 0) {
            if (err.response.data.rooquest) {
                dispatch({
                    type: UPDATE_ROOQUEST_STATUS,
                    payload: err.response.data.rooquest,
                });
            }

            dispatch(launchToastCard({ 
                msg: err.response.data.errors[0].msg,
                type: 'error',
                duration: 8500,
            })) 
            return err.response.data;
        }
        else {
          dispatch(launchToastCard({ 
            msg: msg,
            type: 'error',
            duration: 8500,
         }))
         return { status: 'error'};
        }
      }
      dispatch({
        type: ROOQUEST_ERROR,
        payload: err,
      });
  }