import React, { useState,useRef } from 'react';
import {
  IonModal,
  IonIcon,
  IonFabButton,
  useIonViewDidEnter,
  IonAlert,
  IonSlides,
  IonSlide,
} from '@ionic/react';
import GreenBackButton from '../../../GreenBackButton/GreenBackButton';
import PropTypes from 'prop-types';

import './MyDelivInfoModal.scss';
import {
  chatbubbleEllipsesOutline,
  arrowForwardCircleOutline,
  carOutline,
  checkmarkDoneOutline,
  closeOutline,
  pencilOutline,
} from 'ionicons/icons';
import { connect } from 'react-redux';
import { setPanToLocation } from '../../../../../actions/layout/map';
import {
  deliverRooQuest,
  markRQAsDelivered,
  updateRooQuestWithoutCall,
  getMyDeliveries,
  cancelRooQuestDelivery,
} from '../../../../../actions/rooquest/rooquest';
import {
  getConversation,
  setShowChatModal,
} from '../../../../../actions/chat/chat';
import RouteInfoVertical from '../RouteInfo/RouteInfoVertical';
import {
  setShowInfoModal,
  setShowRatingsModal,
  setShowLocationOnMapModal,
} from '../../../../../actions/layout/navigation';
import getStatusTextAndColour from '../../../../../utils/getStatusTextAndColour';
import ConfirmOnTheWayModal from './ConfirmOnTheWayModal/ConfirmOnTheWayModal';
import ConfirmDeliveryModal from './ConfirmDeliveryModal/ConfirmDeliveryModal';
import { useTranslation } from 'react-i18next';
import {
  setShowEnlargeImageModal
} from '../../../../../actions/layout/navigation';

const MyDelivInfoModal = ({
  auth,
  getConversation,
  setShowChatModal,
  cancelRooQuestDelivery,
  navigation,
  setShowInfoModal,
  rooquest,
  updateRooQuestWithoutCall,
  getMyDeliveries,
  setShowRatingsModal,
  setShowLocationOnMapModal,
  setShowEnlargeImageModal
}) => {
  const { t } = useTranslation();
  let rq = rooquest.rooquest;

  const slideOpts = {
    on: {
      beforeInit() {
        const swiper = this;
        swiper.classNames.push(`${swiper.params.containerModifierClass}flip`);
        swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);
        const overwriteParams = {
          slidesPerView: 1,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
          watchSlidesProgress: true,
          spaceBetween: 0,
          virtualTranslate: true,
        };
        swiper.params = Object.assign(swiper.params, overwriteParams);
        swiper.originalParams = Object.assign(swiper.originalParams, overwriteParams);
      },
      setTranslate() {
        const swiper = this;
        const { slides, rtlTranslate: rtl } = swiper;
        for (let i = 0; i < slides.length; i += 1) {
          const $slideEl = slides.eq(i);
          let progress = $slideEl[0].progress;
          if (swiper.params.flipEffect.limitRotation) {
            progress = Math.max(Math.min($slideEl[0].progress, 1), -1);
          }
          const offset$$1 = $slideEl[0].swiperSlideOffset;
          const rotate = -180 * progress;
          let rotateY = rotate;
          let rotateX = 0;
          let tx = -offset$$1;
          let ty = 0;
          if (!swiper.isHorizontal()) {
            ty = tx;
            tx = 0;
            rotateX = -rotateY;
            rotateY = 0;
          } else if (rtl) {
            rotateY = -rotateY;
          }
  
           $slideEl[0].style.zIndex = -Math.abs(Math.round(progress)) + slides.length;
  
           if (swiper.params.flipEffect.slideShadows) {
            // Set shadows
            let shadowBefore = swiper.isHorizontal() ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
            let shadowAfter = swiper.isHorizontal() ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom');
            if (shadowBefore.length === 0) {
              shadowBefore = swiper.$(`<div class="swiper-slide-shadow-${swiper.isHorizontal() ? 'left' : 'top'}"></div>`);
              $slideEl.append(shadowBefore);
            }
            if (shadowAfter.length === 0) {
              shadowAfter = swiper.$(`<div class="swiper-slide-shadow-${swiper.isHorizontal() ? 'right' : 'bottom'}"></div>`);
              $slideEl.append(shadowAfter);
            }
            if (shadowBefore.length) shadowBefore[0].style.opacity = Math.max(-progress, 0);
            if (shadowAfter.length) shadowAfter[0].style.opacity = Math.max(progress, 0);
          }
          $slideEl
            .transform(`translate3d(${tx}px, ${ty}px, 0px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`);
        }
      },
      setTransition(duration) {
        const swiper = this;
        const { slides, activeIndex, $wrapperEl } = swiper;
        slides
          .transition(duration)
          .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
          .transition(duration);
        if (swiper.params.virtualTranslate && duration !== 0) {
          let eventTriggered = false;
          // eslint-disable-next-line
          slides.eq(activeIndex).transitionEnd(function onTransitionEnd() {
            if (eventTriggered) return;
            if (!swiper || swiper.destroyed) return;
  
            eventTriggered = true;
            swiper.animating = false;
            const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
            for (let i = 0; i < triggerEvents.length; i += 1) {
              $wrapperEl.trigger(triggerEvents[i]);
            }
          });
        }
      }
    }
  };
  const slidesRef = useRef(null);
  const openChat = async () => {
    let images = rq.imgURLS;
    let rqImage = (images.length > 0) ? images[0] : null;
    const res = await getConversation(
      rq._id,
      rq.title,
      rqImage,
      rq.requester,
      rq.requesterFirstName,
      rq.deliverer,
      rq.delivererFirstName
    );
    if (res) setShowChatModal(true);
  };

  let statusInfo;
  useIonViewDidEnter(() => {
    statusInfo = getStatusTextAndColour(
      rq.status,
      rq.deliverer === auth.user._id,
      'mydeliveries',
      auth.user.color_theme
    );
    slidesRef.current.update();
  });

  const cancelDelivery = async (reason) => {
    await updateRooQuestWithoutCall(
      await cancelRooQuestDelivery(rq._id, reason)
    );
    setShowInfoModal('MYDELIV', false);
  };

  const [
    showConfirmationDeliveryModal,
    setShowConfirmationDeliveryModal,
  ] = useState(false);
  const openDeliveryConfirmationModal = () => {
    setShowConfirmationDeliveryModal(true);
  };

  const [
    showConfirmationOnTheWayModal,
    setShowConfirmationOnTheWayModal,
  ] = useState(false);
  const openOnTheWayConfirmationModal = () => {
    setShowConfirmationOnTheWayModal(true);
  };

  const [showCancelDeliveryAlert, setShowCancelDeliveryAlert] = useState(false);

const enlargeImage = (e, src) => {
    if (e.target.tagName !== 'ION-ICON') {
      setShowEnlargeImageModal(true, src);
    }
  };

  const closeModal = () => {
    updateRooQuestWithoutCall(null);
    getMyDeliveries();
    setShowInfoModal('MYDELIV', false);
  };

  return (
    rq ? (
      <IonModal
        mode="ios"
        isOpen={navigation.showMyDelivModal}
        class="MyDelivInfoModal"
        onWillPresent={async () => {
          if(slidesRef.current){
            await slidesRef.current.update();
          }
        }}
        onWillDismiss={() => closeModal() }
      >
        <div className="modalContent">
          <div className="infoContent">
            <GreenBackButton
              onClickFxn={() => closeModal()}
            />
            <div className="rqImg">
              {/* <img
                src={
                  rq.imgURLS && rq.imgURLS[0]
                    ? rq.imgURLS[0]
                    : require('../../../../feed/NoImageAvailable.png')
                }
                alt=""
              /> */}
            {rq.imgURLS.length > 0 ? <IonSlides ref={slidesRef} pager={rq.imgURLS.length > 1} options={slideOpts} className="slide-img">
                 {rq.imgURLS.map((item , index)=> {
                                return (
                                  <IonSlide onClick={(e) => enlargeImage(e, item)} key={index}>
                                      <img src={item} alt="slide" />
                                  </IonSlide>
                                )
                              }
                        )}
              </IonSlides> : <img src={require('../../../../feed/NoImageAvailable.png')} alt=""/>
              }
            </div>
            <div className="rqTitleContainer">
              <div className="title">
                <h1>{rq.title}</h1>
              </div>
              <div className="costContainer">
                <p>
                  <span>{t('Cost of Goods')}: </span>${rq.costOfGoods}
                </p>
                <p>
                  <span>{t('Delivery Fee')}: </span>${rq.costOfDeliverer}
                </p>
              </div>
            </div>
            <div
              className="rqRouteInfoContainer"
              onClick={() =>
                setShowLocationOnMapModal(
                  true,
                  rq.pickupAddress,
                  rq.deliveryAddress,
                  true
                )
              }
            >
              <RouteInfoVertical rooquest={rq} long={true} fullAddress={true} />
            </div>
            <div className="rqOtherInfo">
              <div className="description">
                <h5>{t('Description')}:</h5>
                <p>{rq.description}</p>
              </div>
              {rq.additionalNote && (
                <div className="description">
                  <h5>{t('Additional Notes')}:</h5>
                  <p>{rq.additionalNote}</p>
                </div>
              )}
            </div>
          </div>
          <div className="actionContent">
            {statusInfo && (
              <div className="statusRow">
                <p style={statusInfo.colour}>
                  <span>Status: </span>
                  {statusInfo.text} <IonIcon icon={arrowForwardCircleOutline} />
                </p>
              </div>
            )}
            <div className="actionRow">
              {rq.status === 'RQ_ACCEPTED' && (
                <div className="leftActionBtn">
                  <IonFabButton
                    onClick={async () => openOnTheWayConfirmationModal()}
                  >
                    <IonIcon icon={carOutline}></IonIcon>
                  </IonFabButton>
                  <p>
                    {t('Mark as')} <br /> {t('On The Way')}
                  </p>
                </div>
              )}
              {rq.status === 'RQ_ONTHEWAY' && (
                <div className="leftActionBtn">
                  <IonFabButton
                    onClick={async () => openDeliveryConfirmationModal()}
                  >
                    <IonIcon icon={checkmarkDoneOutline}></IonIcon>
                  </IonFabButton>
                  <p>
                  {t('Mark as')} <br /> {t('Delivered')}
                  </p>
                </div>
              )}
              {rq.status === 'ROOQUEST_DELIVERED' && rq.requesterRatingLeft && (
                <div className="leftActionBtn"></div>
              )}
              {rq.status === 'ROOQUEST_DELIVERED' && !rq.requesterRatingLeft && (
                <div className="leftActionBtn">
                  <IonFabButton
                    onClick={async () =>
                      setShowRatingsModal(true, 'REQUESTER_REVIEW')
                    }
                  >
                    <IonIcon icon={pencilOutline}></IonIcon>
                  </IonFabButton>
                  <p>
                  {t('Leave')} <br />  {t('a Review')}
                  </p>
                </div>
              )}
              {(rq.status === 'RQ_ACCEPTED' ||
                rq.status === 'RQ_ONTHEWAY' ||
                rq.status === 'ROOQUEST_DELIVERED'||
                rq.status === 'UNDER_REVIEW'||
                rq.status === 'ROOQUEST_WAITING_CLIENT_CONFIRMATION') && (
                <div className="rightActionBtn">
                  <p>
                    {t('Contact')} <br /> {t('Requester')}
                  </p>
                  <IonFabButton onClick={async () => openChat()}>
                    <IonIcon icon={chatbubbleEllipsesOutline}></IonIcon>
                  </IonFabButton>
                </div>
              )}
            </div>
            <div className="actionRow">
              {rq.status === 'RQ_ACCEPTED' && (
                <div className="leftActionBtn">
                  <IonFabButton
                    onClick={async () => setShowCancelDeliveryAlert(true)}
                  >
                    <IonIcon icon={closeOutline}></IonIcon>
                  </IonFabButton>
                  <p>
                    {t('Cancel')} <br /> {t('Delivery')}
                  </p>
                  <IonAlert
                    isOpen={showCancelDeliveryAlert}
                    onDidDismiss={() => setShowCancelDeliveryAlert(false)}
                    mode="ios"
                    cssClass="confirmCancelRooQuestAlert"
                    header={`${t('Cancel Delivery?')}`}
                    message={
                      `${t('Are you sure you want to cancel the delivery? This will be kept on your deliverer record and can lead to account penalties.')}`
                    }
                    inputs={[
                      {
                        name: 'cancellationReason',
                        type: 'text',
                        placeholder: 'Reason for cancellation',
                      },
                    ]}
                    buttons={[
                      {
                        text: 'Close',
                        role: 'cancel',
                        cssClass: 'secondary',
                      },
                      {
                        text: 'Cancel Delivery',
                        handler: async (alertData) => {
                          await cancelDelivery(alertData.cancellationReason);
                        },
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <ConfirmOnTheWayModal
          showModal={showConfirmationOnTheWayModal}
          setShowModal={setShowConfirmationOnTheWayModal}
        />
        <ConfirmDeliveryModal
          showModal={showConfirmationDeliveryModal}
          setShowModal={setShowConfirmationDeliveryModal}
        />
      </IonModal>
    ) : null
  );
};

MyDelivInfoModal.propTypes = {
  deliverRooQuest: PropTypes.func.isRequired,
  markRQAsDelivered: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  getConversation: PropTypes.func.isRequired,
  setPanToLocation: PropTypes.func.isRequired,
  setShowChatModal: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
  setShowInfoModal: PropTypes.func.isRequired,
  rooquest: PropTypes.object.isRequired,
  updateRooQuestWithoutCall: PropTypes.func.isRequired,
  getMyDeliveries: PropTypes.func.isRequired,
  cancelRooQuestDelivery: PropTypes.func.isRequired,
  setShowRatingsModal: PropTypes.func.isRequired,
  setShowLocationOnMapModal: PropTypes.func.isRequired,
  setShowEnlargeImageModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  rooquest: state.rooquest,
  map: state.map,
  navigation: state.navigation,
});

export default connect(mapStateToProps, {
  setPanToLocation,
  getConversation,
  setShowChatModal,
  deliverRooQuest,
  markRQAsDelivered,
  setShowInfoModal,
  updateRooQuestWithoutCall,
  getMyDeliveries,
  cancelRooQuestDelivery,
  setShowRatingsModal,
  setShowLocationOnMapModal,
  setShowEnlargeImageModal
})(MyDelivInfoModal);
