export const provincialTaxes_Local = {
    "Alberta": {
      "name": "AB",
      "rate": 0.05,
      "taxes": "GST"
    },
    "British Columbia": {
      "name": "BC",
      "rate": 0.12,
      "taxes": "GST + PST"
    },
    "Manitoba": {
      "name": "MB",
      "rate": 0.12,
      "taxes": "GST + PST"
    },
    "New Brunswick": {
      "name": "NB",
      "rate": 0.15,
      "taxes": "HST"
    },
    "Newfoundland and Labrador": {
      "name": "NL",
      "rate": 0.15,
      "taxes": "HST"
    },
    "Northwest Territories": {
      "name": "NT",
      "rate": 0.05,
      "taxes": "GST"
    },
    "Nova Scotia": {
      "name": "NS",
      "rate": 0.15,
      "taxes": "HST"
    },
    "Nunavut": {
      "name": "NU",
      "rate": 0.05,
      "taxes": "GST"
    },
    "Ontario": {
      "name": "ON",
      "rate": 0.13,
      "taxes": "HST"
    },
    "Prince Edward Island": {
      "name": "PEI",
      "rate": 0.15,
      "taxes": "HST"
    },
    "Quebec": {
      "name": "QC",
      "rate": 0.14975,
      "taxes": "GST + QST"
    },
    "Saskatchewan": {
      "name": "SK",
      "rate": 0.11,
      "taxes": "GST + PST"
    },
    "Yukon": {
      "name": "YT",
      "rate": 0.05,
      "taxes": "GST"
    }
}

export const getTaxRate = (province, taxes) => {
    if (province === 'Alberta' || province === 'AB') {
        return taxes.Alberta;
    }
    if (province === 'British Columbia' || province === 'BC') {
        return taxes['British Columbia'];
    }
    if (province === 'Manitoba' || province === 'MB') {
        return taxes.Manitoba;
    }
    if (province === 'New Brunswick' || province === 'NB') {
        return taxes['New Brunswick'];
    }
    if (province === 'Newfoundland and Labrador' || province === 'NL') {
        return taxes['Newfoundland and Labrador'];
    }
    if (province === 'Northwest Territories' || province === 'NT') {
        return taxes['Northwest Territories'];
    }
    if (province === 'Nova Scotia' || province === 'NS') {
        return taxes['Nova Scotia'];
    }
    if (province === 'Nunavut' || province === 'NU') {
        return taxes.Nunavut;
    }
    if (province === 'Ontario' || province === 'ON') {
        return taxes.Ontario;
    }
    if (province === 'Prince Edward Island' || province === 'PEI') {
        return taxes['Prince Edward Island'];
    }
    if (province === 'Quebec' || province === 'QC' || province === 'Québec') {
        return taxes.Quebec;
    }
    if (province === 'Saskatchewan' || province === 'SK') {
        return taxes.Saskatchewan;
    }
    if (province === 'Yukon' || province === 'YT') {
        return taxes.Yukon;
    }
    return taxes.Quebec;
}