import { DELETE_NOTIFICATION, GETTING_NOTIFICATIONS, GETTING_NOTIFICATIONS_ERROR, GOT_NOTIFICATIONS, MARK_NOTIFICATIONS_AS_READ } from "../actions/notificationmenu/notificationmenu-types";

  
  const initialState = {
    loading: true,
    notifications: [],
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GETTING_NOTIFICATIONS:
        return {
          ...state,
          loading: true,
        };
      case GOT_NOTIFICATIONS:
        return {
          ...state,
          loading: false,
          notifications: payload,
        };
      case DELETE_NOTIFICATION:
        return {
          ...state,
          notifications: state.notifications.filter(
            (notif) => notif._id !== payload
          ),
          loading: false,
          conversation: payload,
        };
      case MARK_NOTIFICATIONS_AS_READ:
        state.notifications.forEach((notif) => notif.viewed = true);
        return {
          ...state,
        };
      case GETTING_NOTIFICATIONS_ERROR:
        return {
          ...state,
          loading: false,
          notifications: [],
        };
      default:
        return state;
    }
  }
  