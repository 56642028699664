import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonList, IonCheckbox } from '@ionic/react';
import { setShowEnlargeImageModal } from '../../../../../actions/layout/navigation';
import WhiteCard from '../../../components/ui/WhiteCard';
import FlipNumbers from 'react-flip-numbers';

import './RooQuestItemsList.scss';
import { useTranslation } from 'react-i18next';


const RooQuestItemsList = ({ rooquest }) => {
    const { t } = useTranslation();
    const calculateTotalItemCost = (quantity, costPerUnit) => {
        const total = quantity * costPerUnit;
        return total.toFixed(2);
    }

    const calculateNewItemCost = (quantity, shelfPrice, shelfPriceType) => {
        if (shelfPriceType === 'perUnit') {
            const total = quantity * shelfPrice;
            return total.toFixed(2);
        }
        else {
            const total = shelfPrice;
            return total.toFixed(2);
        }
    }

    const [total,setTotal] = useState(0);
    useEffect(()=>{
        const add =(a,b)=>{
            if (b.collected && b.shelfPriceType === 'perUnit' && !b.problem.hasProblem)
            {
                return a+ b.quantity*b.shelfPrice;
            }
            else if(b.collected && b.shelfPriceType === 'totalQuantity' && !b.problem.hasProblem)
            {
                return a+b.shelfPrice
            }
            return a;
        }
        const arr = rooquest.items.map((a)=>{return a.collectionInfo});
        const result = arr.reduce(add,0);
        setTotal(result);
    })

    const calculateTotalEstimatedCost = () => {
        let total = 0;
        rooquest.items.forEach(item => {
            if (item.collectionInfo.collected && item.collectionInfo.problem && !item.collectionInfo.problem.hasProblem) {
                total += parseFloat(calculateNewItemCost(item.collectionInfo.quantity, item.collectionInfo.shelfPrice, item.collectionInfo.shelfPriceType));
            }
            else if (item.collectionInfo.collected && item.collectionInfo.problem && item.collectionInfo.problem.hasProblem) {
                // Do nothing
            }
            else {
                total += parseFloat(calculateTotalItemCost(item.quantity, item.costPerUnit));

            }

        });
        return total.toFixed(2);
    }

    return (
        <div className="RooQuestItemsList">
            <p className="sectionTitle">{t("Items")}</p>
            <WhiteCard>
                <div className="estimatedCostContainer">
                    <p>{t("Estimated Total Cost of Goods")}: </p>
                    <FlipNumbers 
                        height={14} 
                        width={9.5} 
                        color="#10be5f" 
                        // background="white" 
                        play perspective={100} 
                        duraction={0.4}
                        numbers={'$'+ parseFloat(rooquest.costOfGoods).toFixed(2)} 
                        numberStyle={{ fontWeight: 'bold'}}
                    />

                </div>
                <IonAccordionGroup mode='ios'>
                    {rooquest.items.map((item, index) => (
                        <IonAccordion key={`infoRQ-item-${index}`} mode='ios' value={`infoRQ-item-${index}`} >
                            <IonItem slot="header" tabIndex={-1}>
                                <IonLabel className="infoRQ-item-info">
                                    <div className="item-title">
                                        <p>{item.title}</p>
                                    </div>
                                    <div className="cost">
                                        <p>{item.collectionInfo.collected && item.collectionInfo.problem && !item.collectionInfo.problem.hasProblem ? item.collectionInfo.quantity:item.quantity} x ${item.collectionInfo.collected && item.collectionInfo.problem && !item.collectionInfo.problem.hasProblem 
                                            ? parseFloat(item.collectionInfo.shelfPrice).toFixed(2) 
                                            : parseFloat(item.costPerUnit).toFixed(2)}</p>
                                        <div className="costOfItem">
                                            {console.log("test itemmssmsn")}
                                            {console.log(item)}
                                        {item.costPerUnit*item.quantity !== item.collectionInfo.shelfPrice * item.collectionInfo.quantity ? <p className={`total ${item.collectionInfo.collected ? 'strikethrough' : ''}`}>${calculateTotalItemCost(item.quantity, item.costPerUnit)}</p>:<div></div>}
                                        {item.collectionInfo.collected && item.collectionInfo.problem && !item.collectionInfo.problem.hasProblem &&
                                        <p className={`total new`}>${calculateNewItemCost(item.collectionInfo.shelfPrice, item.collectionInfo.quantity, item.collectionInfo.shelfPriceType)}</p>}
                                        </div>
                                       
                                    </div>
                                    {item.collectionInfo.collected ? 
                                        (item.collectionInfo.problem && item.collectionInfo.problem.hasProblem)
                                            ? 
                                                <div className='itemCollectionStatus'>
                                                    {item.collectionInfo.problem.reason ? 
                                                        (item.collectionInfo.problem.reason === 'no-stock') 
                                                            ? <p className='warning'>{t("Item out of stock")}</p>
                                                            : (item.collectionInfo.problem.reason === 'unable-to-find')
                                                                ? <p className='warning'>{t("Unable to find item")}</p>
                                                            : null
                                                        : null
                                                    }
                                                    {
                                                        item.collectionInfo.problem.reason === 'other'? item.collectionInfo.problem.note && 
                                                        <p className='warning'>{item.collectionInfo.problem.note}</p>:<></>
                                                    }
                                                </div>
                                            : 
                                                <div className='itemCollectionStatus'>
                                                    <p className='success'>{t("Item Collected")}</p>
                                                </div>
                                        : null
                                    }
                                </IonLabel>
                                
                            </IonItem>
                            <IonList className="infoRQ-item-moreinfo" slot="content">
                                {item.description !== '' && <div className="infoRQ-moreinfo-section">
                                    <p className='infoRQ-sectionTitle'>{t("Description")}:</p>
                                    <p className='infoRQ-sectionContent'>{item.description}</p>
                                </div>}
                                {item.sku !== "" && <div className="infoRQ-moreinfo-section">
                                    <p className='infoRQ-sectionTitle'>SKU:</p>
                                    <p className='infoRQ-sectionContent'>{item.sku}</p>
                                </div>}
                                {item.images.length > 0 && <div className="infoRQ-moreinfo-section">
                                    <p className='infoRQ-sectionTitle'>{t("Images")}:</p>
                                    <div className="photosContainer">
                                        {item.images.map((image, index) => (
                                            <img 
                                                key={`infoRQ-item-image-${index}`} 
                                                src={image} 
                                                alt="item"
                                                onClick={() => setShowEnlargeImageModal(true, image)} 
                                            />
                                        ))}
                                    </div>
                                </div>}
                                {item.description === '' && item.sku === "" && item.images.length === 0 && 
                                <div className="infoRQ-moreinfo-section">
                                    <p className='infoRQ-sectionTitle'>{t("No additional information available")}</p>
                                </div>}
                            </IonList>
                        </IonAccordion>  
                    ))}  
                    {/* <div className='RooQuestCheckListItem'>
                            <IonAccordion mode='ios' value={`infoRQ-item-${rooquest.items.length}`}  >
                                <IonItem slot="header" tabIndex={-1}>
                                    <IonLabel className="infoRQ-item-info">
                                        <div className="item-title">
                                        </div>
                                        <div className="cost">
                                        <p>{t('Total Collected')}:</p>                        
                                        <p className={`total`}>${total.toFixed(2)}</p>
                                        </div>
                                        
                                    </IonLabel>
                                </IonItem>
                            </IonAccordion> 
                     </div>                                  */}
                </IonAccordionGroup>
            </WhiteCard>                                
        </div>
    )
}

RooQuestItemsList.propTypes = {};

export default RooQuestItemsList;